import React, { useEffect, useState } from 'react'
import style from './styles.module.scss';
import { authHeader, secondsToHm } from '../../../../_helpers';
import IconInfo from '../../../_icons/IconInfo';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box } from '@mui/material';
import Widget31Skeleton from './Widget31Skeleton';
import ReactApexChart from 'react-apexcharts';
import BefectiveTooltip from '../../../_shared/BefectiveTooltip';
import { api } from '../../../../_helpers/api';

export default function Widget31({
  alertActions,
  currentDate,
  width,
  filters
}) {
  const { t, i18n } = useTranslation('common');
  const [fetching, setFetching] = useState(true);
  
  const [chartDonutData, setChartDonutData] = useState({
    options: {
      chart: {
        type: 'donut',
        height: 210,
        width: 480,
        selection: {
          enabled: false, // Desabilita a seleção de seções
        }
      },
      labels: [],
      colors: [],
      stroke: {
        width: -2, // Aumente esse valor para aumentar a espessura
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `<div class="custom-tooltip" style="background-color: ${w.config.colors[seriesIndex]}; padding: 4px;">${w.config.labels[seriesIndex]}: ${Number(series[seriesIndex]).toFixed(0)}%</div>`;
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 210
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      legend: {
        show: true,
        position: 'right',
        formatter: function (series, opts) {
          return `<span class="legend-text" style="font-size:14px; color: #4F7091; margin-right: 8px; margin-bottom: 10px;">${opts.w.globals.labels[opts.seriesIndex]}: <strong>${Number(opts.w.globals.series[opts.seriesIndex]).toFixed(0)}%</strong></span>`;
        },
        itemMargin: {
          vertical: 5, // Adiciona margin-top e margin-bottom de 10px
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return val.toFixed(0) + '%'; // Personaliza o formato do percentual com 2 casas decimais
        },
        style: {
          fontSize: '9px !important', // Tamanho da fonte do rótulo
          fontFamily: 'Arial, sans-serif', // Fonte do rótulo
          fontWeight: '400', // Peso da fonte
          colors: ['#FFFFFF'], // Cor do texto
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '52%',
            labels: {
              show: false,
            },
          },
        },
      }
    },
    series: []
  })


  const getData = async ({filterTeams, filterTeammates, filterAssessment}) => {
    try {
      setFetching(true)
      const response = await api.get(`/performance-assessment/widgets/result-indicators-chart?current_day=${currentDate.format('YYYY-MM-DD')}${filterTeams}${filterTeammates}${filterAssessment}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget31!!!')
      }
      const { data } = response
      setChartDonutData({
        ...chartDonutData,
        options: {
          ...chartDonutData.options,
          labels: data.labels,
          colors: data.colors
        },
        series: data.values
      })
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData({...filters})
  }, [])

  return (
    !fetching
      ?

      <Grid item xs={width}>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <div className={style.boxTitle}>
                <h6 className={style.title}>{t('widgets.widget31.title')}</h6>
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('widgets.widget31.tooltip')}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo/></div>
                </BefectiveTooltip>
              </div>
            </div>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box component={'div'} sx={{ width: '100%', height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center'}} className={style.boxChartDonut}>
                  {
                    chartDonutData.series.length > 0
                      ?
                      <ReactApexChart  options={chartDonutData.options} series={chartDonutData.series} width={480} height={210} type="donut" />
                      :
                      <Box component={'div'} sx={{ width: '100%', height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography component={'span'} sx={{color:"#4F7091", fontSize:"14px"}}>{t('widgets.no_data_available')}</Typography>
                      </Box>
                  }
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
      :
      <Grid item xs={width}>
        <Widget31Skeleton />
      </Grid>

  )
}
