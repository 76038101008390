// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1Ysvgt92Cz-HqFtpgpgHBQ\\=\\= {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  font-size: 12px;\n}\n\n._31Fkc-M4Q2pkhKcR3n-4fA\\=\\= {\n  display: flex;\n  align-items: center;\n  height: 20px;\n  width: 20px;\n}", "",{"version":3,"sources":["webpack://./src/_components/reports/value_hour/PieHourPercent.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AACF","sourcesContent":[".containerProductivity{\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  font-size: 12px;\n}\n\n.boxCircularProgress{\n  display: flex;\n  align-items: center;\n  height: 20px;\n  width: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerProductivity": "_1Ysvgt92Cz-HqFtpgpgHBQ==",
	"boxCircularProgress": "_31Fkc-M4Q2pkhKcR3n-4fA=="
};
export default ___CSS_LOADER_EXPORT___;
