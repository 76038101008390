import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './NewAssessmentForm.module.scss'
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { Box, TextField, Grid, Typography } from '@mui/material';
import { AvatarGroup } from "@material-ui/lab";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { authHeader, befectiveFormatDate, getAvatarUrl, getIntroExample, history } from '../../../_helpers';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import QuillToolbar, { formats, modules } from './QuillToolbar';
import ApresentationDetailsModal from './ApresentationDetailsModal';
import FormHeader from './FormHeader';
import FormSkillCard from './FormSkillCard';
import FormPreviewModal from './FormPreviewModal';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';
import { Divider, FormControlLabel, Tooltip, Avatar, IconButton } from '@material-ui/core';
import IconInfo from '../../_icons/IconInfo';
import EnhancedTable from '../../_shared/EnhancedTable';
import ParticipantsDrawer from './ParticipantsDrawer';
import CustomSwitch from '../../_shared/CustomSwitch';
import SubmitEvaluationModal from './SubmitEvaluationModal';
import { Delete } from '@material-ui/icons';
import BefectiveCheckbox from '../../_shared/BefectiveCheckbox';
import { api } from '../../../_helpers/api';
import EvaluatorsEditModal from './EvaluatorsEditModal';
import { useMessagesCount } from '../../../contexts/MessagesCountContext';

export default function NewAssessmentForm({
  assessmentData,
  alertActions,
  userDetails,
  currentTab,
  updateTabValue,
  filter,
  globalData,
  teams,
  formAssessment,
  setFormAssessment,
  skillsAssessment,
  setSkillsAssessment
}) {

  const { t, i18n } = useTranslation("common");
  
  const lang = userDetails && userDetails.language ? userDetails.language : i18n.language;
	const { getAssessmentsPending } = useMessagesCount()

  const [openModalPreviewApresentation, setOpenModalPreviewApresentation] = useState(false);
  const [openModalPreviewForm, setOpenModalPreviewForm] = useState(false);
  const [openModalSubmitEvaluation, setOpenModalSubmitEvaluation] = useState(false);
  const [openDrawerParticipants, setOpenDrawerParticipants] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittingFail, setSubmittingFail] = useState(false);
  const [openModalEditEvaluators, setOpenModalEditEvaluators] = useState(false);
  const [currentEvaluated, setCurrentEvaluated] = useState(null);

  const [isFocused, setIsFocused] = useState({
    performance_assessment_notification_id: false,
    performance_assessment_periodization_id: false,
    performance_assessment_scope_id: false,
    self_assessment: false,
    result_indicators_id: false
  });

  const [isChanged, setIsChanged] = useState({
    performance_assessment_notification_id: false,
    performance_assessment_periodization_id: false,
    performance_assessment_scope_id: false,
    self_assessment: false,
    result_indicators_id: false
  });

  const [formError, setFormError] = useState({
    title: "",
    skills: "",
    result_indicators_id: ""
  });
  const [submitted, setSubmitted] = useState(false);

  
  const columns = useMemo(() => {
    return [
      {
        Header: t("performance_assessment.tab_participants_form.table.col_evaluated"),
        accessor: "username",
      },
      {
        Header: t("performance_assessment.tab_participants_form.table.col_position"),
        accessor: "office",
        Cell: ({ value }) => {
          return value || "----";
        }
      },
      {
        Header: t("performance_assessment.tab_participants_form.table.col_auto_evaluation"),
        accessor: "self_assessment",
        style: { textAlign: "center" },
        Cell: ({ value }) => {
          return (value == true || value == "true")? t("performance_assessment.tab_participants_form.yes") :  t("performance_assessment.tab_participants_form.no");
        },
      },
      {
        Header: t("performance_assessment.tab_participants_form.table.col_evaluators"),
        accessor: "evaluator",
        Cell: ({ value }) => {
          const evaluatorsFiltered = value?.filter((evaluator) => evaluator?.id !== null);
          return evaluatorsFiltered && evaluatorsFiltered.length > 0 ? (
            <AvatarGroup max={5}>
              {evaluatorsFiltered.map((teammate, index) => (
                <Tooltip key={`avatar-${index}`} title={teammate?.username}>
                  <Avatar
                    alt={teammate?.username}
                    src={getAvatarUrl(teammate)}
                    style={{ width: 28, height: 28 }}
                  />
                </Tooltip>
              ))}
            </AvatarGroup>
          ) : (
            "----"
          );
        },
      }
    ];
  }, []);

  const handleFocusSelect = (e) => {
    const { name } = e.target;
    setIsFocused({
      ...isFocused,
      [name]: true
    });
  };

  const handleBlurSelect = (e) => {
    const { name } = e.target;
    setIsFocused({
      ...isFocused,
      [name]: false
    });
  };

  const handleChangeSelect = (e) => {
    const { name, value } = e.target;
    if(name === 'performance_assessment_scope_id'){
      setFormAssessment({
        ...formAssessment,
        self_assessment: 'true',
        [name]: value
      });
      return
    }
    setFormAssessment({
      ...formAssessment,
      [name]: value 
    });
    setIsChanged({
      ...isChanged,
      [name]: true
    });
    setFormError({
      ...formError,
      [name]: ""
    })
  };

  function getDataFormat(lang) {
    if (lang === 'en') {
      return "MM/DD/YYYY"
    } else {
      return "DD/MM/YYYY"
    }
  }

  const handleOpenModalPreviewApresentation = () => {
    setOpenModalPreviewApresentation(true);
  };

  const handleCloseModalPreviewApresentation = () => {
    setOpenModalPreviewApresentation(false);
  };
  
  const handleOpenModalPreviewForm = () => {
    setOpenModalPreviewForm(true);
  };

  const handleCloseModalPreviewForm = () => {
    setOpenModalPreviewForm(false);
  };

  const handleOpenModalSubmitEvaluation = () => {
    setOpenModalSubmitEvaluation(true);
  };

  const handleCloseModalSubmitEvaluation = () => {
    setOpenModalSubmitEvaluation(false);
  };

  const handleOpenParticipantsDrawer = () => {
    setOpenDrawerParticipants(true);
  }

  const handleCloseParticipantsDrawer = () => {
    setOpenDrawerParticipants(false);
  }

  const handleOpenModalEvaluatorsEdit = (row) => {
    setCurrentEvaluated(row);
    setOpenModalEditEvaluators(true);
  }
  const handleCloseModalEvaluatorsEdit = () => {
    setOpenModalEditEvaluators(false);
    setCurrentEvaluated(null);
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if(type === "checkbox"){
      setFormAssessment({
        ...formAssessment,
        [name]: checked
      })
      return
    }

    setFormAssessment({
      ...formAssessment,
      [name]: value
    });

    setFormError({
      ...formError,
      [name]: ""
    });
  };

  const handleChangeIntroduction = useCallback((value) => {
    setFormAssessment(prevState => ({
      ...prevState,
      introduction: value
    }));
  }, []);

  const updateSkillsAssessment = (skill) => {
    const skillsAssessmentNew = skillsAssessment.map(skill2 => {
      if(skill2.id === skill.id){
        return {
          ...skill,
          weight: skill.active ? skill.weight : 1,
          allowed_comment: skill?.active ? skill.allowed_comment : false
        }
      }
      return skill2
    })
    setSkillsAssessment(skillsAssessmentNew)
    localStorage.setItem("skillsAssessment", JSON.stringify(skillsAssessmentNew));
  }

  const updateOptionQuestions = (skill, question, option) => {
    // const skillsAssessmentNew = skillsAssessment.map(skill2 => {
    //   if(skill2.id === skill.id){
    //    const questions = skill2.questions.map(question2 => {
    //      if(question2.questions_id === question.questions_id){
    //        return {
    //          ...question2,
    //          scales_options: question2.scales_options.map(option2 => {
    //            if(option2.id === option.id){
    //              return {
    //                ...option2,
    //                selected: !option2.selected
    //              }
    //            }
    //            return {
    //              ...option2,
    //              selected: false
    //            }
    //          })
    //        }
    //      }
    //      return question2
    //    })

    //    return {
    //      ...skill2,
    //      questions: questions
    //    }
    //   }
    //   return skill2
    // })
    const skillsAssessmentNew = skillsAssessment.map(skill2 => {
      if(skill2.id === skill.id){
       const questions = skill2.questions.map(question2 => {
         if(question2.questions_id === question.questions_id){

           if(question2?.is_objective===false){
             return {
               ...question2,
               discursive_content: option
             }
           }
           return {
             ...question2,
             scales_options: question2.scales_options.map(option2 => {
               if(option2.id === option.id){
                 return {
                   ...option2,
                   selected: !option2.selected
                 }
               }
               return {
                 ...option2,
                 selected: false
               }
             })
           }
         }
         return question2
       })

       return {
         ...skill2,
         questions: questions
       }
      }
      return skill2
    })
    setSkillsAssessment(skillsAssessmentNew)
    localStorage.setItem("skillsAssessment", JSON.stringify(skillsAssessmentNew));
  }

  const getCoverageOption = (option) => {
    return globalData.scopes.find(coverage => coverage.id == option)
  }

  const addParticipants = (participants) => {
    const newParticipants = [...formAssessment.participants, ...participants]

    if(newParticipants.length > 0){
      //Remove duplicated
      newParticipants.forEach((participant, index) => {
        newParticipants.forEach((participant2, index2) => {
          if(participant.machine_users_id === participant2.machine_users_id && index !== index2){
            newParticipants.splice(index2, 1)
          }
        })
      })
      setFormAssessment({
        ...formAssessment,
        participants: newParticipants
      })
      return
    }

    setFormAssessment({
      ...formAssessment,
      participants: []
    })
  }

  const removeParticipant = (participant) => {
    const newParticipants = formAssessment.participants.filter(item => item.machine_users_id !== participant.machine_users_id)
    setFormAssessment({
      ...formAssessment,
      participants: newParticipants
    })
  }

  const updateEvaluators = (participant, evaluators) => {

    const newParticipants = formAssessment.participants.map(item => {
      if(item.machine_users_id === participant.machine_users_id){
        return {
          ...item,
          evaluator: evaluators && evaluators.length > 0 ? evaluators.filter(e => e.value !== null) : []
        }
      }
      return item
    })

    setFormAssessment({
      ...formAssessment,
      participants: newParticipants
    })
  }

  const createNewAssessment = async () => {
    try {
      setOpenModalSubmitEvaluation(true)
      setIsSubmitting(true)
      const payload = {
        ...formAssessment,
        evaluated: formAssessment.participants,
        skills: skillsAssessment.filter(skill => skill.active === true).map(skill => ({id: skill.id, allowed_comment: skill?.allowed_comment || false, performance_assessment_weight_id: 1})),
      }
      const res = await api.post('/performance-assessment', payload, {
        headers: authHeader()
      })

      if(res.status !== 200){
        throw new Error(res.data)
      }
      
      setIsSubmitting(false)
      alertActions.success(t("performance_assessment.messages.success_create_assessment"))
      setSubmittingFail(false)
      localStorage.removeItem("skillsAssessment")
      localStorage.removeItem("formAssessment")
      await getAssessmentsPending()
    } catch (error) {
      console.log(error)
      setIsSubmitting(false)
      setSubmittingFail(true)      
      alertActions.error(error.response.data || t("performance_assessment.messages.error_create_assessment"))
    }
  }

  const updateAssessment = async () => {
    try {
      setIsSubmitting(true)
      const payload = {
        ...formAssessment,
        evaluated: formAssessment.participants,
        skills: skillsAssessment.filter(skill => skill.active === true).map(skill => ({id: skill.id, allowed_comment: skill?.allowed_comment || false, performance_assessment_weight_id: 1})),
      }
      const res = await api.put('/performance-assessment', payload, {
        headers: authHeader()
      })
      
      if(res.status !== 200){
        throw new Error(res.data)
      }

      setIsSubmitting(false)
      alertActions.success(t("performance_assessment.messages.success_update_assessment"))
      history.push("/performance-assessment/created")
      localStorage.removeItem("skillsAssessment")
      localStorage.removeItem("formAssessment")
      await getAssessmentsPending()
    } catch (error) {
      console.log(error)
      setIsSubmitting(false)
      alertActions.error(error.response.data || t("performance_assessment.messages.error_update_assessment"))
    }
  }

  const validateForm = (form) => {
    const errors = {};

    if (!form.title) {
      errors.title = t("validation.required")
    }

    if(!form.result_indicators_id){
      errors.result_indicators_id = t("validation.required")
    }

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setFormError(validateForm(formAssessment))
    setSubmitted(true)
  }

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      if(formAssessment?.id){
        updateAssessment()
      }else{
        createNewAssessment()
      }
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])


  const handleClearForm = (tab) => {
    switch (tab) {
      case "apresentation":
        setFormAssessment((prevState) => ({
          ...prevState,
          id: assessmentData?.id || null,
          title: assessmentData?.title || "",
          subtitle: assessmentData?.subtitle || "",
          introduction: assessmentData?.introduction || getIntroExample(lang),
          performance_assessment_notification_id: assessmentData?.performance_assessment_notification_id || "",
          date_start: assessmentData?.date_start || moment().format("YYYY-MM-DD"),
          date_end:  assessmentData?.date_end ||  moment().endOf('month').format("YYYY-MM-DD"),
          allow_responses_after_deadline: assessmentData?.allow_responses_after_deadline || false,
          performance_assessment_periodization_id: assessmentData?.performance_assessment_periodization_id || "",
          performance_assessment_scope_id: assessmentData?.performance_assessment_scope_id || 1,
          self_assessment: assessmentData ? assessmentData?.self_assessment : true
        }))
        return
      case "form":
        setSkillsAssessment(assessmentData?.skills || globalData?.skills || [])
        localStorage.setItem("skillsAssessment", JSON.stringify(assessmentData?.skills || globalData?.skills || []));
        return
      case "participants":
        setFormAssessment((prevState) => ({
          ...prevState,
          participants: assessmentData?.evaluated || []
        }))
        return
      case "settings":
        setFormAssessment((prevState) => ({
          ...prevState,
          result_indicators_id: assessmentData?.result_indicators_id || '',
          show_result_table: assessmentData ? assessmentData?.show_result_table : true,
          show_avg_score_result_table: assessmentData ? assessmentData?.show_avg_score_result_table : true,
          show_radar: assessmentData ? assessmentData?.show_radar : true,
          show_general_stats: assessmentData ? assessmentData?.show_general_stats : true,
          allowed_todo_keep_doing: assessmentData ? assessmentData?.allowed_todo_keep_doing : true,
          allow_todo_should_do: assessmentData ? assessmentData?.allow_todo_should_do : true
        }))
        return

      default:
        return
    }
  }

  const handleCancelEdit = () => {
    localStorage.removeItem("formAssessment")
    localStorage.removeItem("skillsAssessment")
    return history.push("/performance-assessment/created")
  }

  return (
    <>
      <main className={styles.mainContentPage}>
        {
          currentTab === "apresentation" &&
          <div className={styles.container}>

            <FormHeader 
              currentTab={currentTab}
              title={t("performance_assessment.tab_apresentation_form.title")}
              isPreview={true} 
              handleOpenModalPreview={handleOpenModalPreviewApresentation} 
              handleCancel={() => history.push('/performance-assessment/created')}
              handleNext={() => {
                const errors = validateForm(formAssessment)
                if(Object.keys(errors).includes("title")){
                  setFormError(errors)
                  return
                }
                updateTabValue("form")
              }}
              handleClearForm={handleClearForm}
              handleCancelEdit={handleCancelEdit}
              />
              
            <div className={styles.card}>
              <div className={styles.container}>
                <div className={styles.row}>
                  <div className={styles.col50}>

                    <div className={styles.row}>
                      <h6 className={styles.globalTitle}>{t("performance_assessment.tab_apresentation_form.global_label_assessment")}</h6>
                    </div>
                    <div className={styles.formGroup}>
                      <label className={styles.label} htmlFor="title">{t("performance_assessment.tab_apresentation_form.label_title")}</label>
                      <input id="title" type="text" name='title' value={formAssessment?.title} onChange={handleChange} placeholder={t("performance_assessment.tab_apresentation_form.label_title_placeholder")}/>
                      {formError.title &&
                        <div className={styles.error}>{formError.title}</div>
                      }
                    </div>
                    <div className={styles.formGroup}>
                      <label className={styles.label} htmlFor="subtitle">{t("performance_assessment.tab_apresentation_form.label_subtitle")}</label>
                      <input id="subtitle" type="text" name='subtitle' value={formAssessment?.subtitle} onChange={handleChange} placeholder={t("performance_assessment.tab_apresentation_form.label_subtitle_placeholder")}/>
                    </div>

                    <div className={styles.row}>
                      <h6 className={styles.globalTitle}>{t("performance_assessment.tab_apresentation_form.global_label_notify_participants")}</h6>
                    </div>
                    <div className={styles.formGroup}>
                      <label className={styles.label} htmlFor="performance_assessment_notification_id">{t("performance_assessment.tab_apresentation_form.label_notify_participants")}</label>
                      <select className={`${styles.customSelect} ${styles.w70} ${isFocused.performance_assessment_notification_id ? styles.focused : ''} ${isChanged.performance_assessment_notification_id ? styles.changed : ''}`} name="performance_assessment_notification_id" id="performance_assessment_notification_id" value={formAssessment?.performance_assessment_notification_id} onFocus={handleFocusSelect} onBlur={handleBlurSelect} onChange={handleChangeSelect}>
                        <option value="">{t('global_options.no')}</option>
                        {
                          (globalData && globalData.notification.length > 0) &&
                          globalData.notification.map((notification, index) => (
                            <option key={`notification-${index}`} value={notification.id}>{notification[`label_${lang}`]}</option>
                          ))
                        }
                      </select>
                    </div>

                    <div className={styles.row}>
                      <h6 className={styles.globalTitle}>{t("performance_assessment.tab_apresentation_form.global_label_period_assessment")}</h6>
                    </div>

                    <div className={styles.row}>
                      <div className={styles.colDate}>
                        <label className={styles.label} htmlFor="date_start">{t("performance_assessment.tab_apresentation_form.label_start")}</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs} locale={lang}>
                          <DatePicker
                            label={""}
                            value={formAssessment?.date_start}
                            inputFormat={getDataFormat(lang)}
                            maxDate={moment(formAssessment?.date_end).subtract(1, 'days').format().split("T")[0]}
                            onChange={(value) => {
                              if( moment(value.$d) < moment(formAssessment?.date_end) ){
                                setFormAssessment({...formAssessment, date_start: moment(value.$d).format().split("T")[0]})
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant='outlined'
                                fullWidth
                                {...params}
                                sx={{ height: '48px', fieldset: { border: '1px solid #D6DEEB', borderRadius:'2px', outline: 'none' }, input: { color: '#4F7091', padding: '14px',  borderRadius:'2px', outline: 'none' } }}
                                // error={formErrors.dateStart && true}
                                // helperText={formErrors.dateStart}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className={styles.labelTo}>{t("performance_assessment.tab_apresentation_form.to")}</div>
                      <div className={styles.colDate}>
                        <label className={styles.label} htmlFor="date_end">{t("performance_assessment.tab_apresentation_form.label_end")}</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs} locale={lang}>
                          <DatePicker
                            label={""}
                            value={formAssessment?.date_end}
                            inputFormat={getDataFormat(lang)}
                            minDate={formAssessment?.date_start}
                            onChange={(value) => {
                              if(moment(value.$d) > moment(formAssessment?.date_start)){
                                setFormAssessment({...formAssessment, date_end: moment(value.$d).format().split("T")[0]})
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant='outlined'
                                fullWidth
                                {...params}
                                sx={{ height: '48px', fieldset: { border: '1px solid #D6DEEB', borderRadius:'2px', outline: 'none' }, input: { color: '#4F7091', padding: '14px',  borderRadius:'2px', outline: 'none' } }}
                                // error={formErrors.dateStart && true}
                                // helperText={formErrors.dateStart}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className={styles.row}>
                      <label className={styles.labelText} htmlFor='allow_responses_after_deadline'>
                        <BefectiveCheckbox 
                          id='allow_responses_after_deadline'
                          name='allow_responses_after_deadline'
                          checked={formAssessment?.allow_responses_after_deadline} 
                          onChange={handleChange}/>
                        {t("performance_assessment.tab_apresentation_form.label_not_allowed_reply")}
                      </label>
                    </div>
                  </div>

                  <div className={styles.col50}>
                      <div className={styles.row}>
                        <h6 className={styles.globalTitle}>{t("performance_assessment.tab_apresentation_form.global_label_intro_evaluators")}</h6>
                      </div>
                      <div className={styles.formGroup}>
                        {/* <label className={styles.label} htmlFor="introduction">{t("performance_assessment.tab_apresentation_form.label_intro_evaluators")}</label> */}
                        <div className="text-editor">
                          <QuillToolbar />
                          <ReactQuill
                            theme="snow"
                            value={formAssessment?.introduction}
                            onChange={handleChangeIntroduction}
                            modules={modules}
                            formats={formats}
                          />
                        </div>
                      </div>
                  </div>
                </div>
              </div> 
            </div>

            <div className={styles.card}>
              <div className={styles.container}>
                <div className={styles.row}> 
                    <h6 className={styles.globalTitle}>{t("performance_assessment.tab_apresentation_form.global_label_coverage")}</h6>
                </div>
                <div className={styles.row}>
                  <div className={styles.boxSelect}>
                    <label className={styles.label} htmlFor="performance_assessment_scope_id">{t("performance_assessment.tab_apresentation_form.label_type")}</label>
                    <select style={{width: '350px'}} className={`${styles.customSelect} ${isFocused.performance_assessment_scope_id ? styles.focused : ''} ${isChanged.performance_assessment_scope_id ? styles.changed : ''}`} name="performance_assessment_scope_id" id="performance_assessment_scope_id" value={formAssessment?.performance_assessment_scope_id} onFocus={handleFocusSelect} onBlur={handleBlurSelect} onChange={handleChangeSelect} disabled={formAssessment?.id !== null}>
                      {
                        (globalData && globalData.scopes.length > 0) &&
                        globalData.scopes.map((scope, index) => (
                          <option key={`scope-${index}`} value={scope.id}>{t("performance_assessment.tab_apresentation_form.label_assessment")+" "+scope[`label_${lang}`]}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className={styles.boxSelect}>
                    <label className={styles.label} htmlFor="self_assessment">{t("performance_assessment.tab_apresentation_form.label_auto_evaluation")}</label>
                    <select style={{width: '100px'}} className={`${styles.customSelect} ${isFocused.self_assessment ? styles.focused : ''} ${isChanged.self_assessment ? styles.changed : ''}`} name="self_assessment" id="self_assessment" value={formAssessment?.self_assessment} onFocus={handleFocusSelect} onBlur={handleBlurSelect} onChange={handleChangeSelect} disabled={getCoverageOption(formAssessment?.performance_assessment_scope_id).key === 'ST-180' || formAssessment?.id !== null}>
                      <option value="true">{t("performance_assessment.tab_apresentation_form.option_yes")}</option>
                      <option value="false">{t("performance_assessment.tab_apresentation_form.option_no")}</option>
                    </select>
                  </div>
                  <div className={styles.boxInfo}>
                    <span className={styles.info}><strong>{`${t("performance_assessment.tab_apresentation_form.label_assessment")} ${getCoverageOption(formAssessment?.performance_assessment_scope_id)[`label_${lang}`]}: `}</strong>{getCoverageOption(formAssessment?.performance_assessment_scope_id)[`help_text_${lang}`]}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.card}>
              <div className={styles.container}>
                <div className={styles.row}> 
                    <h6 className={styles.globalTitle}>{t("performance_assessment.tab_apresentation_form.global_label_periodic_assessment")}</h6>
                </div>
                <div className={styles.row}>
                  <div className={styles.boxSelect}>
                    <label className={styles.label} htmlFor="performance_assessment_periodization_id">{t("performance_assessment.tab_apresentation_form.label_periodic_assessment")}</label>
                    <select style={{width: '350px'}} className={`${styles.customSelect} ${isFocused.performance_assessment_periodization_id ? styles.focused : ''} ${isChanged.performance_assessment_periodization_id ? styles.changed : ''}`} name="performance_assessment_periodization_id" id="performance_assessment_periodization_id" value={formAssessment?.performance_assessment_periodization_id} onFocus={handleFocusSelect} onBlur={handleBlurSelect} onChange={handleChangeSelect}>
                      <option value="">{t('global_options.no')}</option>
                      {
                        (globalData && globalData.periodizations.length > 0) &&
                        globalData.periodizations.map((periodization, index) => (
                          <option key={`periodization-${index}`} value={periodization.id}>{periodization[`label_${lang}`]}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className={styles.boxInfo}>
                    <span className={styles.info}>{`${t("performance_assessment.tab_apresentation_form.label_help_start")}: `}<strong>{befectiveFormatDate(formAssessment?.date_start, lang)}</strong>&nbsp;&nbsp;{`${t("performance_assessment.tab_apresentation_form.label_help_end")}: `}<strong>{befectiveFormatDate(formAssessment?.date_end, lang)}</strong></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        {
          currentTab === "form" &&
          <div className={styles.container}>
              <FormHeader   
                currentTab={currentTab}        
                title={`${t("performance_assessment.tab_questions_form.title")} (${skillsAssessment.filter(skill => skill.active).length})`}  
                isPreview={true} 
                handleOpenModalPreview={handleOpenModalPreviewForm} 
                handleCancel={() => updateTabValue("apresentation")}
                handleNext={() => {
                  if(skillsAssessment.filter(skill => skill.active).length <=  0){
                    alertActions.error(t("performance_assessment.messages.select_competencies"))
                    return
                  }
                  updateTabValue("participants")
                }}
                handleClearForm={handleClearForm}
                handleCancelEdit={handleCancelEdit}
              />
              {
                skillsAssessment.length > 0 
                ?
                skillsAssessment.map((skill, index) => (
                  <FormSkillCard hasEdit={formAssessment?.id !== null} key={`form-skill-${index}`} skill={skill} index={index+1} updateSkillsAssessment={updateSkillsAssessment} formAssessment={formAssessment} setFormAssessment={setFormAssessment} />
                ))
                :
                <p className={styles.noSkill}>{t("performance_assessment.messages.none_competencies")}</p>
              }
          </div>
        }

        {
          currentTab === "participants" &&
          <div className={styles.container}>
              <FormHeader           
                currentTab={currentTab}
                title={`${t("performance_assessment.tab_participants_form.title")}`}  
                isPreview={false} 
                handleCancel={() => updateTabValue("form")}
                handleNext={() => {
                  if(formAssessment?.participants.length <=  0){
                    alertActions.error(t("performance_assessment.messages.select_participants"))
                    return
                  }
                  updateTabValue("settings")
                }}                  
                handleClearForm={handleClearForm}
                handleCancelEdit={handleCancelEdit}
              />
            <div className={styles.coverageContainer}>
                <div className={styles.coverageSelectedBox}>
                  <span className={styles.coverageSelectedTitle}>{t("performance_assessment.tab_participants_form.coverage_selected")}: <strong>{getCoverageOption(formAssessment?.performance_assessment_scope_id)[`label_${lang}`]}</strong></span>
                  <BefectiveTooltip
                    title={
                      <Typography component={"div"} variant="caption">
                        {getCoverageOption(formAssessment?.performance_assessment_scope_id)[`help_text_${lang}`]}
                      </Typography>
                    }
                    placement="right-start"
                    arrow
                    interactive
                  >
                    <span style={{ cursor: "pointer", marginTop:"2px", marginLeft:"-4px" }}>
                      &nbsp;&nbsp;
                      <IconInfo />
                    </span>
                  </BefectiveTooltip>
                </div>
                <div className={styles.boxButtonAddColab}>
                  <span>{t("performance_assessment.tab_participants_form.label_evaluated")}:</span>
                  <button onClick={handleOpenParticipantsDrawer}>
                    {t("performance_assessment.tab_participants_form.add_colab")}
                  </button>
                </div>
            </div>
            <EnhancedTable
              columns={columns}
              data={formAssessment?.participants}
              filter={filter}
              onRowClick={(row) => handleOpenModalEvaluatorsEdit(row)}
              actionColumn={(row) => (
                <div>
                  <IconButton 
                    // disabled={row.already_existing}
                    aria-label="delete-participant" 
                    className={"iconButtonDelte"} 
                    size='small'
                    onClick={(e) => { 
                      e.stopPropagation(); 
                      removeParticipant(row) 
                    }}
                    >
                    <Delete fontSize="small" color={"error"}/>
                  </IconButton>
                </div>
              )}
              newItemButton={{
                enable: true,
                label: t("performance_assessment.tab_participants_form.add_colab_to_assessment"),
                onClick: handleOpenParticipantsDrawer
              }}
            />
          </div>
        }

        { 
          currentTab === "settings" &&
          <div className={styles.container}>
             <FormHeader           
                currentTab={currentTab}
                title={`${t("performance_assessment.tab_settings_form.title")}`}  
                isPreview={false} 
                handleCancel={() => updateTabValue("participants")}
                handleSubmit={handleSubmit}
                isEdit={formAssessment?.id ? true : false}
                isSubmitting={isSubmitting}                
                handleClearForm={handleClearForm}
                handleCancelEdit={handleCancelEdit}
              />

              <div className={styles.boxFormSettings}>

              <Grid
                container
                spacing={2}
                component={'div'}
                id='formSettingsAssessment'
              >

                <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'} style={{paddingLeft:'40px'}} paddingRight={'24px'}>
                  <Box>
                    <Typography className={styles.labelSettings}>{t('performance_assessment.tab_settings_form.settings_result_indicator_label')}</Typography>
                    <Typography variant='caption' color='#1E6B7F' fontSize={'12px'} >{t('performance_assessment.tab_settings_form.settings_result_indicator_desc')}</Typography>
                  </Box>
                  <Box className='field'>
                    <select style={{minWidth:'220px'}} className={`${styles.customSelect} ${isFocused.result_indicators_id ? styles.focused : ''} ${isChanged.result_indicators_id ? styles.changed : ''}`} name="result_indicators_id" id="result_indicators_id" value={formAssessment?.result_indicators_id} onFocus={handleFocusSelect} onBlur={handleBlurSelect} onChange={handleChangeSelect}>
                      <option value="" disabled>{t('performance_assessment.tab_participants_form.select')}</option>
                      {
                        (globalData && globalData.result_indicator.length > 0) &&
                        globalData.result_indicator.map((indicator, index) => (
                          <option key={`indicator-${index}`} value={indicator.id}>{indicator.title}</option>
                        ))
                      }
                    </select>
                    {formError.result_indicators_id &&
                      <div className={styles.error}>{formError.result_indicators_id}</div>
                    }
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'} style={{paddingLeft:'40px'}} paddingRight={'24px'}>
                  <Box>
                    <Typography className={styles.labelSettings}>{t('performance_assessment.tab_settings_form.settings_view_table_results_label')}</Typography>
                    <Typography variant='caption' color='#1E6B7F' fontSize={'12px'} >{t('performance_assessment.tab_settings_form.settings_view_table_results_desc')}</Typography>
                  </Box>
                  <Box className='field'>
                    <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                      <FormControlLabel
                        sx={{ margin: 0 }}
                        control={<CustomSwitch sx={{ m: 0 }} checked={formAssessment?.show_result_table} onChange={handleChange} name="show_result_table" />}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'} style={{paddingLeft:'40px'}} paddingRight={'24px'}>
                  <Box>
                    <Typography className={styles.labelSettings}>{t('performance_assessment.tab_settings_form.settings_view_col_score_avg_label')}</Typography>
                    <Typography variant='caption' color='#1E6B7F' fontSize={'12px'} >{t('performance_assessment.tab_settings_form.settings_view_col_score_avg_desc')}</Typography>
                  </Box>
                  <Box className='field'>
                    <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                      <FormControlLabel
                        sx={{ margin: 0 }}
                        control={<CustomSwitch sx={{ m: 0 }} checked={formAssessment?.show_avg_score_result_table} onChange={handleChange} name="show_avg_score_result_table" />}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'} style={{paddingLeft:'40px'}} paddingRight={'24px'}>
                  <Box>
                    <Typography className={styles.labelSettings}>{t('performance_assessment.tab_settings_form.settings_view_chart_radar_label')}</Typography>
                    <Typography variant='caption' color='#1E6B7F' fontSize={'12px'} >{t('performance_assessment.tab_settings_form.settings_view_chart_radar_desc')}</Typography>
                  </Box>
                  <Box className='field'>
                    <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                      <FormControlLabel
                        sx={{ margin: 0 }}
                        control={<CustomSwitch sx={{ m: 0 }} checked={formAssessment?.show_radar} onChange={handleChange} name="show_radar" />}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'} style={{paddingLeft:'40px'}} paddingRight={'24px'}>
                  <Box>
                    <Typography className={styles.labelSettings}>{t('performance_assessment.tab_settings_form.settings_view_cards_stats_label')}</Typography>
                    <Typography variant='caption' color='#1E6B7F' fontSize={'12px'} >{t('performance_assessment.tab_settings_form.settings_view_cards_stats_desc')}</Typography>
                  </Box>
                  <Box className='field'>
                    <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                      <FormControlLabel
                        sx={{ margin: 0 }}
                        control={<CustomSwitch sx={{ m: 0 }} checked={formAssessment?.show_general_stats} onChange={handleChange} name="show_general_stats" />}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'} style={{paddingLeft:'40px'}} paddingRight={'24px'}>
                  <Box>
                    <Typography className={styles.labelSettings}>{t('performance_assessment.tab_settings_form.settings_keep_doing_label')}</Typography>
                    <Typography variant='caption' color='#1E6B7F' fontSize={'12px'} >{t('performance_assessment.tab_settings_form.settings_keep_doing_desc')}</Typography>
                  </Box>
                  <Box className='field'>
                    <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                      <FormControlLabel
                        sx={{ margin: 0 }}
                        control={<CustomSwitch sx={{ m: 0 }} checked={formAssessment?.allowed_todo_keep_doing} onChange={handleChange} name="allowed_todo_keep_doing" />}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'} style={{paddingLeft:'40px'}} paddingRight={'24px'}>
                  <Box>
                    <Typography className={styles.labelSettings}>{t('performance_assessment.tab_settings_form.settings_start_todo_label')}</Typography>
                    <Typography variant='caption' color='#1E6B7F' fontSize={'12px'} >{t('performance_assessment.tab_settings_form.settings_start_todo_desc')}</Typography>
                  </Box>
                  <Box className='field'>
                    <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                      <FormControlLabel
                        sx={{ margin: 0 }}
                        control={<CustomSwitch sx={{ m: 0 }} checked={formAssessment?.allow_todo_should_do} onChange={handleChange} name="allow_todo_should_do" />}
                      />
                    </Box>
                  </Box>
                </Grid>

              </Grid>

              </div>

          </div>
          
        }

        <ApresentationDetailsModal 
          openModal={openModalPreviewApresentation} 
          closeModal={handleCloseModalPreviewApresentation} 
          title={formAssessment.title}
          subtitle={formAssessment.subtitle}
          description={formAssessment.introduction}  
          handleClickStart={handleCloseModalPreviewApresentation}      
        />
        {
          openModalPreviewForm &&
          <FormPreviewModal
            openModal={openModalPreviewForm}
            closeModal={handleCloseModalPreviewForm}
            skills={skillsAssessment.filter(skill => skill.active)}
            updateOptionQuestions={updateOptionQuestions}
          />

        }

        <SubmitEvaluationModal
          openModal={openModalSubmitEvaluation}
          closeModal={handleCloseModalSubmitEvaluation}
          formAssessment={formAssessment}
          lang={lang}
          userDetails={userDetails}
          isSubmitting={isSubmitting}
          submittingFail={submittingFail}          
        />
        {
          openModalEditEvaluators &&
          <EvaluatorsEditModal 
            openModal={openModalEditEvaluators}
            closeModal={handleCloseModalEvaluatorsEdit}
            currentEvaluated={currentEvaluated}
            evaluators={formAssessment?.participants.find(participant => participant.machine_users_id === currentEvaluated.machine_users_id)?.evaluator}
            updateEvaluators={updateEvaluators}
          />
        }
      </main>
      {
        openDrawerParticipants &&
        <ParticipantsDrawer
          openDrawer={openDrawerParticipants}
          closeDrawer={handleCloseParticipantsDrawer}
          addParticipants={addParticipants}
          coverage={getCoverageOption(formAssessment?.performance_assessment_scope_id).key}
          teams={teams}
          offices={globalData?.offices || []}
          autoEvaluation={formAssessment.self_assessment}
          participants={formAssessment.participants}
          alertActions={alertActions}
        />
      }
    </>
  )
}
