import React, { useEffect, useState } from 'react'
import { Drawer, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next';
import { api } from '../../_helpers/api';
import { authHeader, befectiveFormatDate, getMonths } from '../../_helpers';
import styles from './HistoricalOutdoorsDrawer.module.scss'
import { ArrowRight, NotePencil } from 'phosphor-react';
import IconOutdoor from '../_icons/IconOutdoor';
import HistoricalOutdoorsSkeleton from './HistoricalOutdoorsSkeleton';

export default function HistoricalOutdoorsDrawer({
  openDrawer,
  closeDrawer
}) {

  const { t, i18n } = useTranslation("common");
  const [dataDrawer, setDataDrawer] = useState([]);
  const [loading, setLoading] = useState(true);

  const getHistoricalOutdoors = async () => {
    try {
      setLoading(true)

      const response = await api.get(`/stats/my-productivity/inactivity/change-history`, {
        headers: authHeader(),
      })
      setDataDrawer(response.data)

      setLoading(false)
      
    } catch (error) {
      //alertActions.error(error)
      console.log(error)
      setLoading(false)
    }
  }
  useEffect(() => {
    if(openDrawer) {
      getHistoricalOutdoors()
    }
  }, [openDrawer])

  return (
    <Drawer anchor='right' open={openDrawer} onClose={closeDrawer}>
      <div className={styles.boxIconClose}>
        <IconButton aria-label="close" onClick={closeDrawer}>
          <Close fontSize="medium" />
        </IconButton>
      </div>

      <div className={styles.containerDrawer}>
        <header>
          <h2 className={styles.titleDrawer}>
            {t('colab_page.activities.title_drawer')}
          </h2>
        </header>

        <main className={styles.mainDrawerContent}>
            {
              !loading
              ?
                dataDrawer.length > 0 
                ?
                dataDrawer.map((item, index) => (
                  <div className={styles.historyContainer} key={`history-${index}`}>
                    <div className={styles.boxMonthYear}>{`${getMonths(item.month - 1, t)} ${item.year}`}</div>
                    {
                      item.registers.length > 0 &&
                      item.registers.map((reg, index) => (
                        <div key={`register-${index}`} className={styles.boxRegister}>
                          <span className={styles.momentRegister}>{befectiveFormatDate(reg.moment, i18n.language, 'T')}</span>
                          <div className={styles.infoRegister}>
                            <div className={`${styles.register} ${reg.old_efficiency ? styles[reg.old_efficiency] : ''}`}>
                              {/* <NotePencil size={16} color='#4F7091' weight='bold'/> */}
                              <IconOutdoor />
                              <span>{reg.old_label}</span>
                            </div>
                            <ArrowRight size={16} color='#4F7091' weight='bold'/>
                            <div className={`${styles.register} ${reg.new_efficiency ? styles[reg.new_efficiency] : ''}`}>
                              {/* <NotePencil size={16} color='#4F7091' weight='bold'/> */}
                              <IconOutdoor />
                              <span>{reg.new_label}</span>
                            </div>                          
                          </div>
                        </div>                      
                      ))
                    }
                  </div>
                ))
              :
              <div className={styles.noneRegister}>
                {t('colab_page.activities.none_changes')}
              </div>
              :
              <HistoricalOutdoorsSkeleton/>
            }
        </main>
      </div>
        </Drawer>
  )
}
