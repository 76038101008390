import React, { useEffect, useState } from 'react'
import style from './styles.module.scss';
import { authHeader, getMonths, getMonthsAbbreviated, secondsToHm, secondsToHours } from '../../../_helpers';
import IconInfo from '../../_icons/IconInfo';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { api } from '../../../_helpers/api';
import Widget29Skeleton from './Widget29Skeleton';
import ReactApexChart from 'react-apexcharts';
import { CaretDoubleDown, CaretDoubleUp } from 'phosphor-react';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';

export default function Widget29({
  alertActions,
  currentDate,
  width,
  filters
}) {
  const { t, i18n } = useTranslation('common');
  const [fetching, setFetching] = useState(false);
  const [chartData, setChartData] = useState({
    series: [
      {
        name: t("widgets.widget29.percent_productive"),
        data: []
      }
    ],
    options: {
      chart: {
        height: 300,
        type: 'line',
        zoom: {
          enabled: false
        },
        // dropShadow: {
        //   enabled: true,
        //   color: '#000',
        //   top: 18,
        //   left: 7,
        //   blur: 10,
        //   opacity: 0.2
        // },
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '10px',
        },
        formatter: (value) => {
          return `${value}%`;
        }
      },
      stroke: {
        curve: 'smooth'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: [],
        // title: {
        //   text: 'Meses'
        // },
        labels: {
          style: {
            fontSize: '10px',
            colors: '#4F7091'
          },
          formatter: (value) => {
            const [year, month] = String(value).split('-')
            return `${getMonthsAbbreviated(parseInt(month)-1, t)}/${year}`;
          }
          // show: false
        }
      },
      yaxis: {
        // title: {
        //   text: 'Percentual'
        // },
        labels: {
          style: {
            colors: '#4F7091'
          },
          formatter: (value) => {
            return `${value}%`;
          }
          // show: false
        }
      },
      annotations: {
        yaxis: [{
          y: 100,
          borderColor: '#FDBE2B',
          label: {
            borderColor: '#FDBE2B',
            style: {
              color: '#fff',
              background: '#FDBE2B',
            },
            text: '100%',
          }
        }]
      },
      legend: {
        show: false
      },
      tooltip: {
        shared: true
      },
      colors:[]
    }
  })

  const getData = async ({ filterTeams, filterTeammates }) => {
    try {
      setFetching(true)
      const response = await api.get(`/stats/executive/productive-year?current_day=${currentDate.format('YYYY-MM-DD')}${filterTeams}${filterTeammates}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget29!!!')
      }
      const { data } = response

      if (data && data.series.length > 0) {
        const maxTotalPercent = parseInt(Math.max(...data.series))
        setChartData({
          options: {
            chart: {
              height: 300,
              type: 'line',
              zoom: {
                enabled: false
              },
              stacked: false,
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '10px',
              },
              formatter: (value) => {
                return `${value}%`;
              }
            },
            stroke: {
              curve: 'smooth'
            },
            grid: {
              borderColor: '#e7e7e7',
              row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
              },
            },
            markers: {
              size: 1
            },
            xaxis: {
              categories: data.categories,
              labels: {
                style: {
                  fontSize: '10px',
                  colors: '#4F7091'
                },
                formatter: (value) => {
                  const [year, month] = String(value).split('-')
                  return `${getMonthsAbbreviated(parseInt(month)-1, t)}/${year}`;
                }
              }
            },
            yaxis: {
              labels: {
                style: {
                  colors: '#4F7091'
                },
                formatter: (value) => {
                  return `${value}%`;
                }
              },
              max: maxTotalPercent > parseInt(data.target_percent) ? maxTotalPercent : 100,
              tickAmount: 4,
              min: 0
            },
            annotations: {
              yaxis: [{
                y: data.target_percent,
                strokeDashArray: 0,
                borderColor: '#FDBE2B',
                label: {
                  borderColor: '#FDBE2B',
                  style: {
                    color: '#fff',
                    background: '#FDBE2B',
                  },
                  text: `${t('widgets.widget29.goal')}: ${data.target_percent}%`,
                }
              }]
            },
            legend: {
              show: false
            },
            tooltip: {
              shared: true
            },
            colors: ["#22BEAD"]
          },
          series: [
            {
              name: t("widgets.widget29.percent_productive"),
              data: data.series
            }
          ],
        })
      }
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData({ ...filters })
  }, [])

  return (
    !fetching
      ?

      <Grid item xs={width}>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <div className={style.boxTitle}>
                <h6 className={style.title}>{t('widgets.widget29.title')}</h6>
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('widgets.widget29.tooltip')}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo/></div>
                </BefectiveTooltip>
              </div>
            </div>
            <div className={style.chartContainer}>
              <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={300} className={'productiveChart'} />
            </div>
          </div>
        </div>
      </Grid>
      :
      <Grid item xs={width}>
        <Widget29Skeleton />
      </Grid>

  )
}
