import React from 'react'
import { Drawer, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next';
import styles from './InfoHelpDrawer.module.scss'

export default function InfoHelpDrawer({
  openDrawer,
  closeDrawer
}) {

  const { t, i18n } = useTranslation("common");

  return (
    <Drawer anchor='right' open={openDrawer} onClose={closeDrawer}>
      <div className={styles.boxIconClose}>
        <IconButton aria-label="close" onClick={closeDrawer}>
          <Close fontSize="medium" />
        </IconButton>
      </div>

      <main className={styles.mainDrawer}>
        <h5>{t("global_settings.inactivities.drawer_help.title1")}</h5>
        <p>{t("global_settings.inactivities.drawer_help.text1")}</p>
        <h5>{t("global_settings.inactivities.drawer_help.title2")}</h5>
        <h6>{t("global_settings.inactivities.drawer_help.item1.title")}</h6>
        <p>{t("global_settings.inactivities.drawer_help.item1.text")}</p>
        <h6>{t("global_settings.inactivities.drawer_help.item2.title")}</h6>
        <p>{t("global_settings.inactivities.drawer_help.item2.text")}</p>
        <h6>{t("global_settings.inactivities.drawer_help.item3.title")}</h6>
        <p>{t("global_settings.inactivities.drawer_help.item3.text")}</p>
        <h6>{t("global_settings.inactivities.drawer_help.item4.title")}</h6>
        <p>{t("global_settings.inactivities.drawer_help.item4.text")}</p>
        <h6>{t("global_settings.inactivities.drawer_help.item5.title")}</h6>
        <p>{t("global_settings.inactivities.drawer_help.item5.text")}</p>
      </main>
    </Drawer>
  )
}
