import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import style from './styles.module.scss';

import { useTranslation, initReactI18next } from "react-i18next";
import { Link } from 'react-router-dom';
import IconInfo from '../../_icons/IconInfo';
import { Grid, Typography } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { api } from '../../../_helpers/api';
import { authHeader } from '../../../_helpers';
import Top10ActivitiesColabSkeleton from './Top10ActivitiesColabSkeleton';
import moment from 'moment';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#1E6B7F', // Cor de fundo personalizada
    marginRight: 0, // Espaçamento à direita
    maxWidth: '300px'
  },
  arrow: {
    color: '#1E6B7F'
  },
}))(Tooltip);

export default function Top10ActivitiesColab({ width, userId }) {
  const { t, i18n } = useTranslation('common');
  const currentDate = moment()

  const [data, setData] = useState({
    percent_productive: 0,
    percent_neutral: 0,
    percent_unproductive: 0,
    topSite: [],
    topSoftware: [],
    topOffline: []
  });

  const [fetching, setFetching] = useState(true);

  const getData = async () => {
    try {
      setFetching(true)
      const response = await api.get(`/stats/executive/top-10-activities?year_month=${currentDate.format('YYYY-MM')}&current_day=${currentDate.format('YYYY-MM-DD')}&start_date=${currentDate.format('YYYY-MM-DD')}&end_date=${currentDate.format('YYYY-MM-DD')}&users=${userId}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Top10ActivitiesColab!!!')
      }
      setData(response.data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      // alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    !fetching
      ?
      <Grid item xs={width}>
        <div className={style.container}>
          <div>
            <div className={style.boxTitle}>
              <h4>{t('colab_page.last_activities_today.title2')}</h4>
            </div>
            <div className={style.statsBlock}>
              <div className={style.secondaryChart}>
                <div className={style.block}>
                  <div className={style.stats}>
                    <div className={style.labels}>
                      <div className={`${style.label} ${style.related}`}>
                        {t('home.related_to_work')}: <b> {data.percent_productive}%</b>
                      </div>
                      <div className={`${style.label} ${style.neutral}`}>
                        {t('home.neutral')}: <b> {data.percent_neutral}%</b>
                      </div>
                      <div className={style.label}>
                        {t('home.not_related_to_work')}: <b> {data.percent_unproductive}%</b>
                      </div>
                    </div>

                    <div className={style.barchart}>
                      <div className={style.bar} style={{ width: data.percent_productive + '%' }}></div>
                      <div className={`${style.bar} ${style.neutral}`} style={{ width: data.percent_neutral + '%' }}></div>
                      <div className={`${style.bar} ${style.unrelated}`} style={{ width: data.percent_unproductive + '%' }}></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.displayList}>
                <div className={style.list}>
                  <h3>Top 10 Site</h3>
                  <ul>
                    {
                      data.topSite.length > 0
                        ?
                        data.topSite.map((activity, index) => (
                          <li key={`topSite2-${index}`} className={activity.efficience_name}>
                            <div className={style.itemName} title={activity.exhibition_name}>{activity.exhibition_name}</div>
                            <div
                              className={style.number}>{activity.percent}%
                            </div>
                          </li>
                        ))
                        :
                        <p className={'noData'}>{t('no_data')}</p>
                    }
                  </ul>
                </div>
                <div className={style.list}>
                  <h3>Top 10 Software</h3>
                  <ul>
                    {
                      data.topSoftware.length > 0
                        ?
                        data.topSoftware.map((activity, index) => (
                          <li key={`topSoftware2-${index}`} className={activity.efficience_name}>
                            <div className={style.itemName} title={activity.exhibition_name}>{activity.exhibition_name}</div>
                            <div
                              className={style.number}>{activity.percent}%
                            </div>
                          </li>
                        ))
                        :
                        <p className={'noData'}>{t('no_data')}</p>
                    }
                  </ul>
                </div>
                <div className={style.list}>
                  <h3>Top 10 Offline</h3>
                  <ul>
                    {
                      data.topOffline.length > 0
                        ?
                        data.topOffline.map((activity, index) => (
                          <li key={`topOffline2-${index}`} className={activity.efficience_name}>
                            <div className={style.itemName} title={activity.exhibition_name}>{activity.exhibition_name}</div>
                            <div
                              className={style.number}>{activity.percent}%
                            </div>
                          </li>
                        ))
                        :
                        <p className={'noData'}>{t('no_data')}</p>
                    }
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Grid>
      :
      <Grid item xs={width}>
        <Top10ActivitiesColabSkeleton />
      </Grid>
  )
};