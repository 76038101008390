import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader, orderData, secondsToHm } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import ShareModal from '../../_components/_shared/ShareModal';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import moment from 'moment';
import { CardsHeaderTransactional } from '../../_components/reports/transactional/CardsHeader';
import TableReportsTransactionalPagination from '../../_components/reports/transactional/TableReportsTransactionalPagination';
import { ArrowFatUp, ArrowUp } from 'phosphor-react';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ProductiveReportsTransactionalPage(props) {
  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, shareActions, teammates, filter } = props

  const [fetching, setFetching] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true);
  const [dataTransactional, setDataTransactional] = useState({
    data: [],
    stats: null
  });
  const [pageCount, setPageCount] = useState(0);
  const [pageCountRows, setPageCountRows] = useState(0);
  const [dateRef, setDateRef] = useState(moment(new Date()).startOf('month').format('YYYY-MM-DD'));
  const [pageSize, setPageSize] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [startDate, setStartDate] = useState(new Date(moment().startOf('month')).toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date(moment()).toISOString().split('T')[0]);


  async function getDataTransactional(date_start, date_end, pageIndex, pageSize, isLoading, teams = null, teammates = null) {
    try {

      if (isLoading) {
        setFetching(true)
      }

      const queryTeams = teams ? `&teams=${teams.map(t=>t.id).join(',')}` : ''
      const queryTeammates = teammates ? `&users=${teammates.map(t=>t.id).join(',')}` : ''

      const response = await api.get(`/report/transactional?page=${pageIndex}&per_page=${pageSize}&date_start=${date_start}&date_end=${date_end}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      setDataTransactional(response.data)
      setPageCount(Math.ceil(parseInt(response.data.countRows) / pageSize))
      setPageCountRows(parseInt(response.data.countRows))
      setPageSize(pageSize)
      setPageIndex(pageIndex)
      setFetching(false)

    } catch (error) {
      console.log(error)
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t('reports_transactional.table.col_colab'),
        accessor: 'username'
      },
      {
        Header: t('reports_transactional.table.col_team'),
        accessor: 'group_name'
      },
      {
        Header: t('reports_transactional.table.col_percent_productive'),
        accessor: 'percent_productive_time',
        Cell: ({ value }) => {
          return `${value || "0"}%`
        }
      },
      {
        Header: t('reports_transactional.table.col_percent_transactional'),
        accessor: 'percent_transactional_time',
        Cell: ({ value }) => {
          return `${value || "0"}%`
        }
      },
      {
        Header: t('reports_transactional.table.col_not_percent_transactional'),
        accessor: 'percent_not_transactional_time',
        Cell: ({ value }) => {
          return `${value || "0"}%`
        }
      },
    ];
  }, []);


  useEffect(() => {
    getDataTransactional(startDate, endDate, 0, localStorage.getItem('pageSizeStorage') || 20, false, null, null)
    document.title = 'Befective | ' + t('productive_reports.card_reports.transactional');
  }, [])

  const getDataTransactionalFiltered = (pageIndex, pageSize, filter) => {
    let start_date = startDate;
    let end_date = endDate;

    if (filter.date) {
      start_date = filter.date.startDate;
      end_date = filter.date.endDate;
    }

    setStartDate(start_date)
    setEndDate(end_date)

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getDataTransactional(start_date, end_date, pageIndex, pageSize, true, teams, teammates)
  }

  function closeShareModal() {
    shareActions.closeModal()
  }

  return (
    <>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterWho={'multi'}
          filterWhen={'multi'}
          filterWhat={'disabled'}
          startDate={startDate}
          endDate={endDate}
          enableButtonRedirectChartTransactional={true}
        />
        <div className={style.containerCheckinPage}>
          {
            !fetching
              ?
              <>
                <CardsHeaderTransactional alertActions={alertActions} userDetails={userDetails} stats={dataTransactional.stats} />
                <TableReportsTransactionalPagination
                  columns={columns}
                  data={dataTransactional.data}
                  count={pageCount}
                  filter={filter}
                  getData={getDataTransactionalFiltered}
                  pageCountRows={pageCountRows}
                  alertActions={alertActions}
                  dateRef={dateRef}
                  startDate={startDate}
                  endDate={endDate}
                />
              </>
              :
              <GlobalSkeleton totalRowsStats={1} totalTables={1} heightTable={600} />
          }

        </div>

      </section>

      <div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
        closeShareModal()
      }}></div>

      <section className={'modal' + (props.share.modal ? ' open' : '')}>
        <ShareModal 
          filters={{
            ...filter,
            date: {
              startDate: startDate,
              endDate: endDate
            }
          }} 
          reports={'transactionalReports'} 
          paginationFilters={{pageSize: pageSize, pageIndex: pageIndex}}
          modelExportFullExcel={"transactionalTimeUsers"}
          language={userDetails.language || i18n.language}
        />
      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductiveReportsTransactionalPage)