import { Tooltip, Typography } from "@mui/material";
import { Gantt, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { CaretDown, CaretUp } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader, befectiveFormatDate, getStartEndDateForProject, initTasks } from '../../_helpers';
import { api } from '../../_helpers/api';
import { ViewSwitcher } from './ViewSwitcher';
import './styles.scss';

const Header = (props) => {
  console.log(props)
  return <div><div>Nome</div><div>Início</div><div>Término</div></div>
}
function ProjectManagementReportsTimelinePage(props) {
  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, responsibles, usersActions } = props
  const [view, setView] = useState(ViewMode.Month);
  const [tasks, setTasks] = useState(initTasks());
  const [data, setData] = useState(null)

  const [isChecked, setIsChecked] = useState(false);

  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleTaskChange = (task) => {
    console.log("On date change Id:" + task.id);
    let newTasks = tasks.map(t => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map(t =>
          t.id === task.project ? changedProject : t
        );
      }
    }
    setTasks(newTasks);
  };

  const handleTaskDelete = (task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter(t => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task) => {
    setTasks(tasks.map(t => (t.id === task.id ? task : t)));
    console.log("On progress change Id:" + task.id);
  };

  const handleDblClick = (task) => {
    alert("On Double Click event Id:" + task.id);
  };

  const handleClick = (task) => {
    console.log("On Click event Id:" + task.id);
  };

  const handleSelect = (task, isSelected) => {
    console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task, workspaceId) => {

    const nd = data.map((ws) => {

      return {
        ...ws, boards: ws.id === workspaceId ? ws.boards.map((board) => {
          if (board.id === task.id) {
            return { ...board, hideChildren: !Boolean(board.hideChildren) }
          }
          return { ...board }
        }) : ws.boards
      }
    })

    setData(nd)
  };

  const handleExpanderClick2 = (task) => {
    setTasks(tasks.map(t => (t.id === task.id ? task : t)));

  };
  const [loading, setLoading] = useState(false);

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/project-management/report/workspace/gantt`, {
        headers: authHeader()
      })
      // console.log("/data -> ",data)
      const new_data = data.map(item => {
        return { ...item, boards: formatDataToGanttFormat(item.boards) }
      })

      // const dateStartCurrent = new Date("2023-08-01T03:00:00.000Z")
      // const dateEndCurrent = new Date("2023-09-15T03:00:00.000Z")
      console.log(new_data.filter(t => t.boards.length > 0))
      setData(new_data.filter(t => t.boards.length > 0))

      setLoading(false)
    } catch (error) {
      console.error(error.response)
      //alertActions.error(t('project_management.errors.load'))
      setLoading(false)
    }
  }

  const formatDataToGanttFormat = (data) => {
    return data.map(item => {
      const dateStartCurrent = item.start ? new Date(item.start) : new Date()
      const dateEndCurrent = item.end ? new Date(item.end) : new Date()
      if (item.type === 'project') {
        return {
          id: item.id,
          name: item.name,
          full_name: item.full_name,
          progress: item.progress,
          type: item.type,
          start: new Date(dateStartCurrent.getFullYear(), dateStartCurrent.getMonth(), dateStartCurrent.getDate()),
          end: new Date(dateEndCurrent.getFullYear(), dateEndCurrent.getMonth(), dateEndCurrent.getDate()),
          hideChildren: true
        }
      }
      return { ...item, start: new Date(dateStartCurrent.getFullYear(), dateStartCurrent.getMonth(), dateStartCurrent.getDate()), end: new Date(dateEndCurrent.getFullYear(), dateEndCurrent.getMonth(), dateEndCurrent.getDate()) }
    })
  }

  useEffect(() => {
    getData()
    document.title = 'Befective | ' + t('breadcrub.project_management.timeline');
  }, [])


  return (
    <section className="mainContent productivity">

      <Toolbar />

      <Filterbar
        filterWho={'disabled'}
        filterWhen={'disabled'}
        filterWhat={'disabled'}
      />

      <div className={'containerBoardsPage'}>
        <div className="Wrapper">
          <ViewSwitcher
            onViewModeChange={viewMode => setView(viewMode)}
            onViewListChange={setIsChecked}
            isChecked={isChecked}
            view={view}
          />
          {data && data.map(item => (
            <div key={item.id} className="boxWorkspace">
              <Typography variant="h6" sx={{ color: '#1E6B7F', marginBottom:'6px' }}>{item.title}</Typography>
              <Gantt
                tasks={item.boards}
                viewMode={view}
                // onDateChange={handleTaskChange}
                // onDelete={handleTaskDelete}
                // onProgressChange={handleProgressChange}
                // onDoubleClick={handleDblClick}
                // onClick={handleClick}
                // onSelect={handleSelect}
                onExpanderClick={(task) => handleExpanderClick(task, item.id)}
                listCellWidth={isChecked ? "155px" : ""}
                columnWidth={columnWidth}
                locale={i18n.language}
                projectBackgroundColor="#f7bb53"
                projectProgressColor="#f7bb53"
                projectProgressSelectedColor="#f7bb53"
                projectBackgroundSelectedColor="#f7bb53"
                headerHeight={50}
                TaskListHeader={(data) => {
                  return <div className="TaskHeaderList" style={{ height: `${data.headerHeight - 2}px`, display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: data.rowWidth, borderRight: '2px solid #e4e4e4', paddingLeft:'4px' }}>{t('project_management.reports.time_line.name')}</div>
                    <div style={{ width: data.rowWidth, borderRight: '2px solid #e4e4e4', paddingLeft:'4px' }}>{t('project_management.reports.time_line.start')}</div>
                    <div style={{ width: data.rowWidth, paddingLeft:'4px' }}>{t('project_management.reports.time_line.end')}</div>
                  </div>
                }}

                TaskListTable={(data) => {
                  return data.tasks.map((task, index) => {
                    // console.log(task)
                    return <div className="TaskTableList" style={{ height: `${data.rowHeight}px`, display: 'flex', alignItems: 'center', backgroundColor: `${index % 2 == 0 ? '#ffffff' : '#f5f5f5'}` }}>

                      <div style={{ width: data.rowWidth, paddingLeft:'4px' }}>{task.type === 'project' ? (task.hideChildren && task.hideChildren === true) ? <CaretDown style={{ cursor: "pointer" }} size={14} weight="bold" onClick={() => data.onExpanderClick(task)} /> : <CaretUp style={{ cursor: "pointer" }} size={14} weight="bold" onClick={() => data.onExpanderClick(task)} /> : <></>}

                        <Tooltip title={task.name} arrow>
                          <span
                            style={{
                              marginLeft: `${task.type === 'project' ? '4px' : '8px'}`
                            }}
                          >{task.name}</span>                          
                        </Tooltip>

                      </div>
                      <div style={{ width: data.rowWidth, paddingLeft:'4px' }}>{befectiveFormatDate(task.start, i18n.language)}</div>
                      <div style={{ width: data.rowWidth, paddingLeft:'4px' }}>{befectiveFormatDate(task.end, i18n.language)}</div>
                    </div>
                  })

                }}
                TooltipContent={(data) => {

                  return <div key={data.id} style={{ backgroundColor: "white", fontSize: `${data.fontSize}`, width: `250px`, fontFamily: `${data.fontFamily}`, padding: "10px", boxShadow: `5px 5px 10px rgba(0, 0, 0, 0.3)`, borderRadius: "10px" }}>
                    <p>{data.task.full_name}</p>
                    <p>{befectiveFormatDate(data.task.start, i18n.language)} - {befectiveFormatDate(data.task.end, i18n.language)} </p>
                    {/* <p>{data.task.progress}</p>  */}
                  </div>
                }}

              />
            </div>
          ))
          }
          {/* 
          <Gantt

            tasks={tasks}
            viewMode={view}
            // onDateChange={handleTaskChange}
            // onDelete={handleTaskDelete}
            // onProgressChange={handleProgressChange}
            // onDoubleClick={handleDblClick}
            // onClick={handleClick}
            // onSelect={handleSelect}
            onExpanderClick={handleExpanderClick2}
            listCellWidth={isChecked ? "155px" : ""}
            columnWidth={columnWidth}

          /> */}

          {/* <h3>Gantt With Limited Height</h3>
          <Gantt
            tasks={tasks}
            viewMode={view}
            onDateChange={handleTaskChange}
            onDelete={handleTaskDelete}
            onProgressChange={handleProgressChange}
            onDoubleClick={handleDblClick}
            onClick={handleClick}
            onSelect={handleSelect}
            onExpanderClick={handleExpanderClick}
            listCellWidth={isChecked ? "155px" : ""}
            rowHeight={40}
            ganttHeight={300}
            columnWidth={columnWidth} 
          /> */}
        </div>
      </div>

    </section>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    responsibles: state.users.responsibles
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagementReportsTimelinePage)