import React from 'react'
import style from './AvgValueHour.module.scss'
import { befetiveFormatCurrency } from '../../../_helpers'

export default function AvgValueHour({
  lang='en',
  avg_value_hour=0,
  avg_value_hour_percent=0,
  target_avg_hour_value=0
}) {
  return (
    <div className={`${style.boxAvgValue} ${avg_value_hour > target_avg_hour_value ? style.danger : style.success}`}>
      <span>
        {befetiveFormatCurrency(avg_value_hour || 0, lang, true)}
      </span>
      <div className={style.percent}>
        {avg_value_hour_percent}%
      </div>
    </div>
  )
}
