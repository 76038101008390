import { Tooltip, withStyles } from "@material-ui/core";

const TooltipBlue = withStyles((theme) => ({
	tooltip: {
			backgroundColor: '#187DFF', // Cor de fundo personalizada
			marginRight: 0, // Espaçamento à direita
			maxWidth: '300px'
	},
	arrow: {
			color: '#187DFF'
	},
}))(Tooltip);

const TooltipGreen = withStyles((theme) => ({
	tooltip: {
			backgroundColor: '#22BEAD', // Cor de fundo personalizada
			marginRight: 0, // Espaçamento à direita
			maxWidth: '300px'
	},
	arrow: {
			color: '#22BEAD'
	},
}))(Tooltip);

const TooltipRed = withStyles((theme) => ({
	tooltip: {
			backgroundColor: '#FC6662', // Cor de fundo personalizada
			marginRight: 0, // Espaçamento à direita
			maxWidth: '300px'
	},
	arrow: {
			color: '#FC6662'
	},
}))(Tooltip);

export { TooltipBlue, TooltipGreen, TooltipRed };