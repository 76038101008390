import React from 'react'
import styles from './PieHourPercent.module.scss'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'

export default function PieHourPercent({
  percent,
  hours,
  total_hours,
  type = 'working_day'
}) {
  return (
    <div className={styles.containerProductivity}>
      <div className={styles.boxCircularProgress}>
        <CircularProgressbar
          value={Number(percent)}
          strokeWidth={50}
          styles={buildStyles({
            strokeLinecap: "butt",
            pathColor: type === 'working_day' ? "#187DFF" : "#22BEAD",
            trailColor: "#ccc",
          })}
        />
      </div>
      <div>{`${hours}h / ${total_hours}h`}</div>
    </div>
  )
}
