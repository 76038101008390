import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { useTranslation, initReactI18next } from "react-i18next";
import { bindActionCreators } from "redux";
import moment from "moment";
import { useTable, useSortBy, useFilters } from "react-table";

import { activitiesActions, alertActions, filterActions } from "../../_actions";

import IconSoftware from "../_icons/IconSoftware";
import IconSite from "../_icons/IconSite";
import IconOutdoor from "../_icons/IconOutdoor";

import { authHeader, secondsToHm } from "../../_helpers";
import EditIcon from "@material-ui/icons/Edit";

import { IconButton, Slide, Tooltip, Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import IconClose from "../_icons/IconClose";
import Backdrop from "@material-ui/core/Backdrop";
import { api } from "../../_helpers/api";
import { CircularProgress } from "@mui/material";

import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import allLocales from "@fullcalendar/core/locales-all";
import IconInfo from "../_icons/IconInfo";
import styles from "./InactivityFull.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  rootTab: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    width: "37ch",
  },
  modal: {
    "&:focus": {
      outline: "none",
    },
    outline: "none",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "400px",
    outline: "none",
    borderRadius: "8px",
  },
}));

function InactivityFull(props) {
  const { t, i18n } = useTranslation("common");

  let activities = props.activities || [];

  const classes = useStyles();
  const [inactivitiesData, setInactivitiesData] = useState([]);
  const data = useMemo(() => (activities.rows ? activities.rows : []));

  const [currentTrackedEdit, setCurrentTrackedEdit] = useState(null);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [label, setLabel] = useState(null);

  const closeModalEdit = () => {
    setOpenModalEdit(false);
    setCurrentTrackedEdit(null);
    setLabel(null);
  };

  const changeLabel = (event) => {
    setLabel(
      activities?.labelsOutdoorGroup?.find(
        (item) => item?.outdoor_id === parseInt(event.target.value)
      )
    );
  };

  const editTracked = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitting(true);

      const res = await api.put(
        `/stats/my-productivity/inactivity/?organization_id=${activities?.user?.org_id}`,
        {
          agent_collector_id: currentTrackedEdit?.id,
          outdoor_id: label?.outdoor_id,
          efficiency_id: label?.efficiency_id,
          category_id: label?.category_id,
        },
        { headers: authHeader() }
      );

      if (res.status === 200) {
        let date = moment(new Date()).format("YYYY-MM-DD");

        if (props.filter.singleDate) {
          date = props.filter.singleDate;
        }

        props.getActivities(date, date);
        // props.alertActions.success(t('activities.success.update_closed'))
      }
      setIsSubmitting(false);
      closeModalEdit();
    } catch (error) {
      setIsSubmitting(false);
      console.log(error.response);
      //props.alertActions.error(t('activities.error.update_closed'))
    }
  };

  const columns = React.useMemo(
    () => [
      {
        id: "exhibitionName",
        Header: t("activities.activity"),
        accessor: (c) => c.exhibitionName.toLowerCase(),
        Cell: (d) => {
          let iconType = "";

          if (d.row.original.trackedType == "Software") {
            iconType = <IconSoftware />;
          }
          if (d.row.original.trackedType == "Site") {
            iconType = <IconSite />;
          }
          if (d.row.original.trackedType == "Outdoor") {
            iconType = <IconOutdoor />;
          }

          let current = d.row.original;

          return (
            <div
              className="activity noLink"
              title={d.row.original.exhibitionName}
            >
              <div className="icon">{iconType}</div>
              {d.row.original.exhibitionName.substring(0, 50)}
            </div>
          );
        },
      },
      {
        Header: t("activities.type"),
        accessor: "trackedType",
      },
      {
        Header: t("activities.datetime_start"),
        accessor: "timeStart",
      },
      {
        Header: t("activities.datetime_end"),
        accessor: "timeEnd",
      },
      {
        Header: t("activities.total_time"),
        accessor: "duration",
        Cell: (d) => {
          return (
            <div className="totalTime">
              <div className="time center">{secondsToHm(d.value)}</div>
            </div>
          );
        },
      },
      {
        Header: t("activities.actions"),
        accessor: "actions",

        Cell: (d) => {
          return (
            <div>
              <button
                onClick={() => {
                  setCurrentTrackedEdit(d.row.original);
                  setOpenModalEdit(true);
                }}
              >
                <EditIcon />
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  // a custom render function
  function renderEventContent(eventInfo) {
    const { id, start, end, title } = eventInfo.event;
    const eventFinder = inactivitiesData.find(
      (item) => item.id == id
    )
    return (
      <div>
        <span className={`${styles.event} ${styles[eventFinder?.efficiencyName || 'neutral']}`}>
          {title +
            " - " +
            secondsToHm(
              moment(end).diff(
                moment(start),
                "seconds"
              )
            )}
        </span>
        {/* <b>{moment(eventInfo.event.start).format('HH:mm')+' - '+ moment(eventInfo.event.end).format('HH:mm')}</b> */}
      </div>
    );
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableMultiSort: true,
        initialState: {
          sortBy: [
            {
              id: "datetimeStart",
              desc: true,
            },
          ],
        },
      },
      useFilters,
      useSortBy
    );

  const handleEventClick = (clickInfo) => {
    clickInfo.jsEvent.preventDefault();
    setCurrentTrackedEdit({
      id: clickInfo.event.id,
      title: clickInfo.event.title,
      duration: secondsToHm(
        moment(clickInfo.event.end).diff(
          moment(clickInfo.event.start),
          "seconds"
        )
      ),
      timeStart: moment(clickInfo.event.start).format("HH:mm:ss"),
      timeEnd: moment(clickInfo.event.end).format("HH:mm:ss"),
      datetimeStart: clickInfo.event.start,
      datetimeEnd: clickInfo.event.end
    });
    setOpenModalEdit(true);
  };

  // const handleEvents = (events) => {
  //     console.log(events)
  //     setCurrentTrackedEdits(events)
  // }

  const getCalendarData = useCallback(
    async (fetchInfo, successCallback, failureCallback) => {
      try {
        const start_date = moment(fetchInfo.start).format("YYYY-MM-DD");
        const end_date = moment(fetchInfo.end).format("YYYY-MM-DD");
        const response = await api.get(
          `/stats/my-productivity/inactivity?start_date=${start_date}&end_date=${end_date}&organization_id=${props.organizationId}&hash=${props.hash}`,
          {
            headers: authHeader(),
          }
        );

        setInactivitiesData(response.data.rows);
        
        successCallback(
          response.data.rows.map((event) => ({
            id: event.id,
            title: event.exhibitionName,
            start: event.datetimeStart,
            end: event.datetimeEnd,
            efficiency: event.efficiencyName
          }))
        );
      } catch (error) {
        console.log(error);
        failureCallback(error);
      }
    },
    [props.organizationId] // Dependências necessárias
  );
  // useEffect(()=> {
  //     getCalendarData()
  // }, [])

  return (
    <div className="box noBg">
      {!props.showCalendar ? (
        <div className="dataTable activities">
          {data.length > 0 && (
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <div className="arrowDown"></div>
                            ) : (
                              <div className="arrowUp"></div>
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          {data.length == 0 && <p className={"noData"}>{t("no_data")}</p>}
        </div>
      ) : (
        <div id="containerCalendarInactivities">
          <FullCalendar
            key="inactivitiesCalendar"
            locales={allLocales}
            locale={i18n.language}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{ left: "title", right: "today prev,next" }}
            height={680}
            events={getCalendarData}
            initialView="dayGridMonth"
            editable={false}
            selectable={false}
            selectMirror={false}
            dayMaxEvents={true}
            eventContent={renderEventContent}
            eventClick={handleEventClick}
            />

        </div>
      )}

      {currentTrackedEdit && (
        <Modal
          disableEnforceFocus={true}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModalEdit}
          onClose={closeModalEdit}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          sx={{ border: "none" }}
          style={{ outline: 0 }}
        >
          <Slide
            direction="down"
            in={openModalEdit}
            style={{ outline: 0 }}
            mountOnEnter
            unmountOnExit
          >
            <div className={"containerModalEditTracked"}>
              <button onClick={closeModalEdit} className="close">
                <IconClose />
              </button>

              <form onSubmit={editTracked}>
                <main>
                  <div className="fieldset">
                    <div className="col">
                      <label htmlFor="label" style={{ display: "flex" }}>
                        {t("activities.label_question")}
                        {props.showCalendar && (
                          <span style={{ marginLeft: 4 }}>
                            <Tooltip
                              title={
                                <>
                                  <Typography variant="subtitle2">
                                    {t("activities.activity") +
                                      ": " +
                                      currentTrackedEdit?.title}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {t("activities.datetime_start") +
                                      ": " +
                                      currentTrackedEdit?.timeStart}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {t("activities.datetime_end") +
                                      ": " +
                                      currentTrackedEdit?.timeEnd}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {t("activities.total_time") +
                                      ": " +
                                      currentTrackedEdit?.duration}
                                  </Typography>
                                </>
                              }
                              placement="right"
                              arrow
                            >
                              <IconButton
                                aria-label="help"
                                style={{
                                  backgroundColor: "transparent",
                                  padding: 0,
                                }}
                                variant="raised"
                              >
                                <IconInfo />
                              </IconButton>
                            </Tooltip>
                          </span>
                        )}
                      </label>
                      <select
                        name="label"
                        id="label"
                        onChange={changeLabel}
                        required
                      >
                        <option value="" disabled selected hidden>
                          {t("activities.label_select")}
                        </option>
                        {activities?.labelsOutdoorGroup?.length > 0 &&
                          activities?.labelsOutdoorGroup.map((label) => (
                            <option
                              key={label?.outdoor_id}
                              value={label?.outdoor_id}
                            >
                              {label?.exhibition_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <footer>
                    <div className="buttonActionsGroup">
                      <button
                        className="btnCancel"
                        type="button"
                        onClick={closeModalEdit}
                      >
                        {t("button.cancel")}
                      </button>
                      <button
                        type="submit"
                        disabled={isSubmitting || label === null}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={15} />
                        ) : (
                          t("button.save")
                        )}
                      </button>
                    </div>
                  </footer>
                </main>
              </form>
            </div>
          </Slide>
        </Modal>
      )}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    activitiesActions: bindActionCreators(activitiesActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    activities: state.activities,
    filter: state.filter,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InactivityFull);
