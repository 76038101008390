import React, { useEffect, useState } from 'react'
import styles from './ActionPlanForm.module.scss'
import { useTranslation } from 'react-i18next';
import { Avatar, Box, CircularProgress, Grid, TextField } from '@mui/material';
import { authHeader, befectiveFormatDate, getAvatarUrl, history, numberToPercentString } from '../../../_helpers';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import { api } from '../../../_helpers/api';
import PopoverConfirm from '../../teams_settings/PopoverConfirmDelete';
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { Delete, Edit } from '@material-ui/icons';
import NewItemModal from './NewItemModal';
import { Menu, Slider, withStyles } from '@material-ui/core';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';
import { get } from 'config';

const PrettoSlider = withStyles({
  root: {
    height: 10,
    width: 100
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '3px solid currentColor',
    marginTop: -1,
    marginLeft: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 10,
    borderRadius: 4,
  },
  rail: {
    height: 10,
    borderRadius: 4,
    backgroundColor: '#E4EAEF'
  },
})(Slider);

export default function ActionPlanForm({
  selectData = null,
  actionPlanData = null,
  teammates = [],
  evaluatedId = null,
  actionPlanId=null,
  getDataForm=null,
  new_action_plan=null,
  alertActions,
  assessments = [],
  evaluateds = [],
  getActionPlanCreateSelectData = async () => {},
}) {
  
  const { t, i18n } = useTranslation("common");
  const [disableSelectStatus, setDisableSelectStatus] = useState(false);
  const optionsResponsibles = teammates.sort((a, b) => a.name.localeCompare(b.name)).map((item) => {
    return {
      value: item.id,
      label: item.name
    }
  })
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModalAddItem, setOpenModalAddItem] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [itemToUpdateProgress, setItemToUpdateProgress] = useState(null);
  const [isUpdatingProgress, setIsUpdatingProgress] = useState(null);

  const [anchorElConfirmDeleteItem, setAnchorElConfirmDeleteItem] = useState(null);
  const openConfirmDeleteItem = Boolean(anchorElConfirmDeleteItem);
  const idConfirmDeleteItem = openConfirmDeleteItem ? 'popover-confirm-delete-item' : undefined;

  const [currentValueProgress, setCurrentValueProgress] = useState(0);
  const [anchorElItemUpdateProgress, setAnchorElItemUpdateProgress] = useState(null);

  const [form, setForm] = useState({
    id: actionPlanData?.id || null,
    performance_assessment_evaluated_id: actionPlanData?.performance_assessment_evaluated_id || evaluatedId,
    performance_assessment_action_plan_status_id: actionPlanData?.performance_assessment_action_plan_status_id || "",
    responsible_id: actionPlanData?.responsible_id || "",
    name: actionPlanData?.name || "",
    objective: actionPlanData?.objective || "",
    expected_outcome: actionPlanData?.expected_outcome || "",
    date_start: actionPlanData?.date_start || null,
    date_end: actionPlanData?.date_end || null,
    todo_list: actionPlanData?.todo_list || [],
    assessment: actionPlanData?.assessment || "",
    evaluated: actionPlanData?.evaluated || "",
  });

  const [formError, setFormError] = useState({
    performance_assessment_action_plan_status_id: "",
    responsible_id: "",
    name: "",
    objective: "",
    expected_outcome: "",
    date_start: "",
    date_end: "",
    assessment: "",
    evaluated: ""
  });

  const handleOpenModalAddItem = (item=null) => {
    setOpenModalAddItem(true)
    if(item){
      setItemSelected(item)
    }
  }

  const handleCloseModalAddItem = () => {
    setOpenModalAddItem(false)
    setItemSelected(null)
  }
  
  
  function getDataFormat(lang) {
    if (lang === 'en') {
      return "MM/DD/YYYY"
    } else {
      return "DD/MM/YYYY"
    }
  }  

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value
    })

    setFormError({
      ...formError,
      [name]: ""
    })
  }

  const handleChangeAssessment = async (value) => {
    setForm({
      ...form,
      assessment: value,
      evaluated: ""
    })
    setFormError({
      ...formError,
      assessment: ""
    })
    await getActionPlanCreateSelectData(value)
  }

  const validateForm = (form) => {
    const errors = {};

    if(form.responsible_id === "") {
      errors.responsible_id = t("validation.required")
    }

    if(form.objective === "") {
      errors.objective = t("validation.required")
    }

    if(form.expected_outcome === "") {
      errors.expected_outcome = t("validation.required")
    }

    if(!form.date_start) {
      errors.date_start = t("validation.required")
    }

    if(!form.date_end) {
      errors.date_end = t("validation.required")
    }

    if(new_action_plan && new_action_plan === "new_action_plan" && form.assessment === "") {
      errors.assessment = t("validation.required")
    }

    if(new_action_plan && new_action_plan === "new_action_plan" && form.evaluated === "") {
      errors.evaluated = t("validation.required")
    }

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const saveActionPlan = async () => {
    try{
      setIsSubmitting(true)

      const evaluated_id = new_action_plan && new_action_plan === "new_action_plan" ? form.evaluated : form.performance_assessment_evaluated_id

      const payload = {
        ...form,
        performance_assessment_evaluated_id: evaluated_id
      }
      const response = await api.post("/performance-assessment/action-plan", payload, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error('Erro to create action plan')
      }

      if(new_action_plan && new_action_plan === "new_action_plan") {
        alertActions.success(t('performance_assessment.messages.success_create_action_plan'))
        const { action_plan_id } = response.data
        history.push(action_plan_id ?`/performance-assessment/action-plan/details/${action_plan_id}` : '/performance-assessment/action-plan')
        setIsSubmitting(false)
        return
      }

      if(actionPlanData?.id === undefined) {
        getDataForm()
        alertActions.success(t('performance_assessment.messages.success_create_action_plan'))
      }
      alertActions.success(t('performance_assessment.messages.success_update_action_plan'))
      setIsSubmitting(false)
    }catch(error) {
      console.log(error)
      setIsSubmitting(false)
      alertActions.error(error.response)
    }
  }

  
  const handleClickConfirmDeleteItem = (event, item) => {
    setItemSelected(item)
    setAnchorElConfirmDeleteItem(event.currentTarget);
  };

  const handleCloseConfirmDeleteItem = () => {
    setAnchorElConfirmDeleteItem(null)
    setItemSelected(null)
  };
  
  const handleConfirmDeleteItem = async () => {
    try {
      if (!itemSelected.id) {
        throw new Error('Item to delete not found')
      }

      const res = await api.delete(`/performance-assessment/action-plan/item/${itemSelected.id}`, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro to delete item')
      }
      await getDataForm(false)
      handleCloseConfirmDeleteItem()
      
      alertActions.success(t('performance_assessment.messages.success_delete_item'))
    } catch (error) {
      console.error(error)
      alertActions.error(t('performance_assessment.messages.error_delete_item'))
    }
  }

  const handleClickMenuUpdateProgress = (event, item) => {
    setItemToUpdateProgress(item);
    setCurrentValueProgress(parseInt(item?.progress))
    setAnchorElItemUpdateProgress(event.currentTarget);
  };

  const handleCloseMenuUpdateProgress = () => {
    setCurrentValueProgress(0)
    setAnchorElItemUpdateProgress(null)
    setItemToUpdateProgress(null)
  }

  const updateProgressItem = async () => {
    try {

      if (currentValueProgress !== null && Number(currentValueProgress) === Number(itemToUpdateProgress.progress)) {
        return
      }

      if (currentValueProgress === null) {
        return
      }

      setIsUpdatingProgress(true)

      const payload = {
        progress: currentValueProgress
      };


      const result = await api.patch(`performance-assessment/action-plan/item/${itemToUpdateProgress.id}`, payload, {
        headers: authHeader()
      });

      setIsUpdatingProgress(false)
      handleCloseMenuUpdateProgress()
      getDataForm(false)
    } catch (error) {
      setIsUpdatingProgress(false)
      console.log('Response Error -> ', error);
      // //alertActions.error(error)
    }
  }

  const handleSliderChangeCommitted = () => {
    if (currentValueProgress !== null) {
      updateProgressItem(); // Envia a requisição de atualização quando o usuário solta o slider
    }
  };

  // useEffect(() => {
  //   if (currentValueProgress) {
  //     const delayDebounceFn = setTimeout(() => {
  //       updateProgressItem();
  //     }, 500)

  //     return () => clearTimeout(delayDebounceFn)

  //   }
  // }, [currentValueProgress])

  useEffect(() => {
    if (Object.keys(formError).length === 0 && submitted) {
      saveActionPlan()
    } else {
      setSubmitted(false)
    }
  }, [formError, submitted])


  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={styles.cardContainer}>
          <header className={styles.cardHeader}>
            {
              (new_action_plan && new_action_plan === "new_action_plan") 
              ? 
              <div className={styles.infoUser}>
                <h4 className={styles.title}>{t("performance_assessment.page_action_plan.header.create_new_action_plan")}</h4> 
              </div>
              : 
              <div className={styles.infoUser}>
                <div className={styles.avatarContainer}>
                  <Avatar
                    alt={actionPlanData?.evaluated_name}
                    src={
                      getAvatarUrl({
                        name: actionPlanData?.evaluated_name || "-----",
                      },
                        "187DFF"
                      )
                    }
                    style={{ width: 72, height: 72 }}
                    title={actionPlanData?.evaluated_name || "-----"}
                  />
                </div>
                <div className={styles.infoUserContent}>
                  <h6 className={styles.title}>{actionPlanData?.evaluated_name}</h6>
                  <div className={styles.listInfo}>
                    <span>{t("performance_assessment.page_action_plan.header.position")}: {actionPlanData?.office || "-----"}</span>
                    <span>{t("performance_assessment.page_action_plan.header.created_at")}: {actionPlanData?.created_at ? befectiveFormatDate(actionPlanData?.created_at, i18n.language) : "-----"}</span>
                  </div>
                </div>
              </div>
            }
            <div className={styles.boxButtonHeader}>
              <button className={`${styles.button} ${styles.buttonCreate}`} type="submit" disabled={isSubmitting}>
                {
                  isSubmitting
                    ? <CircularProgress size={18} />
                    : <span>{t("performance_assessment.actions.save")}</span>
                }
              </button>
            </div>
          </header>
          
          <Box component={"main"} sx={{ flexGrow: 1, padding: '24px' }}>
            {
              (new_action_plan && new_action_plan === "new_action_plan") && (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Box className={styles.field}>
                      <label htmlFor='assessment'>{t("performance_assessment.page_action_plan.form.assessment_field")}*</label>
                      
                      <select  
                        className={styles.customSelect} 
                        name="assessment" 
                        id="assessment" 
                        onChange={(e) => handleChangeAssessment(e.target.value)} 
                        value={form?.assessment}                  
                        >

                        <option value="" disabled>{t("performance_assessment.page_action_plan.form.select")}</option>

                        {assessments?.map((item) => (
                          <option key={`assesment_${item.value}`} value={item.value}>{item.label}</option>
                        ))}

                      </select>

                      {formError?.assessment && <span className={styles.error}>{formError.assessment}</span>}
                      
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={styles.field}>
                      <label htmlFor='evaluated'>{t("performance_assessment.page_action_plan.form.evaluated_field")}*</label>
                      
                      <select  
                        className={styles.customSelect} 
                        name="evaluated" 
                        id="evaluated" 
                        onChange={handleChange} 
                        value={form?.evaluated}      
                        disabled={!form?.assessment}            
                        >

                        <option value="" disabled>{t("performance_assessment.page_action_plan.form.select")}</option>

                        {evaluateds?.map((item) => (
                          <option key={`evaluated_${item.value}`} value={item.value}>{item.label}</option>
                        ))}

                      </select>

                      {formError?.evaluated && <span className={styles.error}>{formError.evaluated}</span>}
                      
                    </Box>
                  </Grid>
                </Grid>
              )
            }

            <Grid container spacing={1}>
              <Grid item xs={5}>
                <Box className={styles.field}>
                  <label htmlFor='responsible_id'>{t("performance_assessment.page_action_plan.form.responsible_field")}*</label>
                  
                  <select  
                    className={styles.customSelect} 
                    name="responsible_id" 
                    id="responsible_id" 
                    onChange={handleChange} 
                    value={form?.responsible_id}                  
                    >

                    <option value="" disabled>{t("performance_assessment.page_action_plan.form.responsible_placeholder")}</option>

                    {optionsResponsibles?.map((item) => (
                      <option key={`responsible_${item.value}`} value={item.value}>{item.label}</option>
                    ))}

                  </select>

                  {formError?.responsible_id && <span className={styles.error}>{formError.responsible_id}</span>}
                  
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className={styles.field}>
                  <label htmlFor='performance_assessment_action_plan_status_id'>Status*</label>
                  <select
                      className={styles.customSelect} 
                      id="performance_assessment_action_plan_status_id"
                      name="performance_assessment_action_plan_status_id"
                      onChange={handleChange}
                      value={form?.performance_assessment_action_plan_status_id}
                      disabled={disableSelectStatus}
                  >
                      {
                        (selectData && selectData.status.length > 0) &&
                        selectData.status?.map((status, index) => (
                          <option key={`status-${index}`} value={status.id}>{status[`label_${i18n.language}`]}</option>
                        ))
                      }
                  </select>                
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={styles.field}>
                  <label htmlFor='date_start'>{t("performance_assessment.page_action_plan.form.start_date_field")}*</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs} locale={i18n.language}>
                    <DatePicker
                      label={""}
                      value={form?.date_start}
                      inputFormat={getDataFormat(i18n.language)}
                      onChange={(value) => {
                        if(value === null) return
                        setForm({...form, date_start: moment(value.$d).format().split("T")[0]})
                        setFormError({...formError, date_start: ''})
                      }}
                      renderInput={(params) => (
                        <TextField
                          id='date_start'
                          variant='outlined'
                          fullWidth
                          {...params}
                          sx={{ height: '48px', fieldset: { border: '1px solid #D6DEEB', borderRadius:'2px', outline: 'none' }, input: { color: '#4F7091', padding: '14px',  borderRadius:'2px', outline: 'none' } }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formError?.date_start && <span className={styles.error}>{formError.date_start}</span>}
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box className={styles.field}>
                  <label htmlFor='date_end'>{t("performance_assessment.page_action_plan.form.end_date_field")}*</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs} locale={i18n.language}>
                    <DatePicker
                      label={""}
                      value={form?.date_end}
                      inputFormat={getDataFormat(i18n.language)}
                      onChange={(value) => {
                        if(value === null) return
                        setForm({...form, date_end: moment(value.$d).format().split("T")[0]})
                        setFormError({...formError, date_end: ''})
                      }}
                      renderInput={(params) => (
                        <TextField
                          id='date_end'
                          variant='outlined'
                          fullWidth
                          {...params}
                          sx={{ height: '48px', fieldset: { border: '1px solid #D6DEEB', borderRadius:'2px', outline: 'none' }, input: { color: '#4F7091', padding: '14px',  borderRadius:'2px', outline: 'none' } }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {formError?.date_end && <span className={styles.error}>{formError.date_end}</span>}
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box className={styles.field} mb={'6px'}>
                  <label htmlFor='objective'>{t("performance_assessment.page_action_plan.form.target_plan_field")}*</label>
                  <input
                    id='objective'
                    name='objective'
                    type='text'
                    placeholder={t("performance_assessment.page_action_plan.form.target_plan_placeholder")}
                    onChange={handleChange}
                    value={form?.objective}
                  />                
                  {formError?.objective && <span className={styles.error}>{formError.objective}</span>} 
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={styles.field}>
                  <label htmlFor='expected_outcome'>{t("performance_assessment.page_action_plan.form.result_expected_field")}*</label>
                  <input
                    id='expected_outcome'
                    name='expected_outcome'
                    type='text'
                    placeholder={t("performance_assessment.page_action_plan.form.result_expected_placeholder")}
                    onChange={handleChange}
                    value={form?.expected_outcome}
                  />

                  {formError?.expected_outcome && <span className={styles.error}>{formError.expected_outcome}</span>}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>

        <div className={styles.cardContainer}>
          <header className={styles.cardHeader}>
            <div className={styles.boxInfoActionPlan}>
              <div className={styles.circularProgressGeneral}>
                <CircularProgressbarWithChildren
                  value={actionPlanData?.todo_list_stats?.progress_percent || 0}
                  strokeWidth={10}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: actionPlanData?.todo_list_stats?.progress_percent >= 100 ? "#22BEAD" : "#187DFF",
                    trailColor: "#eee",
                  })}>
                  <span style={{ fontSize: 14, fontWeight: 500, color: actionPlanData?.todo_list_stats?.progress_percent >= 100 ? "#22BEAD" : "#187DFF" }}>
                    {`${actionPlanData?.todo_list_stats?.progress_percent || 0}%`}
                  </span>
                </CircularProgressbarWithChildren>
              </div>
              <div className={styles.boxInfo}>
                <h6 className={styles.title}>{`${t("performance_assessment.page_action_plan.checklists.action_plan")} (${actionPlanData?.todo_list_stats?.step_stats || '0/0'})`}</h6>
                <p className={styles.subtitle}>{`${t("performance_assessment.page_action_plan.checklists.last_update")}: ${actionPlanData?.todo_list_stats?.last_updated ? befectiveFormatDate(actionPlanData?.todo_list_stats?.last_updated, i18n.language, 'T') : '-----'}`}</p>
              </div>
            </div>
            {
              actionPlanData?.id
              ? <button 
                  className={`${styles.button} ${styles.buttonCreate}`} 
                  type="button" 
                  onClick={() => handleOpenModalAddItem(null)}
                  >
                  <span>{t("performance_assessment.actions.add_item")}</span>
                </button>
              : 
              <BefectiveTooltip title={t("performance_assessment.actions.tooltip_alert_add_item")}>
                 <button 
                  className={`${styles.button} ${styles.buttonCreate}`} 
                  type="button" 
                  disabled={true} 
                  >
                  <span>{t("performance_assessment.actions.add_item")}</span>
                </button>
              </BefectiveTooltip>
            }
          </header>
          
          <table className={styles.table}>
            <thead>
              <tr>
                <th>{t("performance_assessment.page_action_plan.checklists.what_todo")}</th>
                <th>{t("performance_assessment.page_action_plan.checklists.how_todo")}</th>
                <th>{t("performance_assessment.page_action_plan.checklists.responsible")}</th>
                <th>{t("performance_assessment.page_action_plan.checklists.priority")}</th>
                <th>{t("performance_assessment.page_action_plan.checklists.progress")}</th>
                <th style={{ width: '0px'}}/>
              </tr>
            </thead>
            <tbody>
              {
                actionPlanData?.todo_list && actionPlanData?.todo_list?.length > 0
                ?
                actionPlanData?.todo_list?.map((item, index) => (
                  <tr key={`item-${index}`}>
                    <td className='cellShrinkTable' title={item?.what_todo}>{item?.what_todo}</td>
                    <td className='cellShrinkTable' title={item?.how_todo}>{item?.how_todo}</td>
                    <td className='cellShrinkTable' title={item?.responsible_username}>{item?.responsible_username}</td>
                    <td>
                      <div className={`${styles.boxPriority} ${styles[item?.priority_key || 'default']}`}>
                        {item?.priority_key ? t(`performance_assessment.page_action_plan.modal_add_item.option_${item?.priority_key}`) : '----'}
                      </div>
                    </td>
                    <td>
                      <div className={`boxCellCircularProgress ${styles.hoverBoxProgress}`} onClick={(e) => handleClickMenuUpdateProgress(e, item)}>
                        <div className={`containerCircularProgress`}>
                          <CircularProgressbar
                            value={parseInt(item?.progress || 0)}
                            strokeWidth={50}
                            styles={buildStyles({
                              strokeLinecap: "butt",
                              pathColor: item?.progress && item?.progress >= 100 ? "#22BEAD" : "#187DFF",
                              trailColor: "#E4EAEF",
                            })}
                          />
                        </div>
                        <div>{parseInt(item?.progress || 0)}%</div>
                      </div>
                    </td>
                    <td>
                      <div className={styles.boxButtonsOptions}>
                        <button
                            className={styles.buttonRemoveItem}
                            type='button'
                            onClick={(e) => handleClickConfirmDeleteItem(e, item)}
                        >
                            <Delete style={{color:"#FC6662"}} fontSize='small' />
                        </button>
                        <button
                            className={styles.buttonEditItem}
                            type='button'
                            onClick={() => handleOpenModalAddItem(item)}
                        >
                            <Edit style={{color:"#187DFF"}} fontSize='small'/>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
                :
                <tr>
                  <td colSpan={6} className={styles.noDataItem}>{t("performance_assessment.page_action_plan.checklists.no_data_item")}</td>
                </tr>
              }              
            </tbody>
          </table>
        </div>
      </form>

      <NewItemModal
        openModal={openModalAddItem}
        closeModal={handleCloseModalAddItem}
        actionPlanId={actionPlanData?.id}
        itemToEdit={itemSelected}
        updateData={getDataForm}
        alertActions={alertActions}
        optionsResponsibles={optionsResponsibles}
      />

       {/* Start Confirm Dialog Delete Item */}
       <PopoverConfirm
        idConfirm={idConfirmDeleteItem}
        openConfirm={openConfirmDeleteItem}
        anchorEl={anchorElConfirmDeleteItem}
        handleClose={handleCloseConfirmDeleteItem}
        handleConfirm={handleConfirmDeleteItem}
        title={t('performance_assessment.messages.delete_item')}
      />
      {/* End Confirm Dialog Delete Item */}

      {itemToUpdateProgress &&
        (
          <Menu
            className="menu-task-on-table"
            id={`menu-options-goals`}
            anchorEl={anchorElItemUpdateProgress}
            keepMounted
            open={Boolean(anchorElItemUpdateProgress)}
            onClose={handleCloseMenuUpdateProgress}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ horizontal: "center" }}
            style={{ width: 100, padding: 10, }}
          >
            <div className={`box-progress ${isUpdatingProgress && 'disabled-div'}`}>
              <label
                style={{
                  color: currentValueProgress >= 100 ? '#22BEAD' : '#187DFF'
                }}
              >
                {currentValueProgress !== null ? String(currentValueProgress) + '%' : (itemToUpdateProgress ? itemToUpdateProgress.progress : 0) + '%'}
              </label>
              <PrettoSlider
                style={{ color: currentValueProgress >= 100 ? '#22BEAD' : '#187DFF'}}
                width={16}
                height={16}
                aria-label="pretto slider"
                value={currentValueProgress !== null ? currentValueProgress : (itemToUpdateProgress ? itemToUpdateProgress.progress : 0)}
                onChange={(event, newValue) => setCurrentValueProgress(newValue)}                
                onMouseUp={handleSliderChangeCommitted} // Ao soltar o slider
                onTouchEnd={handleSliderChangeCommitted} // Para dispositivos móveis
              />
            </div>
          </Menu>
        )
      }

    </>
  )
}
