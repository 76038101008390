import React, { useState, useEffect} from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import Moment from "moment";
import { statsActions } from "../../../_actions";
import { historicalActions } from "../../../_actions";
import { secondsToHm } from "../../../_helpers";
import IconDate from "../../_icons/IconDate";
import IconArrow from "../../_icons/IconArrow";


function TransactionalChartSummaryHour(props) {

  const { t, i18n } = useTranslation("common");
  Moment.locale(t("date.locale"));

  const { data , back} = props;

  const history = useHistory();

  const [DisplayHoursHTML, setDisplayHoursHTML] = useState([]);

  function renderChart(data) {

    const OutlineHTML = [];
    const HoursHTML = [];
    const MaxHours = 60;

    for (let item of data.data) {
      let totalTransactional = 0;
      let totalNoTransactional = 0;      
      let TotalTime = 3600;
      let noBorder = "";
      let nothing = "";
      let hourFormated = Moment.utc(item.hour).format("HH:mm");

      const itemTotalTransactional = parseInt(item.total_transactional);
      const itemTotalNoTransactional = parseInt(item.total_no_transactional);

      if (MaxHours > 0 && itemTotalTransactional > 0) {
        totalTransactional = Math.round(
          (itemTotalTransactional / TotalTime) * 100
        );
      }

      if (MaxHours > 0 && itemTotalNoTransactional > 0) {
        totalNoTransactional = Math.round(
          (itemTotalNoTransactional / TotalTime) * 100
        );
      }

      if (totalTransactional + totalNoTransactional < 5) {
        noBorder = "no-border";
      }

      if (TotalTime == 0) {
        nothing = "nothing";
      }

      HoursHTML.push(
        <div className={"day " + nothing}>
          <div className="labelBox">
            <div className="header">
              <div className="icon">
                <IconDate />
              </div>
              {hourFormated}
            </div>
            <div className="related">
              {t("reports_transactional.chart.transactional")}:{" "}
              {secondsToHm(itemTotalTransactional)}
            </div>
            <div className="unrelated">
              {t("reports_transactional.chart.not_transactional")}:{" "}
              {secondsToHm(itemTotalNoTransactional)}
            </div>
          </div>
          <div
            className="bar"
          >
            <div
              className={"unrelated " + noBorder}
              style={{ height: totalNoTransactional + "%" }}
            ></div>
            <div
              className={"related " + noBorder}
              style={{ height: totalTransactional + "%" }}
            ></div>
          </div>
          <div className="labelDay">{hourFormated}</div>
        </div>
      );
    }

    setDisplayHoursHTML(HoursHTML);

    const Fraction = Math.round(MaxHours / 9);

    for (let n = 0; n < 10; n++) {
      let hour = Fraction * n;

      if (n == 9) {
        OutlineHTML.push(
          <div className="hour">
            <span>{MaxHours}</span>
          </div>
        );
      } else {
        OutlineHTML.push(
          <div className="hour">
            <span>{hour}</span>
          </div>
        );
      }
    }
  }

  useEffect(() => {
    if (data) {
      renderChart(data);
    }
  }, [data]);

  return (
    <div className="box historical noBg">
      <div className="chartWrapper">
        <div className="internalBreadcrub">
          <button
            className="back"
            onClick={() => {
              history.push("/productive/reports/transactional/chart-view");
              back();
            }}
          >
            <IconArrow />
          </button>
          <h3>
            {Moment.utc(props.day).format("DD/MM/YYYY")} -{" "}
            {data.stats.username}{" "}
            {data.stats.start
              ? " - " +
                t("historical.started") +
                ": " +
                Moment.utc(data.stats.start).format("HH:mm:ss") +
                " | " +
                t("historical.ended") +
                ": " +
                Moment.utc(data.stats.end).format("HH:mm:ss")
              : " - " + t("no_data")}
          </h3>
        </div>
        <div className={"historicalChart days"}>
          <div className="outline">
            <div className="hour">
              <span>0</span>
            </div>
            <div className="hour">
              <span>10</span>
            </div>
            <div className="hour">
              <span>20</span>
            </div>
            <div className="hour">
              <span>30</span>
            </div>
            <div className="hour">
              <span>40</span>
            </div>
            <div className="hour">
              <span>50</span>
            </div>
            <div className="hour">
              <span>60</span>
            </div>
            <div className="timeLabel">min</div>
          </div>
          <div className={"days hours"}>{DisplayHoursHTML}</div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    historicalActions: bindActionCreators(historicalActions, dispatch),
    statsActions: bindActionCreators(statsActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    historical: state.historical,
    filter: state.filter,
    userDetails: state.authentication.userDetails,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionalChartSummaryHour);
