import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import Toolbar from '../../_components/_shared/Toolbar'
import TeamsSkeleton from '../../_components/settings/teams/TeamsSkeleton'
import DrawerCreate from '../../_components/settings/teams/DrawerCreate'
import DrawerInvite from '../../_components/settings/teams/DrawerInvite'
import DrawerEdit from '../../_components/settings/teams/DrawerEdit'


import { useTranslation } from "react-i18next";

import { alertActions, filterActions, settingsActions, teamsActions } from '../../_actions';
import { teammatesActions } from '../../_actions';

import IconClose from '../../_components/_icons/IconClose';
import FilterbarTeams from '../../_components/_shared/FilterbarTeams';
import { api } from '../../_helpers/api';
import { authHeader, befectiveFormatDate } from '../../_helpers';
import CompanyInfoHeader from '../../_components/teams_settings/CompanyInfoHeader';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Filterbar from '../../_components/_shared/Filterbar';
import TeamCollaboratorsTable from '../../_components/teams_settings/TeamCollaboratorsTable';
import TeamInfoHeader from '../../_components/teams_settings/TeamInfoHeader';
import { Box } from '@mui/material';
import Header from '../../_components/switch_page/Header';


function TeamsColabSettingsPage(props) {
  const { t, i18n } = useTranslation('common');

  const { id } = useParams()

  const { teamsActions, teammatesActions, userDetails, alertActions, settingsActions, loadingTeams, loadingTeammates, filter, teams, teammates, filterActions} = props;

  const [drawer, setDrawer] = useState(false)
  const [isShowingDrawerInvite, setDrawerInvite] = useState(false)
  const [isShowingDrawerEdit, setDrawerEdit] = useState(false)
  const [inviteCurrent, setInviteCurrent] = useState()
  const [editCurrent, setEditCurrent] = useState()
  const [inactiveTeammates, setInactiveTeammates] = useState([])
  const [isTeamsSelected, setIsTeamsSelected] = useState(true)
  const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true)
  const [value, setValue] = useState('1');
  const [data, setData] = useState(null)
  const [selectedRows, setSelectedRows] = useState([]);
  const [fetching, setFetching] = useState(true)
  const [pageCount, setPageCount] = useState(0);
  const [pageCountRows, setPageCountRows] = useState(0);
  const [activeFilter, setActiveFilter] = useState(0);

  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };

  function openDrawer(data) {

    setDrawer(true)

    if (data.type == "invite") {
      setDrawerInvite(true)
      setDrawerEdit(false)
      setInviteCurrent(data)
      setEditCurrent()
    }

    if (data.type == "edit") {
      teammatesActions.getTeammate(data.id)
      setDrawerInvite(false)
      setDrawerEdit(true)
      setInviteCurrent()
      setEditCurrent(data)
      setValue("1")
    }

  }

  function closeDrawer() {
    setDrawer(false)
    setDrawerInvite(false)
    setDrawerEdit(false)
    setInviteCurrent(null)
    setEditCurrent(null)
  }

  async function getInactiveTeammates() {
    try {
      const response = await api.get(
        `/teammates/inactive_teammates?organization_id=${userDetails.organizationId}`,
        {
          headers: authHeader()
        }
      )

      setInactiveTeammates(response.data)
    } catch (error) {
      console.log(error)
      alertActions.error(t('teams.get_inactive_erro'))
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t('settings.teams.colabs'),
        accessor: 'username',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => {
          return value == true ? t(`settings.teams.status_active`) : t(`settings.teams.status_inactive`);
        },
      },
      {
        Header: t('settings.teams.profile'),
        accessor: 'profile_name',
        Cell: (data) => {

          const { profile_name, profile_key, is_default_profile } = data.row.original

          if(!profile_key) return '------'
          
          if(is_default_profile === true){
            return t(`settings.teams.profiles.${profile_key}`)
          }
          return profile_name || '-----'
        },
      },
      {
        Header: 'Token',
        accessor: 'token',
      },
      {
        Header: t('settings.teams.last_access'),
        accessor: 'last_sign_in_at',
        Cell: ({ value }) => {
          return value ? befectiveFormatDate(value, i18n.language, 'T') : '------'
        },
      },
    ];
  }, []);

  const getTeamCollaborators = async ({ pageIndex, pageSize=localStorage.getItem('pageSizeStorage') || 20, isLoading, teammates='', activeFilter=''  }) => {
    try {
      if (isLoading) {
        setFetching(true)
      }
      const queryTeammates = (teammates && teammates.length > 0) ? `&users=${teammates.map(t => t.id).join(',')}` : ''

      let queryActive = '';

      if(activeFilter == 1){
        queryActive = `&filter_active=${true}`
      }
      if(activeFilter == 2){
        queryActive = `&filter_active=${false}`
      }


      const res = await api.get(`/group/${id}/?page=${pageIndex}&per_page=${pageSize}${queryTeammates}${queryActive}`, { headers: authHeader() })
      if (res.status !== 200) {
        throw new Error('Erro get teams')
      }
      setData(res.data.team)
      setPageCount(Math.ceil(parseInt(res.data.countRows) / pageSize))
      setPageCountRows(parseInt(res.data.countRows))
    } catch (error) {
      console.error(error)
    } finally {
      setFetching(false)
    }
  }

  useEffect(() => {
    let teammates = null;
    let active_filter = null;

    if (filter.teammates) {
      teammates = filter.teammates;
    }

    if(activeFilter){
      active_filter = activeFilter
    }

    getTeamCollaborators({ pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20, isLoading: false, teammates: teammates, activeFilter: activeFilter})
    

  }, [filter, activeFilter]);

  useEffect(() => {
    document.title = 'Befective | ' + t('menu.teams');
    teamsActions.getTeams()
    teammatesActions.getTeammates(id)
    settingsActions.getCompanyInformation()
    filterActions.clear("who");
    getInactiveTeammates()
  }, []);


  return (
    <Box bgcolor="#F5F6F9" width="100%" display="flex" flexDirection="column">
      <Header toBackUrl={"/global-settings/teams"} />
      {/* <AlertBox /> */}

      <section className="mainContent">
        {/* <Toolbar /> */}
        <Filterbar
          filterWho={'multi'}
          hideTeams={true}
          showButtonNewCollab={true}
          filterWhen={'disabled'}
          showShareBtn={false}
          openDrawer={(data) => {
            openDrawer(data)
          }}
          dataTeam={data}
          // routeBackToPage={"/settings/teams"}
          urlSettingsTeam={`/global-settings/teams/${id}/configuration?to_back=teammates`}
          disabledFetchingTeamsAndTeammates={true}
        />
        {/* PAGE STARTS */}
        <div className="mainPage profile">
          <div className="nocolumn" style={{ background: 'none' }}>
            <div className={'tab full active'}>
              {
                (fetching || loadingTeams || loadingTeammates)
                  ? (
                    <TeamsSkeleton />
                  ) : (
                    <>
                      <TeamInfoHeader
                        organization={data}
                        selectedRows={selectedRows}
                        alertActions={alertActions}
                        getColabsData={getTeamCollaborators}
                        teams={teams}
                        teammates={teammates}
                        setSelectedRows={setSelectedRows}
                        activeFilter={activeFilter}
                        setActiveFilter={setActiveFilter}
                        userDetails={userDetails}
                      />
                      <TeamCollaboratorsTable
                        columns={columns}
                        data={data.colabs}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        count={pageCount}
                        getTeamCollaboratorsData={getTeamCollaborators}
                        openDrawer={(data) => {
                          openDrawer(data)
                        }}
                        pageCountRows={pageCountRows}
                        activeFilter={activeFilter}
                        userDetails={userDetails}
                      />
                    </>
                  )
              }
            </div>
          </div>
        </div>
        {/* PAGE ENDS */}
      </section>

      <div
        className={'overlay' + (drawer ? ' open' : '')}
        onClick={() => {
          setDrawer(false)
        }}>
      </div>

      <section className={'drawer drawer600' + (drawer ? ' open' : '')}>
        <button
          onClick={() => {
            setDrawer(false)
            if (!isTeamsSelected)
              setValue("1")
          }}
          className="close">
          <IconClose />
        </button>

        {
          (isShowingDrawerInvite && props.companyInformation) &&
          <DrawerInvite
            current={inviteCurrent}
            closeDrawer={() => {
              closeDrawer()
            }}
            companyInformation={props.companyInformation}
            getCompanyInformation={settingsActions.getCompanyInformation}
          />
        }

        {
          isShowingDrawerEdit &&
          <DrawerEdit
            current={editCurrent}
            setInactiveTeammates={setInactiveTeammates}
            inactiveTeammates={inactiveTeammates}
            isTeamsSelected={isTeamsSelected}
            value={value}
            handleChangeValue={handleChangeValue}
            closeDrawer={() => {
              closeDrawer()
            }}
            getCompanyInformation={settingsActions.getCompanyInformation}
            userDetails={userDetails}
          />
        }

      </section>

    </Box>
  )
};

function mapDispatchToProps(dispatch) {

  return {
    teamsActions: bindActionCreators(teamsActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch),
    settingsActions: bindActionCreators(settingsActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch)
  }
}

function mapStateToProps(state) {

  return {
    token: state.authentication.user.accessToken,
    loadingTeams: state.teams.loading,
    loadingTeammates: state.teammates.loading,
    filter: state.filter,
    permissions: state.authentication.permissions,
    userDetails: state.authentication.userDetails,
    companyInformation: state.settings.companyInformation,
    teams: state.teams.payload,
    teammates: state.teammates.payload
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(TeamsColabSettingsPage)
