// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VpvQJ1PlzVgKOhpSFziLEA\\=\\= {\n  display: flex;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/_components/reports/mood_new/FragmentsMoodAvg.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;AACF","sourcesContent":[".containerFragments{\n  display: flex;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerFragments": "VpvQJ1PlzVgKOhpSFziLEA=="
};
export default ___CSS_LOADER_EXPORT___;
