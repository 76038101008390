import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import {
	PublicClientApplication,
	EventType
} from '@azure/msal-browser';



import common_pt from "./i18n/common.pt.json";
import common_en from "./i18n/common.en.json";
import common_es from "./i18n/common.es.json";

import { store } from './_helpers';
import { App } from './pages';

import msalConfig from './msal-config';

// setup fake backend
import { configureFakeBackend } from './_helpers';
// import Hotjar from './_helpers/Hotjar';

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		interpolation: { escapeValue: false },
		resources: {
			en: {
				common: common_en
			},
			pt: {
				common: common_pt
			},
			es: {
				common: common_es
			},
		},
		fallbackLng: 'en'
	});
//configureFakeBackend();
// Testing deploy

const msalInstance = new PublicClientApplication({
	auth: {
		clientId: msalConfig.appId,
		redirectUri: msalConfig.redirectUri
		// authority: msalConfig.authority ,
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: false
	}
});

const accounts = msalInstance.getAllAccounts();
if (accounts && accounts.length > 0) {
	msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
		// Set the active account - this simplifies token acquisition
		const authResult = event.payload;
		msalInstance.setActiveAccount(authResult.account);
	}
});

render(
	<Provider store={store}>
		<I18nextProvider i18n={i18n}>
			{/* <Hotjar /> */}
			<App pca={msalInstance} />
		</I18nextProvider>
	</Provider>,
	document.getElementById('app')
);
