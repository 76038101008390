import React from 'react'
import { Chat } from 'phosphor-react'
import styles from './ButtonAlertInfo.module.scss'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

export default function ButtonAlertInfo({modeObject = {type: null, action: () => {}}, total_alerts = 0, handleClick = () => {}}, currentMonth) {
  const { t } = useTranslation('common')
  return (
    <div className={styles.buttonContainer}>
      {
        (modeObject.type === 'users' && total_alerts === 0)
        ?
          (
            currentMonth === moment().format('YYYY-MM') &&
            <button 
              className={`${styles.buttonAlertInfo} ${styles.active}`}
              onClick={(e)=>{
                e.stopPropagation()
                modeObject.action()
              }}
              >
              <div>{t('reports_mood.send_message')}</div>
            </button> 
          )
        :
        <button 
          className={`${styles.buttonAlertInfo} ${total_alerts > 0 && styles.active}`}
          onClick={(e)=>{
            e.stopPropagation()
            handleClick()
          }}
          >
          <div>{total_alerts}</div>
          <Chat size={12} weight="regular" />
        </button>
      }
    </div>
  )
}
