import React from 'react'
import style from './styles.module.scss';
import { Box, Grid, Skeleton } from '@mui/material';
import { Card, CardContent } from '@material-ui/core';

export default function Widget36Skeleton() {

  return (
    <div className={style.container}>
      <Grid container spacing={1}>
        {
          Array.from(Array(6).keys()).map((_, index) => (
            <Grid item xs={2} key={`card-${index}`}>
              <Card
                variant="outlined"
                className={style.cardContainer}
              >
                 <CardContent className={`${style.cardHeader}`}>
                    <Skeleton variant='circular' width={20} height={20} />
                    <h6 className={`${style.titleCard}`}>
                      <Skeleton variant='rounded' width={60} height={22} />
                    </h6>
                    <span className={style.subtitleCard}>
                      <Skeleton variant='rounded' width={100} height={10} />
                    </span>
                  </CardContent>
              </Card>
            </Grid>
          ))
        }
      </Grid>
    </div>
  )
}
