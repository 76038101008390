import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt-BR';
import en from 'date-fns/locale/en-US';

import "react-datepicker/dist/react-datepicker.css";
import { addDays, subYears } from 'date-fns';
import Moment from 'moment';

import { filterActions } from '../../../_actions';
import { statsActions } from '../../../_actions';
import { teamsActions } from '../../../_actions';
import { teammatesActions } from '../../../_actions';

import { useTranslation, initReactI18next } from "react-i18next";
import useOutsideClick from "../../../_helpers/useOutsideClick";


import IconClear from '../../_icons/IconClear'


function WhenMulti(props) {
    const { t, i18n } = useTranslation('common');

    registerLocale('es', es)
    registerLocale('pt', pt)
    registerLocale('en', en)

    const { filterActions } = props;
    const { userDetails, resetOneYear, cleanWhenNull } = props

    let filter = props.filter || {};

    let defaultStartDate = props.startDate || null;
    let defaultEndDate = props.endDate || null;

    let limit = props.limit || null;


    const [minDate, setMinDate] = useState(props.minDate || null);
    const [maxDate, setMaxDate] = useState(props.maxDate || null);

    const [whenFilter, setShowWhenFilter] = useState(true);

    const totalDays = props.totalDays || 31

    if (limit) {

        if (filter.date) {
            if (!props.maxDate) {
                if (new Date(Moment(filter.date.startDate).add(totalDays, 'd')) < new Date()) {
                    setMaxDate(new Date(Moment(filter.date.startDate).add(totalDays, 'd')));
                } else {
                    setMaxDate(new Date());
                }
            }


            setMinDate(new Date(Moment(filter.date.endDate).subtract(totalDays, 'd')));


        } else {
            setMinDate(new Date(Moment().subtract(totalDays, 'd')));
        }

    }


    const [ManualWhenValue, setManualWhenValue] = useState('');

    const refFilter = useRef();

    const [filterWhen, setWhen] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const renderDayContents = (day) => {

        return <span>{day}</span>;
    };

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        if (end) {
            setEndDate(end);

        } else {
            setEndDate();
        }

        if (!props.withoutMax && !props.maxDate) {
            if (new Date(Moment(start).add(totalDays, 'd')) < new Date()) {
                setMaxDate(new Date(Moment(start).add(totalDays, 'd')));
            } else {
                setMaxDate(new Date());
            }
        }

        if (start && end) {
            let date1 = Moment(new Date(start)).format('YYYY-MM-DD');
            let date2 = Moment(new Date(end)).format('YYYY-MM-DD');

            filterActions.set({ startDate: date1, endDate: date2 }, "when");

            setWhen(false);
        }

    };


    useOutsideClick(refFilter, target => {

        if (filterWhen) {

            if (refFilter && !target.includes("react-datepicker") && !target.includes("column") && !target.includes("who") && !target.includes("input")) {

                if (filterWhen) {
                    setWhen(false);
                }

            }

        }
    });


    function clearWhen() {

        if(cleanWhenNull){            
            filterActions.set({ startDate: null, endDate: null }, "when");
            setWhen(false)
            return
        }
        let date1 = ''
        let date2 = ''

        if (resetOneYear) {
            setStartDate(new Date(defaultStartDate + "T12:00:00"));
            setEndDate(new Date(defaultEndDate + "T12:00:00"));

            date1 = defaultStartDate
            date2 = defaultEndDate
        } else {
            setStartDate(new Date());
            setEndDate(new Date());

            date1 = Moment(new Date()).format('YYYY-MM-DD');
            date2 = Moment(new Date()).format('YYYY-MM-DD');
        }

        filterActions.set({ startDate: date1, endDate: date2 }, "when");

        setWhen(false);
    }

    function applyWhenFilter() {

        if (startDate && endDate) {

            filterActions.set({
                startDate: Moment(startDate).format('YYYY-MM-DD'),
                endDate: Moment(endDate).format('YYYY-MM-DD')
            }, "when");

            setWhen(false);
        }

    }


    function quickWhenFilter(date1, date2) {


        setStartDate(date1);
        setEndDate(date2);


        date1 = Moment(date1).format('YYYY-MM-DD');
        date2 = Moment(date2).format('YYYY-MM-DD');

        if (startDate && endDate) {

            filterActions.set({ startDate: date1, endDate: date2 }, "when");

            setWhen(false);
        }

    }


    function manualWhenFilter(e) {

        if (e.target.value.length >= 23) {

            if (e.keyCode == 13) {

                let toDate = ManualWhenValue.split(" > ");
                let toDateStart = toDate[0].split("/");
                let toDateEnd = toDate[1].split("/");

                let NewStartDate = '';
                let NewEndDate = '';

                let date1 = '';
                let date2 = '';

                let valid = true;

                if (i18n.language == 'en') {
                    date1 = toDateStart[2] + '-' + toDateStart[0] + '-' + toDateStart[1];
                    date2 = toDateEnd[2] + '-' + toDateEnd[0] + '-' + toDateEnd[1];
                } else {
                    date1 = toDateStart[2] + '-' + toDateStart[1] + '-' + toDateStart[0];
                    date2 = toDateEnd[2] + '-' + toDateEnd[1] + '-' + toDateEnd[0];
                }

                NewStartDate = new Date(date1 + "T12:00:00");
                NewEndDate = new Date(date2 + "T12:00:00");

                if (new Date(date1) < new Date(minDate)) {
                    valid = false;
                }
                if (new Date(date2) > new Date()) {
                    valid = false;
                }
                if (new Date(date2) > new Date(maxDate)) {
                    valid = false;
                }


                if (valid) {
                    filterActions.set({
                        startDate: Moment(date1).format('YYYY-MM-DD'),
                        endDate: Moment(date2).format('YYYY-MM-DD')
                    }, "when");
                    setStartDate(NewStartDate);
                    setEndDate(NewEndDate);

                    setWhen(false);
                } else {
                    alert("DATA INVALIDA [MESSAGEM PROVISÓRIA DE ERRO]");
                    setManualWhenValue(null);
                }


            }
            if (e.keyCode == 8 || e.keyCode == 46) {
                setManualWhenValue(e.target.value.slice(0, -1));
            }

            return
        }

        if (e.keyCode != 8 && e.keyCode != 46) {

            if (e.keyCode >= 48 && e.keyCode <= 57) {

                let newValue = '';

                if (e.target.value.length == 2 || e.target.value.length == 5 || e.target.value.length == 15 || e.target.value.length == 18) {
                    newValue = e.target.value + "/" + e.key;
                } else if (e.target.value.length == 10) {
                    newValue = e.target.value + " > " + e.key;
                } else {
                    newValue = e.target.value + e.key;
                }

                setManualWhenValue(newValue);

            } else {

            }

        } else {
            setManualWhenValue(e.target.value.slice(0, -1));
        }


    }

    useEffect(() => {

        let startDateP = Moment(new Date()).format('YYYY-MM-DD');
        let endDateP = Moment(new Date()).format('YYYY-MM-DD');

        if (defaultStartDate) {
            startDateP = defaultStartDate;
        }
        if (defaultEndDate) {
            endDateP = defaultEndDate;
        }

        if (filter.date) {
            startDateP = filter.date.startDate;
            endDateP = filter.date.endDate;
        }

        setStartDate(new Date(startDateP + " 12:00:00"));
        setEndDate(new Date(endDateP + " 12:00:00"));


        // filterActions.set({ startDate: startDateP, endDate: endDateP }, "when");
    }, [])


    useEffect(() => {


        if (props.whenFilter && props.whenFilter == 'hide') {
            setShowWhenFilter(false);
        }

        if (props.whenFilter && props.whenFilter == 'show') {
            setShowWhenFilter(true);
        }


    }, [props.whenFilter])

    let path = window.location.pathname.split("/");

    return (

        <div className={filterWhen ? 'filter when open' : 'when filter'} ref={refFilter}>
            <div className="holder">
                <span className="label">{t('filter.when')}:</span>
                <span className="value" onClick={() => {
                    setWhen(!filterWhen);

                    setManualWhenValue('')
                }}>{Moment(startDate).format(t('date.mm/dd/yyyy')) + " > " + Moment(endDate).format(t('date.mm/dd/yyyy'))}</span>
                <input className="input" value={ManualWhenValue} onKeyUp={(data) => {
                    manualWhenFilter(data);
                }}
                    placeholder={Moment(startDate).format(t('date.mm/dd/yyyy')) + " > " + Moment(endDate).format(t('date.mm/dd/yyyy'))} />
                <div className="clear" onClick={() => {
                    clearWhen()
                }}><IconClear /></div>
            </div>
            <div className="comp date">
                <div className="column date">
                    <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        maxDate={maxDate}
                        minDate={minDate}
                        dateFormat="YYYY-MM-DD"
                        disabledKeyboardNavigation // <-- active-day bug is still open
                        inline
                        renderDayContents={renderDayContents}
                        locale={userDetails.language}
                    />
                </div>

                <div className="defaultFilters">
                    <div className="buttonGroup">
                        <button onClick={() => {
                            quickWhenFilter(new Date(Moment().add(-1, 'M').startOf('month')), new Date(Moment().add(-1, 'M').endOf('month')))
                        }}>{t('filter.last_month')}</button>
                        <button onClick={() => {
                            quickWhenFilter(new Date(Moment().add(-1, 'w').weekday(1)), new Date(Moment().add(-1, 'w').weekday(7)))
                        }}>{t('filter.last_week')}</button>
                        <button onClick={() => {
                            quickWhenFilter(new Date(Moment().add(-1, 'd')), new Date(Moment().add(-1, 'd')))
                        }}>{t('filter.yesterday')}</button>

                        <button onClick={() => {
                            quickWhenFilter(new Date(Moment().startOf('month')), new Date(Moment().endOf('month')))
                        }}>{t('filter.this_month')}</button>
                        <button onClick={() => {
                            quickWhenFilter(new Date(Moment().weekday(1)), new Date(Moment().weekday(7)))
                        }}>{t('filter.this_week')}</button>
                        {!props.maxDate &&
                            <button onClick={() => {
                                quickWhenFilter(new Date(), new Date())
                            }}>{t('filter.today')}</button>
                        }
                    </div>
                    <div className="buttonGroup">
                        <button className="apply" onClick={() => {
                            applyWhenFilter()
                        }}>OK
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
};


function mapDispatchToProps(dispatch) {

    return {
        statsActions: bindActionCreators(statsActions, dispatch),
        filterActions: bindActionCreators(filterActions, dispatch),
        teamsActions: bindActionCreators(teamsActions, dispatch),
        teammatesActions: bindActionCreators(teammatesActions, dispatch)
    }
}


function mapStateToProps(state) {

    return {
        teams: state.teams.payload,
        teammates: state.teammates.payload,
        filter: state.filter,
        userDetails: state.authentication.userDetails
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(WhenMulti)
