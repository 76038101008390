import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './TableReportsPoints.scss';
import { useTranslation } from 'react-i18next';
import { ArrowRight } from 'phosphor-react';
import { Apartment, Home } from '@material-ui/icons';
import { Typography } from '@mui/material';
import { history } from '../../../../_helpers';
import {Tooltip} from '@material-ui/core';

export default function TableColabs({
  data = []
}) {
  
  const { t, i18n } = useTranslation('common');

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className='tableReportsPoints' aria-label="collapsible table" size='small'>
        <TableHead>
          <TableRow style={{borderBottom: '2px solid #F5F6F9'}}>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }}>{t("inventory.colabs.table.colaborator")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t("inventory.colabs.table.ram_usage")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t("inventory.colabs.table.disk_usage")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t("inventory.colabs.table.avg_cpu")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t("inventory.colabs.table.os")}</TableCell>
            <TableCell sx={{ color: '#98ACC1', fontSize: 10, textTransform: 'uppercase' }} align='center'>{t("inventory.colabs.table.screen_size")}</TableCell>
            <TableCell width={'30px'} />
          </TableRow>
        </TableHead>
        <TableBody>

          {data.length > 0
            ? data.map((row, index) => (
              <TableRow key={row.id} className={`row-table`} onClick={() => history.push(`/inventory/colabs/${row.machine_user_id}`)}>
                <TableCell>
                  <Box display={'flex'} alignItems={'center'} gap={'2px'}>
                    <Box>
                      {/* {
                        row.location.length > 0
                          ?
                          (
                            row.location[0].type === 'H' ? <Home fontSize='small' style={{ marginTop: '2px' }} /> : <Apartment fontSize='small' />
                          )
                          :
                          '--'
                      } */}

                    </Box>
                    <Box>{row.username || "test"}</Box>
                  </Box>
                </TableCell>
                
                <TableCell align="center">{String(Number(row.ram_current_usage_in_mb/1024).toFixed(2))+" / "+String(Number(row.ram_total_in_mb/1024).toFixed(2)) + " GB"}</TableCell>
                <TableCell align="center">                
                  {String(Number(row.disk_used_in_mb/1024).toFixed(2))+" / "+String(Number(row.disk_total_in_mb/1024).toFixed(2))+" GB"}
                </TableCell>
                <TableCell align="center">                
                  {String(Number(row.avg_cpu_usage).toFixed(2))+"%"}
                </TableCell>
                <TableCell align="center">                
                  {String(row.OS)}
                </TableCell>
                <TableCell align="center">                
                  {String(row.size_screen)}
                </TableCell>
                <TableCell style={{fontSize:'12px'}}>
                  <IconButton
                    className={`button-options`}
                    aria-label="expand row"
                    size="small"
                    onClick={() => history.push(`/inventory/colabs/${row.machine_user_id}`)}
                  >
                    <ArrowRight size={16} />
                  </IconButton>
                </TableCell>
                
              </TableRow>
            ))
            :
            <TableRow>
              <TableCell colSpan={5} className='noHover'>
                <Typography color="#4F7091" component="span" fontSize={12} lineHeight="initial">
                  {t('checkin.no_data.generic')}
                </Typography>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
