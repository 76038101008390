// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9Bkd4Aqhp4\\+FVI0jURpYfg\\=\\= {\n  padding: 24px 120px;\n}\n\n.-iguAQZ7rnPuYq94NQyCug\\=\\= {\n  background-color: #ffffff;\n  border-radius: 8px;\n}\n\n.Ia\\+-04xKMFSFcC5uUm0wTQ\\=\\= {\n  padding: 10px 24px;\n}\n.Ia\\+-04xKMFSFcC5uUm0wTQ\\=\\= h4 {\n  color: #1E6B7F;\n  font-size: 18px;\n  font-weight: 500;\n  margin: 14px 0px;\n}\n.Ia\\+-04xKMFSFcC5uUm0wTQ\\=\\= p {\n  color: #4F7091;\n  font-size: 12px;\n  font-weight: 400;\n  margin: 10px 0px;\n}", "",{"version":3,"sources":["webpack://./src/pages/InactivitySettingsTeamsPage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,yBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF;AACE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AAEE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".containerPage {\n  padding: 24px 120px;\n}\n\n.mainCard{\n  background-color: #ffffff;\n  border-radius: 8px;\n}\n\n.mainCardHeader{\n  padding: 10px 24px;\n\n  h4{\n    color: #1E6B7F;\n    font-size: 18px;\n    font-weight: 500;\n    margin: 14px 0px;\n  }\n\n  p{\n    color: #4F7091;\n    font-size: 12px;\n    font-weight: 400;\n    margin: 10px 0px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerPage": "_9Bkd4Aqhp4+FVI0jURpYfg==",
	"mainCard": "-iguAQZ7rnPuYq94NQyCug==",
	"mainCardHeader": "Ia+-04xKMFSFcC5uUm0wTQ=="
};
export default ___CSS_LOADER_EXPORT___;
