import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, teammatesActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader, getAvatarUrl, getMoodById} from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import moment from 'moment';
import ButtonAlertInfo from '../../_components/reports/mood_new/ButtonAlertInfo';
import ProductivityGroupPercent from '../../_components/reports/mood_new/ProductivityGroupPercent';
import FragmentsMoodAvg from '../../_components/reports/mood_new/FragmentsMoodAvg';
import { useReactToPrint } from 'react-to-print';
import ModalExportReports from '../../_components/goals/ModalExportReports';
import MessageAlertDrawer from '../../_components/reports/mood_new/MessageAlertDrawer';
import MonthDetailsModal from '../../_components/reports/mood_new/MonthDetailsModal';
import SendMessageModal from '../../_components/reports/mood_new/SendMessageModal';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { MoodColabsCards } from '../../_components/reports/mood_new/MoodColabsCards';
import MoodColabsTable from '../../_components/reports/mood_new/MoodColabsTable';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ProductiveReportsMoodColabsPage(props) {

  const classes = useStyles();
  const {group_id} = useParams();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, shareActions, teammates, filter } = props

  const [fetching, setFetching] = useState(true);
  const [dataMood, setDataMood] = useState(null);
	const [currentMonth, setCurrentMonth] = useState(moment(new Date()).format('YYYY-MM'));	
	const [openDrawerMessageAlert, setOpenDrawerMessageAlert] = useState(false);
	const [openDrawerAlertsNegatives, setOpenDrawerAlertsNegatives] = useState(false);
	const [currentUser, setCurrentUser] = useState(null);
	const [openModalExportReports, setOpenModalExportReports] = useState(false);
	const [openModalMonthDetails, setOpenModalMonthDetails] = useState(false);
	const [openModalSendMessage, setOpenModalSendMessage] = useState(false);
  
	const contentDocument = useRef();
	
	const handlePrint = useReactToPrint({
		content: () => contentDocument.current,
	})

	const handleOpenModalExport = () => {
		setOpenModalExportReports(true);
	};

  const handleCloseModalExport = () => {
		setOpenModalExportReports(false);
	};

  const handleOpenDrawerMessageAlert = (user) => { 
		setCurrentUser(user);
    setOpenDrawerMessageAlert(true);
  };

  const handleCloseDrawerMessageAlert = () => { 
    setOpenDrawerMessageAlert(false);
		setCurrentUser(null);
  };

	const handleOpenModalMonthDetails = (user) => { 
		setCurrentUser(user);
    setOpenModalMonthDetails(true);
  };

  const handleCloseModalMonthDetails = () => { 
    setOpenModalMonthDetails(false);
		setCurrentUser(null);
  };

  const handleOpenModalSendMessage = (user) => { 
		setCurrentUser(user);
    setOpenModalSendMessage(true);
  };

  const handleCloseModalSendMessage = () => { 
    setOpenModalSendMessage(false);
		setCurrentUser(null);
  };

	const handleOpenDrawerAlertsNegatives = () => { 
    setOpenDrawerAlertsNegatives(true);
  };

  const handleCloseDrawerAlertsNegatives = () => { 
    setOpenDrawerAlertsNegatives(false);
  };

  async function getMoodData(is_loading, month_ref = null, group_id = null, teammates = []) {
    try {

      if (is_loading) {
        setFetching(true)
      }

      const queryTeammates = teammates.length > 0 ? `&users=${teammates.map(t=>t.id).join(',')}` : ''

      const response = await api.get(`/report/mood/details?month_ref=${month_ref}&current_day=${moment().format('YYYY-MM-DD')}&group_id=${group_id}${queryTeammates}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      setDataMood(response.data)
      setFetching(false)

    } catch (error) {
      console.log(error)
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t('reports_mood.table.col_colab'),
        accessor: 'username',
        Cell: ({value, row}) => {
          return (
            <div className={style.boxCellUsername}>
              <Avatar
                  alt={value}
                  src={getAvatarUrl({ name: value })}
                  style={{ width: 20, height: 20 }}
                />
              <span>{value}</span>
            </div>
          )
        }
      },
      {
        Header: t('reports_mood.table.col_mood'),
        accessor: 'series',
				Cell: ({value, row}) => {
					const {machine_user_id, username} = row.original
					return <FragmentsMoodAvg series={value} handleClick={() => handleOpenModalMonthDetails({user_id: machine_user_id, label: username})}/>
				}
      },
      {
        Header: t('reports_mood.table.col_answers'),
        accessor: 'total_mood_sent',
				Cell: ({value, row}) => {
					return `${value || 0}/${row.original.total_expected_mood || 0}`
				}
      },
			{
        Header: t('reports_mood.table.col_average'),
        accessor: 'avg_mood_group',
        width: '8%',
				Cell: ({value}) => {
					if(!value) return '---'
					return <img title={t(`widgets.moods.${getMoodById(parseInt(value)).key}`)} src={getMoodById(parseInt(value)).img} alt={t(`widgets.moods.${getMoodById(parseInt(value)).key}`)} width={'18px'} height={'18px'}/>
				}
      },
			{
        Header: t('reports_mood.table.col_alert'),
        accessor: 'total_alert_message',
				Cell: ({value, row}) => {
					const {machine_user_id, username} = row.original
					return <ButtonAlertInfo modeObject={{type: 'users', action: () => handleOpenModalSendMessage({user_id: machine_user_id, label: username})}} total_alerts={parseInt(value)} handleClick={() => handleOpenDrawerMessageAlert({user_id: machine_user_id, label: username})} currentMonth={currentMonth}/>
				}
      },
			{
        Header: t('reports_mood.table.col_productivity'),
        accessor: 'percent_working_day',
				Cell: ({ value, ...rest }) => {
          const currentRow = rest.row.original;
          return <ProductivityGroupPercent percent_working_day={parseInt(value)} percent_performance={parseInt(currentRow.percent_performance)} />;
        },
      }
    ];
  }, []);


  useEffect(() => {
   	let date = moment(new Date()).format('YYYY-MM');
	 
		if (filter.singleDate) {
			date = moment(filter.singleDate).format('YYYY-MM')
		}

		setCurrentMonth(date)

    let teammates = [];

    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getMoodData(true, date, group_id, teammates)

  }, [filter])

	useEffect(() => {			
		document.title = 'Befective | ' + t('menu.reports') + ' | ' + t('menu.mood');
    teammatesActions.getTeammates(group_id)
	}, [])

  return (
			<>
				<section className="mainContent">

					<Toolbar groupName={dataMood?.team_name}/>

					<Filterbar
						filterWho={'multi'}
						filterWhenMonths={'single'}
						filterWhen={'disabled'}
						filterWhat={'disabled'}
						showShareBtn={false}
            hideTeams={true}  
            disabledFetchingTeamsAndTeammates={true}
					/>

					<div className={style.containerPage} ref={contentDocument}>
						{
							!fetching
								?
								<>
									<MoodColabsCards stats={dataMood?.stats} />
									<MoodColabsTable 
                    columns={columns} 
                    groupName={dataMood?.team_name} 
                    data={dataMood?.data} 
                    handlePrint={handlePrint} 
                    handleOpenModalExport={handleOpenModalExport} 
                    handleOpenModalCalendar={handleOpenModalMonthDetails}
                    />
								</>
								:
								<GlobalSkeleton totalRowsStats={1} totalTables={1} heightTable={600} />
						}

					</div>

				</section>

				<ModalExportReports
					openModalExport={openModalExportReports} 
					handleCloseModalExport={handleCloseModalExport} 
					viewExport='mood3' 
          filter={{ ...filter, month_ref: currentMonth, current_day: moment().format('YYYY-MM-DD'), group_id: group_id, teams: [{id: group_id}]  }} 
					disabledExport={{
						excel: true,
						pdf: false
					}}
				/>

				<MonthDetailsModal
					currentMonthYear={currentMonth}
					currentData={currentUser}
					openModal={openModalMonthDetails}
					closeModal={handleCloseModalMonthDetails}
				/>

        <SendMessageModal
          openModal={openModalSendMessage}
          closeModal={handleCloseModalSendMessage}
          closeDrawerAlerts={handleCloseDrawerAlertsNegatives}
          currentUser={currentUser}
          alertActions={alertActions}
          updateDataPage={() => getMoodData(true, currentMonth, filter.teams, filter.teammates)}
        />
				
				<MessageAlertDrawer 
					currentMonthYear={currentMonth}
					currentData={currentUser}
					openDrawer={openDrawerMessageAlert} 
					closeDrawer={handleCloseDrawerMessageAlert}
				/>

			</>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductiveReportsMoodColabsPage)