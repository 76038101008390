import React, { useState } from "react";
import style from "./style.module.scss"
import { Modal } from "@material-ui/core";
import { CheckCircle } from "phosphor-react";
import { useTranslation } from "react-i18next";

function ModalMenuInactiveItem({isOpen, closeModel, modelDetails}) {
  const { t, i18n } = useTranslation('common');
  const urlsRedirectProduct = {
    goals_management: {
        pt: '/pt/okr/',
        en: '/en/okr/',
        es: '/es/okr/'            
    },
    check_in_remote: {
      pt: '/pt/points/',
      en: '/en/points/',
      es: '/es/points/'            
    },
    rooms_management: {
      pt: '/pt/rooms/',
      en: '/en/rooms/',
      es: '/es/rooms/'            
    },
    check_in_remote_mobile: {
      pt: '/pt/checkin/',
      en: '/en/checkin/',
      es: '/es/checkin/'   
    },
    project_management: {
      pt: '/pt/projects/',
      en: '/en/projects/',
      es: '/es/projects/'   
    },
    inventory: {
      pt: '/pt/inventory/',
      en: '/en/inventory/',
      es: '/es/inventory/'   
    },
    performance_assessment: {
      pt: '/pt/performance-assessment/',
      en: '/en/performance-assessment/',
      es: '/es/performance-assessment/'   
    }
  }
  
  return (
    <Modal
        className={style.modalContainer}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={closeModel}
        closeAfterTransition
        sx={{ border: 'none' }}
        style={{ outline: 'none', borderStyle: "none" }}
    >
      <div className={style.content}>
        <button className={style.btnClose} onClick={closeModel}>X</button>
        <div className={style.imgContainer}>
          <img src={modelDetails.image} alt="" />
        </div>
        <div className={style.descriptionContainer}>
          <div className={style.descriptionContent}>
          <h2 className={style.title}>{modelDetails.title}</h2>
        <p className={style.description}>{modelDetails.description}</p>
        <ul className={style.featuresList}>
          {modelDetails.features && modelDetails.features.map((item, i) => (
            <li className={style.item} key={modelDetails.id + modelDetails.name + i}>
            <CheckCircle size={15} color="#22BEAD"/>
              <p>{item}</p>
            </li>
          ))}
        </ul>
        <div className={style.talkToExpert}>
          <p>{t("switch.modal.talk_to_expert")}</p>
        </div>
        <div className={style.buttonsGroup}>
          <a href={"https://www.befective.com" + urlsRedirectProduct[modelDetails.name || "performance_assessment"][i18n.language]}  target="_blank" className={style.buttonMoreInfo}>{t("switch.modal.button_more_info")}</a>
          <a href="https://meetings.hubspot.com/mkt-befective?embed=true&uuid=6185d9c5-1b1e-4c6d-8bc2-4b6bbc5f9b29"  target="_blank" className={style.buttonEspecialist}>{t("switch.modal.button_especialist")}</a>
        </div>
          </div>
        </div>
      </div>
    </Modal>
  )

};

export default ModalMenuInactiveItem;
