// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4ajad9MTEtgK6NVQE\\+bc8g\\=\\= {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n}\n._4ajad9MTEtgK6NVQE\\+bc8g\\=\\= svg {\n  transition: all 0.2s ease-in;\n  cursor: pointer;\n  color: #fff;\n}\n\n.LsaKP0pmyXtKrevQjAaiZg\\=\\= {\n  background-color: #24677E;\n  border-radius: 100%;\n  overflow: hidden;\n  height: 30px;\n  width: 30px;\n  border: 2px solid #F5F6F9;\n  transition: all 0.2s ease;\n  box-shadow: 0 0 1px 0 #24677E inset, 0 0 1px 0 #24677E;\n  padding: 1px;\n}\n.LsaKP0pmyXtKrevQjAaiZg\\=\\= img {\n  width: 30px;\n  height: 30px;\n  border-radius: 100%;\n  background-color: #24677E;\n  box-shadow: 0 0 1px 0 #24677E inset, 0 0 1px 0 #24677E;\n}", "",{"version":3,"sources":["webpack://./src/_components/finalization_poc/Header.module.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAFF;AAIE;EACE,4BAAA;EACA,eAAA;EACA,WAAA;AAFJ;;AAMA;EACE,yBAhBa;EAiBb,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,yBAAA;EACA,sDAAA;EACA,YAAA;AAHF;AAKE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBA9BW;EA+BX,sDAAA;AAHJ","sourcesContent":["$accent-color: #24677E;\n$main-background: #F5F6F9;\n\n.user {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n\n  svg {\n    transition: all 0.2s ease-in;\n    cursor: pointer;\n    color: #fff;\n  }\n}\n\n.avatar {\n  background-color: $accent-color;\n  border-radius: 100%;\n  overflow: hidden;\n  height: 30px;\n  width: 30px;\n  border: 2px solid $main-background;\n  transition: all 0.2s ease;\n  box-shadow: 0 0 1px 0 $accent-color inset, 0 0 1px 0 $accent-color;\n  padding: 1px;\n\n  img {\n    width: 30px;\n    height: 30px;\n    border-radius: 100%;\n    background-color: $accent-color;\n    box-shadow: 0 0 1px 0 $accent-color inset, 0 0 1px 0 $accent-color;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user": "_4ajad9MTEtgK6NVQE+bc8g==",
	"avatar": "LsaKP0pmyXtKrevQjAaiZg=="
};
export default ___CSS_LOADER_EXPORT___;
