import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import {NavLink, Link} from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Toolbar from '../_components/_shared/Toolbar'

import {useTranslation, initReactI18next} from "react-i18next";

import {downloadsActions} from '../_actions';
import termPt from '../assets/docs/term-pt.pdf'
import termPtPublic from '../../public/term-pt.pdf'
import IconMac from '../_components/_icons/IconMac';
import IconWindows from '../_components/_icons/IconWindows';
import PdfViewer from '../_components/pdfViewer';

function TermsPage(props) {
    const {t, i18n} = useTranslation('common');

    let userDetails = props.userDetails || [];
    let loading = props.loading || false;

    let token = props.token || {};

    useEffect(() => {
        document.title = 'Befective | ' + t('menu.user.terms');
    });


    return (
        <React.Fragment>
            <section className="mainContent">

                <Toolbar/>


                <div className="mainPage term">
                    <div className="nocolumn">
                        <div className={'tab full active'}>
                            <PdfViewer fileUrl="/term-pt.pdf#toolbar=0"/>
                        </div>
                    </div>
                </div>
                {/* PAGE ENDS */}
            </section>

        </React.Fragment>
    )
};

function mapDispatchToProps(dispatch) {

    return {
        downloadsActions: bindActionCreators(downloadsActions, dispatch),

    }
}

function mapStateToProps(state) {

    return {
        filter: state.filter,
        loading: state.downloads.loading,
        userDetails: state.authentication.userDetails
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(TermsPage)
