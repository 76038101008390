import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import HeaderForm from "./HeaderForm";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import BefectiveCheckbox from "../../_shared/BefectiveCheckbox";
import CustomReactSelect from "../../_shared/CustomReactSelect";
import ReactInputMask from "react-input-mask";
import { api } from "../../../_helpers/api";
import { authHeader, getGenders, prepareOptionsData } from "../../../_helpers";
import { TagsInput } from "react-tag-input-component";

export default function FormPersonalCreatePT({
  formData,
  toogleShowForm,
  isEditing,
  lang,
  optionsData,
  alertActions,
  userId,
  getDataForm,
}) {
  const { t, i18n } = useTranslation("common");
  const [isSubmitting, setIsSubmitting] = useState(false);

  let formSchema = yup.object({
    full_name: yup.string().required(t("spaces.required_field")),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      full_name: formData?.full_name || "",
      birth_date: formData?.birth_date
        ? moment(formData?.birth_date).format("YYYY-MM-DD")
        : null,
      birth_location: formData?.birth_location || "",
      sex: formData?.sex || null,
      marital_status: formData?.marital_status
        ? {
            value: formData?.marital_status.value,
            label: formData?.marital_status[`label_${lang}`],
          }
        : null,
      document_1: formData?.document_1 || "",
      document_2: formData?.document_2 || "",
      blood_type: formData?.blood_type
        ? {
            value: formData?.blood_type.value,
            label: formData?.blood_type[`label_${lang}`],
          }
        : null,
      nationality: formData?.nationality
        ? {
            value: formData?.nationality.value,
            label: formData?.nationality[`label_${lang}`],
          }
        : null,
      cep: formData?.CEP || "",
      street: formData?.street || "",
      address_number: formData?.address_number || "",
      complement: formData?.complement || "",
      neighborhood: formData?.neighborhood || "",
      state: formData?.state || null,
      city: formData?.city || "",
      country: formData?.country
        ? {
            value: formData?.country?.value,
            label: formData?.country?.label
          }
        : null,
      observation: formData?.observation || "",
      telephone_number: formData?.telephone_number || "",
      phone_number: formData?.phone_number || "",
      email: formData?.email || "",
      emergency_contact_full_name: formData?.emergency_contact_full_name || "",
      emergency_contact_assignment: formData?.emergency_contact_assignment 
        ? {
          value: formData?.emergency_contact_assignment.value,
          label: formData?.emergency_contact_assignment[`label_${lang}`],
        }
        : null,
      emergency_contact_phone_number: formData?.emergency_contact_phone_number || "",
      medica_conditions: formData?.medica_conditions || "",
      allergies: formData?.allergies || [],
      medicines: formData?.medicines || []
    },
    resolver: yupResolver(formSchema),
  });

  const {
    full_name,
    birth_date,
    birth_location,
    sex,
    marital_status,
    document_1,
    document_2,
    blood_type,
    nationality,
    cep,
    street,
    address_number,
    complement,
    neighborhood,
    city,
    state,
    country,
    observation,
    telephone_number,
    phone_number,
    email,
    emergency_contact_full_name,
    emergency_contact_assignment,
    emergency_contact_phone_number,
    medica_conditions,
    allergies,
    medicines
  } = watch();

  const getViaCep = async (cep) => {
    if (String(cep).length !== 9) {
      return;
    }

    const { data } = await api.get(`https://viacep.com.br/ws/${cep}/json/`);

    if (!data.erro) {
      setValue("street", data.logradouro);
      setValue("neighborhood", data.bairro);
      setValue("city", data.localidade);
      setValue("state", data.uf);
      // const uf = optionsData.state.find((item) => item.uf === data.uf);
      // if(uf) {
      //   setValue("state", {
      //     ...uf,
      //     value: uf.id,
      //     label: uf.name
      //   });
      // }
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      const payload = {
        ...data,
        birth_date: data.birth_date
          ? moment(data.birth_date?.$d).format("YYYY-MM-DD")
          : null,
        machine_users_id: userId,
        marital_status_id: marital_status?.value,
        blood_type_id: blood_type?.value,
        nationality_id: nationality?.value,
        state: state,
        country_id: country?.value,
        emergency_contact_assignment_id: emergency_contact_assignment?.value,
        emergency_phone_number: emergency_contact_phone_number,
        sex: data.sex?.value || null
      };

      const resCreate = await api.post(
        "/users/personal-information/",
        payload,
        {
          headers: authHeader(),
        }
      );

      if (resCreate.status !== 200) {
        throw new Error("Error create personal information");
      }

      await getDataForm(false);
      setIsSubmitting(false);
      toogleShowForm()
      alertActions.success(t("settings.users.success.info_save"));
    } catch (error) {
      alertActions.error(error.response.data);
      console.error(error);
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className={styles.cardContainer}>
        <HeaderForm
          formData={formData}
          toogleShowForm={toogleShowForm}
          isEditing={isEditing}
          titleCreate={t("settings.users.personal_form.title_create")}
          submitForm={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          userId={userId}
        />

        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          paddingX="16px"
          paddingBottom="16px"
          borderRadius="4px"
          marginBottom="2px"
          flexGrow={1}
        >
            <Grid container spacing={2} marginBottom={'4px'} marginTop={0} >
              <Grid item xs={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="2px"
                  width="100%"
                >
                  <Typography
                    component="label"
                    sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                    htmlFor="full_name"
                  >
                    {t("settings.users.personal_form.full_name")}
                  </Typography>

                  <TextField
                    id="full_name"
                    name="full_name"
                    placeholder={t("settings.users.personal_form.full_name_placeholder")}
                    value={full_name}
                    error={false}
                    helperText={""}
                    {...register("full_name")}
                    sx={{
                      width: "100%",
                      height: "45px",
                      fieldset: {
                        border: "1px solid #DEE3E9CC",
                        height: "45px",
                      },
                      div: { color: "#4F7091", height: "45px" },
                      label: { top: "-4px" },
                    }}
                  />

                  {errors.full_name && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {errors.full_name.message}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="2px"
                  width="100%"
                >
                  <Typography
                    component="label"
                    sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                    htmlFor="birth_date"
                  >
                    {t("settings.users.personal_form.birth_date")}
                  </Typography>
                  
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    locale={lang}
                  >
                    <DatePicker
                      value={birth_date}
                      inputFormat={lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY"}
                      onChange={(value) => setValue("birth_date", value)}
                      renderInput={(params) => (
                        <TextField
                          id="birth_date"
                          variant="outlined"
                          fullWidth
                          {...params}
                          sx={{
                            width: "100%",
                            height: "45px",
                            fieldset: {
                              border: "1px solid #DEE3E9CC",
                              height: "45px",
                            },
                            input: { color: "#4F7091", paddingY: "11.5px" },
                            label: { top: "-4px" },
                          }}
                          name="birth_date"
                          error={false}
                          helperText={""}
                        />
                      )}
                    />
                  </LocalizationProvider>

                  {errors.birth_date && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {errors.birth_date.message}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="2px"
                  width="100%"
                >
                  <Typography
                    component="label"
                    sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                    htmlFor="birth_location"
                  >
                    {t("settings.users.personal_form.birth_location")}
                  </Typography>

                  <TextField
                    id="birth_location"
                    name="birth_location"
                    value={birth_location}
                    placeholder={t("settings.users.personal_form.birth_location_placeholder")}
                    error={false}
                    helperText={""}
                    {...register("birth_location")}
                    sx={{
                      width: "100%",
                      height: "45px",
                      fieldset: {
                        border: "1px solid #DEE3E9CC",
                        height: "45px",
                      },
                      div: { color: "#4F7091", height: "45px" },
                      label: { top: "-4px" },
                    }}
                  ></TextField>

                  {errors.birth_location && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {errors.birth_location.message}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2} marginBottom={'4px'} marginTop={0} >
              <Grid item xs={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="2px"
                  width="100%"
                >
                  <Typography
                    component="label"
                    sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                    htmlFor="document_1"
                  >
                    {t("settings.users.personal_form.document_1")}
                  </Typography>

                  <TextField
                    id="document_1"
                    name="document_1"
                    value={document_1}
                    placeholder={t("settings.users.personal_form.documents_placeholder")}
                    error={false}
                    helperText={""}
                    {...register("document_1")}
                    sx={{
                      width: "100%",
                      height: "45px",
                      fieldset: {
                        border: "1px solid #DEE3E9CC",
                        height: "45px",
                      },
                      div: { color: "#4F7091", height: "45px" },
                      label: { top: "-4px" },
                    }}
                  />

                  {errors.document_1 && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {errors.document_1.message}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="2px"
                  width="100%"
                >
                  <Typography
                    component="label"
                    sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                    htmlFor="document_2"
                  >
                    {t("settings.users.personal_form.document_2")}
                  </Typography>

                  <TextField
                    id="document_2"
                    name="document_2"
                    value={document_2}
                    placeholder={t("settings.users.personal_form.documents_placeholder")}
                    error={false}
                    helperText={""}
                    {...register("document_2")}
                    sx={{
                      width: "100%",
                      height: "45px",
                      fieldset: {
                        border: "1px solid #DEE3E9CC",
                        height: "45px",
                      },
                      div: { color: "#4F7091", height: "45px" },
                      label: { top: "-4px" },
                    }}
                  />

                  {errors.document_2 && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {errors.document_2.message}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="2px"
                  width="100%"
                >
                  <Typography
                    component="label"
                    sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                    htmlFor="nationality"
                  >
                    {t("settings.users.personal_form.nationality")}
                  </Typography>

                  <CustomReactSelect
                    id="nationality"
                    isMulti={false}
                    value={nationality}
                    options={prepareOptionsData(optionsData?.nationality, lang)}
                    onChange={(values) => setValue("nationality", values)}
                    closeMenuOnSelect={true}
                  />

                  {errors.nationality && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {errors.nationality.message}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2} marginBottom={'4px'} marginTop={0}>
              <Grid item xs={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="2px"
                  width="100%"
                >
                  <Typography
                    component="label"
                    sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                    htmlFor="sex"
                  >
                    {t("settings.users.personal_form.gender")}
                  </Typography>

                  <CustomReactSelect
                    id={"sex"}
                    isMulti={false}
                    value={sex}
                    options={getGenders()}
                    onChange={(values) => setValue("sex", values)}
                    closeMenuOnSelect={true}
                  />

                  {errors.sex && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {errors.sex.message}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="2px"
                  width="100%"
                >
                  <Typography
                    component="label"
                    sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                    htmlFor="marital_status"
                  >
                    {t("settings.users.personal_form.marital_status")}
                  </Typography>

                  <CustomReactSelect
                    id={"marital_status"}
                    isMulti={false}
                    value={marital_status}
                    options={prepareOptionsData(optionsData?.marital_status, lang)}
                    onChange={(values) => setValue("marital_status", values)}
                    closeMenuOnSelect={true}
                  />
                  {errors.marital_status && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {errors.marital_status.message}
                    </Typography>
                  )}
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="2px"
                  width="100%"
                >
                  <Typography
                    component="label"
                    sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                    htmlFor="blood_type"
                  >
                    {t("settings.users.personal_form.blood_type")}
                  </Typography>

                  <CustomReactSelect
                    id="blood_type"
                    isMulti={false}
                    value={blood_type}
                    options={prepareOptionsData(optionsData?.blood_type, lang)}
                    onChange={(values) => setValue("blood_type", values)}
                    closeMenuOnSelect={true}
                  />

                  {errors.blood_type && (
                    <Typography fontSize={12} color="#FF7A90" component="span">
                      {errors.blood_type.message}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
        </Box>
      </div>
      <div className={styles.cardContainer}>
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          paddingX="16px"
          paddingBottom="16px"
          borderRadius="4px"
          marginBottom="2px"
          flexGrow={1}
        >
          <Grid container spacing={2} marginBottom={'4px'} marginTop={0} >
            <Grid item xs={12}>
              <Typography
                component="h3"
                sx={{ fontSize: "18px", color: "#1E6B7F", fontWeight: 500 }}
              >
                {t("settings.users.personal_form.address")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="30%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="country"
                >
                  {t("settings.users.personal_form.country")}
                </Typography>

                <CustomReactSelect
                  id="country"
                  isMulti={false}
                  value={country}
                  options={prepareOptionsData(optionsData?.country, lang)}
                  onChange={(values) => setValue("country", values)}
                  closeMenuOnSelect={true}
                />

                {errors.country && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.country.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="cep"
                >
                  {t("settings.users.personal_form.cep")}
                </Typography>
                
                <ReactInputMask
                    mask={country?.value === 1 ? '99999-999' : '99999'}
                    maskChar={null}
                    value={cep}
                    onChange={(e) => {
                      setValue("cep", e.target.value);
                      if(country?.value === 1){
                        getViaCep(e.target.value);
                      }
                    }}
                >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        id="cep"
                        name="cep"
                        placeholder={country?.value === 1 ? '99999-999' : '99999'}
                        error={false}
                        helperText={""}
                        sx={{
                          width: "100%",
                          height: "45px",
                          fieldset: {
                            border: "1px solid #DEE3E9CC",
                            height: "45px",
                          },
                          div: { color: "#4F7091", height: "45px" },
                          label: { top: "-4px" },
                        }}
                      />
                    )}
                </ReactInputMask>

                {errors.cep && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.cep.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={8}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="street"
                >
                  {t("settings.users.personal_form.street")}
                </Typography>

                <TextField
                  id="street"
                  name="street"
                  value={street}
                  placeholder={t("settings.users.personal_form.street_placeholder")}
                  error={false}
                  helperText={""}
                  {...register("street")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                ></TextField>

                {errors.street && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.street.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="address_number"
                >
                  {t("settings.users.personal_form.number")}
                </Typography>

                <TextField
                  id="address_number"
                  name="address_number"
                  value={address_number}
                  placeholder={t("settings.users.personal_form.number_placeholder")}
                  error={false}
                  helperText={""}
                  {...register("address_number")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                ></TextField>

                {errors.address_number && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.address_number.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="complement"
                >
                  {t("settings.users.personal_form.complement")}
                </Typography>

                <TextField
                  id="complement"
                  name="complement"
                  value={complement}
                  placeholder={t("settings.users.personal_form.complement_placeholder")}
                  error={false}
                  helperText={""}
                  {...register("complement")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                ></TextField>

                {errors.complement && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.complement.message}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} marginBottom={'4px'} marginTop={0} >
            <Grid item xs={4}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="neighborhood"
                >
                  {t("settings.users.personal_form.neighborhood")}
                </Typography>

                <TextField
                  id="neighborhood"
                  name="neighborhood"
                  value={neighborhood}
                  placeholder={t("settings.users.personal_form.neighborhood_placeholder")}
                  error={false}
                  helperText={""}
                  {...register("neighborhood")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                />

                {errors.neighborhood && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.neighborhood.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="city"
                >
                  {t("settings.users.personal_form.city")}
                </Typography>

                <TextField
                  id="city"
                  name="city"
                  value={city}
                  placeholder={t("settings.users.personal_form.city_placeholder")}
                  error={false}
                  helperText={""}
                  {...register("city")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                />

                {errors.city && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.city.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="state"
                >
                  {t("settings.users.personal_form.state")}
                </Typography>

                {/* <CustomReactSelect
                  id="state"
                  isMulti={false}
                  value={state}
                  options={prepareOptionsData(optionsData?.state, lang)}
                  onChange={(values) => setValue("state", values)}
                  closeMenuOnSelect={true}
                /> */}
                <TextField
                  id="state"
                  name="state"
                  value={state}
                  placeholder={t("settings.users.personal_form.state_placeholder")}
                  error={false}
                  helperText={""}
                  {...register("state")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                />

                {errors.state && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.state.message}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="observation"
                >
                  {t("settings.users.personal_form.obs")}
                </Typography>

                <TextField
                  multiline
                  rows={2}
                  id="observation"
                  name="observation"
                  value={observation}
                  placeholder={t("settings.users.personal_form.obs_placeholder")}
                  error={false}
                  helperText={""}
                  {...register("observation")}
                  sx={{
                    width: "100%",
                    minHeight: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      minHeight: "45px",
                    },
                    div: { color: "#4F7091", minHeight: "45px" },
                    label: { top: "-4px" },
                  }}
                />

                {errors.observation && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.observation.message}
                  </Typography>
                )}
              </Box>
            </Grid>

          </Grid>
        </Box>
      </div>
      <div className={styles.cardContainer}>
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          paddingX="16px"
          paddingBottom="16px"
          borderRadius="4px"
          marginBottom="2px"
          flexGrow={1}
        >
          <Grid container spacing={2} marginBottom={'4px'} marginTop={0} >
            <Grid item xs={12}>
              <Typography
                component="h3"
                sx={{ fontSize: "18px", color: "#1E6B7F", fontWeight: 500 }}
              >
                {t("settings.users.personal_form.contact")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                >
                  {t("settings.users.personal_form.email")}
                </Typography>

                <TextField
                  disabled
                  name="email"
                  placeholder="------"
                  value={email}
                  error={false}
                  helperText={""}
                  sx={{
                    width: "100%",
                    height: "42px",
                    color: "#4F7091",
                    backgroundColor: '#F5F6F9',
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                      // backgroundColor: '#F5F6F9',
                      // color: "#4F7091"
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" }
                  }}
                />

                {errors.email && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.email.message}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="phone_number"
                >
                  {t("settings.users.personal_form.cell_phone")}
                </Typography>

                <TextField
                  id="phone_number"
                  name="phone_number"
                  placeholder={t("settings.users.personal_form.cell_phone_placeholder")}
                  value={phone_number}
                  error={false}
                  helperText={""}
                  {...register("phone_number")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                />

                {errors.phone_number && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.phone_number.message}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="telephone_number"
                >
                  {t("settings.users.personal_form.telephone")}
                </Typography>

                <TextField
                  id="telephone_number"
                  name="telephone_number"
                  placeholder={t("settings.users.personal_form.telephone_placeholder")}
                  value={telephone_number}
                  error={false}
                  helperText={""}
                  {...register("telephone_number")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                />

                {errors.telephone_number && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.telephone_number.message}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} marginBottom={'4px'} marginTop={'10px'} >
            <Grid item xs={12}>
              <Typography
                component="h3"
                sx={{ fontSize: "18px", color: "#1E6B7F", fontWeight: 500 }}
              >
                {t("settings.users.personal_form.contact_emergency")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="emergency_contact_full_name"
                >
                  {t("settings.users.personal_form.full_name")}
                </Typography>

                <TextField
                  id="emergency_contact_full_name"
                  name="emergency_contact_full_name"
                  placeholder={t("settings.users.personal_form.full_name_placeholder")}
                  value={emergency_contact_full_name}
                  error={false}
                  helperText={""}
                  {...register("emergency_contact_full_name")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                />

                {errors.emergency_contact_full_name && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.emergency_contact_full_name.message}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="telephone_number"
                >
                  {t("settings.users.personal_form.attribuition")}
                </Typography>

                <CustomReactSelect
                  id="emergency_contact_assignment"
                  isMulti={false}
                  value={emergency_contact_assignment}
                  options={prepareOptionsData(optionsData?.contact_assignment, lang)}
                  onChange={(values) => setValue("emergency_contact_assignment", values)}
                  closeMenuOnSelect={true}
                />

                {errors.emergency_contact_assignment && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.emergency_contact_assignment.message}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="emergency_contact_phone_number"
                >
                  {t("settings.users.personal_form.cell_phone")}
                </Typography>

                <TextField
                  id="emergency_contact_phone_number"
                  name="emergency_contact_phone_number"
                  placeholder={t("settings.users.personal_form.cell_phone_placeholder")}
                  value={emergency_contact_phone_number}
                  error={false}
                  helperText={""}
                  {...register("emergency_contact_phone_number")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                />

                {errors.emergency_contact_phone_number && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.emergency_contact_phone_number.message}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className={styles.cardContainer}>
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          paddingX="16px"
          paddingBottom="16px"
          borderRadius="4px"
          marginBottom="2px"
          flexGrow={1}
        >
          <Grid container spacing={2} marginBottom={'4px'} marginTop={0} >
            <Grid item xs={12}>
              <Typography
                component="h3"
                sx={{ fontSize: "18px", color: "#1E6B7F", fontWeight: 500 }}
              >
                {t("settings.users.personal_form.info_health")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                  htmlFor="medica_conditions"
                >
                  {t("settings.users.personal_form.conditions_medical")}
                </Typography>

                <TextField
                  id="medica_conditions"
                  name="medica_conditions"
                  placeholder={t("settings.users.personal_form.placeholder_conditions_medical")}
                  value={medica_conditions}
                  error={false}
                  helperText={""}
                  {...register("medica_conditions")}
                  sx={{
                    width: "100%",
                    height: "45px",
                    fieldset: {
                      border: "1px solid #DEE3E9CC",
                      height: "45px",
                    },
                    div: { color: "#4F7091", height: "45px" },
                    label: { top: "-4px" },
                  }}
                />

                {errors.medica_conditions && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.medica_conditions.message}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                >
                  {t("settings.users.personal_form.allergies")}
                </Typography>

                <div className={styles.tagBox}>
                  <TagsInput
                      value={allergies}
                      name="allergies"
                      id="allergies"
                      onChange={values => setValue('allergies', values)}
                      placeHolder={t('settings.users.personal_form.placeholder_allergies')}
                    />
                </div>

                {errors.allergies && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.allergies.message}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                gap="2px"
                width="100%"
              >
                <Typography
                  component="label"
                  sx={{ paddingLeft: "4px", fontSize: "12px", color: "#1E6B7F", fontWeight: 400 }}
                >
                  {t("settings.users.personal_form.medicines")}
                </Typography>

                <div className={styles.tagBox}>
                  <TagsInput
                      value={medicines}
                      name="medicines"
                      id="medicines"
                      onChange={values => setValue('medicines', values)}
                      placeHolder={t('settings.users.personal_form.placeholder_medicines')}
                    />
                </div>

                {errors.medicines && (
                  <Typography fontSize={12} color="#FF7A90" component="span">
                    {errors.medicines.message}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}
