import { Backdrop, Modal, Slide, makeStyles } from "@material-ui/core";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { authHeader } from "../../../_helpers";
import { api } from "../../../_helpers/api";
import styles from "./styles.module.scss";
import { Close } from "@material-ui/icons";
import OpenAI from "openai";
import { Remarkable } from "remarkable";
import es from "dayjs/locale/es";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export function ReportAiModal({
  openModal,
  toggleModal,
  filter,
  userDetails,
  report,
  data,
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation("common");
  const [contentGenerate, setContentGenerate] = useState("");
  const [loading, setLoading] = useState(false);

  const generateAiReport = async () => {
    try {
      setLoading(true);
      const openAI = new OpenAI({
        apiKey:
          "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",
        dangerouslyAllowBrowser: true,
      });
      switch (report) {
        case "performance":
          const content_user = {
            pt: `Gere um relatório com insights com as informações mais relevantes do relatório de desempenho. Os dados são: ${JSON.stringify(data)}`,
            en: `Generate a report with insights with the most relevant information from the performance report. The data is: ${JSON.stringify(data)}`,
            es: `Genere un informe con insights con las información mas relevantes del informe de desempeno. Los datos son: ${JSON.stringify(data)}`
          };

          const content_system = {
            pt: "Você é um especialista em analisar relatório de desempenho. Você ajuda os gestores a tomar decisões com base nas informações do relatório de desempenho, gerando um relatório com insights com as informações mais relevantes.",
            en: "You are an expert in analyzing performance reports. You help managers make decisions based on the report data, generating a report with insights with the most relevant information.",
            es: "Eres un experto en analizar informes de desempeno. Ayudas a los directores a tomar decisiones basadas en los datos del informe, generando un informe con insights con las información mas relevantes."
          }
          
          const completion = await openAI.chat.completions.create({
            model: "gpt-4o",
            messages: [
              {
                role: "system",
                content: content_system[userDetails.language || i18n.language],
              },
              {
                role: "user",
                content: content_user[userDetails.language || i18n.language],
              },
            ],
          });
          setContentGenerate(completion.choices[0].message.content);
          break;
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getRawMarkup = (content) => {
    var md = new Remarkable();
    return { __html: md.render(content) };
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={toggleModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: "#205B6F", opacity: 0.8 },
      }}
      sx={{ border: "none" }}
      style={{ outline: 0 }}
    >
      <Slide
        direction="down"
        in={openModal}
        style={{ outline: 0 }}
        mountOnEnter
        unmountOnExit
      >
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          zIndex={2}
          py="26px"
          px="26px"
          borderRadius="8px"
          width={580}
          component="form"
          noValidate
          autoComplete="off"
          position={"relative"}
        >
          <div className={styles.closeBtn}>
            <IconButton aria-label="close" onClick={toggleModal} size="small">
              <Close fontSize="medium" />
            </IconButton>
          </div>

          <Typography
            component="h1"
            fontSize={18}
            color="#205B6F"
            fontWeight={500}
          >
            {t("report_ai_modal.title")}
          </Typography>
          <Typography
            component="p"
            fontSize={14}
            color="#205B6F"
            fontWeight={400}
            marginTop={"8px"}
            marginBottom={"16px"}
          >
            {t("report_ai_modal.subtitle")}
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            maxHeight={"400px"}
            overflow={"auto"}
          >
            <div
              className={styles.containerMarkdown}
              dangerouslySetInnerHTML={getRawMarkup(contentGenerate)}
            />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap="14px"
            marginTop={"20px"}
          >
            <Button
              style={{
                backgroundColor: "#E3EFFF",
                paddingY: "12px",
                paddingX: "28px",
                borderRadius: "4px",
                width: "105px",
                height: "42px",
              }}
              className={styles.cancelBtn}
              onClick={toggleModal}
              disabled={loading}
            >
              <Typography
                component="span"
                fontSize={14}
                color="#187DFF"
                textTransform="initial"
                fontWeight={400}
              >
                {t("report_ai_modal.btn_cancel")}
              </Typography>
            </Button>
            <Button
              style={{
                backgroundColor: "#187DFF",
                paddingY: "12px",
                paddingX: "28px",
                borderRadius: "4px",
                minWidth: "120px",
                height: "42px",
              }}
              className={styles.save}
              onClick={generateAiReport}
              disabled={loading}
            >
              <Typography
                component="span"
                fontSize={14}
                color="#fff"
                textTransform="initial"
                fontWeight={400}
              >
                {loading ? (
                    t("report_ai_modal.label_btn_generating")
                ) : (
                    t("report_ai_modal.btn_generate")
                )}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
}
