import { Menu, MenuItem } from '@material-ui/core';
import { Box } from '@mui/material';
import { CalendarCheck, ChartBar, ClipboardText, DeviceMobile, DotsNine, HouseSimple, Kanban, ListChecks, Medal, Timer } from 'phosphor-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { redirectToModule } from '../../_helpers/redirectToModule';
import { useCurrentModule } from '../../contexts/CurrentModuleContext';

import { authHeader, doCountAccessModule, history } from '../../_helpers';
import './MenuSwitch.scss';
import { api } from '../../_helpers/api';

const icons = {
  productivity_management: <ChartBar fill="#E4EAEF" weight='bold' />,
  goals_management: <ListChecks fill="#E4EAEF" weight='bold' />,
  check_in_remote: <Timer color="#E4EAEF" weight='bold' />,
  check_in_remote_mobile: <DeviceMobile color="#E4EAEF" weight='bold' />,
  rooms_management: <CalendarCheck color="#E4EAEF" weight='bold' />,
  project_management: <Kanban color="#E4EAEF" weight='bold' />,
  inventory: <ClipboardText color="#E4EAEF" weight='bold' />,
  performance_assessment: <Medal color="#E4EAEF" weight='bold' />
}

export function MenuSwitch({ userDetails }) {
  const { t } = useTranslation('common');

  const { currentModule, saveCurrentModule, removeCurrentModule, setCurrentModule } = useCurrentModule()

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const modules = userDetails?.roleName !== 'collaborator' ? userDetails?.modules : userDetails?.modules?.filter(module => !['performance_assessment'].includes(module.name))
  
  // Ordem desejada
  const order = [
    "productivity_management",
    "goals_management",
    "performance_assessment",
    "check_in_remote_mobile",
    "check_in_remote",
    "rooms_management",
    "project_management",
    "inventory",
  ];

  // Reordenar o array e mapear os nomes
  const orderedArray = order.map((key) => {
                        const item = modules.find((obj) => obj.name === key);
                        if(!item) return null
                        return {
                          ...item
                        };
                      }).filter((item) => item !== null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleRedirectToModule(moduleName) {
    
    if(moduleName==='switch'){
      setCurrentModule(null)
      removeCurrentModule()
      history.push('/productive')
      return
    }

    saveCurrentModule(moduleName)
    if (userDetails.roleName !== 'collaborator') {
      redirectToModule(moduleName,'',userDetails?.allowed_menu)
    } else if (moduleName === 'productivity_management') {
      history.push('/productive/productivity/')
    } else {
      redirectToModule(moduleName)
    }
  }

  return (
    <>
      <DotsNine size={24} weight="bold" className="modules" onClick={handleClick} />

      <Menu
        anchorEl={anchorEl}
        id="switchBefective"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
          }
        }}
        getContentAnchorEl={null}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem
          onClick={() => handleRedirectToModule('switch')}
        >
          <Box
            height={15}
            width={14}
          >
            <HouseSimple color="#E4EAEF" weight='bold' />
          </Box>
          <span>
            {t('switch.home')}
          </span>
        </MenuItem> */}

        {orderedArray && orderedArray?.map(module => (
          <MenuItem
            key={module.id}
            className={currentModule === module.name ? 'selected' : ''}
            onClick={() => {
              handleRedirectToModule(module.name)
              
              doCountAccessModule(module.name,api,authHeader)
            }
              
            }
            
          >
            <Box
              height={15}
              width={14}
            >
              {icons[`${module.name}`]}
            </Box>
            <span style={{ color: currentModule === module.name ? "#FDA02B" : "" }}>
              {t(`switch.${module.name}`)}
            </span>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
