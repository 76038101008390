import React, { useEffect, useState } from 'react';
import { useTable, usePagination, useSortBy, useGlobalFilter, useRowSelect } from 'react-table';
import styles from './DetailTable.module.scss';
import { Pagination } from '@material-ui/lab';
import { api } from '../../../../../_helpers/api';
import { ArrowDown, ArrowRight, ArrowUp, MagnifyingGlassPlus, User } from 'phosphor-react';
import { authHeader, history } from '../../../../../_helpers';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@material-ui/core';

const DetailTable = ({ columns, data, options, selectedRows, setSelectedRows, count, getData, openDrawer, pageCountRows, alertActions, activeFilter, showsIcons = true, dateStart, dateEnd, licenseId}) => {

  const { t } = useTranslation('common');

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: count,
      autoResetPage: false,
      initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20 },
      pageOptions: Array.from({ length: count }, () => 1).map((_, index) => index)
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const myPageOptions = [
    {
      value: 20,
      label: '20'
    },
    {
      value: 50,
      label: '50'
    },
    {
      value: 100,
      label: '100'
    },
    {
      value: 200,
      label: '200'
    },
    {
      value: 300,
      label: '300'
    },
    {
      value: 400,
      label: '400'
    },
    {
      value: 500,
      label: '500'
    },
    {
      value: pageCountRows,
      label: 'all'
    }
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, sortBy, sortOrder, globalFilter }
  } = tableInstance;

  const toggleAllRowsSelection = (e) => {
    const { checked } = e.target
    const allRows = page.map((row) => row.original.id);
    if (checked) {
      setSelectedRows(allRows);
    } else {
      setSelectedRows([])
    }

  };



  const fetchData = async ({ pageIndex, pageSize, sortBy, sortOrder, globalFilter }) => {
    try {
      getData({ pageIndex, pageSize, isLoading: false, date_start:dateStart, date_end:dateEnd, teams:null, teammates:null })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  return (
    <div className={styles.tableContainer}>

      <table {...getTableProps()}>
        <thead>

          <tr>
            {headerGroups.map((headerGroup) => (
              <React.Fragment key={headerGroup.id}>
                {headerGroup.headers.map((column) => {
                  return <th key={`column-${column.id}`} {...column.getHeaderProps(column.getSortByToggleProps())} title={t('title_sort')} className={styles.headColTable} style={{ textAlign: `${['available_days','total_used_days','real_usage_percent','geral_usage'].includes(column.id) ? 'center' : 'left'}` }}>
                    {column.render('Header')}
                    {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                  </th>
                })}
              </React.Fragment>
            ))}
            {
              showsIcons && <th style={{ width: '0px' }} className={styles.headColTable} />
            }
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={`row-${i}`} className={styles.rowHover} onClick={(e) => history.push(`/inventory/software/${licenseId}/${row.original.id}`)}>
                {row.cells.map((cell) => {
                  switch (cell.column.id) {
                    case 'active':
                      return (
                        <td key={`cell-${cell.column.id}`} {...cell.getCellProps()} className={row.original.active ? styles.statusActive : styles.statusInactive} style={{ fontSize: '12px' }}>{cell.render("Cell")}</td>
                      );
                    case 'geral_usage':
                      return (
                        <td key={`cell-${cell.column.id}`} {...cell.getCellProps()} style={{ textAlign: 'center', fontSize: '12px' }} >{cell.render("Cell")}</td>
                      );
                    default:
                      return (
                        <td key={`cell-${cell.column.id}`} {...cell.getCellProps()} style={{ textAlign: `${['available_days','total_used_days','real_usage_percent'].includes(cell.column.id) ? 'center' : 'left'}`, fontSize: '12px' }} >{cell.render("Cell")}</td>
                      );
                  }
                })}
                {showsIcons &&
                <td>
                  <div className={styles.boxActions}>
                    <IconButton
                        className={`button-options`}
                        aria-label="expand row"
                        size="small"
                        onClick={(e) => history.push(`/inventory/software/${licenseId}/${row.original.id}`)}
                      > 
                        <MagnifyingGlassPlus size={16} weight='bold' fill='#187DFF'/>
                      </IconButton>
                  </div>
                </td>}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={styles.containerPagination}>
        <Pagination
          count={pageCount}
          page={pageIndex + 1}
          onChange={(event, page) => {
            const new_pg_index = (page - 1)
            gotoPage(page - 1)
            fetchData({pageIndex:new_pg_index, pageSize, sortBy, sortOrder, globalFilter });
          }}
          variant="outlined"
          shape="rounded"
          siblingCount={1}
          boundaryCount={1}
        />
        <select
          value={pageSize}
          onChange={(e) => {
            const new_pg_size = Number(e.target.value)
            setPageSize(new_pg_size);
            localStorage.setItem('pageSizeStorage', new_pg_size);
            fetchData({ pageIndex, pageSize:new_pg_size, sortBy, sortOrder, globalFilter });
          }}
          className={styles.selectOptions}
        >
          {myPageOptions.map((pageSize, index) => (
            <option key={`page-${index}`} value={pageSize.value}>
              {pageSize.label === 'all' ? t('settings.teams.show_all') : `${t('settings.teams.show')} ${pageSize.label}`}
            </option>
          ))}
        </select>
      </div>

    </div>
  );
};

export default DetailTable;
