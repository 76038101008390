import React from "react";

export default function IconBefectiveAIChatResponse(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#FDBE2B" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.9979 12.9755V14.5902L13.8838 16.3751L12.1743 18.3898C12.1432 18.4267 12.0954 18.439 12.0498 18.4226C12.0041 18.4062 11.9772 18.3652 11.9772 18.318V17.4399L10.5809 16.6397L17 12.9755H16.9979ZM11.9979 6L13.4295 6.82065L7 10.4931V8.86407L11.9751 6.01436L11.9979 6ZM14.8631 7.6413L16.2946 8.46195L7 13.7675V12.1282L14.8631 7.6413ZM16.9979 9.69909V11.3363L9.1473 15.8191L7.71577 14.9984L17 9.69704L16.9979 9.69909Z"
        fill="#24677E"
      />
    </svg>
  );
}
