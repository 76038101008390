import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import Toolbar from '../../_components/_shared/Toolbar'
import Filterbar from '../../_components/_shared/Filterbar'
import {useTranslation} from "react-i18next";
import moment from 'moment';
import {filterActions, historicalActions} from '../../_actions';
import {useHistory} from "react-router-dom";
import { api } from '../../_helpers/api.js';
import { authHeader } from '../../_helpers/auth-header.js';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton.jsx';
import TransactionalChartSummary from '../../_components/reports/transactional/TransactionalChartSummary.jsx';

function ProductiveReportsTransactionalChartPage(props) {
    const {t, i18n} = useTranslation('common');
    const {filterActions, filter} = props;
    const history = useHistory();    
    const [fetching, setFetching] = useState(true);
    const [data, setData] = useState([]);
    const [openWho, setOpenWho] = useState('');
    const [dayToShow, setDayToShow] = useState(null);
    let startDate = new Date(moment().add(-8, 'd')).toISOString().split('T')[0];
    let endDate = new Date(moment()).toISOString().split('T')[0];


    function tiggerWho(day, now) {
        setOpenWho(now);
    }

    const getData = async (isLoading = true, startDate = null, endDate = null) => {
      try {

        if (isLoading) {
          setFetching(true)
        }

        const response = await api.get(`/report/transactional/graphic?date_start=${startDate || startDate}&date_end=${endDate || endDate}`, { 
          headers: authHeader()
        })

        setData(response.data)

        setFetching(false)

      } catch (error) {
        console.log(error)
        //alertActions.error(t('generic_messages.errors.load'))
        setFetching(false)
      }
      
    }

    useEffect(() => {
      
      let startDate = new Date(moment().add(-8, 'd')).toISOString().split('T')[0];
      let endDate = new Date(moment()).toISOString().split('T')[0];
      let teammates = null;

      if (filter.date) {
          startDate = filter.date.startDate;
          endDate = filter.date.endDate;
      }

      if (filter.teammates && filter.teammates[0] && dayToShow) {
          teammates = filter.teammates;
          history.push("/productive/reports/transactional/chart-view/hour?mu=" + teammates[0].id + "&day=" + dayToShow)
      } else if (filter.teammates && filter.teammates[0] && !dayToShow) {
          getData(true, startDate, endDate);
      } else {
          getData(true, startDate, endDate);
      }


    }, [filter]);


    useEffect(() => {
        document.title = 'Befective | ' + t('productive_reports.card_reports.transactional');
    },[]);

    return (
        <React.Fragment>

            <section className="mainContent">
                <Toolbar/>
                <Filterbar 
                  routeBackToPage={'/productive/reports/transactional'}
                  defaultStartDate={startDate}
                  defaultEndDate={endDate}
                  openWho={openWho}
                  filterWhen={'multi'}
                  filterWho={'singleTeammates'}
                  filterWhat={'disabled'}
                  startDate={startDate}
                  endDate={endDate}
                  showShareBtn={false}
                    //maxDate={maxDate}
                />
                {/* PAGE STARTS */}
                <div className="mainPage">
                    <div className="column single">
                        {!fetching
                          ?
                          <TransactionalChartSummary 
                            chartData={data}
                            startDate={startDate} 
                            endDate={endDate} 
                            openWho={(data) => {
                              tiggerWho(data.day, data.now);
                              setDayToShow(data.day);
                            }}
                          />
                          :
                          <GlobalSkeleton totalRowsStats={0} totalTables={1} heightTable={600} />
                        }
                    </div>
                </div>
                {/* PAGE ENDS */}

            </section>

        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        historicalActions: bindActionCreators(historicalActions, dispatch),
        filterActions: bindActionCreators(filterActions, dispatch)
    }
}


function mapStateToProps(state) {

    return {
        loading: state.historical.loading,
        filter: state.filter,
        historical: state.historical
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductiveReportsTransactionalChartPage)
