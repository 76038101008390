import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Alarm, ClockCounterClockwise, CurrencyDollar, Timer, Wallet } from 'phosphor-react';
import { Card, CardContent } from '@material-ui/core';
import style from './CardsHeader.module.scss';
import { befetiveFormatCurrency } from '../../../_helpers';
export function CardsHeader({ stats, lang='en' }) {
  
  const { t, i18n } = useTranslation('common');

  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >
        
      <Card 
        variant="outlined"
        style={{ border: 'none', display: 'flex', flex: 1, minWidth: '165px', position: 'relative' }}
      >
        <CardContent style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '1rem', borderLeft: '2px solid #187DFF' }}>
          <Timer size={18} color={'#187DFF'} weight="bold" />
          <Typography color={'#187DFF'} component="span" fontSize={18} my={1}>
            {`${stats?.working_day_hours || 0} ${t('report_value_hour.hours')}`}
          </Typography>
          <Typography color={'#1E6B7F'} component="span" fontSize={12}>
            {t('report_value_hour.cards.working_day')}
          </Typography>
        </CardContent>
        <div className={`${style.badgePercent} ${style.blue}`}>
          {`${stats?.percent_working_day_hours || 0}%`}
        </div>
      </Card>
           
      <Card 
        variant="outlined"
        style={{ border: 'none', display: 'flex', flex: 1, minWidth: '165px', position: 'relative' }}
      >
        <CardContent style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '1rem', borderLeft: '2px solid #22BEAD' }}>
          <ClockCounterClockwise size={18} color={'#22BEAD'} weight="bold" />
          <Typography color={'#22BEAD'} component="span" fontSize={18} my={1}>
            {`${stats?.performance_hours || 0} ${t('report_value_hour.hours')}`}
          </Typography>
          <Typography color={'#1E6B7F'} component="span" fontSize={12}>
            {t('report_value_hour.cards.performance')}
          </Typography>
        </CardContent>
        <div className={`${style.badgePercent} ${style.green}`}>
          {`${stats?.percent_performance_hours || 0}%`}
        </div>
      </Card>

      <Card
        variant="outlined"
        style={{ border: 'none', display: 'flex', flex: 1, minWidth: '165px', position: 'relative' }}
      >
        <CardContent style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '1rem', borderLeft: '2px solid #4F7091' }}>
          <Alarm size={18} color="#4F7091" weight="bold" />
          <Typography color="#4F7091" component="span" fontSize={18} my={1}>
            {`${stats?.offline_hours || 0} ${t('report_value_hour.hours')}`}
          </Typography>
          <Typography color="#1E6B7F" component="span" fontSize={12}>
            {t('report_value_hour.cards.time_without_register')}
          </Typography>
        </CardContent>
        <div className={`${style.badgePercent} ${style.grey}`}>
          {`${stats?.percent_offline_hours || 0}%`}
        </div>
      </Card>

      <Card 
        variant="outlined"
        style={{ border: 'none', display: 'flex', flex: 1, minWidth: '165px' }}
      >
        <CardContent style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '1rem', borderLeft: '2px solid #187DFF' }}>
          <Wallet size={18} color={'#187DFF'} weight="bold" />
          <Typography color={'#187DFF'} component="span" fontSize={18} my={1}>
            {`${befetiveFormatCurrency(stats?.total_salary || 0, lang, true)}`}
          </Typography>
          <Typography color={'#1E6B7F'} component="span" fontSize={12}>
            {t('report_value_hour.cards.value_amount_paid')}
          </Typography>
        </CardContent>
      </Card>

      <Card 
        variant="outlined"
        style={{ border: 'none', display: 'flex', flex: 1, minWidth: '165px' }}
      >
        <CardContent style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '1rem', borderLeft: '2px solid #FF7A90' }}>
          <CurrencyDollar size={18} color={'#FF7A90'} weight="bold" />
          <Typography color={'#FF7A90'} component="span" fontSize={18} my={1}>
           {`${befetiveFormatCurrency(stats?.stats_avg_hour_value || 0, lang, true)}`}
          </Typography>
          <Typography color={'#1E6B7F'} component="span" fontSize={12}>
            {t('report_value_hour.cards.avtg_value_hour')}
          </Typography>
        </CardContent>
      </Card>

    </Stack>
  );
}
