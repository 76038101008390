import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import { Grid, Skeleton, Typography } from "@mui/material";
import BoardsArchivedList from "../../_components/project_management/BoardsArchivedList";

function ProjectManagementBoardsArchivedPage(props) {
  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions } = props
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  
  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const getBoardsArchived = async (isLoading=true) => {
    try {
      setLoading(true)

      const { data } = await api.get('/project-management/boards/archive', {
        headers: authHeader()
      })

      setData(data)
      
    } catch (error) {
      console.error(error.response)
      //alertActions.error(t('project_management.errors.load'))
      
    }finally{
      setLoading(false)
    }
  }

  

  useEffect(() => {
    getBoardsArchived()    
    document.title = 'Befective | ' + t('breadcrub.project_management.archived_boards');
  }, [])
  
  return (
    <section className="mainContent productivity">

      <Toolbar />

      <Filterbar
        filterWho={'disabled'}
        filterWhen={'disabled'}
        filterWhat={'disabled'}
      />

      <div className={style.containerBoardsPage}>
        {
          (!loading) ?
            (
              data && data.length > 0
              ?
                <BoardsArchivedList
                  data={data}
                  userDetails={userDetails}
                  alertActions={alertActions}
                  getData={getBoardsArchived}
                />
                :
                <div className={"boxNone"}>
                  <p className={"textNone"}>
                    {t('project_management.no_archived_boards')}
                  </p>
                </div>
            )
            :
            <Grid container spacing={2}>
              {
                Array.from(new Array(3)).map((_, index) => (
                  <Grid key={index} xs={12} sm={6} md={4} lg={3} xl={3} item>
                    <Skeleton variant="rectangular" width={'100%'} height={70} />
                  </Grid>
                ))
              }
            </Grid>
        }
      </div>
    </section>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagementBoardsArchivedPage)