import React, {  useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { usersActions } from '../../../_actions';
import utf8 from 'utf8'
import Select from 'react-select'
import chroma from "chroma-js";
import { authHeader } from '../../../_helpers';
import { api } from '../../../_helpers/api';

const colourStyles = {
	control: (styles) => ({
		...styles,
		backgroundColor: 'white',
		zIndex: 1000,
		minHeight: 48,
		borderRadius: 2,
		border: 'solid 1px #D6DEEB',
		display: 'flex',
		color: '#0D1F40',
		cursor: 'pointer',
		webkitAppearance: 'none',
		transition: 'all 0.3s ease',
		':focus': {
			outline: 0,
			border: 'solid 1px #187DFF',
		},
		':active': {
			outline: 0,
			border: 'solid 1px #187DFF',
		}
	}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		const color = chroma(data.color ?? 'black');
		return {
			...styles,
			backgroundColor: isDisabled
				? undefined
				: isSelected
					? data.color
					: isFocused
						? color.alpha(0.1).css()
						: undefined,
			color: isDisabled
				? '#ccc'
				: isSelected
					? chroma.contrast(color, 'white') > 2
						? 'white'
						: 'black'
					: data.color,
			cursor: isDisabled ? 'not-allowed' : 'default',

			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled
					? isSelected
						? data.color
						: color.alpha(0.3).css()
					: undefined,
			},
		};
	},
	multiValue: (styles, { data }) => {
		return {
			...styles,
			height: 37,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: '#E8F1FE',
		};
	},
	multiValueLabel: (styles, { data }) => ({
		...styles,
		color: data.color,
	}),
	multiValueRemove: (styles, { data }) => ({
		...styles,
		color: data.color,
		':hover': {
			backgroundColor: data.color,
			color: 'red',
		},
	}),
};

function SettingsProfile(props) {
  const { t } = useTranslation('common');

  const { usersActions, userDetails } = props
  
  let updated = props.updated || false
  let currentUser = props.userDetails || {}
  
  const [submitted, setSubmitted] = useState(false)
  const [dailyNotificationOptions, setDailyNotificationOptions] = useState([])
  // const [activeDailyNotification, setActiveDailyNotification] = useState([])

  const [form, setForm] = useState({
    name: '',
    email: '',
    language: '',
    timezone: '',
    initialPreview: '',
    initialScreen: '',
    asideMenu: '',
    active_daily_notification:""
  })
  
  // console.log("/activeDailyNotification",activeDailyNotification)
  const [formError, setFormError] = useState({
    name: '',
    email: '',
    language: '',
    timezone: ''
  })

  const handleChange = e => {
    setSubmitted(false)

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })

  }
  const getActiveNotification = async () => {
    try {


      const res = await api.get(`/settings/notification-options`, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro get users')
      }
      setDailyNotificationOptions(res.data)

    } catch (error) {
      console.error(error)
    }
  }

 const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitted(true)

    let formIsValid = true

    if (!form["name"]) {
      formIsValid = false
      setFormError({
        ...formError,
        name: t('settings.company.error.required'),
      })
    }

    if (!form["email"]) {
      formIsValid = false
      setFormError({
        ...formError,
        email: t('settings.company.error.required'),
      })
    }

    if (formIsValid) {

      setFormError({
        name: '',
        email: '',
        language: '',
        timezone: ''
      })

      await usersActions.patch(form, currentUser.id)
      await usersActions.refresh(userDetails)
    }
    else {
      setSubmitted(false)
    }

  }

  function cancel() {

    setFormError({
      name: '',
      email: '',
      language: '',
      timezone: ''
    })

    setForm({
      name: utf8.decode(currentUser.name),
      email: currentUser.email,
      language: currentUser.language,
      timezone: currentUser.timezone,
      initialPreview: currentUser.initialPreview,
      initialScreen: currentUser.initialScreen,
      asideMenu: currentUser.asideMenu,
      active_daily_notification: userDetails.active_daily_notification
    })
  }

  useEffect(() => {

    setForm({
      name: utf8.decode(currentUser.name),
      email: currentUser.email,
      language: currentUser.language,
      timezone: currentUser.timezone,
      initialPreview: currentUser.initialPreview,
      initialScreen: currentUser.initialScreen,
      asideMenu: currentUser.asideMenu,
      active_daily_notification: userDetails.active_daily_notification
    })
    getActiveNotification()

  }, [])

  useEffect(() => {

    if (updated == 'error') {
      setSubmitted(false)
    }

    if (updated == 'success') {
      setSubmitted(false)
      usersActions.refresh(userDetails)
    }

  }, [updated])


  return (

    <form onSubmit={handleSubmit} className="form">
      <div className="field">
        <label htmlFor="profileName">{t('profile.name')}</label>
        <input id="profileName" type="text" name="name" value={form.name} onChange={handleChange} />
      </div>
      <div className="field">
        <label>Email</label>
        <input type="email" value={form.email} disabled="disabled" />
      </div>
      <div className="field">
        <label>{t('profile.language')}</label>
        <select name="language" value={form.language} onChange={handleChange}>
          <option value="en">English</option>
          <option value="es">Español</option>
          <option value="pt">Português</option>
        </select>
      </div>

      <div className="field">
        <label>{t('profile.timezone')}</label>
        <select name="timezone" value={form.timezone} onChange={handleChange}>
          <option value="Abu Dhabi">Abu Dhabi +04:00</option>
          <option value="Adelaide">Adelaide +09:30</option>
          <option value="Alaska">Alaska -09:00</option>
          <option value="Almaty">Almaty +06:00</option>
          <option value="American Samoa">American Samoa -11:00</option>
          <option value="Amsterdam">Amsterdam +01:00</option>
          <option value="Arizona">Arizona -07:00</option>
          <option value="Astana">Astana +06:00</option>
          <option value="Athens">Athens +02:00</option>
          <option value="Atlantic Time (Canada)">Atlantic Time (Canada) -04:00</option>
          <option value="Auckland">Auckland +12:00</option>
          <option value="Azores">Azores -01:00</option>
          <option value="Baghdad">Baghdad +03:00</option>
          <option value="Baku">Baku +04:00</option>
          <option value="Bangkok">Bangkok +07:00</option>
          <option value="Beijing">Beijing +08:00</option>
          <option value="Belgrade">Belgrade +01:00</option>
          <option value="Berlin">Berlin +01:00</option>
          <option value="Bern">Bern +01:00</option>
          <option value="Bogota">Bogota -05:00</option>
          <option value="Brasilia">Brasilia -03:00</option>
          <option value="Bratislava">Bratislava +01:00</option>
          <option value="Brisbane">Brisbane +10:00</option>
          <option value="Brussels">Brussels +01:00</option>
          <option value="Bucharest">Bucharest +02:00</option>
          <option value="Budapest">Budapest +01:00</option>
          <option value="Buenos Aires">Buenos Aires -03:00</option>
          <option value="Cairo">Cairo +02:00</option>
          <option value="Canberra">Canberra +10:00</option>
          <option value="Cape Verde Is.">Cape Verde Is. -01:00</option>
          <option value="Caracas">Caracas -04:00</option>
          <option value="Casablanca">Casablanca +00:00</option>
          <option value="Central America">Central America -06:00</option>
          <option value="Central Time (US &amp; Canada)">Central Time (US &amp; Canada) -06:00</option>
          <option value="Chatham Is.">Chatham Is. +12:45</option>
          <option value="Chennai">Chennai +05:30</option>
          <option value="Chihuahua">Chihuahua -07:00</option>
          <option value="Chongqing">Chongqing +08:00</option>
          <option value="Copenhagen">Copenhagen +01:00</option>
          <option value="Darwin">Darwin +09:30</option>
          <option value="Dhaka">Dhaka +06:00</option>
          <option value="Dublin">Dublin +00:00</option>
          <option value="Eastern Time (US &amp; Canada)">Eastern Time (US &amp; Canada) -05:00</option>
          <option value="Edinburgh">Edinburgh +00:00</option>
          <option value="Ekaterinburg">Ekaterinburg +05:00</option>
          <option value="Fiji">Fiji +12:00</option>
          <option value="Georgetown">Georgetown -04:00</option>
          <option value="Greenland">Greenland -03:00</option>
          <option value="Guadalajara">Guadalajara -06:00</option>
          <option value="Guam">Guam +10:00</option>
          <option value="Hanoi">Hanoi +07:00</option>
          <option value="Harare">Harare +02:00</option>
          <option value="Hawaii">Hawaii -10:00</option>
          <option value="Helsinki">Helsinki +02:00</option>
          <option value="Hobart">Hobart +10:00</option>
          <option value="Hong Kong">Hong Kong +08:00</option>
          <option value="Indiana (East)">Indiana (East) -05:00</option>
          <option value="International Date Line West">International Date Line West -11:00</option>
          <option value="Irkutsk">Irkutsk +08:00</option>
          <option value="Islamabad">Islamabad +05:00</option>
          <option value="Istanbul">Istanbul +03:00</option>
          <option value="Jakarta">Jakarta +07:00</option>
          <option value="Jerusalem">Jerusalem +02:00</option>
          <option value="Kabul">Kabul +04:30</option>
          <option value="Kaliningrad">Kaliningrad +02:00</option>
          <option value="Kamchatka">Kamchatka +12:00</option>
          <option value="Karachi">Karachi +05:00</option>
          <option value="Kathmandu">Kathmandu +05:45</option>
          <option value="Kolkata">Kolkata +05:30</option>
          <option value="Krasnoyarsk">Krasnoyarsk +07:00</option>
          <option value="Kuala Lumpur">Kuala Lumpur +08:00</option>
          <option value="Kuwait">Kuwait +03:00</option>
          <option value="Kyiv">Kyiv +02:00</option>
          <option value="La Paz">La Paz -04:00</option>
          <option value="Lima">Lima -05:00</option>
          <option value="Lisbon">Lisbon +00:00</option>
          <option value="Ljubljana">Ljubljana +01:00</option>
          <option value="London">London +00:00</option>
          <option value="Madrid">Madrid +01:00</option>
          <option value="Magadan">Magadan +11:00</option>
          <option value="Marshall Is.">Marshall Is. +12:00</option>
          <option value="Mazatlan">Mazatlan -07:00</option>
          <option value="Melbourne">Melbourne +10:00</option>
          <option value="Mexico City">Mexico City -06:00</option>
          <option value="Mid-Atlantic">Mid-Atlantic -02:00</option>
          <option value="Midway Island">Midway Island -11:00</option>
          <option value="Minsk">Minsk +03:00</option>
          <option value="Monrovia">Monrovia +00:00</option>
          <option value="Monterrey">Monterrey -06:00</option>
          <option value="Montevideo">Montevideo -03:00</option>
          <option value="Moscow">Moscow +03:00</option>
          <option value="Mountain Time (US &amp; Canada)">Mountain Time (US &amp; Canada) -07:00</option>
          <option value="Mumbai">Mumbai +05:30</option>
          <option value="Muscat">Muscat +04:00</option>
          <option value="Nairobi">Nairobi +03:00</option>
          <option value="New Caledonia">New Caledonia +11:00</option>
          <option value="New Delhi">New Delhi +05:30</option>
          <option value="Newfoundland">Newfoundland -03:30</option>
          <option value="Novosibirsk">Novosibirsk +07:00</option>
          <option value="Nuku'alofa">Nuku'alofa +13:00</option>
          <option value="Osaka">Osaka +09:00</option>
          <option value="Pacific Time (US &amp; Canada)">Pacific Time (US &amp; Canada) -08:00</option>
          <option value="Paris">Paris +01:00</option>
          <option value="Perth">Perth +08:00</option>
          <option value="Port Moresby">Port Moresby +10:00</option>
          <option value="Prague">Prague +01:00</option>
          <option value="Pretoria">Pretoria +02:00</option>
          <option value="Quito">Quito -05:00</option>
          <option value="Rangoon">Rangoon +06:30</option>
          <option value="Riga">Riga +02:00</option>
          <option value="Riyadh">Riyadh +03:00</option>
          <option value="Rome">Rome +01:00</option>
          <option value="Samara">Samara +04:00</option>
          <option value="Samoa">Samoa +13:00</option>
          <option value="Santiago">Santiago -04:00</option>
          <option value="Sapporo">Sapporo +09:00</option>
          <option value="Sarajevo">Sarajevo +01:00</option>
          <option value="Saskatchewan">Saskatchewan -06:00</option>
          <option value="Seoul">Seoul +09:00</option>
          <option value="Singapore">Singapore +08:00</option>
          <option value="Skopje">Skopje +01:00</option>
          <option value="Sofia">Sofia +02:00</option>
          <option value="Solomon Is.">Solomon Is. +11:00</option>
          <option value="Srednekolymsk">Srednekolymsk +11:00</option>
          <option value="Sri Jayawardenepura">Sri Jayawardenepura +05:30</option>
          <option value="St. Petersburg">St. Petersburg +03:00</option>
          <option value="Stockholm">Stockholm +01:00</option>
          <option value="Sydney">Sydney +10:00</option>
          <option value="Taipei">Taipei +08:00</option>
          <option value="Tallinn">Tallinn +02:00</option>
          <option value="Tashkent">Tashkent +05:00</option>
          <option value="Tbilisi">Tbilisi +04:00</option>
          <option value="Tehran">Tehran +03:30</option>
          <option value="Tijuana">Tijuana -08:00</option>
          <option value="Tokelau Is.">Tokelau Is. +13:00</option>
          <option value="Tokyo">Tokyo +09:00</option>
          <option value="UTC">UTC +00:00</option>
          <option value="Ulaanbaatar">Ulaanbaatar +08:00</option>
          <option value="Urumqi">Urumqi +06:00</option>
          <option value="Vienna">Vienna +01:00</option>
          <option value="Vilnius">Vilnius +02:00</option>
          <option value="Vladivostok">Vladivostok +10:00</option>
          <option value="Volgograd">Volgograd +03:00</option>
          <option value="Warsaw">Warsaw +01:00</option>
          <option value="Wellington">Wellington +12:00</option>
          <option value="West Central Africa">West Central Africa +01:00</option>
          <option value="Yakutsk">Yakutsk +09:00</option>
          <option value="Yerevan">Yerevan +04:00</option>
          <option value="Zagreb">Zagreb +01:00</option>
        </select>
      </div>
      
      <div className="field">
        <label>{t('settings.initial_preview')}</label>
        <select name="initialPreview" value={form.initialPreview} onChange={handleChange}>
          <option value="today">{t('menu_settings_view.today')}</option>
          <option value="yesterday">{t('menu_settings_view.yesterday')}</option>
          <option value="current_week">{t('menu_settings_view.current_week')}</option>
          <option value="last_week">{t('filter.last_week')}</option>
          <option value="this_month">{t('filter.this_month')}</option>
          <option value="last_month">{t('filter.last_month')}</option>
        </select>
      </div>

      <div className="field">
        <label>{t('settings.initial_screen')}</label>
        <select name="initialScreen" value={form.initialScreen} onChange={handleChange}>
          <option value="executive">{t("menu.today")}</option>
          <option value="realtime">{t('menu.reports')} - {t("menu.realtime")}</option>
          <option value="time_goal">{t('menu.reports')} - {t("menu.time_goal")}</option>
          <option value="work_goal">{t('menu.reports')} - {t("menu.work_goal")}</option>
          <option value="mood">{t('menu.reports')} - {t("menu.mood")}</option>
          <option value="report-teams">{t('menu.reports')} - {t("menu.teams")}</option>
          <option value="teammates">{t('menu.reports')} - {t("menu.teammates")}</option>
          <option value="inactivity">{t('menu.reports')} - {t("menu.inactivity")}</option>
          <option value="activities">{t("menu.activities")}</option>
          <option value="history">{t("menu.historical")}</option>
          {/* <option value="teams">{t("menu.teams")}</option> */}
          <option value="running">{t("menu.goals")} - {t("breadcrub.running")}</option>
          <option value="completed">{t("menu.goals")} - {t("breadcrub.completed")}</option>
        </select>
      </div>
      {(userDetails.profile_key === 'admin' || userDetails?.isMagicAgent === true) && (

        <div className="field" style={{ display: 'flex', flexDirection: 'column' }}>
        <label htmlFor="active_daily_notification">{t('settings.company.daily_notification_option')}</label>
        <Select
          id='active_daily_notification'
          isMulti
          defaultValue={userDetails?.active_daily_notification || ''}
          name="active_daily_notification"
          options={dailyNotificationOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={(e)=>{
            setForm({
              ...form,
              active_daily_notification:e
            })
          } }
          styles={colourStyles}
          placeholder={t('filter.select')}
          />
        </div>
        )}

      <div className="field">
        <label>{t('settings.aside_menu')}</label>
        <select name="asideMenu" value={form.asideMenu} onChange={handleChange}>
          <option value="open">{t('settings.open')}</option>
          <option value="close">{t('settings.closed')}</option>
        </select>
      </div>
      

      <div className="field">
        {(submitted && (!updated)) &&
          <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        }
        <button type="button" onClick={() => cancel() }>{t('profile.cancel')}</button>
        <button type="submit">{t('profile.update')}</button>
      </div>
    </form>


  )
};



function mapStateToProps(state) {

  return {
    token: state.authentication.user.accessToken,
    userDetails: state.authentication.userDetails,
    updated: state.users.updated
  }
}

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsProfile));