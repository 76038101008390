import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'

import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import TeammatesFull from '../_components/reports/teammates/TeammatesFull'
import TeammatesFullSkeleton from '../_components/reports/teammates/TeammatesFullSkeleton'
import Moment from 'moment';

import {useTranslation, initReactI18next} from "react-i18next";


import {goalsActions, statsActions, shareActions} from '../_actions';
import ShareModal from '../_components/_shared/ShareModal';
import { api } from '../_helpers/api';
import { authHeader } from '../_helpers';


function TeammatesPage(props) {
    const {t, i18n} = useTranslation('common');

    const {goalsActions} = props;
    const {statsActions} = props;
    const {shareActions} = props;

    let loading = props.loading;
    let loadingStats = props.loadingStats;
    let filter = props.filter || {};

    const [stats, setStats] = useState(null);
    const [dataTeammates, setDataTeammates] = useState({rowCount: 0, data: []});
    const [fetching, setFetching] = useState(true);
    const [count, setCount] = useState(0);
    const [pageCountRows, setPageCountRows] = useState(0);

    const [globalFilter, setGlobalFilter] = useState({
        page: 0,
        pageSize: localStorage.getItem('pageSizeStorage') || 20,
        profiles: null
    });

    function closeShareModal() {
        shareActions.closeModal()
    }


    const fetchData = async ({isLoading, page, pageSize, profiles, startDate, endDate, teams, teammates}) => {
        try {
            if(isLoading) {
                setFetching(true)
            }

            const filterStartDate = startDate ? `&startDate=${startDate}` : '';
            const filterEndDate = endDate ? `&endDate=${endDate}` : '';
            const filterTeams = teams ? `&teamIds=${teams.map(t => t.id).join(',')}` : '';
            const filterTeammates = teammates ? `&machineUserIds=${teammates.map(t => t.id).join(',')}` : '';
            const filterProfiles = (profiles && profiles.length > 0) ? `&profiles=${profiles.map(p => p.value).join(',')}` : '';
            
            const responseStats = await api.get(`/stats/?page=${page}&per_page=${pageSize}${filterProfiles}${filterStartDate}${filterEndDate}${filterTeams}${filterTeammates}`, {
                headers: authHeader()
            })

            setStats(responseStats.data)

            const  responseDataTeammates = await api.get(`/stats/goals/teammates/?page=${page}&per_page=${pageSize}${filterProfiles}${filterStartDate}${filterEndDate}${filterTeams}${filterTeammates}`, {
                headers: authHeader()
            })
            
            setCount(Math.ceil(parseInt(responseDataTeammates.data.rowCount) / pageSize))
            setPageCountRows(parseInt(responseDataTeammates.data.rowCount))
            setDataTeammates(responseDataTeammates.data)

        } catch (error) {
            console.log(error)
        }finally{
            setFetching(false)
        }
    }

    const getGlobalData = async ({isLoading, page, pageSize, profiles}) => {
        let startDate = Moment(new Date()).format('YYYY-MM-DD');
        let endDate = Moment(new Date()).format('YYYY-MM-DD');

        let teams = null;
        let teammates = null;

        if (filter.date) {
            startDate = filter.date.startDate;
            endDate = filter.date.endDate;
        }

        if (filter.teams) {
            teams = filter.teams;
        }
        if (filter.teammates) {
            teammates = filter.teammates;
        }


        setGlobalFilter({page: page, pageSize: pageSize, profiles: profiles})

        await fetchData({isLoading: isLoading, page: page, pageSize: pageSize, profiles: profiles, startDate, endDate, teams, teammates});
    }

    useEffect(() => {
        getGlobalData({isLoading: true, page: globalFilter.page, pageSize: globalFilter.pageSize, profiles: globalFilter.profiles});
    }, [filter])

    useEffect(() => {
        document.title = 'Befective | ' + t('menu.reports') + ' | ' + t('menu.teammates');
    },[]);

    return (
        <React.Fragment>
            <section className="mainContent">
                <Toolbar/>
                <Filterbar filterProfiles={"enabled"} getFilterProfiles={getGlobalData} globalFilter={globalFilter}/>
                {/* PAGE STARTS */}
                <div className="mainPage">
                    <div className="column single">
                        {
                            fetching
                                ? <TeammatesFullSkeleton/>
                                : <TeammatesFull 
                                    goals={dataTeammates.data} 
                                    stats={stats} 
                                    getData={getGlobalData}
                                    count={count}
                                    pageCountRows={pageCountRows}
                                    globalFilter={globalFilter}
                                    />
                        }
                    </div>


                </div>
                {/* PAGE ENDS */}
            </section>

            <div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
                closeShareModal()
            }}></div>

            <section className={'modal' + (props.share.modal ? ' open' : '')}>
                <ShareModal reports='teammates' filters={{...filter, profiles: globalFilter.profiles}}/>
            </section>
        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        goalsActions: bindActionCreators(goalsActions, dispatch),
        statsActions: bindActionCreators(statsActions, dispatch),
        shareActions: bindActionCreators(shareActions, dispatch)
    }
}


function mapStateToProps(state) {

    return {
        loading: state.goals.loadingTeammates,
        loadingStats: state.stats.loading,
        filter: state.filter,
        share: state.share
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(TeammatesPage)
