import React from 'react'
import style from './styles.module.scss';
import { Box, Skeleton } from '@mui/material';

export default function Widget13Skeleton() {

  return (
    <div className={style.container}>
      <Box display={'flex'}>
        <Skeleton variant='h6' width={'30%'} />
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={'6px'}>
        <Skeleton variant="rectangular" width={'100%'} height={240} />
      </Box>
    </div>
  )
}