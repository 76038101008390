import React, { useEffect, useState } from 'react';
import { Card, CardContent, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import style from './StatsCardHeader.module.scss';
import { CalendarCheck, ChartLineUp, CheckCircle, Checks, UserCircle,  WarningCircle } from 'phosphor-react';
import { Typography } from '@material-ui/core';
import { befectiveFormatDate } from '../../../_helpers';

export function StatsCardHeader({ alertActions, userDetails, stats }) {
  const { t, i18n } = useTranslation('common');
  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >
      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #187DFF' }}>
          <ChartLineUp size={18} color={'#187DFF'} weight="bold" />
          <Typography className={style.textStats} style={{color: "#187DFF"}}>
            {`${stats?.general_progress || 0}%`}
          </Typography>
          <Typography className={style.textInfo} component={'span'}>
            {t("performance_assessment.page_action_plan.stats.general_progress")}
          </Typography>
        </CardContent>
      </Card>
      

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #22BEAD' }}>
          <CheckCircle size={18} color={'#22BEAD'} weight="bold" />
          <Typography className={style.textStats} style={{color: "#22BEAD"}}>
            {stats?.stats_plans || '0/0'}
          </Typography>
          <Typography className={style.textInfo} component={'span'}>
            {t("performance_assessment.page_action_plan.stats.plan_completed")}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #22BEAD' }}>
          <Checks size={18} color={'#22BEAD'} weight="bold" />
          <Typography className={style.textStats} style={{color: "#22BEAD"}}>
            {stats?.stats_items || '0/0'}
          </Typography>
          <Typography className={style.textInfo} component={'span'}>
            {t("performance_assessment.page_action_plan.stats.item_completed")}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #187DFF' }}>
          <UserCircle size={18} color={'#187DFF'} weight="bold" />
          <Typography className={style.textStats} style={{color: "#187DFF"}}>
            {`${stats?.most_item_user?.evaluated_name || '-----'}`}
          </Typography>
          <Typography className={style.textInfo} component={'span'}>
            {t("performance_assessment.page_action_plan.stats.colab_most_itens")}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #FC6662' }}>
          <CalendarCheck size={18} color="#FC6662" weight="bold" />
          <Typography className={style.textStats} style={{color: "#FC6662"}}>
            {befectiveFormatDate(stats?.close_to_expiration, i18n.language)}
          </Typography>
          <Typography className={style.textInfo} component="span">
            {t("performance_assessment.page_action_plan.stats.next_due")}
          </Typography>
        </CardContent>
      </Card>

    </Stack>
  );
}
