import { Backdrop, Modal, Slide, Switch, makeStyles, withStyles } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import axios from 'axios';
import GoogleMapReact from 'google-map-react';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { authHeader, delay, getAvatarUrl } from '../../../_helpers';
import { api } from '../../../_helpers/api';
import IconIn from '../../_icons/IconIn';
import './styles.scss';
// import Switch from 'react-ios-switch';
import Geocode from "react-geocode";
import utf8 from 'utf8';
// import { DragSwitch } from 'react-dragswitch'
// import 'react-dragswitch/dist/index.css'

const AnyReactComponent = ({ text }) => <RoomIcon style={{ fill: '#FF5151' }} />;

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    overflow: 'inherit'

  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#22BEAD',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#22BEAD',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function ModalActionCheckIn(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('common')

  const { openModalActionCheckIn, setOpenModalActionCheckIn, userDetails, teammateDetails, teammatesActions, showToast, getColabPoints,settings, alertActions } = props;

  
  const [isChecked, setIsChecked] = useState(false);
  const [checkedTypeLocation, setCheckedTypeLocation] = useState(teammateDetails?.last_type_workplace === 'H');
  const [location, setLocation] = useState(null);
  const [ip, setIp] = useState('');
  const [address, setAddress] = useState('');
  const [sending, setSending] = useState(false);

  // AIzaSyDBsCiWrArMVf2ggd6rEQkwsttW-CUrugA
  function handleCloseModalActionCheckIn() {
    setOpenModalActionCheckIn(false)
    setIsChecked(false)
  }
  
  const handleCheck = async () => {
    await delay(400);

    setIsChecked(!isChecked)

    const res = await axios.get("https://api.ipify.org/?format=json");
    setIp(res.data.ip)

    if(!settings.allow_geolocation){
      return;
    }
    if (!navigator.geolocation) {
      axios.get(`https://ipinfo.io/json?token=34e1af23ec8841`)
        .then(({ data }) => {
          const [lat, lng] = data.loc.split(',')
          setLocation({
            lat: Number(lat),
            lng: Number(lng)
          });
          setAddress(`${data.city}, ${data.region} / ${data.country} - ${data.postal}`)
        })
        .catch(e => {
          console.log(e);
        });
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setLocation({
          lat: pos.coords.latitude,
          lng: pos.coords.longitude
        });
        getAddress(pos.coords.latitude, pos.coords.longitude, "AIzaSyDBsCiWrArMVf2ggd6rEQkwsttW-CUrugA")
      },
      (err) => {
        if (err.code === err.PERMISSION_DENIED) {
          axios.get(`https://ipinfo.io/json?token=34e1af23ec8841`)
            .then(({ data }) => {
              const [lat, lng] = data.loc.split(',')
              setLocation({
                lat: Number(lat),
                lng: Number(lng)
              });
              getAddress(lat, lng, "AIzaSyDBsCiWrArMVf2ggd6rEQkwsttW-CUrugA")
            })
            .catch(e => {
              console.log(e);
            });
        }
      }
    );
  }

  const formatPeriods = (periods) => {
   
    if (!periods) {
      return ' ' + t('periods.none')
    }

    let strBuildPeriods = '';
    for (const period of periods) {
      strBuildPeriods += ` ${String(period.start)} - ${String(period.end)} /`
    }
    return strBuildPeriods.slice(0, -1)
  }

  const handleSubmitCheckIn = async (e) => {
    e.preventDefault()
    try {
      setSending(true)
      const payload = {
        moment: moment().format('YYYY-MM-DDTHH:mm:ss'),
        latitude: location ? location.lat : "",
        longitude: location ? location.lng : "",
        ip_address: ip,
        location_type: checkedTypeLocation === true ? 'H' : 'E',
        address: address
      }
      
      const res = await api.post('/checkin/point', payload, {
        headers: authHeader()
      });
      teammatesActions.getTeammate(userDetails.id)
      handleCloseModalActionCheckIn()
      setSending(false)

      if (res.data) {
        const { type, receipt_token, created_at } = res.data;
        showToast(type, receipt_token, created_at)
        getColabPoints()
      }


    } catch (e) {
      // console.log("/error-01 ",e)
      // console.log("/error-e.response.data ",e.response.data)

      alertActions.error(e.response.data.message || t('checkin.dashboard.alerts.create_point_error'))
      setSending(false)
    }
  }

  const getAddress = (lat, lng, googleKey) => {
    Geocode.setApiKey(googleKey);
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setAddress(address)
      },
      (error) => {
        console.error(error);
      }
    );
  }

  return (
    <>
      <Modal
        id='modalActionCheckIn'
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalActionCheckIn}
        onClose={handleCloseModalActionCheckIn}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#205B6F', opacity: 0.8 }
        }}
        sx={{ border: 'none' }}
      >
        <Slide direction="down" in={openModalActionCheckIn} style={{ outline: 0 }} mountOnEnter unmountOnExit>
          <Box className="containerModalActionCheckIn">
            <Box
              display="flex"
              bgcolor="#fff"
              flexDirection="column"
              zIndex={2}
              py="32px"
              px="26px"
              borderRadius="8px"
              gap="28px"
              width={"320px"}
              component="form"
              noValidate
              autoComplete='off'
              position="relative"
              onSubmit={handleSubmitCheckIn}
            >
              {
                !isChecked &&
                <Box className="form-content">

                  <Box className="header-info">
                    <Box>
                      <IconIn fill="#22BEAD" />
                    </Box>
                    <Typography variant='h6'>
                      {teammateDetails?.last_point_type === "IN" && `${t("checkin.dashboard.modal_action.slide_message")} check-out`}
                      {(!teammateDetails || teammateDetails?.last_point_type === null || teammateDetails?.last_point_type === "OUT") && `${t("checkin.dashboard.modal_action.slide_message")} check-in`}
                    </Typography>
                  </Box>

                  <Box className="checked-box">
                    <input type="checkbox" id="check1" className="toggle" value={isChecked} onChange={handleCheck} />
                    <label htmlFor="check1"></label>
                  </Box>
                </Box>
               }
              {
                isChecked &&
                <Grid className="form-confirm-content" container>
                  <Grid item xs={12} className='info-user'>
                    <Box display={'flex'} alignItems={'center'}>
                      <Avatar
                        alt={userDetails?.name}
                        src={getAvatarUrl({ name: userDetails?.name })}
                        style={{ width: 50, height: 50 }}
                      />
                    </Box>
                    <Box className="info-user-content">
                      <Typography variant='h6' >{utf8.decode(userDetails?.name)}</Typography>
                      {userDetails?.job_name && <Typography variant='caption'>{userDetails?.job_name}</Typography>}
                      <Typography variant='caption'>{t("checkin.dashboard.modal_action.periods") + ": " + formatPeriods(teammateDetails?.periods)}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} className='info-moment'>
                    <Box>
                      <Typography variant='h3'>{moment().format('HH:mm')}</Typography>
                      <Typography variant='subtitle2'>{String(new Date().toTimeString()).slice(8)}</Typography>
                    </Box>
                    <Box>
                      <Typography variant='subtitle2'>{t("checkin.dashboard.modal_action.last_record")}</Typography>
                      <Typography variant='subtitle2'>{t("checkin.dashboard.modal_action.today") + ": "}{teammateDetails?.last_point_moment !== null ? `${String(new Date(teammateDetails?.last_point_moment).toTimeString()).slice(0, 5)} (${teammateDetails?.last_point_type === 'IN' ? 'check-in' : 'check-out'})` : '--:-- (-------)'}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} className='location-content'>
                    <Box className="header-location">
                      <Grid component="label" container alignItems="center" width={'100%'} justifyContent={'space-between'}>
                        <Grid item color={!checkedTypeLocation ? "#22BEAD" : "#98ACC1"} fontWeight={!checkedTypeLocation ? "500" : "400"}>{t("checkin.dashboard.modal_action.in_company")}</Grid>
                        <Grid item>
                          <IOSSwitch checked={checkedTypeLocation} onChange={(e) => setCheckedTypeLocation(e.target.checked)} name="checkedTypeLocation" />
                        </Grid>
                        <Grid item color={checkedTypeLocation ? "#22BEAD" : "#98ACC1"} fontWeight={checkedTypeLocation ? "500" : "400"}>{t("checkin.dashboard.modal_action.home_office")}</Grid>
                      </Grid>
                    </Box>
                    {
                      settings.allow_geolocation === true ? 
                        <Box className="map-location-box">
                          {
                            location && <Box className="map-location">
                              <GoogleMapReact
                                bootstrapURLKeys={{ key: "AIzaSyDBsCiWrArMVf2ggd6rEQkwsttW-CUrugA" }}
                                defaultCenter={{
                                  lat: location.lat,
                                  lng: location.lng
                                }}
                                defaultZoom={17}
                              >
                                <AnyReactComponent
                                  lat={location.lat}
                                  lng={location.lng}
                                />
                              </GoogleMapReact>
                            </Box>
                          }
                        </Box>
                      : 
                      <></>
                    }
                    
                  </Grid>
                  <Grid item xs={12} className='footer-buttons' display='flex' gap='10px' paddingTop='24px'>
                    <button className='btn btn-cancel' type='button' onClick={handleCloseModalActionCheckIn}>{t("button.cancel")}</button>
                    <button className='btn btn-confirm-checkin' disabled={sending}>

                      {
                        !sending
                          ?
                          <>
                            {teammateDetails?.last_point_type === "IN" && "Check-out"}
                            {(teammateDetails?.last_point_type === null || teammateDetails?.last_point_type === "OUT") && "Check-in"}
                          </>
                          :
                          t("button.sending")
                      }
                    </button>
                  </Grid>
                </Grid>
              }

            </Box>
          </Box>
        </Slide>
      </Modal >
    </>
  )
}
