import { Backdrop, IconButton, Modal, Popover, Slide, makeStyles } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, AttachFile, Brightness2, Brightness5, Close, Delete } from '@material-ui/icons';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './ModalPreviewOutdoorGUI.module.scss';
import IconLogoBefective from '../_icons/IconLogoBefective';
import { replaceTemplate } from '../../_helpers';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export function ModalPreviewOutdoorGUI({
  openModalPreviewOutdoorGUI,
  handleCloseModalPreviewOutdoorGUI,
  data,
  templateString
}) {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [mode, setMode] = useState('dark');
  const [anchorElSelect, setAnchorElSelect] = useState(null);
  const openPopoverSelect = Boolean(anchorElSelect);
  const idPopoverSelect = openPopoverSelect ? 'popover-select-icon' : undefined;

  const handleClickSelect = (event, response) => {
    setAnchorElSelect(event.currentTarget);
  };

  const handleCloseSelect = () => {
    setAnchorElSelect(null);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModalPreviewOutdoorGUI}
      onClose={handleCloseModalPreviewOutdoorGUI}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: '#205B6F', opacity: 0.8 }
      }}
      sx={{ border: 'none' }}
      style={{ outline: 0 }}
    >
      <Slide direction="down" in={openModalPreviewOutdoorGUI} style={{ outline: 0 }} mountOnEnter unmountOnExit>
        <Box
          display="flex"
          bgcolor="#EEEEEE"
          flexDirection="column"
          zIndex={2}
          py="0px"
          px="0px"
          borderRadius="24px"
          width={560}
          component="div"
          noValidate
          autoComplete='off'
        >
          <header className={`${style.headerModal} ${mode === 'dark' ? style.dark : ''}`}>
              <div className={style.boxIconTitle}>
                <IconLogoBefective />
                <span className={mode === 'dark' ? style.dark : ''}>{t('global_settings.inactivities.preview.title')}</span>
              </div>
              <IconButton aria-label="delete" onClick={() => setMode(mode === 'light' ? 'dark' : 'light')} color="primary">
                {
                  mode === 'light'
                    ?
                    <Brightness2 fontSize="small" />
                    :
                    <Brightness5 fontSize="small" />
                }
              </IconButton>
          </header>
          <main className={`${style.mainModal} ${mode === 'dark' ? style.dark : ''}`}>
            <h6>
              {data?.pop_up_message || t('global_settings.inactivities.preview.pop_up_message')}
            </h6>
            <p>
              {replaceTemplate(templateString, { minutes: '30', start_time: '11:00', end_time: '11:30' })}
            </p>
            <ul>
              {
                data?.responses?.slice(0, 5)?.map((item, index) => (
                  <li key={`response_item_${index}`} className={`${style.outdoorItem} ${mode === 'dark' ? style.hoverDark : style.hoverLight}`}>
                    <img src={item.url_dark} alt={item.label} />
                    <span>{item.label}</span>
                  </li>
                ))
              }
            </ul>
            {
              data?.responses?.length > 5 &&
              <>
                <button className={style.customSelectOtherActivity} type='button' onClick={handleClickSelect}>
                  <span>{t('global_settings.inactivities.preview.other_activity')}</span>
                  <div className={style.boxArrow}>
                    {
                       !anchorElSelect
                        ?
                        <ArrowDropDown />
                        :
                        <ArrowDropUp />
                    }
                  </div>
                </button>
                <Popover
                  id={idPopoverSelect}
                  open={openPopoverSelect}
                  anchorEl={anchorElSelect}
                  onClose={handleCloseSelect}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  style={{width:'100%'}}
                >
                  <div className={`${style.dropdownContainer} ${mode === 'dark' ? style.dark : ''}`} >
                    {
                      data?.responses?.slice(5, data?.responses?.length)?.map((response, index) => {
                        return (
                          <div key={`response-item-${index}`} className={`${style.itemOthersResponse}`}>
                            {response.label}
                          </div>
                        )
                      })
                    }
                  </div>
                </Popover>
              </>
            }
          </main>
        </Box>
      </Slide>
    </Modal>
  )
}
