import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClockCounterClockwise, Percent, Smiley, SmileySad, Sun, Timer, Warning } from 'phosphor-react';
import { getDayWeek, getMoodById } from '../../../_helpers';
import moment from 'moment';
import { Card, CardContent } from '@material-ui/core';

export function MoodTeamsCards({ alertActions, userDetails, stats, handleOpenDrawerAlertsNegatives }) {
  
  const { t, i18n } = useTranslation('common');

  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >
      <Card
        variant="outlined"
        style={{ border: 'none', display: 'flex', flex: 1, minWidth: '165px' }}
      >
        <CardContent style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '1rem', borderLeft: '2px solid #12B48E' }}>
          <Smiley size={18} color="#12B48E" weight="bold" />
          <Typography color="#12B48E" component="span" fontSize={18} my={1}>
            {stats?.best_mood ? getDayWeek(moment(stats?.best_mood?.mood_date).weekday(), false) : '----'}
          </Typography>
          <Typography color="#1E6B7F" component="span" fontSize={12}>
            {t('reports_mood.cards.day_most_positive')}
          </Typography>
        </CardContent>
      </Card>

      <Card 
        variant="outlined"
        style={{ border: 'none', display: 'flex', flex: 1, minWidth: '165px' }}
      >
        <CardContent style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '1rem', borderLeft: '2px solid #FF7A90' }}>
          <SmileySad size={18} color={'#FF7A90'} weight="bold" />
          <Typography color={'#FF7A90'} component="span" fontSize={18} my={1}>            
            {stats?.worst_mood ? getDayWeek(moment(stats?.worst_mood?.mood_date).weekday(), false) : '----'}
          </Typography>
          <Typography color={'#1E6B7F'} component="span" fontSize={12}>
          {t('reports_mood.cards.day_most_negative')}
          </Typography>
        </CardContent>
      </Card>

      <Card 
        variant="outlined"
        style={{ border: 'none', display: 'flex', flex: 1, minWidth: '165px' }}
      >
        <CardContent style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '1rem', borderLeft: `2px solid ${getMoodById(stats?.avg_moods).color}` }}>
          <img src={getMoodById(stats?.avg_moods).img} alt={t(`widgets.moods.${getMoodById(stats?.avg_moods).key}`)} width={'16px'} height={'16px'}/>
          <Typography color={getMoodById(stats?.avg_moods).color} component="span" fontSize={18} my={1}>
            {stats?.avg_moods ? t(`widgets.moods.${getMoodById(stats?.avg_moods).key}`) : '----'}
          </Typography>
          <Typography color={'#1E6B7F'} component="span" fontSize={12}>
          {t('reports_mood.cards.avg_in_period')}
          </Typography>
        </CardContent>
      </Card>

      
      <Card 
        variant="outlined"
        style={{ border: 'none', display: 'flex', flex: 1, minWidth: '165px' }}
      >
        <CardContent style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '1rem', borderLeft: '2px solid #187DFF' }}>
          <ClockCounterClockwise size={18} color={'#187DFF'} weight="bold" />
          <Typography color={'#187DFF'} component="span" fontSize={18} my={1}>
            {`${stats?.total_moods || 0}/${stats?.total_mood_expected || 0}`}
          </Typography>
          <Typography color={'#1E6B7F'} component="span" fontSize={12}>
          {t('reports_mood.cards.answers_of_mood')}
          </Typography>
        </CardContent>
      </Card>

      <Card 
        variant="outlined"
        style={{ border: 'none', display: 'flex', flex: 1, minWidth: '165px', cursor: 'pointer' }}
        onClick={handleOpenDrawerAlertsNegatives}
      >
        <CardContent style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '1rem', borderLeft: '2px solid #FF7A90' }}>
          <Warning size={18} color={'#FF7A90'} weight="bold" />
          <Typography color={'#FF7A90'} component="span" fontSize={18} my={1}>
            {stats?.total_alert || 0}
          </Typography>
          <Typography color={'#FF7A90'} component="span" fontSize={12}>
          {t('reports_mood.cards.alerts_negatives')}
          </Typography>
        </CardContent>
      </Card>

    </Stack>
  );
}
