// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iBvKmZbA\\+s6SNRB8F-os-Q\\=\\= {\n  display: flex;\n  flex: 1;\n  min-width: 165px;\n  border: none !important;\n}\n\n.cDbyO7wdq5AnGUmWS\\+\\+B5w\\=\\= {\n  display: flex;\n  flex-direction: column;\n  padding: 0.75rem !important;\n}\n\n.TNySCQn-xhoD8FqNtIJI6w\\=\\= {\n  border-left: 2px solid #22BEAD;\n}\n\n.ZyCmynOV\\+nXiZ0qz46MppA\\=\\= {\n  border-left: 2px solid #FC6662;\n}\n\n.KtAs3YXQHu7xD10CI5RNqg\\=\\= {\n  font-size: 20px;\n  font-weight: 400;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.iGxRnIl32pB00Bd35sWZug\\=\\= {\n  font-size: 11px;\n  color: #1E6B7F;\n}\n\n.KtAs3YXQHu7xD10CI5RNqg\\=\\=.L\\+S1g9GVsYcO8usKTynCCw\\=\\= {\n  color: #FC6662;\n}\n\n.KtAs3YXQHu7xD10CI5RNqg\\=\\=.swuykbrpYrxSOLuQ0PzcAQ\\=\\= {\n  color: #22BEAD;\n}", "",{"version":3,"sources":["webpack://./src/_components/executive/Widget36/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,OAAA;EACA,gBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAGA;EACE,eAAA;EACA,cAAA;AAAF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".cardContainer {\n  display: flex;\n  flex: 1;\n  min-width: 165px;\n  border: none !important;\n}\n\n.cardHeader {\n  display: flex;\n  flex-direction: column;\n  padding: 0.75rem !important;\n}\n\n.borderLeftGreen {\n  border-left: 2px solid #22BEAD;\n}\n\n.borderLeftRed {\n  border-left: 2px solid #FC6662;\n}\n\n.titleCard{\n  font-size: 20px;\n  font-weight: 400;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n\n.subtitleCard{\n  font-size: 11px;\n  color: #1E6B7F;\n}\n.titleCard.colorRed{\n  color: #FC6662;\n}\n\n.titleCard.colorGreen{\n  color: #22BEAD;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": "iBvKmZbA+s6SNRB8F-os-Q==",
	"cardHeader": "cDbyO7wdq5AnGUmWS++B5w==",
	"borderLeftGreen": "TNySCQn-xhoD8FqNtIJI6w==",
	"borderLeftRed": "ZyCmynOV+nXiZ0qz46MppA==",
	"titleCard": "KtAs3YXQHu7xD10CI5RNqg==",
	"subtitleCard": "iGxRnIl32pB00Bd35sWZug==",
	"colorRed": "L+S1g9GVsYcO8usKTynCCw==",
	"colorGreen": "swuykbrpYrxSOLuQ0PzcAQ=="
};
export default ___CSS_LOADER_EXPORT___;
