// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S-6Sthatxm-PuHHbvU7tfQ\\=\\= {\n  display: flex;\n  flex: 1;\n  min-width: 165px;\n}\n\n.yTn7WG2S8FaTkH0fpPPgyg\\=\\= {\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n}\n\n.sWldO\\+y\\+L4QPJLs0vxpXBA\\=\\= {\n  font-size: 18px !important;\n  font-weight: 400;\n  margin-top: 6px !important;\n  margin-bottom: 4px !important;\n}\n\n.S6YQIQFfDJ26WUpu4EPhMg\\=\\= {\n  font-size: 12px !important;\n  color: #4F7091;\n}", "",{"version":3,"sources":["webpack://./src/_components/performance_assessments/PerformanceAssessmentActionPlan/StatsCardHeader.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,OAAA;EACA,gBAAA;AACJ;;AAEE;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AACJ;;AAEE;EACE,0BAAA;EACA,gBAAA;EACA,0BAAA;EACA,6BAAA;AACJ;;AAEE;EACE,0BAAA;EACA,cAAA;AACJ","sourcesContent":[".cardContainer {\n    display: flex;\n    flex: 1;\n    min-width: 165px;\n  }\n  \n  .cardHeader {\n    display: flex;\n    flex-direction: column;\n    padding: 1rem;\n  }\n  \n  .textStats {\n    font-size: 18px !important;\n    font-weight: 400;\n    margin-top: 6px !important;\n    margin-bottom: 4px !important;\n  }\n  \n  .textInfo {\n    font-size: 12px !important;\n    color: #4F7091;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": "S-6Sthatxm-PuHHbvU7tfQ==",
	"cardHeader": "yTn7WG2S8FaTkH0fpPPgyg==",
	"textStats": "sWldO+y+L4QPJLs0vxpXBA==",
	"textInfo": "S6YQIQFfDJ26WUpu4EPhMg=="
};
export default ___CSS_LOADER_EXPORT___;
