import { Client, PageIterator } from '@microsoft/microsoft-graph-client';

let graphClient = undefined
const optTimezone = {
  pt: {
    "timezone":"America/Sao_Paulo",
    "zone":'-03:00',
  },
  es: {
    "timezone":"America/Mexico_City",
    "zone":'-06:00',
  },
  en: {
    "timezone":"America/New_York",
    "zone":'-05:00',
  },
}
export function ensureClient(authProvider) {
  if (!graphClient) {
    graphClient = Client.initWithMiddleware({
      authProvider: authProvider
    });
  }

  return graphClient;
}

export async function getUser(authProvider) {
  ensureClient(authProvider);

  // Return the /me API endpoint result as a User object
  const user = await graphClient.api('/me')
    // Only retrieve the specific fields needed
    .select('displayName,mail,mailboxSettings,userPrincipalName')
    .get();

  return user;
}

export async function getUserWeekCalendar(authProvider, date) {
  try {
    ensureClient(authProvider);
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    // Generate startDateTime and endDateTime query params
    // to display a 7-day window
    // const startDateTime = zonedTimeToUtc(startOfWeek(now), timeZone).toISOString();
    const startDateTime = `${date}T00:00:00.000Z`
    // const endDateTime = zonedTimeToUtc(endOfWeek(now), timeZone).toISOString();
    const endDateTime = `${date}T23:59:59.999Z`

    // GET /me/calendarview?startDateTime=''&endDateTime=''
    // &$select=subject,organizer,start,end
    // &$orderby=start/dateTime
    // &$top=50

    const response = await graphClient
      .api('/me/calendarview')
      .header('Prefer', `outlook.timezone="${timeZone}"`)
      .query({ startDateTime: startDateTime, endDateTime: endDateTime })
      .select('subject,organizer,start,end')
      .orderby('start/dateTime')
      .top(25)
      .get();
    console.log("/response",response)
    if (response["@odata.nextLink"]) {
      // Presence of the nextLink property indicates more results are available
      // Use a page iterator to get all results
      var events = [];

      // Must include the time zone header in page
      // requests too
      var options = {
        headers: { 'Prefer': `outlook.timezone="${timeZone}"` }
      };

      var pageIterator = new PageIterator(graphClient, response, (event) => {
        events.push(event);
        return true;
      }, options);

      await pageIterator.iterate();

      return events;
    } else {

      return response.value;
    }
  } catch (e) {
    console.log("err1",e)
  }
  
}
function formatEmailListToOutlookCalendarBody(email_list) {
  const formatted_email_list = email_list.map(item => {
    return {
      emailAddress: {
          address: item.email,
          name: item.username
      },
      type: "required"
  }
  })
  return formatted_email_list
}

export async function createNewEventMicrosoftCalendar(authProvider,title,date,start_period,end_period,emails,language) {
  try {
    console.log("params: ",title,date,start_period,end_period,emails,language)
    ensureClient(authProvider);
    const newEvent = {
      subject: title,
      // body: {
      //   "contentType": "HTML",
      //   "content": "Does mid month work for you?"
      // },
      start: {
          dateTime:`${date}T${start_period}`,
          timeZone: optTimezone[language].timezone,
      },
      end: {
          dateTime: `${date}T${end_period}`,
          timeZone:optTimezone[language].timezone,
      },
      attendees: formatEmailListToOutlookCalendarBody(emails),
      isOnlineMeeting: true,
      onlineMeetingProvider: 'teamsForBusiness'  
    };

    console.log("new event:",newEvent)
    const response = await graphClient
      .api('/me/events')
      .post(newEvent);

    console.log(response);
    return response

  } catch (e) {
    console.log("Error on function `createNewEventMicrosoftCalendar` :", e)
    throw e
  }
  
}
export async function updateEventMicrosoftCalendar(authProvider,event_id,title,date,start_period,end_period,emails,language) {
  try {
    console.log("Function `updateEventMicrosoftCalendar` ")
    ensureClient(authProvider);
    const updatedEvent = {
      subject: title,
      // body: {
      //   "contentType": "HTML",
      //   "content": "Does mid month work for you?"
      // },
      start: {
          dateTime:`${date}T${start_period}`,
          timeZone: optTimezone[language].timezone,
      },
      end: {
          dateTime: `${date}T${end_period}`,
          timeZone:optTimezone[language].timezone,
      },
      attendees: formatEmailListToOutlookCalendarBody(emails)
    };


    const response = await graphClient
    .api(`/me/events/${event_id}`)  // Substitua eventId pelo ID do evento
    .update(updatedEvent);


    console.log(response);
    return response

  } catch (e) {
    console.log("Error on function `updateEventMicrosoftCalendar` :", e)
    throw e
  }
  
}
export async function deleteEventMicrosoftCalendar(authProvider,event_id) {
  try {
    console.log("/executando a funçao de `deleteEventMicrosoftCalendar`!")
    ensureClient(authProvider);  

    const response = await graphClient
    .api(`/me/events/${event_id}`)  // Substitua eventId pelo ID do evento
    .delete();

    console.log(response);
    return response

  } catch (e) {
    console.log("Error on function `deleteEventMicrosoftCalendar` :", e)
    throw e
  }
  
}
