import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import Moment from 'moment-timezone';


import IconTeammate from '../../_icons/IconTeammate';
import IconCompleted from '../../_icons/IconCompleted';
import IconHappy from '../../_icons/IconHappy';
import IconOk from '../../_icons/IconOk';
import IconSad from '../../_icons/IconSad';

import styles from './styles.module.scss'
import { getMoodById } from '../../../_helpers';

const colors = {
	productive: '#22bead',
	neutral: '#FDA02B',
	unproductive: '#FC6662'
}

function RealTimeUserDetails({ data, userDetails }) {

	const { t, i18n } = useTranslation('common');

	return (
		<div className="box teammates noBg">
			<div className="pageHeader compact realtime">
				<h3>
					{data?.username || ''}
				</h3>
				<div className="internalFilters">


					<div className="filter details active work">
						<div className={"head"}>
							<div className="icon">
								<IconTeammate />
							</div>
						</div>
						<div className={"highlight"}>{data?.activeDays || 0} / {data?.total_working_days}</div>
						<div className="name">{t('realtime.days_active')}</div>
					</div>


					<div className="filter details active work">
						<div className={"head"}>
							<div className="icon">
								<IconTeammate />
							</div>
						</div>
						<div className={"highlight"}>{data?.inactiveDays || 0}</div>
						<div className="name">{t('realtime.days_inactive')}</div>
					</div>



					{/* <div className="filter details active mood">
						<div className={"head"}>
							<div className="icon happy">
								<IconHappy />
							</div>
						</div>
						<div className={"highlight"}>{data?.percentHappyMood || 0}%</div>
						<div className="name">{t('mood.happy')}</div>
					</div>


					<div className="filter details active ok">
						<div className={"head"}>
							<div className="icon ok">
								<IconOk />
							</div>
						</div>
						<div className="highlight">{data?.percentOkMood || 0}%</div>
						<div className="name">{t('mood.ok')}</div>
					</div>


					<div className="filter details active sad">
						<div className={"head"}>
							<div className="icon sad">
								<IconSad />
							</div>
						</div>
						<div className="highlight">{data?.percentSadMood || 0}%</div>
						<div className="name">{t('mood.sad')}</div>
					</div> */}


					<div className="filter active  ">
						<div className={"head"}>
							<div className="icon">
								<IconCompleted />
							</div>
						</div>
						<div className="highlight">{data?.averageStart || '00:00'}</div>
						<div className="name">{t('realtime.avarage_start')}</div>
					</div>

					{userDetails.allowed_total_time === true && (
					<div className="filter active time ">
						<div className={"head"}>
							<div className="icon">
								<IconCompleted />
							</div>
						</div>
						<div className="highlight">{data?.averageTimeGoal || 0}%</div>
						<div className="name">{t('standards.working_day')}</div>
					</div>
					)}
					
					{userDetails.allowed_productive.includes('related') && (
					<div className="filter work active  ">
						<div className={"head"}>
							<div className="icon">
								<IconCompleted />
							</div>
						</div>
						<div className="highlight">{data?.averageWorkGoal || 0}%</div>
						<div className="name">{t('standards.performance')}</div>
					</div>
				)}
				</div>
			</div>
			<div className={styles.tableContainer}>
				<table className={styles.table}>
					<thead>
						<tr>
							<th style={{ width: '3%', paddingLeft: '6px', paddingRight: '6px' }}></th>
							<th style={{ width: '20%' }}>{t('realtime.date')}</th>
							<th style={{ width: '20%' }}>{t('settings.teams.team')}</th>
							<th>{t('realtime.started')}</th>
							<th style={{ paddingLeft: '30px', paddingRight: '20px' }}>{t('realtime.ended')}</th>
							
							{userDetails.allowed_total_time === true && (
								<th style={{ textAlign: 'center', width: '15%' }}>
									{t('standards.working_day')}
								</th>
							)}
							{userDetails.allowed_productive.includes('related') && (
							<th style={{ textAlign: 'center', width: '15%' }}>
								{t('standards.performance')}
							</th>
							)}
							<th style={{ paddingLeft: '30px' }}>{t('realtime.last_seen')}</th>
						</tr>
					</thead>
					<tbody>
						{data?.userData.map(userInfo => (
							<tr>
								<td style={{ paddingLeft: '6px', paddingRight: '6px' }}>
									<div>
										{
											userInfo.mood > -1 
											? 
											<img src={getMoodById(userInfo.mood).img} width={18} loading='lazy'/>
											:
											'--'
										}
									</div>
								</td>
								<td>{Moment.utc(userInfo.day).format('DD/MM/YYYY')}</td>
								<td>{userInfo.name}</td>
								{userInfo.first_seen_at !== null ? (
									<>
										<td>{Moment(userInfo.first_seen_at).format('HH:mm')}</td>
										<td style={{ paddingLeft: '30px', paddingRight: '20px' }}>{Moment(userInfo.last_tracked[1]).format('HH:mm')}</td>
									</>
								) : (
									<>
										<td>--</td>
										<td style={{ paddingLeft: '30px', paddingRight: '20px' }}>--</td>
									</>
								)}
								{userDetails.allowed_total_time === true && (
									<td
										style={{ textAlign: 'center', color: '#187DFF' }}
									>
										{Number(userInfo.total_expected_worked_time) !== 0 ? (Math.round(Number(userInfo.total_worked_time) * 100 / Number(userInfo.total_expected_worked_time))) : 0}%
									</td>
								)}
								{userDetails.allowed_productive.includes('related') && (
									<td
										style={{ textAlign: 'center', color: '#22bead' }}
									>
										{Number(userInfo.total_expected_productive_time) !== 0 ? (Math.round(Number(userInfo.total_productive_time) * 100 / Number(userInfo.total_expected_productive_time))) : 0}%
									</td>
								)}
								{userInfo.first_seen_at !== null ? (
									<td style={{ paddingLeft: '30px', color: colors[`${userInfo.last_tracked[2]}`] }}>{userInfo.last_tracked[0]}</td>
								) : (
									<td style={{ paddingLeft: '30px' }}>--</td>
								)}
							</tr>
						))}
					</tbody>
				</table>
			</div>

		</div>
	)
};

function mapDispatchToProps(dispatch) {

	return {
	}
}

function mapStateToProps(state) {

	return {
		userDetails : state.authentication.userDetails
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(RealTimeUserDetails)