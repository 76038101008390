// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rj-faq9HMx8mmDgfzw0pZA\\=\\= {\n  display: flex;\n  padding: 0px;\n  margin: 0px;\n  gap: 4px;\n}\n\n.q9gxXzGMAsXshCmWygV-gQ\\=\\= {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 28px;\n  width: 32px;\n  border-radius: 25%;\n  cursor: pointer;\n  background-color: #187DFF;\n  color: #FFFFFF;\n  font-size: 12px;\n}", "",{"version":3,"sources":["webpack://./src/pages/TeamsSettingsPage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,WAAA;EACA,QAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;AACF","sourcesContent":[".listWeekDays{\n  display: flex;\n  padding: 0px;\n  margin: 0px;\n  gap: 4px; \n}\n\n.listWeekDaysItem{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 28px;\n  width: 32px;\n  border-radius: 25%;\n  cursor: pointer;\n  background-color: #187DFF;\n  color: #FFFFFF;\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listWeekDays": "rj-faq9HMx8mmDgfzw0pZA==",
	"listWeekDaysItem": "q9gxXzGMAsXshCmWygV-gQ=="
};
export default ___CSS_LOADER_EXPORT___;
