import { Box, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Header from "../../_components/switch_page/Header";
import { useEffect } from "react";
import { bindActionCreators } from "redux";
import { usersActions } from "../../_actions";
import style from "./styles.module.scss";
import { history } from "../../_helpers";
import { Card, CardContent, Typography } from '@material-ui/core'
import { Buildings, ClipboardText, Plug, UserCircle, UserFocus, Users, UsersThree } from 'phosphor-react';
function GlobalSettingsPage({ userDetails, usersActions }) {
  const { t, i18n } = useTranslation("common");
  const lang = userDetails.language || i18n.language;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  useEffect(() => {
    document.title = "Befective | " + t("menu.user.global_settings");
  }, []);

  return (
    <Box bgcolor="#F5F6F9" width="100%" display="flex" flexDirection="column">
      
      <Header toBackUrl={localStorage.getItem("pathToBackPage") || "/"} />

      <main className={style.containerMain}>
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Typography component="h6" className={style.title}>{t('global_settings.title')}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
            <Card className={style.card} variant="outlined" onClick={() => history.push('/global-settings/my-account')}>
              <CardContent className={style.cardContent}>
                <UserCircle size={24} color="#1E6B7F" weight="bold" />
                <Typography component="h6" className={style.cardTitle}>{t('global_settings.my_account.title')}</Typography>
                <Typography component="p" className={style.cardSubtitle}>{t('global_settings.my_account.subtitle')}</Typography>
              </CardContent>
            </Card>
          </Grid>
          {
            userDetails?.profile_key !== 'collaborator' &&
            <>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <Card className={style.card} variant="outlined" onClick={() => history.push('/global-settings/company')}>
                  <CardContent className={style.cardContent}>
                    <Buildings size={24} color="#1E6B7F" weight="bold" />
                    <Typography component="h6" className={style.cardTitle}>{t('global_settings.company.title')}</Typography>
                    <Typography component="p" className={style.cardSubtitle}>{t('global_settings.company.subtitle')}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <Card className={style.card} variant="outlined" onClick={() => history.push('/global-settings/users')}>
                  <CardContent className={style.cardContent}>
                    <Users size={24} color="#1E6B7F" weight="bold" />
                    <Typography component="h6" className={style.cardTitle}>{t('global_settings.users.title')}</Typography>
                    <Typography component="p" className={style.cardSubtitle}>{t('global_settings.users.subtitle')}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <Card className={style.card} variant="outlined" onClick={() => history.push('/global-settings/teams')}>
                  <CardContent className={style.cardContent}>
                    <UsersThree size={24} color="#1E6B7F" weight="bold" />
                    <Typography component="h6" className={style.cardTitle}>{t('global_settings.teams.title')}</Typography>
                    <Typography component="p" className={style.cardSubtitle}>{t('global_settings.teams.subtitle')}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <Card className={style.card} variant="outlined" onClick={() => history.push('/global-settings/profiles')}>
                  <CardContent className={style.cardContent}>
                    <UserFocus size={24} color="#1E6B7F" weight="bold" />
                    <Typography component="h6" className={style.cardTitle}>{t('global_settings.profiles.title')}</Typography>
                    <Typography component="p" className={style.cardSubtitle}>{t('global_settings.profiles.subtitle')}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <Card className={style.card} variant="outlined" onClick={() => history.push('/global-settings/integrations')}>
                  <CardContent className={style.cardContent}>
                    <Plug size={24} color="#1E6B7F" weight="bold" />
                    <Typography component="h6" className={style.cardTitle}>{t('global_settings.integrations.title')}</Typography>
                    <Typography component="p" className={style.cardSubtitle}>{t('global_settings.integrations.subtitle')}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <Card className={style.card} variant="outlined" onClick={() => history.push('/global-settings/inactivities')}>
                  <CardContent className={style.cardContent}>
                    <ClipboardText size={24} color="#1E6B7F" weight="bold" />
                    <Typography component="h6" className={style.cardTitle}>{t('global_settings.inactivities.title')}</Typography>
                    <Typography component="p" className={style.cardSubtitle}>{t('global_settings.inactivities.subtitle')}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </>
          }
        </Grid>
      </main>
    </Box>
  );
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GlobalSettingsPage)
);
