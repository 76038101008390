import { MsalProvider } from '@azure/msal-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import { isMobile } from "react-device-detect";
import { connect } from 'react-redux';
import { Route, Router } from 'react-router-dom';

import { alertActions, usersActions } from '../_actions';
import AlertBox from '../_components/_shared/AlertBox';
import Menu from '../_components/_shared/Menu';
import MenuBackfective from '../_components/_shared/MenuBackfective';
import PrivateRoute from '../_components/_shared/PrivateRoute';
import { history } from '../_helpers';
import ActivitiesPage from './ActivitiesPage';
import BackfectivePageInvitations from './BackfectivePageInvitations';
import DownloadPage from './DownloadPage';
import HistoricalPage from './HistoricalPage';
import HistoricalPageByHour from './HistoricalPageByHour';
import HomePage from './HomePage';
import { InvitationPage } from './InvitationPage';
import { LoginPage } from './LoginPage';
import MyProductivityPage from './MyProductivityPageOld';
import PeriodicReportsPage from "./PeriodicReportsPage";
import PeriodicReportsPageEdit from "./PeriodicReportsPageEdit";
import PublicDownloadPage from './PublicDownloadPage';
import ReportsHighlightsPage from './ReportsHighlightsPage';
import ReportsInactivityPage from './ReportsInactivityPage';
import ReportsMoodPage from './ReportsMoodPage';
import ReportsRealTimePage from './ReportsRealTimePage';
import ReportsTeammatesPage from './ReportsTeammatesPage';
import ReportsTeamsPage from './ReportsTeamsPage';
import ReportsTimeGoalPage from './ReportsTimeGoalPage';
import ReportsWorkGoalPage from './ReportsWorkGoalPage';
import { ResetPasswordPage } from './ResetPasswordPage';
import CompanyPageAdministrators from './SettingsCompanyPageAdministrators';
import CompanyPageDaysoff from './SettingsCompanyPageDaysoff';
import CompanyPageInformation from './SettingsCompanyPageInformation';
import CompanyPageInventory from './SettingsCompanyPageInventory';
import ProfilePage from './SettingsProfilePage';
import TeamsPage from './SettingsTeamsPage';
import TeamsPageEdit from './SettingsTeamsPageEdit';
import TeamsPageFocus from './SettingsTeamsPageFocus';
import TermsPage from './TermsPage';
import SwitchPage from './SwitchPage';
import SwitchPageOld from './SwitchPageOld';
import GoalsPage from './GoalsPage/index';
import GoalsPageDetails from './GoalsPageDetails';
import MyProductivityPageActivities from "./MyProductivityPageActivities";
// import MyActivitiesInProductivity from './MyActivitiesInProductivity';
import { CurrentModuleProvider } from '../contexts/CurrentModuleContext';
import { DatePickerFilterProvider } from '../contexts/DatePickerFilterContext';
import { MessagesCountProvider } from '../contexts/MessagesCountContext';
import { RoomsFilterProvider } from '../contexts/RoomsFilterContext';
import CallsPage from './CallsPage';
import CheckinControl from './CheckinControl';
import CheckinControlSolicitations from './CheckinControlSolicitations';
import CheckinDashboard from './CheckinDashboard';
import CheckinHistory from './CheckinHistory';
import CheckinReportsMapPage from './CheckinReportsMapPage';
import CheckinReportsOvertimePage from './CheckinReportsOvertimePage';
import CheckinReportsPointsDetailsPage from './CheckinReportsPointsDetailsPage';
import CheckinReportsPointsPage from './CheckinReportsPointsPage';
import CheckinSettings from './CheckinSettings';
import GoalsArchivedPage from './GoalsArchivedPage';
import GoalsCompletedPage from './GoalsCompletedPage';
import GoalsNotificationsPage from './GoalsNotificationsPage';
import IntegrationGoogleCalendarPage from './IntegrationGoogleCalendarPage';
import IntegrationOutlookCalendarPage from './IntegrationOutlookCalendarPage';
import MessagesArchivedPage from './MessagesArchivedPage';
import MessagesPage from './MessagesPage';
import MessagesReceivedPage from './MessagesReceivedPage';
import MyProductivityPageInactivity from './MyProductivityPageInactivity';
import ReportsRealTimePageByUser from './ReportsRealTimePageByUser';
import RoomAttributesPage from './RoomAttributesPage';
import RoomCreate from './RoomCreate';
import RoomMyReservations from './RoomMyReservations';
import RoomReservation from './RoomReservation';
import Spaces from './Spaces';
import RoomsManagement from './RoomsManagement';
import RoomsReports from './RoomsReports';
import ProjectManagementBoardsPage from './ProjectManagementBoardsPage';
import ProjectManagementListsPage from './ProjectManagementListsPage';
import CheckinSettingsTeams from './CheckinSettingsTeams';
import TeamSettings from './CheckinSettingsTeams/TeamSettings';
import { Redirect } from './redirect';
import ProjectManagementHomePage from './ProjectManagementHomePage';
import ProjectManagementWorkspaceDetailsPage from './ProjectManagementWorkspaceDetailsPage';
import ProjectManagementWorkspacesPage from './ProjectManagementWorkspacesPage';
import ProjectManagementWorkspaceTypesPage from './ProjectManagementWorkspaceTypesPage';
import CheckinReportsSignedPointsPage from './CheckinReportsSignedPointsPage';
import RoomsMap from './RoomsMap';
import RoomsMapEditor from './RoomsMapEditor';
import ProjectManagementBoardsArchivedPage from './ProjectManagementBoardsArchivedPage';
import ScheduledReportsPage from './ScheduledReportsPage';
import ScheduledReportsCreatePage from './ScheduledReportsCreatePage';
import ScheduledReportsEditPage from './ScheduledReportsEditPage';
import ProjectManagementReportsTimelinePage from './ProjectManagementReportsTimelinePage';
import RemoteCheckinDownloadPage from './RemoteCheckinDownloadPage';
import RemoteCheckinReportsMapPage from './RemoteCheckinReportsMapPage';
import RemoteCheckinReportsTeammatesPage from './RemoteCheckinReportsTeammatesPage';
import RemoteCheckinDashboardPage from './RemoteCheckinDashboardPage';
import InventoryColabsPage from './InventoryColabsPage';
import RemoteCheckinReportsTeammatesDetailsPage from './RemoteCheckinReportsTeammatesDetailsPage';
import InventoryDashboardPage from './InventoryDashboardPage';
import InventoryColabsDetailsPage from './InventoryColabsDetailsPage';
import { RequestReservationsConfirmation } from './RequestReservationsConfirmation';
import WidgetsSettings from './WidgetsSettings';
import ExecutivePage from './ExecutivePage';
import ProductiveReportsPage from './ProductiveReportsPage';
import ProductiveReportsAdvancedPage from './ProductiveReportsAdvancedPage';
import HelpCenterPage from './HelpCenterPage';
import HelpCenterSubCategoriesPage from './HelpCenterSubCategoriesPage';
import HelpCenterDetailsPage from './HelpCenterDetailsPage';
import TeamsSettingsPage from './TeamsSettingsPage';
import TeamsColabSettingsPage from './TeamsColabSettingsPage';
import UsersSettingsPage from './UsersSettingsPage';
import ExtraCollectionsSettingsPage from './ExtraCollectionsSettingsPage';
import DaysOffSettingsPage from './DaysOffSettingsPage';
import UsersSettingsCreatePage from './UsersSettingsCreatePage';
import ModuleDetailsPage from './ModuleDetailsPage';
import NewsDetailsPage from './NewsDetailsPage';
import ProductiveReportsVacationPage from './ProductiveReportsVacationPage';
import ProductiveReportsAbsenteeismPage from './ProductiveReportsAbsenteeismPage';
import ProductiveReportsPotentialProductivePage from './ProductiveReportsPotentialProductivePage';
import ProductiveReportsFatiguePage from './ProductiveReportsFatiguePage';
import InventorySoftwarePage from './InventorySoftwarePage';
import InventorySoftwareDetailsPage from './InventorySoftwarePage/details';
import InventorySoftwareColabDetailsPage from './InventorySoftwarePage/colab_details';
import ProductivityPage from './ProductivityPage';
import ChecklistPage from './ChecklistPage';
import MoodsSettingsPage from './MoodsSettingsPage';
import ActivitiesSettingsPage from './ActivitiesSettingsPage';
import ProductivityDetailsPage from './ProductivityDetailsPage';
import ProductiveReportsChecklistsPage from './ProductiveReportsChecklistsPage';
import ProductiveReportsChecklistsSharingPage from './ProductiveReportsChecklistsSharingPage';
import { Helmet } from 'react-helmet';
import ProfilesSettingsPage from './ProfilesSettingsPage';
import ProfilesSettingsFormPage from './ProfilesSettingsFormPage';
import NotFoundPage from './NotFoundPage';
import ProductiveReportsTransactionalPage from './ProductiveReportsTransactionalPage';
import PerformanceAssessmentDashboardPage from './PerformanceAssessmentDashboardPage';
import PerformanceAssessmentsCreatedPage from './PerformanceAssessmentsCreatedPage';
import PerformanceAssessmentSkillsPage from './PerformanceAssessmentSkillsPage';
import PerformanceAssessmentFeedbackPage from './PerformanceAssessmentFeedbackPage';
import PerformanceAssessmentActionPlanPage from './PerformanceAssessmentActionPlanPage';
import ProductiveReportsCollectionMonitoringPage from './ProductiveReportsCollectionMonitoringPage';
import DownloadStefaniniPage from './DownloadStefaniniPage';
import PerformanceAssessmentReportsPage from './PerformanceAssessmentReportsPage';
import PerformanceAssessmentsAssessPage from './PerformanceAssessmentsAssessPage';
import ProductivityPerformancePage from './ProductivityPerformancePage';
import PerformanceAssessmentsDetailsParticipantsPage from './PerformanceAssessmentsDetailsParticipantsPage';
import PerformanceAssessmentsDetailsRepliesPage from './PerformanceAssessmentsDetailsRepliesPage';
import PerformanceAssessmentsDetailsResultsPage from './PerformanceAssessmentsDetailsResultsPage';
import PerformanceAssessmentsNewPage from './PerformanceAssessmentsNewPage';
import PerformanceAssessmentScalesAnswersPage from './PerformanceAssessmentScalesAnswersPage';
import PerformanceAssessmentScalesResultIndicatorsPage from './PerformanceAssessmentScalesResultIndicatorsPage';
import PerformanceAssessmentReportsAssessmentsPage from './PerformanceAssessmentReportsAssessmentsPage';
import PerformanceAssessmentReportsAssessmentsDetailsPage from './PerformanceAssessmentReportsAssessmentsDetailsPage';
import PerformanceAssessmentFeedbackReportPage from './PerformanceAssessmentFeedbackReportPage';
import PerformanceAssessmentFeedbackActionPlanPage from './PerformanceAssessmentFeedbackActionPlanPage';
import ProductivityPerformanceFormPage from './ProductivityPerformanceFormPage';
import UsersSettingsPersonalPage from './UsersSettingsPersonalPage';
import UsersSettingsProfessionalPage from './UsersSettingsProfessionalPage';
import UsersSettingsFinancePage from './UsersSettingsFinancePage';
import UsersSettingsDocumentsPage from './UsersSettingsDocumentsPage';
import UsersSettingsEducationPage from './UsersSettingsEducationPage';
import UsersSettingsAnnotationsPage from './UsersSettingsAnnotationsPage';
import GlobalSettingsPage from './GlobalSettingsPage';
import IntegrationsSettingsPage from './IntegrationsSettingsPage';
import ProductivityActivitiesPage from './ProductivityActivitiesPage';
import SettingsCompanyPageAtivitiesOff from './SettingsCompanyPageAtivitiesOff';

require('../assets/styles/App.scss');
require('../assets/styles/Mobile.scss');
require('../assets/styles/Tables.scss');
require('../assets/styles/Filters.scss');
require('../assets/styles/LowRes.scss');

const known_routes = ["invitation", "reset_password", "downloads", "download", "calls-page", "productive", "settings", "goals", "messages", "terms", "virtual-point", "rooms", "project_management", "calls-page", "spaces", "checkin-remote", "inventory", "requests_confirmation", "help-center", "help-center-subcategories", "help-center-details", "module-details", "news-details","login","invitation","requests_confirmation","help-center","help-center-subcategories","help-center-details","stefanini", "performance-assessment", "global-settings"];
class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			logged: false,
			isMenuOpen: this.props?.userDetails?.asideMenu === 'close' ? false : true
		};
		const { dispatch } = this.props;

		history.listen((location, action) => {
			// clear alert on location change
			dispatch(alertActions.clear());
		});
	}

	// componentDidUpdate(prevProps) {
	//   // Verifica se a rota mudou
	//   if (this.props.location !== prevProps.location) {
	//     window.scrollTo(0, 0); // Define a posição do scroll para o topo quando a rota mudar
	//   }
	// }

	componentDidMount() {
		if(this.props?.userDetails && this.props?.userDetails?.allowed_menu === undefined){
			usersActions.logout()
			history.push("/login")
		}
		if (this.props?.userDetails) {
			this.setState({ logged: true, isMenuOpen: this.props?.userDetails?.asideMenu === 'close' ? false : true });
		}
	}

	componentWillReceiveProps = (nextProps) => {
		if (nextProps.userDetails?.email !== this.props.userDetails?.email) {
			this.setState({ logged: true, isMenuOpen: nextProps?.userDetails?.asideMenu === 'close' ? false : true })
		}
	}

	isAlertBox(path, logged) {
		return (path && logged)
			&& (
				path[1] !== 'login'
				&& path[1] !== 'not-found'
				&& path[1] !== 'invitation'
				&& path[1] !== 'reset_password'
				&& path[1] !== 'backfective'
				&& path[1] !== 'calls-page'
				&& path[1] !== 'requests_confirmation'
				&& path[1] !== 'help-center'
				&& path[1] !== 'help-center-subcategories'
				&& path[1] !== 'help-center-details'
				&& path[1] !== 'module-details'
				&& path[1] !== 'news-details'
				&& path[2] !== 'productivity'
				&& path[2] !== 'checklist'
				&& path[2] !== 'activities_colab'
				&& path[1] !== 'not-found'
				&& (path[1] !== 'performance-assessment' || path[2] !== 'answer-form')
				&& path[1] !== 'global-settings'
			);
	}

	toggleMenu(type) {

		if (type == 'main') {
			this.setState({ isMenuOpen: !this.state.isMenuOpen });

		} else {
			if (isMobile) {
				this.setState({ isMenuOpen: false });
			}
		}

	};

	toggleFromMenu = (value) => {

		if (value == 'closed') {
			this.setState({ isMenuOpen: true });
		} else {
			this.setState({ isMenuOpen: false });
		}
	}

	render() {
		const path = window.location.pathname.split("/") || [];
		const { logged } = this.state;

		return (
			<DatePickerFilterProvider>
				<RoomsFilterProvider>
					<Router history={history}>

						{/* <Helmet>
							<meta
								httpEquiv="Content-Security-Policy"
								content={`
														default-src 'self';
														script-src 'self' www.google-analytics.com https://googleads.g.doubleclick.net https://maps.googleapis.com  https://accounts.google.com 'unsafe-inline' 'unsafe-eval';
														style-src 'self' https://fonts.googleapis.com 'unsafe-inline';
														font-src 'self' https://fonts.gstatic.com https://www.befective.com data:;
														img-src 'self' https://ui-avatars.com https://backend-befective.s3.amazonaws.com https://befective-staging.s3.amazonaws.com https://www.google.com https://www.google.com.br data:;
														media-src 'self';
														base-uri 'self';
														form-action 'self';
														frame-src 'self' https://www.befective.com https://www.googletagmanager.com https://td.doubleclick.net;
														connect-src 'self' http://localhost:8090 https://api-staging.befective.com https://api-test.befective.com https://analytics.google.com https://maps.googleapis.com https://api.ipify.org https://ipinfo.io https://stats.g.doubleclick.net;
														manifest-src 'self';
												`}
								></meta>
						</Helmet> */}

						{path && (path[1] === 'login') && <AlertBox />}
						<Route path="/login" component={LoginPage} />
						<Route path="/invitation" component={InvitationPage} />
						<Route path="/reset_password" component={ResetPasswordPage} />
						<Route path="/requests_confirmation/:id" component={RequestReservationsConfirmation} />
						<Route path="/help-center" component={HelpCenterPage} />
						<Route path="/help-center-subcategories/:id" component={HelpCenterSubCategoriesPage} />
						<Route path="/help-center-details/:id" component={HelpCenterDetailsPage} />
						<Route path="/not-found" exact component={NotFoundPage} />
						<Route path="/stefanini" exact component={DownloadStefaniniPage} />

						{/* <Route path="/*" component={Redirect} /> */}
						{/* {console.log("path",path)}
						{path.length > 0 && path[1] !== '' && !known_routes.includes(path[1]) &&
							<Route path="/*" component={NotFoundPage} />
						} */}

						<CurrentModuleProvider >
							<>
								<PrivateRoute exact path="/" component={SwitchPage} />
								<PrivateRoute exact path="/module-details/:id" component={ModuleDetailsPage} />
								<PrivateRoute exact path="/news-details/:id" component={NewsDetailsPage} />
								{
									path[1] === 'global-settings' && (
										<>
											<AlertBox timeAlert={5000}/>
											<PrivateRoute exact path="/global-settings" component={GlobalSettingsPage} />								
											<PrivateRoute exact path="/global-settings/my-account" component={ProfilePage} />
			
											<PrivateRoute exact path="/global-settings/users" component={UsersSettingsPage} />
											<PrivateRoute exact path="/global-settings/users/create" component={UsersSettingsCreatePage} />
											<PrivateRoute exact path="/global-settings/users/personal/:id" component={UsersSettingsPersonalPage} />
											<PrivateRoute exact path="/global-settings/users/professional/:id" component={UsersSettingsProfessionalPage} />
											<PrivateRoute exact path="/global-settings/users/education/:id" component={UsersSettingsEducationPage} />
											<PrivateRoute exact path="/global-settings/users/finance/:id" component={UsersSettingsFinancePage} />
											<PrivateRoute exact path="/global-settings/users/documents/:id" component={UsersSettingsDocumentsPage} />
											<PrivateRoute exact path="/global-settings/users/annotations/:id" component={UsersSettingsAnnotationsPage} />
											<PrivateRoute exact path="/global-settings/users/edit/:id" component={UsersSettingsCreatePage} />
			
											<PrivateRoute exact path="/global-settings/teams" component={TeamsSettingsPage} />
											<PrivateRoute exact path="/global-settings/teams/:id" component={TeamsColabSettingsPage} />
											<PrivateRoute exact path="/global-settings/teams/:id/configuration" component={TeamsPageEdit} />
											<PrivateRoute exact path="/global-settings/teams/:id/focus" component={TeamsPageFocus} />
			
											<PrivateRoute exact path="/global-settings/profiles" component={ProfilesSettingsPage} />
											<PrivateRoute exact path="/global-settings/profiles/create" component={ProfilesSettingsFormPage} />
											<PrivateRoute exact path="/global-settings/profiles/edit/:id" component={ProfilesSettingsFormPage} />
			
											<PrivateRoute exact path="/global-settings/company" component={CompanyPageInformation} />
											<PrivateRoute exact path="/global-settings/company/acivities-off-computer" component={SettingsCompanyPageAtivitiesOff} />
											<MsalProvider instance={this.props.pca}>
												<GoogleOAuthProvider
													clientId="90494001516-0ra520poch3aamre4c8uvtfqqe0f0tjh.apps.googleusercontent.com"
												>
													<PrivateRoute exact path="/global-settings/integrations" component={IntegrationsSettingsPage} />
												</GoogleOAuthProvider>
											</MsalProvider>
										</>
									)
								}


								{path && (path[1] === 'downloads' || path[1] === 'productivity') ?
									<>
										<AlertBox />
										<Route exact path="/downloads/get/windows/" component={PublicDownloadPage} />
										<Route exact path="/downloads/get/mac/" component={PublicDownloadPage} />
									</>
									:
									<MessagesCountProvider>
										<>
											{this.isAlertBox(path, logged) && path[1] &&
												<>
													<AlertBox />
													<div className={this.state.isMenuOpen ? "menuToggle open" : "menuToggle"}
														onClick={() => {
															this.toggleMenu('main')
														}}>
														<i className="arrow"></i>
														<span></span>
														<span></span>
														<span></span>
													</div>
													<Menu menuStatus={this.state.isMenuOpen} toggle={this.toggleFromMenu} goals={this.state.goals} />
												</>
											}
											{(path && logged) && (path[1] === 'backfective') &&
												<>
													<MenuBackfective />

													<div className="menuToggle" onClick={() => {
														this.toggleMenu('main')
													}}>
														<i className="arrow"></i>
														<span></span>
														<span></span>
														<span></span>
													</div>
													<AlertBox />
												</>
											}
											{/* productive management */}
											{
												this.props?.userDetails?.allowed_menu?.includes('executive') && (
													<>
														<PrivateRoute exact path="/productive" component={ExecutivePage} />
														<PrivateRoute exact path="/productive/widgets" component={WidgetsSettings} />
													</>
												)
											}

											{/* <PrivateRoute exact path="/productive/periodic-reports" component={PeriodicReportsPage} />
											<PrivateRoute exact path="/productive/periodic-reports/add" component={PeriodicReportsPageEdit} />
											<PrivateRoute exact path="/productive/periodic-reports/:id/edit" component={PeriodicReportsPageEdit}/>
											<PrivateRoute exact path="/productive/scheduled-reports" component={ScheduledReportsPage} />
											<PrivateRoute exact path="/productive/scheduled-reports/create" component={ScheduledReportsCreatePage} />
											<PrivateRoute exact path="/productive/scheduled-reports/edit/:id" component={ScheduledReportsEditPage} /> */}
											{
												this.props?.userDetails?.allowed_menu?.includes('reports') && (
													<>
														<PrivateRoute exact path="/productive/reports" component={ProductiveReportsPage} />
														<PrivateRoute exact path="/productive/reports/highlights" component={ReportsHighlightsPage} />
														<PrivateRoute exact path="/productive/reports/timegoal" component={ReportsTimeGoalPage} />
														<PrivateRoute exact path="/productive/reports/workgoal" component={ReportsWorkGoalPage} />
														<PrivateRoute exact path="/productive/reports/mood" component={ReportsMoodPage} />
														<PrivateRoute exact path="/productive/reports/teams" component={ReportsTeamsPage} />
														<PrivateRoute exact path="/productive/reports/teammates" component={ReportsTeammatesPage} />
														<PrivateRoute exact path="/productive/reports/realtime" component={ReportsRealTimePage} />
														<PrivateRoute exact path="/productive/reports/vacation" component={ProductiveReportsVacationPage} />
														<PrivateRoute exact path="/productive/reports/absenteeism" component={ProductiveReportsAbsenteeismPage} />
														<PrivateRoute exact path="/productive/reports/potential-productive" component={ProductiveReportsPotentialProductivePage} />
														<PrivateRoute exact path="/productive/reports/fatigue" component={ProductiveReportsFatiguePage} />
														<PrivateRoute exact path="/productive/reports/checklists" component={ProductiveReportsChecklistsPage} />
														<PrivateRoute exact path="/productive/reports/checklists/sharing/:id" component={ProductiveReportsChecklistsSharingPage} />
														<PrivateRoute exact path="/productive/reports/transactional" component={ProductiveReportsTransactionalPage} />

														<PrivateRoute exact path="/productive/reports/periodic" component={PeriodicReportsPage} />
														<PrivateRoute exact path="/productive/reports/periodic/add" component={PeriodicReportsPageEdit} />
														<PrivateRoute exact path="/productive/reports/periodic/:id/edit" component={PeriodicReportsPageEdit} />

														<PrivateRoute exact path="/productive/reports/scheduled-reports" component={ScheduledReportsPage} />
														<PrivateRoute exact path="/productive/reports/scheduled-reports/create" component={ScheduledReportsCreatePage} />
														<PrivateRoute exact path="/productive/reports/scheduled-reports/edit/:id" component={ScheduledReportsEditPage} />
														
														<PrivateRoute exact path="/productive/reports/collection_monitoring" component={ProductiveReportsCollectionMonitoringPage} />

														<PrivateRoute exact path="/productive/reports/realtime/user/:machine_user_id" component={ReportsRealTimePageByUser} />
														<PrivateRoute path="/productive/reports/inactivity" component={ReportsInactivityPage} />
														<PrivateRoute path="/productive/reports/advanced" component={ProductiveReportsAdvancedPage} />
													</>
												)
											}
											{this.props?.userDetails?.allowed_menu?.includes('activities') && <PrivateRoute path="/productive/activities" component={ActivitiesPage} />}
											{
												this.props?.userDetails?.allowed_menu?.includes('historical') && (
													<>
														<PrivateRoute exact path="/productive/historical" component={HistoricalPage} />
														<PrivateRoute exact path="/productive/historical/hour" component={HistoricalPageByHour} />
													</>
												)
											}

											{/* <PrivateRoute exact path="/productive/teams" component={TeamsPage} />
											<PrivateRoute exact path="/productive/teams/:id/configuration" component={TeamsPageEdit} />
											<PrivateRoute exact path="/productive/teams/:id/focus" component={TeamsPageFocus} /> */}

											{/* My productivity */}
											<PrivateRoute
												exact
												path="/productive/productivity"
												component={ProductivityPage}
											/>
											<PrivateRoute
												exact
												path="/productive/productivity/activities_colab"
												component={ProductivityActivitiesPage}
											/>
											<PrivateRoute
												exact
												path="/productive/productivity/details"
												component={ProductivityDetailsPage}
											/>
											<PrivateRoute
												exact
												path="/productive/productivity/checklist"
												component={ChecklistPage}
											/>
											<PrivateRoute
												exact
												path="/productive/productivity/performance-assessment"
												component={ProductivityPerformancePage}
											/>
											<PrivateRoute
												exact
												path="/productive/productivity/performance-assessment/form/:id"
												component={ProductivityPerformanceFormPage}
											/>
											{/* <PrivateRoute
												exact
												path="/productive/productivity/activities"
												component={MyProductivityPageActivities}
											/>
											<PrivateRoute
												exact
												path="/productive/productivity/inactivity"
												component={MyProductivityPageInactivity}
											/> */}
											<GoogleOAuthProvider
												clientId="90494001516-0ra520poch3aamre4c8uvtfqqe0f0tjh.apps.googleusercontent.com"
											>
												<PrivateRoute
													exact
													path="/productive/productivity/google-calendar"
													component={IntegrationGoogleCalendarPage}
												/>
											</GoogleOAuthProvider>

											<MsalProvider instance={this.props.pca}>
												<PrivateRoute
													exact
													path="/productive/productivity/outlook-calendar"
													component={IntegrationOutlookCalendarPage}
												/>
											</MsalProvider>

											<PrivateRoute path="/settings/my-account" component={ProfilePage} />
											{/* Settings */}
											{
												this.props?.userDetails?.allowed_menu?.includes('settings') && (
													<>
														{this.props?.userDetails?.allowed_menu?.includes('settings_company') && <PrivateRoute exact path="/settings/company" component={CompanyPageInformation} />}
														{/* <PrivateRoute path="/settings/company/administrators" component={CompanyPageAdministrators} />
															<PrivateRoute path="/settings/company/daysoff" component={CompanyPageDaysoff} />
															<PrivateRoute path="/settings/company/inventory-teammates" component={CompanyPageInventory} /> */}
														{this.props?.userDetails?.allowed_menu?.includes('settings_teams') && (
															<>
																<PrivateRoute exact path="/settings/teams" component={TeamsSettingsPage} />
																<PrivateRoute exact path="/settings/teams/:id" component={TeamsColabSettingsPage} />
																<PrivateRoute exact path="/settings/teams/:id/configuration" component={TeamsPageEdit} />
																<PrivateRoute exact path="/settings/teams/:id/focus" component={TeamsPageFocus} />
															</>
														)}
														{this.props?.userDetails?.allowed_menu?.includes('settings_users') && (
															<>
																<PrivateRoute exact path="/settings/users" component={UsersSettingsPage} />
																<PrivateRoute exact path="/settings/users/create" component={UsersSettingsCreatePage} />
																<PrivateRoute exact path="/settings/users/personal/:id" component={UsersSettingsPersonalPage} />
																<PrivateRoute exact path="/settings/users/professional/:id" component={UsersSettingsProfessionalPage} />
																<PrivateRoute exact path="/settings/users/education/:id" component={UsersSettingsEducationPage} />
																<PrivateRoute exact path="/settings/users/finance/:id" component={UsersSettingsFinancePage} />
																<PrivateRoute exact path="/settings/users/documents/:id" component={UsersSettingsDocumentsPage} />
																<PrivateRoute exact path="/settings/users/annotations/:id" component={UsersSettingsAnnotationsPage} />
																<PrivateRoute exact path="/settings/users/edit/:id" component={UsersSettingsCreatePage} />
															</>
														)}
														{this.props?.userDetails?.allowed_menu?.includes('settings_extra_collections') && <PrivateRoute exact path="/settings/extra-collections" component={ExtraCollectionsSettingsPage} />}
														{this.props?.userDetails?.allowed_menu?.includes('settings_daysoff') && <PrivateRoute exact path="/settings/daysoff" component={DaysOffSettingsPage} />}
														{this.props?.userDetails?.allowed_menu?.includes('settings_mood_states') && <PrivateRoute exact path="/settings/moods" component={MoodsSettingsPage} />}
														{this.props?.userDetails?.allowed_menu?.includes('settings_activities') && <PrivateRoute exact path="/settings/activities" component={ActivitiesSettingsPage} />}
													</>
												)
											}
											{
												['magic', 'admin'].includes(this.props?.userDetails?.profile_key) && (
													<>
														<PrivateRoute exact path="/settings/profiles" component={ProfilesSettingsPage} />
														<PrivateRoute exact path="/settings/profiles/create" component={ProfilesSettingsFormPage} />
														<PrivateRoute exact path="/settings/profiles/edit/:id" component={ProfilesSettingsFormPage} />
													</>
												)
											}

											{/* goals management */}
											<PrivateRoute exact path="/goals" component={GoalsPage} />
											<PrivateRoute exact path="/goals/details/:id" component={GoalsPageDetails} />
											<PrivateRoute exact path="/goals/completed" component={GoalsCompletedPage} />
											<PrivateRoute exact path="/goals/archived" component={GoalsArchivedPage} />
											<PrivateRoute exact path="/goals/notifications" component={GoalsNotificationsPage} />


											{/* Messages */}
											<PrivateRoute exact path="/messages" component={MessagesReceivedPage} />
											<PrivateRoute exact path="/messages/sent" component={MessagesPage} />
											<PrivateRoute exact path="/messages/archived" component={MessagesArchivedPage} />

											{/* Others */}
											<PrivateRoute exact path="/download" component={DownloadPage} />
											<PrivateRoute exact path="/terms" component={TermsPage} />
											<PrivateRoute exact path="/backfective/invitations"
												component={BackfectivePageInvitations}
											/>
											<PrivateRoute exact path="/calls-page" component={CallsPage} />


											{/* Remote Check-in */}
											<PrivateRoute exact path="/virtual-point" component={CheckinDashboard} />
											<PrivateRoute exact path="/virtual-point/control/overview" component={CheckinControl} />
											<PrivateRoute exact path="/virtual-point/control/solicitations" component={CheckinControlSolicitations} />
											<PrivateRoute exact path="/virtual-point/reports/points" component={CheckinReportsPointsPage} />
											<PrivateRoute exact path="/virtual-point/reports/signed-points" component={CheckinReportsSignedPointsPage} />
											<PrivateRoute exact path="/virtual-point/reports/points/:mu_id" component={CheckinReportsPointsDetailsPage} />
											<PrivateRoute exact path="/virtual-point/reports/signed-points/:mu_id" component={CheckinReportsPointsDetailsPage} />
											<PrivateRoute exact path="/virtual-point/reports/overtime" component={CheckinReportsOvertimePage} />
											<PrivateRoute exact path="/virtual-point/reports/map" component={CheckinReportsMapPage} />
											<PrivateRoute exact path="/virtual-point/history" component={CheckinHistory} />
											<PrivateRoute exact path="/settings/virtual-point" component={CheckinSettings} />
											<PrivateRoute exact path="/settings/virtual-point/teams" component={CheckinSettingsTeams} />
											<PrivateRoute exact path="/settings/virtual-point/teams/:team_id" component={TeamSettings} />

											{/* Rooms Management */}
											<PrivateRoute exact path="/spaces" component={Spaces} />
											<PrivateRoute exact path="/spaces/reservations" component={Spaces} />
											<PrivateRoute exact path="/spaces/management" component={RoomsManagement} />
											<PrivateRoute exact path="/spaces/management/create" component={RoomCreate} />
											<PrivateRoute exact path="/spaces/management/edit/:id" component={RoomCreate} />
											<PrivateRoute exact path="/spaces/reports-colabs" component={RoomsReports} />
											<PrivateRoute exact path="/spaces/maps" component={RoomsMap} />
											<PrivateRoute exact path="/spaces/maps/:id" component={RoomsMapEditor} />
											<PrivateRoute exact path="/spaces/reservation/:id" component={RoomReservation} />
											<PrivateRoute exact path="/spaces/my-reservations" component={RoomMyReservations} />
											<PrivateRoute exact path="/settings/spaces/attributes" component={RoomAttributesPage} />

											{/* Project Management */}
											<PrivateRoute exact path="/project_management/home" component={ProjectManagementHomePage} />
											<PrivateRoute exact path="/project_management/home/workspace/:id" component={ProjectManagementWorkspaceDetailsPage} />
											<PrivateRoute exact path="/project_management/home/boards/:id" component={ProjectManagementListsPage} />
											<PrivateRoute exact path="/project_management/workspaces" component={ProjectManagementWorkspacesPage} />
											<PrivateRoute exact path="/project_management/workspaces/:id" component={ProjectManagementWorkspaceDetailsPage} />
											<PrivateRoute exact path="/project_management/archived-boards" component={ProjectManagementBoardsArchivedPage} />
											<PrivateRoute exact path="/project_management/workspaces/boards/:id" component={ProjectManagementListsPage} />
											{/* <PrivateRoute exact path="/project_management/settings/workspace-types" component={ProjectManagementWorkspaceTypesPage} /> */}
											<PrivateRoute exact path="/project_management/reports/workspaces" component={ProjectManagementReportsTimelinePage} />
											<PrivateRoute exact path="/project_management/reports/boards" component={ProjectManagementReportsTimelinePage} />
											<PrivateRoute exact path="/project_management/reports/timeline" component={ProjectManagementReportsTimelinePage} />
											<PrivateRoute exact path="/settings/project_management/workspace-types" component={ProjectManagementWorkspaceTypesPage} />


											{/* Remote Check-in Mobile */}
											<PrivateRoute exact path="/checkin-remote" component={RemoteCheckinDashboardPage} />
											<PrivateRoute exact path="/checkin-remote/downloads" component={RemoteCheckinDownloadPage} />
											<PrivateRoute exact path="/checkin-remote/reports/teammates" component={RemoteCheckinReportsTeammatesPage} />
											<PrivateRoute exact path="/checkin-remote/reports/teammates/:id" component={RemoteCheckinReportsTeammatesDetailsPage} />
											<PrivateRoute exact path="/checkin-remote/reports/map" component={RemoteCheckinReportsMapPage} />

											{/* Remote Check-in Mobile */}
											<PrivateRoute exact path="/inventory" component={InventoryDashboardPage} />
											<PrivateRoute exact path="/inventory/software" component={InventorySoftwarePage} />
											<PrivateRoute exact path="/inventory/software/:id" component={InventorySoftwareDetailsPage} />
											<PrivateRoute exact path="/inventory/software/:id/:mu_id" component={InventorySoftwareColabDetailsPage} />
											<PrivateRoute exact path="/inventory/colabs" component={InventoryColabsPage} />
											<PrivateRoute exact path="/inventory/colabs/:id" component={InventoryColabsDetailsPage} />

											{/* Performance Assessment  */}
											{/* <PrivateRoute exact path="/performance-assessment" component={PerformanceAssessmentsCreatedPage} /> */}
											<PrivateRoute exact path="/performance-assessment/created" component={PerformanceAssessmentsCreatedPage} />
											<PrivateRoute exact path="/performance-assessment/new" component={PerformanceAssessmentsNewPage} />
											<PrivateRoute exact path="/performance-assessment/edit/:id" component={PerformanceAssessmentsNewPage} />
											<PrivateRoute exact path="/performance-assessment/details/participants/:id" component={PerformanceAssessmentsDetailsParticipantsPage} />
											<PrivateRoute exact path="/performance-assessment/details/replies/:id" component={PerformanceAssessmentsDetailsRepliesPage} />
											<PrivateRoute exact path="/performance-assessment/details/results/:id" component={PerformanceAssessmentsDetailsResultsPage} />
											<PrivateRoute exact path="/performance-assessment/assess" component={PerformanceAssessmentsAssessPage} />
											{/* <PrivateRoute exact path="/performance-assessment/dashboard" component={PerformanceAssessmentDashboardPage} /> */}
											<PrivateRoute exact path="/performance-assessment/pre-settings/skills" component={PerformanceAssessmentSkillsPage} />
											<PrivateRoute exact path="/performance-assessment/pre-settings/scales" component={PerformanceAssessmentScalesAnswersPage} />
											<PrivateRoute exact path="/performance-assessment/pre-settings/indicators" component={PerformanceAssessmentScalesResultIndicatorsPage} />
											<MsalProvider instance={this.props.pca}><PrivateRoute exact path="/performance-assessment/feedback" component={PerformanceAssessmentFeedbackPage}  /> </MsalProvider>
											<PrivateRoute exact path="/performance-assessment/feedback/report/:evaluated_id" component={PerformanceAssessmentFeedbackReportPage} />
											<PrivateRoute exact path="/performance-assessment/feedback/action-plan/:evaluated_id" component={PerformanceAssessmentFeedbackActionPlanPage} />
											<PrivateRoute exact path="/performance-assessment/action-plan" component={PerformanceAssessmentActionPlanPage} />
											<PrivateRoute exact path="/performance-assessment/action-plan/details/:action_plan_id" component={PerformanceAssessmentFeedbackActionPlanPage} />
											<PrivateRoute exact path="/performance-assessment/action-plan/create/:new_action_plan" component={PerformanceAssessmentFeedbackActionPlanPage} />
											<PrivateRoute exact path="/performance-assessment/reports" component={PerformanceAssessmentReportsPage} />
											<PrivateRoute exact path="/performance-assessment/reports/assessments" component={PerformanceAssessmentReportsAssessmentsPage} />
											<PrivateRoute exact path="/performance-assessment/reports/assessments/:id" component={PerformanceAssessmentReportsAssessmentsDetailsPage} />
											
											<PrivateRoute
												exact
												path="/performance-assessment/answer-form/:id"
												component={ProductivityPerformanceFormPage}
											/>
											
											{
												true && (
													<>
														<PrivateRoute exact path="/performance-assessment" component={ExecutivePage} />
														<PrivateRoute exact path="/performance-assessment/widgets" component={WidgetsSettings} />
													</>
												)
											}
											<div className="overlay"></div>
										</>
									</MessagesCountProvider>
								}
							</>
						</CurrentModuleProvider>
						{path.length > 0 && path[1] !== '' && !known_routes.includes(path[1]) &&
							<Route component={NotFoundPage} />
						}
					</Router>
				</RoomsFilterProvider>

			</DatePickerFilterProvider>
		);
	}
}

function mapStateToProps(state) {
	const { alert } = state;
	const { authentication } = state;
	return {
		alert,
		usersActions,
		authentication,
		userDetails: state.authentication.userDetails,
	};
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
