import React, { useEffect, useState } from 'react'
import style from './styles.module.scss';
import { authHeader, numberToPercentString, secondsToHm, secondsToHours } from '../../../_helpers';
import IconInfo from '../../_icons/IconInfo';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@mui/material';
import { api } from '../../../_helpers/api';
import Widget9Skeleton from './Widget9Skeleton';
import IconSite from '../../_icons/IconSite';
import IconOutdoor from '../../_icons/IconOutdoor';
import IconSoftware from '../../_icons/IconSoftware';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export default function Widget9({
  alertActions,
  currentDate,
  width,
  userDetails,
  filters
}) {
  const { t, i18n } = useTranslation('common');
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(true);

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const getData = async ({filterStartDate, filterEndDate, filterTeams, filterTeammates}) => {
    try {
      setFetching(true)
      const response = await api.get(`/stats/executive/top-activities?year_month=${currentDate.format('YYYY-MM')}&current_day=${currentDate.format('YYYY-MM-DD')}${filterStartDate}${filterEndDate}${filterTeams}${filterTeammates}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget9!!!')
      }
      setData(response.data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData(filters)
  }, [])

  return (
    !fetching
      ?

      <Grid item xs={width}>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <div className={style.boxTitle}>                
                <Link className={"linkDashRedirect"} to={'/productive/activities'}>
                  <h6 className={style.title}>{t("widgets.widget9.title")}</h6>
                </Link>
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t("widgets.widget9.tooltip")}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo /></div>
                </BefectiveTooltip>
              </div>
            </div>

            <ul className={style.listAppsContainer}>
              {
                data.length > 0
                  ?
                  data.slice(0, 4).map(app => (
                    <li className={style.boxItem} key={app.tracked_id}>
                      <div className={style.boxApp}>
                        <div className={style.boxIcon}>
                          {
                            (!app.type || app.type === "Software") && <IconSoftware />
                          }
                          {
                            app.type === "Outdoor" && <IconOutdoor />
                          }
                          {
                            app.type === "Site" && <IconSite />
                          }
                        </div>
                        <div>
                          <h6 className={style.titleApp}>{app.exhibition_name}</h6>
                          <span className={style.subtitleApp}>{app[`name_${lang}`]}</span>
                        </div>
                      </div>
                      <div className={style.boxTimeInfo}>
                        <h6 className={style.timeInfo}>{`${Math.round(app.percent)}%`}</h6>
                        {/* <h6 className={style.timeInfo}>{secondsToHm(app.total_duration, false)}</h6> */}
                      </div>
                    </li>

                  ))
                  :
                  <li className={style.boxItem}>
                    <span className={style.subtitleApp}>{t("widgets.widget9.none_apps")}</span>
                  </li>

              }
            </ul>
          </div>
        </div>
      </Grid>
      :
      <Grid item xs={width}>
        <Widget9Skeleton />
      </Grid>

  )
}
