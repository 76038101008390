import React from 'react';
import styles from './GradientBar.module.scss';


const GradientBar = ({ colors = ["#1E6B7F", "#FDBE2B", "#86B2B3", "#187DFF", "#22BEAD"], markerPercentage = 0, avgScore=0 }) => {
  const step = 100 / colors.length;
  const gradientStops = colors.map((color, index) => {
    if(index === 0) {
      return `${color} 0%`;
    }
    if(index === colors.length - 1) {
      return `${color} 100%`;
    }
    return `${color} ${(index * step) + 11 }%`;

  });

  const gradient = `linear-gradient(90deg, ${gradientStops.join(', ')})`;

  const markerPosition = Math.max(0, Math.min(markerPercentage, 100));
  
  const calculatePosition = (parcentMarkerPosition) => {

    if (parcentMarkerPosition > 90) {
      return `calc(100% - 40px)`;
    }

    if (parcentMarkerPosition < 10) {
      return `calc(0% + 40px)`;
    }

    return `calc(${parcentMarkerPosition}%)`;
    
  }
  return <div className={styles.bar} style={{background: gradient}}>
          <div className={styles.marker} style={{left: calculatePosition(markerPosition)}}></div>
          <div className={styles.markerText} style={{left: calculatePosition(markerPosition)}}>{avgScore}</div>
        </div>;
};

export default GradientBar;