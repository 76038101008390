import React, { useEffect, useState } from 'react'
import style from './styles.module.scss';
import { authHeader, secondsToHm, secondsToHours } from '../../../_helpers';
import IconInfo from '../../_icons/IconInfo';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, Typography } from '@mui/material';
import { api } from '../../../_helpers/api';
import Widget11Skeleton from './Widget11Skeleton';
import ReactApexChart from 'react-apexcharts';
import { CaretDoubleDown, CaretDoubleUp, Sliders } from 'phosphor-react';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';
import { Popover } from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';

export default function Widget11({
  alertActions,
  currentDate,
  width,
  filters
}) {
  const { t, i18n } = useTranslation('common');
  const [anchorEl, setAnchorEl] = useState(null);
  const [monthFilters, setMonthFilters] = useState({
    month_1: null,
    month_2: null
  });
  const [monthFiltersErrors, setMonthFiltersErrors] = useState({
    month_1: null,
    month_2: null
  });


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [fetching, setFetching] = useState(true);
  const [chartData, setChartData] = useState({
    stats: {
      current_month_productive_time_avg: 0,
      previous_month_productive_time_avg: 0,
      percent_status: 0
    },
    series: [],
    options: {
      chart: {
        height: 200,
        type: 'line',
        zoom: {
          enabled: false
        },
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 4,
        curve: 'smooth'
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            fontSize: '8px',
            colors: '#4F7091'
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#4F7091'
          },
          formatter: (value) => {
            return secondsToHm(value) ;
          }
        }
      },
      legend: {
        show: true
      },
      tooltip: {
        shared: true
      },
    }
  })

  const getData = async ({filterTeams, filterTeammates, month1 = null, month2 = null}) => {
    try {
      setFetching(true)

      const filterDate1 = month1 ? `&month_1=${monthFilters.month_1 ? moment(monthFilters.month_1).format('YYYY-MM-DD') : ''}` : ''
      const filterDate2 = month2 ? `&month_2=${monthFilters.month_2 ? moment(monthFilters.month_2).format('YYYY-MM-DD') : ''}` : '' 
      const response = await api.get(`/stats/executive/productive-graph?year_month=${currentDate.format('YYYY-MM')}&current_day=${currentDate.format('YYYY-MM-DD')}${filterDate1}${filterDate2}${filterTeams}${filterTeammates}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget11!!!')
      }
      const { data } = response
      if (data && data.series.length > 0) {
        setChartData({
          stats: {
            current_month_productive_time_avg: secondsToHm(data.current_month_productive_time_avg),
            previous_month_productive_time_avg: secondsToHm(data.previous_month_productive_time_avg),
            percent_status: data.percent_status
          },
          series: data.series,
          options: {
            colors: [data.percent_status > 0 ? '#22BEAD' : '#FF7A90', '#D2D7E1'],
            chart: {
              height: 200,
              type: 'line',
              zoom: {
                enabled: false
              },
              stacked: false,
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: 4,
              curve: 'smooth'
            },
            xaxis: {
              categories: data.categories,
              labels: {
                style: {
                  fontSize: '8px',
                  colors: '#4F7091'
                }
              }
            },
            yaxis: {
              labels: {
                style: {
                  colors: '#4F7091'
                },
                formatter: (value) => {
                  return secondsToHm(value) ;
                }
              }
            },
            legend: {
              show: true
            },
            tooltip: {
              shared: true
            },
          }
        })
      }
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  const handleApplyFilters = async (actionReset=false) => {
    if (actionReset === true) {
      setMonthFilters({
        month_1: null,
        month_2: null
      })
      await getData({...filters, month1: null, month2: null})
      handleClose()
      return      
    }

    const errors = {}
    if(!monthFilters.month_1) {
      errors.month_1 = true
    }
    if(!monthFilters.month_2) {
      errors.month_2 = true
    }

    if(errors.month_1 || errors.month_2) {
      setMonthFiltersErrors(errors)
      return;
    }
    await getData({...filters, month1: monthFilters.month_1, month2: monthFilters.month_2})
    handleClose()
  }

  const handleChangeInputMonth = (event) => {
    const { name, value } = event.target
    setMonthFilters({
      ...monthFilters,
      [name]: value
    })
    setMonthFiltersErrors({
      ...monthFiltersErrors,
      [name]: false
    })
  }

  useEffect(() => {
    getData({...filters, month1: null, month2: null})
  }, [])

  return (
    !fetching
      ?

      <Grid item xs={width}>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <div className={style.boxTitle}>
                <h6 className={style.title}>{t('widgets.widget11.title')}</h6>
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('widgets.widget11.tooltip')}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo /></div>
                </BefectiveTooltip>
              </div>
              <button className={style.buttonFilter} onClick={handleClick}>
                <Sliders size={16} color="#4F7091" weight="bold" />
              </button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className={style.popoverFilter}>
                  <div>
                    <h6 className={style.title}>{t('widgets.widget11.title_filter')}</h6>
                  </div>
                  <div className={style.inputBoxField}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      locale={i18n.language}
                    >
                      <DatePicker
                        label={t('widgets.widget11.month_first')}
                        value={monthFilters.month_1}
                        inputFormat={"MM/YYYY"}
                        views={['month', 'year']}
                        onChange={(value) => (value && value.$d) && handleChangeInputMonth({ target: { name: 'month_1', value: value.$d } })}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            fullWidth
                            {...params}
                            sx={{
                              width: "100%",
                              height: "45px",
                              fieldset: {
                                border: "1px solid #DEE3E9CC",
                                height: "45px",
                              },
                              input: {
                                color: "#4F7091",
                                paddingY: "11.5px",
                              },
                              label: { top: "-4px" },
                            }}
                            name="month_1"
                            error={monthFiltersErrors.month_1}
                            helperText={""}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className={style.inputBoxField}>
                  <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      locale={i18n.language}
                    >
                      <DatePicker
                        label={t('widgets.widget11.month_second')}
                        value={monthFilters.month_2}
                        inputFormat={"MM/YYYY"}
                        views={['month', 'year']}
                        onChange={(value) => (value && value.$d) && handleChangeInputMonth({ target: { name: 'month_2', value: value.$d } })}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            fullWidth
                            {...params}
                            sx={{
                              width: "100%",
                              height: "45px",
                              fieldset: {
                                border: "1px solid #DEE3E9CC",
                                height: "45px",
                              },
                              input: {
                                color: "#4F7091",
                                paddingY: "11.5px",
                              },
                              label: { top: "-4px" },
                            }}
                            name="month_2"
                            error={monthFiltersErrors.month_2}
                            helperText={""}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <button className={`${style.buttonFilterForm} ${style.apply}`} onClick={() => handleApplyFilters(false)}>{t('widgets.widget11.apply')}</button>
                  <button className={`${style.buttonFilterForm} ${style.reset}`} onClick={() => handleApplyFilters(true)}>{t('widgets.widget11.reset')}</button>
                </div>
              </Popover>
            </div>

            {
              chartData.stats.percent_status > 0
                ?
                <div className={style.boxInfoStats}>
                  <span style={{ color: '#D2D7E1' }}>{chartData.stats.previous_month_productive_time_avg}&nbsp;/&nbsp;</span><span style={{ color: '#22BEAD' }}>{chartData.stats.current_month_productive_time_avg}</span>&nbsp;<span style={{ color: '#22BEAD' }}><CaretDoubleUp size={16} color='#22BEAD' weight="bold" />{chartData.stats.percent_status + '%'}</span>

                </div>
                :
                <div className={style.boxInfoStats}>
                  <span style={{ color: '#D2D7E1' }}>{chartData.stats.previous_month_productive_time_avg}&nbsp;/&nbsp;</span><span style={{ color: '#FF7A90' }}>{chartData.stats.current_month_productive_time_avg}</span>&nbsp;<span style={{ color: '#FF7A90' }}><CaretDoubleDown size={16} color='#FF7A90' weight="bold" />{Math.abs(chartData.stats.percent_status) + '%'}</span>
                </div>
            }
            <div className={style.chartContainer}>
              <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={200} className={'productiveChart'} />
            </div>
          </div>
        </div>
      </Grid>
      :
      <Grid item xs={width}>
        <Widget11Skeleton />
      </Grid>

  )
}
