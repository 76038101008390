import React, { useEffect, useMemo, useState } from 'react'
import Toolbar from '../../_components/_shared/Toolbar'
import Filterbar from '../../_components/_shared/Filterbar'
import { connect } from 'react-redux'
import { alertActions, teammatesActions } from '../../_actions'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { authHeader, befectiveFormatDate, history } from '../../_helpers'
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton'
import { api } from '../../_helpers/api'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { IconButton } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import NoData from './NoData'
import BefectiveTooltip from '../../_components/_shared/BefectiveTooltip'
import FeedbacksTable from '../../_components/performance_assessments/PerformanceAssessmentFeedback/FeedbacksTable'
import { useMsal } from '@azure/msal-react';

function PerformanceAssessmentFeedbackPage({
  alertActions,
  userDetails,
  filter,  
  teammates
}) {
  const msal = useMsal();

  const { t, i18n } = useTranslation("common");
  const [firstFetch, setFirstFetch] = useState(true);
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);
  const [firstData, setFirstData] = useState([]);
  const [dateRef, setDateRef] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [isUpdating, setIsUpdating] = useState(false);
  const [accountsLogged, setAccountsLogged] = useState([])

  console.log("accountsLogged:",accountsLogged)
  console.log("msal:",msal)
  let lang = i18n.language || "en"

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  }

  function getDataFormat(lang) {
    if (lang === 'en') {
      return "MM/DD/YYYY"
    } else {
      return "DD/MM/YYYY"
    }
  }

  async function getFeedbacks(
    date_ref,
    pageIndex,
    pageSize,
    isLoading,
    teams = null,
    teammates = null
  ) {

    try {

      if (isLoading) {
        setFetching(true)
      }

      const queryTeams = teams ? `&teams=${teams.map(t=>t.id).join(',')}` : ''
      const queryTeammates = teammates ? `&users=${teammates.map(t=>t.id).join(',')}` : ''
      const queryFilterYear = date_ref ? `&year=${moment(date_ref).year()}` : ''
      const response = await api.get(`/performance-assessment/feedback?page=${pageIndex}&per_page=${pageSize}${queryFilterYear}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      setData(response.data)

      if(firstFetch){
        setFirstData(response.data)
        setFirstFetch(false)
      }

      setFetching(false);
      
    } catch (error) {
      console.log(error);
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false);
    }
  }

  const handleChangeDateFeedback = async (date, feedback) => {
    try {
      setIsUpdating(true)
      await api.put(`/performance-assessment/feedback`, {date: moment(date.$d).format('YYYY-MM-DD'), evaluated_id: feedback.evaluated_id}, {headers: authHeader()}) 
      setIsUpdating(false)
      alertActions.success(t('performance_assessment.messages.feedback_scheduled_success'))
      getFeedbacks(dateRef, 0, localStorage.getItem("pageSizeStorage") || 20, false, null, null)        
    } catch (error) {
      console.log(error)
      setIsUpdating(false)
      alertActions.error(t('performance_assessment.messages.feedback_scheduled_error'))     
    }
  }

  const getFeedbackData = ({pageIndex, pageSize, filter}) => {

    let date_ref = dateRef;

    if (filter.singleDate) {
      date_ref = filter.singleDate;
    }

    setDateRef(date_ref);

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getFeedbacks(date_ref, pageIndex, pageSize, true, teams, teammates);
  };

  const handleRowClick = (row) => {
    return history.push(`/performance-assessment/feedback/report/${row.evaluated_id}`);
  };

  async function getActiveAccountEvents() {
    try {
      if (msal.instance.getAllAccounts().length === 0) {
        console.log("aqui2222222222222")
        setAccountsLogged([])
        return;
      }

      const response = await api.get(`integrations/outlook-calendar/list-accounts`, {headers: authHeader()})

      if (response.data.accounts.length === 0) {
        return;
      }

      setAccountsLogged(response.data.accounts)
    } catch (error) {

      console.log(error.response)
    }
  }

  useEffect(() => {
    getFeedbackData({
      pageIndex: 0,
      pageSize: localStorage.getItem("pageSizeStorage") || 20,
      filter: {...filter}
    })
  }, [filter.singleDate, filter.teams, filter.teammates]);

  useEffect(() => {    
    document.title = `Befective | ${t('menu.performance_assessment.feedback')} `;  
    getActiveAccountEvents()
  }, [])

  return (
    <section className="mainContent">
      <Toolbar />

      <Filterbar
        filterYears={"single"}
        filterWho={"multi"}
        filterWhen={"disabled"}
        filterWhat={"disabled"}
        maxDate={new Date()}
      />

      <main className="mainContentPage">
        {!fetching ? (
          firstData.length > 0 
          ?
            <FeedbacksTable
              title={t("performance_assessment.page_feedback.title_table")}
              lang={lang}
              data={data}
              handleChangeDateFeedback={handleChangeDateFeedback}
              isUpdating={isUpdating}
              actionColumn={(row) => (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                  <IconButton
                    style={{
                      height:'32px',
                      width:'32px'
                    }}
                    aria-label="expand row"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRowClick(row);
                    }}
                  >
                    <Search style={{color: "#4F7091"}} />
                  </IconButton>
                </div>
              )}
              getDataFormat={getDataFormat}
              teammates={teammates}
              getFeedbackData={() => getFeedbackData({pageIndex: 0, pageSize: localStorage.getItem("pageSizeStorage") || 20, filter: {...filter}})}
              alertActions={alertActions}
              accountsLogged={accountsLogged}
              msal={msal}
            />
          :
          <NoData />
        ) : (
          <GlobalSkeleton
            totalRowsStats={0}
            totalTables={1}
            heightTable={600}
          />
        )}
      </main>
    </section>
  )
}



function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter,
    teammates: state.teammates.payload
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceAssessmentFeedbackPage)