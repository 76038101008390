import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import moment from 'moment';

import { alertActions } from '../../_actions';
import HeaderInfoCalendar from '../../_components/integrations/HeaderInfoCalendar';
import ListEventsCalendar from '../../_components/integrations/ListEventsCalendar';
import AlertBox from '../../_components/_shared/AlertBox';
import Filterbar from '../../_components/_shared/Filterbar';
import MenuAccount from '../../_components/_shared/MenuAccount';
import MenuMU from '../../_components/_shared/MenuMU';
import { api } from '../../_helpers/api';

import { ListEventsCalendarSkeleton } from '../../_components/integrations/ListEventsCalendarSkeleton';

import msalConfig from '../../msal-config';
import { createNewEventMicrosoftCalendar, deleteEventMicrosoftCalendar, getUserWeekCalendar, updateEventMicrosoftCalendar } from '../../_helpers/graph';

import './styles.scss'
import ToolbarMu from '../../_components/_shared/ToolbarMu';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader } from '../../_helpers';

function IntegrationOutlookCalendarPage({ filter, alertActions, userDetails }) {

  const { t } = useTranslation('common');

  const msal = useMsal();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [accountMenu, setAccountMenu] = useState(false);
  const [events, setEvents] = useState([])
  const [accountsLogged, setAccountsLogged] = useState([])
  const [isLoadingEvents, setIsLoadingEvents] = useState(true)

  const date = filter.singleDate ?? String(moment().format()).split('T')[0]

  const handleClickMenuAccounts = (event) => {
    setAnchorEl(event.currentTarget);
    setAccountMenu(!accountMenu)
  };

  function toggleMenu(type) {
    if (type == 'main') {
      setIsMenuOpen(!isMenuOpen);
    } else {
      if (isMobile) {
        setIsMenuOpen(false)
      }
    }
  };

  function toggleFromMenu(value) {

    if (value == 'closed') {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false)
    }
  }


  async function handleDeactivateAllOutlookAccounts() {
    try {
      setAccountMenu(false)
      setIsLoadingEvents(true)

      await api.patch(`integrations/outlook-calendar/deactivate-all`, {}, {headers: authHeader()})

      setEvents([])
      setAccountsLogged([])
      setIsLoadingEvents(false)
      alertActions.success(t('integrations.success.deleted'))
    } catch (error) {
      console.log(error.response)
      setIsLoadingEvents(false)
      //alertActions.error(t('integrations.errors.delete'))
    }
  }

  const handleMicrosoftLogin = async () => {
    try {
      console.log("/aqui1")
      setIsLoadingEvents(true)
      const responseLogin = await msal.instance.loginPopup({
        scopes: msalConfig.scopes,
        prompt: 'select_account',
      });

      if (!responseLogin.scopes.includes('Calendars.Read')) {
        setIsLoadingEvents(false)
        //alertActions.error(t('integrations.errors.login'))
        return
      }

      const response = await api.post(`integrations/outlook-calendar/create-user`, {
        microsoft_user: {
          displayName: responseLogin.account.name,
          userPrincipalName: responseLogin.account.username
        }
      },
      {headers: authHeader()}
      )

      setAccountsLogged(response.data.accounts)

      setAccountMenu(false)
      
      if(accountsLogged.length > 0) {
        window.location.reload(true)
      }else{
        setIsLoadingEvents(false)
      }
    } catch (error) {
      setIsLoadingEvents(false)
      console.log("/error1",error)
      console.log(error.response)
      //alertActions.error(t('integrations.errors.login'))
    }
  }

  async function handleGetAccountEvents(account_id) {
    try {
      setAccountMenu(false)
      setIsLoadingEvents(true)
      const response = await api.patch(`integrations/outlook-calendar/${account_id}/toggle-is-active`, {}, {headers: authHeader()})

      setAccountsLogged(response.data.accounts)

      window.location.reload(true)
      setIsLoadingEvents(false)
    } catch (error) {
      console.log(error.response)
      setIsLoadingEvents(false)
      //alertActions.error(t('integrations.errors.get_data'))
    }
  }

  async function getActiveAccountEvents() {
    try {
      setIsLoadingEvents(true)

      if (msal.instance.getAllAccounts().length === 0) {
        setAccountsLogged([])
        setIsLoadingEvents(false)
        return;
      }

      const response = await api.get(`integrations/outlook-calendar/list-accounts`, {headers: authHeader()})

      if (response.data.accounts.length === 0) {
        setIsLoadingEvents(false)
        return;
      }

      setAccountsLogged(response.data.accounts)

      setIsLoadingEvents(false)
    } catch (error) {
      setIsLoadingEvents(false)
      console.log(error.response)
      //alertActions.error(t('integrations.errors.get_data'))
    }
  }

  async function getEvents() {
    setIsLoadingEvents(true)

    const allAccounts = msal.instance.getAllAccounts()
    const accountActive = accountsLogged.find(account => account.is_active === true)
    const userProvider = allAccounts.find(account => account.username === accountActive.email)

    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      msal.instance,
      {
        account: userProvider,
        scopes: msalConfig.scopes,
        interactionType: InteractionType.Popup
      }
    );
    console.log("authProvider2",authProvider)
    console.log("allAccounts2",allAccounts)
    console.log("accountActive2",accountActive)
    console.log("userProvider2",userProvider)

    const events = await getUserWeekCalendar(authProvider, date)

    if(events.length === 0 ){
      setEvents([])
      setIsLoadingEvents(false)
      return
    }

    const response = await api.get(`integrations/outlook-calendar/list-events-integrated/?email=${accountActive.email}`, {headers: authHeader()})

    const { eventsIntegrated } = response.data


    const eventsFormatted = events.map(event => {
      return {
        event_id: event.id,
        title: event.subject,
        start_time: event.start.dateTime,
        end_time: event.end.dateTime,
        color_id: '0',
        organizer: event.organizer.emailAddress.address,
        is_checked: false
      }
    })

    if (eventsIntegrated.length > 0) {
      eventsFormatted.forEach(eventFormatted => {
        eventsIntegrated.forEach(event => {
          if (event.event_id === eventFormatted.event_id) {
            eventFormatted.is_checked = true
          }
        })
      })
    }
   
    
    const responseCheckReconcile = await api.post(`integrations/outlook-calendar/check-reconcile`, {  
      email: accountActive.email,
      events: eventsFormatted
    },
    {headers: authHeader()}
    )

    setEvents(responseCheckReconcile.data.newEvents)
    setIsLoadingEvents(false)
  }

  async function createEventTesting() {
    
    try {
      const allAccounts = msal.instance.getAllAccounts()
      const accountActive = accountsLogged.find(account => account.is_active === true)
      const userProvider = allAccounts.find(account => account.username === accountActive.email)

      const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
        msal.instance,
        {
          account: userProvider,
          scopes: msalConfig.scopes,
          interactionType: InteractionType.Popup
        }
      );

      const new_event = await createNewEventMicrosoftCalendar(authProvider)
    } catch (e) {
      console.log("Error on `createEventTesting` : ",e)
    }
    

  }

  async function updateEventTesting() {
    
    try {
      const allAccounts = msal.instance.getAllAccounts()
      const accountActive = accountsLogged.find(account => account.is_active === true)
      const userProvider = allAccounts.find(account => account.username === accountActive.email)

      const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
        msal.instance,
        {
          account: userProvider,
          scopes: msalConfig.scopes,
          interactionType: InteractionType.Popup
        }
      );
      const event_id = 'AQMkADAwATNiZmYAZS04N2RiLTAzMjktMDACLTAwCgBGAAADufY59msli063wkJP231BNQcAh_QsDBlNfEWwRDYetO6WKQAAAgENAAAAh_QsDBlNfEWwRDYetO6WKQAAAgs7AAAA'

      const updated = await updateEventMicrosoftCalendar(authProvider,event_id)
    } catch (e) {
      console.log("Error on `createEventTesting` : ",e)
    }
    

  }
  async function deleteEventTesting() {
    
    try {
      const allAccounts = msal.instance.getAllAccounts()
      const accountActive = accountsLogged.find(account => account.is_active === true)
      const userProvider = allAccounts.find(account => account.username === accountActive.email)

      const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
        msal.instance,
        {
          account: userProvider,
          scopes: msalConfig.scopes,
          interactionType: InteractionType.Popup
        }
      );
      const event_id = 'AQMkADAwATNiZmYAZS04N2RiLTAzMjktMDACLTAwCgBGAAADufY59msli063wkJP231BNQcAh_QsDBlNfEWwRDYetO6WKQAAAgENAAAAh_QsDBlNfEWwRDYetO6WKQAAAgs7AAAA'

      const deleted = await deleteEventMicrosoftCalendar(authProvider,event_id)
    } catch (e) {
      console.log("Error on `createEventTesting` : ",e)
    }
    

  }

  useEffect(() => {
    if (accountsLogged.length > 0) {
      getEvents()
      // async function test () {
      //   const res = await deleteEventTesting()
      //   return res
      // }
      // test()
    }
  }, [accountsLogged])


  useEffect(() => {
    getActiveAccountEvents()
  }, [filter?.singleDate])


  useEffect(() => {
    document.title = 'Befective | ' + t('integrations.title');
  });
  return (
    <>
      <section className="mainContent">
        <Toolbar
          breadcrumb={t('integrations.title')}
        />
    
        <Filterbar
          filterWho={'disabled'}
          filterWhen={'single'}
          filterWhat={'disabled'}
          actionAddAccountOutlookCalendar={true}
          setAccountMenu={setAccountMenu}
          accountMenu={accountMenu}
          accountsLogged={accountsLogged}
          isLoadingEvents={isLoadingEvents}
          handleMicrosoftLogin={handleMicrosoftLogin}
          handleClickMenuAccounts={handleClickMenuAccounts}
        />

        <MenuAccount
          accountMenu={accountMenu}
          setAccountMenu={setAccountMenu}
          accountsLogged={accountsLogged}
          handleLogin={handleMicrosoftLogin}
          handleGetAccountEvents={handleGetAccountEvents}
          handleDeactivateAllAccounts={handleDeactivateAllOutlookAccounts}
          anchorEl={anchorEl}
        />

        <main className='container-list-calendar'>
          <HeaderInfoCalendar
            info={{
              service: 'Outlook Calendar',
              accounts_connected: accountsLogged.length,
              accountActive: accountsLogged.find(acc => acc.is_active)
            }}
            isLoadingEvents={isLoadingEvents}
          />

          {!isLoadingEvents && events.length > 0 ? (
            <ListEventsCalendar
              eventsList={events}
              date={date}
              infoUser={{ organization_id: userDetails.origin_organization, mu_token: userDetails.mu_token }}
              accountIsActive={accountsLogged.find(acc => acc.is_active)}
              getActiveAccountEvents={getActiveAccountEvents}
              platform="microsoft"
            />
          ) : (
            !isLoadingEvents && (
              <p className='no-events'>
                {accountsLogged.length > 0
                  ? t('integrations.no_data')
                  : t('integrations.connect-microsoft')
                }
              </p>
            )
          )}

          {isLoadingEvents && (
            <ListEventsCalendarSkeleton />
          )}
        </main>
      </section>

    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    filter: state.filter,
    userDetails: state.authentication.userDetails
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationOutlookCalendarPage)