import React, { useEffect, useState } from 'react'
import style from './styles.module.scss';
import { authHeader, getRangeFilterWhen, secondsToHm, secondsToHours } from '../../../_helpers';
import IconInfo from '../../_icons/IconInfo';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@mui/material';
import { api } from '../../../_helpers/api';
import Widget7Skeleton from './Widget7Skeleton';
import { ChartBarHorizontal, ChartPieSlice } from 'phosphor-react';
import DonutChart from './DonutChart';
import ReactApexChart from 'react-apexcharts';
import IconOnline from '../../_icons/IconOnline';
import IconOutdoor from '../../_icons/IconOutdoor';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


export default function Widget7({
  alertActions,
  currentDate,
  width,
  filters
}) {
  const { t, i18n } = useTranslation('common');
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [toggleView, setToggleView] = useState(true);

  const getData = async ({ filterStartDate, filterEndDate, filterTeams, filterTeammates}) => {
    try {
      setFetching(true)
      const response = await api.get(`/stats/executive/time_goal_work_goal?year_month=${currentDate.format('YYYY-MM')}${filterStartDate}${filterEndDate}${filterTeams}${filterTeammates}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget7!!!')
      }
      const { data } = response;
      setData(data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData({...filters})
  }, [])

  // useEffect(() => {
  //   getData({ ...filters, toggleView: toggleView })
  // }, [toggleView])

  return (

    <Grid item xs={width} position={'relative'}>
      {/* <button className={style.btnToggle} onClick={() => setToggleView(!toggleView)}>
        {
          toggleView
            ?
            <ChartBarHorizontal
              size={18}
              weight="bold"
            />
            :
            <ChartPieSlice
              size={18}
              weight="bold"
            />
        }
      </button> */}
      {
        !fetching
          ?
          <div className={style.container}>
            <div>
              <div className={style.boxTitle}>
                {/* <Link className={"linkDashRedirect"} to={'/productive/reports/timegoal'}> */}
                  <h6 className={style.title}>{`${t('widgets.widget7.time_at_work_avg')}: ${secondsToHm(data.total_work_average)} ${data.percent_work}%`}</h6>
                {/* </Link> */}
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('widgets.widget7.tooltip1')}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo /></div>
                </BefectiveTooltip>
              </div>
              <div>
                {/* <div className={`${style.info} ${style.alignEnd}`}>{secondsToHours(data.total_expected_work)}</div> */}
                <div className={style.progressBarContainer}>
                  <div className={`${style.progressBar} ${style.progressBarBlue}`} style={{ width: `${data.percent_work}%`, maxWidth: '100%' }}></div>
                  {
                    parseInt(data.percent_work) > 3 &&
                    <div className={style.goalMarkerPercent} style={{ left: `${parseInt(data.percent_work) < 100 ? data.percent_work : '99'}%` }}>
                      <span>{`${data.percent_work}%`}</span>
                    </div>
                  }
                </div>
                <div className={style.boxInfoFooter}>
                  <div className={style.boxStats}>
                    <div className={style.info}>{`${t('widgets.widget7.software')}: ${secondsToHm(data.total_work_software_avg)} ${data.percent_work_software}%`}</div>
                    <div className={style.info}>{`${t('widgets.widget7.site')}: ${secondsToHm(data.total_work_site_avg)} ${data.percent_work_site}%`}</div>
                    <div className={style.info}>{`${t('widgets.widget7.offline')}: ${secondsToHm(data.total_work_offline_avg)} ${data.percent_work_offline}%`}</div>
                  </div>
                  <div className={`${style.info}`}>{secondsToHours(data.total_expected_work_avg)}</div>
                </div>
              </div>
            </div>

            <div>
              <div className={style.boxTitle}>
                {/* <Link className={"linkDashRedirect"} to={'/productive/reports/workgoal'}> */}
                  <h6 className={style.title}>{`${t('widgets.widget7.productive_time_avg')}: ${secondsToHm(data.total_productive_avg)} ${data.percent_productive}%`}</h6>
                {/* </Link> */}
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('widgets.widget7.tooltip2')}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo /></div>
                </BefectiveTooltip>
              </div>
              <div>
                {/* <div className={`${style.info} ${style.alignEnd}`}>{secondsToHours(data.total_expected_work)}</div> */}
                <div className={style.progressBarContainer}>
                  <div className={`${style.progressBar} ${parseInt(data.percent_productive) >= parseInt(data.target_percent) ? style.progressBarGreen : style.progressBarRed}`} style={{ width: `${data.percent_productive}%`, maxWidth: '100%' }}></div>
                  {
                    parseInt(data.percent_productive) > 3 &&
                    <div className={style.goalMarkerPercent} style={{ left: `${parseInt(data.percent_productive) < 100 ? data.percent_productive : '99'}%` }}>
                      <span>{`${data.percent_productive}%`}</span>
                    </div>
                  }
                  <div className={style.goalMarker} style={{ left: `${data.target_percent}%` }}>
                    <span style={{left: `${parseInt(data.target_percent) < 99 ? '-16px' : '-35px'}`}}>{`${t('widgets.widget7.goal')}: ${data.target_percent}% (${String(secondsToHours(data.total_expected_productive_time_avg).trim())})`}</span>
                  </div>
                </div>
                <div className={style.boxInfoFooter}>
                  <div className={style.boxStats}>
                    <div className={style.info}>{`${t('widgets.widget7.software')}: ${secondsToHm(data.total_productive_software_avg)} ${data.percent_productive_software}%`}</div>
                    <div className={style.info}>{`${t('widgets.widget7.site')}: ${secondsToHm(data.total_productive_site_avg)} ${data.percent_productive_site}%`}</div>
                    <div className={style.info}>{`${t('widgets.widget7.offline')}: ${secondsToHm(data.total_productive_offline_avg)} ${data.percent_productive_offline}%`}</div>
                  </div>
                  <div className={`${style.info}`}>{secondsToHours(data.total_expected_work_avg)}</div>
                </div>
              </div>
            </div>

          </div>                
          :
          <Widget7Skeleton />
      }
    </Grid>
  )
}
