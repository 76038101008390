import React, { useEffect, useState } from 'react'
import { Drawer, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next';
import { api } from '../../../_helpers/api';
import { authHeader, befectiveFormatDate, getAvatarUrl, getMonths, getMoodById } from '../../../_helpers';
import styles from './MessageAlertDrawer.module.scss'
import MessageAlertDrawerSkeleton from './MessageAlertDrawerSkeleton';
import { Avatar } from '@mui/material';
import moment from 'moment';
import { Chat } from 'phosphor-react';

export default function AlertsNegativesDrawer({
  openDrawer,
  closeDrawer,
  handleOpenModalSendMessage = () => {},
  handleOpenModalMonthDetails = () => {},
  handleOpenDrawerMessageAlert = () => {}
}) {

  const { t, i18n } = useTranslation("common");
  const [dataDrawer, setDataDrawer] = useState({
    total_users_alerts: 0,
    current_date: null,
    seven_day_past: null,
    registers: []
  });
  const [loading, setLoading] = useState(true);
  const [filterByStatus, setFilterByStatus] = useState('all')
  
  const getAlertsNegatives = async ({ filterStatus = 'all' }) => {
    try {
      setLoading(true)

      const queryFilterByStatus = filterStatus !== 'all' ? `?pending=${filterStatus==='pending'}` : ''

      const {data} = await api.get(`/report/mood/alert${queryFilterByStatus}`, {
        headers: authHeader(),
      })

      setDataDrawer(data)

      setLoading(false)
      
    } catch (error) {
      //alertActions.error(error)
      console.log(error)
      setLoading(false)
    }
  }

  const handleChangeFilterStatus = async (e) => {
    const { value } = e.target
    setFilterByStatus(value)
    await getAlertsNegatives({ filterStatus: value })
  }
  
  useEffect(() => {
    if(openDrawer) {
      getAlertsNegatives({
        filterStatus: filterByStatus
      })
    }
  }, [openDrawer])

  return (
    <Drawer anchor='right' open={openDrawer} onClose={closeDrawer}>
      <div className={styles.boxIconClose}>
        <IconButton aria-label="close" onClick={closeDrawer}>
          <Close fontSize="medium" />
        </IconButton>
      </div>

      <div className={styles.containerDrawer}>
        <header>
          <h2 className={styles.titleDrawer}>
            {`${dataDrawer.total_users_alerts} ${t('reports_mood.drawer_alerts_negatives.colabs_with_alert')}`}
          </h2>
          <p className={styles.subtitleDrawer}>{t('reports_mood.drawer_alerts_negatives.colabs_with_alert_subtitle')}</p>
          <label className={styles.selectControl} htmlFor="status">
            Status
            <select name="status" id="status" onChange={handleChangeFilterStatus} value={filterByStatus} disabled={loading}>
              <option value="all">{t('reports_mood.drawer_alerts_negatives.all')}</option>
              <option value="send">{t('reports_mood.drawer_alerts_negatives.msg_send')}</option>
              <option value="pending">{t('reports_mood.drawer_alerts_negatives.msg_pending')}</option>
            </select>
          </label>
        </header>

        <main className={styles.mainDrawerContent}>
            {
              !loading
              ?
                <div className={styles.historyContainer}>
                  <div className={styles.boxMonthYear}>
                    {`${t('reports_mood.drawer_alerts_negatives.from')} ${befectiveFormatDate(dataDrawer.seven_day_past, i18n.language)} ${t('reports_mood.drawer_alerts_negatives.to')} ${befectiveFormatDate(dataDrawer.current_date, i18n.language)}`}
                  </div>
                  {
                    (dataDrawer.registers && dataDrawer.registers.length > 0)
                    ?
                    dataDrawer.registers.map((reg, index) => (
                      <div key={`register-${index}`} className={styles.boxRegister}>
                        <div className={styles.headInfoBox}>
                          <Avatar
                              alt={reg.username}
                              src={
                                getAvatarUrl({
                                  name: reg.username || "-----",
                                },
                                  "187DFF"
                                )
                              }
                              style={{ width: 20, height: 20 }}
                              title={reg.username || "-----"}
                            />
                            <span>{reg.username}</span>
                        </div>
                        {
                          reg.moods.length > 0 &&
                          <ul className={styles.listMoods}>
                            {
                              reg.moods.map((mood, index) => {
                                const  {key, img} = getMoodById(parseInt(mood.mood))
                                return <li key={`mood-item-${index}`} className={styles.itemMoods}>
                                  <img title={t(`widgets.moods.${key}`)} src={img} alt={t(`widgets.moods.${key}`)} width={'16px'} height={'16px'}/>
                                  <span>{mood.date ? moment(mood.date).format('DD/MM') : '--/--'}</span>
                                </li>
                              })
                            }
                          </ul>
                        }
                        <div className={styles.containerButtons}>
                          {
                            reg.has_already_been_sent
                            ?
                            <button className={`${styles.buttonMessage} ${styles.send}`} onClick={() => handleOpenDrawerMessageAlert({user_id: reg.machine_user_id, label: reg.username})}>
                              <span>{reg?.total_msg_sent || 0}</span>
                              <Chat size={12} weight="regular" />
                            </button>
                            :
                            <button className={`${styles.buttonMessage} ${styles.send}`} onClick={() => handleOpenModalSendMessage({user_id: reg.machine_user_id, label: reg.username})}>
                              {t('reports_mood.drawer_alerts_negatives.sent_message')}
                            </button>                            
                          }
                          <button className={`${styles.buttonMessage} ${styles.month}`} onClick={() => handleOpenModalMonthDetails({user_id: reg.machine_user_id, label: reg.username})}>
                            {t('reports_mood.drawer_alerts_negatives.see_mood_calendar')}
                          </button>
                        </div>
                      </div>                      
                    ))
                    :
                    <div className={styles.noneRegister}>
                      {t('reports_mood.no_alerts_negatives')}
                    </div>
                  }
                </div>             
              :
              <MessageAlertDrawerSkeleton/>
            }
        </main>
      </div>
    </Drawer>
  )
}
