import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Modal,
  Slide,
  makeStyles,
} from "@material-ui/core";
import styles from "./NewItemModal.module.scss";
import { useTranslation } from "react-i18next";
import { api } from "../../../_helpers/api";
import { authHeader } from "../../../_helpers";
import IconClose from "../../_icons/IconClose";
import { NumberFormatBase } from "react-number-format"

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function NewItemModal({ openModal, closeModal, actionPlanId, updateData, itemToEdit = null, optionsResponsibles = [], alertActions}) {

  const classes = useStyles();
  const { t, i18n } = useTranslation("common");
  const [isSubmit, setIsSubmit] = useState(false);
  
  const [form, setForm] = useState({
    action_plan_id: actionPlanId,
    id: null,
    what_todo: "",
    how_todo: "",
    responsible_id: "",
    priority_key: "medium",
    progress: 0
  });

  const [formErrors, setFormErrors] = useState({
    what_todo: "",
    how_todo: "",
    responsible_id: ""
  });

  const resetForm = () => {
    setForm({
      action_plan_id: actionPlanId,
      id: null,
      what_todo: "",
      how_todo: "",
      responsible_id: "",
      priority_key: "medium",
      progress: 0
    });
    setFormErrors({
      what_todo: "",
      how_todo: "",
      responsible_id: ""
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  }

  const handleSubmit = (e, typeButton) => {
    e.preventDefault();

    let isValid = true;
    const errors = {...formErrors}

    if (form.what_todo === "") {
      errors.what_todo = t("validation.required");
      isValid = false;
    }

    if (form.how_todo === "") {
      errors.how_todo = t("validation.required");
      isValid = false;
    }

    if (form.responsible_id === "") {
      errors.responsible_id = t("validation.required");
      isValid = false;
    }

    if (isValid) {
      setIsSubmit(true);

      const payload = {
        ...form
      };

      if (itemToEdit) {
        api
          .put(`/performance-assessment/action-plan/item`, payload, {
            headers: authHeader(),
          })
          .then((res) => {
            updateData();
            closeModal();
            resetForm();
            setIsSubmit(false);
            alertActions.success(t("performance_assessment.messages.success_update_item"));
          })
          .catch((err) => {
            setIsSubmit(false);
            alertActions.error(t("performance_assessment.messages.error_update_item"));
          });
          return
      }
      
      api
        .post(`/performance-assessment/action-plan/item`, payload, {
          headers: authHeader(),
        })
        .then((res) => {
          updateData(false);
          if(typeButton === "save") {
            closeModal();
          }
          resetForm();
          setIsSubmit(false);
          alertActions.success(t("performance_assessment.messages.success_create_item"));
        })
        .catch((err) => {
          setIsSubmit(false);
          alertActions.error(t("performance_assessment.messages.error_create_item"));
        });

    }else{
      setFormErrors(errors);
    }
  }

  const formatPercent = (val) => {
    if (!val) return '';
    return `${val}%`;
  };

  const removePercent = (val) => {
    return val.replace('%', '');
  };
  
  const isAllowed = (values) => {
    const { floatValue } = values;
    return floatValue >= 0 && floatValue <= 100;
  };

  useEffect(() => {
    console.log("itemToEdit ", itemToEdit)
    if (itemToEdit) {
      setForm({
        ...itemToEdit
      });
    }else{
      resetForm();
    }
  }, [itemToEdit]);


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} ${styles.modal}`}
      open={openModal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: "#205B6F", opacity: 0.8 },
      }}
      sx={{ border: "none" }}
      style={{ outline: 0 }}
    >
      <Slide
        direction="down"
        in={openModal}
        style={{ outline: 0 }}
        mountOnEnter
        unmountOnExit
      >
        <div className={styles.containerModal}>

          <button
              onClick={closeModal}
              className={styles.close}
          >
            <IconClose />
          </button>


          <header>
            <h4>
              {t("performance_assessment.page_action_plan.modal_add_item.title")}
            </h4>
          </header>

          <div className={styles.formContainer}>

            <div className={styles.fieldBox}>
              <label htmlFor="what_todo">{t("performance_assessment.page_action_plan.modal_add_item.field_what_todo")}*</label>
              <input type="text" id="what_todo" name="what_todo" onChange={handleChange} value={form.what_todo} placeholder={t("performance_assessment.page_action_plan.modal_add_item.placeholder_what_todo")}/>
              {formErrors.what_todo && <p className={styles.error}>{formErrors.what_todo}</p>}
            </div>

            <div className={styles.fieldBox}>
              <label htmlFor="how_todo">{t("performance_assessment.page_action_plan.modal_add_item.field_how_todo")}*</label>
              <input type="text" id="how_todo" name="how_todo" onChange={handleChange} value={form.how_todo} placeholder={t("performance_assessment.page_action_plan.modal_add_item.placeholder_how_todo")}/>
              {formErrors.how_todo && <p className={styles.error}>{formErrors.how_todo}</p>}
            </div>
            <div className={styles.fieldBox}>
              <label htmlFor="responsible">{t("performance_assessment.page_action_plan.modal_add_item.field_responsible")}*</label>
              <select  
                className={styles.customSelect} 
                name="responsible_id" 
                id="responsible_id" 
                onChange={handleChange} 
                value={form?.responsible_id}                  
                >

                <option value="" disabled>{t("performance_assessment.page_action_plan.form.responsible_placeholder")}</option>

                {optionsResponsibles?.map((item) => (
                  <option key={`responsible_${item.value}`} value={item.value}>{item.label}</option>
                ))}

              </select>
              {formErrors.responsible_id && <p className={styles.error}>{formErrors.responsible_id}</p>}
            </div>

            <div className={styles.containerDoubleField}>
              <div className={styles.fieldBox}>
                <label htmlFor="priority">{t("performance_assessment.page_action_plan.modal_add_item.field_priority")}</label>
                <select className={styles.customSelect} name="priority_key" id="priority" onChange={handleChange} value={form.priority_key}>
                  <option value="low">{t("performance_assessment.page_action_plan.modal_add_item.option_low")}</option>
                  <option value="medium">{t("performance_assessment.page_action_plan.modal_add_item.option_medium")}</option>
                  <option value="high">{t("performance_assessment.page_action_plan.modal_add_item.option_high")}</option>
                </select>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="progress">{t("performance_assessment.page_action_plan.modal_add_item.field_progress")}</label>
                <NumberFormatBase
                  format={formatPercent} // Formata o valor com o símbolo de %
                  removeFormatting={removePercent} // Remove o símbolo de % ao editar
                  decimalScale={2} // Define até 2 casas decimais
                  allowNegative={false} // Não permite valores negativos
                  placeholder="Ex: 10%"
                  isAllowed={isAllowed}
                  onValueChange={(values) => {
                    const { value } = values;
                    console.log(value);
                    if (value > 100) {
                      setForm({
                        ...form,
                        progress: 100
                      });
                      return
                    }
                    if (value < 0) {
                      setForm({
                        ...form,
                        progress: 0
                      });
                      return
                    }
                    setForm({
                      ...form,
                      progress: value
                    });
                  }}
                  value={form.progress}
                />
              </div>
            </div>            
          
            <footer>        
              <button id="buttonSave" type="button" className={styles.buttonSave} disabled={isSubmit} onClick={(e) => handleSubmit(e, "save")}>
                {t("performance_assessment.actions.save")}
              </button>
              {
                !itemToEdit?.id &&
                <button id="buttonSaveAndNew" type="button" className={styles.buttonSaveAndNew} disabled={isSubmit} onClick={(e) => handleSubmit(e, "save_and_new")}>
                  {t("performance_assessment.actions.save_and_new")}
                </button>
              }
            </footer> 
          </div>
        </div>
      </Slide>
    </Modal>
  );
}
