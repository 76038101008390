import React, { useState } from "react";
import { connect } from "react-redux";

import { withTranslation } from "react-i18next";
import { alertActions, usersActions } from "../_actions";

import { Box, Dialog, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
} from "@mui/material";
import { Buildings, LockKey, UserCircle } from "phosphor-react";
import IconBell from "../_components/_icons/IconBell";
import IconEmail from "../_components/_icons/IconEmail";
import IconMainLogoV2 from "../_components/_icons/IconMainLogoV2";
import IconShowPassword from "../_components/_icons/IconShowPassword";
import { history } from "../_helpers";
import { api } from "../_helpers/api";
import { AES, enc } from "crypto-js";
import { useMsal } from '@azure/msal-react';
import msalConfig from './../msal-config';
import { useGoogleLogin } from '@react-oauth/google';
import { usersConstants } from "../_constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  "&:focus": {
    outline: "none",
  },
  outline: "none",
};

class LoginPage extends React.Component {
  
  constructor(props) {
    super(props);

    // reset login status
    this.props.dispatch(usersActions.logout());

    this.state = {
      email: "",
      password: "",
      organization: "",
      recoverEmail: "",
      recoverOrganization: "",
      submitted: false,
      showPassword: false,
      resetPassword: false,
      loading: false,
      popunder: false,
      news: [],
      organizations: [],
      open: false,
      openModal: false,
      remember: localStorage.getItem("remember") ? true : false,
      isMounted: true,
      msalInstance: null,
      isLoadingMS:false,
      isLoadingGoogle:false
    };

    this.secret = "t5H8MObIxIgraAzpw6Bh";

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitReset = this.handleSubmitReset.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOpenModal = this.handleClickOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.toggleResetPassword = this.toggleResetPassword.bind(this);
  }

  toggleShow() {
    this.setState({ showPassword: !this.state.showPassword });
    this.setState({ submitted: false });
  }

  toggleIsLoadingMS() {
    this.setState({ isLoadingMS: !this.state.isLoadingMS });
  }

  toggleIsLoadingGoogle() {
    this.setState({ isLoadingGoogle: !this.state.isLoadingGoogle });
  }

  setPopunder(value) {
    this.setState({ popunder: value });
  }

  toggleResetPassword() {
    this.setState({ resetPassword: !this.state.resetPassword });
    this.setState({ submitted: false });
    this.props.dispatch(alertActions.clear());
  }

  handleChange(e) {
    if (e.target.type === "checkbox") {
      this.setState({ remember: e.target.checked });
      return;
    }
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.dispatch(alertActions.clear());
    this.setState({ submitted: true });
    const { email, password, organization, remember } = this.state;

    if (isNaN(organization)) {
      return;
    }

    const { dispatch } = this.props;
    if (organization && email && password) {
      if (remember) {
        const encryptedInfo = AES.encrypt(
          JSON.stringify({
            organization,
            email,
            password,
          }),
          this.secret
        );
        localStorage.setItem("remember", encryptedInfo.toString());
      } else {
        if (localStorage.getItem("remember"))
          localStorage.removeItem("remember");
      }
      dispatch(usersActions.login(organization, email, password));
      this.setState({ loading: false });
    }
  }

  handleSubmitReset(e) {
    e.preventDefault();
    this.props.dispatch(alertActions.clear());
    this.setState({ submitted: true });
    const { recoverEmail, recoverOrganization } = this.state;

    if (isNaN(recoverOrganization)) {
      return;
    }

    const { dispatch } = this.props;
    if (recoverOrganization && recoverEmail) {
      dispatch(usersActions.passwordRecover(recoverOrganization, recoverEmail));
      this.setState({ loading: false });
      // this.toggleResetPassword()
      // this.setState({ resetPassword: !this.state.resetPassword });
      this.setState({ submitted: false });
    }
  }

  handleGoogleLogin = async (userGoogleInfo) => {
    const { dispatch } = this.props;

    try {
      
      this.toggleIsLoadingGoogle()

      const email = userGoogleInfo.email;
      const username = userGoogleInfo.name;
      
      if(!email || !username) throw new Error('Scope not authorized');
      
      dispatch(usersActions.loginPlatform(username,email));
      alertActions.success('Success!')
      this.toggleIsLoadingGoogle()
    } catch (error) {      
      this.toggleIsLoadingGoogle()
      dispatch(alertActions.error(error.response.data.message));
    }
  };
  
  handleMicrosoftLogin = async () => {
    const { dispatch } = this.props;
    try {
      
      
      if (!this.state.msalInstance)  throw new Error('Scope not authorized');
      
      this.toggleIsLoadingMS()
      
      const responseLogin = await this.state.msalInstance.instance.loginPopup({
        scopes: msalConfig.scopes,
        prompt: 'select_account',
      });

      
      if (!responseLogin.scopes.includes('User.Read') && !responseLogin.accessToken) {
        throw new Error('Scope not authorized')
      }
      
      const username = responseLogin.account.name
      const email = responseLogin.account.username
      dispatch(usersActions.loginPlatform(username,email));
      alertActions.success('Success!')

      this.toggleIsLoadingMS()
      
    } catch (error) {
      this.toggleIsLoadingMS()
      dispatch(alertActions.error(error.response.data.message));      

    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userGoogleInfo.email !== this.props.userGoogleInfo.email) {
      this.handleGoogleLogin(this.props.userGoogleInfo);
    }
  }


  componentDidMount() {
    localStorage.removeItem("formAssessment")
    localStorage.removeItem("skillsAssessment")
    localStorage.removeItem("pathToBackPage");
    localStorage.removeItem(process.env.KEY_USER_LOCAL_STORAGE);
    localStorage.removeItem("befective:currentModule");
    localStorage.setItem("befective:firstAccess", true);
    document.title = "Befective";
    const rememberItem = localStorage.getItem("remember");
    if (rememberItem) {
      const decryptedInfo = AES.decrypt(rememberItem, this.secret);
      const data = JSON.parse(enc.Utf8.stringify(decryptedInfo));
      this.setState({
        ...this.state,
        organization: data.organization,
        email: data.email,
        password: data.password,
        recoverOrganization: data.organization,
        recoverEmail: data.email,
      });
    }
    this.setState({ isMounted: true });
    this.getNews();
    this.setState({ msalInstance: this.props.msal });
  }

  componentWillUnmount() {
    this.setState({ isMounted: false }); // Define isMounted como false ao desmontar o componente
  }

  getNews = () => {
    const language = window.navigator.language;

    if (language != undefined) {
      api
        .get(`news?language=${language.split("-")[0]}`)
        .then((res) => {
          if (this.state.isMounted) {
            this.setState({ news: res.data });
          }
        })
        .catch((error) => {
          alertActions.error(error);
          console.log(error);
        });
    }
  };

  getOrganizations = async () => {
    try {
      const result = await api.get(`/auth/bef-companies`);
      this.setState({ organizations: result?.data });
    } catch (error) {
      alertActions.error(error);
      console.log(error);
    }
  };

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleClickOpenModal() {
    this.setState({ openModal: true });
  }

  handleCloseModal() {
    this.setState({ openModal: false });
  }

  render() {
    const { loggingIn, sended, userDetails } = this.props;

    const {
      organization,
      email,
      password,
      submitted,
      recoverOrganization,
      recoverEmail,
      popunder,
      news,
      open,
      handleClose,
      openModal,
      organizations,
      remember,
    } = this.state;

    const { t } = this.props;
    const { alert } = this.props;
    const userLang = navigator.language || navigator.userLanguage;

    function getLang(userLang) {
      if (userLang.includes("pt")) {
        return "Portugues";
      } else if (userLang.includes("es")) {
        return "Espanhol";
      } else {
        return "Ingles";
      }
    }

    if (userDetails) {
      history.push("/");
    }

    return (
      // height: '100vh'
      <Box sx={{ width: "100%", height: "100vh" }}>
        <div className="login-bef-grid">
          <div className="left-side">
            {news.length > 0 && (
              <div className={"loginBox news"}>
                <div className="news-label">
                  <IconBell fill="#FDA02B" />
                  <h3>{t("login.news")}</h3>
                </div>

                {news.length <= 2
                  ? news?.map((item) => (
                      <div key={item.id} className="news-content">
                        <h1>{item.post_title}</h1>
                        <p>{item.post_content}</p>
                        <span>{new Date(item.post_date).toLocaleString()}</span>
                      </div>
                    ))
                  : [news[0], news[1]].map((item) => (
                      <div key={item.id} className="news-content">
                        <h1>{item.post_title}</h1>
                        <p>{item.post_content}</p>
                        <span>{new Date(item.post_date).toLocaleString()}</span>
                      </div>
                    ))}
                <div className={"detalhes"} onClick={this.handleClickOpen}>
                  {t('login.details_last_updated')}
                </div>
              </div>
            )}
          </div>

          <div className="right-side">
            <div className="loginMain">
              <div className={"loginBox login"}>
                <div className="loginContainer">
                  <div className="mainLogo">
                    <IconMainLogoV2 />
                  </div>

                  <div
                    className={
                      this.state.resetPassword ? "loginForm hide" : "loginForm"
                    }
                  >
                    {/* <h2>{t("login.access_your_account")}</h2> */}

                    {alert.message && (
                      <div className="alert">{alert.message}</div>
                    )}

                    <form name="form" onSubmit={this.handleSubmit}>
                      <div
                        className={
                          "formGroup" +
                          (submitted && (!organization || isNaN(organization) || alert.message)
                            ? " has-error"
                            : "")
                        }
                      >
                        <div className="icon">
                          <Buildings size={32} color="#FDA02B" />
                        </div>
                        <input
                          id="organization"
                          name="organization"
                          placeholder={t("login.organization_code")}
                          value={organization}
                          onChange={this.handleChange}
                          type="text"
                        />
                        {submitted && !organization && (
                          <div className="error">
                            {t("login.org_is_required")}
                          </div>
                        )}
                        {(submitted && organization && isNaN(organization))&& (
                          <div className="error">
                            {t("login.number_is_required")}
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          "formGroup" +
                          (submitted && (!email || alert.message)
                            ? " has-error"
                            : "")
                        }
                      >
                        <div className="icon">
                          {/* <IconEmail/> */}
                          <UserCircle size={32} color="#FDA02B" />
                        </div>
                        <input
                          id="email"
                          name="email"
                          placeholder={t("login.email")}
                          value={email}
                          onChange={this.handleChange}
                          type="email"
                        />
                        {submitted && !email && (
                          <div className="error">
                            {t("login.email_is_required")}
                          </div>
                        )}
                      </div>

                      <div
                        className={
                          "formGroup" +
                          (submitted && (!password || alert.message)
                            ? " has-error"
                            : "")
                        }
                        style={{ marginBottom: 0 }}
                      >
                        <div className="icon">
                          <LockKey size={32} color="#FDA02B" />
                          {/* <IconPassword/> */}
                        </div>
                        <button
                          type="button"
                          className="showPassword"
                          onClick={this.toggleShow}
                          tabIndex="-1"
                        >
                          <IconShowPassword />
                        </button>
                        <input
                          id="password"
                          type={this.state.showPassword ? "text" : "password"}
                          name="password"
                          placeholder={t("login.password")}
                          value={password}
                          onChange={this.handleChange}
                        />
                        {submitted && !password && (
                          <div className="error">
                            {t("login.password_is_required")}
                          </div>
                        )}
                      </div>

                      <div
                        className={"formGroup"}
                        style={{
                          justifyContent: "flex-end",
                          marginBottom: "10px",
                          height: "32px",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="remember"
                              checked={remember}
                              onChange={this.handleChange}
                            />
                          }
                          label={
                            <span style={{ fontSize: "12px", color: '#205B6F' }}>
                              {t("login.login_remember")}
                            </span>
                          }
                          labelPlacement="start"
                        />
                      </div>

                      <div className="submitContainer">
                        <button
                          disabled={this.state.loading ? "disabled" : ""}
                        >
                          {t("login.login")}
                        </button>
                      </div>
                    </form>
                    {
                      !this.state.resetPassword &&
                      <>
                        <button
                          type="button"
                          onClick={this.handleMicrosoftLogin}
                          className="buttonLoginMS"
                        >
                          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="10" height="10" fill="#F25022"/>
                            <rect x="13" y="1" width="10" height="10" fill="#7FBA00"/>
                            <rect x="1" y="13" width="10" height="10" fill="#00A4EF"/>
                            <rect x="13" y="13" width="10" height="10" fill="#FFB900"/>
                          </svg>
                          {t('login.login_with_microsoft')}
                        </button>
                         <button
                          type="button"
                          onClick={this.props.handleGoogleLogin}
                          className="buttonLoginMS"
                        >                          
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32"
                            width="20"
                            height="20"
                          >
                            <defs>
                              <path
                                id="A"
                                d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                              />
                            </defs>
                            <clipPath id="B">
                              <use href="#A" />
                            </clipPath>
                            <g transform="matrix(.727273 0 0 .727273 -.954545 -1.45455)">
                              <path d="M0 37V11l17 13z" clipPath="url(#B)" fill="#fbbc05" />
                              <path d="M0 11l17 13 7-6.1L48 14V0H0z" clipPath="url(#B)" fill="#ea4335" />
                              <path d="M0 37l30-23 7.9 1L48 0v48H0z" clipPath="url(#B)" fill="#34a853" />
                              <path d="M48 48L17 24l-4-3 35-10z" clipPath="url(#B)" fill="#4285f4" />
                            </g>
                          </svg>
                          {t('login.login_with_google')}
                        </button>
                      </>
                    }
                    <div className="goResetPassword">
                      <span onClick={this.toggleResetPassword}>
                        {t("login.forgot_your_password")}
                      </span>
                    </div>
                  </div>

                  <div
                    className={
                      !this.state.resetPassword
                        ? "resetPassword hide"
                        : "resetPassword"
                    }
                  >
                    <h2>{t("login.forgot_your_password")}</h2>
                    <p>{t("login.tip")}</p>
                    {alert.message && (
                      <div className="alert">{alert.message}</div>
                    )}
                    <form name="form" onSubmit={this.handleSubmitReset}>
                      <div
                        className={
                          "formGroup" +
                          (submitted && (!recoverOrganization || isNaN(recoverOrganization))
                            ? " has-error"
                            : "")
                        }
                      >
                        <div className="icon">
                          <Buildings size={32} color="#97B3BF" />
                        </div>
                        <input
                          id="recoverOrganization"
                          name="recoverOrganization"
                          placeholder={t("login.organization_code")}
                          value={recoverOrganization}
                          onChange={this.handleChange}
                          type="text"
                        />
                        {submitted && !recoverOrganization && (
                          <div className="error">
                            {t("login.org_is_required")}
                          </div>
                        )}
                        
                        {(submitted && recoverOrganization && isNaN(recoverOrganization))&& (
                          <div className="error">
                            {t("login.number_is_required")}
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          "formGroup" +
                          (submitted && !recoverEmail ? " has-error" : "")
                        }
                      >
                        <div className="icon">
                          <IconEmail />
                        </div>
                        <input
                          id="recoverEmail"
                          disabled={"" + (this.state.loading ? "disabled" : "")}
                          name="recoverEmail"
                          placeholder={t("login.email")}
                          value={recoverEmail}
                          onChange={this.handleChange}
                        />
                        {submitted && !recoverEmail && (
                          <div className="error">
                            {t("login.email_is_required")}
                          </div>
                        )}
                      </div>

                      <div className="submitContainer">
                        <button
                          disabled={"" + (this.state.loading ? "disabled" : "")}
                        >
                          {t("login.reset_password")}
                        </button>
                      </div>
                    </form>

                    <span
                      className="backToLogin"
                      onClick={this.toggleResetPassword}
                    >
                      {t("login.back_login")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright" onClick={this.handleClickOpenModal}>
              <div>
                Befective ® {t("login.privacy_policy")}
                {/*t('login.terms')*/}
              </div>
            </div>

            <Modal
              open={openModal}
              onClose={this.handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div style={style}>
                <div className="modalContent">
                  <iframe
                    src={
                      "https://www.befective.com/wp-content/uploads/2023/01/Politica-de-Privacidade-" +
                      getLang(userLang) +
                      ".pdf"
                    }
                    style={{
                      width: "800px",
                      height: "500px",
                      borderRadius: "8px",
                      border: "none",
                    }}
                    loading={"lazy"}
                  ></iframe>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        {news.length > 0 && (
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <div className="dialog-container-details">
              <IconButton
                aria-label="close"
                onClick={this.handleClose}
                style={{
                  position: "absolute",
                  top: "-5px",
                  right: "0",
                  color: "#4F7091",
                }}
              >
                <Close fontSize="medium" />
              </IconButton>
              <main main className="m-news-content">
                {news.map((item) => (
                  <div key={item.id}>
                    <h3>
                      <strong>{item.post_title}</strong>
                    </h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.post_content?.replaceAll("\n", "<br />"),
                      }}
                    ></p>
                    <span>{new Date(item.post_date).toLocaleString()}</span>
                  </div>
                ))}
              </main>
            </div>
          </Dialog>
        )}
      </Box>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, userDetails } = state.authentication;
  const { sended } = state.users;
  const { alert } = state;
  return {
    loggingIn,
    alert,
    sended,
    userDetails,
  };
}

const LoginPageWrapper = (props) => {
  const msal = useMsal();
  const [userGoogleInfo, setUserGoogleInfo] = useState({
    email: null,
    name: null
  });

  const googleLogin = useGoogleLogin({
    scope: "openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    onSuccess: async (tokenResponse) => {
      const res = await fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`,
        },
      });
  
      const userInfo = await res.json();
      setUserGoogleInfo(userInfo); // Armazena o token no estado
    },
    onError: (error) => {
      console.error("Erro no login do Google:", error);
    },
    flow: "implicit",
  });

  return <LoginPage {...props} msal={msal} userGoogleInfo={userGoogleInfo} handleGoogleLogin={googleLogin} />;
};

const connectedLoginPage = withTranslation("common")(
  connect(mapStateToProps)(LoginPageWrapper)
);
export { connectedLoginPage as LoginPage };
