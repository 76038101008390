import React, { useEffect, useState } from 'react';
import { useTable, usePagination, useSortBy, useGlobalFilter, useRowSelect } from 'react-table';
import styles from './MoodTeamsTable.module.scss';
import { ArrowDown, ArrowRight, ArrowUp, FileArrowUp, Printer, User } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { history } from '../../../_helpers';

const MoodTeamsTable = ({ columns, data, handlePrint, handleOpenModalExport }) => {

  const { t } = useTranslation('common');
  
  const tableInstance = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance;


  return (
    <div className={styles.tableContainer}>

      <header>
        <div className={styles.statsHeader}>
          <div className={styles.boxResume}>
            <div>
              <h4 className={styles.title}>{t('reports_mood.table.mood_per_team')}</h4>
            </div>
          </div>
          <div className={styles.boxButtonHeader}>
            <button type='button' onClick={handlePrint}>
              <Printer size={18} color='#98ACC1' weight="fill" />
            </button>
            <button type='button' onClick={handleOpenModalExport}>
              <FileArrowUp size={18} color='#98ACC1' weight="fill" />
            </button>
          </div>
        </div>
      </header> 

      <table {...getTableProps()}>
        <thead>
          <tr>
            {headerGroups.map((headerGroup, i) => (
              <React.Fragment key={`header-${headerGroup.id}-${i}`}>
                {headerGroup.headers.map((column) => {
                  switch (column.id) {
                    default:
                      return (
                        <th key={column.id} {...column.getHeaderProps(column.getSortByToggleProps())} title={t('title_sort')} className={`${styles.headColTable}  ${['total_alert_message', 'percent_working_day'].includes(column.id) ? styles.colShrink : ''}`} style={{ textAlign: `${!['name', 'series', 'total_alert_message'].includes(column.id) ? 'center' : 'left'}` }}>
                          {column.render('Header')}
                          {column.isSorted ? (column.isSortedDesc ? <ArrowDown size={10} color="#1E6B7F" weight="bold" /> : <ArrowUp size={10} color="#1E6B7F" weight="bold" />) : ''}
                        </th>
                    )
                  }
                })}
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {
          rows.length > 0 
          ?
          rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={`row-${i}-${row.id}`} onClick={() => history.push(`/productive/reports/mood/details/${row.original.group_id}`)} className={styles.rowHover}>
                {row.cells.map((cell, i) => {
                  switch (cell.column.id) {
                    default:
                      return (
                        <td className={['total_alert_message', 'percent_working_day'].includes(cell.column.id) ? styles.cellShrink : ''} key={`cell-${i}-${cell.column.id}`} {...cell.getCellProps()} style={{ textAlign: `${!['name', 'series', 'total_alert_message'].includes(cell.column.id) ? 'center' : 'left'}`, fontSize: '12px' }} >{cell.render("Cell")}</td>
                      );
                  }
                })}
              </tr>
            );
          })
          :
          <tr>
            <td colSpan={columns.length}>{t('no_data')}</td>
          </tr>          
          }
        </tbody>
      </table>
    </div>
  );
};

export default MoodTeamsTable;
