import { Backdrop, Modal, Slide, makeStyles } from "@material-ui/core";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { authHeader } from "../../../_helpers";
import { api } from "../../../_helpers/api";
import styles from "./styles.module.scss";
import { Close } from "@material-ui/icons";
import OpenAI from "openai";
import { Remarkable } from "remarkable";
import IconBefectiveAIFull from "../../_icons/IconBefectiveAIFull";
import IconSearch from "../../_icons/IconSearch";
import IconTelegramSend from "../../_icons/IconTelegramSend";
import IconBefectiveAIChatResponse from "../../_icons/IconBefectiveAIChatResponse";
import moment from "moment";
import Avatar from "react-avatar";
import ScrollableFeed from "react-scrollable-feed";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export function ChatBefeciveAiModal({
  openModal,
  toggleModal,
  userDetails,
  filters
}) {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const [fetching, setFetching] = useState(true);
  const [historyData, setHistoryData] = useState([]);
  const [question, setQuestion] = useState("");
  const [search, setSearch] = useState("");
  const [errors, setErrors] = useState({ question: false , search: false});
  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const refMain = useRef(null);

  const getChatHistory = async (searchTerm=null) => {
    try {
      setFetching(true);
      const params = searchTerm ? `?search=${searchTerm}` : '';
      const response = await api.get(`/befectiveai/chat/history${params}`, {
        headers: authHeader(),
      });
      setHistoryData(response.data);
      setFetching(false);
    } catch (error) {
      console.log(error);
      setFetching(false);
    }
  }

  const sendQuestion = async () => {

    try {

      if(!question) {
        return
      }

      setLoadingQuestion(true);

      const payload = {
        question: question,
        start_date: filters.date ? filters.date.startDate : moment().startOf('month').format('YYYY-MM-DD'),
        end_date: filters.date ? filters.date.endDate : moment().format('YYYY-MM-DD'),
      }
      
      setQuestion("");

      const historyDataAux = [...historyData, {user_message: question, ai_message: null}];
      
      setHistoryData(historyDataAux);

      const { data } = await api.post("/befectiveai/chat", payload, {
        headers: authHeader(),
      });

      historyDataAux[historyDataAux.length - 1].ai_message = data;

      setHistoryData(historyDataAux);      

      setLoadingQuestion(false);

    } catch (error) {
      console.log(error);
      setLoadingQuestion(false);
    }

  }

  const handleSendMessage = (e) => {
    e.preventDefault();
    sendQuestion();
  }

  const handleChangeQuestion = (e) => {
    const { value } = e.target;
    setQuestion(value);
    setErrors({...errors, question: false})
  }

  const handleChangeSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setErrors({...errors, search: false})
  }

  const scrollToBottom = () => {
    if (refMain.current) {
      refMain.current.scrollTop = refMain.current.scrollHeight;
    }
  };

  const getRawMarkup = (content) => {
    var md = new Remarkable();
    return { __html: md.render(content) };
  };

  useEffect(() => {
    scrollToBottom();
  }, [historyData]);


  useEffect(() => {
    if (openModal) {
      getChatHistory(null);
    }
  }, [openModal])

  useEffect(() => {

    if(search.length === 0 && openModal === true) {
      getChatHistory();
    }

    if (search.length < 3) {
      return;
    }

    const timeoutId = setTimeout(() => getChatHistory(search), 500);
    return () => clearTimeout(timeoutId);

  }, [search]);
  

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={toggleModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: "#205B6F", opacity: 0.8 },
      }}
      sx={{ border: "none" }}
      style={{ outline: 0 }}
    >
      <Slide
        direction="down"
        in={openModal}
        style={{ outline: 0 }}
        mountOnEnter
        unmountOnExit
      >
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          zIndex={2}
          borderRadius="8px"
          width={'60%'}
          component="div"
          position={"relative"}
        >
          <header className={styles.header}>
            <div className={styles.titleBox}>
              <IconBefectiveAIFull />
              <h6>{t("chat_befective_ai.title")}</h6>
            </div>
            <div className={styles.searchTextBox}>
              <input 
                className={styles.inputSearchText} 
                type="text" 
                placeholder={ t("chat_befective_ai.input_search_placeholder")} 
                onChange={handleChangeSearch} 
                value={search}
                />
              <button className={styles.buttonSearch}>
                <IconSearch />
              </button>
            </div>
          </header>
          <div className={styles.main}>
            <ScrollableFeed>
              {
                !fetching
                ?
                (
                  historyData.length > 0
                  ?
                  historyData.map((item, index) => (
                    <React.Fragment key={`message-${index}`}>
                      <div className={`${styles.messageBoxUser}`}>
                        <div className={styles.contentMessage}>
                          {item.user_message}
                        </div>
                        <div className={styles.avatarBox}>
                          <Avatar
                            name={userDetails?.name}
                            size={22}
                            round
                            color={'#24677E'}
                            style={{
                              fontSize: '12px'
                            }}
                          />
                        </div>
                      </div>
                      <div className={`${styles.messageBoxAI}`}>
                        <div className={styles.titleMessage}>
                          <IconBefectiveAIChatResponse />
                          {t("chat_befective_ai.title")}
                        </div>
                        <div className={styles.contentMessage}>
                          {
                            item.ai_message !== null 
                            ?
                            <div dangerouslySetInnerHTML={getRawMarkup(item.ai_message)}/>
                            :
                            <CircularProgress size={20}/> 
                          }
                        </div>
                      </div>                  
                    </React.Fragment>
                  ))
                  :
                  <div className={`${styles.messageBox} ${styles.default}`}>
                    <div className={styles.titleMessage}>
                      <IconBefectiveAIChatResponse />
                      {t("chat_befective_ai.title")}
                    </div>
                    <div className={styles.contentMessage}>
                      {t("chat_befective_ai.msg_default_1")}<br/>
                      {t("chat_befective_ai.msg_default_2")}
                    </div>
                  </div>
                )
                :
                <div className={styles.loadingBox}>
                  <CircularProgress size={40}/>
                </div>
              }         
              <div ref={refMain}></div>
            </ScrollableFeed>  
          </div>
          <footer className={styles.footer}>
            <input 
              className={styles.inputMessage} 
              type="text" 
              placeholder={t("chat_befective_ai.input_message_placeholder")} 
              onChange={handleChangeQuestion} 
              value={question}
              onKeyDown={(e) => e.key === 'Enter' && handleSendMessage(e)}
              />
            <button className={styles.buttonSendMessage} onClick={handleSendMessage}>
              <IconTelegramSend />
            </button>
          </footer>
        </Box>
      </Slide>
    </Modal>
  );
}
