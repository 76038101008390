import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import HeaderForm from "../personal/HeaderForm";
import { Box, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomReactSelect from "../../_shared/CustomReactSelect";
import { AttachFile, Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { authHeader, prepareOptionsData } from "../../../_helpers";
import { api } from "../../../_helpers/api";
import { TagsInput } from "react-tag-input-component";

export default function FormDocumentsCreatePT({
  formData,
  getFormData,
  toogleShowForm,
  isEditing,
  userId,
  alertActions
}) {
  const { t, i18n } = useTranslation("common");
  const [isSubmitting, setIsSubmitting] = useState(false);
  let formSchema = yup.object({
    // emergency_contact: yup.string().required(t("spaces.required_field"))
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      documents: formData?.documents,
    },
    resolver: yupResolver(formSchema),
  });

  const {
    documents
  } = watch();

  const onChangeInputDocuments = (e) => {
    const filesInput = e.target.files;
    const filesArr = Array.prototype.slice.call(filesInput);
    setValue("documents", [...documents, ...filesArr]);
  };

  const removeDocument = async (attachment) => {
    setValue("documents", documents.filter(f => f !== attachment));
  }

  
  const onSubmit = async (data) => {

    try {

      setIsSubmitting(true)

      const dataForm = new FormData();

      dataForm.append('machine_users_id', userId)

      data.documents.filter(d => !d.id).forEach(doc => {

        const acceptFiles = ['PNG', 'JPG', 'JPEG', 'GIF', 'WEBP', 'PDF', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PPT', 'PPTX']

        if ((doc.size / 1000000) > 2) {
          setError('documents', { message: t('calls_page.error.file') })
          return
        }

        const fileNameSplitted = doc.name.split('.')
        const fileExtension = fileNameSplitted[fileNameSplitted.length - 1]

        if (!acceptFiles.includes(fileExtension.toUpperCase())) {
          setError('documents', { message: t('calls_page.error.file_type') })
          return
        }
      })

      dataForm.append('documents', JSON.stringify(data.documents.filter(d => d.id)))

      for(let item of data.documents) {
        dataForm.append('files', item)
      }

      const resCreate = await api.post('/users/user-documents', dataForm,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': authHeader().Authorization
          },
        })

      if (resCreate.status !== 200) {
        throw new Error("Error create others information")
      }
      
      await getFormData(false)
      setIsSubmitting(false)
      toogleShowForm()
      alertActions.success(t('settings.users.success.info_save'))
      
    } catch (error) {
      setIsSubmitting(false)
      console.error(error)
      alertActions.error(error.response)
    }
  }

  console.log(getValues())

  return (
    <div className={styles.cardContainer}>
      <HeaderForm
        formData={formData}
        toogleShowForm={toogleShowForm}
        isEditing={isEditing}
        titleCreate={t("settings.users.documents_form.title_create")}
        isSubmitting={isSubmitting}
        submitForm={handleSubmit(onSubmit)}
        userId={userId}
        showExportData={false}
      />
      <Box
        display="flex"
        flexDirection="column"
        component="form"
      >
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          paddingX="14px"
          borderRadius="4px"
          marginTop="0px"
          marginBottom="4px"
        >
          <Box paddingX="12px" paddingY="20px">
            <Typography
              component="h6"
              sx={{ fontSize: "16px", color: "#1E6B7F", fontWeight: 500 }}
            >
              {`${t("settings.users.documents_form.documents")} (${
                documents.length
              })`}
            </Typography>
          </Box>

          <Box paddingX="12px" paddingBottom={'24px'}>
            <div
              className={styles.boxInputFile}
              style={{
                fontFamily: "sans-serif",
                textAlign: "left",
                display: "flex",
              }}
            >
              <label className={styles.customFileUpload}>
                <input
                  type="file"
                  multiple
                  onChange={onChangeInputDocuments}
                  accept=".pdf, .xls, .xlsx, .doc, .docx, image/*"
                />
                <AttachFile fontSize="small" style={{color: "#1E6B7F", fill: "#1E6B7F"}} />
                <div>{t("settings.users.actions.add_docs")}</div>
              </label>
              <p className={styles.attachmentInfo}>
                {t('settings.users.documents_form.attach_info')}*
              </p>

              {
                documents.length > 0 && documents.map((attachment, index) => (
                  <div key={index} className={styles.filePreview}>
                    <div>{attachment.original_name || attachment.name}</div>
                    <div>
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={() => { removeDocument(attachment) }}
                      >
                        <Close fontSize='small' />
                      </IconButton>
                    </div>
                  </div>
                ))
              }
            </div>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
