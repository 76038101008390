import React, { useEffect, useState } from 'react'
import style from './ChartBarDay.module.scss';
import ReactApexChart from 'react-apexcharts';
import { getMonths } from '../../../_helpers';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function ChartBarDay({
  teamName=null,
  month,
  data
}) {
  const [chartKey, setChartKey] = useState(0);
  const { t } = useTranslation('common');

  useEffect(() => {
    setTimeout(() => setChartKey((prev) => prev + 1), 100);
  }, []);

  return (
    <div className={style.cardContainer}>
      <header className={style.header}>
        <h3>{teamName && `${teamName} - `}{`${getMonths(moment(`${month}-01`).month(), t)} ${t('report_value_hour.of')} ${moment(`${month}-01`).year()}`}</h3>
      </header>
      <div className={style.chart} style={{ width: "100%", maxWidth: "100%"}}>
        <ReactApexChart key={chartKey} options={data.options} series={data.series} type="bar" height={350}/>
      </div>
    </div>
  )
}
