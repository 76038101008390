import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { shareActions } from "../../_actions";
import IconClose from "../_icons/IconClose";
import Skeleton from "@material-ui/lab/Skeleton";
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CircularProgress, TextField } from "@material-ui/core";
import { api } from "../../_helpers/api";
import { authHeader } from "../../_helpers";
import moment from "moment";


function ShareModal({
    shareActions,
    filters = {},
    reports = "executive",
    showOptionsTypeExportView = false,
    paginationFilters = null,
    modelExportFullExcel=null,
    language = 'en',
    ...props
}) {
    const [loadingDoDownload, setLoadingDoDownload] = useState(false);
    const [typeExportView, setTypeExportView] = useState('default')
    const { t, i18n } = useTranslation('common');
    const enableXlsx = reports === 'executive' || reports === 'mood' || false;
    
    const [dateRef, setDateRef] = useState(new Date());
    const [loadingDateRef, setLoadingDateRef] = useState(false);
    const [downloadTime, setDownloadTime] = useState(0);

    let filter = props.filter;

    const handleRadioChange = (event) => {
        setTypeExportView(event.target.value);
    };

    function closeShareModal() {
        shareActions.closeModal()
        setTypeExportView('default')
    }

    function doDownload(format, type = 'pdf') {
        setLoadingDoDownload(true);
        shareActions.getFile({
            format,
            report: reports,
            filters: ['transactionalReports', 'realtimeColabDetails', 'teammates'].includes(reports) ? filters : filter,
            type,
            paginationFilters,
            setLoadingDoDownload,
            typeExportView
        });
    }

    const handleSubmitFormExportFullExcel = async (e) => {
        e.preventDefault();

        if(!dateRef){
            return
        }

        let interval;

        setLoadingDateRef(true)
        setDownloadTime(0);

        interval = setInterval(() => {
            setDownloadTime((prevTime) => prevTime + 1); // Atualiza o contador a cada segundo
        }, 1000);

        const date_start = moment(dateRef).startOf('month').format('YYYY-MM-DD')
        const date_end = moment(dateRef).month() === moment().month() && moment(dateRef).year() === moment().year() ? moment().format('YYYY-MM-DD') :  moment(dateRef).endOf('month').format('YYYY-MM-DD')
        api.get(`/excel/${modelExportFullExcel}?date_start=${date_start}&date_end=${date_end}`, { headers: authHeader(), responseType: 'blob' })
        .then((response) => {
            let blob = new Blob([response.data], { type: response.headers['content-type'] });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = `${modelExportFullExcel}-${moment(dateRef).format('MM-YYYY')}.xlsx`;
            a.click();
        })
        .catch((error) => {
            console.log(`Error get file excel: ${error}`);
        })
        .finally(() => {
            setLoadingDateRef(false)
            clearInterval(interval);
        })
       
    }

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}min ${secs}seg`;
    };

    return (

        <div className={"internal"}>

            <div className="header">
                <div className={"title"}>{t('share.title')}</div>                
                <button onClick={() => { closeShareModal() }} className="close"><IconClose /></button>
            </div>

            <div className={"content"}>
                <ul className={"download"}>
                    <li><b>{t('share.download_files')}</b></li>
                    {loadingDoDownload && <li><Skeleton variant="rect" width={90} height={36} /></li>}
                    {loadingDoDownload && <li><Skeleton variant="rect" width={90} height={36} /></li>}
                    {!loadingDoDownload && <li><button onClick={() => { doDownload('xlsx', 'excel') }}>XLSX</button></li>}
                    {!loadingDoDownload && <li><button onClick={() => { doDownload("pdf") }}>PDF</button></li>}
                </ul>
                {
                    showOptionsTypeExportView &&
                    <Box marginTop={'40px'}>
                        <FormControl>
                            <FormLabel id="option-type-export">{t('share.type_of_export')}</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="option-type-export"
                                name="typeExportView"
                                value={typeExportView}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="default" control={<Radio />} label={t('export.type_summarized')} />
                                <FormControlLabel value="complete" control={<Radio />} label={t('export.type_complete')} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                }

                {
                    modelExportFullExcel &&
                    <>
                    <form className="form-export-full-excel" onSubmit={handleSubmitFormExportFullExcel}>
                        <h4 className="title">{t('share.export_full_excel')}</h4>
                        <LocalizationProvider 
                            dateAdapter={AdapterDayjs} 
                            locale={language}
                        >
                            <DatePicker
                                label={t('share.month_year')}
                                value={dateRef}
                                inputFormat={"MM/YYYY"}
                                views={['month', 'year']}
                                onChange={(value) => (value && value.$d) && setDateRef(value.$d)}
                                minDate={new Date(2023, 0, 1)}
                                maxDate={new Date()}
                                renderInput={(params) => (
                                    <TextField
                                    variant="outlined"
                                    {...params}
                                    style={{
                                        width: "160px"
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {
                                            height: "12px",
                                        }
                                    }}
                                    error={!dateRef}
                                />
                                )}
                                />
                        </LocalizationProvider>
                        <button className={`button-submit ${loadingDateRef && 'noHover'}`} disabled={!dateRef || loadingDateRef} type="submit">
                            {
                                loadingDateRef && 
                                <CircularProgress
                                    size={14}
                                    color="inherit"
                                />
                                
                            }
                            {loadingDateRef ? t('share.downloading') : t('share.btn_export_excel')}
                        </button>
                    </form>
                    {
                        loadingDateRef &&
                        <div className="label-info-loading">
                            {downloadTime > 120 ? t('share.info_loading_2') : t('share.info_loading')} {`( ${formatTime(downloadTime)} )`}
                        </div>
                    }
                    </>
                }
            </div>

        </div>

    )

};

function mapStateToProps(state) {

    return {
        filter: state.filter,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        shareActions: bindActionCreators(shareActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShareModal));
