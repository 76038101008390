import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import style from './styles.module.scss';

import { useTranslation, initReactI18next } from "react-i18next";
import { Link } from 'react-router-dom';
import IconInfo from '../../_icons/IconInfo';
import IconHappy from '../../_icons/IconHappy';
import IconOk from '../../_icons/IconOk';
import IconSad from '../../_icons/IconSad';
import { Grid, Typography } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Widget13Skeleton from './Widget13Skeleton';
import { api } from '../../../_helpers/api';
import { authHeader } from '../../../_helpers';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';

export default function Widget13({ width, currentDate, alertActions, filters }) {
  const { t, i18n } = useTranslation('common');

  const [data, setData] = useState({
    data: []
  });

  const [fetching, setFetching] = useState(true);

  const getData = async ({ filterStartDate, filterEndDate, filterTeams, filterTeammates }) => {
    try {
      setFetching(true)
      const response = await api.get(`/stats/executive/moods?year_month=${currentDate.format('YYYY-MM')}&current_day=${currentDate.format('YYYY-MM-DD')}${filterStartDate}${filterEndDate}${filterTeams}${filterTeammates}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget11!!!')
      }
      setData(response.data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData(filters)
  }, [])

  return (
    !fetching
      ?
      <Grid item xs={width}>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <div className={style.boxTitle}>
              <Link className={"linkDashRedirect"} to={'/productive/reports/mood'}>
                <h6 className={style.title}>{t('home.teammates_mood')}</h6>
              </Link>
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('home.teammates_mood_tooltip')}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo /></div>
                </BefectiveTooltip>
              </div>
            </div>
            <div>
              <div className={style.containerMoods}>
                {
                  data.data.length > 0
                  ?
                  data.data.map((mood) => {

                    const splitUrl = String(mood.mood_url_image).split('/')
                    const altName = splitUrl[splitUrl.length - 1]

                    return (
                      <div key={`mood-${mood.mood}`} className={style.boxMood}>
                        <BefectiveTooltip title={<Typography component={'div'} variant='caption' fontSize={'14px'} lineHeight={'14px'} marginY={'2px'}>{mood.mood_json[`title_${i18n.language}`]}</Typography>} placement="top-center" arrow interactive>
                          <div className={style.boxIcon}>
                            <img src={mood.mood_url_image} alt={altName} width={50} loading='lazy' />
                          </div>
                        </BefectiveTooltip>
                        <div className={style.percentMood}>{mood.percent}%</div>
                      </div>
                    )
                  })
                  :
                  <div className={style.boxNoneMoods}>{t('home.none_moods')}</div>
                }
              </div>
              <div className={style.totalInfo}>{data.stats.total_users_active || 0} / {data.stats.total_users || 0} {t('home.awsered')}</div>
            </div>
          </div>
        </div>
      </Grid>
      :
      <Grid item xs={width}>
        <Widget13Skeleton />
      </Grid>
  )
};
