import React, { useEffect, useState } from 'react'
import style from './styles.module.scss';
import { authHeader, getAvatarUrl, secondsToHm } from '../../../../_helpers';
import IconInfo from '../../../_icons/IconInfo';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Tooltip } from '@mui/material';
import Widget34Skeleton from './Widget34Skeleton';
import ReactApexChart from 'react-apexcharts';
import BefectiveTooltip from '../../../_shared/BefectiveTooltip';
import { api } from '../../../../_helpers/api';
import { Avatar } from '@material-ui/core';

export default function Widget34({
  alertActions,
  currentDate,
  width,
  filters
}) {
  const { t, i18n } = useTranslation('common');
  const [fetching, setFetching] = useState(true);
  const [filterSelected, setFilterSelected] = useState("best");
  
  const [data, setData] = useState([])


  const getData = async ({filterTeams, filterTeammates, filterAssessment}) => {
    try {
      setFetching(true)      
      const filterOrder = `&order=${filterSelected}`
      const response = await api.get(`/performance-assessment/widgets/users?current_day=${currentDate.format('YYYY-MM-DD')}${filterOrder}${filterTeams}${filterTeammates}${filterAssessment}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget34!!!')
      }
      const { data } = response
      setData(data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData({...filters})
  }, [filterSelected])

  return (
    !fetching
      ?

      <Grid item xs={width}>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <div className={style.boxTitle}>
                <h6 className={style.title}>{t('widgets.widget34.title')}</h6>
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('widgets.widget34.tooltip')}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo/></div>
                </BefectiveTooltip>
              </div>
              <select className={style.select} name="filterSelect" id="filterSelect" value={filterSelected} onChange={(e) => setFilterSelected(e.target.value)}>
                <option value="best">{t('widgets.options.five_best')}</option>
                <option value="worst">{t('widgets.options.five_worst')}</option>
              </select>
            </div>
            <div className={style.tableContainer}>
              <table className={style.tableColabs}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t('widgets.widget34.cols.colab')}</th>
                    <th>{t('widgets.widget34.cols.office')}</th>
                    <th className={`${style.cellCenter}`}>{t('widgets.widget34.cols.score_avg')}</th>
                    <th className={`${style.cellShrink} ${style.cellCenter}`}>{t('widgets.widget34.cols.classification')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data && data.length > 0
                    ?
                    data.map((item, index) => (
                      <tr key={`colaborator-${item.machine_users_id}`}>
                        <td>{index + 1}</td>
                        <td title={item.username}>
                          <div className={`${style.boxCellUsername}`}>
                            <Avatar
                                alt={item.usernam}
                                src={getAvatarUrl({ name: item.username })}
                                style={{ width: 20, height: 20 }}
                              />
                            <span className={style.truncateCell}>{item.username}</span>
                          </div>
                        </td>
                        <td className={style.truncateCell} title={item.office}>{item.office || '-----'}</td>
                        <td className={`${style.cellCenter}`} style={{color: item.classification.bg_color, fontWeight:'500'}}>{item.avg}</td>
                        <td className={`${style.cellClassification} ${style.cellShrink} ${style.cellCenter}`} style={{background: item.classification.bg_color}}>
                          {item.classification.label}
                        </td>
                      </tr>
                    ))
                    :
                    <tr>
                      <td colSpan={5} className={style.noData}>
                        <span>{t('widgets.widget34.no_data')}</span>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>            
          </div>
        </div>
      </Grid>
      :
      <Grid item xs={width}>
        <Widget34Skeleton />
      </Grid>

  )
}
