import React from 'react';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import style from './CardsHeader.module.scss';
import { CheckCircle, ListDashes, Percent, ShieldWarning } from 'phosphor-react';
import IconInfo from '../../_icons/IconInfo';

export function CardsHeaderAgentsInstalledTeams({ alertActions, userDetails, stats }) {
  const { t, i18n } = useTranslation('common');
  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >
      <Box width={'100%'}>
        <h3 className='reportTitleGeneric'>
          {t('reports_agents_installed_teams.title')}<span><IconInfo /><div className="tooltip">{t('reports_agents_installed_teams.tooltip')}</div></span>
        </h3>
      </Box>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #22BEAD' }}>
          <CheckCircle size={18} color={'#22BEAD'} weight="bold" />
          <Typography color={'#22BEAD'} component="span" fontSize={20} my={1}>
            {`${stats?.total_installed || "0"}`}
          </Typography>
          <Typography color={'#22BEAD'} component="span" fontSize={12}>
            {t('reports_agents_installed_teams.header_cards.total_installed')}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #FC6662' }}>
          <ShieldWarning size={18} color="#FC6662" weight="bold" />
          <Typography color="#FC6662" component="span" fontSize={20} my={1}>
            {`${stats?.total_not_installed || "0"}`}
          </Typography>
          <Typography color="#FC6662" component="span" fontSize={12}>
            {t('reports_agents_installed_teams.header_cards.total_pending')}
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #4F7091' }}>
          <ListDashes size={18} color="#4F7091" weight="bold" />
          <Typography color="#4F7091" component="span" fontSize={20} my={1}>
            {`${stats?.total_users || "0"}`}
          </Typography>
          <Typography color="#4F7091" component="span" fontSize={12}>
            {t('reports_agents_installed_teams.header_cards.total_general')}
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #187DFF' }}>
          <Percent size={18} color="#187DFF" weight="bold" />
          <Typography color="#187DFF" component="span" fontSize={20} my={1}>
            {`${stats?.percent_installed || "0"}%`}
          </Typography>
          <Typography color="#187DFF" component="span" fontSize={12}>
            {t('reports_agents_installed_teams.header_cards.percent_general_installed')}
          </Typography>
        </CardContent>
      </Card>

    </Stack>
  );
}
