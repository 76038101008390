import React, { useRef, useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import "react-datepicker/dist/react-datepicker.css";

import { filterActions } from '../../../_actions';
import { statsActions } from '../../../_actions';
import { teammatesActions } from '../../../_actions';

import { useTranslation, initReactI18next } from "react-i18next";
import useOutsideClick from "../../../_helpers/useOutsideClick";

import IconCheck from '../../_icons/IconCheck'

function AssessmentsFilterDashboard({
    assessments = [],
    getData = async () => {},
    ...props
}) {
	const { t } = useTranslation('common');
	const { filterActions } = props;

	let filter = props.filter || {};

	const searchInput = useRef(null);
	const [assessmentValue, setAssessmentValue] = useState(assessments.length > 0 ? assessments[0].label : '');
    const [assessmentSelected, setAssessmentSelected] = useState(assessments.length > 0 ? assessments[0] : null);
	const refFilter = useRef();
	const [filterAssessment, setFilterAssessment] = useState(false);
    const [assessmentsList, setAssessmentsList] = useState(assessments);

	const changeFilterAssessment = (event) => {

        const { value } = event.target;
        
		setAssessmentValue(value);

		if (value.length > 2) {
            const assessmentFiltered = assessments.filter((assessment) => (assessment.label.toLowerCase().includes(value.toLowerCase())));
            setAssessmentsList(assessmentFiltered);
		} else {
            setAssessmentsList(assessments);
		}
	};


	useOutsideClick(refFilter, target => {

		if (filterAssessment) {

			if (refFilter && !target.includes("react-datepicker") && !target.includes("column") && !target.includes("who") && !target.includes("input")) {
				if (filterAssessment) {
					setFilterAssessment(false);
				}
			}

		}
	});


    const selectAssessment = (assessmentId) => {
        const elems = document.querySelectorAll(".assesmentList");

        [].forEach.call(elems, function (el) {
            el.classList.remove("active");
        });

        document.getElementById(assessmentId).classList.add('active');
    }


	useEffect(() => {

		searchInput.current.focus();

	}, [filterAssessment])

    
    const onSelectAssessment = async (assessment) => {
        setFilterAssessment(false);
        setAssessmentSelected(assessment);
        setAssessmentValue(assessment.label);
        selectAssessment(`assessment-${assessment.value}`);
        await getData(assessment.value);
    }

	return (

		<div className={filterAssessment ? 'filter who open' : 'who filter'} ref={refFilter}>
			<div className="holder">
				<span className="label">{t('filter.assessment')}:</span>
				<span className="value"title={assessmentValue} onClick={() => {
					setFilterAssessment(!filterAssessment);
				}}>
					{
                        String(assessmentValue).length > 25
                        ?
                            String(assessmentValue).substring(0, 25) + '...'
                        :
                        assessmentValue
                    }
					<i className="arrow"></i></span>
				<input 
                    className="input" 
                    ref={searchInput} 
                    placeholder={t('filter.search')} 
                    value={assessmentValue}
					onChange={changeFilterAssessment} 
                    />
			</div>
			<div className={"comp single"}>
				<div className="column">
					<div className="title">{t('filter.assessment')}</div>
					<ul>
                        {
                           assessmentsList && assessmentsList.length > 0 
                           ?
                            assessmentsList.map((assessment) => (
                                    <li 
                                        id={`assessment-${assessment.value}`}
                                        className={`assesmentList ${assessmentSelected && assessmentSelected.value == assessment.value ? 'active' : ''}`}
                                        key={assessment.value} 
                                        onClick={() => assessmentSelected.value != assessment.value && onSelectAssessment(assessment)}>
                                        <div className="check"><IconCheck /></div>
                                        {assessment.label}
                                    </li>
                                ))
                            :
                            <span className='noDataFilter'>{t('filter.no_assessments')}</span>
                        }
					</ul>
				</div>

			</div>
		</div>

	)
};


function mapDispatchToProps(dispatch) {

	return {
		statsActions: bindActionCreators(statsActions, dispatch),
		filterActions: bindActionCreators(filterActions, dispatch),
		teammatesActions: bindActionCreators(teammatesActions, dispatch)
	}
}


function mapStateToProps(state) {

	return {
		teammates: state.teammates.payload,
		filter: state.filter,
		userDetails: state.authentication.userDetails
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentsFilterDashboard)
