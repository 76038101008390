import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Link, NavLink, useLocation, withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { menuActions, usersActions } from '../../_actions';
import IconActivities from '../_icons/IconActivities';
import IconExecutive from '../_icons/IconExecutive';
import IconGoals from '../_icons/IconGoals';
import IconHistorical from '../_icons/IconHistorical';
import IconLogo from '../_icons/IconLogo';
import IconSettings from '../_icons/IconSettings';
import IconToday from '../_icons/IconToday';
import ReactGA from 'react-ga4';
import {
	isMobile
} from "react-device-detect";
import { ArchiveTray, Article, Briefcase, CalendarBlank, CalendarCheck, ChartBar, ClipboardText, DeviceMobile, Download, HouseSimple, Kanban, ListChecks, DesktopTower, Timer, FolderNotchOpen, Medal, Gauge, Chats, Warning, ArrowLeft, Gear } from 'phosphor-react';
import { useCurrentModule } from '../../contexts/CurrentModuleContext';
import { useMessagesCount } from '../../contexts/MessagesCountContext';
import { api } from '../../_helpers/api';
import { authHeader, doCountAccessModule, history } from '../../_helpers';
import { redirectToModule } from '../../_helpers/redirectToModule';
import { makeStyles, Tooltip } from '@material-ui/core'
import IconLogoBefective from '../_icons/IconLogoBefective';
import IconArrow from '../_icons/IconArrow';
import PopoverConfirm from '../teams_settings/PopoverConfirmDelete';
import ModalMenuInactiveItem from './ModalMenuInactiveItem';

ReactGA.initialize("G-9FF1246SC3")

const useStyles = makeStyles((theme) => ({
	customTooltip: {
		backgroundColor: '#FDBE2B', // Cor de fundo personalizada
		marginLeft: 0
	},
}));

function Menu(props) {
	const { t, i18n } = useTranslation('common');
	
	const { currentModule, saveCurrentModule } = useCurrentModule()

	const classes = useStyles();

	const { messagesNotRead, getMessagesUnRead, solicitations, getOpenSolicitations, assessmentPending, getAssessmentsPending } = useMessagesCount()

	let path = window.location.pathname.split("/") || [];

	const { menuActions, usersActions, userDetails } = props;

	const [integrationOptionSelected, setIntegrationOptionSelected] = useState('')
	const [isActiveIntegrations, setIsActiveIntegrations,] = useState();
	const [isActiveCheckinReports, setActiveCheckinReports] = useState();
	const [isActiveCheckinControl, setActiveCheckinControl] = useState();
	const [isActiveProjectManagementReports, setActiveProjectManagementReports] = useState();
	const [isActiveInventory, setActiveInventory] = useState();
	const [isActiveReports, setActiveReports] = useState();
	const [isActiveSettings, setActiveSettings] = useState();
	const [isActivePerformanceAssessmentPreSettings, setActivePerformanceAssessmentPreSettings] = useState();
	const [isActivePerformanceAssessments, setActivePerformanceAssessments] = useState();
	const [isActiveGoals, setActiveGoals] = useState();
	const [isMenuOpen, setMenuOpen] = useState(false);
	const [isInactiveItemModal, setInactiveItemModal ] = useState(false)
	const [modelDetails, setModeDetails] = useState({})
	const [goalsOptionSelected, setGoalsOptionSelected] = useState('')
	const [homePage, setHomePage] = useState('/')

	let menu = props.menu || [];
	let permissions = props.permissions || [];
	const location = useLocation();

	const modules = userDetails?.modules || []
	const showMenu = userDetails?.showMenu || []
	const initialScreen = userDetails?.initialScreen || "executive"

	const [anchorElConfirmCancelEdit, setAnchorElConfirmCancelEdit] = useState(null);
  const openConfirmCancelEdit = Boolean(anchorElConfirmCancelEdit);
  const idConfirmCancelEdit = openConfirmCancelEdit ? 'popover-confirm-cancel-edit' : undefined;
	const [dataRedirect, setDataRedirect] = useState(null);

	// Ordem desejada
	const order = [
			{
					"id": 1,
					"name": "productivity_management",
					"title": t(`switch.modal.productivity_management.title`),
					"description": t(`switch.modal.productivity_management.description`),
					"image": `https://befective-staging.s3.us-east-1.amazonaws.com/images_modules_details/banner_productivity_management_${i18n.language}.png`,
					"features": t(`switch.modal.productivity_management.features`, {returnObjects: true})
			},
			{
				"id": 2,
				"name": "goals_management",
				"title": t(`switch.modal.goals_management.title`),
				"description": t(`switch.modal.goals_management.description`),
				"image": `https://befective-staging.s3.us-east-1.amazonaws.com/images_modules_details/banner_goals_management_${i18n.language}.png`,
				"features": t(`switch.modal.goals_management.features`, {returnObjects: true})
			},
			{
					"id": 10,
					"name": "performance_assessment",
					"title": t(`switch.modal.performance_assessment.title`),
					"description": t(`switch.modal.performance_assessment.description`),
					"image": `https://befective-staging.s3.us-east-1.amazonaws.com/images_modules_details/banner_productivity_management_${i18n.language}.png`,
					"features": t(`switch.modal.performance_assessment.features`, {returnObjects: true})
			},
			{
					"id": 3,
					"name": "check_in_remote",
					"title": t(`switch.modal.check_in_remote.title`),
					"description": t(`switch.modal.check_in_remote.description`),
					"image": "https://befective-staging.s3.us-east-1.amazonaws.com/images_modules_details/banner_check_in_remote_unique.png",
					"features": t(`switch.modal.check_in_remote.features`, {returnObjects: true})
			},
			{
					"id": 6,
					"name": "check_in_remote_mobile",
					"title": t(`switch.modal.check_in_remote_mobile.title`),
					"description": t(`switch.modal.check_in_remote_mobile.description`),
					"image": "https://befective-staging.s3.us-east-1.amazonaws.com/images_modules_details/banner_check_in_remote_unique.png",
					"features": t(`switch.modal.check_in_remote_mobile.features`, {returnObjects: true})
			},
			{
					"id": 4,
					"name": "rooms_management",
					"title": t(`switch.modal.rooms_management.title`),
					"description": t(`switch.modal.rooms_management.description`),
					"image": "https://befective-staging.s3.us-east-1.amazonaws.com/images_modules_details/banner_rooms_management_unique.png",
					"features": t(`switch.modal.rooms_management.features`, {returnObjects: true})
			},
			{
					"id": 7,
					"name": "project_management",
					"title": t(`switch.modal.project_management.title`),
					"description": t(`switch.modal.project_management.description`),
					"image": `https://befective-staging.s3.us-east-1.amazonaws.com/images_modules_details/banner_project_management_unique.png`,
					"features": t(`switch.modal.project_management.features`, {returnObjects: true})
			},
			{
					"id": 9,
					"name": "inventory",
					"title": t(`switch.modal.inventory.title`),
					"description": t(`switch.modal.inventory.description`),
					"image": `https://befective-staging.s3.us-east-1.amazonaws.com/images_modules_details/banner_inventory_${i18n.language}.png`,
					"features": t(`switch.modal.inventory.features`, {returnObjects: true})
			},
	];

	// Reordenar o array e mapear os nomes
	const orderedArrayModules = order.map((key) => {
												const item = modules.find((obj) => obj.name === key.name);
												if(!item) return {...key, 'isActive': false}
												return {
													...item,
													'isActive': true
												};
											}).filter((item) => item !== null);
  const handleClickConfirmCancelEdit = (event, data) => {
    setAnchorElConfirmCancelEdit(event.currentTarget);
		setDataRedirect(data)
  };

  const handleCloseConfirmCancelEdit = () => {
    setAnchorElConfirmCancelEdit(null)
		setDataRedirect(null)
  };

	function toggleClass(type = null) {

		if (!isMenuOpen) {

			toggleMenu('main');
			if (type === "reports") {
				menuActions.set('reports', true);
			}
			if (type === "settings") {
				menuActions.set('settings', true);
			}
			if (type === "goals") {
				menuActions.set("goals", true);
			}
			if (type === "integrations") {
				menuActions.set('integrations', true);
			}
			if (type === "checkin") {
				menuActions.set('checkin', true);
			}
			if (type === "control") {
				menuActions.set('control', true);
			}
			if (type === "project_management") {
				menuActions.set('project_management', true);
			}
			if (type === "inventory") {
				menuActions.set('inventory', true);
			}
			if (type === "performance_assessments") {
				menuActions.set('performance_assessments', true);
			}
			if (type === "pre_settings") {
				menuActions.set('pre_settings', true);
			}

		} else {

			if (type === "reports") {
				menuActions.set('reports', !isActiveReports);
			} else if (type === "settings") {
				menuActions.set('settings', !isActiveSettings);
			} else if (type === "goals") {
				menuActions.set("goals", !isActiveGoals);
			} else if (type === 'integrations') {
				menuActions.set("integrations", !isActiveIntegrations);
			} else if (type === 'checkin') {
				menuActions.set("checkin", !isActiveCheckinReports);
			} else if (type === 'control') {
				menuActions.set("control", !isActiveCheckinControl);
			} else if (type === 'project_management') {
				menuActions.set("project_management", !isActiveProjectManagementReports);
			} else if (type === 'inventory') {
				menuActions.set("inventory", !isActiveInventory);
			} else if (type === 'performance_assessments') {
				menuActions.set("performance_assessments", !isActivePerformanceAssessments);
			} else if (type === 'pre_settings') {
				menuActions.set("pre_settings", !isActivePerformanceAssessmentPreSettings);
			} else {
				menuActions.set('reports', false);
			}

		}

	};

	function toggleMenu(type) {

		if (type == 'main') {
			if (isMenuOpen) {
				props.toggle('open')
			} else {
				props.toggle('closed')
			}


		} else {
			if (isMobile) {
				props.toggle('open')
			}
		}

	};

	function verifyIfIsIntegrationPath() {
		if (path.includes('google-calendar') || path.includes('outlook-calendar')) {
			return true
		}

		return false
	}

	const activeModule = (moduleName) => {
		if (currentModule === moduleName)
			return true
		return false
	}

	const getIconModule = (moduleName, moduleIsActive = true) => {
		const icons = {
			productivity_management: <ChartBar color={activeModule('productivity_management') ? "#FDBE2B" : moduleIsActive ? "#97B3BF" : "#97b3bf94"} weight='bold' size={'30px'} />,
			goals_management: <ListChecks color={activeModule('goals_management') ? "#FDBE2B" : moduleIsActive ? "#97B3BF" : "#97b3bf94"} weight='bold' size={'30px'} />,
			check_in_remote: <Timer color={activeModule('check_in_remote') ? "#FDBE2B" : moduleIsActive ? "#97B3BF" : "#97b3bf94"} weight='bold' size={'30px'} />,
			check_in_remote_mobile: <DeviceMobile color={activeModule('check_in_remote_mobile') ? "#FDBE2B" : moduleIsActive ? "#97B3BF" : "#97b3bf94"} weight='bold' size={'30px'} />,
			rooms_management: <CalendarCheck color={activeModule('rooms_management') ? "#FDBE2B" : moduleIsActive ? "#97B3BF" : "#97b3bf94"} weight='bold' size={'30px'} />,
			project_management: <Kanban color={activeModule('project_management') ? "#FDBE2B" : moduleIsActive ? "#97B3BF" : "#97b3bf94"} weight='bold' size={'30px'} />,
			inventory: <ClipboardText color={activeModule('inventory') ? "#FDBE2B" : moduleIsActive ? "#97B3BF" : "#97b3bf94"} weight='bold' size={'30px'} />,
			performance_assessment: <Medal color={activeModule('performance_assessment') ? "#FDBE2B" : moduleIsActive ? "#97B3BF" : "#97b3bf94"} weight='bold' size={'30px'} />,
		}
		return icons[`${moduleName}`]
	}

	const updateLastModule = async (module) => {
		try {
			await api.put(`/teammates/${userDetails?.mu_id}/update-last-access`, {
				last_module_name: module.name
			}, {
				headers: authHeader()
			})
		} catch (error) {
			console.log(error)
		}
	}

	async function handleRedirectToModule(module) {
		saveCurrentModule(module.name)
		await updateLastModule(module)
		if (userDetails.roleName !== 'collaborator') {
			redirectToModule(module.name, initialScreen, userDetails?.allowed_menu)
		} else if (module.name === 'productivity_management') {
			history.push('/productive/productivity/')
		} else {
			redirectToModule(module.name, initialScreen, userDetails?.allowed_menu)
		}
	}

  const handleOpenInactiveItemModal = () => {
		setInactiveItemModal(true)
	}

  const handleCloseInactiveItemModal = () => {
		setInactiveItemModal(false)
	}

	const activatedModel = (switchId) => {
    setModeDetails(order.find(key => key.id === switchId))
	}

	useEffect(() => {
		if (menu) {
			setActiveReports(menu.reports);
			setActiveSettings(menu.settings);
			setActiveGoals(menu.goals)
			setIsActiveIntegrations(menu.integrations)
			setActiveCheckinReports(menu.checkin)
			setActiveCheckinControl(menu.control)
			setActiveProjectManagementReports(menu.project_management)
			setActiveInventory(menu.inventory)
			setActivePerformanceAssessmentPreSettings(menu.pre_settings)
			setActivePerformanceAssessments(menu.performance_assessments)
		}

	}, [props.menu]);

	useEffect(() => {

		// usersActions.refresh(userDetails);

		let currentPath = window.location.pathname.split("/")
		const lastPathPosition = currentPath[currentPath.length - 1]

		if (currentPath.includes('google-calendar') || currentPath.includes('outlook-calendar')) {
			menuActions.set('integrations', true);
			setIntegrationOptionSelected(lastPathPosition)
		}

		if (currentPath[2] == "reports") {
			menuActions.set('reports', true);
		}

		if (currentPath[1] == "settings") {
			menuActions.set('settings', true);
		}

		if (currentPath[1] == "goals") {
			menuActions.set('goals', true);
			const optionGoals = currentPath[2]
			if (optionGoals) {
				setGoalsOptionSelected(optionGoals)
			} else {
				setGoalsOptionSelected('goals')
			}
		}

		switch (currentPath[1]) {
			case 'productive':
				saveCurrentModule('productivity_management')
				break;
			case 'goals':
				saveCurrentModule('goals_management')
				break;
			case 'virtual-point':
				saveCurrentModule('check_in_remote')
				break;
			case 'spaces':
				saveCurrentModule('rooms_management')
				break;
			case 'project_management':
				saveCurrentModule('project_management')
				break;
			case 'checkin-remote':
				saveCurrentModule('check_in_remote_mobile')
				break;
			case 'performance-assessment':
				saveCurrentModule('performance_assessment')
				break;
			default:
				break;
		}


		switch (currentModule) {
			case 'productivity_management':
				setHomePage('productive')
				break;
			case 'goals_management':
				setHomePage('goals')
				break;
			case 'check_in_remote':
				setHomePage('virtual-point')
				break;
			case 'rooms_management':
				setHomePage('spaces')
				break;
			case 'project_management':
				setHomePage('project_management')
				break;
			case 'check_in_remote_mobile':
				setHomePage('checkin-remote')
				break;
			case 'performance_assessment':
				setHomePage('performance-assessment')
				break;
			default:
				break;
		}

		getOpenSolicitations()

		if(currentModule === 'performance_assessment'){
			getAssessmentsPending()
		}
		// getMessagesUnRead(userDetails?.organizationId, userDetails?.mu_id)
	}, [path[1]]);

	useEffect(() => {
		getMessagesUnRead(userDetails?.organizationId, userDetails?.mu_id)

		usersActions.refresh(userDetails);

		setInterval(() => {
			usersActions.refresh(userDetails);
		}, (1 * 60 * 60 * 1000)); // 1h


	}, [])

	useEffect(() => {
		setMenuOpen(props.menuStatus)
	}, [props.menuStatus]);

	useEffect(() => {
		//ReactGA.initialize("G-9FF1246SC3");
		const path = location.pathname + location.search;
		ReactGA.send({ hitType: "pageview", page: path });
	}, [location]);

	return (
		<>
			<div className='mainMenuModules'>
				<div className="item hidden">
					<div className={`mainLogo ${isMenuOpen && 'hide'}`}>
						<IconLogoBefective />
					</div>
				</div>
				{
					orderedArrayModules && orderedArrayModules?.filter(module => module.isActive).map((module, i) => 
					(
						<Tooltip
							key={`module-${module.id}`}
							placement='right'
							title={<div style={{ color: '#106276', fontSize: '12px' }}>{t(`switch.${module.name}`)}</div>}
							interactive
							classes={{ tooltip: classes.customTooltip }}
						>
							<div className={`item`} >
								<a className={`${activeModule(module.name) && 'activeModule'}`} onClick={(e) => {
									e.preventDefault()
									// if(path[1] === 'performance-assessment' && ['new', 'edit'].includes(path[2])) {
									// 	handleClickConfirmCancelEdit(e, {type: 'module', module: module})
									// 	return
									// }
									handleRedirectToModule(module)
									props.toggle('closed')
									doCountAccessModule(module.name, api, authHeader)
								}}>
									{getIconModule(module.name, module.isActive)}
								</a>
							</div>
						</Tooltip>
					))
				}  
				{orderedArrayModules && orderedArrayModules?.filter(module => module.isActive === false).map((module, i) =>
					(
						<Tooltip
							key={`module-${module.id}`}
							placement='right'
							title={<div style={{ color: '#106276', fontSize: '12px' }}>{t(`switch.${module.name}`)}</div>}
							interactive
							classes={{ tooltip: classes.customTooltip }}
						>
							<div className={`item`} >
								<a className={`${activeModule(module.name) && 'activeModule'}`} onClick={(e) => {
									e.preventDefault()
									// if(path[1] === 'performance-assessment' && ['new', 'edit'].includes(path[2])) {
									// 	handleClickConfirmCancelEdit(e, {type: 'module', module: module})
									// 	return
									// }
									handleOpenInactiveItemModal()
									activatedModel(module.id)
									props.toggle('closed')
									doCountAccessModule(module, api, authHeader)
								}}>
									{getIconModule(module.name, module.isActive)}
								</a>
							</div>
						</Tooltip>
					)
				)
			}

			</div>

			<nav className={isMenuOpen ? 'mainMenu open' : 'mainMenu'}>

						<div className="item nopointer">
							<div className="mainLogo" onClick={() => !(path[1] === 'performance-assessment' && ['new', 'edit'].includes(path[2])) && history.push('/productive')} style={{ cursor: !(path[1] === 'performance-assessment' && ['new', 'edit'].includes(path[2])) ? 'pointer' : 'default' }}>
								<IconLogo moduleName={t(`switch.${currentModule}`)} />
							</div>
						</div>

						{currentModule === 'productivity_management' && (

						userDetails &&	userDetails.profile_key !== 'collaborator' ? (
						<>
							{
								userDetails.allowed_menu.includes('executive') && (
									<div className="item" onClick={() => {
										toggleClass();
										toggleMenu()
									}}>
										<NavLink exact={true} to="/productive" activeClassName="active" className={path[2] == "widgets" ? 'active' : ''}>
											<IconToday />
											<span>{t('menu.today')}</span>
										</NavLink>
									</div>
								)
							}
							{
								userDetails.allowed_menu.includes('activities') && (
									<div className="item" onClick={() => {
										toggleClass();
										toggleMenu()
									}}>
										<NavLink to="/productive/activities">
											<IconActivities />
											<span>{t('menu.activities')}</span>
										</NavLink>
									</div>
								)
							}
							{
								userDetails.allowed_menu.includes('historical') && (
									<div className="item" onClick={() => {
										toggleClass();
										toggleMenu()
									}}>
										<NavLink to="/productive/historical">
											<IconHistorical />
											<span>{t('menu.historical')}</span>
										</NavLink>
									</div>
								)
							}

							{
								userDetails.allowed_menu.includes('reports') && (
									<div className="item" onClick={() => {
										toggleClass("reports")
										toggleMenu()
									}}>
										<NavLink exact={true} to="/productive/reports" activeClassName="active" className={path[2] == "reports" ? 'active' : ''}>
											<IconExecutive />
											<span>{t('menu.reports')}</span>
										</NavLink>
									</div>
								)
							}

							{
								userDetails.allowed_menu.includes('settings') && (
									<div className={isActiveSettings ? 'item sub open' : 'item sub'}>
										<a className={path[1] == "settings" ? 'active' : ''} onClick={() => {
											toggleClass("settings")
										}}>

											<IconSettings />
											<span>{t('menu.settings')}</span>
											<div>
												<i className="arrow"></i>
											</div>
										</a>

										{/* <div className="item" onClick={() => {
											// toggleClass();
											toggleMenu("settings")
										}}>
											<NavLink to="/settings/my-account" activeClassName="active">
												<span>{t('menu.my_account')}</span>
											</NavLink>
										</div>

										{
											userDetails.allowed_menu.includes('settings_users') && (
												<div className="item" onClick={() => {
													// toggleClass();
													toggleMenu("settings")
												}}>
													<NavLink to="/settings/users" activeClassName="active">
														<span>{t('menu.users')}</span>
													</NavLink>
												</div>
											)
										}

										{['admin', 'magic'].includes(userDetails.profile_key) &&
											<div className="item" onClick={() => {
												// toggleClass();
												toggleMenu("settings")
											}}>
												<NavLink to="/settings/profiles" activeClassName="active">
													<span>{t('menu.profiles')}</span>
												</NavLink>
											</div>
										}
										
										{
											userDetails.allowed_menu.includes('settings_teams') && (
												<div className="item" onClick={() => {
													// toggleClass();
													toggleMenu("settings")
												}}>
													<NavLink to="/settings/teams" activeClassName="active">
														<span>{t('menu.teams')}</span>
													</NavLink>
												</div>
											)
										}
										
										{
											userDetails.allowed_menu.includes('settings_company') && (
												<div className="item" onClick={() => {
													// toggleClass();
													toggleMenu("settings")
												}}>
													<NavLink to="/settings/company" activeClassName="active">
														<span>{t('menu.company')}</span>
													</NavLink>
												</div>
											)
										} */}
										
										{
											userDetails.allowed_menu.includes('settings_activities') && (
												<div className="item" onClick={() => {
													// toggleClass();
													toggleMenu("settings")
												}}>
													<NavLink to="/settings/activities" activeClassName="active">
														<span>{t('menu.activities')}</span>
													</NavLink>
												</div>												
											)
										}

										{
											userDetails.allowed_menu.includes('settings_daysoff') && (
												<div className="item" onClick={() => {
													// toggleClass();
													toggleMenu("settings")
												}}>
													<NavLink to="/settings/daysoff" activeClassName="active">
														<span>{t('menu.daysoff')}</span>
													</NavLink>
												</div>											
											)
										}
										
										{
											userDetails.allowed_menu.includes('settings_extra_collections') && (
												<div className="item" onClick={() => {
													// toggleClass();
													toggleMenu("settings")
												}}>
													<NavLink to="/settings/extra-collections" activeClassName="active">
														<span>{t('menu.extra_collections')}</span>
													</NavLink>
												</div>										
											)
										}
										
										{
											userDetails.allowed_menu.includes('settings_mood_states') && (
												<div className="item" onClick={() => {
													// toggleClass();
													toggleMenu("settings")
												}}>
													<NavLink to="/settings/moods" activeClassName="active">
														<span>{t('menu.moods_settings')}</span>
													</NavLink>
												</div>									
											)
										}
									</div>
								)
							}

						</>
					) : (
						<>
							<div className="item" onClick={() => {
								toggleClass();
								toggleMenu()
							}}>
								<NavLink
									exact={true}
									to={'/productive/productivity/'}
									activeClassName="active"
								>
									<IconToday />
									<span>{t('home.my_productivity')}</span>
								</NavLink>
							</div>

							<div className={isActiveSettings ? 'item sub open' : 'item sub'}>
								<a className={path[1] == "settings" ? 'active' : ''} onClick={() => {
									toggleClass("settings")
								}}>

									<IconSettings />
									<span>{t('menu.settings')}</span>
									<div>
										<i className="arrow"></i>
									</div>
								</a>

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu("settings")
								}}>
									<NavLink to="/settings/my-account" activeClassName="active">
										<span>{t('menu.my_account')}</span>
									</NavLink>
								</div>
							</div>
						</>
					)
				)}

				{currentModule === 'goals_management' && (
					<>
						<div className={isActiveGoals ? 'item sub open' : 'item sub'}>
							<a
								className={path[1] === "goals" ? 'active' : ''}
								onClick={() => {
									toggleClass("goals")
								}}
							>
								<IconGoals />
								<span>{t('menu.goals')}</span>
								<div>
									<i className="arrow"></i>
								</div>
							</a>

							<div className="item" onClick={() => {
								toggleMenu()
								setGoalsOptionSelected('goals')
							}}>
								<NavLink to="/goals" activeClassName={goalsOptionSelected === 'goals' ? 'active' : ''}>
									<span>{t('goals.running')}</span>
								</NavLink>
							</div>

							<div className="item" onClick={() => {
								toggleMenu()
								setGoalsOptionSelected('completed')
							}}>
								<NavLink to="/goals/completed" activeClassName={goalsOptionSelected === 'completed' ? 'active' : ''}>
									<span>{t('goals.completed')}</span>
								</NavLink>
							</div>

							<div className="item" onClick={() => {
								toggleMenu()
								setGoalsOptionSelected('archived')
							}}>
								<NavLink to="/goals/archived" activeClassName={goalsOptionSelected === 'archived' ? 'active' : ''}>
									<span>{t('goals.archived')}</span>
								</NavLink>
							</div>

							<div className="item" onClick={() => {
								toggleMenu()
								setGoalsOptionSelected('notifications')
							}}>
								<NavLink to="/goals/notifications" activeClassName={goalsOptionSelected === 'notifications' ? 'active' : ''}>
									<span>{t('goals_notifications.notification')}</span>
								</NavLink>
							</div>

						</div>

						<div className={isActiveSettings ? 'item sub open' : 'item sub'}>
							<a className={path[1] == "settings" ? 'active' : ''} onClick={() => {
								toggleClass("settings")
							}}>

								<IconSettings />
								<span>{t('menu.settings')}</span>
								<div>
									<i className="arrow"></i>
								</div>
							</a>

							<div className="item" onClick={() => {
								toggleClass();
								toggleMenu("settings")
							}}>
								<NavLink to="/settings/my-account" activeClassName="active">
									<span>{t('menu.my_account')}</span>
								</NavLink>
							</div>

							{(userDetails.roleName !== 'collaborator' && (permissions.ViewCompanyInformation || permissions.ViewCompanyAdministrators || permissions.ViewCompanyFreeDays || permissions.ViewCompanyInvetory)) &&
								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu("settings")
								}}>
									<NavLink to="/settings/company" activeClassName="active">
										<span>{t('menu.company')}</span>
									</NavLink>
								</div>
							}

							<div className="item" onClick={() => {
								toggleClass();
								toggleMenu("settings")
							}} style={{ display: 'none' }}>
								<NavLink to="/settings/payment" activeClassName="active">
									<span>{t('menu.payment')}</span>
								</NavLink>
							</div>


						</div>
					</>
				)}

				{currentModule === 'check_in_remote' && (
					<>
						{userDetails.roleName !== 'collaborator' ? (
							<>
								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/virtual-point" activeClassName="active">
										<Timer style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										{
											t("menu.check_in_remote").length > 17 ?
												<span style={{ fontSize: "13px" }}>{t("menu.check_in_remote")}</span>
												:
												<span>{t("menu.check_in_remote")}</span>}

									</NavLink>
								</div>

								<div className={isActiveCheckinControl ? 'item sub open' : 'item sub'}>
									<a className={path[1] == "virtual-point" && path[2] == 'control' ? 'active' : ''} onClick={() => {
										toggleClass("control")
										getOpenSolicitations()
									}}>
										<Briefcase style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.points_control")}</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>

									<div className="item" onClick={() => {
										toggleMenu()
									}}>
										<NavLink to="/virtual-point/control/overview" activeClassName="active">
											<span>{t("menu.points_control_overview")}</span>
										</NavLink>
									</div>

									<div className="item" onClick={() => {
										toggleMenu()
									}}>
										<NavLink to="/virtual-point/control/solicitations" activeClassName="active">
											<span>{t("menu.points_control_solicitations")}</span>
											{
												solicitations !== 0 && props.menuStatus && (
													<div className='badge' style={{ width: "22px" }}>
														{solicitations}
													</div>
												)
											}
										</NavLink>
									</div>

								</div>

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/virtual-point/history" activeClassName={'active'}>
										<IconHistorical />
										<span>{t("menu.historical")}</span>
									</NavLink>
								</div>

								<div className={isActiveCheckinReports ? 'item sub open' : 'item sub'}>
									<a className={path[1] == "virtual-point" && path[2] == 'reports' ? 'active' : ''} onClick={() => {
										toggleClass("checkin")
									}}>
										<Briefcase style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.reports")}</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>

									<div className="item" onClick={() => {
										toggleMenu()
									}}>
										<NavLink to="/virtual-point/reports/points" activeClassName="active">
											<span>{t("menu.points")}</span>
										</NavLink>
									</div>
									<div className="item" onClick={() => {
										toggleMenu()
									}}>
										<NavLink to="/virtual-point/reports/signed-points" activeClassName="active">
											<span>{t("menu.signed_points")}</span>
										</NavLink>
									</div>
									<div className="item" onClick={() => {
										toggleMenu()
									}}>
										<NavLink to="/virtual-point/reports/overtime" activeClassName="active">
											<span>{t("menu.overtime")}</span>
										</NavLink>
									</div>

									<div className="item" onClick={() => {
										toggleMenu()
									}}>
										<NavLink to="/virtual-point/reports/map" activeClassName="active">
											<span>{t("menu.map")}</span>
										</NavLink>
									</div>

								</div>


								<div className={isActiveSettings ? 'item sub open' : 'item sub'}>
									<a className={path[1] == "settings" ? 'active' : ''} onClick={() => {
										toggleClass("settings")
									}}>

										<IconSettings />
										<span>{t('menu.settings')}</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>

									<div className="item" onClick={() => {
										// toggleClass();
										toggleMenu("settings")
									}}>
										<NavLink to="/settings/virtual-point" activeClassName={(path[2] == "virtual-point" && !path[3]) ? 'active' : ''}>
											<span>{t('checkin.dashboard.title_page')}</span>
										</NavLink>
									</div>

									<div className="item" onClick={() => {
										// toggleClass();
										toggleMenu("settings")
									}}>
										<NavLink to="/settings/virtual-point/teams" activeClassName={(path[2] == "virtual-point" && path[3] == "teams") ? 'active' : ''}>
											<span>{t('settings.teams.teams')}</span>
										</NavLink>
									</div>

									<div className="item" onClick={() => {
										// toggleClass();
										toggleMenu("settings")
									}}>
										<NavLink to="/settings/my-account" activeClassName="active">
											<span>{t('menu.my_account')}</span>
										</NavLink>
									</div>


									{(permissions.ViewCompanyInformation || permissions.ViewCompanyAdministrators || permissions.ViewCompanyFreeDays || permissions.ViewCompanyInvetory) &&
										<div className="item" onClick={() => {
											// toggleClass();
											toggleMenu("settings")
										}}>
											<NavLink to="/settings/company" activeClassName="active">
												<span>{t('menu.company')}</span>
											</NavLink>
										</div>
									}

									<div className="item" onClick={() => {
										// toggleClass();
										toggleMenu("settings")
									}} style={{ display: 'none' }}>
										<NavLink to="/settings/payment" activeClassName="active">
											<span>{t('menu.payment')}</span>
										</NavLink>
									</div>


								</div>
							</>
						) : (
							<>
								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/virtual-point" activeClassName="active">
										<Timer style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.check_in_remote")}</span>
									</NavLink>
								</div>

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/virtual-point/history" activeClassName={'active'}>
										<IconHistorical />
										<span>{t("menu.historical")}</span>
									</NavLink>
								</div>

								<div className={isActiveSettings ? 'item sub open' : 'item sub'}>
									<a className={path[1] == "settings" ? 'active' : ''} onClick={() => {
										toggleClass("settings")
									}}>

										<IconSettings />
										<span>{t('menu.settings')}</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>

									<div className="item" onClick={() => {
										toggleClass();
										toggleMenu("settings")
									}}>
										<NavLink to="/settings/my-account" activeClassName="active">
											<span>{t('menu.my_account')}</span>
										</NavLink>
									</div>

								</div>
							</>
						)}
					</>
				)}

				{currentModule === 'rooms_management' && (
					<>
						{userDetails.roleName !== 'collaborator' ? (
							<>
								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/spaces/management" className={(path[1] == "spaces" && path[2] == "management") ? 'active' : ''} activeClassName={"active"}>
										<HouseSimple style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t('spaces.management')}</span>
									</NavLink>
								</div>

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/spaces" activeClassName="active" className={(path.includes("spaces") && !path[2]) || path.includes('reservation') ? 'active' : ''}>
										<CalendarCheck style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t('spaces.reservation_room')}</span>
									</NavLink>
								</div>

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/spaces/my-reservations" activeClassName="active">
										<CalendarBlank style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t('spaces.my_reservarions')}</span>
									</NavLink>
								</div>

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/spaces/reports-colabs" activeClassName="active">
										<Article style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t('spaces.reports')}</span>
									</NavLink>
								</div>

								<div className={isActiveSettings ? 'item sub open' : 'item sub'}>
									<a className={path[1] == "settings" ? 'active' : ''} onClick={() => {
										toggleClass("settings")
									}}>

										<IconSettings />
										<span>{t('menu.settings')}</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>

									<div className="item" onClick={() => {
										// toggleClass('settings');
										toggleMenu()
									}}>
										<NavLink to="/settings/spaces/attributes" activeClassName="active">
											<span>{t('spaces.management')}</span>
										</NavLink>
									</div>

									<div className="item" onClick={() => {
										// toggleClass();
										toggleMenu()
									}}>
										<NavLink to="/settings/my-account" activeClassName="active">
											<span>{t('menu.my_account')}</span>
										</NavLink>
									</div>


									{(permissions.ViewCompanyInformation || permissions.ViewCompanyAdministrators || permissions.ViewCompanyFreeDays || permissions.ViewCompanyInvetory) &&
										<div className="item" onClick={() => {
											// toggleClass();
											toggleMenu()
										}}>
											<NavLink to="/settings/company" activeClassName="active">
												<span>{t('menu.company')}</span>
											</NavLink>
										</div>
									}
								</div>
							</>
						) : (
							<>
								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/spaces" activeClassName="active">
										<CalendarCheck style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t('spaces.reservation_room')}</span>
									</NavLink>
								</div>
								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/spaces/my-reservations" activeClassName="active">
										<CalendarBlank style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t('spaces.my_reservarions')}</span>
									</NavLink>
								</div>

								<div className={isActiveSettings ? 'item sub open' : 'item sub'}>
									<a className={path[1] == "settings" ? 'active' : ''} onClick={() => {
										toggleClass("settings")
									}}>

										<IconSettings />
										<span>{t('menu.settings')}</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>

									<div className="item" onClick={() => {
										toggleClass();
										toggleMenu("settings")
									}}>
										<NavLink to="/settings/my-account" activeClassName="active">
											<span>{t('menu.my_account')}</span>
										</NavLink>
									</div>
								</div>
							</>
						)}
					</>
				)}

				{currentModule === 'project_management' && (
					<>
						{userDetails.roleName !== 'collaborator' ? (
							<>

								<div className="item" onClick={() => {
									// toggleClass();
									// toggleMenu()
								}}>
									<NavLink exact={true} to="/project_management/home" className={path[2] == "home" && "active"}>
										<HouseSimple style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t('project_management.menu.home')}</span>
									</NavLink>
								</div>

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/project_management/workspaces" className={path[2] == "workspaces" && "active"}>
										<Kanban style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t('project_management.menu.workspaces')}</span>
									</NavLink>
								</div>

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/project_management/archived-boards" className={path[2] == "archived-boards" && "active"}>
										<ArchiveTray style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t('project_management.menu.archived_boards')}</span>
									</NavLink>
								</div>

								<div className={isActiveProjectManagementReports ? 'item sub open' : 'item sub'}>
									<a className={path[1] == "project_management" && path[2] == 'reports' ? 'active' : ''} onClick={() => {
										toggleClass("project_management")
									}}>
										<Article style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t('project_management.menu.reports')}</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>
									<div className="item" onClick={() => {
										toggleMenu()
									}}>
										<NavLink to="/project_management/reports/boards" activeClassName="active">
											<span>{t('project_management.reports.boards.title')}</span>
										</NavLink>
									</div>
									<div className="item" onClick={() => {
										toggleMenu()
									}}>
										<NavLink to="/project_management/reports/timeline" activeClassName="active">
											<span>{t('project_management.reports.time_line.label')}</span>
										</NavLink>
									</div>
								</div>
								<div className={isActiveSettings ? 'item sub open' : 'item sub'}>
									<a className={path[1] == "settings" ? 'active' : ''} onClick={() => {
										toggleClass("settings")
									}}>

										<IconSettings />
										<span>{t('menu.settings')}</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>

									<div className="item" onClick={() => {
										// toggleClass('settings');
										toggleMenu()
									}}>
										<NavLink to="/settings/project_management/workspace-types" activeClassName="active">
											<span>{t('menu.project_management')}</span>
										</NavLink>
									</div>

									<div className="item" onClick={() => {
										toggleClass();
										// toggleMenu("settings")
									}}>
										<NavLink to="/settings/my-account" activeClassName="active">
											<span>{t('menu.my_account')}</span>
										</NavLink>
									</div>


									{(permissions.ViewCompanyInformation || permissions.ViewCompanyAdministrators || permissions.ViewCompanyFreeDays || permissions.ViewCompanyInvetory) &&
										<div className="item" onClick={() => {
											toggleClass();
											// toggleMenu("settings")
										}}>
											<NavLink to="/settings/company" activeClassName="active">
												<span>{t('menu.company')}</span>
											</NavLink>
										</div>
									}
								</div>
							</>
						) : (
							<>

								<div className="item" onClick={() => {
									// toggleClass();
									// toggleMenu()
								}}>
									<NavLink exact={true} to="/project_management/home" className={path[2] == "home" && "active"}>
										<HouseSimple style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t('project_management.menu.home')}</span>
									</NavLink>
								</div>

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/project_management/workspaces" className={path[2] == "workspaces" && "active"}>
										<Kanban style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t('project_management.menu.workspaces')}</span>
									</NavLink>
								</div>


								<div className={isActiveSettings ? 'item sub open' : 'item sub'}>
									<a className={path[1] == "settings" ? 'active' : ''} onClick={() => {
										toggleClass("settings")
									}}>

										<IconSettings />
										<span>{t('menu.settings')}</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>

									<div className="item" onClick={() => {
										toggleClass();
										toggleMenu("settings")
									}}>
										<NavLink to="/settings/my-account" activeClassName="active">
											<span>{t('menu.my_account')}</span>
										</NavLink>
									</div>
								</div>
							</>
						)}
					</>
				)}

				{currentModule === 'check_in_remote_mobile' && (
					<>
						{userDetails.roleName !== 'collaborator' ? (
							<>
								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/checkin-remote" activeClassName="active">
										<HouseSimple style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.dashboard")}</span>
									</NavLink>
								</div>

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/checkin-remote/downloads" activeClassName={'active'}>
										<Download style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.downloads")}</span>
									</NavLink>
								</div>

								<div className={isActiveCheckinReports ? 'item sub open' : 'item sub'}>
									<a className={path[1] == "checkin-remote" && path[2] == 'reports' ? 'active' : ''} onClick={() => {
										toggleClass("checkin")
									}}>
										<Briefcase style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.reports")}</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>

									<div className="item" onClick={() => {
										toggleMenu()
									}}>
										<NavLink to="/checkin-remote/reports/teammates" activeClassName="active">
											<span>{t("menu.teammates")}</span>
										</NavLink>
									</div>
									<div className="item" onClick={() => {
										toggleMenu()
									}}>
										<NavLink to="/checkin-remote/reports/map" activeClassName="active">
											<span>{t("menu.map")}</span>
										</NavLink>
									</div>

								</div>

								<div className={isActiveSettings ? 'item sub open' : 'item sub'}>
									<a className={path[1] == "settings" ? 'active' : ''} onClick={() => {
										toggleClass("settings")
									}}>

										<IconSettings />
										<span>{t('menu.settings')}</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>

									<div className="item" onClick={() => {
										toggleClass();
										toggleMenu("settings")
									}}>
										<NavLink to="/settings/my-account" activeClassName="active">
											<span>{t('menu.my_account')}</span>
										</NavLink>
									</div>


									{(permissions.ViewCompanyInformation || permissions.ViewCompanyAdministrators || permissions.ViewCompanyFreeDays || permissions.ViewCompanyInvetory) &&
										<div className="item" onClick={() => {
											toggleClass();
											toggleMenu("settings")
										}}>
											<NavLink to="/settings/company" activeClassName="active">
												<span>{t('menu.company')}</span>
											</NavLink>
										</div>
									}

									<div className="item" onClick={() => {
										toggleClass();
										toggleMenu("settings")
									}} style={{ display: 'none' }}>
										<NavLink to="/settings/payment" activeClassName="active">
											<span>{t('menu.payment')}</span>
										</NavLink>
									</div>


								</div>
							</>
						) : (
							<>
								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/checkin-remote" activeClassName="active">
										<HouseSimple style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.dashboard")}</span>
									</NavLink>
								</div>

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/checkin-remote/downloads" activeClassName={'active'}>
										<Download style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.downloads")}</span>
									</NavLink>
								</div>


								<div className={isActiveSettings ? 'item sub open' : 'item sub'}>
									<a className={path[1] == "settings" ? 'active' : ''} onClick={() => {
										toggleClass("settings")
									}}>

										<IconSettings />
										<span>{t('menu.settings')}</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>

									<div className="item" onClick={() => {
										toggleClass();
										toggleMenu("settings")
									}}>
										<NavLink to="/settings/my-account" activeClassName="active">
											<span>{t('menu.my_account')}</span>
										</NavLink>
									</div>

								</div>
							</>
						)}
					</>
				)}

				{currentModule === 'inventory' && (
					<>
						{userDetails.roleName !== 'collaborator' && (
							<>
								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/inventory" activeClassName="active">
										<HouseSimple style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.dashboard")}</span>
									</NavLink>
								</div>

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/inventory/software" activeClassName={'active'} className={path[2] == "software" ? 'active' : ''}>
										<FolderNotchOpen style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />

										<span>{t("menu.software")}</span>
									</NavLink>
								</div>

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/inventory/colabs" activeClassName={'active'}>
										<DesktopTower style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />

										<span>{t("menu.hardware")}</span>
									</NavLink>
								</div>

								<div className={isActiveSettings ? 'item sub open' : 'item sub'}>
									<a className={path[1] == "settings" ? 'active' : ''} onClick={() => {
										toggleClass("settings")
									}}>

										<IconSettings />
										<span>{t('menu.settings')}</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>

									<div className="item" onClick={() => {
										toggleClass();
										toggleMenu("settings")
									}}>
										<NavLink to="/settings/my-account" activeClassName="active">
											<span>{t('menu.my_account')}</span>
										</NavLink>
									</div>


									{(permissions.ViewCompanyInformation || permissions.ViewCompanyAdministrators || permissions.ViewCompanyFreeDays || permissions.ViewCompanyInvetory) &&
										<div className="item" onClick={() => {
											toggleClass();
											toggleMenu("settings")
										}}>
											<NavLink to="/settings/company" activeClassName="active">
												<span>{t('menu.company')}</span>
											</NavLink>
										</div>
									}

									<div className="item" onClick={() => {
										toggleClass();
										toggleMenu("settings")
									}} style={{ display: 'none' }}>
										<NavLink to="/settings/payment" activeClassName="active">
											<span>{t('menu.payment')}</span>
										</NavLink>
									</div>


								</div>
							</>
						)}
					</>
				)}

				
			
				{currentModule === 'performance_assessment' && (
					<>
						{userDetails.roleName !== 'collaborator' ? (
							// path[1] == "performance-assessment" && ['new', 'edit'].includes(path[2])
							// ?
							// 	<div className="item">
							// 		<a exact={true} to="" onClick={(e)  => handleClickConfirmCancelEdit(e, {type: 'back'})}>
							// 			<ArrowLeft size={24} weight='bold' color='#CEDEE3'/>
							// 			<span>{t("performance_assessment.actions.back")}</span>
							// 		</a>
							// 	</div>
							// :
							<>
								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/performance-assessment" className={path[2] == "widgets" ? 'active' : ''}>
										<Gauge style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.performance_assessment.dashboard")}</span>
									</NavLink>
								</div>

								<div className={isActivePerformanceAssessments ? 'item sub open' : 'item sub'}>
									<a className={(path[1] == "performance-assessment" && ['assess','created', 'new', 'edit', 'details'].includes(path[2]))  ? 'active' : ''} onClick={() => {
										toggleClass("performance_assessments")
									}}>
										<ListChecks style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.performance_assessment.assessments")}
											{
												(assessmentPending !== 0 && props.menuStatus && (!props?.menu?.performance_assessments || props?.menu?.performance_assessments === false)) && (
													<span className='badge' style={{ width: "18px", marginLeft: "5px" }}>
														{assessmentPending}
													</span>
												)
											}
										</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>

									<div className="item" onClick={() => {
										toggleMenu()
									}}>
										<NavLink to="/performance-assessment/created" className={(path[1] == "performance-assessment" && ['created', 'new', 'edit', 'details'].includes(path[2])) ? 'active' : ''} activeClassName="active">
											<span>{t("menu.performance_assessment.register")}</span>
										</NavLink>
									</div>

									<div className="item" onClick={() => {
										toggleMenu()
									}}>
										<NavLink to="/performance-assessment/assess" activeClassName="active">
											<span>
												{t("menu.performance_assessment.pending")}
												{
												assessmentPending !== 0 && props.menuStatus && (
													<div className='badge' style={{ width: "18px", marginLeft: "5px" }}>
														{assessmentPending}
													</div>
												)
											}
											</span>
										</NavLink>
									</div>

								</div>							
								

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/performance-assessment/reports" className={path[1] == "performance-assessment" && path[2] == 'reports' ? 'active' : ''} activeClassName="active">
										<Briefcase style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.performance_assessment.reports")}</span>
									</NavLink>
								</div>

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/performance-assessment/feedback" className={path[1] == "performance-assessment" && path[2] == 'feedback' ? 'active' : ''} activeClassName="active">
										<Chats style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.performance_assessment.feedback")}</span>
									</NavLink>
								</div>

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/performance-assessment/action-plan" className={path[1] == "performance-assessment" && path[2] == 'action-plan' ? 'active' : ''} activeClassName="active">
										<Warning style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.performance_assessment.action_plan")}</span>
									</NavLink>
								</div>

								<div className={isActivePerformanceAssessmentPreSettings ? 'item sub open' : 'item sub'}>
									<a className={path[1] == "performance-assessment" && path[2] == 'pre-settings' ? 'active' : ''} onClick={() => {
										toggleClass("pre_settings")
									}}>
										<Gear style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.performance_assessment.pre_settings")}</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>
									
									<div className="item" onClick={() => {
										toggleMenu()
									}}>
										<NavLink to="/performance-assessment/pre-settings/scales" className={path[1] == "performance-assessment" && path[2] == 'pre-settings' && path[3] == 'scales' ? 'active' : ''} activeClassName="active">
											<span>{`1 - ${t("menu.performance_assessment.scales")}`}</span>
										</NavLink>
									</div>

									<div className="item" onClick={() => {
										toggleMenu()
									}}>
										<NavLink to="/performance-assessment/pre-settings/skills" activeClassName="active">
											<span>{`2 - ${t("menu.performance_assessment.skills")}`}</span>
										</NavLink>
									</div>

									<div className="item" onClick={() => {
										toggleMenu()
									}}>
										<NavLink to="/performance-assessment/pre-settings/indicators" className={path[1] == "performance-assessment" && path[2] == 'pre-settings' && path[3] == 'indicators' ? 'active' : ''} activeClassName="active">
											<span>{`3 - ${t("menu.performance_assessment.result_indicators")}`}</span>
										</NavLink>
									</div>
								</div>

								<div className={isActiveSettings ? 'item sub open' : 'item sub'}>
									<a className={path[1] == "settings" ? 'active' : ''} onClick={() => {
										toggleClass("settings")
									}}>

										<IconSettings />
										<span>{t('menu.settings')}</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>

									<div className="item" onClick={() => {
										toggleClass();
										toggleMenu("settings")
									}}>
										<NavLink to="/settings/my-account" activeClassName="active">
											<span>{t('menu.my_account')}</span>
										</NavLink>
									</div>


									{(permissions.ViewCompanyInformation || permissions.ViewCompanyAdministrators || permissions.ViewCompanyFreeDays || permissions.ViewCompanyInvetory) &&
										<div className="item" onClick={() => {
											toggleClass();
											toggleMenu("settings")
										}}>
											<NavLink to="/settings/company" activeClassName="active">
												<span>{t('menu.company')}</span>
											</NavLink>
										</div>
									}

									<div className="item" onClick={() => {
										toggleClass();
										toggleMenu("settings")
									}} style={{ display: 'none' }}>
										<NavLink to="/settings/payment" activeClassName="active">
											<span>{t('menu.payment')}</span>
										</NavLink>
									</div>


								</div>
							</>
						) : (
							<>
								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/checkin-remote" activeClassName="active">
										<HouseSimple style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.dashboard")}</span>
									</NavLink>
								</div>

								<div className="item" onClick={() => {
									toggleClass();
									toggleMenu()
								}}>
									<NavLink exact={true} to="/checkin-remote/downloads" activeClassName={'active'}>
										<Download style={{ minWidth: '24px', minHeight: '24px' }} weight='bold' />
										<span>{t("menu.downloads")}</span>
									</NavLink>
								</div>


								<div className={isActiveSettings ? 'item sub open' : 'item sub'}>
									<a className={path[1] == "settings" ? 'active' : ''} onClick={() => {
										toggleClass("settings")
									}}>

										<IconSettings />
										<span>{t('menu.settings')}</span>
										<div>
											<i className="arrow"></i>
										</div>
									</a>

									<div className="item" onClick={() => {
										toggleClass();
										toggleMenu("settings")
									}}>
										<NavLink to="/settings/my-account" activeClassName="active">
											<span>{t('menu.my_account')}</span>
										</NavLink>
									</div>

								</div>
							</>
						)}
					</>
				)}

				{/* Start Confirm Dialog Cancel Edit */}
				<PopoverConfirm
						idConfirm={idConfirmCancelEdit}
						openConfirm={openConfirmCancelEdit}
						anchorEl={anchorElConfirmCancelEdit}
						handleClose={handleCloseConfirmCancelEdit}
						handleConfirm={() => {
							if(dataRedirect){
								if(dataRedirect?.type === 'back') {
									history.push('/performance-assessment/created')
								}
								if(dataRedirect?.type === 'module') {
									handleRedirectToModule(dataRedirect.module)
									props.toggle('closed')
									doCountAccessModule(dataRedirect.module.name, api, authHeader)
								}
							}
							handleCloseConfirmCancelEdit();
						}}
						title={t('performance_assessment.messages.cancel_edit_info')}
					/>
				{/* End Confirm Dialog Cancel Edit */}


			</nav>
      <ModalMenuInactiveItem isOpen={isInactiveItemModal} closeModel={handleCloseInactiveItemModal} modelDetails={modelDetails}/>
		</>

	)
};

function mapDispatchToProps(dispatch) {

	return {
		menuActions: bindActionCreators(menuActions, dispatch),
		usersActions: bindActionCreators(usersActions, dispatch)
	}
}

function mapStateToProps(state) {

	return {
		menu: state.menu,
		permissions: state.authentication.permissions,
		userDetails: state.authentication.userDetails
	}

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
