import React from "react";
import Chart from "react-apexcharts";
import BefectiveTooltip from "../../_shared/BefectiveTooltip";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import IconInfo from "../../_icons/IconInfo";
import './MoodWidget.scss'

const MoodWidget = ({data}) => {
  
  const { t } = useTranslation('common');

  return (
    <div className="card-mood-widget">
      <header>
        <div className='box-title'>
          <Link className={"linkDashRedirect"} to={'/productive/reports/mood'}>
            <h3 className='title'>{t('home.teammates_mood')}</h3>
          </Link>
          <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('home.teammates_mood_tooltip')}</Typography>} placement="right-start" arrow interactive>
            <div><IconInfo /></div>
          </BefectiveTooltip>
        </div>
        <p>{`${data.stats.total_users_active} / ${data.stats.total_users} ${t('widgets.responded')}`}</p>
      </header>
      <div style={{ position: "relative" }}>
        {/* Gráfico */}
        <Chart options={data.chart.options} series={data.chart.series} type="bar" height={230} />
        {/* Imagens do eixo X */}
        <div className="footer-chart-mood">
          {data.moodImages.map((mood, index) => (
            <div key={index} className="mood-item">
              <img src={mood.src} alt={mood.alt} width={'20px'} height={'20px'}/>
              <span style={{ color: mood.color }}>{mood.percent}%</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoodWidget;
