import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, teammatesActions, usersActions } from '../../_actions';
import Toolbar from '../../_components/_shared/Toolbar';
import style from './styles.module.scss';
import { api } from '../../_helpers/api';
import { authHeader } from '../../_helpers';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { FormCreate } from '../../_components/users_settings/FormCreate';
import UsersTabComponent from '../../_components/users_settings/UsersTabComponent';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import { Box } from '@mui/material';
import Header from '../../_components/switch_page/Header';


function UsersSettingsCreatePage(props) {
  const { t, i18n } = useTranslation('common');

  const { userDetails, teammates, teammatesActions, alertActions } = props
  const [userToEdit, setUserToEdit] = useState(null)
  const [loading, setLoading] = useState(false);
  const [optionsGroups, setOptionsGroups] = useState([])
  const [optionsProfile, setOptionsProfile] = useState([])
  const [fetchingOptionsProfile, setFetchingOptionsProfile] = useState(false)
  const { id } = useParams();

  let lang = 'pt'

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  } else {
    lang = window.navigator.userLanguage;
  }

  const getUserById = async (withLoading=true) => {
    try {
      if(withLoading){
        setLoading(true)
      }
      const { data } = await api.get(`/users/v2/${id}`, { headers: authHeader() })
      setUserToEdit(data)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const getGroups = async () => {
    try {
      const resResponsibles = await api.get(`/goals/get-groups?organization_id=${userDetails.organizationId}`, {
				headers: authHeader()
			})
      setOptionsGroups(resResponsibles?.data)
    } catch (error) {
      console.log(error)  
    }

  }

  const getOptionsProfile = async () => {
    try {

      setFetchingOptionsProfile(true)
      const {data} = await api.get(`settings/access-profile-options`, {
				headers: authHeader()
			})
      const newData = data.map(option => {
        if(option.is_default_profile===true){
          return {...option, label: t(`settings.teams.profiles.${option.key}`)}
        }
        return option
      })
      setOptionsProfile(newData)
      setFetchingOptionsProfile(false)
    } catch (error) {
      console.log(error)  
      setFetchingOptionsProfile(false)
    }

  }

  useEffect(() => {

    if (id) {
      getUserById()
    } else {
      setLoading(false)
    }
    teammatesActions.getTeammates()
    getGroups()
    getOptionsProfile()
  }, [id])

  return (
    <Box bgcolor="#F5F6F9" width="100%" display="flex" flexDirection="column">
      <Header toBackUrl={"/global-settings/users"} />
      <section className="mainContent productivity">

        {/* <Toolbar /> */}

        <UsersTabComponent currentActive="user" userId={id} userDetails={userDetails}/>

        {/* PAGE STARTS */}
        <div className={style.containerRoomsPage}>
          {
            (!loading && !fetchingOptionsProfile)
            ? <FormCreate teammates={teammates} alertActions={alertActions} userDetails={userDetails} userToEdit={userToEdit} optionsGroups={optionsGroups} getDetailUser={getUserById} optionsProfile={optionsProfile}/>
            :
            <GlobalSkeleton totalTables={1} totalRowsStats={0} padding={"0px"} marginTopTable={"0px"}/>
          }
        </div>
        {/* PAGE ENDS */}

      </section>
    </Box>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(UsersSettingsCreatePage)