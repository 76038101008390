import { Box, CircularProgress, Container, Grid, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { alertActions, usersActions } from '../../_actions'
import style from './styles.module.scss'
import { authHeader, getDayWeekV2, history } from '../../_helpers'
import { api } from '../../_helpers/api'
import AlertBox from '../../_components/_shared/AlertBox'
import { ChartLineUp } from 'phosphor-react'
import Header from '../../_components/finalization_poc/Header'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import BannerFinalizationPT from '../../../public/images/banner_finalization_pt.png'
import BannerFinalizationEN from '../../../public/images/banner_finalization_en.png'
import BannerFinalizationES from '../../../public/images/banner_finalization_es.png'

function FinalizationPocPage({ userDetails, alertActions, usersActions }) {
  const { t, i18n } = useTranslation('common');
  
  const [highlightsData, setHighlightsData] = useState({
    has_meeting_scheduling_poc: false,
    avg_performance_percent: 0,
    avg_working_time: 0,
    avg_performance_time: 0,
    most_productive_colab: 0,
    worst_productive_colab: 0,
    most_productive_dow: 0,
    most_used_distraction: 0
  });

  const [fetching, setFetching] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [form, setForm] = useState({
    objective: '',
    colaborador_range: '',
    sector: '',
    contract_period: '',
    meeting_datetime: null
  });

  const [formError, setFormError] = useState({
    objective: '',
    colaborador_range: '',
    sector: '',
    contract_period: ''
  });

  const lang = userDetails.language || i18n.language

  const sectorsOptions = [
    { key: "administration", pt: "Administração", en: "Administration", es: "Administración" },
    { key: "human_resources", pt: "Recursos Humanos (RH)", en: "Human Resources (HR)", es: "Recursos Humanos (RH)" },
    { key: "finance", pt: "Financeiro", en: "Finance", es: "Finanzas" },
    { key: "accounting", pt: "Contabilidade", en: "Accounting", es: "Contabilidad" },
    { key: "legal", pt: "Jurídico", en: "Legal", es: "Legal" },
    { key: "strategic_planning", pt: "Planejamento Estratégico", en: "Strategic Planning", es: "Planificación Estratégica" },
    { key: "purchasing_supplies", pt: "Compras e Suprimentos", en: "Purchasing & Supplies", es: "Compras y Suministros" },
    { key: "logistics", pt: "Logística", en: "Logistics", es: "Logística" },
    { key: "production_operations", pt: "Produção/Operações", en: "Production/Operations", es: "Producción/Operaciones" },
    { key: "sales", pt: "Vendas", en: "Sales", es: "Ventas" },
    { key: "marketing", pt: "Marketing", en: "Marketing", es: "Marketing" },
    { key: "customer_service", pt: "Atendimento ao Cliente", en: "Customer Service", es: "Atención al Cliente" },
    { key: "it", pt: "Tecnologia da Informação (TI)", en: "Information Technology (IT)", es: "Tecnología de la Información (TI)" },
    { key: "software_development", pt: "Desenvolvimento de Software", en: "Software Development", es: "Desarrollo de Software" },
    { key: "research_development", pt: "Pesquisa e Desenvolvimento (P&D)", en: "Research & Development (R&D)", es: "Investigación y Desarrollo (I+D)" },
    { key: "general_services", pt: "Serviços Gerais", en: "General Services", es: "Servicios Generales" },
    { key: "workplace_safety", pt: "Segurança do Trabalho", en: "Workplace Safety", es: "Seguridad en el Trabajo" },
  ];

  const objectivesOptions = [
    {
      key: "increase_productivity",
      pt: "Aumentar a Produtividade da Equipe",
      en: "Increase Team Productivity",
      es: "Aumentar la Productividad del Equipo"
    },
    {
      key: "improve_time_management",
      pt: "Melhorar a Gestão do Tempo",
      en: "Improve Time Management",
      es: "Mejorar la Gestión del Tiempo"
    },
    {
      key: "reduce_unproductive_time",
      pt: "Reduzir o Tempo Gasto com Atividades Não Produtivas",
      en: "Reduce Time Spent on Unproductive Activities",
      es: "Reducir el Tiempo Dedicado a Actividades No Productivas"
    },
    {
      key: "optimize_workflows",
      pt: "Otimizar Processos e Fluxos de Trabalho",
      en: "Optimize Processes and Workflows",
      es: "Optimizar Procesos y Flujos de Trabajo"
    },
    {
      key: "enhance_engagement",
      pt: "Melhorar o Engajamento e a Motivação dos Colaboradores",
      en: "Enhance Employee Engagement and Motivation",
      es: "Mejorar el Compromiso y la Motivación de los Empleados"
    },
    {
      key: "increase_transparency",
      pt: "Aumentar a Transparência e a Prestação de Contas",
      en: "Increase Transparency and Accountability",
      es: "Aumentar la Transparencia y la Responsabilidad"
    },
    {
      key: "data_driven_decisions",
      pt: "Tomada de Decisões Baseada em Dados",
      en: "Data-Driven Decision Making",
      es: "Toma de Decisiones Basada en Datos"
    },
    {
      key: "monitor_remote_work",
      pt: "Monitorar e Melhorar o Trabalho Remoto",
      en: "Monitor and Improve Remote Work",
      es: "Monitorear y Mejorar el Trabajo Remoto"
    },
    {
      key: "reduce_operational_costs",
      pt: "Reduzir Custos Operacionais",
      en: "Reduce Operational Costs",
      es: "Reducir Costos Operativos"
    },
    {
      key: "align_with_goals",
      pt: "Alinhar Equipes com os Objetivos da Empresa",
      en: "Align Teams with Business Goals",
      es: "Alinear Equipos con los Objetivos de la Empresa"
    }
  ];

  const colabsOptions = [
    {
      key: "one_to_five",
      pt: "De 1 a 5",
      en: "1 to 5",
      es: "De 1 a 5"
    },
    {
      key: "five_to_ten",
      pt: "De 5 a 10",
      en: "5 to 10",
      es: "De 5 a 10"
    },
    {
      key: "ten_to_twenty",
      pt: "De 10 a 20",
      en: "10 to 20",
      es: "De 10 a 20"
    },
    {
      key: "twenty_to_fifty",
      pt: "De 20 a 50",
      en: "20 to 50",
      es: "De 20 a 50"
    },
    {
      key: "fifty_to_hundred",
      pt: "De 50 a 100",
      en: "50 to 100",
      es: "De 50 a 100"
    },
    {
      key: "hundred_to_two_hundred",
      pt: "De 100 a 200",
      en: "100 to 200",
      es: "De 100 a 200"
    },
    {
      key: "more_than_two_hundred",
      pt: "Mais de 200",
      en: "More than 200",
      es: "Mas de 200"
    }
  ]

  const periodsOptions = [
    {
      key: "monthly",
      pt: "Mensal",
      en: "Monthly",
      es: "Mensual"
    },
    {
      key: "quarterly",
      pt: "Trimestral",
      en: "Quarterly",
      es: "Trimestral"
    },
    {
      key: "semi-annual",
      pt: "Semestral",
      en: "Semi-annual",
      es: "Semestral"
    },
    {
      key: "annual",
      pt: "Anual",
      en: "Annual",
      es: "Anual"
    }
  ]

  const getHighlights = async (isLoading=true) => {
    try {
      if(isLoading){
        setFetching(true);
      }

      const response = await api.get(`/settings/poc/highlights`, {
        headers: authHeader()
      });

      setHighlightsData(response.data);

      setFetching(false);

    } catch (error) { 
      console.log(error);
      setFetching(false);
    }
  }

  const onChangeSelect = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setFormError({ ...formError, [name]: '' });
  }

  const validateForm = (form) => {
    const errors = {};

    if (!form.objective) {
      errors.objective = t('validation.required')
    }

    if (!form.colaborador_range) {
      errors.colaborador_range = t('validation.required')
    }

    if (!form.sector) {
      errors.sector = t('validation.required')
    }

    if (!form.contract_period) {
      errors.contract_period = t('validation.required')
    }

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const saveScheduleMeeting = async () => {
    try {
      setIsSubmitting(true)
      await api.post(`/settings/poc/save-meeting`, form, { headers: authHeader() })
      await getHighlights(false)
      alertActions.success(t('finalization_poc.form.success_schedule_meeting'))
      setIsSubmitting(false)
    } catch (error) {
      console.log(error)
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (Object.keys(formError).length === 0 && submitted) {
      saveScheduleMeeting()
    } else {
      setSubmitted(false)
    }
  }, [formError, submitted])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  useEffect(() => {
    getHighlights(true);
    document.title = "Befective | " + t('finalization_poc.title');
  }, []);

  return (
    <Box
      bgcolor="#F5F6F9"
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <AlertBox />

      <Header />

      {
        !fetching
          ?
          <Container component={'main'} maxWidth="lg" className={style.containerMain} sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} component={'section'} marginTop={'4px'}>
              <Grid item xs={3}>
                <div className={style.cardHighlights}>
                  <header>
                    <div className={style.iconChart}>
                      <ChartLineUp size={24} />
                    </div>
                    <div>
                      {t('finalization_poc.card.highlights')}
                    </div>
                  </header>
                  <main>
                    <ul>
                      <li>{t('finalization_poc.card.avg_productivity')}: {highlightsData?.avg_performance_percent || 0}%</li>
                      <li>{t('finalization_poc.card.avg_work_time')}: {highlightsData?.avg_working_time || 0}h</li>
                      <li>{t('finalization_poc.card.avg_productivity_time')}: {highlightsData?.avg_performance_time || 0}h</li>
                      <li>{t('finalization_poc.card.day_most_productive')}: {highlightsData?.most_productive_dow?.dow ? getDayWeekV2(highlightsData?.most_productive_dow?.dow, false, t): '----'}</li>
                      <li>{t('finalization_poc.card.activity_main')}: {highlightsData?.most_used_distraction?.exhibition_name || '----'}</li>
                      <li>{t('finalization_poc.card.colab_most_productive')}: {highlightsData?.most_productive_colab?.username || '----'}</li>
                      <li>{t('finalization_poc.card.colab_in_risk')}: {highlightsData?.worst_productive_colab?.username || '----'}</li>
                    </ul>
                  </main>
                  {/* <footer>
                    <button type="button" className={`${style.customButton} ${style.fullWidth}`}>
                      {t('finalization_poc.card.btn_download_report_full')}                      
                    </button>
                  </footer> */}
                </div>
              </Grid>
              <Grid item xs={9}>
                {
                  lang === 'pt' && <img className={style.bannerImage} src={BannerFinalizationPT} alt="Banner" />
                }
                {
                  lang === 'en' && <img className={style.bannerImage} src={BannerFinalizationEN} alt="Banner" />
                }
                {
                  lang === 'es' && <img className={style.bannerImage} src={BannerFinalizationES} alt="Banner" />
                }

                <form className={style.formFinalization} onSubmit={handleSubmit}>
                  {
                    !highlightsData.has_meeting_scheduling_poc
                    ?
                    <Grid container spacing={2}>
                      <Grid item xs={12} className={style.formTitle}>
                        <h3>{t('finalization_poc.form.title')}</h3>
                        <p>{t('finalization_poc.form.subtitle')}</p>
                      </Grid>
                      <Grid item xs={9} marginBottom={"8px"}>
                        <div className={style.formGroup}>
                          <label htmlFor="objective">{t('finalization_poc.form.field_objective')}</label>
                          <select className={style.customSelect} name="objective" id="objective" defaultValue={form.objective} onChange={onChangeSelect}>
                            <option value="" disabled>{t('finalization_poc.form.select')}</option>
                            {
                              objectivesOptions.map((item, index) => (
                                <option key={item.key} value={item.key}>{item[lang]}</option>
                              ))
                            }
                          </select>
                          {
                            formError.objective && <span className={style.error}>{formError.objective}</span>
                          }
                        </div>
                      </Grid>
                      <Grid item xs={3} marginBottom={"8px"}>
                        <div className={style.formGroup}>
                          <label htmlFor="colaborador_range">{t('finalization_poc.form.field_colabs')}</label>
                          <select className={style.customSelect} name="colaborador_range" id="colaborador_range" defaultValue={form.colaborador_range} onChange={onChangeSelect}>
                            <option value="" disabled>{t('finalization_poc.form.select')}</option>
                            {
                              colabsOptions.map((item, index) => (
                                <option key={item.key} value={item.key}>{item[lang]}</option>
                              ))
                            }
                          </select>
                          {
                            formError.colaborador_range && <span className={style.error}>{formError.colaborador_range}</span>
                          }
                        </div>
                      </Grid>
                      <Grid item xs={8} marginBottom={"8px"}>
                        <div className={style.formGroup}>
                          <label htmlFor="sector">{t('finalization_poc.form.field_sector')}</label>
                          <select className={style.customSelect} name="sector" id="sector" defaultValue={form.sector} onChange={onChangeSelect}>
                            <option value="" disabled>{t('finalization_poc.form.select')}</option>
                            {
                              sectorsOptions.map((item, index) => (
                                <option key={item.key} value={item.key}>{item[lang]}</option>
                              ))
                            }
                          </select>
                          {
                            formError.sector && <span className={style.error}>{formError.sector}</span>
                          }
                        </div>
                      </Grid>
                      <Grid item xs={4} marginBottom={"8px"}>
                        <div className={style.formGroup}>
                          <label htmlFor="contract_period">{t('finalization_poc.form.field_expected_hiring_period')}</label>
                          <select className={style.customSelect} name="contract_period" id="contract_period" defaultValue={form.contract_period} onChange={onChangeSelect}>
                            <option value="" disabled>{t('finalization_poc.form.select')}</option>
                            {
                              periodsOptions.map((item, index) => (
                                <option key={item.key} value={item.key}>{item[lang]}</option>
                              ))
                            }
                          </select>
                          {
                            formError.contract_period && <span className={style.error}>{formError.contract_period}</span>
                          }
                        </div>
                      </Grid>
                      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }} marginTop={"6px"}>
                        <button className={style.customButton} disabled={isSubmitting}>
                          {
                            isSubmitting
                            ?
                              <CircularProgress size={16} />
                            :
                              t('finalization_poc.form.btn_schedule_meeting')                     
                          }
                        </button>
                      </Grid>
                    </Grid>
                    :
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                          <p className={style.information}>
                            <strong>{t('finalization_poc.information1')}</strong><br/>
                            {t('finalization_poc.information2')}<br/>
                            {t('finalization_poc.information3')} 🚀
                          </p>
                      </Grid>
                    </Grid>
                  }
                </form>
              </Grid>
            </Grid>
          </Container>
          :
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'25vh'}>
            <CircularProgress />
          </Box>
      }
    </Box >
  )
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FinalizationPocPage));