import { Backdrop, IconButton, Modal, Slide, makeStyles } from '@material-ui/core';
import { AttachFile, Brightness2, Brightness5, Close, Delete } from '@material-ui/icons';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './ModalPreviewMoodGUI.module.scss';
import IconLogoBefective from '../_icons/IconLogoBefective';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export function ModalPreviewMoodGUI({
  openModalPreviewMoodGUI,
  handleCloseModalPreviewMoodGUI,
  data
}) {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [mode, setMode] = useState('dark');
  

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModalPreviewMoodGUI}
      onClose={handleCloseModalPreviewMoodGUI}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: '#205B6F', opacity: 0.8 }
      }}
      sx={{ border: 'none' }}
      style={{ outline: 0 }}
    >
      <Slide direction="down" in={openModalPreviewMoodGUI} style={{ outline: 0 }} mountOnEnter unmountOnExit>
        <Box
          display="flex"
          bgcolor="#EEEEEE"
          flexDirection="column"
          zIndex={2}
          py="0px"
          px="0px"
          borderRadius="24px"
          width={560}
          component="div"
          noValidate
          autoComplete='off'
        >
          <header className={`${style.headerModal} ${mode === 'dark' ? style.dark : ''}`}>
              <div className={style.boxIconTitle}>
                <IconLogoBefective />
                <span className={mode === 'dark' ? style.dark : ''}>{t('moods_settings.modal_preview.title')}</span>
              </div>
              <IconButton aria-label="delete" onClick={() => setMode(mode === 'light' ? 'dark' : 'light')} color="primary">
                {
                  mode === 'light'
                    ?
                    <Brightness2 fontSize="small" />
                    :
                    <Brightness5 fontSize="small" />
                }
              </IconButton>
          </header>
          <main className={`${style.mainModal} ${mode === 'dark' ? style.dark : ''}`}>
            <h6>
              {data?.question}
            </h6>
            <p>
              {data?.description}
            </p>
            <ul>
              {
                data?.moods_options?.map((item, index) => (
                  <li key={`mood_option_item_${index}`} className={`${style.moodItem} ${mode === 'dark' ? style.hoverDark : style.hoverLight}`}>
                    {item.img}
                  </li>
                ))
              }
            </ul>
          </main>
        </Box>
      </Slide>
    </Modal>
  )
}
