import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import MoodFull from '../_components/reports/mood/MoodFull'
import MoodSkeleton from '../_components/reports/mood/MoodFullSkeleton'

import { useTranslation } from "react-i18next";
import Moment from 'moment';

import { alertActions, goalsActions, shareActions, statsActions } from '../_actions';

import ShareModal from '../_components/_shared/ShareModal';
import { authHeader } from '../_helpers';
import { api } from '../_helpers/api';

function MoodPage({alertActions, ...props}) {
	const { t, i18n } = useTranslation('common');
	const [isLoading, setIsLoading] = useState(true)
	const [moodsData, setMoodsData] = useState([])
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [count, setCount] = useState(10);
  const [pageCountRows, setPageCountRows] = useState(0);
	const [pageIndex, setPageIndex] = useState(0);
	const [pageSize, setPageSize] = useState(localStorage.getItem('pageSizeStorage') || 20);

	let filter = props.filter || {};

	function closeShareModal() {
		shareActions.closeModal()
	}

	useEffect(() => {
		document.title = 'Befective | ' + t('menu.reports') + ' | ' + t('menu.mood');
	});

	async function getMoodReports({isLoading = true, pageIndex = 0, pageSize = localStorage.getItem('pageSizeStorage') || 20}) {
		try {
			
			if(isLoading){
				setIsLoading(true)
			}

			let date_ref = Moment(new Date()).format('YYYY-MM-DD');
			let teams = null;
			let teammates = null;

			if (filter?.singleDate) {
				date_ref = filter.singleDate;
			}

			if (filter.teams) {
				teams = filter.teams.reduce((acc, item) => {
					return acc + String(item.id) + ','
				}, '').slice(0, -1);
			}

			if (filter.teammates) {
				teammates = filter.teammates.reduce((acc, item) => {
					return acc + String(item.id) + ','
				}, '').slice(0, -1);
			}


			let url = `/report/mood?page=${pageIndex}&per_page=${pageSize}`

			if (teams !== null) {
				url += `&group_ids=${teams}`
			}

			if (teammates !== null) {
				url += `&teammate_ids=${teammates}`
			}

			const response = await api.get(url + `&date_ref=${date_ref}`, {
				headers: authHeader()
			})
			setMoodsData(response.data.moodsData)
			setStartDate(response.data.date_start.split('-')[2])
			setEndDate(response.data.date_end)			
			setCount(Math.ceil(parseInt(response.data.total) / pageSize))
			setPageCountRows(parseInt(response.data.total))
			setPageIndex(pageIndex)
			setPageSize(pageSize)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log(error.response)
			//alertActions.error(t('mood.error.get_moods'))
		}
	}

	useEffect(() => {
			getMoodReports({isLoading: true, pageIndex: pageIndex, pageSize: pageSize || localStorage.getItem('pageSizeStorage') || 20})
	}, [filter.teams, filter.teammates, filter.singleDate])

	return (
		<React.Fragment>
			<section className="mainContent">
				<Toolbar />
				<Filterbar filterWhen="single" filterWho="multi" />
				{/* PAGE STARTS */}
				<div className="mainPage">
					<div className="column single">
						{isLoading ? (
							<MoodSkeleton />
						) : (
							<MoodFull 
								moodsData={moodsData}
								startDate={startDate}
								endDate={endDate}
								filter={filter}
								count={count}
								pageCountRows={pageCountRows}
								getMoodData={getMoodReports}
							/>
						)}
					</div>


				</div>
				{/* PAGE ENDS */}
			</section>

			<div 
				className={'overlay' + (props.share.modal ? ' open' : '')} 
				onClick={() => {
					closeShareModal()
				}}
			></div>

			<section 
				className={'modal' + (props.share.modal ? ' open' : '')}
			>
				<ShareModal reports='mood' filters={filter} />
			</section>
		</React.Fragment>
	)
};

function mapDispatchToProps(dispatch) {

	return {
		goalsActions: bindActionCreators(goalsActions, dispatch),
		statsActions: bindActionCreators(statsActions, dispatch),
		shareActions: bindActionCreators(shareActions, dispatch),
		alertActions: bindActionCreators(alertActions, dispatch)
	}
}


function mapStateToProps(state) {

	return {
		loading: state.goals.loading,
		filter: state.filter,
		share: state.share
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(MoodPage)
