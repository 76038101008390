import React from 'react'
import { Drawer, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next';
import styles from './InfoHelpDrawer.module.scss'

export default function InfoHelpDrawer({
  openDrawer,
  closeDrawer
}) {

  const { t, i18n } = useTranslation("common");

  return (
    <Drawer anchor='right' open={openDrawer} onClose={closeDrawer}>
      <div className={styles.boxIconClose}>
        <IconButton aria-label="close" onClick={closeDrawer}>
          <Close fontSize="medium" />
        </IconButton>
      </div>

      <main className={styles.mainDrawer}>
        <h5>{t("report_value_hour.drawer_help.title1")}</h5>
        <p>{t("report_value_hour.drawer_help.text1")}</p>
        <div className={styles.boxFormula}>
          <div>
            {t("report_value_hour.drawer_help.value_hour")} =
          </div>
          <div>
            {t("report_value_hour.drawer_help.salary")}
            <div className={styles.line}></div>
            {t("report_value_hour.drawer_help.monthly_working_day")}
          </div>
        </div>
        
        <h5>{t("report_value_hour.drawer_help.example")}</h5>
        <p>{t("report_value_hour.drawer_help.salary")}: <span>{t("report_value_hour.drawer_help.value_salary")} </span>/ {t("report_value_hour.drawer_help.monthly_working_day")}: <span>{t("report_value_hour.drawer_help.value_monthly_working_day")}</span></p>
        <div className={styles.boxFormula}>
          <div>
            {t("report_value_hour.drawer_help.value_hour")} =
          </div>
          <div>
            3000
            <div className={styles.line}></div>
            200
          </div>
          <div>
            = {t("report_value_hour.drawer_help.value_result")}
          </div>
        </div>

        <h5>{t("report_value_hour.drawer_help.important")}</h5>
        <p className={styles.mbMedium}>{t("report_value_hour.drawer_help.important_text1")}</p>
        <p className={styles.mbMedium}>{t("report_value_hour.drawer_help.important_text2")}</p>
        <p>{t("report_value_hour.drawer_help.important_text3")}</p>
      </main>
    </Drawer>
  )
}
