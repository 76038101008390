import MoodVeryBad from '../../public/images/mood1_very_bad.png'
import MoodBad from '../../public/images/mood2_bad.png'
import MoodOk from '../../public/images/mood3_ok.png'
import MoodGood from '../../public/images/mood4_good.png'
import MoodVeryGood from '../../public/images/mood5_very_good.png'

export function getMoodById(id = null) {
  const moods = {
    1: {
      key: 'very_bad',
      img: MoodVeryBad,
      color: '#FF7A90'
    },
    2: {
      key: 'bad',
      img: MoodBad,
      color: '#FF9B1C'
    },
    3: {
      key: 'ok',
      img: MoodOk,
      color: '#FFC94B'
    },
    4: {
      key: 'good',
      img: MoodGood,
      color: '#44D163'
    },
    5: {
      key: 'very_good',
      img: MoodVeryGood,
      color: '#12B48E'
    },
  }

  if(id === null || typeof id !== 'number' || (id < 1 || id > 5)) return moods[3];

  return moods[parseInt(id)]
  
}
