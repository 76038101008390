import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, teammatesActions, usersActions } from '../../_actions';
import Toolbar from '../../_components/_shared/Toolbar';
import style from './styles.module.scss';
import { api } from '../../_helpers/api';
import { authHeader } from '../../_helpers';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import UsersTabComponent from '../../_components/users_settings/UsersTabComponent';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import FormAnnotationsCreatePT from '../../_components/users_settings/annotations/FormAnnotationsCreatePT';
import FormAnnotationsPreviewPT from '../../_components/users_settings/annotations/FormAnnotationsPreviewPT';
import { Box } from '@mui/material';
import Header from '../../_components/switch_page/Header';


function UsersSettingsAnnotationsPage(props) {
  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions } = props
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    id: null,
    full_name: "",
    office: "",
    text_body: ""
  });
  const [showForm, setShowForm] = useState(localStorage.getItem('showForm') === 'true' ? true : false);
  const { id } = useParams();  
  const lang = userDetails && userDetails.language ? userDetails.language : i18n.language;

  const getUserAnnotationsData = async (withLoading=true) => {
    try {
      if(withLoading){
        setLoading(true)
      }
      const { data } = await api.get(`/users/general-notes?machine_users_id=${id}`, { headers: authHeader() })
      if(data){
        setFormData(data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const toogleShowForm = () => {
    if (!showForm) {
      localStorage.setItem('showForm', true)
    }else{
      localStorage.removeItem('showForm')
    }
    setShowForm(!showForm)
  }

  useEffect(() => {

    if (id) {
      getUserAnnotationsData()
    } else {
      setLoading(false)
    }
    
    document.title = `${t('menu.users')} | ${t('settings.users.tabs.annotations')}`

  }, [id])

  return (
    <Box bgcolor="#F5F6F9" width="100%" display="flex" flexDirection="column">
      <Header toBackUrl={"/global-settings/users"} />
      <section className="mainContent productivity">

        {/* <Toolbar /> */}

        <UsersTabComponent currentActive="annotations" userId={id} userDetails={userDetails}/>

        {/* PAGE STARTS */}
        <div className={style.containerPage}>
          {
            !loading 
            ? 
              (
                showForm
                ? <FormAnnotationsCreatePT
                    formData={formData} 
                    getFormData={getUserAnnotationsData} 
                    toogleShowForm={toogleShowForm}
                    isEditing={showForm}
                    lang={lang}
                    userId={id}
                    alertActions={alertActions}
                  />
                : <FormAnnotationsPreviewPT
                    formData={formData} 
                    toogleShowForm={toogleShowForm}  
                    isEditing={showForm}
                    isExistUserId={true}
                    userId={id}
                  />
              )
            : 
            <GlobalSkeleton totalTables={1} totalRowsStats={0} padding={"0px"} marginTopTable={"0px"}/>
          }
        </div>
        {/* PAGE ENDS */}

      </section>
    </Box>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(UsersSettingsAnnotationsPage)