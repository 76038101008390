import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader, befectiveFormatDate } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import { CardsHeaderVacation } from '../../_components/reports/vacation/CardsHeader';
import { CardsHeaderBoards } from '../../_components/project_management/report_boards/CardsHeader';
import EnhancedTable from '../../_components/_shared/EnhancedTable';
import moment from 'moment';
import CustomAvatarGroup from '../../_components/_shared/CustomAvatarGroup';


function ProjectManagementReportsBoardsPage(props) {
  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, filter } = props

  const [fetching, setFetching] = useState(true);
  const [dataBoards, setDataBoards] = useState({
    stats: {
      total_boards: 0,
      done_boards: 0,
      pending_boards: 0,
      overdue_boards: 0
    },
    data: []
  });

  async function getDataBoards(isLoading, dateRef = null) {
    try {

      if (isLoading) {
        setFetching(true)
      }

      const queryFilterYear = dateRef ? `?year=${moment(dateRef).year()}` : ''

      const { data } = await api.get(`/project-management/report/boards${queryFilterYear}`, {
        headers: authHeader()
      })

      setDataBoards(data)
      setFetching(false)

    } catch (error) {
      console.log(error)
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t('project_management.reports.boards.table.col_board'),
        accessor: 'board'
      },
      {
        Header: t('project_management.reports.boards.table.col_workspace'),
        accessor: 'workspace',
        Cell: ({ value }) => value || '----'
      },
      {
        Header: t('project_management.reports.boards.table.col_responsible'),
        accessor: 'responsible'	
      },
      {
        Header: t('project_management.reports.boards.table.col_members'),
        accessor: 'members',	
        Cell: ({ value }) => {
          return value && value.length > 0 ? (
            <CustomAvatarGroup avatars={value} max={4}/>
          ) : (
            "----"
          );
        },
      },
      {
        Header: t('project_management.reports.boards.table.col_status'),
        accessor: 'status',	
        Cell: ({ value }) => {
          return <span className={style[value]}>{t(`project_management.reports.boards.${value}`)}</span>
        }	
      },
      {
        Header: t('project_management.reports.boards.table.col_deadline'),
        accessor: 'deadline',
        Cell: ({ value, row}) => {
          const {status} = row.original
          return <span className={style[status]}>{befectiveFormatDate(value, i18n.language)}</span>
        }	
      },
      {
        Header: t('project_management.reports.boards.table.col_progress'),
        accessor: 'progress',
        style: { width: '0px', textAlign: 'center' },
        Cell: ({ value, row }) => {
          const {status} = row.original
          return <span className={style[status]}>{`${value}%`}</span>
        }
      },
    ];
  }, []);

  useEffect(() => {
    const date_ref = filter.singleDate ? filter.singleDate : moment(new Date()).format("YYYY-MM-DD");
    getDataBoards(true, date_ref)
  }, [filter.singleDate])

  useEffect(() => {
    document.title = 'Befective | ' + t('breadcrub.project_management.boards');
  }, [])

  return (
    <>

      <section className="mainContent">

        <Toolbar />

        <Filterbar
          filterYears={"single"}
          filterWho={"disabled"}
          filterWhen={"disabled"}
          filterWhat={"disabled"}
          maxDate={new Date()}
        />

        <div className={style.containerPage}>
          {
            !fetching
              ?
              <>
                <CardsHeaderBoards alertActions={alertActions} userDetails={userDetails} stats={dataBoards?.stats} />
                <EnhancedTable
                  columns={columns}
                  data={dataBoards?.data}
                  title={t('project_management.reports.boards.label')}
                  enableSearch={true}
                  fontSize="12px"
                />
              </>
              :
              <GlobalSkeleton totalRowsStats={1} totalTables={1} heightTable={600} />
          }

        </div>
      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagementReportsBoardsPage)