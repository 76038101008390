import React, { useState, useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import moment from "moment";
import { activitiesActions, alertActions, filterActions } from "../../_actions";
import { authHeader, secondsToHm } from "../../_helpers";
import { IconButton, Slide, Tooltip, Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import IconClose from "../_icons/IconClose";
import Backdrop from "@material-ui/core/Backdrop";
import { api } from "../../_helpers/api";
import { CircularProgress } from "@mui/material";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import allLocales from "@fullcalendar/core/locales-all";
import IconInfo from "../_icons/IconInfo";
import styles from "./InactivityFull.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  rootTab: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    width: "37ch",
  },
  modal: {
    "&:focus": {
      outline: "none",
    },
    outline: "none",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "400px",
    outline: "none",
    borderRadius: "8px",
  },
}));

function InactivityCalendar(props) {
  const { t, i18n } = useTranslation("common");
  const classes = useStyles();
  const {activities, activitiesActions, userDetails, alertActions} = props;
  const [inactivitiesData, setInactivitiesData] = useState([]);
  const [currentTrackedEdit, setCurrentTrackedEdit] = useState(null);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [label, setLabel] = useState(null);
  const [labels, setLabels] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const calendarRef = useRef(null);

  const closeModalEdit = () => {
    setOpenModalEdit(false);
    setCurrentTrackedEdit(null);
    setLabel(null);
  };

  const changeLabel = (event) => {
    setLabel(
      labels?.find(
        (item) => item?.outdoor_id === parseInt(event.target.value)
      )
    );
  };
  console.log(label, currentTrackedEdit)

  const editTracked = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitting(true);

      const res = await api.put(
        `/stats/my-productivity/inactivity`,
        {
          agent_collector_id: currentTrackedEdit?.id,
          outdoor_id: label?.outdoor_id,
          efficiency_id: label?.efficiency_id,
          category_id: label?.category_id,
          old_label: currentTrackedEdit.exhibitionName,
          old_efficiency: currentTrackedEdit.efficiencyName,
          new_label: label.exhibition_name,
          new_efficiency: label.efficiency_name
        },
        { headers: authHeader() }
      );
      setIsSubmitting(false);
      closeModalEdit();
      setIsUpdated(true);
      alertActions.success(t('activities.success.update_closed'))
    } catch (error) {
      setIsSubmitting(false);
      console.log(error.response);
      //props.alertActions.error(t('activities.error.update_closed'))
    }
  };

  // a custom render function
  function renderEventContent(eventInfo) {
    const { id, start, end, title } = eventInfo.event;
    const eventFinder = inactivitiesData.find(
      (item) => item.id == id
    )
    const titleFull = `${title} | ${moment(eventInfo.event.start).format('HH:mm')} - ${moment(eventInfo.event.end).format('HH:mm')} | ${secondsToHm(moment(end).diff(moment(start),"seconds"))}`
    return (
        <div className={`${styles.event} ${styles[eventFinder?.efficiencyName || 'neutral']}`}>
          {
            titleFull.length > 27 ?
              <Tooltip
                title={titleFull}
              >
                <span>{titleFull.slice(0, 27) + "..."}</span>
              </Tooltip>
              :
              <span>{titleFull}</span>
          }
        </div>
    );
  }

  const handleEventClick = (clickInfo) => {
    clickInfo.jsEvent.preventDefault();

    const {trackedId, efficiencyName, exhibitionName} = inactivitiesData.find(item => item.id == clickInfo.event.id);

    setCurrentTrackedEdit({
      id: clickInfo.event.id,
      trackedId: trackedId,
      efficiencyName: efficiencyName,
      exhibitionName: exhibitionName,
      title: clickInfo.event.title,
      duration: secondsToHm(
        moment(clickInfo.event.end).diff(
          moment(clickInfo.event.start),
          "seconds"
        )
      ),
      timeStart: moment(clickInfo.event.start).format("HH:mm:ss"),
      timeEnd: moment(clickInfo.event.end).format("HH:mm:ss"),
      datetimeStart: clickInfo.event.start,
      datetimeEnd: clickInfo.event.end
    });
    setOpenModalEdit(true);
  };

  const getCalendarData = useCallback(
    async (fetchInfo, successCallback, failureCallback) => {
      try {
        const start_date = moment(fetchInfo.start).format("YYYY-MM-DD");
        const end_date = moment(fetchInfo.end).format("YYYY-MM-DD");
        const response = await api.get(
          `/stats/my-productivity/inactivity?start_date=${start_date}&end_date=${end_date}`,
          {
            headers: authHeader(),
          }
        );

        setInactivitiesData(response.data.rows);
        
        if(labels.length === 0){
          setLabels(response.data.labels);
        }
        successCallback(
          response.data.rows.map((event) => ({
            id: event.id,
            trackedId: event.trackedId,
            title: event.exhibitionName,
            start: event.datetimeStart,
            end: event.datetimeEnd,
            efficiency: event.efficiencyName
          }))
        );
      } catch (error) {
        console.log(error);
        failureCallback(error);
      }
    },
    [] // Dependências necessárias
  );

  useEffect(() => {
    if (isUpdated) {
      // Atualiza os eventos do calendário
      const calendarApi = calendarRef.current?.getApi(); // Obtém a API do FullCalendar
      if (calendarApi) {
        calendarApi.refetchEvents();
        setIsUpdated(false); // Reseta o estado
      }
    }
  }, [isUpdated]);

  return (
    <div className="box noBg w100">
      <div id="containerCalendarInactivities">
        <FullCalendar
          ref={calendarRef}
          key="inactivitiesCalendar"
          locales={allLocales}
          locale={i18n.language}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{ left: "title", right: "today prev,next" }}
          height={680}
          events={getCalendarData}
          initialView="dayGridMonth"
          editable={false}
          selectable={false}
          selectMirror={false}
          dayMaxEvents={true}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          />

      </div>
      {currentTrackedEdit && (
        <Modal
          disableEnforceFocus={true}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModalEdit}
          onClose={closeModalEdit}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          sx={{ border: "none" }}
          style={{ outline: 0 }}
        >
          <Slide
            direction="down"
            in={openModalEdit}
            style={{ outline: 0 }}
            mountOnEnter
            unmountOnExit
          >
            <div className={"containerModalEditTracked"}>
              <button onClick={closeModalEdit} className="close">
                <IconClose />
              </button>

              <form onSubmit={editTracked}>
                <main>
                  <h6 className={styles.titleForm}>{`${t('colab_page.activities.msg1')} `} <strong>{currentTrackedEdit?.title}</strong> {`${t('colab_page.activities.from')} ${currentTrackedEdit?.timeStart} ${t('colab_page.activities.to')} ${currentTrackedEdit?.timeEnd} ${t('colab_page.activities.with_duration')} ${currentTrackedEdit?.duration}`}</h6>
                  <div className="fieldset">
                    <div className="col">
                      <label htmlFor="label">
                        {t("colab_page.activities.label_question")}
                      </label>
                      <select
                        name="label"
                        id="label"
                        onChange={changeLabel}
                        required
                        value={label?.outdoor_id || currentTrackedEdit?.trackedId || ""}
                      >
                        <option value="" disabled hidden>
                          {t("activities.label_select")}
                        </option>
                        {labels?.length > 0 &&
                          labels.map((label) => (
                            <option
                              key={label?.outdoor_id}
                              value={label?.outdoor_id}
                              className={styles[`color_${label?.efficiency_name || "neutral"}`]}
                            >
                              {label?.exhibition_name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <footer>
                    <div className="buttonActionsGroup">
                      <button
                        className="btnCancel"
                        type="button"
                        onClick={closeModalEdit}
                      >
                        {t("button.cancel")}
                      </button>
                      <button
                        type="submit"
                        disabled={isSubmitting || label === null || (label && label.outdoor_id === currentTrackedEdit?.trackedId)}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={15} />
                        ) : (
                          t("button.save")
                        )}
                      </button>
                    </div>
                  </footer>
                </main>
              </form>
            </div>
          </Slide>
        </Modal>
      )}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    activitiesActions: bindActionCreators(activitiesActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    activities: state.activities,
    filter: state.filter,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InactivityCalendar);
