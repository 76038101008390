import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { List } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { history } from '../../_helpers';
import './MenuSwitch.scss';

export function MenuOptionsExtra() {
  const { t } = useTranslation('common');

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button className={'buttonMenuOptionsExtra'} onClick={handleClick}>
        <List size={22} />
      </button>

      <Menu
        anchorEl={anchorEl}
        id="switchBefective"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
          }
        }}
        getContentAnchorEl={null}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => history.push('/productive/productivity/activities')}
        >
          <span>
            {t('colab_page.activities')}
          </span>
        </MenuItem>
        <MenuItem
          onClick={() => history.push('/productive/productivity/inactivity')}
        >
          <span>
            {t('colab_page.inactivity')}
          </span>
        </MenuItem>
      </Menu>
    </>
  )
}
