import React, { useEffect, useState } from 'react'
import { Drawer, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useTranslation } from 'react-i18next';
import { api } from '../../../_helpers/api';
import { authHeader, befectiveFormatDate, getAvatarUrl, getMonths } from '../../../_helpers';
import styles from './MessageAlertDrawer.module.scss'
import { ArrowRight, NotePencil } from 'phosphor-react';
import IconOutdoor from '../../_icons/IconOutdoor';
import MessageAlertDrawerSkeleton from './MessageAlertDrawerSkeleton';
import { Avatar } from '@mui/material';
import moment from 'moment';

export default function MessageAlertDrawer({
  currentMonthYear,
  currentData,
  openDrawer,
  closeDrawer
}) {

  const { t, i18n } = useTranslation("common");
  const [dataDrawer, setDataDrawer] = useState({
    month: moment().month(),
    year: moment().year(),
    registers: []
  });
  const [loading, setLoading] = useState(true);

  const getAlertMessages = async ({month_ref, user, group}) => {
    try {
      setLoading(true)

      const queryUser = user ? `&users=${user}` : ''
      const queryGroup = group ? `&groups=${group}` : ''

      const response = await api.get(`/report/mood/alert-messages?month_ref=${month_ref}${queryUser}${queryGroup}`, {
        headers: authHeader(),
      })

      const [year, month] = month_ref.split('-')

      setDataDrawer({
        month: parseInt(month),
        year: parseInt(year),
        ...response.data
      })

      setLoading(false)
      
    } catch (error) {
      //alertActions.error(error)
      console.log(error)
      setLoading(false)
    }
  }
  useEffect(() => {
    if(openDrawer) {
      getAlertMessages({
        user: currentData.user_id || null,
        group: currentData.group_id || null,
        month_ref: currentMonthYear || moment().format('YYYY-MM')
      })
    }
  }, [openDrawer])

  return (
    <Drawer anchor='right' open={openDrawer} onClose={closeDrawer}>
      <div className={styles.boxIconClose}>
        <IconButton aria-label="close" onClick={closeDrawer}>
          <Close fontSize="medium" />
        </IconButton>
      </div>

      <div className={styles.containerDrawer}>
        <header>
          <h2 className={styles.titleDrawer}>
            {`${t('reports_mood.messages_to')} ${currentData?.label}`}
          </h2>
        </header>

        <main className={styles.mainDrawerContent}>
            {
              !loading
              ?
                <div className={styles.historyContainer}>
                  <div className={styles.boxMonthYear}>{`${getMonths(dataDrawer.month - 1, t)} ${dataDrawer.year}`}</div>
                  {
                    (dataDrawer.registers && dataDrawer.registers.length > 0)
                    ?
                    dataDrawer.registers.map((reg, index) => (
                      <div key={`register-${index}`} className={styles.boxRegister}>
                        <div className={styles.headInfoBox}>
                          <Avatar
                              alt={reg.username_sender}
                              src={
                                getAvatarUrl({
                                  name: reg.username_sender || "-----",
                                },
                                  "187DFF"
                                )
                              }
                              style={{ width: 20, height: 20 }}
                              title={reg.username_sender || "-----"}
                            />
                            <span>{`${t('reports_mood.sent_to')} ${reg?.username_receiver}: ${befectiveFormatDate(reg.moment, i18n.language, 'T')}`}</span>
                        </div>
                        <div className={styles.message}>
                          {reg.message}
                        </div>
                      </div>                      
                    ))
                    :
                    <div className={styles.noneRegister}>
                      {t('reports_mood.no_alert_messages')}
                    </div>
                  }
                </div>             
              :
              <MessageAlertDrawerSkeleton/>
            }
        </main>
      </div>
    </Drawer>
  )
}
