import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import "react-datepicker/dist/react-datepicker.css";

import Moment from 'moment';

import { filterActions, statsActions, teammatesActions, teamsActions } from '../../../_actions';

import { useTranslation } from "react-i18next";
import useOutsideClick from "../../../_helpers/useOutsideClick";


import IconCheck from '../../_icons/IconCheck';
import IconClear from '../../_icons/IconClear';

import IconTeam from '../../_icons/IconTeam';
import IconTeammate from '../../_icons/IconTeammate';

function WhoSingleTeam(props) {
    const { t, i18n } = useTranslation('common');

    const { filterActions } = props;
    const { userDetails } = props

    let teams = props.teams || [];
    let teammates = props.teammates || [];
    let filter = props.filter || {};

    let limit = props.limit || null;
    let whoStyle = props.whoStyle || 'multi';


    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(new Date());

    const [whenFilter, setShowWhenFilter] = useState(true);
    let whoFilter = props.whoFilter || true;

    if (limit) {

        if (filter.date) {
            if (new Date(Moment(filter.date.startDate).add(+31, 'd')) < new Date()) {
                setMaxDate(new Date(Moment(filter.date.startDate).add(+31, 'd')));
            } else {
                setMaxDate(new Date());
            }

            setMinDate(new Date(Moment(filter.date.endDate).add(-31, 'd')));


        } else {
            setMinDate(new Date(Moment().add(-31, 'd')));
        }

    }

    const { teamsActions } = props;
    const { teammatesActions } = props;

    let FilteredTeamsHTML = [];
    let FilteredTeammatesHTML = [];

    const [ManualWhenValue, setManualWhenValue] = useState('');

    const [HelperWhoTeams, setHelperWhoTeams] = useState(0);
    const [HelperWhoTeammates, setHelperWhoTeammates] = useState(0);

    const refFilter = useRef();

    const [filterWho, setWho] = useState(false);

    const [selectedTeams, setSelectedTeams] = useState({});
    const [selectedTeammates, setSelectedTeammates] = useState({});

    const [filterWhoValue, setFilterWhoValue] = useState('');


    const [InitialTeamsHTML, setInitialTeamsHTML] = useState('');
    const [InitialTeammatesHTML, setInitialTeammatesHTML] = useState('');
    const [teamsHTML, setTeamsHTML] = useState('');
    const [teammatesHTML, setTeammatesHTML] = useState('');


    const changeFilterWho = (event) => {

        setFilterWhoValue(event.target.value);

        let value = event.target.value.toLowerCase();

        if (value.length > 2) {

            for (let Team of teams) {

                let testName = Team.name.toLowerCase();

                if (testName.includes(value)) {

                    let TeamClass = 'fTeam';

                    if (selectedTeams && selectedTeams[Team.id]) {
                        TeamClass = 'fTeam active';
                    }

                    FilteredTeamsHTML.push(<li id={"fTeam" + Team.id} className={TeamClass} onClick={() => {
                        setWhoFilter(Team.id, Team.name, 'teams')
                    }} key={"Teams-" + Team.id}>
                        <div className="check"><IconCheck /></div>
                        {Team.name}</li>)

                }
            }

            setTeamsHTML(FilteredTeamsHTML);


        } else {
            setTeamsHTML(InitialTeamsHTML);

        }
    };


    useOutsideClick(refFilter, target => {

        if (FilteredTeamsHTML.length == 0) {
            setTeamsHTML(InitialTeamsHTML);
        }
        if (FilteredTeammatesHTML.length == 0) {
            setTeammatesHTML(InitialTeammatesHTML);
        }

        if (filterWho) {

            if (refFilter && !target.includes("react-datepicker") && !target.includes("column") && !target.includes("who") && !target.includes("input")) {
                if (filterWho) {
                    setWho(false);
                }

            }

        }
    });

    teams = teams.sort((a, b) => a.name.localeCompare(b.name))


    function setTeamsList() {

        let totalTeams = 0;

        if (props.teams) {

            let newSelected = {}

            if (props.filter.teams) {
                for (let item of props.filter.teams) {
                    newSelected[item.id] = true;
                }
            }


            let tempTeams = []

            for (let t0 of teams) {


                if (!t0.ancestry) {
                    let t0Class = 'fTeam';

                    if ((selectedTeams || newSelected) && (selectedTeams[t0.id] || newSelected[t0.id])) {
                        t0Class = 'fTeam active';
                        totalTeams++;
                    }

                    tempTeams.push(<li id={"fTeam" + t0.id} className={t0Class} onClick={() => {
                        setWhoFilter(t0.id, t0.name, 'teams')
                    }} key={"Teams-" + t0.id}>
                        <div className="check"><IconCheck/></div>
                        {t0.name}</li>)

                    let p0 = t0.id;
                    for (let t1 of teams) {


                        if (t1.ancestry == p0) {
                            let t1Class = 'fTeam';
                            if ((selectedTeams || newSelected) && (selectedTeams[t1.id] || newSelected[t1.id])) {
                                t1Class = 'fTeam active';
                                totalTeams++;
                            }
                            tempTeams.push(<li id={"fTeam" + t1.id} className={t1Class} onClick={() => {
                                setWhoFilter(t1.id, t1.name, 'teams')
                            }} key={"Teams-" + t1.id}>
                                &nbsp;&nbsp;
                                <div className="check"><IconCheck/></div>
                                {t1.name}</li>)

                            let p1 = t1.id;
                            for (let t2 of teams) {
                                if (t2.ancestry == p1) {

                                    let t2Class = 'fTeam';

                                    if ((selectedTeams || newSelected) && (selectedTeams[t2.id] || newSelected[t2.id])) {
                                        t2Class = 'fTeam active';
                                        totalTeams++;
                                    }
                                    tempTeams.push(<li id={"fTeam" + t2.id} className={t2Class} onClick={() => {
                                        setWhoFilter(t2.id, t2.name, 'teams')
                                    }} key={"Teams-" + t2.id}>
                                        &nbsp;&nbsp;
                                        &nbsp;&nbsp;
                                        <div className="check"><IconCheck/></div>
                                        {t2.name}</li>)

                                    let p2 = t2.id;

                                    for (let t3 of teams) {
                                        if (t3.ancestry == p2) {

                                            let t3Class = 'fTeam';

                                            if ((selectedTeams || newSelected) && (selectedTeams[t3.id] || newSelected[t3.id])) {
                                                t3Class = 'fTeam active';
                                                totalTeams++;
                                            }
                                            tempTeams.push(<li id={"fTeam" + t3.id} className={t3Class}
                                                               onClick={() => {
                                                                   setWhoFilter(t3.id, t3.name, 'teams')
                                                               }} key={"Teams-" + t3.id}>
                                                &nbsp;&nbsp;
                                                &nbsp;&nbsp;
                                                &nbsp;&nbsp;
                                                <div className="check"><IconCheck/></div>
                                                {t3.name}</li>)

                                            let p3 = t3.id;

                                            for (let t4 of teams) {
                                                if (t4.ancestry == p3) {

                                                    let t4Class = 'fTeam';

                                                    if ((selectedTeams || newSelected) && (selectedTeams[t4.id] || newSelected[t4.id])) {
                                                        t4Class = 'fTeam active';
                                                        totalTeams++;
                                                    }
                                                    tempTeams.push(<li id={"fTeam" + t4.id} className={t4Class}
                                                                       onClick={() => {
                                                                           setWhoFilter(t4.id, t4.name, 'teams')
                                                                       }} key={"Teams-" + t4.id}>
                                                        &nbsp;&nbsp;
                                                        &nbsp;&nbsp;
                                                        &nbsp;&nbsp;
                                                        &nbsp;&nbsp;
                                                        <div className="check"><IconCheck/></div>
                                                        {t4.name}</li>)

                                                    let p4 = t4.id;

                                                    for (let t5 of teams) {
                                                        if (t5.ancestry == p4) {

                                                            let t5Class = 'fTeam';

                                                            if ((selectedTeams || newSelected) && (selectedTeams[t5.id] || newSelected[t5.id])) {
                                                                t5Class = 'fTeam active';
                                                                totalTeams++;
                                                            }
                                                            tempTeams.push(<li id={"fTeam" + t5.id}
                                                                               className={t5Class}
                                                                               onClick={() => {
                                                                                   setWhoFilter(t5.id, t5.name, 'teams')
                                                                               }} key={"Teams-" + t5.id}>
                                                                &nbsp;&nbsp;
                                                                &nbsp;&nbsp;
                                                                &nbsp;&nbsp;
                                                                &nbsp;&nbsp;
                                                                &nbsp;&nbsp;
                                                                <div className="check"><IconCheck/></div>
                                                                {t5.name}</li>)

                                                            let p5 = t5.id;

                                                            for (let t6 of teams) {
                                                                if (t6.ancestry == p5) {

                                                                    let t6Class = 'fTeam';

                                                                    if ((selectedTeams || newSelected) && (selectedTeams[t6.id] || newSelected[t6.id])) {
                                                                        t6Class = 'fTeam active';
                                                                        totalTeams++;
                                                                    }
                                                                    tempTeams.push(<li id={"fTeam" + t6.id}
                                                                                       className={t6Class}
                                                                                       onClick={() => {
                                                                                           setWhoFilter(t6.id, t6.name, 'teams')
                                                                                       }} key={"Teams-" + t6.id}>
                                                                        &nbsp;&nbsp;
                                                                        &nbsp;&nbsp;
                                                                        &nbsp;&nbsp;
                                                                        &nbsp;&nbsp;
                                                                        &nbsp;&nbsp;
                                                                        &nbsp;&nbsp;
                                                                        <div className="check"><IconCheck/></div>
                                                                        {t6.name}</li>)

                                                                    let p6 = t6.id;
                                                                    for (let t7 of teams) {
                                                                        if (t7.ancestry == p6) {

                                                                            let t7Class = 'fTeam';

                                                                            if ((selectedTeams || newSelected) && (selectedTeams[t7.id] || newSelected[t7.id])) {
                                                                                t7Class = 'fTeam active';
                                                                                totalTeams++;
                                                                            }
                                                                            tempTeams.push(<li id={"fTeam" + t7.id}
                                                                                               className={t7Class}
                                                                                               onClick={() => {
                                                                                                   setWhoFilter(t7.id, t7.name, 'teams')
                                                                                               }}
                                                                                               key={"Teams-" + t7.id}>
                                                                                &nbsp;&nbsp;
                                                                                &nbsp;&nbsp;
                                                                                &nbsp;&nbsp;
                                                                                &nbsp;&nbsp;
                                                                                &nbsp;&nbsp;
                                                                                &nbsp;&nbsp;
                                                                                &nbsp;&nbsp;
                                                                                <div className="check"><IconCheck/>
                                                                                </div>
                                                                                {t7.name}</li>)


                                                                        }
                                                                    }

                                                                }
                                                            }
                                                        }
                                                    }

                                                }
                                            }
                                        }
                                    }
                                }


                            }
                        }


                    }


                }else{
                    if(teams.filter(item => item?.id == t0.ancestry).length === 0){
                        let t0Class = 'fTeam';

                        if ((selectedTeams || newSelected) && (selectedTeams[t0.id] || newSelected[t0.id])) {
                            t0Class = 'fTeam active';
                            totalTeams++;
                        }
    
                        tempTeams.push(<li id={"fTeam" + t0.id} className={t0Class} onClick={() => {
                            setWhoFilter(t0.id, t0.name, 'teams')
                        }} key={"Teams-" + t0.id}>
                            <div className="check"><IconCheck/></div>
                            {t0.name}</li>)
                    }
                    
                }


            }

            setInitialTeamsHTML(tempTeams)
            setTeamsHTML(tempTeams)

        }

        setHelperWhoTeams(totalTeams)
    }

    function clearWho() {


        filterActions.clear("who");
        setHelperWhoTeams(0);
        setWho(false);


        let elemsTeams = document.querySelectorAll(".fTeam");

        [].forEach.call(elemsTeams, function (el) {
            el.classList.remove("active");
        });

        let elemsTeammates = document.querySelectorAll(".fTeammate");

        [].forEach.call(elemsTeammates, function (el) {
            el.classList.remove("active");
        });


    }


    function setWhoFilter(id, name, type) {
        // setWho(false);
        if (type == "teams") {

            filterActions.set({ id }, "teams");


            let element = document.getElementById("fTeam" + id)


            if (element.classList.contains('active')) {
                document.getElementById("fTeam" + id).classList.remove('active');

            } else {
                document.getElementById("fTeam" + id).classList.add('active');
            }

        }


    }


    useEffect(() => {

        setTeamsList()

    }, [props.teams])


    useEffect(() => {

        let nf = 0;

        if (filter.teams) {


            if ((filter.teams && filter.teams.length == 0) || (filter.teammates && filter.teammates.length > 0)) {

                let teammatesToHide = document.getElementsByClassName('fTeammate');

                for (let tm of teammatesToHide) {

                    document.getElementById(tm.id).classList.remove('hide');
                    document.getElementById(tm.id).classList.remove('active');
                }
                //filterActions.clear('teammates')

            }


            let newSelected = {}

            let teammatesTeamToShow = []

            for (let item of filter.teams) {
                teammatesTeamToShow.push(item.id)
                newSelected[item.id] = true;
                nf++;
            }

            setSelectedTeams(newSelected);

            if (filter.teams.length > 0) {

                let teammatesToHide = document.getElementsByClassName('fTeammate');

                for (let tm of teammatesToHide) {

                    document.getElementById(tm.id).classList.add('hide');
                }
            }

            for (let t of teammatesTeamToShow) {

                let teammatesToShow = document.getElementsByClassName('fTeamTeammate-' + t);

                for (let tm of teammatesToShow) {

                    document.getElementById(tm.id).classList.remove('hide');
                }
            }


        }
        setHelperWhoTeams(nf);


    }, [filter.teams])

    useEffect(() => {

        if (props.openWho && props.openWho != '') {


            if (props.filter.teammates && props.filter.teammates.length == 0) {
                setWho(true);

            } else if (props.filter.teammates && props.filter.teammates.length == 1) {
                clearWho();
                filterActions.set(props.filter.teammates[0], "teammates");
            } else {
                clearWho();
                setWho(true);

            }


        }


    }, [props.openWho])

    useEffect(() => {

        if (props.clearWho && props.clearWho != '') {

            clearWho();
        }


    }, [props.clearWho])


    return (

        <div className={filterWho ? 'filter who open' : 'who filter'} ref={refFilter}>
            <div className="holder">
                <span className="label">{t('filter.who')}:</span>
                <span className="value" onClick={() => {
                    setWho(!filterWho);
                }}>
                    {HelperWhoTeams > 0 && (
                        <div className="helper teams">
                            <div className="icon"><IconTeam /></div>
                            {HelperWhoTeams}
                        </div>
                    )}
                    {HelperWhoTeammates > 0 && (
                        <div className={HelperWhoTeams > 0 ? "helper teammates right" : "helper teammates"}>
                            <div className="icon"><IconTeammate /></div>
                            {HelperWhoTeammates}
                        </div>
                    )}
                    {(HelperWhoTeammates == 0 && HelperWhoTeams == 0) && t('filter.all_your_organization')}
                    <i className="arrow"></i></span>
                <input className="input" placeholder={t('filter.search')} value={filterWhoValue}
                    onChange={changeFilterWho} />
                <div className="clear" onClick={() => {
                    clearWho()
                }}><IconClear /></div>
            </div>
            <div className={"comp " + whoStyle} style={{ maxWidth: 'fit-content', minWidth: '300px' }}>
                {(whoStyle == 'multi') &&
                    <div className="column">
                        <div className="title">{t('filter.teams')}</div>
                        <ul>
                            {teamsHTML}
                        </ul>
                    </div>
                }
            </div>
        </div>


    )
};


function mapDispatchToProps(dispatch) {

    return {
        statsActions: bindActionCreators(statsActions, dispatch),
        filterActions: bindActionCreators(filterActions, dispatch),
        teamsActions: bindActionCreators(teamsActions, dispatch),
        teammatesActions: bindActionCreators(teammatesActions, dispatch)
    }
}


function mapStateToProps(state) {

    return {
        teams: state.teams.payload,
        teammates: state.teammates.payload,
        filter: state.filter,
        userDetails: state.authentication.userDetails
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(WhoSingleTeam)
