import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useTranslation, initReactI18next } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { usersActions } from '../../_actions';
import { menuActions } from '../../_actions';

import mainLogo from '../../assets/images/logo.svg';

import IconArrow from '../_icons/IconArrow';
import IconInvitation from '../_icons/IconInvitation';

import IconLogo from '../_icons/IconLogo'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

function MenuBackfective(props) {
  const { t, i18n } = useTranslation('common');

  const { menuActions, usersActions , userDetails } = props;
  const [isActiveReports, setActiveReports] = useState();
  const [isActiveSettings, setActiveSettings] = useState();
  const [isMenuOpen, setMenuOpen] = useState(false);

  let menu = props.menu || [];

  let permissions = props.permissions || [];

  let path = window.location.pathname.split("/") || [];

  function toggleClass(type = null) {

    if(!isMenuOpen){

      toggleMenu('main');
      if (type === "reports") {
        menuActions.set('reports', true);
      }
      if (type === "settings") {
        menuActions.set('settings', true);
      }
    }
    else{

      if (type === "reports") {
        menuActions.set('reports', !isActiveReports);
      }
      else if (type === "settings") {
        menuActions.set('settings', !isActiveSettings);
      }
      else{
        menuActions.set('reports', false);
      }
      
    }

  };

  function toggleMenu(type) {

    if(type == 'main'){
      setMenuOpen(!isMenuOpen);
    }
    else{
      if(isMobile){
        setMenuOpen(false);
      }
    }    

  };

  useEffect(() => {
    if (menu) {
      setActiveReports(menu.reports);
      setActiveSettings(menu.settings);
    }

  }, [props.menu]);

  useEffect(() => {
    let path = window.location.pathname.split("/")

    if (path[1] == "reports") {
      menuActions.set('reports', true);
    }

    if (path[1] == "settings") {
      menuActions.set('settings', true);
    }

  }, []);

  useEffect(() => {
    // Evitar que o token expire por tempo
    setInterval(() => {
        usersActions.refresh(userDetails);
      }, (280*1000));
    // Evitar que o token expire em caso de refresh na página
    setTimeout(() => {
        usersActions.refresh(userDetails);
      }, (15000));
  }, [])


  return (
    <nav className={isMenuOpen ? 'mainMenu backfective open' : 'mainMenu backfective'}>

      <div className="menuToggle" onClick={() => { toggleMenu('main') }}>
        <i className="arrow"></i>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div className="item nopointer">
        <Link to="/productive">
        <div  className="mainLogo">
         <IconLogo />
        </div>
        </Link>
      </div>    
    

      <div className="item" onClick={() => { toggleClass(); toggleMenu() }}>
        <NavLink to="/backfective/invitations">
          <IconInvitation />
          <span>{t('menu.backfective.invitations')}</span>
        </NavLink >
      </div>

      <div className="item" onClick={() => { toggleClass(); toggleMenu() }}>
        <NavLink exact={true} to="/productive">
          <IconArrow />
          <span>{t('menu.backfective.back_to_befective')}</span>
        </NavLink >
      </div>
    
    </nav>

  )
};

function mapDispatchToProps(dispatch) {

  return {
    menuActions: bindActionCreators(menuActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch)
  }
}

function mapStateToProps(state) {

  return {
    menu: state.menu,
    userDetails: state.authentication.userDetails,
    permissions: state.authentication.permissions
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuBackfective));
