import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { shareActions } from "../../_actions";
import IconClose from "../_icons/IconClose";
import Skeleton from "@material-ui/lab/Skeleton";
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

function ShareModal({
    shareActions,
    filters = {},
    reports = "executive",
    showOptionsTypeExportView = false,
    paginationFilters = null,
    ...props
}) {
    const [loadingDoDownload, setLoadingDoDownload] = useState(false);
    const [typeExportView, setTypeExportView] = useState('default')
    const { t, i18n } = useTranslation('common');
    const enableXlsx = reports === 'executive' || reports === 'mood' || false;

    let filter = props.filter;

    const handleRadioChange = (event) => {
        setTypeExportView(event.target.value);
    };

    function closeShareModal() {
        shareActions.closeModal()
        setTypeExportView('default')
    }

    function doDownload(format, type = 'pdf') {
        setLoadingDoDownload(true);
        shareActions.getFile({
            format,
            report: reports,
            filters: ['transactionalReports', 'realtimeColabDetails', 'teammates'].includes(reports) ? filters : filter,
            type,
            paginationFilters,
            setLoadingDoDownload,
            typeExportView
        });
    }

    return (

        <div className={"internal"}>

            <div className="header">
                <div className={"title"}>{t('share.title')}</div>
                
                <button onClick={() => { closeShareModal() }} className="close"><IconClose /></button>
            </div>

            <div className={"content"}>
                <ul className={"download"}>
                    <li><b>Download</b></li>
                    {loadingDoDownload && <li><Skeleton variant="rect" width={90} height={36} /></li>}
                    {loadingDoDownload && <li><Skeleton variant="rect" width={90} height={36} /></li>}
                    {!loadingDoDownload && <li><button onClick={() => { doDownload('xlsx', 'excel') }}>XLSX</button></li>}
                    {!loadingDoDownload && <li><button onClick={() => { doDownload("pdf") }}>PDF</button></li>}
                </ul>
                {
                    showOptionsTypeExportView &&
                    <Box marginTop={'40px'}>
                        <FormControl>
                            <FormLabel id="option-type-export">Tipo de exportação</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="option-type-export"
                                name="typeExportView"
                                value={typeExportView}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="default" control={<Radio />} label={t('export.type_summarized')} />
                                <FormControlLabel value="complete" control={<Radio />} label={t('export.type_complete')} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                }
                <div>

                </div>
            </div>

        </div>

    )

};

function mapStateToProps(state) {

    return {
        filter: state.filter,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        shareActions: bindActionCreators(shareActions, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShareModal));
