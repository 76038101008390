import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader, befectiveFormatDate } from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import moment from 'moment';
import CollectionMonitoringFull from '../../_components/reports/collection_monitoring/CollectionMonitoringFull';
import AgentsInstalledTeamsComponent from './AgentsInstalledTeamsComponent';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ProductiveReportsCollectionMonitoringPage(props) {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const { filter } = props;
  const [typeView, setTypeView] = useState('default');
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState({
    data: [],
    stats: {
      total: 0,
      total_active: 0,
      total_inactive: 0,
    },
  });
  const [activeFilter, setActiveFilter] = useState('all');

  const fetchData = async (isLoading, filter, activeFilter) => {
    if(typeView !== 'default') return
    const teams = filter.teams || null;
    const teammates = filter.teammates || null;
    await getData(isLoading, teams, teammates, activeFilter);
  };

  const getData = async (isLoading, teams, teammates, quick_filter = 'all') => {
    if (isLoading) setFetching(true);
    try {
      const queryTeams = (teams && teams.length > 0) ? `&teams=${teams.map((t) => t.id).join(',')}` : '';
      const queryTeammates = (teammates && teammates.length > 0) ? `&users=${teammates.map((t) => t.id).join(',')}` : '';

      const response = await api.get(
        `/report/inactive/?current_day=${moment().format('YYYY-MM-DD HH:mm:ss')}&quick_filter=${quick_filter}${queryTeams}${queryTeammates}`,
        { headers: authHeader() }
      );

      if (response.status !== 200) throw new Error(t('generic_messages.errors.load'));

      setData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if(filter.teams){
      fetchData(true, filter, activeFilter);
    }
  }, [filter.teams]);

  useEffect(() => {
    if(filter.teammates && filter.teammates.length > 0){
      fetchData(true, filter, activeFilter);
    }
  }, [filter.teammates]);

  useEffect(() => {
    fetchData(true, filter, activeFilter);
  }, [])
  
  return (
    <section className="mainContent">
      <Toolbar />
      <Filterbar
        filterWho={typeView === 'default' ? "multi" : "singleTeamsTESTE"}
        filterWhen="disabled"
        filterWhat="disabled"
        showShareBtn={false}
        enableButtonToggleCollection={true}
        typeViewCollection={typeView}
        setTypeViewCollection={setTypeView}
      />
      <div className={style.containerPage}>
        {!fetching ? (
          (
            typeView === 'default'
            ?
              <CollectionMonitoringFull
                data={data}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
                filter={filter}
                fetchData={fetchData}
              />
            :
              <AgentsInstalledTeamsComponent/>
          )
        ) : (
          <GlobalSkeleton totalRowsStats={0} totalTables={1} heightTable={600} />
        )}
      </div>
    </section>
  );
}


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductiveReportsCollectionMonitoringPage)