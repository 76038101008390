import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import { authHeader } from '../../_helpers';
import { api } from '../../_helpers/api';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import { CardsHeaderAgentsInstalledTeams } from '../../_components/reports/agents_installed_teams/CardsHeader';
import TableReportsAgentsInstalledTeamsPagination from '../../_components/reports/agents_installed_teams/TableReportsAgentsInstalledTeamsPagination';

function ProductiveReportsAgentsInstalledTeamsPage(props) {

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, filter } = props

  const [fetching, setFetching] = useState(true);
  const [dataAgentsInstalled, setDataAgentsInstalled] = useState({
    data: [],
    stats: null
  });
  const [pageCount, setPageCount] = useState(0);
  const [pageCountRows, setPageCountRows] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);


  async function getDataAgentsInstalled(pageIndex, pageSize, isLoading, teams = null) {
    try {

      if (isLoading) {
        setFetching(true)
      }

      const queryTeams = teams ? `&groups=${teams.map(t=>t.id).join(',')}` : ''

      const response = await api.get(`/report/agent-installation-status?page=${(parseInt(pageIndex) + 1)}&per_page=${pageSize}${queryTeams}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      setDataAgentsInstalled(response.data)
      setPageCount(Math.ceil(parseInt(response.data.rowCount) / pageSize))
      setPageCountRows(parseInt(response.data.rowCount))
      setPageSize(pageSize)
      setPageIndex(pageIndex)
      setFetching(false)

    } catch (error) {
      console.log(error)
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t('reports_agents_installed_teams.table.col_team'),
        accessor: 'name'
      },
      {
        Header: t('reports_agents_installed_teams.table.col_instaled'),
        accessor: 'total_installed',
        Cell: ({ value }) => {
          return `${value || "0"}`
        }
      },
      {
        Header: t('reports_agents_installed_teams.table.col_pending'),
        accessor: 'total_not_installed',
        Cell: ({ value }) => {
          return `${value || "0"}`
        }
      },
      {
        Header: t('reports_agents_installed_teams.table.col_total'),
        accessor: 'total_users',
        Cell: ({ value }) => {
          return `${value || "0"}`
        }
      },
      {
        Header: t('reports_agents_installed_teams.table.col_percent_instaled'),
        accessor: 'percent_installed',
        Cell: ({ value }) => {
          return `${value || "0"}%`
        }
      },
    ];
  }, []);


  useEffect(() => {
    getDataAgentsInstalled(0, localStorage.getItem('pageSizeStorage') || 20, true, null)
  }, [])

  const getDataAgentsInstalledFiltered = (pageIndex, pageSize, filter) => {

    let teams = null;
    let loading = false
    if (filter.teams) {
      teams = filter.teams;
      loading = filter.teams.length > 0 ? true : false
    }

    getDataAgentsInstalled(pageIndex, pageSize, loading, teams)
  }

  return (
        
    !fetching
      ?
      <>
        <CardsHeaderAgentsInstalledTeams 
          alertActions={alertActions} 
          userDetails={userDetails} 
          stats={dataAgentsInstalled.stats} />
        <TableReportsAgentsInstalledTeamsPagination
          columns={columns}
          data={dataAgentsInstalled.data}
          count={pageCount}
          filter={filter}
          getData={getDataAgentsInstalledFiltered}
          pageCountRows={pageCountRows}
          alertActions={alertActions}
        />
      </>
      :
      <GlobalSkeleton totalRowsStats={1} totalTables={1} heightTable={600} />

  );
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductiveReportsAgentsInstalledTeamsPage)