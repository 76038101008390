import React from 'react'
import styles from './MessageAlertDrawer.module.scss';
import { Skeleton } from '@mui/material';

export default function MessageAlertDrawerSkeleton() {
  return (
    <div className={styles.historyContainer} >
      <div className={styles.boxMonthYear}>
        <Skeleton variant="rectangular" width={100} height={8} />
      </div>
      {
        [1,2,3].map((reg, index) => (
          <div key={`register-${index}`} className={styles.boxRegister}>
            <div className={styles.headInfoBox}>
              <Skeleton variant='circular' width={20} height={20} style={{marginRight: '10px'}} />
              <span>
                <Skeleton variant="rectangular" width={100} height={7} />
              </span>
            </div>
            <div className={styles.message}>
              <Skeleton variant="rectangular" width={200} height={16} />
            </div>
          </div>                      
        ))
      }
    </div>
  )
}
