import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, CircularProgress, FormControl, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { authHeader, getRangeFilterWhen, secondsToHm, secondsToHours } from '../../../_helpers';
import { api } from '../../../_helpers/api';
import { CalendarCheck, CalendarX, Clock, Sliders, Sun, Timer } from 'phosphor-react';
import Widget36Skeleton from './Widget36Skeleton';
import style from './styles.module.scss';
import { Checkbox, FormControlLabel, FormGroup, Popover } from '@material-ui/core';
import BefectiveCheckbox from '../../_shared/BefectiveCheckbox';


export default function Widget36({
  alertActions,
  currentDate,
  width,
  filters,
  userDetails,
  usersActions
}) {
  const { t, i18n } = useTranslation('common');
  const [data, setData] = useState({
    avg_working_day: null,
    avg_start_time: null,
    best_day_week: null,
    worst_day_week: null,
    best_productive_period: null,
    worst_productive_period: null
  });
  const [fetching, setFetching] = useState(true);

  const lang = userDetails.language || i18n.language

  const [formSettingsPeriod, setFormSettingsPeriod] = useState({
    morning: true,
    afternoon: true,
    evening: true
  });

  const [loading, setLoading] = useState(false);
  const [anchorElPopover, setAnchorElPopover] = useState(null);

  const open = Boolean(anchorElPopover);
  const id = open ? 'simple-popover' : undefined;
  
  const handleOpenPopover = (event) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };

  const getData = async ({ filterStartDate, filterEndDate, filterTeams, filterTeammates}) => {
    try {
      setFetching(true)
      const response = await api.get(`/stats/executive/five-stats?current_day=${currentDate.format('YYYY-MM-DD')}${filterStartDate}${filterEndDate}${filterTeams}${filterTeammates}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget36!!!')
      }
      const { data } = response;
      setData(data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  const handleSaveSettingsPeriod = async () => {

    try {

      const arrayValues = Object.values(formSettingsPeriod)

      if(arrayValues.filter(value => value === true).length < 2) {
        alertActions.error(t('widgets.widget36.min_two_periods'))
        return        
      }

      setLoading(true)

      const response = await api.post(`/stats/executive/update/period-filter`, {periods: formSettingsPeriod}, { headers: authHeader() })

      if (response.status !== 200) {
        throw new Error('Erro save data Widget36!!!')
      }

      alertActions.success(t('widgets.widget36.success_save'))
      usersActions.refresh()
      setLoading(false)
      handleClosePopover()
    } catch (error) {
      console.log(error)
      setLoading(false)      
    }

  }

  const handleChange = (event) => {

    const { name, checked } = event.target

    setFormSettingsPeriod({
      ...formSettingsPeriod,
      [name]: checked
    })

  };

  useEffect(() => {
    if (userDetails.widgets.periods) {
      setFormSettingsPeriod({
        morning: userDetails.widgets.periods.morning ?? true,
        afternoon: userDetails.widgets.periods.afternoon ?? true,
        evening: userDetails.widgets.periods.evening ?? true
      })
    }
  }, [userDetails])

  useEffect(() => {
    getData({...filters})
  }, [])

  return (

    <Grid item xs={width} position={'relative'}>
      {
        !fetching
          ?
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Card
                variant="outlined"
                className={style.cardContainer}
              >
                <CardContent className={`${style.cardHeader} ${style.borderLeftGreen}`}>
                  <Timer size={18} color="#22BEAD" weight="bold" />
                  <h6 className={`${style.titleCard} ${style.colorGreen}`}>
                    {secondsToHm(data?.avg_working_day)}
                  </h6>
                  <span className={style.subtitleCard}>
                    {t('widgets.widget36.avg_daily_journey')}
                  </span>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={2}>
              <Card
                variant="outlined"
                className={style.cardContainer}
              >
                <CardContent className={`${style.cardHeader} ${style.borderLeftRed}`}>
                  <Sun size={18} color="#FC6662" weight="bold" />
                  <h6 className={`${style.titleCard} ${style.colorRed}`}>
                    {data?.avg_start_time || '--:--'}
                  </h6>
                  <span className={style.subtitleCard}>
                    {t('widgets.widget36.avg_start_time')}
                  </span>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={2}>
              <Card
                variant="outlined"
                className={style.cardContainer}
              >
                <CardContent className={`${style.cardHeader} ${style.borderLeftGreen}`}>
                  <CalendarCheck size={18} color="#22BEAD" weight="bold" />
                  <h6 className={`${style.titleCard} ${style.colorGreen}`}>
                    {(data.best_day_week && Object.keys(data.best_day_week).length > 0) ? data.best_day_week.translations[lang] : '-----'}
                  </h6>
                  <span className={style.subtitleCard}>
                    {t('widgets.widget36.more_productive_day')}
                  </span>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={2}>
              <Card
                variant="outlined"
                className={style.cardContainer}
              >
                <CardContent className={`${style.cardHeader} ${style.borderLeftRed}`}>
                  <CalendarX size={18} color="#FC6662" weight="bold" />
                  <h6 className={`${style.titleCard} ${style.colorRed}`}>
                    {(data.worst_day_week && Object.keys(data.worst_day_week).length > 0) ? data.worst_day_week.translations[lang] : '-----'}
                  </h6>
                  <span className={style.subtitleCard}>
                    {t('widgets.widget36.less_productive_day')}
                  </span>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={2}>
              <Card
                variant="outlined"
                className={style.cardContainer}
              >
                <CardContent className={`${style.cardHeader} ${style.borderLeftGreen}`}>
                  <Clock size={18} color="#22BEAD" weight="bold" />
                  <h6 className={`${style.titleCard} ${style.colorGreen}`}>
                    {(data.best_productive_period && Object.keys(data.best_productive_period).length > 0 && Object.keys(data.best_productive_period.data).length > 0) ? data.best_productive_period.data.translations[lang] : '-----'}
                  </h6>
                  <span className={style.subtitleCard}>
                    {t('widgets.widget36.more_produtive_period')}
                  </span>
                </CardContent>

                <button className={style.buttonSettings} onClick={(e) => handleOpenPopover(e)}>
                  <Sliders size={14} color="#4F7091" weight="bold" />
                </button>
              </Card>
            </Grid>
            <Grid item xs={2}>
              <Card
                variant="outlined"
                className={style.cardContainer}
              >
                <CardContent className={`${style.cardHeader} ${style.borderLeftRed}`}>
                  <Clock size={18} color="#FC6662" weight="bold" />
                  <h6 className={`${style.titleCard} ${style.colorRed}`}>
                    {(data.worst_productive_period && Object.keys(data.worst_productive_period).length > 0 && Object.keys(data.worst_productive_period.data).length > 0) ? data.worst_productive_period.data.translations[lang] : '-----'}
                  </h6>
                  <span className={style.subtitleCard}>
                    {t('widgets.widget36.less_produtive_period')}
                  </span>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          :
          <Widget36Skeleton />
      }

      <Popover
        id={id}
        open={open}
        anchorEl={anchorElPopover}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={style.popoverForm}>
          <div>
            <h6 className={style.titleForm}>{t('widgets.widget36.title_form')}</h6>
          </div>
          <div>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={<BefectiveCheckbox checked={formSettingsPeriod.morning} onChange={handleChange} name="morning" />}
                label={<Typography component="span" className={style.labelForm}>{t('widgets.widget36.morning')}</Typography>}
              />
              <FormControlLabel
               control={<BefectiveCheckbox checked={formSettingsPeriod.afternoon} onChange={handleChange} name="afternoon" />}
               label={<Typography component="span" className={style.labelForm}>{t('widgets.widget36.afternoon')}</Typography>}
              />
              <FormControlLabel
                 control={<BefectiveCheckbox checked={formSettingsPeriod.evening} onChange={handleChange} name="evening" />}
                 label={<Typography component="span" className={style.labelForm}>{t('widgets.widget36.evening')}</Typography>}
              />
            </FormGroup>
          </FormControl>
          </div>
          <button className={`${style.buttonSaveForm} ${style.apply}`} type='button' onClick={handleSaveSettingsPeriod} disabled={loading}>
            {
              loading
              ?
              <CircularProgress color="inherit" size={16} />
              :
              t('widgets.widget36.save')
            }
          </button>
        </div>
      </Popover>
    </Grid>
  )
}
