// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p4InU2qn7nc34KNaLS7HGw\\=\\= {\n  display: flex;\n  justify-content: center;\n  gap: 12px;\n}\n\n.p4InU2qn7nc34KNaLS7HGw\\=\\= div {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  font-size: 12px;\n}\n\n.wPqlTyDpFcmAaX3Ga-ALyw\\=\\= {\n  display: flex;\n  align-items: center;\n  height: 20px;\n  width: 20px;\n}", "",{"version":3,"sources":["webpack://./src/_components/reports/mood_new/ProductivityGroupPercent.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AACF","sourcesContent":[".containerProductivity{\n  display: flex;\n  justify-content: center;\n  gap: 12px;\n}\n\n.containerProductivity div{\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  font-size: 12px;\n}\n\n.boxCircularProgress{\n  display: flex;\n  align-items: center;\n  height: 20px;\n  width: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerProductivity": "p4InU2qn7nc34KNaLS7HGw==",
	"boxCircularProgress": "wPqlTyDpFcmAaX3Ga-ALyw=="
};
export default ___CSS_LOADER_EXPORT___;
