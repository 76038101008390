import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import style from './CardsHeader.module.scss';
import { Check, HourglassMedium, TrendUp, X } from 'phosphor-react';
import IconInfo from '../../_icons/IconInfo';

export function CardsHeaderBoards({ alertActions, userDetails, stats }) {
  const { t, i18n } = useTranslation('common');
  return (
    <Stack
      direction="row"
      component="header"
      justifyContent="space-between"
      gap={1}
      flexWrap="wrap"
      marginBottom={2}
    >
      
      <Card variant="outlined" sx={{ border: 'none', position: 'relative' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #187DFF' }}>
          <TrendUp size={18} color="#187DFF" weight="bold" />
          <Typography color="#187DFF" component="span" fontSize={20} my={1}>
            {stats.total_boards || 0}
          </Typography>
          <Typography color="#187DFF" component="span" fontSize={12}>
            {t('project_management.reports.boards.stats.total_boards')}
          </Typography>
        </CardContent>
      </Card>

      <Card
        variant="outlined"
        sx={{ border: 'none' }}
        className={style.cardContainer}
      >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #22BEAD' }}>
          <Check size={18} color="#22BEAD" weight="bold" />
          <Typography color="#22BEAD" component="span" fontSize={20} my={1}>
            {stats.done_boards || 0}
          </Typography>
          <Typography color="#22BEAD" component="span" fontSize={12}>
            {t('project_management.reports.boards.stats.done_boards')}
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #FDBE2B' }}>
          <HourglassMedium size={18} color="#FDBE2B" weight="bold" />
          <Typography color="#FDBE2B" component="span" fontSize={20} my={1}>
            {stats.pending_boards || 0}
          </Typography>
          <Typography color="#FDBE2B" component="span" fontSize={12}>
            {t('project_management.reports.boards.stats.pending_boards')}
          </Typography>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ border: 'none' }} className={style.cardContainer} >
        <CardContent className={style.cardHeader} style={{ padding: '1rem', borderLeft: '2px solid #FC6662' }}>
          <X size={18} color="#FC6662" weight="bold" />
          <Typography color="#FC6662" component="span" fontSize={20} my={1}>
            {stats.overdue_boards || 0}
          </Typography>
          <Typography color="#FC6662" component="span" fontSize={12}>
            {t('project_management.reports.boards.stats.overdue_boards')}
          </Typography>
        </CardContent>
      </Card>

    </Stack>
  );
}
