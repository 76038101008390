import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import { useTranslation } from "react-i18next";
import Moment from 'moment';

import { activitiesActions, statsActions } from '../_actions';

import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';

import Filterbar from "../_components/_shared/Filterbar";

import ActivitiesFullSkeleton from "../_components/productivity/ActivitiesFullSkeleton";
import ActivitiesFull from "../_components/productivity/ActivitiesFull";
import ToolbarMu from '../_components/_shared/ToolbarMu';
import Toolbar from '../_components/_shared/Toolbar';
import HeaderProductivityColab from '../_components/_shared/HeaderProductivityColab';
import { pad } from 'crypto-js';
import { Container, Grid } from '@mui/material';

function MyProductivityPageActivities(props) {
	const { t } = useTranslation('common');
	registerLocale('es', es)
	registerLocale('pt', pt)
	const { activtiesActions, statsActions } = props;

	let userLang = navigator.language || navigator.userLanguage;

	if (userLang == 'pt-BR') {
		userLang = 'pt';
	}

	let loading = props.loading;
	let loadingStats = props.loadingStats;

	const isMountedDate = useRef(false);

	const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
	const [whoName, setWhoName] = useState('');

	let date = new Date();
	let startDate = new Date(Moment().add(-7, 'd')).toISOString().split('T')[0];
	let endDate = date.toISOString().split('T')[0];
	const maxDate = new Date(Moment());

	function getActivities(date) {
		statsActions.getProductivity(date);
		activtiesActions.getProductivityActivities(date);
	}

	useEffect(() => {
		let date = Moment(new Date()).format('YYYY-MM-DD');

		if (props.filter.singleDate) {
			date = props.filter.singleDate;
		}

		getActivities(date);
	}, [])

	useEffect(() => {
		if (props.productivity && props.productivity.user) {
			setWhoName(props.productivity.user.name)
		}
	}, [props.productivity])

	useEffect(() => {
		if (isMountedDate.current) {
			if (props.filter.singleDate) {
				getActivities(props.filter.singleDate)
			}
		} else {
			isMountedDate.current = true;
		}
	}, [props.filter.singleDate]);


	useEffect(() => {
		document.title = 'Befective | ' + t('home.my_productivity') + ' | ' + t('home.activities');
	});


	return (
		<React.Fragment>

						<section className="mainContent productivity">

							<HeaderProductivityColab
									smallHeight={true}
									pageRef="activities"
									showDateFilter={true}
									activeMenu="activities"
							/>

							<Container component={'main'} maxWidth="xl" sx={{ flexGrow: 1, padding: '16px 0px'}}>
								<Grid container spacing={0} mb={'16px'} paddingX={'32px'}>
									<Grid item xs={12}>
										{/* PAGE STARTS */}
										<div className="mainPage noPadding">
											<div className="column single">
												{
													!minimumTimeElapsed || loading || loadingStats
														? <ActivitiesFullSkeleton />
														: <ActivitiesFull />
												}
											</div>

										</div>
										{/* PAGE ENDS */}
									</Grid>
								</Grid>
							</Container>
						</section>

		</React.Fragment>
	)
};


function mapDispatchToProps(dispatch) {

	return {
		statsActions: bindActionCreators(statsActions, dispatch),
		activtiesActions: bindActionCreators(activitiesActions, dispatch)
	}
}


function mapStateToProps(state) {

	return {
		loading: state.activities.loading,
		loadingStats: state.stats.loading,
		filter: state.filter,
		activities: state.activities.rows,
		productivity: state.stats.payload,
		userDetails: state.authentication.userDetails
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(MyProductivityPageActivities)
