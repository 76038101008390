import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { useTranslation } from "react-i18next";
import { alertActions } from '../../_actions';
import { api } from '../../_helpers/api';
import { authHeader } from '../../_helpers';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import Header from '../../_components/switch_page/Header';
import style from './styles.module.scss';
import IconGoogleCalendar from '../../_components/_icons/IconGoogleCalendar';
import IconOutlookCalendar from '../../_components/_icons/IconOutlookCalendar';
import { useGoogleLogin } from '@react-oauth/google';
import { useMsal } from '@azure/msal-react';
import msalConfig from '../../msal-config';
import moment from 'moment';
import { set } from 'react-ga';
import { Skeleton } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import PopoverConfirm from '../../_components/teams_settings/PopoverConfirmDelete';

function IntegrationsSettingsPage(props) {
  const msal = useMsal();
  
  const { alertActions } = props

  const { t, i18n } = useTranslation('common');
  
  const [accountsLoggedGoogle, setAccountsLoggedGoogle] = useState(null)
  const [accountsLoggedMicrosoft, setAccountsLoggedMicrosoft] = useState(null)

  const [myIntegrations, setMyIntegrations] = useState({
    google: null,
    microsoft: null
  })

  const [isLoadingConnect, setIsLoadingConnect] = useState({
    google: false,
    microsoft: false
  })

  const [isFetching, setIsFetching] = useState(false)
  
  const locale = navigator.language;
  
  const langOpt = {
    'pt': 'pt',
    'en': 'en',
    'es': 'es',
    'pt-BR': 'pt',
    'en-US': 'en',
    'es-MX': 'es' 
  } 

  const lang = langOpt[`${locale}`] 

  const integrations = [
    {
      service: 'google',
      resource: 'google_calendar',
      icon:  <IconGoogleCalendar />,
      connected: false
    },
    {
      service: 'microsoft',
      resource: 'microsoft_calendar',
      icon: <IconOutlookCalendar />,
      connected: false
    }
  ]

  const [serviceToDisconnect, setServiceToDisconnect] = useState(null)

  const [anchorElConfirmDisconnectAccount, setAnchorElConfirmDisconnectAccount] = useState(null);
  const openConfirmDisconnectAccount = Boolean(anchorElConfirmDisconnectAccount);
  const idConfirmDisconnectAccount = openConfirmDisconnectAccount ? 'popover-confirm-disconnect-account' : undefined;

  const handleClickConfirmDisconnectAccount = (event, serviceDisconnect) => {
    setServiceToDisconnect(serviceDisconnect)
    setAnchorElConfirmDisconnectAccount(event.currentTarget);
  };

  const handleCloseConfirmDisconnectAccount = () => {
    setServiceToDisconnect(null)
    setAnchorElConfirmDisconnectAccount(null)
  };

  const getMyIntegrations = async () => {
    try {
      setIsFetching(true)
      const response = await api.get(`integrations/google-calendar/my-integrations`, { headers: authHeader() })
      if(response.status !== 200) {
        setIsFetching(false)
        throw new Error('Error to load data my integrations')
      }
      const { data } = response
      setMyIntegrations({
        google: data.find(i => i.platform === 'google'),
        microsoft: data.find(i => i.platform === 'microsoft'),
      })
      setIsFetching(false)
    }catch (error) {
      console.log(error)
      setIsFetching(false)
    }
  }

  async function handleGoogleDisconnect() {
    try {
      await api.patch(`integrations/google-calendar/deactivate-all`, {}, {headers: authHeader()})  
      handleCloseConfirmDisconnectAccount()
      setAccountsLoggedGoogle(null)
      getMyIntegrations()
      alertActions.success(t('global_settings.integrations.messages.success_disconnect'))
    } catch (error) {
      console.log(error)
      alertActions.error(t('global_settings.integrations.messages.error_disconnect'))
    }
    
  }

  const handleGoogleLogin = useGoogleLogin({
    scope: 'openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar',
    onSuccess: async codeResponse => {
      try {
        setIsLoadingConnect({ ...isLoadingConnect, google: true })
        if (!codeResponse.scope.includes('https://www.googleapis.com/auth/calendar')) {
          throw new Error('Scope not authorized')
        }

        const response = await api.post(`integrations/google-calendar/create-token?lang=${lang}`, {
          code: codeResponse.code,
          date: moment().format('YYYY-MM-DD')
        },
        {headers: authHeader()}        
        )

        await getMyIntegrations()
        setAccountsLoggedGoogle(response.data.accounts)
        alertActions.success(t('global_settings.integrations.messages.success_connect'))
        setIsLoadingConnect({ ...isLoadingConnect, google: false })
      } catch (error) {
        alertActions.error(t('global_settings.integrations.messages.error_connect'))
        setIsLoadingConnect({ ...isLoadingConnect, google: false })
      }
    },
    onError: error => {
      // console.log(error)
      // alertActions.error(t('global_settings.integrations.messages.error_connect'))
      setIsLoadingConnect({ ...isLoadingConnect, google: false })
    },
    flow: 'auth-code'
  });

  const handleMicrosoftLogin = async () => {
    try {
      
      setIsLoadingConnect({ ...isLoadingConnect, microsoft: true })

      const responseLogin = await msal.instance.loginPopup({
        scopes: msalConfig.scopes,
        prompt: 'select_account',
      });

      if (!responseLogin.scopes.includes('Calendars.Read')) {
        throw new Error('Scope not authorized')
      }

      const response = await api.post(`integrations/outlook-calendar/create-user`, {
        microsoft_user: {
          displayName: responseLogin.account.name,
          userPrincipalName: responseLogin.account.username
        }
      },
      {headers: authHeader()}
      )

      await getMyIntegrations()          
      setAccountsLoggedMicrosoft(response.data.accounts)  
      alertActions.success(t('global_settings.integrations.messages.success_connect'))

      setIsLoadingConnect({ ...isLoadingConnect, microsoft: false })
    } catch (error) {
      // console.log(error)
      // alertActions.error(t('global_settings.integrations.messages.error_connect'))
      setIsLoadingConnect({ ...isLoadingConnect, microsoft: false })

    }
  }

  async function handleOutlookDisconect() {
    try {
      await api.patch(`integrations/outlook-calendar/deactivate-all`, {}, {headers: authHeader()}) 
      handleCloseConfirmDisconnectAccount()
      setAccountsLoggedMicrosoft(null)
      getMyIntegrations()
      alertActions.success(t('global_settings.integrations.messages.success_disconnect'))
    } catch (error) {
      console.log(error)
      alertActions.error(t('global_settings.integrations.messages.error_disconnect'))
    }
  }

  const handleConnect = (service) => {
    switch (service) {
      case 'google':
        handleGoogleLogin();
        break;
      case 'microsoft':
        handleMicrosoftLogin();
        break;
      default:
        break;
    }
  }

  const handleDisconnect = (service) => {
    switch (service) {
      case 'google':
        handleGoogleDisconnect();
        break;
      case 'microsoft':
        handleOutlookDisconect();
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    document.title = 'Befective | ' + t('global_settings.integrations.title');
    getMyIntegrations();
  }, []);


  return (
    <Box bgcolor="#F5F6F9" width="100%" display="flex" flexDirection="column">
      <Header toBackUrl={"/global-settings"} />

      <main className={style.containerMain}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" className={style.title}>{t('global_settings.integrations.my_integrations')}</Typography>
          </Grid>
          {
            !isFetching
            ?
              integrations.map((integration, index) => {
                return (
                  <Grid item xs={12} key={integration.resource}>
                    <Card className={`${style.card} ${myIntegrations[integration.service] ? style.connected : style.disconnected}`} variant="outlined">
                      <Box className={style.cardContent}>
                        {integration.icon}
                        <Box className={style.contexText}>
                          <div className={`${myIntegrations[integration.service] ? style.boxStatusConnected : style.boxStatusDisconnected}`}>
                            <div className={style.cicle}></div>
                            <div className={style.statusText}>{myIntegrations[integration.service] ? (t('global_settings.integrations.connected')+` (${myIntegrations[integration.service]?.email})`) : t('global_settings.integrations.disconnected')}</div>
                          </div>
                          <Typography variant="h6" className={style.cardTitle}>{t(`global_settings.integrations.${integration.resource}.title`)}</Typography>
                          <Typography variant="p" className={style.cardSubtitle}>{t(`global_settings.integrations.${integration.resource}.subtitle`)}</Typography>
                        </Box>
                      </Box>
                      <Box className={style.cardActions}>
                        {
                          !myIntegrations[integration.service]
                          ?
                            (

                              !isLoadingConnect[integration.service]
                              ?
                              <Button variant="contained" color="primary" disableElevation onClick={() => handleConnect(integration.service)}>
                                {t('global_settings.integrations.connect')}
                              </Button>
                              :
                              <Button variant="contained" color="primary" disableElevation disabled={isLoadingConnect[integration.service]}>
                                {t('global_settings.integrations.connecting')}
                              </Button>

                            )
                          :
                            <Button variant="outlined" color="error" disableElevation onClick={(e) => handleClickConfirmDisconnectAccount(e, integration.service)}>
                              {t('global_settings.integrations.disconnect')}
                            </Button>
                        }

                      </Box>
                    </Card>
                  </Grid>
                )
              })
            :
             [1,2].map((index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Skeleton variant="rectangular" height={120} />
                </Grid>
              )
             })
          }
        </Grid>
      </main>

      {/* Start Confirm Dialog Disconnect Account */}
      <PopoverConfirm
        idConfirm={idConfirmDisconnectAccount}
        openConfirm={openConfirmDisconnectAccount}
        anchorEl={anchorElConfirmDisconnectAccount}
        handleClose={handleCloseConfirmDisconnectAccount}
        handleConfirm={() => handleDisconnect(serviceToDisconnect)}
        title={t('global_settings.integrations.messages.confirm_disconnect_account')}
      />
      {/* End Confirm Dialog Disconnect Account */}

    </Box>
  )
};

function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsSettingsPage)
