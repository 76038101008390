import {filterConstants} from "../_constants";

export function filter(
    state = {
        software: false,
        site: false,
        offline: false,
        productive: false,
        neutral: false,
        unproductive: false,
    },
    action
) {
    switch (action.type) {
        case filterConstants.SET_WHEN:
            return {
                ...state,
                date: {
                    startDate: action.setFilters.startDate,
                    endDate: action.setFilters.endDate,
                },
            };
        case filterConstants.REPLACE_SINGLE_WHEN:
            return {
                ...state,
                singleDate: action.setFilters[0],
            };

        case filterConstants.SET_WHAT:
            return {
                ...state,
                search: action.payload,
            };

        case filterConstants.SET_WHO_TEAMS:
            let alreadyInList = false;
            let newTeams = [];
            if (state.teams) {
                for (let team of state.teams) {
                    if (team.id == action.setFilters[0].id) {
                        alreadyInList = true;
                    } else {
                        let addTeam = [{id: team.id}];
                        newTeams = [...newTeams, ...addTeam];
                    }
                }
            }

            if (!alreadyInList) {
                if (state.teams) {
                    newTeams = [...state.teams, ...action.setFilters];
                } else {
                    newTeams = action.setFilters;
                }
            }

            return {
                ...state,
                teams: newTeams,
            };

        case filterConstants.SET_WHO_TEAMMATES:
            alreadyInList = false;
            let newTeammates = [];


            if (state.teammates) {
                for (let teammate of state.teammates) {
                    if (teammate.id == action.setFilters[0].id) {
                        alreadyInList = true;
                    } else {
                        let addTeammate = [{id: teammate.id}];
                        newTeammates = [...newTeammates, ...addTeammate];
                    }
                }
            } else if (!state.teammates && action.setFilters.length > 0) {
                newTeammates.push(action.setFilters[0])
            }

            if (!alreadyInList) {
                if (state.teammates) {
                    newTeammates = [...state.teammates, ...action.setFilters];
                } else {
                    newTeammates = action.setFilters;
                }
            }


            return {
                ...state,
                teammates: newTeammates,
            };

        case filterConstants.REPLACE_WHO_TEAMMATES:


            return {
                ...state,
                teammates: [{id: action.setFilters[0].id}],
            };

        case filterConstants.SET_INTERNAL_SOFTWARE:
            return {
                ...state,
                software: action.payload,
            };
        case filterConstants.SET_INTERNAL_SITE:
            return {
                ...state,
                site: action.payload,
            };
        case filterConstants.SET_INTERNAL_OFFLINE:
            return {
                ...state,
                offline: action.payload,
            };
        case filterConstants.SET_INTERNAL_PRODUCTIVE:
            return {
                ...state,
                productive: action.payload,
            };
        case filterConstants.SET_INTERNAL_NEUTRAL:
            return {
                ...state,
                neutral: action.payload,
            };
        case filterConstants.SET_INTERNAL_UNPRODUCTIVE:
            return {
                ...state,
                unproductive: action.payload,
            };
        case filterConstants.CLEAR_WHO:
            return {
                ...state,
                teammates: [],
                teams: [],
            };
        case filterConstants.CLEAR_WHEN:
            return {
                ...state,
                date:null
            };
        case filterConstants.CLEAR_WHAT:
            return {
                ...state,
                what: null,
            };
        case filterConstants.CLEAR_WHO_TEAMS:
            return {
                ...state,
                teams: [],
            };
        case filterConstants.CLEAR_WHO_TEAMMATES:
            return {
                ...state,
                teammates: [],
            };
        default:
            return state;
    }
}
