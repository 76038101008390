import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useTranslation, initReactI18next } from "react-i18next";

import { useTable, useSortBy, usePagination } from 'react-table'

import { TruncateNumberWithoutRounding, getMoodById, secondsToHm } from '../../../_helpers';


import IconTeam from '../../_icons/IconTeam';
import IconInfo from '../../_icons/IconInfo';
import IconCompleted from '../../_icons/IconCompleted';
import IconOnline from '../../_icons/IconOnline';
import IconOffline from '../../_icons/IconOffline';
import IconHappy from '../../_icons/IconHappy';
import { isProductive } from "../../../_helpers/calculate";
import { Robot } from 'phosphor-react';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';


function TeammatesFull(props) {
    const { t, i18n } = useTranslation('common');

    let goals = props.goals || [];
    const stats = props.stats || [];
    const { userDetails, count, pageCountRows, globalFilter, getData } = props
    const [firstLoad, setFirstLoad] = useState(true)
    const [loading, setLoading] = useState(false)

    const [filterProductive, setFilterProductive] = useState(true);
    const [filterUnproductive, setFilterUnproductive] = useState(true);
    const [TotalProductiveTeammates, setProductiveTeammates] = useState(0);
    const [TotalUnproductiveTeammates, setUnproductiveTeammates] = useState(0);

    const [PercentWorkedTime, setPercentWorkedTime] = useState(0);
    const [PercentProductiveTime, setPercentProductiveTime] = useState(0);
    const [TotalOnlineTime, setTotalOnlineTime] = useState(0);
    const [TotalOfflineTime, setTotalOfflineTime] = useState(0);
    const [PercentOnlineTime, setPercentOnlineTime] = useState(0);
    const [PercentOfflineTime, setPercentOfflineTime] = useState(0);

    const [TotalWorkGoalTime, setTotalWorkGoalTime] = useState(0);
    const [TotalTimeGoalTime, setTotalTimeGoalTime] = useState(0);

    const [TotalExpectedProductive, setTotalExpectedProductive] = useState(0);
    const [TotalExpectedTime, setTotalExpectedTime] = useState(0);

    const data = useMemo(() => goals);

    let PercentHappy = 0;
    let HappyMoodUsers = stats.HappyMoodUsers || 0;
    let SadMoodUsers = stats.SadMoodUsers || 0;
    let OkMoodUsers = stats.OkMoodUsers || 0;

    let TotalMoodUsers = 0;
    TotalMoodUsers = HappyMoodUsers + OkMoodUsers + SadMoodUsers;

    if (TotalMoodUsers > 0) {

        PercentHappy = ((HappyMoodUsers / TotalMoodUsers) * 100).toFixed(2);

    }


    const columns = React.useMemo(
        () => [
            {
                Header: t('teammates.teammates'),
                accessor: c => c.machineUserName,
                show: true,
                Cell: (d) => {
                    return <div className='boxTeammateName cellShrinkTable'>
                                <span className='name' title={d.value}>{d.value}</span><br/>
                            </div>
                }
            },
            {
                Header: t('teammates.teams'),
                accessor: c => c.groupName,
                show: true,
                Cell: (d) => {
                    return <div className='boxTeammateName cellShrinkTable'>
                                <span className='group' title={d.value}>{d.value}</span>
                            </div>
                }
            },
            {
                Header: t('teammates.profile'),
                accessor: c => c.accessProfileName,
                show: true,
                Cell: (d) => {
                    const { accessProfileKey, isDefaultProfile } = d.row.original;
                    if(isDefaultProfile === true){
                      return <div className='boxTeammateName cellShrinkTable'>
                                <span className='group' title={t(`settings.teams.profiles.${accessProfileKey}`)}>{t(`settings.teams.profiles.${accessProfileKey}`)}</span>
                            </div>
                    }
                    return  <div className='boxTeammateName cellShrinkTable'>
                                <span className='group' title={d.value}>{d.value}</span>
                            </div>
                }
            },
            {
                Header: `${t('standards.working_day')} (h | %)`,
                accessor: (cellRow) => {
                    let percentWorked = 0;
                    if (cellRow.totalWorkedTime > 0 && cellRow.totalExpectedWorkedTime > 0) {
                        percentWorked = ((cellRow.totalWorkedTime / cellRow.totalExpectedWorkedTime) * 100);
                    }
                    return percentWorked;
                },
                show: userDetails.allowed_total_time,
                Cell: (d) => {
                    const cellRow = d.row.original;
                    let percentWorked = 0;
                    if (cellRow.totalWorkedTime > 0 && cellRow.totalExpectedWorkedTime > 0) {
                        percentWorked = ((cellRow.totalWorkedTime / cellRow.totalExpectedWorkedTime) * 100);
                    }
                    
                    return <div className={"time "}>
                                {`${secondsToHm(cellRow.totalWorkedTime)} | ${TruncateNumberWithoutRounding(percentWorked)}%`}
                            </div>
                }
            },
            {
                id: 'workgoal',
                Header: `${t('standards.performance')} (h | %)`,
                accessor: (d) => {
                    let percentWorked = 0.00;
                    if ((d.totalProductiveTime - d.totalProductiveTimeOutdoor > 0) && d.totalExpectedProductiveTime > 0) {
                        percentWorked = ((d.totalProductiveTime) / d.totalExpectedProductiveTime) * 100;
                    }
                    return percentWorked;
                },
                show: userDetails.allowed_productive.includes("related"),
                Cell: (d) => {
                    let percentWorked = 0.00;
                    if (d.row.original.totalProductiveTime && d.row.original.totalExpectedProductiveTime > 0) {
                        percentWorked = ((d.row.original.totalProductiveTime) / d.row.original.totalExpectedProductiveTime) * 100;
                    }
                    let productive = 'unproductive';
                    if (percentWorked >= 100) {
                        productive = 'productive';
                    }
                    return <div className={"work " + productive}>
                                {`${secondsToHm(d.row.original.totalProductiveTime)} | ${TruncateNumberWithoutRounding(percentWorked)}%`}
                           </div>
                }
            },
            {
                Header: `${t('teammates.on')} | ${t('teammates.off')}`,
                show: true,
                accessor: (d) => {
                    let percentOnline = 0.00;
                    if (d.totalExpectedProductiveTime > 0) {
                        percentOnline = ((d.totalProductiveTime-d.totalProductiveTimeOutdoor) / d.totalExpectedProductiveTime) * 100;
                    }
                    return percentOnline;
                },
                Cell: (d) => {
                    let percentOnline = 0.00;

                    if (d.row.original.totalExpectedProductiveTime > 0) {
                        percentOnline = TruncateNumberWithoutRounding(((d.row.original.totalProductiveTime-d.row.original.totalProductiveTimeOutdoor) / d.row.original.totalExpectedProductiveTime) * 100);
                    }

                    let percentWorkedOutdoor = 0.00;
                    
                    if (d.row.original.totalProductiveTimeOutdoor > 0 && d.row.original.totalExpectedProductiveTime > 0) {
                        percentWorkedOutdoor = TruncateNumberWithoutRounding((d.row.original.totalProductiveTimeOutdoor / d.row.original.totalExpectedProductiveTime) * 100);
                    }

                    return <div className="work">{`${percentOnline}% | ${percentWorkedOutdoor}%`}</div>;
                }
            },
            {
                Header: t('teammates.last_mood'),
                show: true,
                accessor: "mood",
                Cell: (d) => {
                    if (!d.value || d.value <= 0) {
                        return <div style={{minWidth:'80px'}}>--</div>
                    }
                    const mood = getMoodById(d.value);
                    return  <div style={{minWidth:'80px'}}>
                                <Tooltip arrow title={t(`moods_settings.moods.${mood.key}`)}>
                                    <img src={mood.img} width={18} loading='lazy'/>
                                </Tooltip>
                            </div>
                }
            },
            {
                Header: t('teammates.agent_version'),
                show: true,
                accessor: (d) => {
                    return d.agentVersion === null ? '---' : d.agentVersion;

                },
                Cell: (d) => {

                    return <div className="">{d.value}</div>;
                }
            }
        ],
        []
    );


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable({
        columns,
        data,
        disableMultiSort: true,
        manualPagination: true,
        pageCount: count,
        autoResetPage: false,
        initialState: { 
            pageIndex: 0, 
            pageSize: localStorage.getItem('pageSizeStorage') || 20
        },
        pageOptions: Array.from({ length: count }, () => 1).map((_, index) => index)

    }, useSortBy, usePagination);

    const myPageOptions = [
        {
          value: 20,
          label: '20'
        },
        {
          value: 50,
          label: '50'
        },
        {
          value: 100,
          label: '100'
        },
        {
          value: 200,
          label: '200'
        },
        {
          value: 300,
          label: '300'
        },
        {
          value: 400,
          label: '400'
        },
        {
          value: 500,
          label: '500'
        },
        {
          value: pageCountRows,
          label: 'all'
        }
      ]


    useEffect(() => {
        if (stats) {

            let tExpected = stats.TotalExpectedWorkedTime
            let tProductive = stats.TotalProductiveTime
            let pTime = 0
            let pProductive = 0

            if (stats.TotalExpectedWorkedTime) {
                pTime = ((stats.TotalWorkedTime / stats.TotalExpectedWorkedTime) * 100)
            }

            if (stats.TotalExpectedWorkedTime) {
                pProductive = ((stats.TotalProductiveTime / stats.TotalExpectedProductiveTime) * 100)
            }

            let tOnline = stats.TotalProductiveTime - stats.TotalProductiveTimeOutdoor
            let tOffline = stats.TotalProductiveTimeOutdoor

            let pOffline = 0;
            let pOnline = 0;

            if (stats.TotalProductiveTimeOutdoor > 0 && stats.TotalExpectedProductiveTime > 0) {
                pOffline = ((stats.TotalProductiveTimeOutdoor / stats.TotalExpectedProductiveTime) * 100);
            }

            if (stats.TotalProductiveTime - stats.TotalProductiveTimeOutdoor > 0 && stats.TotalExpectedProductiveTime > 0) {
                pOnline = (((stats.TotalProductiveTime) / stats.TotalExpectedProductiveTime) * 100);
            }


            setTotalWorkGoalTime(stats.TotalWorkedTime);
            setTotalTimeGoalTime(stats.TotalProductiveTime);

            setTotalExpectedTime(tExpected)
            setTotalExpectedProductive(tProductive)
            setPercentWorkedTime(pTime)
            setPercentProductiveTime(pProductive)
            setTotalOnlineTime(tOnline)
            setTotalOfflineTime(tOffline)
            setPercentOnlineTime(pOnline - pOffline)
            setPercentOfflineTime(pOffline)

        }
    }, [stats])

    useEffect(() => {
        const elementsProductive = document.querySelectorAll(".productive");
        const elementsUnproductive = document.querySelectorAll(".unproductive");
        if (filterProductive) {
            [].forEach.call(elementsProductive, function (el) {
                el.parentNode.parentNode.classList.remove("hide");
            });
        } else {
            [].forEach.call(elementsProductive, function (el) {
                el.parentNode.parentNode.classList.add("hide");
            });
        }

        if (filterUnproductive) {
            [].forEach.call(elementsUnproductive, function (el) {
                el.parentNode.parentNode.classList.remove("hide");
            });
        } else {
            [].forEach.call(elementsUnproductive, function (el) {
                el.parentNode.parentNode.classList.add("hide");
            });
        }

    }, [filterProductive, filterUnproductive])

    const fetchData = async ({ pageIndex, pageSize}) => {
        try {
            setLoading(true)
            await getData({ isLoading: false, page: pageIndex, pageSize: pageSize, profiles: globalFilter.profiles })
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        }
    };
    
    useEffect(() => {
        if(!firstLoad){
            fetchData({ pageIndex, pageSize });
        }else{
            setFirstLoad(false)
        }
    }, [pageIndex, pageSize]);

    return (
        <div className="box teammates noBg">
            <div className="pageHeader compact">
                <h3>{t('teammates.teammates')} <span><IconInfo /><div
                    className="tooltip">{/*<IconInfo/>*/}{t('teammates.tooltip')}</div></span></h3>
                <div className="internalFilters">
                    <div className={'filter work active'}>
                        <div className={"head"}>
                            <div className="icon">
                                <IconTeam />
                            </div>
                        </div>
                        <div className={"highlight"}>{`${stats?.TotalProductiveMachines || 0} / ${stats?.TotalMachines || 0}`}</div>
                        <div className="name">{t('teammates.work_goal_reached')}</div>
                    </div>
                    {/* <div className={filterUnproductive ? 'filter active canFilter work' : 'filter canFilter work'}
                        onClick={() => {
                            setFilterUnproductive(!filterUnproductive)
                        }}>
                        <div className={"head"}>
                            <div className="icon">
                                <IconTeam />
                            </div>
                        </div>
                        <div className={"highlight"}>{TotalUnproductiveTeammates}</div>
                        <div className="name">{t('teammates.work_goal_not_reached')}</div>
                    </div> */}

                    {userDetails.allowed_total_time == true && (
                    <div className="filter active time disable">
                        <div className={"head"}>
                            <div className="icon">
                                <IconCompleted />
                            </div>
                            <div className="number">{secondsToHm(stats.TotalWorkedTimeAVG)}</div>
                        </div>
                        <div className="highlight">{(PercentWorkedTime).toFixed(2)}%</div>
                        <div className="name">{t('standards.working_day')}</div>
                    </div>
                    )}
                    {userDetails.allowed_productive.includes("related") && (
                    <div className="filter work active disable">
                        <div className={"head"}>
                            <div className="icon">
                                <IconCompleted />
                            </div>
                            <div className="number">{secondsToHm(stats.TotalProductiveTimeAVG)}</div>
                        </div>
                        <div className="highlight">{(PercentProductiveTime).toFixed(2)}%</div>
                        <div className="name">{t('standards.performance')}</div>
                    </div>
                    )}
                    <div className="filter work active disable">
                        <div className={"head"}>
                            <div className="icon">
                                <IconOnline />
                            </div>
                            <div className="number">{secondsToHm(stats.totalOnlineTimeAVG)}</div>
                        </div>
                        <div className="highlight">{stats.percent_online_from_total_time}%</div>
                        <div className="name">{t('teammates.online_activities')}</div>
                    </div>
                    <div className="filter work active disable">
                        <div className={"head"}>
                            <div className="icon">
                                <IconOffline />
                            </div>
                            <div className="number">{secondsToHm(stats.TotalWorkedTimeOutdoorAVG)}</div>
                        </div>
                        <div className="highlight">{stats.percent_ofline_from_total_time}%</div>
                        <div className="name">{t('teammates.offline_activities')}</div>
                    </div>
                    <div className="filter active mood">
                        <div className={"head"}>
                            <div className="icon">
                                <IconHappy />
                            </div>
                        </div>
                        <div className="highlight">{stats.happiness_percent || 0}%</div>
                        <div className="name">{t('teammates.happiness')}</div>
                    </div>
                    <div className="filter danger">
                        <div className={"head"}>
                            <div className="icon">
                                <Robot color='#FC6662' size={16} weight='bold' />
                            </div>
                        </div>
                        <div className="highlight">{`${stats?.percent_users_agent_installed || 0}%`}<span className='small'>{`(${stats?.total_users_agent_installed || 0}/${stats?.total_users || 0})`}</span></div>
                        <div className="name">{t('teammates.agents_installed')}</div>
                    </div>
                </div>


            </div>
            <div className="dataTable teammates" style={{ overflow: 'auto' }}>
                {data.length > 0 &&
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => {
                    if (column.show) {
                      return (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <div className="arrowDown"></div>
                              ) : (
                                <div className="arrowUp"></div>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      );
                    }
                  })}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map(
                                (row, i) => {

                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                if (cell.column.show) {
                                                    return (
                                                      <td {...cell.getCellProps()}>
                                                        {cell.render("Cell")}
                                                      </td>
                                                    );
                                                  }
                                                  return null;
                                            })}
                                        </tr>
                                    )
                                }
                            )}
                        </tbody>
                    </table>
                }
            </div>

            <div className={"containerPaginationGlobal bgWhite fullWidth"}>
                {
                    loading && <CircularProgress color="primary" size={20} />
                }
                <Pagination
                    count={pageCount}
                    page={pageIndex + 1}
                    onChange={(event, page) => {
                        gotoPage(page - 1)
                    }}
                    variant="outlined"
                    shape="rounded"
                    siblingCount={1}
                    boundaryCount={1}
                />
                <select
                value={pageSize}
                onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    localStorage.setItem('pageSizeStorage', Number(e.target.value));
                }}
                className={"selectOptionsPaginationGlobal"}
                >
                {myPageOptions.map((pageSize) => (
                    <option key={`page-${pageSize.value}`} value={pageSize.value}>
                    {pageSize.label === 'all' ? t('settings.teams.show_all') : `${t('settings.teams.show')} ${pageSize.label}`}
                    </option>
                ))}
                </select>
            </div>


        </div>
    )
};

function mapDispatchToProps(dispatch) {

    return {}
}

function mapStateToProps(state) {

    return {
        filter: state.filter,
        userDetails: state.authentication.userDetails
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(TeammatesFull)
