// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5RZL\\+BfOa5z7PuXXM5BPig\\=\\= {\n  width: 100%;\n  height: 38px;\n  border-radius: 22px;\n  position: relative;\n}\n\n._9C3jms4wNzAEkSYfEIrxMQ\\=\\= {\n  position: absolute;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  width: 80px;\n  height: 48px;\n  background: #FFFFFF 0% 0% no-repeat padding-box;\n  border-radius: 24px;\n  border: #E4EAEF 1px solid;\n  backdrop-filter: blur(10px);\n  --webkit-backdrop-filter: blur(10px);\n  opacity: 0.4;\n}\n\n.h86JquVPoZSf4J4U6wY5AA\\=\\= {\n  position: absolute;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 1;\n  color: #FFFFFF;\n  opacity: 1;\n  font-size: 24px;\n  font-weight: 400;\n}", "",{"version":3,"sources":["webpack://./src/_components/performance_assessments/dashboard/Widget30/GradientBar.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,gCAAA;EACA,WAAA;EACA,YAAA;EACA,+CAAA;EACA,mBAAA;EACA,yBAAA;EACA,2BAAA;EACA,oCAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,gCAAA;EACA,UAAA;EACA,cAAA;EACA,UAAA;EACA,eAAA;EACA,gBAAA;AACF","sourcesContent":[".bar{\n  width: 100%;\n  height: 38px;\n  border-radius: 22px;\n  position: relative;\n}\n\n.marker{\n  position: absolute;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  width: 80px;\n  height: 48px;\n  background: #FFFFFF 0% 0% no-repeat padding-box;\n  border-radius: 24px;\n  border: #E4EAEF 1px solid;\n  backdrop-filter: blur(10px);\n  --webkit-backdrop-filter: blur(10px);\n  opacity: 0.4;\n}\n\n.markerText{\n  position: absolute;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 1;\n  color: #FFFFFF;\n  opacity: 1;\n  font-size: 24px;\n  font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bar": "_5RZL+BfOa5z7PuXXM5BPig==",
	"marker": "_9C3jms4wNzAEkSYfEIrxMQ==",
	"markerText": "h86JquVPoZSf4J4U6wY5AA=="
};
export default ___CSS_LOADER_EXPORT___;
