// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JQfGoSP6c8Ttc6zF7FwQIQ\\=\\= {\n  padding: 22px;\n}\n\n.TbzU12pifqpfeoBJgnH\\+AA\\=\\= {\n  display: flex;\n  gap: 6px;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/ProductiveReportsValueHourColabsPage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AACF","sourcesContent":[".containerPage {\n  padding: 22px;\n}\n\n.boxCellUsername{\n  display: flex;\n  gap: 6px;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerPage": "JQfGoSP6c8Ttc6zF7FwQIQ==",
	"boxCellUsername": "TbzU12pifqpfeoBJgnH+AA=="
};
export default ___CSS_LOADER_EXPORT___;
