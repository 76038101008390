import { usersConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem(process.env.KEY_USER_LOCAL_STORAGE));

let base64Url = null;
let base64 = null;
let userDetails = null;
let permissions = {};


if (user && user.accessToken) {

    base64Url = user.accessToken.split('.')[1];
    base64 = base64Url.replace('-', '+').replace('_', '/');
    userDetails = JSON.parse(window.atob(base64));

    for (let role of userDetails.role) {
        permissions[role] = true;
    }
    delete userDetails.role

}

const initialState = user ? { loggedIn: true, user, userDetails: userDetails, permissions: permissions } : {};


export function authentication(state = initialState, action) {

    switch (action.type) {
        case usersConstants.LOGIN_REQUEST:


            return {
                loggingIn: true,
                user: action.user,
                userDetails: userDetails
            };
        case usersConstants.LOGIN_SUCCESS:

            base64Url = action.user.accessToken.split('.')[1];
            base64 = base64Url.replace('-', '+').replace('_', '/');
            userDetails = JSON.parse(window.atob(base64));

            for (let role of userDetails.role) {
                permissions[role] = true;
            }

            return {
                loggedIn: true,
                user: action.user,
                userDetails: userDetails,
                permissions: permissions
            };
        case usersConstants.LOGIN_FAILURE:
            return { loggedIn: false, };
        case usersConstants.LOGOUT:
            return {};
        case usersConstants.LOGIN_REFRESH_SUCCESS:

            base64Url = action.user.accessToken.split('.')[1];
            base64 = base64Url.replace('-', '+').replace('_', '/');
            userDetails = JSON.parse(window.atob(base64));

            for (let role of userDetails.role) {
                permissions[role] = true;
            }

            return {
                loggedIn: true,
                user: action.user,
                userDetails: userDetails,
                permissions: permissions
            };
        case usersConstants.USER_DETAILS_CLEAR:
            return {userDetails: undefined}
        default:
            return state
    }
}