import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import style from './styles.module.scss';

import { useTranslation, initReactI18next } from "react-i18next";
import { Link } from 'react-router-dom';
import IconInfo from '../../_icons/IconInfo';
import { Grid, Typography } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import { TablePagination, Tooltip } from '@material-ui/core';
import { api } from '../../../_helpers/api';
import { authHeader } from '../../../_helpers';
import Widget26Skeleton from './Widget26Skeleton';
import { UserCircle, Users } from 'phosphor-react';
import TeammatesTable from './TeammatesTable';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';
import CustomPaginationMUI from './CustomPaginationMUI';


export default function Widget26({ width, currentDate, alertActions, filters }) {
  const { t, i18n } = useTranslation('common');

  const [toggleView, setToggleView] = useState('teams')

  const [data, setData] = useState({
    percent_productive: 0,
    percent_neutral: 0,
    percent_unproductive: 0,
    topSite: [],
    topSoftware: [],
    topOffline: []
  });

  const [fetching, setFetching] = useState(true);

  
 const [page, setPage] = useState(1);
 const [rowsPerPage, setRowsPerPage] = useState(50);
 const [totalRecords, setTotalRecords] = useState(0);

 const handlePageChange = (event, newPage) => {
    setPage(newPage);
 };

 const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
 };


  const getData = async ({ filterStartDate, filterEndDate, filterTeams, filterTeammates, rowsPerPage, page }) => {
    try {
      setFetching(true)
      const response = await api.get(`/stats/executive/colabs?year_month=${currentDate.format('YYYY-MM')}&current_day=${currentDate.format('YYYY-MM-DD')}&per_page=${rowsPerPage}&page=${page}${filterStartDate}${filterEndDate}${filterTeams}${filterTeammates}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget26!!!')
      }
      setData(response.data)
      setTotalRecords(parseInt(response.data.totalCount))
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData({ ...filters, rowsPerPage, page })
  }, [rowsPerPage, page])

  return (
    !fetching
      ?
      <Grid item xs={width}>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <div className={style.boxTitle}>
                <Link className={"linkDashRedirect"} to={'/productive/reports/teammates'}>
                  <h6 className={style.title}>{t('widgets.widget10.teammates_title')}</h6>
                </Link>
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('widgets.widget10.teammates_tooltip')}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo /></div>
                </BefectiveTooltip>
              </div>
              {/* <button className={style.btnToggle} onClick={() => setToggleView('teams')}>
                <Users size={18} weight="bold" color='#98ACC1' />
              </button> */}
            </div>
            <div className={style.statsBlock}>
              <div className={style.secondaryChart}>
                <div className={style.block}>
                  <div className={style.stats}>
                    <div className={style.labels}>
                      <div className={`${style.label} ${style.related}`}>
                        {t('widgets.widget10.goal_reached')}: <b> {data.total_reach_goal} ({data.percent_reach_goal}%)</b>
                      </div>
                      <div className={style.label}>
                        {t('widgets.widget10.goal_not_reached')}: <b> {data.total_not_reach_goal} ({data.percent_not_reach_goal}%)</b>
                      </div>
                    </div>

                    <div className={style.barchart}>
                      <div className={style.bar} style={{ width: data.percent_reach_goal + '%' }}></div>
                      <div className={`${style.bar} ${style.unrelated}`} style={{ width: data.percent_not_reach_goal + '%' }}></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.displayList}>
                <TeammatesTable data={data.data} />
              </div>

              <CustomPaginationMUI
                page={page}
                handlePageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange} 
                totalRecords={totalRecords}                 
              />

            </div>
          </div>
        </div>
      </Grid>
      :
      <Grid item xs={width}>
        <Widget26Skeleton />
      </Grid>
  )
};