import React from "react";

const IconBefectiveAI = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 11C3.59333 9.68266 6.50328 6.75161 8 3C9.4967 6.75161 12.4067 9.68268 16 11C12.4067 12.3173 9.4967 15.2484 8 19C6.50328 15.2484 3.59333 12.3173 0 11Z"
        fill="#24677E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 2.5C15.1229 2.08833 16.0323 1.17238 16.5 0C16.9677 1.17238 17.8771 2.08834 19 2.5C17.8771 2.91166 16.9677 3.82762 16.5 5C16.0323 3.82762 15.1229 2.91167 14 2.5Z"
        fill="#24677E"
      />
    </svg>
  );
};

export default IconBefectiveAI;
