import React, { useEffect, useMemo, useState } from 'react'
import Toolbar from '../../_components/_shared/Toolbar'
import Filterbar from '../../_components/_shared/Filterbar'
import { connect } from 'react-redux'
import { alertActions } from '../../_actions'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { api } from '../../_helpers/api'
import { authHeader, befectiveFormatDate, history } from '../../_helpers'
import EnhancedTable from '../../_components/_shared/EnhancedTable'
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton'
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import StatusAssessment from '../../_components/performance_assessments/PerformanceAssessmentsCreated/StatusAssessment'
import NoData from './NoData'
import { StatsCardHeader } from '../../_components/performance_assessments/PerformanceAssessmentActionPlan/StatsCardHeader'
import IconThreePoints from '../../_components/_icons/IconThreePoints'
import PopoverConfirm from '../../_components/teams_settings/PopoverConfirmDelete'
import { Box, Popover } from '@material-ui/core'

function PerformanceAssessmentActionPlanPage({
  alertActions,
  userDetails,
  filter
}) {

  
  const { t, i18n } = useTranslation("common");

  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState({
    stats: null,
    data: [],
    total: 0
  });
  const [dateRef, setDateRef] = useState(moment(new Date()).format("YYYY-MM-DD"));  

  const [firstFetch, setFirstFetch] = useState(true);
  const [firstData, setFirstData] = useState([]);

  let lang = i18n.language || "en"

  if (userDetails && userDetails.language) {
    lang = userDetails.language;
  }

  const [currentRow, setCurrentRow] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "actions-popover" : undefined;

  const handleClick = (event, row) => {
    setCurrentRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const [anchorElConfirmCancelPlan, setAnchorElConfirmCancelPlan] = useState(null);
  const openConfirmCancelPlan = Boolean(anchorElConfirmCancelPlan);
  const idConfirmCancelPlan = openConfirmCancelPlan ? 'popover-confirm-cancel-plan' : undefined;

  const handleClickConfirmCancelPlan = (event) => {
    setAnchorElConfirmCancelPlan(event.currentTarget);
  };

  const handleCloseConfirmCancelPlan = () => {
    setAnchorElConfirmCancelPlan(null)
  };
  
  const handleConfirmCancelPlan = async () => {
    try {
      if (!currentRow.id) {
        throw new Error('Action plan to delete not found')
      }

      const res = await api.delete(`/performance-assessment/action-plan/${currentRow.id}`, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro to delete assessment')
      }
      getActionPlan(
        dateRef,
        0,
        localStorage.getItem("pageSizeStorage") || 20,
        false,
        null,
        null
      )
      handleCloseConfirmCancelPlan()
      handleClose()
      
      alertActions.success(t('performance_assessment.messages.success_cancel_plan'))
    } catch (error) {
      alertActions.error(error.response.data)
      console.error(error)
    }
  }


  async function getActionPlan(
    date_ref,
    pageIndex,
    pageSize,
    isLoading,
    teams = null,
    teammates = null
  ) {

    try {

      if (isLoading) {
        setFetching(true)
      }

      const queryTeams = teams ? `&teams=${teams.map(t=>t.id).join(',')}` : ''
      const queryTeammates = teammates ? `&users=${teammates.map(t=>t.id).join(',')}` : ''
      const queryFilterYear = date_ref ? `&year=${moment(date_ref).year()}` : ''
      const response = await api.get(`/performance-assessment/action-plan?page=${pageIndex}&per_page=${pageSize}${queryFilterYear}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }
      
      if(firstFetch){
        setFirstData(response.data)
        setFirstFetch(false)
      }

      setData(response.data)

      setFetching(false);
      
    } catch (error) {
      console.log(error);
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false);
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t("performance_assessment.page_action_plan.table.col_plan"),
        accessor: "objective",
        Cell: ({ value }) => {
          return (
            <div className={"cellShrinkTable"} title={value}>
              {value}
            </div>
          )
        }
      },  
      {
        Header: t("performance_assessment.page_action_plan.table.col_colab"),
        accessor: "evaluated_name"
      }, 
      {
        Header: t("performance_assessment.page_action_plan.table.col_responsible"),
        accessor: "responsible_name"
      },   
      {
        Header: t("performance_assessment.page_action_plan.table.col_start"),
        accessor: "date_start",
        Cell: ({ value }) => {
          return befectiveFormatDate(value, lang);
        },
      },
      {
        Header: t("performance_assessment.page_action_plan.table.col_expiration"),
        accessor: "date_end",
        Cell: ({ value }) => {
          return befectiveFormatDate(value, lang);
        },
      },
      {
        Header: t("performance_assessment.page_action_plan.table.col_progress"),
        accessor: "progress",
        Cell: ({ value, row }) => {
          const currentRow = row.original;
          return (
            <div className="boxCellCircularProgress">
              <div className={`containerCircularProgress`}>
                <CircularProgressbar
                  value={Number(value)}
                  strokeWidth={50}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: currentRow.access_profile.text_color || "#4F7091",
                    trailColor: "#E4EAEF",
                  })}
                />
              </div>
              <div>{`${value || 0}%`}</div>
            </div>
          );
        },
      },
      {
        Header: t("performance_assessment.page_action_plan.table.col_status"),
        accessor: "access_profile",
        style: { padding: "4px" },
        Cell: ({ value, row }) => {
          return (
            <StatusAssessment
              title={value["label_" + lang]}
              textColor={value.text_color}
              bgColor={value.bg_color}
              height='48px'
            />
          );
        },
      },
    ];
  }, []);

  const getActionPlanData = ({pageIndex, pageSize, filter}) => {

    let date_ref = dateRef;

    if (filter.singleDate) {
      date_ref = filter.singleDate;
    }

    setDateRef(date_ref);

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getActionPlan(date_ref, pageIndex, pageSize, true, teams, teammates);
  };

  const handleRowClick = (row) => {
    return history.push(`/performance-assessment/action-plan/details/${row.id}`);
  };

  const handleClickCreateSkill = () => {
    return history.push("/performance-assessment/action-plan/create/new_action_plan");
  }

  useEffect(() => {    
    document.title = `Befective | ${t('menu.performance_assessment.action_plan')} `;  
    getActionPlan(
      dateRef,
      0,
      localStorage.getItem("pageSizeStorage") || 20,
      false,
      null,
      null
    )
  }, [])
  return (
    <section className="mainContent">
      
      <Toolbar />
     
      <Filterbar
        filterYears={"single"}
        filterWho={"multi"}
        filterWhen={"disabled"}
        filterWhat={"disabled"}
        maxDate={new Date()}        
        buttonCreate={{
          title: t("button_titles.create_action_plan"),
          handleClick: handleClickCreateSkill,
        }}
      />

      <main className='mainContentPage'>
      {!fetching ? (
        firstData.total > 0 ?
        <>
          <StatsCardHeader
            stats={data.stats}
          />
          <EnhancedTable
            columns={columns}
            data={data.data}
            filter={filter}
            fetchData={getActionPlanData}
            onRowClick={handleRowClick}
            actionColumn={(row) => (
              <div>
                <button
                  className={"buttonThreePoints"}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClick(e, row);
                  }}
                >
                  <IconThreePoints />
                </button>
              </div>
            )}
          />
        </>
        :
        <NoData />

      ) : (
        <GlobalSkeleton
          totalRowsStats={1}
          totalTables={1}
          heightTable={600}
        />
      )}
      </main>

      {/* Start Popover Actions */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            backgroundColor: "#1E6B7F",
            marginTop: "10px",
          },
        }}
      >
        <Box className={"menuOptionsActions"}>
          <button
            className={"menuOptionsActionButton"}
            onClick={() => handleRowClick(currentRow)}
          >
            {t("performance_assessment.actions.edit")}
          </button>
          <button
            className={"menuOptionsActionButton"}
            onClick={handleClickConfirmCancelPlan}
          >
            {t("performance_assessment.actions.cancel_plan")}
          </button>
        </Box>
      </Popover>
      {/* End Popover Actions */}

      {/* Start Confirm Dialog Delete Plan */}
      <PopoverConfirm
        idConfirm={idConfirmCancelPlan}
        openConfirm={openConfirmCancelPlan}
        anchorEl={anchorElConfirmCancelPlan}
        handleClose={handleCloseConfirmCancelPlan}
        handleConfirm={handleConfirmCancelPlan}
        title={t('performance_assessment.messages.cancel_plan')}
      />
      {/* End Confirm Dialog Delete Plan */}

    </section>
  )
}



function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceAssessmentActionPlanPage)