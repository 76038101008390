import React from 'react'
import styles from './ProductivityGroupPercent.module.scss'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'


export default function ProductivityGroupPercent({
  percent_working_day = 0,
  percent_performance = 0
}) {
  return (
    <div className={styles.containerProductivity}>
      <div>
        <div className={styles.boxCircularProgress}>
          <CircularProgressbar
            value={Number(percent_working_day)}
            strokeWidth={50}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: "#187DFF",
              trailColor: "#E4EAEF",
            })}
          />
        </div>
        <div>{percent_working_day}%</div>
      </div>
      <div>
        <div className={styles.boxCircularProgress}>
          <CircularProgressbar
            value={Number(percent_performance)}
            strokeWidth={50}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: "#22BEAD",
              trailColor: "#E4EAEF",
            })}
          />
        </div>
        <div>{percent_performance}%</div>
      </div>
    </div>
  )
}
