// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BxrJPe1NNc5bCHjyE-B7vw\\=\\= {\n  font-size: 12px;\n  cursor: pointer;\n  padding: 4px;\n  border-radius: 4px;\n  margin-bottom: 2px;\n}\n\n.BxrJPe1NNc5bCHjyE-B7vw\\=\\=.aUz3pjZTFh6NepfWQc-XxQ\\=\\= {\n  color: #FC6662;\n  background-color: #FFE0DF;\n}\n\n.BxrJPe1NNc5bCHjyE-B7vw\\=\\=.QIjMGp8eBmfwvvLMVrVZjg\\=\\= {\n  color: #22BEAD;\n  background-color: #DBF5F2;\n}\n\n.BxrJPe1NNc5bCHjyE-B7vw\\=\\=.T3OT2n\\+RDA0f4WpYIyOWdQ\\=\\= {\n  color: #FDA02B;\n  background-color: #FFECD4;\n}\n\n.tOQdzU6nqbJ90Fj1X1\\+iwg\\=\\= {\n  color: #FC6662;\n}\n\n.T84eo5Z3EagPJxcCh7JbMQ\\=\\= {\n  color: #22BEAD;\n}\n\n.gFNy-aMtMXLN\\+Yxr6H8\\+7Q\\=\\= {\n  color: #FDA02B;\n}\n\n.Bc6QvrNDVzZs23uJlRRQRg\\=\\= {\n  color: #24677E;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n  margin-top: 0px;\n  margin-bottom: 14px;\n}", "",{"version":3,"sources":["webpack://./src/_components/productivity/InactivityFull.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,yBAAA;AACF;;AAEA;EACE,cAAA;EACA,yBAAA;AACF;;AAEA;EACE,cAAA;EACA,yBAAA;AACF;;AAGA;EACE,cAAA;AAAF;;AAGA;EACE,cAAA;AAAF;;AAGA;EACE,cAAA;AAAF;;AAGA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,mBAAA;AAAF","sourcesContent":[".event{\n  font-size: 12px;\n  cursor: pointer;\n  padding: 4px;\n  border-radius: 4px;\n  margin-bottom: 2px;\n}\n\n.event.unproductive{\n  color: #FC6662;\n  background-color: #FFE0DF;\n}\n\n.event.productive{\n  color: #22BEAD;\n  background-color: #DBF5F2;\n}\n\n.event.neutral{\n  color: #FDA02B;\n  background-color: #FFECD4;\n}\n\n\n.color_unproductive{\n  color: #FC6662;\n}\n\n.color_productive{\n  color: #22BEAD;\n}\n\n.color_neutral{\n  color: #FDA02B;\n}\n\n.titleForm{\n  color: #24677E;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n  margin-top: 0px;\n  margin-bottom: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"event": "BxrJPe1NNc5bCHjyE-B7vw==",
	"unproductive": "aUz3pjZTFh6NepfWQc-XxQ==",
	"productive": "QIjMGp8eBmfwvvLMVrVZjg==",
	"neutral": "T3OT2n+RDA0f4WpYIyOWdQ==",
	"color_unproductive": "tOQdzU6nqbJ90Fj1X1+iwg==",
	"color_productive": "T84eo5Z3EagPJxcCh7JbMQ==",
	"color_neutral": "gFNy-aMtMXLN+Yxr6H8+7Q==",
	"titleForm": "Bc6QvrNDVzZs23uJlRRQRg=="
};
export default ___CSS_LOADER_EXPORT___;
