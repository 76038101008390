import React from "react";

const IconBefectiveAIFull = (props) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="4" fill="#FDBE2B" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7059 24C17.7789 22.1402 21.887 18.0023 24.0001 12.7059C26.113 18.0023 30.2212 22.1402 35.2942 24C30.2212 25.8597 26.113 29.9977 24.0001 35.2941C21.887 29.9977 17.7789 25.8598 12.7059 24Z"
        fill="#24677E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.4706 12C34.0559 11.4188 35.3397 10.1257 36 8.47058C36.6603 10.1257 37.9441 11.4188 39.5294 12C37.9441 12.5812 36.6603 13.8743 36 15.5294C35.3397 13.8743 34.0559 12.5812 32.4706 12Z"
        fill="#24677E"
      />
    </svg>
  );
};

export default IconBefectiveAIFull;
