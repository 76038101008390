import { Box, Container, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { ArrowRight, CalendarCheck, Moon, Sun, Timer } from 'phosphor-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { alertActions, usersActions } from '../../_actions'
import style from './ProductivityPage.module.scss'
import { authHeader, befectiveFormatDate, calcWidthWidget, formatNumber, getDayWeekV2, getMoodById, getRandomNumber, history, secondsToHm } from '../../_helpers'
import { api } from '../../_helpers/api'
import AlertBox from '../../_components/_shared/AlertBox'
import Header from '../../_components/productivity/Header'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import ReactApexChart from 'react-apexcharts'
import { Chart as ChartJS, ArcElement, Tooltip as TooltipChart, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import RulerTrackeds from '../../_components/productivity/RulerTrackeds'
import moment from 'moment'
import Top10ActivitiesColab from '../../_components/productivity/Top10ActivitiesColab'
import SkeletonProductivityPage from './SkeletonProductivityPage'
import { ModalAvgStartEndDetails } from '../../_components/productivity/ModalAvgStartEndDetails'
import { IconButton } from '@material-ui/core'
import HeaderProductivityColab from '../../_components/_shared/HeaderProductivityColab'


ChartJS.register(ArcElement, TooltipChart, Legend, ChartDataLabels);

function ProductivityPage({ userDetails, usersActions, alertActions, filter }) {
  const { t, i18n } = useTranslation('common');
  const [fetching, setFetching] = useState(true)
  const [openModalAvgStartEndDetails, setOpenModalAvgStartEndDetails] = useState(false)
  const current_day = moment().format('YYYY-MM-DD HH:mm')

  const [data, setData] = useState({
    ruler_data: {
      stats: {
        start_time_value: '00:00',
        is_late: false,
        worked_time: 0,
        worked_time_percent: 0,
        productive_time: 0,
        productive_time_percent: 0,
        closed_time: 0,
        closed_time_percent: 0,
        period_greatest_productivity: '00:00 - 00:00',
        online_time: 0,
        online_time_productive_percent: 0,
        online_time_neutral_percent: 0,
        online_time_unproductive_percent: 0,
        offline_time: 0,
        offline_time_productive_percent: 0,
        offline_time_neutral_percent: 0,
        offline_time_unproductive_percent: 0,
        mood_today: null
      },
      start_time: '00:00',
      end_time: '00:00',
      trackeds: []
    },
    chart_donut_worked_time: {
      worked_time: 0,
      labels: [t('colab_page.unproductive'), t('colab_page.neutral'), t('colab_page.productive'), t('colab_page.missing')],
      data: [0, 0, 0, 0]
    },
    chart_donut_productive_time: {
      productive_time: 0,
      status_productive_target: 1,
      target_productive_percent: 0,
      labels: [t('colab_page.productive'), t('colab_page.missing')],
      data: [0, 0]
    },
    last_activities_today: [],
    chart_comparison_months: {
      is_more_productive_last_month: true,
      percent_more_productive: 0,
      series: [],
      categories: []
    },
    simple_cards: {
      date_most_productive: '--/--',
      day_most_productive: 0,
      avg_work_daily: 0,
      start_time_avg: 0,
      end_time_avg: 0
    },
    apps_most_used_month: []
  })
  const [sending, setSending] = useState(false);
  const lang = userDetails.language || i18n.language

  const [chartWorkedTime, setChartWorkedTime] = useState({
    data: {
      labels: [t('colab_page.unproductive'), t('colab_page.neutral'), t('colab_page.productive'), t('colab_page.missing')],
      datasets: [
        {
          data: [0, 0, 0, 100],
          backgroundColor: [
            '#FC6162',
            '#F5C220',
            '#22BEAD',
            '#D2DEEC'
          ],
          borderWidth: 1
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false, // Desabilita a legenda
        },
        datalabels: {
          formatter: function (value, context) {
            if (context.dataIndex !== 3)
              return `${value}%`; //for number format
            return ''
          },
          color: "white",
          font: {
            size: 14
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.label || '';

              if (label) {
                label += ': ';
              }
              label += context.formattedValue + '%';
              return label;
            }
          }
        }
      }
    },
    plugins: [
      {
        id: 'textCenter',
        beforeDatasetDraw(chart, args, pluginOptions) {
          const { ctx, data } = chart;
          ctx.save();
          ctx.font = 'bolder 28px sans-serif';
          ctx.fillStyle = '#22BEAD';
          ctx.textAlign = 'center'
          ctx.textBaseline = 'middle'
          ctx.fillText(`0%`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
        }
      }
    ]
  })

  const [chartProductiveTime, setChartProductiveTime] = useState({
    data: {
      labels: [t('colab_page.productive'), t('colab_page.missing')],
      datasets: [
        {
          data: [0, 100],
          backgroundColor: ['#187DFF', '#D2DEEC'],
          borderWidth: 1
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false, // Desabilita a legenda
        },
        datalabels: {
          formatter: function (value, context) {
            return ''
          },
          color: "white",
          font: {
            size: 14
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.label || '';

              if (label) {
                label += ': ';
              }
              label += context.formattedValue + '%';
              return label;
            }
          }
        }
      }
    },
    plugins: [
      {
        id: 'textCenter',
        beforeDatasetDraw(chart, args, pluginOptions) {
          const { ctx, data } = chart;
          ctx.save();
          ctx.font = 'bolder 28px sans-serif';
          ctx.fillStyle = '#187DFF';
          ctx.textAlign = 'center'
          ctx.textBaseline = 'middle'
          ctx.fillText(`0%`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
        }
      }
    ]
  })

  const [chartComparisonMonths, setChartComparisonMonths] = useState({
    series: [],
    options: {
      chart: {
        height: 300,
        type: 'area',
        zoom: {
          enabled: false
        },
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#F5C220', '#22BEAD'],
      stroke: {
        width: 2,
        curve: 'straight'
      },
      // annotations: {
      //   xaxis: [{
      //     x: "07",
      //     borderColor: '#FDBE2B',
      //     label: {
      //       borderColor: '#FDBE2B',
      //       style: {
      //         color: '#fff',
      //         background: '#FDBE2B',
      //       },
      //       text: `07h`,
      //       orientation: "horizontal",
      //       formatter: (value) => {
      //         console.log(value)
      //         return value + 'h';
      //       }
      //     }

      //   }]
      // },
      xaxis: {
        categories: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29"],
        labels: {
          style: {
            fontSize: '8px',
            colors: '#4F7091'
          }
        }
      },

      yaxis: {
        labels: {
          formatter: (value) => {
            return secondsToHm(value);
          },
          style: {
            colors: '#4F7091'
          }
        }
      },
      legend: {
        show: false
      }
    }
  })


  const getData = async (isFetching = true, filterMonth = moment(new Date()).format('YYYY-MM')) => {

    if (isFetching) {
      setFetching(true)
    }

    try {

      const res = await api.get(`/users/new-productivity?current_day=${current_day}&mu_id=${userDetails.id}&year_month=${filterMonth}`, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error("Falha ao carregar os dados")
      }

      const { data } = res
      if (data && data.chart_comparison_months.series.length > 0) {
        setChartComparisonMonths({
          options: {
            ...chartComparisonMonths.options,
            xaxis: {
              ...chartComparisonMonths.options.xaxis,
              categories: data.chart_comparison_months.categories
            }
          },
          series: data.chart_comparison_months.series,
        })
      }
      if (data && data.chart_donut_worked_time.data.length > 0) {

        setChartWorkedTime({
          ...chartWorkedTime,
          data: {
            ...chartWorkedTime.data,
            datasets: [
              {
                data: data.chart_donut_worked_time.data,
                backgroundColor: [
                  '#FC6162',
                  '#F5C220',
                  '#22BEAD',
                  '#D2DEEC'
                ],
                borderWidth: 1,
                workedTimePercent: data.chart_donut_worked_time.month_worked_time_percent
              },
            ],
          },
          plugins: [
            {
              id: 'textCenter',
              beforeDatasetDraw(chart, args, pluginOptions) {
                const { ctx, data } = chart;
                ctx.save();
                ctx.font = 'bolder 24px sans-serif';
                ctx.fillStyle = '#22BEAD';
                ctx.textAlign = 'center'
                ctx.textBaseline = 'middle'
                ctx.fillText(`${formatNumber(Number(data.datasets[0].workedTimePercent).toFixed(1), i18n.language)}%`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
              }
            }
          ]
        })
      }
      if (data && data.chart_donut_productive_time.data.length > 0) {
        setChartProductiveTime({
          ...chartProductiveTime,
          data: {
            ...chartProductiveTime.data,
            datasets: [
              {
                data: data.chart_donut_productive_time.data,
                backgroundColor: ['#187DFF', '#D2DEEC'],
                borderWidth: 1,
                productiveTimePercent: data.chart_donut_productive_time.month_produtive_time_percent
              },
            ],
          },
          plugins: [
            {
              id: 'textCenterProductive',
              beforeDatasetDraw(chart, args, pluginOptions) {
                const { ctx, data } = chart;
                ctx.save();
                ctx.font = 'bolder 24px sans-serif';
                ctx.fillStyle = '#187DFF';
                ctx.textAlign = 'center'
                ctx.textBaseline = 'middle'
                ctx.fillText(`${formatNumber(Number(data.datasets[0].productiveTimePercent).toFixed(1), i18n.language)}%`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
              }
            }
          ]
        })
      }
      setData(data)
    } catch (error) {
      console.log(error)
    } finally {
      setFetching(false)
    }
  }

  const handleOpenModalAvgStartEndDetails = () => {
    setOpenModalAvgStartEndDetails(true)
  }

  const handleCloseModalAvgStartEndDetails = () => {
    setOpenModalAvgStartEndDetails(false)
  }

  useEffect(() => {
    const date = filter.singleDate ? moment(filter.singleDate).format('YYYY-MM') : moment(new Date()).format('YYYY-MM');
    getData(true, date)
  }, [filter])

  useEffect(() => {

    document.title ='Befective | ' + t('home.my_productivity');

  }, [])


  return (
    <Box
      bgcolor="#F2F5FA"
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <AlertBox />

      <HeaderProductivityColab
        activeMenu="productivity"
        optionsExtra={true}
        showMonthFilter={true}
      />


      {
        (!fetching && data !== null)
          ?
          <Container component={'main'} maxWidth="xl" className={style.containerMain} sx={{ flexGrow: 1, marginTop:'-80px'}}>
            <Grid container spacing={0} mb={'16px'} paddingX={'32px'}>
              <Grid item xs={12}>
                <div className={style.rulerContainer}>
                  <table className={style.table}>
                    <thead>
                      <th style={{width:'80px'}}>{t('colab_page.ruler.date')}</th>
                      <th>{t('colab_page.ruler.start')}</th>
                      <th>{t('standards.working_day')}</th>
                      <th>{t('standards.performance')}</th>
                      <th>{t('colab_page.ruler.closed_time')}</th>
                      <th>{t('colab_page.ruler.period_greatest_productivity')}</th>
                      <th>{t('colab_page.ruler.online')}</th>
                      <th>{t('colab_page.ruler.offline')}</th>
                      <th style={{ textAlign: 'center' }}>{t('colab_page.ruler.mood')}</th>
                      <th style={{width:'10px'}}/>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {t('colab_page.ruler.today')}
                        </td>
                        <td style={{ color: data.ruler_data.stats.is_late ? '#FF7A90' : '#4F7091' }}>
                          {data.ruler_data.stats.start_time_value}
                        </td>
                        <td>
                          <div className={style.boxCircularProgress}>
                            <div className={`${style.circularProgress} ${style.borderPrimary}`} >
                              <CircularProgressbar
                                value={data.ruler_data.stats.worked_time_percent}
                                strokeWidth={50}
                                styles={buildStyles({
                                  strokeLinecap: "butt",
                                  pathColor: '#187DFF',
                                  trailColor: '#E4EAEF',
                                })}
                              />
                            </div>
                            <div>
                              {secondsToHm(data.ruler_data.stats.worked_time)}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className={style.boxCircularProgress}>
                            <div className={`${style.circularProgress} ${style.borderCompleted}`} >
                              <CircularProgressbar
                                value={data.ruler_data.stats.productive_time_percent}
                                strokeWidth={50}
                                styles={buildStyles({
                                  strokeLinecap: "butt",
                                  pathColor: '#22BEAD',
                                  trailColor: '#E4F7F5',
                                })}
                              />
                            </div>
                            <div>
                              {secondsToHm(data.ruler_data.stats.productive_time)}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className={style.boxCircularProgress}>
                            <div className={`${style.circularProgress} ${style.borderPlanned}`} >
                              <CircularProgressbar
                                value={data.ruler_data.stats.closed_time_percent}
                                strokeWidth={50}
                                styles={buildStyles({
                                  strokeLinecap: "butt",
                                  pathColor: '#98ACC1',
                                  trailColor: '#E4EAEF',
                                })}
                              />
                            </div>
                            <div>
                              {secondsToHm(data.ruler_data.stats.closed_time)}
                            </div>
                          </div>
                        </td>
                        <td>
                          {data.ruler_data.stats.period_greatest_productivity}
                        </td>
                        <td>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <div>{secondsToHm(data.ruler_data.stats.online_time)}</div>
                            <div className={style.barchart}>
                              <div className={style.bar} style={{ width: `${data.ruler_data.stats.online_time_productive_percent}%` }}></div>
                              <div className={`${style.bar} ${style.neutral}`} style={{ width: `${data.ruler_data.stats.online_time_neutral_percent}%` }}></div>
                              <div className={`${style.bar} ${style.unrelated}`} style={{ width: `${data.ruler_data.stats.online_time_unproductive_percent}%` }}></div>
                            </div>
                          </div>
                        </td>
                        <td>

                          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <div>{secondsToHm(data.ruler_data.stats.offline_time)}</div>
                            <div className={style.barchart}>
                              <div className={style.bar} style={{ width: `${data.ruler_data.stats.offline_time_productive_percent}%` }}></div>
                              <div className={`${style.bar} ${style.neutral}`} style={{ width: `${data.ruler_data.stats.offline_time_neutral_percent}%` }}></div>
                              <div className={`${style.bar} ${style.unrelated}`} style={{ width: `${data.ruler_data.stats.offline_time_unproductive_percent}%` }}></div>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {
                              (data.ruler_data.stats.mood_today && data.ruler_data.stats.mood_today > 0)
                                ?
                                <Tooltip arrow title={t(`moods_settings.moods.${getMoodById(data.ruler_data.stats.mood_today).key}`)}>
                                  <img src={getMoodById(data.ruler_data.stats.mood_today).img} width={18} loading='lazy'/>
                                </Tooltip>
                                :
                                '--'
                            }
                          </div>
                        </td>

                        <td>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <IconButton
                              className={`button-options`}
                              aria-label="expand row"
                              size="small"
                              onClick={() => history.push('/productive/productivity/details')}
                            >
                              <ArrowRight size={16} weight='bold'/>
                            </IconButton>
                          </div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                  {/* <RulerTrackeds data={data.ruler_data}/> */}
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1} mb={'16px'} paddingX={'32px'}>
              <Grid item xs={12} sm={6}>
                <div className={style.card}>
                  <div className={style.boxTitle}>
                    {
                      data.chart_comparison_months.is_more_productive_last_month
                        ?
                        <h4>{`${data.chart_comparison_months.percent_more_productive}% ${t('colab_page.title_comparision_month_down')}`}</h4>
                        :
                        <h4>{`${data.chart_comparison_months.percent_more_productive}% ${t('colab_page.title_comparision_month_up')}`}</h4>
                    }
                  </div>
                  <div className={style.boxLegend}>
                    <div className={style.legend}>
                      <div className={`${style.circle} ${style.green}`}></div>
                      <div className={style.textLegend}>{t('colab_page.this_month')}</div>
                    </div>
                    <div className={style.legend}>
                      <div className={`${style.circle} ${style.yellow}`}></div>
                      <div className={style.textLegend}>{t('colab_page.last_month')}</div>
                    </div>
                  </div>
                  <div className={style.chartContainer}>
                    <ReactApexChart options={chartComparisonMonths.options} series={chartComparisonMonths.series} type="area" height={300} className={'productiveChart'} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={style.card}>
                  <div className={style.boxTitle}>
                    <h4>{`${t('standards.working_day')}: ${secondsToHm(data.chart_donut_worked_time.worked_time)}`}</h4>
                  </div>
                  <div className={style.boxLegend}>
                    <div className={style.legend}>
                      <div className={`${style.circle} ${style.green}`}></div>
                      <div className={style.textLegend}>{t('colab_page.productive')}</div>
                    </div>
                    <div className={style.legend}>
                      <div className={`${style.circle} ${style.yellow}`}></div>
                      <div className={style.textLegend}>{t('colab_page.neutral')}</div>
                    </div>
                    <div className={style.legend}>
                      <div className={`${style.circle} ${style.red}`}></div>
                      <div className={style.textLegend}>{t('colab_page.unproductive')}</div>
                    </div>
                  </div>
                  <div className={style.chartContainer} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '260px', height: '260px' }}>
                      <Doughnut
                        data={chartWorkedTime.data}
                        plugins={chartWorkedTime.plugins}
                        options={chartWorkedTime.options}
                      />
                    </div>
                  </div>
                  <div className={style.footerInfo}>
                    {t('colab_page.worked_time_info')}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={style.card}>
                  <div className={style.boxTitle}>
                    <h4>{`${t('standards.performance')}: ${secondsToHm(data.chart_donut_productive_time.productive_time)}`}</h4>
                  </div>
                  <div className={style.boxLegend}>
                    <div className={style.legend}>
                      <div className={style.textLegend}>
                        {
                          data.chart_donut_productive_time.status_productive_target
                            ?
                            t('colab_page.productive_time_label3')
                            :
                            t('colab_page.productive_time_label1')
                        }
                        {/* {data.chart_donut_productive_time.status_productive_target === 1 && t('colab_page.productive_time_label1')}
                        {data.chart_donut_productive_time.status_productive_target === 2 && t('colab_page.productive_time_label2')}
                        {data.chart_donut_productive_time.status_productive_target === 3 && t('colab_page.productive_time_label3')} */}
                      </div>
                    </div>
                  </div>
                  <div className={style.chartContainer} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '260px', height: '260px' }}>
                      <Doughnut
                        data={chartProductiveTime.data}
                        plugins={chartProductiveTime.plugins}
                        options={chartProductiveTime.options}
                      />
                    </div>
                  </div>
                  <div className={style.footerInfo}>
                    {`${t('colab_page.productive_time_info')} ${data.chart_donut_productive_time.target_productive_percent}%`}
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1} mb={'16px'} paddingX={'32px'}>

              <Grid item xs={12} sm={3}>
                <div className={style.cardSmall}>
                  <div className={style.boxIcon}>
                    <CalendarCheck size={42} weight="bold" color="#A9C6D0" />
                  </div>
                  <div className={style.boxInfo}>
                    <h6>{`${data.simple_cards.date_most_productive} (${getDayWeekV2(data.simple_cards.day_most_productive, false, t)})`}</h6>
                    <span>{t('colab_page.simple_cards.day_most_productive')}</span>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={3}>
                <div className={style.cardSmall}>
                  <div className={style.boxIcon}>
                    <Timer size={42} weight="bold" color="#A9C6D0" />
                  </div>
                  <div className={style.boxInfo}>
                    <h6>{secondsToHm(data.simple_cards.avg_work_daily)}</h6>
                    <span>{t('colab_page.simple_cards.avg_work_daily')}</span>
                  </div>

                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={style.cardSmall} style={{ cursor: 'pointer' }} onClick={() => handleOpenModalAvgStartEndDetails('start_time')}>
                  <div className={style.boxIcon}>
                    <Sun size={42} weight="bold" color="#A9C6D0" />
                  </div>
                  <div className={style.boxInfo}>
                    <h6>{secondsToHm(data.simple_cards.start_time_avg)}</h6>
                    <span>{t('colab_page.simple_cards.start_time_avg')}</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div className={style.cardSmall} style={{ cursor: 'pointer' }} onClick={() => handleOpenModalAvgStartEndDetails('end_time')}>
                  <div className={style.boxIcon}>
                    <Moon size={42} weight="bold" color="#A9C6D0" />
                  </div>
                  <div className={style.boxInfo}>
                    <h6>{secondsToHm(data.simple_cards.end_time_avg)}</h6>
                    <span>{t('colab_page.simple_cards.end_time_avg')}</span>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1} mb={'16px'} paddingX={'32px'}>
              <Grid item xs={12} sm={8}>
                <Top10ActivitiesColab width={calcWidthWidget(100)} userId={userDetails.id} />
                {/* <div className={style.card}>
                  <div className={style.boxTitle}>
                    <h4>{t('colab_page.last_activities_today.title')}</h4>
                  </div>
                  <div style={{maxHeight:'400px', overflow:'auto'}}>
                    <table className={style.table}>
                      <thead>
                        <th>{t('colab_page.last_activities_today.col_hour')}</th>
                        <th>{t('colab_page.last_activities_today.col_activity')}</th>
                        <th>{t('colab_page.last_activities_today.col_time_used')}</th>
                        <th>{t('colab_page.last_activities_today.col_project')}</th>
                        <th>{t('colab_page.last_activities_today.col_comment')}</th>
                      </thead>
                      <tbody>
                        {
                          data && data.last_activities_today.length > 0
                            ?
                            data.last_activities_today.map((activity, index1) => (
                              activity.trackeds.map((tracked, index2) => (
                                <tr key="">
                                  {index2 === 0 && <td rowSpan={activity.trackeds.length} style={{ borderRight: '1px solid #D2DEEC' }}>{activity.interval}</td>}
                                  <td>{tracked.tracked_name}</td>
                                  <td>{secondsToHm(tracked.duration, true)}</td>
                                  <td>{tracked.project_name}</td>
                                  <td>{tracked.comment}</td>
                                </tr>
                              ))
                            ))
                            :
                            <tr key="">
                              <th colSpan={5}>
                                {t('colab_page.last_activities_today.none_activity')}
                              </th>
                            </tr>
                        }
                      </tbody>
                    </table>

                  </div>
                </div> */}
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={style.card}>
                  <div className={style.boxTitle}>
                    <h4>{t('colab_page.apps_most_used_month_title')}</h4>
                  </div>
                  <div className={style.containerApps}>
                    {
                      data.apps_most_used_month.length > 0
                        ?
                        <>
                          <div className={style.boxChartBar}>
                            {
                              data.apps_most_used_month.map((app, index) => (
                                <div key={`bar-app-${index}`} className={`${style.barChartApp} ${style[`${app.efficiency_name}`]}`} style={{ height: `${app.percent_usage}%`, maxHeight: '160px', width: `${100 / data.apps_most_used_month.length}%` }}>
                                  <label className={style.labelPercentApp}>{`${app.percent_usage}%`}</label>
                                </div>
                              ))
                            }
                          </div>
                          <div className={style.boxListApps}>
                            {
                              data.apps_most_used_month.map((app, index) => (
                                <div key={`item-app-${index}`} className={style.listItemApp}>
                                  <div className={style.textItemApp}>{`${index + 1}. ${app.name}`}</div>
                                  <div className={style.textItemApp}>{secondsToHm(app.duration, true)}</div>
                                </div>
                              ))
                            }
                          </div>
                        </>
                        :
                        <div style={{ color: '#1E6B7F' }}>
                          {t('colab_page.apps_most_used_month_none')}
                        </div>
                    }
                  </div>
                </div>
              </Grid>
            </Grid>
            <ModalAvgStartEndDetails
              openModal={openModalAvgStartEndDetails}
              closeModal={handleCloseModalAvgStartEndDetails}
              filterMonth={filter.singleDate ? moment(filter.singleDate).format('YYYY-MM') : moment(new Date()).format('YYYY-MM')}
            />
          </Container>

          :

          <SkeletonProductivityPage />
      }
    </Box >
  )
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter
  }
}

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductivityPage));