import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import Toolbar from '../../_components/_shared/Toolbar'
import Filterbar from '../../_components/_shared/Filterbar'
import {useTranslation} from "react-i18next";
import moment from 'moment';
import {filterActions, historicalActions} from '../../_actions';
import {useHistory} from "react-router-dom";
import { api } from '../../_helpers/api.js';
import { authHeader } from '../../_helpers/auth-header.js';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton.jsx';
import TransactionalChartSummaryHour from '../../_components/reports/transactional/TransactionalChartSummaryHour.jsx';

function ProductiveReportsTransactionalChartHourPage(props) {
    const {t, i18n} = useTranslation('common');
    const {filterActions, filter} = props;
    const history = useHistory();    
    const [fetching, setFetching] = useState(true);
    const [data, setData] = useState(null);
    const [openWho, setOpenWho] = useState('');
    const [dayToShow, setDayToShow] = useState(null);
    let startDate = new Date(moment().add(-8, 'd')).toISOString().split('T')[0];
    let endDate = new Date(moment()).toISOString().split('T')[0];

    const isMountedTeammates = useRef(false);
    const isMountedDate = useRef(false);

    const getData = async (isLoading = true, dayRef = null, userId = null) => {
      try {

        if (isLoading) {
          setFetching(true)
        }

        const response = await api.get(`/report/transactional/graphic/detail?day_ref=${dayRef}&user_id=${userId}`, { 
          headers: authHeader()
        })

        setData(response.data)

        setFetching(false)

      } catch (error) {
        console.log(error)
        //alertActions.error(t('generic_messages.errors.load'))
        setFetching(false)
      }
      
    }

    useEffect(() => {    
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      setDayToShow(params.day)

      if (params.mu && params.day) {
          getData(true, params.day, params.mu);
          let id = params.mu;
          filterActions.replace(params.day, "singleWhen");
          filterActions.replace({id}, "teammates");
      }

      document.title = 'Befective | ' + t('productive_reports.card_reports.transactional');
    }, [])
    
    useEffect(() => {

        if (isMountedTeammates.current) {
          let teammates = null;
          if (filter.teammates && filter.teammates[0] && dayToShow) {
              teammates = filter.teammates;
              history.push("/productive/reports/transactional/chart-view/hour?mu=" + teammates[0].id + "&day=" + dayToShow)
              getData(true, dayToShow, teammates[0].id);
          }
        } else {
            isMountedTeammates.current = true;
        }

    }, [filter.teammates]);
    
    useEffect(() => {
      
      if (isMountedDate.current) {
        let teammates = null;

        if (filter.singleDate) {
            setDayToShow(filter.singleDate)
            if (filter.teammates && filter.teammates[0]) {
              teammates = filter.teammates;
              history.push("/productive/reports/transactional/chart-view/hour?mu=" + teammates[0].id + "&day=" + filter.singleDate)
              getData(true, filter.singleDate, teammates[0].id);
            }
        }
      } else {
          isMountedDate.current = true;
      }

    }, [filter.singleDate]);


    return (
        <React.Fragment>

            <section className="mainContent">
                <Toolbar/>
                <Filterbar 
                  routeBackToPage={'/productive/reports/transactional/chart-view'}
                  defaultStartDate={startDate}
                  defaultEndDate={endDate}
                  openWho={openWho}
                  filterWho={'singleTeammates'}
                  filterWhen={'single'}
                  filterWhat={'disabled'}
                  //maxDate={maxDate}
                />
                {/* PAGE STARTS */}
                <div className="mainPage">
                    <div className="column single">
                        {!fetching
                          ?
                          <TransactionalChartSummaryHour 
                            data={data}
                            day={dayToShow} 
                            back={() => {
                              setOpenWho('');
                              setDayToShow(null);
                              filterActions.clear('who')
                          }}/>
                          :
                          <GlobalSkeleton totalRowsStats={0} totalTables={1} heightTable={600} />
                        }
                    </div>
                </div>
                {/* PAGE ENDS */}

            </section>

        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        historicalActions: bindActionCreators(historicalActions, dispatch),
        filterActions: bindActionCreators(filterActions, dispatch)
    }
}


function mapStateToProps(state) {

    return {
        loading: state.historical.loading,
        filter: state.filter,
        historical: state.historical
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductiveReportsTransactionalChartHourPage)
