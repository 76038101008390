import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import "react-datepicker/dist/react-datepicker.css";

import { filterActions, statsActions, teammatesActions, teamsActions } from '../../_actions';

import { useTranslation } from "react-i18next";
import ButtonActionsGroup from '../goals/ButtonActionsGroup';
import CountLabel from '../goals/TotalLabel';
import ShareButton from "./ShareButton";
import What from "./filters/What";
import WhatSkeleton from "./filters/WhatSkeleton";
import WhenMulti from "./filters/WhenMulti";
import WhenMultiSkeleton from "./filters/WhenMultiSkeleton";
import WhenSingle from "./filters/WhenSingle";
import WhenSingleSkeleton from "./filters/WhenSingleSkeleton";
import WhoMulti from "./filters/WhoMulti";
import WhoMultiSkeleton from "./filters/WhoMultiSkeleton";
import WhoSingleTeam from "./filters/WhoSingleTeam";
import WhoSingleTeamSkeleton from "./filters/WhoSingleTeamSkeleton";
import WhoSingleTeammate from "./filters/WhoSingleTeammate";
import WhoSingleTeammateSkeleton from "./filters/WhoSingleTeammateSkeleton";

import { Button, ClickAwayListener, Grow, ListItemIcon, Menu, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Checkbox, FormControl, OutlinedInput, Popover, Select, Typography } from '@mui/material';
import { CalendarBlank, ChartBar, FileArrowDown, Gear, HouseSimple, Kanban, List, MapTrifold, PresentationChart, Robot, SignIn, SquaresFour, Users } from 'phosphor-react';
import { NavLink, useHistory } from 'react-router-dom';
import IconBack from '../../_components/_icons/IconBack';
import { authHeader, befectiveFormatDate, getDynamicRedirectUrl } from '../../_helpers';
import IconJustify from '../_icons/IconJustify';
import { ChangeMyActivitiesButtons } from '../productivity/MyActivities/ChangeMyActivitiesButtons';
import PeriodSingleGoal from './filters/PeriodSingleGoal';
import PeriodSingleGoalSkeleton from './filters/PeriodSingleGoalSkeleton';
import { WhenMonthMulti } from './filters/WhenMonthMulti';
import WhenSingleMonths from './filters/WhenSingleMonths';

import { useRoomsFilter } from '../../contexts/RoomsFilterContext';
import './Filterbar.scss';
import CustomSelect from './CustomSelect';
import { ArrowDropDown, ImportExport, PersonAdd, Settings } from '@material-ui/icons';
import WorkspacesFilter from './filters/WorkspacesFilter';
import RecentBoardsFilter from './filters/RecentBoardsFilter';
import IconImport from '../_icons/IconImport';
import IconList from '../_icons/IconList';
import IconCalendar from '../_icons/IconCalendar';
import IconRoomCalendar from '../_icons/IconRoomCalendar';
import IconCalendarFA from '../_icons/IconCalendarFA';
import WhenSingleYears from './filters/WhenSingleYears';
import ButtonCreate from './ButtonCreate';
import SkillTypesFilter from './filters/SkillTypesFilter';
import SkillTypesFilterSkeleton from './filters/SkillTypesFilterSkeleton';
import AssessmentsFilter from './filters/AssessmentsFilter';
import { api } from '../../_helpers/api';
import AssessmentsFilterDashboard from './filters/AssessmentsFilterDashboard';
import ProfilesFilter from './filters/ProfilesFilter';

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(0),
		},
	},
	button: {
		minWidth: '130px',
		minHeight: '42px',
		backgroundColor: '#FDBE2B',
		borderRadius: '2px',
		cursor: 'pointer',
		fontWeight: '600',
		border: 'none',
		color: '#106276',
		textTransform: 'none',
		boxShadow: 'none',
		transition: '0.2s',

		'&:hover': {
			backgroundColor: '#FDBE2B',
			boxShadow: '0px 0px 4px 0px #2B2B2B55',
		},
		'& > span': {
			fontSize: '13px',
			display: 'flex',
			flexWrap: 'nowrap'
		}
	}

}));

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
// 	PaperProps: {
// 		style: {
// 			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
// 			width: 250,
// 			backgroundColor: '#1E6B7F !important',
// 		},
// 	},
// };



function Filterbar(props) {
	const { rooms, setRooms } = useRoomsFilter()

	const { t, i18n } = useTranslation('common');
	const history = useHistory();

	const {
		teamsActions,
		teammatesActions,
		setToggleDrawerAddCall,
		setShowCalendar,
		showCalendar,
		showMaps,
		setShowMaps,
		setAccountMenu,
		accountMenu,
		accountsLogged,
		handleGoogleLogin,
		isLoadingEvents,
		handleMicrosoftLogin,
		setOpenModalNewMessage,
		org_id,
		token,
		userDetails,
		handleClickMenuAccounts,
		teammatesDetail,
		newRoom,
		tabSettingsRoom,
		tabSettingsWorkspaceTypes,
		createAttribute,
		createMap,
		createWorkspaceType,
		setOpenModalNewAttribute,
		setOpenModalNewMap,
		setOpenModalCreateWorkspaceType,
		buttonCreateBoard,
		setOpenModalCreateBoard,
		setOpenModalCreateWorkspace,
		showShareBtn,
		filterWorkspace,
		filterRecentBoards,
		dataFilters,
		showCurrentWorkspace = false,
		getWorkspaceData,
		widgetsSettings,
		pathModule,
		showButtonNewCollab,
		showButtonConfigTeam,
		showButtonImport,
		showActionCreateExtraCollection,
		handleOpenModalCreateExtraCollection,
		groupButtonsDaysOff,
		showButtonCreateLicense,
		statsLicense,
		setOpenModalCreateSoftwarelicense,
		actionCreateProfile
	} = props;
	const showShareButton = showShareBtn !== undefined ? showShareBtn : true

	let path = window.location.pathname.split("/");

	let filterWho = props.filterWho || 'multi';
	let filterWhen = props.filterWhen || 'multi';
	let filterWhat = props.filterWhat || 'disabled';
	let filterPeriod = props.filterPeriod || 'disabled';
	let filterWhenMonths = props.filterWhenMonths || 'disabled'
	let filterWhenYear = props.filterWhenYear || 'disabled'
	const filterYears = props.filterYears || 'disabled'
	const filterAssessment = props.filterAssessment || 'disabled'
	const filterProfiles = props.filterProfiles || 'disabled'
	const goals = props.goals || false;
	const checkin = props.checkin || false;
	const checkinHistory = props.checkinHistory || false;
	const cardView = props.cardView || false;
	const hasTogglePage = props.hasTogglePage || false;
	const companyPurpose = false //props.companyPurpose || false;
	const actionsGoals = props.actionsGoals || false;
	const actionsGoalsDetails = props.actionsGoalsDetails || false;
	const setFirstPageActive = props.setFirstPageActive
	const setOpenModalJustifyWork = props.setOpenModalJustifyWork
	const setOpenModalActionCheckIn = props.setOpenModalActionCheckIn
	const [assessmentsOptions, setAssessmentsOptions] = useState([])
	const [fetchingAssessments, setFetchingAssessments] = useState(false)
	const [profilesOptions, setProfilesOptions] = useState([])
	const [fetchingProfiles, setFetchingProfiles] = useState(false)

	const {
		setOpenModalNewGoal,
		setOpenModalNewKr,
		setDrawerFiltersGoals,
		first_label,
		second_label,
		total_msg_not_read,
		filterRoom = false,
		roomsData = []
	} = props

	const actionAddCalls = props.actionAddCalls || false;
	const isUpdatingPreview = props.isUpdatingPreview || false
	const viewSettingsIcon = props.viewSettings || false;
	const disabledFetchingTeamsAndTeammates = props.disabledFetchingTeamsAndTeammates || false;

	const handleOpenMenuSettings = (event) => {
		props.setAnchorEl(event.currentTarget);
	};

	const handleCloseMenuSettings = () => {
		props.setAnchorEl(null);
	};

	const [anchorElMenuCreateBoards, setAnchorElMenuCreateBoards] = useState(null);
	const openMenuCreateBoards = Boolean(anchorElMenuCreateBoards);
	const id = open ? 'simple-popover' : undefined;

	const handleClickButtonCreateBoards = (event) => {
		setAnchorElMenuCreateBoards(event.currentTarget);
	};

	const handleCloseMenuCreateBoards = () => {
		setAnchorElMenuCreateBoards(null);
	};

	const actionAddAccountGoogleCalendar = props.actionAddAccountGoogleCalendar || false;
	const actionAddAccountOutlookCalendar = props.actionAddAccountOutlookCalendar || false;
	const actionNewMessages = props.actionNewMessages || false;
	const actionNewMessagesProductivity = props.actionNewMessagesProductivity || false;
	const actionLoginProductive = props.actionLoginProductive || false;
	const actionCheckin = props.actionCheckin || false;
	const hideTeams = props.hideTeams || false;

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;

		if (!(value.includes(0)) && !(rooms.includes(0)) && value.length === roomsData.length) {
			setRooms([0, ...value])
			return
		}

		if ((rooms.includes(0)) && !(value.includes(0))) {
			setRooms([])
			return
		}

		if (value.includes(0) && !(rooms.includes(0))) {
			const all_ids = [0, ...roomsData.reduce((acum, curr) => {
				return [...acum, curr.value]
			}, [])]

			setRooms(all_ids)
			return
		}
		if (value.includes(0) && ((value.length - 1) !== rooms.length)) {
			setRooms(value.filter(item => item !== 0))
			return
		}

		setRooms(
			typeof value === 'string' ? value.split(',') : value,
		);
	};


	useEffect(() => {

		if (filterWho && filterWho != 'disabled' && !disabledFetchingTeamsAndTeammates) {
			teammatesActions.getTeammates();
			teamsActions.getTeams();
		}

	}, [])

	const classes = useStyles();

	const getAssessmentsOptions = async () => {
		try {
			setFetchingAssessments(true);
			const {data} = await api.get('/performance-assessment/action-plan/select-create', { headers: authHeader() })
			setAssessmentsOptions(data?.performance_assessments || []);
			setFetchingAssessments(false);
		} catch (error) {
			console.log(error);
			setFetchingAssessments(false);
		}
	}

	
	const getProfilesOptions = async () => {
		try {
			setFetchingProfiles(true);
			const {data} = await api.get('/settings/access-profile-options', { headers: authHeader() })
			setProfilesOptions(data || []);
			setFetchingProfiles(false);
		} catch (error) {
			console.log(error);
			setFetchingProfiles(false);
		}
	}


	useEffect(() => {
		if (filterAssessment && filterAssessment != 'disabled') {
			getAssessmentsOptions();
		}
	}, [filterAssessment])

	useEffect(() => {
		if (filterProfiles && filterProfiles != 'disabled') {
			getProfilesOptions();
		}
	}, [filterProfiles])

	return (
		<div className="mainFilterbar">
			<div className="filters">

				{
					filterWorkspace && <WorkspacesFilter getWorkspaceData={getWorkspaceData} myWorkspaces={dataFilters?.my_workspaces} workspacesInvited={dataFilters?.workspaces_invited} showCurrentWorkspace={showCurrentWorkspace} />
				}
				{
					filterRecentBoards && <RecentBoardsFilter recentBoards={dataFilters?.recently_viewed_boards} />
				}

				{actionNewMessages &&
					<>
						<NavLink exact to="/messages" activeClassName="active" style={{ height: 50 }}>
							<button className={'settingsNav'}>{t('messages.received')}
							</button>
							{total_msg_not_read > 0 && <div className='badge-messages'>{total_msg_not_read}</div>}

						</NavLink>
						<NavLink exact to="/messages/sent" activeClassName="active" style={{ height: 50 }}>
							<button className={'settingsNav'}>{t('messages.sent')}</button>
						</NavLink>
						<NavLink exact to="/messages/archived" activeClassName="active" style={{ height: 50 }}>
							<button className={'settingsNav'}>{t('messages.archived')}</button>
						</NavLink>
					</>
				}

				{tabSettingsRoom &&
					<>
						<NavLink exact to="/settings/spaces/attributes" activeClassName="active" style={{ height: 50 }}>
							<button className={'settingsNav'} style={{ minWidth: 150 }}>{t('spaces.tab_attributes')}
							</button>
						</NavLink>
					</>
				}
				{tabSettingsWorkspaceTypes &&
					<>
						<NavLink exact to="/settings/project_management/workspace-types" activeClassName="active" style={{ height: 50 }}>
							<button className={'settingsNav'}>{t('project_management.tabs.workspace_types')}
							</button>
						</NavLink>
					</>
				}

				{actionNewMessagesProductivity && (
					<>
						<NavLink
							exact
							to={'/productivity/' + org_id + '/' + token + '/messages'}
							activeClassName="active"
							style={{ height: 50 }}
						>
							<button className={'settingsNav'}>{t('messages.received')}</button>
							{total_msg_not_read > 0 && <div className='badge-messages'>{total_msg_not_read}</div>}
						</NavLink>
						<NavLink
							exact
							to={'/productivity/' + org_id + '/' + token + '/messages/sent'}
							activeClassName="active"
							style={{ height: 50 }}
						>
							<button className={'settingsNav'}>{t('messages.sent')}</button>
						</NavLink>
						<NavLink
							exact
							to={'/productivity/' + org_id + '/' + token + '/messages/archived'}
							activeClassName="active"
							style={{ height: 50 }}
						>
							<button className={'settingsNav'}>{t('messages.archived')}</button>
						</NavLink>
					</>
				)}

				{(filterRoom && !showMaps) && (
					<Box>
						<CustomSelect
							isMulti={true}
							widthControl='300px'
							initialValues={rooms}
							onChange={handleChange}
							options={roomsData}
							showIconRoom={true}
							setSelectedOptions={setRooms}
						/>
					</Box>
				)}

				{hasTogglePage && (
					<ChangeMyActivitiesButtons
						first_label={first_label}
						second_label={second_label}
						setFirstPageActive={setFirstPageActive}
					/>
				)}

				{goals &&
					<React.Fragment>
						{props.loadingTeams || props.loadingTeammates
							? <WhoMultiSkeleton />
							: <CountLabel goals={goals} cardView={cardView} />
						}
					</React.Fragment>
				}

				{checkin && (
					<>
						{props.loadingTeams || props.loadingTeammates
							? (
								<WhoMultiSkeleton />
							) : (
								<span className="totalLabel">
									{t('menu.check_in_remote')}
								</span>
							)
						}
					</>
				)}
				{checkinHistory && (
					<>
						{props.loadingTeams || props.loadingTeammates
							? (
								<WhoMultiSkeleton />
							) : (
								<span className="totalLabel">
									{t('menu.historical')}
								</span>
							)
						}
					</>
				)}

				{
					companyPurpose &&
					<React.Fragment>
						{props?.isLoading
							? <WhoMultiSkeleton />
							: <span style={{ fontSize: '14px', fontWeight: 500, color: '#4F7091' }}>{companyPurpose}</span>
						}
					</React.Fragment>
				}

				{
					props.filterTypeSkills === 'enabled' &&
					<React.Fragment>
						{props.isLoadingSkillTypes
							? <SkillTypesFilterSkeleton />
							: <SkillTypesFilter filterSkills={props.filterSkills} skillTypes={props.skillTypes} />
						}
					</React.Fragment>
				}
					
				{filterWho === 'multi' &&
					<React.Fragment>
						{props.loadingTeams || props.loadingTeammates
							? <WhoMultiSkeleton />
							: <WhoMulti openWho={props.openWho} hideTeams={hideTeams} />
						}
					</React.Fragment>
				}

				{filterPeriod === 'single' &&
					<React.Fragment>
						{props.loadingTeammates
							? <PeriodSingleGoalSkeleton />
							: <PeriodSingleGoal openWho={props.openWho} />
						}
					</React.Fragment>
				}

				{filterWho === 'singleTeamsTESTE' &&
					<React.Fragment>
						{props.loadingTeams
							? <WhoSingleTeamSkeleton />
							: <WhoSingleTeam openWho={props.openWho} />
						}
					</React.Fragment>
				}
				{filterWho === 'singleTeammates' &&
					<React.Fragment>
						{props.loadingTeammates
							? <WhoSingleTeammateSkeleton />
							: <WhoSingleTeammate openWho={props.openWho} />
						}
					</React.Fragment>
				}
				
				{(filterYears == 'single') &&
					<React.Fragment>
						{props.loadingTeams || props.loadingTeammates
							? <WhenSingleSkeleton />
							: <WhenSingleYears
								maxDate={props.maxDate}
							/>
						}
					</React.Fragment>
				}


				{filterWhen == 'multi' &&
					<React.Fragment>
						{props.loadingTeams || props.loadingTeammates || props.isUpdatingPreview
							? <WhenMultiSkeleton />
							: <WhenMulti
								startDate={props.startDate}
								endDate={props.endDate}
								maxDate={props.maxDate}
								resetOneYear={props.resetOneYear || false}
								withoutMax={props.withoutMax || false}
								cleanWhenNull={props.cleanWhenNull || false}
								totalDays={props.totalDays}
							/>
						}
					</React.Fragment>
				}

				{(filterWhen == 'single' && !showCalendar) &&
					<React.Fragment>
						{props.loadingTeams || props.loadingTeammates
							? <WhenSingleSkeleton />
							: <WhenSingle
								maxDate={props.maxDate}
							/>
						}
					</React.Fragment>
				}

				{(filterWhenMonths == 'single' && !showCalendar) &&
					<React.Fragment>
						{props.loadingTeams || props.loadingTeammates
							? <WhenSingleSkeleton />
							: <WhenSingleMonths
								maxDate={props.maxDate}
							/>
						}
					</React.Fragment>
				}

				{(filterWhenYear == 'single') &&
					<React.Fragment>
						{props.loadingTeams || props.loadingTeammates
							? <WhenSingleSkeleton />
							: (
								<WhenMonthMulti />
							)
						}
					</React.Fragment>
				}

				{filterWhat == 'enabled' &&
					<React.Fragment>
						{props.loadingTeams || props.loadingTeammates
							? <WhatSkeleton />
							: <What />
						}
					</React.Fragment>
				}
				
				{
					filterAssessment === 'single' &&
					<React.Fragment>
						{
							fetchingAssessments
							?
								<WhoMultiSkeleton />
							:
								<AssessmentsFilterDashboard 
									assessments={assessmentsOptions} 
									getData={props.getFilterAssessment}
								/>
						}
					</React.Fragment>
				}

				{
					filterProfiles === 'enabled' &&
					<React.Fragment>
						{fetchingProfiles
							? <WhoMultiSkeleton />
							: <ProfilesFilter profiles={profilesOptions} getData={props.getFilterProfiles} globalFilter={props.globalFilter}/>
						}
					</React.Fragment>
				}

				{actionsGoalsDetails &&
					<button className={'back'} title={t('historical.back')} onClick={() => {
						history.push(getDynamicRedirectUrl(userDetails, "/goals"))
					}}>
						<IconBack />
					</button>
				}

			</div>

			{
				props.routeBackToPage &&
				<button className={'back'} title={t('historical.back')} onClick={() => {
					history.push(props.routeBackToPage)
				}}>
					<IconBack />
				</button>
			}

			{
				widgetsSettings &&
				(
					<button className="share" onClick={() => history.push(`/${pathModule}/widgets`)}>
						<ChartBar size={24} color="#98ACC1" />
					</button>
				)

			}

			{showShareButton && <ShareButton path={path} />}


			{
				(
					(path[2] === 'productivity' && path[3] === 'inactivity') ||
					(path[2] === 'control' && path[3] === 'solicitations')
				) &&
				(
					<button className="share" onClick={() => { setShowCalendar(!showCalendar) }}>
						{
							!showCalendar ? <CalendarBlank size={24} color="#98ACC1" /> : <List size={24} color="#98ACC1" />
						}
					</button>
				)

			}

			{

				(path[1] === 'spaces' && !path[2])
				&& (
					<>
						{
							!showMaps &&
							<button className="share" onClick={() => { setShowCalendar(!showCalendar) }}>
								{
									!showCalendar ? <CalendarBlank size={24} color="#98ACC1" /> : <List size={24} color="#98ACC1" />
								}
							</button>
						}
					</>
				)
			}

			{
				actionsGoals &&
				<ButtonActionsGroup
					path={path}
					openDrawerMovimentations={props.openDrawerMovimentations}
					changeViewerObjectivies={props?.changeViewerObjectivies}
					setOpenModalNewGoal={setOpenModalNewGoal}
					setOpenModalNewKr={setOpenModalNewKr}
					setDrawerFiltersGoals={setDrawerFiltersGoals}
					userDetails={userDetails}
				/>
			}
			{
				actionsGoalsDetails &&
				<ButtonActionsGroup
					path={path}
					openDrawerMovimentations={props.openDrawerMovimentations}
					changeViewerKrs={props?.changeViewerKrs}
					setOpenModalNewGoal={setOpenModalNewGoal}
					setOpenModalNewKr={setOpenModalNewKr}
					userDetails={userDetails}
				/>
			}

			{
				actionAddCalls &&
				<div className={classes.root}>
					<Button variant="contained" color="secondary" className={classes.button} onClick={() => setToggleDrawerAddCall(true)}>
						{t('button.open_call')}
					</Button>
				</div>
			}

			{
				actionAddAccountGoogleCalendar &&
				<>
					{accountsLogged.length > 0 ? (
						<div className="ButtonActionsGroup">
							<button
								className="btnAddAccount"
								onClick={handleClickMenuAccounts}
							>
								{t('integrations.my_accounts')}
							</button>
						</div>
					) : (
						<div className="ButtonActionsGroup">
							<button
								className="btnAddAccount"
								onClick={handleGoogleLogin}
								disabled={isLoadingEvents}
							>
								{t('integrations.add_account')}
							</button>
						</div>
					)}
				</>
			}

			{
				actionAddAccountOutlookCalendar &&
				<>
					{accountsLogged.length > 0 ? (
						<div className="ButtonActionsGroup">
							<button
								className="btnAddAccount"
								onClick={handleClickMenuAccounts}
							>
								{t('integrations.my_accounts')}
							</button>
						</div>
					) : (
						<div className="ButtonActionsGroup">
							<button
								className="btnAddAccount"
								onClick={handleMicrosoftLogin}
								disabled={isLoadingEvents}
							>
								{t('integrations.add_account')}
							</button>
						</div>
					)}
				</>
			}

			{
				(actionNewMessages || actionNewMessagesProductivity) &&

				<div className={classes.root}>
					<Button variant="contained" color="secondary" className={classes.button} onClick={() => setOpenModalNewMessage(true)} style={{ minWidth: 150 }}>
						{t('messages.new_message')}
					</Button>
				</div>

			}

			{
				buttonCreateBoard &&

				<div className={classes.root}>
					<Button aria-controls={id} aria-haspopup={true} variant="contained" color="secondary" className={classes.button} onClick={handleClickButtonCreateBoards} style={{ minWidth: 100 }}>
						{t('project_management.boards.create')}
					</Button>
					<Menu
						anchorEl={anchorElMenuCreateBoards}
						id={id}
						getContentAnchorEl={null}
						open={openMenuCreateBoards}
						onClose={handleCloseMenuCreateBoards}
						elevation={1}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<MenuItem
							onClick={() => {
								setOpenModalCreateWorkspace(true)
								handleCloseMenuCreateBoards()
							}}
							style={{ color: '#1E6B7F' }}>
							<ListItemIcon style={{ minWidth: '30px' }}>
								<Users size={20} color="#1E6B7F" />
							</ListItemIcon>
							{t('project_management.boards.new_workspace')}
						</MenuItem>
						{
							dataFilters?.my_workspaces?.length > 0 &&
							<MenuItem onClick={() => {
								setOpenModalCreateBoard(true)
								handleCloseMenuCreateBoards()
							}} style={{ color: '#1E6B7F' }}>
								<ListItemIcon style={{ minWidth: '30px' }}>
									<Kanban size={20} color="#1E6B7F" />
								</ListItemIcon>
								{t('project_management.boards.new_board')}
							</MenuItem>
						}
						
					</Menu>
				</div>

			}

			{
				createAttribute &&
				<div className={classes.root}>
					<Button variant="contained" color="secondary" className={classes.button} onClick={() => setOpenModalNewAttribute(true)} style={{ minWidth: 180 }}>
						{t('spaces.attributes.new')}
					</Button>
				</div>
			}

			{
				createMap &&
				<div className={classes.root}>
					<Button
						variant="contained"
						color="secondary"
						className={classes.button}
						onClick={() => setOpenModalNewMap(true)}
						style={{ minWidth: 130 }}>
						{t('spaces.maps.new')}
					</Button>
				</div>
			}

			{
				createWorkspaceType &&
				<div className={classes.root}>
					<Button variant="contained" color="secondary" className={classes.button} onClick={() => setOpenModalCreateWorkspaceType(true)} style={{ minWidth: 150 }}>
						{t('project_management.workspace_types.new')}
					</Button>
				</div>
			}


			{
				actionLoginProductive &&
				<div className="ButtonActionsGroup">
					<NavLink
						to={'/productivity/' + path[2] + '/' + path[3] + '/login'}
					>
						<button
							className="btnLoginProductive"
						>
							{t('login.login')}
						</button>
					</NavLink>

				</div>
			}

			{
				actionCheckin && (
					<div className="ButtonActionsGroup">
						<button className="btnMovimentations" style={{ marginRight: '4px' }} onClick={() => setOpenModalJustifyWork(true)}>
							<IconJustify />
						</button>
						<Button
							variant="contained"
							className={classes.button}
							startIcon={<SignIn size={16} weight='bold' />}
							style={{
								width: "100px",
								minWidth: '100px',
								padding: '0',
								minHeight: '0',
								borderRadius: '0',
								backgroundColor: "#FDBE2B",
								boxShadow: 'none'
							}}
							onClick={() => setOpenModalActionCheckIn(true)}
						>
							{teammatesDetail?.last_point_type === "IN" && t('checkin.status.out')}
							{(!teammatesDetail || teammatesDetail?.last_point_type === null || teammatesDetail?.last_point_type === "OUT") && t('checkin.status.in')}
						</Button>
					</div>
				)
			}

			{
				newRoom && (
					<div className="ButtonActionsGroup">
						<Button
							variant="contained"
							className={classes.button}
							style={{
								width: "130px",
								minWidth: '100px',
								padding: '8px',
								minHeight: '0',
								borderRadius: '0',
								backgroundColor: "#FDBE2B",
								boxShadow: 'none'
							}}
							onClick={() => history.push('/spaces/management/create')}
						>
							+ {t('spaces.new_room_btn')}
						</Button>
					</div>
				)
			}


			{
				showButtonNewCollab &&

				<div className="ButtonActionsGroup">
					{/* <button className="btnMovimentations" style={{ marginRight: '4px' }} title={t('historical.back')} onClick={() => history.push('/settings/teams')}>
						<IconBack />
					</button> */}
					{
						userDetails.allowed_update && (
							<button className="btnMovimentations" style={{ marginRight: '8px' }} onClick={() => history.push(props.urlSettingsTeam)}>
								<Settings fontSize='medium' />
							</button>
						)
					}
					{
						userDetails.allowed_create && (
							<Button
								variant="contained"
								className={classes.button}
								style={{
									minWidth: '120px',
									padding: '0',
									minHeight: '0',
									borderRadius: '0',
									backgroundColor: "#FDBE2B",
									boxShadow: 'none'
								}}
								onClick={() => props.openDrawer({ ...props.dataTeam, type: "invite" })}
							>
								{`+ ${t('settings.teams.teammate')}`}
							</Button>
						)
					}
				</div>
			}

			{
				props.enableButtonToggleCollection && (
					<div className="ButtonActionsGroup">
						{
							props.typeViewCollection === 'default'
							?
								<button className="btnMovimentations" onClick={() => props.setTypeViewCollection('agent_installed')}>
									<Robot size={20} color='#1E6B7F' weight='bold' />
								</button>
							:
							<button className="btnMovimentations" onClick={() => props.setTypeViewCollection('default')}>
								<SquaresFour size={20} color='#1E6B7F' weight='bold'/>
							</button>
						}
					</div>
				)
			}

			{
				groupButtonsDaysOff &&

				<div className="ButtonActionsGroup">

					{
						props.viewCalendar
							?
							<button className="btnMovimentations" style={{ marginRight: '4px' }} onClick={() => props.setViewCalendar(false)}>
								<IconList />
							</button>
							:
							<button className="btnMovimentations" style={{ marginRight: '4px' }} onClick={() => props.setViewCalendar(true)}>
								<IconCalendarFA />
							</button>
					}

					{
						['magic', 'admin'].includes(userDetails.profile_key) && (
							<button className="btnMovimentations" style={{ marginRight: '8px' }} onClick={() => props.setOpenModalImportDaysOff(true)}>
								<IconImport />
							</button>
						)
					}
					{
						userDetails.allowed_create && (
							<Button variant="contained" color="secondary" className={classes.button} onClick={() => props.setOpenModalCreateDaysOff(true)}>
								{`+ ${t('settings.daysoff_settings.add_data')}`}
							</Button>
						)
					}

				</div>
			}
			{
				showButtonCreateLicense &&
				<div className="ButtonActionsGroup" style={{width:'50%',alignItems:'center', justifyContent:'flex-end', gap:'16px'}}>
					{statsLicense && <span style={{fontSize:'12px'}}>{`${t("software_inventory.last_quote")} ${befectiveFormatDate(statsLicense.day_ref, i18n.language)}`}</span>}
					<Button variant="contained" color="secondary" className={classes.button} onClick={() => props.setOpenModalCreateSoftwarelicense(true)}>
						{t("software_inventory.title_button_add_license")}
					</Button>
				</div>
			}

			{
				actionCreateProfile &&
				<div className="ButtonActionsGroup">
					<Button variant="contained" color="secondary" className={classes.button} onClick={() => history.push("/global-settings/profiles/create")} style={{ minWidth: '100px' }}>
						+ {t("settings.profiles.button_title")}
					</Button>
				</div>
			}

			{
				(showActionCreateExtraCollection && userDetails.allowed_create) &&
				<div className={classes.root}>
					<Button variant="contained" color="secondary" className={classes.button} onClick={handleOpenModalCreateExtraCollection} style={{ width: userDetails.language === 'es' ? '180px' : '130px' }}>
						{t('settings.extra_collections.btn_create_extra_collections')}
					</Button>
				</div>
			}
			{
				props.buttonImport && (
					<div className="ButtonActionsGroup">
						<button className="btnMovimentations" onClick={props.buttonImport.handleClick}>
							<IconImport />
						</button>
					</div>
				)
			}
			{
				(props.buttonCreate && userDetails.allowed_create) && (
					<ButtonCreate
						title={props.buttonCreate.title}
						handleClick={props.buttonCreate.handleClick}
						hasAssessmentFormInStorage={props.buttonCreate.hasAssessmentFormInStorage}
					/>
				)
			}
		</div >
	)
};


function mapDispatchToProps(dispatch) {

	return {
		statsActions: bindActionCreators(statsActions, dispatch),
		filterActions: bindActionCreators(filterActions, dispatch),
		teamsActions: bindActionCreators(teamsActions, dispatch),
		teammatesActions: bindActionCreators(teammatesActions, dispatch)
	}
}


function mapStateToProps(state) {

	return {
		teams: state.teams.payload,
		teammates: state.teammates.payload,
		teammatesDetail: state.teammates.detail,
		loadingTeams: state.teams.loading,
		loadingTeammates: state.teammates.loading,
		filter: state.filter,
		userDetails: state.authentication.userDetails
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(Filterbar)
