import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { pdfjs } from 'react-pdf';


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const PdfViewer = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState(null);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#282828",
    }}>
      <Document 
        file={fileUrl} 
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        onLoadError={(error) => console.log("An error loading the PDF: ", error)}>
        {numPages && Array.from({ length: numPages }, (_, index) => (
          <Page key={index} pageNumber={index + 1}/>
        ))}
      </Document>
    </div>
  );
};

export default PdfViewer;