import React, { useEffect, useState } from "react";
import { Backdrop, Modal, Slide, makeStyles } from "@material-ui/core";
import styles from "./MonthDetailsModal.module.scss";
import { useTranslation } from "react-i18next";
import { api } from "../../../_helpers/api";
import { authHeader, getDayWeek, getMonths, getMoodById } from "../../../_helpers";
import IconClose from "../../_icons/IconClose";
import moment from "moment";
import { CaretLeft, CaretRight } from "phosphor-react";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function MonthDetailsModal({ openModal, closeModal, currentData, currentMonthYear }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation("common");
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState([]);
  const [filterCalendar, setFilterCalendar] = useState({
    month: null,
    year: null
  })

  const getDataMonthDetails = async ({ month_ref, user, group }) => {
    try {
      setLoading(true);

      const queryUser = user ? `&users=${user}` : "";
      const queryGroup = group ? `&groups=${group}` : "";

      const response = await api.get(
        `/report/mood/month?month_ref=${month_ref}${queryUser}${queryGroup}`,
        {
          headers: authHeader(),
        }
      );

      setModalData(response.data);

      setLoading(false);
    } catch (error) {
      //alertActions.error(error)
      console.log(error);
      setLoading(false);
    }
  };

  const handlePreviousMonthYear = () => {
    const dateRef = moment(`${filterCalendar.year}-${filterCalendar.month}-01`)
      .subtract(1, "month")
      .format("YYYY-MM");
    
    setFilterCalendar({month: dateRef.split("-")[1], year: dateRef.split("-")[0]})

    getDataMonthDetails({
      user: currentData.user_id || null,
      group: currentData.group_id || null,
      month_ref: dateRef
    })
  }

  const handleNextMonthYear = () => {
    const dateRef = moment(`${filterCalendar.year}-${filterCalendar.month}-01`)
      .add(1, "month")
      .format("YYYY-MM");
    
    setFilterCalendar({month: dateRef.split("-")[1], year: dateRef.split("-")[0]})

    getDataMonthDetails({
      user: currentData.user_id || null,
      group: currentData.group_id || null,
      month_ref: dateRef
    })
  }

  useEffect(() => {
    if (openModal) {
      getDataMonthDetails({
        user: currentData.user_id || null,
        group: currentData.group_id || null,
        month_ref: currentMonthYear || moment().format('YYYY-MM')
      })
      const [year, month] = currentMonthYear.split("-");
      setFilterCalendar({month: month, year: year })
    }
  }, [openModal]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} ${styles.modal}`}
      open={openModal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: "#205B6F", opacity: 0.8 },
      }}
      style={{ outline: 0, border: "none" }}
    >
      <Slide
        direction="down"
        in={openModal}
        style={{ outline: 0 }}
        mountOnEnter
        unmountOnExit
      >
        <div className={styles.containerModal}>

          <button onClick={closeModal} className={styles.close}>
            <IconClose />
          </button>

          <header>
            <h4>
              {`${t('reports_mood.mood_of')} ${currentData?.label}`}
            </h4>
            <div className={styles.filterCalendarBox}>
              <button onClick={handlePreviousMonthYear} disabled={loading}>
                <CaretLeft size={16} color="#98ACC1" weight="bold"/>
              </button>
              <div className={styles.filterCalendarTitle}>
                {getMonths(parseInt(filterCalendar.month) - 1, t)} {filterCalendar.year}
              </div>
              <button onClick={handleNextMonthYear} disabled={loading}>
                <CaretRight size={16} color="#98ACC1" weight="bold"/>
              </button>
            </div>
          </header>

          <main className={styles.containerCalendar}>
            <div className={styles.gridCalendar}>
              {
                [0,1,2,3,4,5,6].map(dayWeek => (
                  <div key={`weekday-${dayWeek}`} className={styles.dayWeekHead}>{getDayWeek(dayWeek, true)}</div>
                ))
              }
            </div>
            <div className={styles.gridCalendar}>
            {
              !loading
              ?
                modalData.map((moodDay, index) => (
                  <div key={`moodDay-${index}`} className={styles.dayWeekItem}>
                    {
                      moodDay.avg_mood_day 
                      ?
                        <img src={getMoodById(moodDay.avg_mood_day).img} width={'30px'} height={'30px'} title={t(`widgets.moods.${getMoodById(moodDay.avg_mood_day).key}`)}/>
                      :
                        <div style={{backgroundColor: '#F5F6F9', width: `30px`, height: '30px', borderRadius:'50%'}}></div>
                    }
                    <span>{moment(moodDay.day_ref).date()}</span>
                  </div>
                ))
              :
              Array.from(Array(35)).map((_, index) => (
                <div key={`moodDay-${index}`} className={styles.dayWeekItem}>
                  <Skeleton variant="circular" width={'30px'} height={'30px'} />
                  <Skeleton variant="rectangular" width={'12px'} height={'12px'} />                  
                </div>
              ))
              }
            </div>
          </main>
        </div>
      </Slide>
    </Modal>
  );
}
