import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import Toolbar from '../../_components/_shared/Toolbar'
import { useTranslation } from "react-i18next";
import { activitiesActions, alertActions } from '../../_actions';
import { api } from '../../_helpers/api';
import { authHeader } from '../../_helpers';
import FilterbarUsersSettings from '../../_components/_shared/FilterbarUsersSettings';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import ActivitiesTable from '../../_components/activities_settings/ActivitiesTable';
import ActivitiesHeader from '../../_components/activities_settings/ActivitiesHeader';


function ActivitiesSettingsPage(props) {
  const { t, i18n } = useTranslation('common');
  const { userDetails, alertActions, activitiesActions, activities } = props;
  const [data, setData] = useState(null)
  const [selectedRows, setSelectedRows] = useState([]);
  const [fetching, setFetching] = useState(true)
  const [pageCount, setPageCount] = useState(0);
  const [pageCountRows, setPageCountRows] = useState(0);


  const columns = useMemo(() => {
    return [
      {
        Header: t('settings.activities.col_name'),
        accessor: 'exhibitionName'
      },
      {
        Header: t('settings.activities.col_origin'),
        accessor: 'origin'
      },
      {
        Header: t('settings.activities.col_type'),
        accessor: 'type'
      },
      {
        Header: t('settings.activities.col_category'),
        accessor: 'category_json',
        Cell: ({ value }) => {
          return value[`name_${userDetails.language || i18n.language}`];
        },
      },
      {
        Header: t('settings.activities.col_percent_usage'),
        accessor: 'percent_time_usage',
        Cell: ({ value }) => {
          return `${value}%`;
        },
      },
    ];
  }, []);

  const getActivities = async ({ pageIndex, pageSize, isLoading, search_text = '' }) => {
    try {
      if (isLoading) {
        setFetching(true)
      }

      const querySearch = search_text != '' ? `&search_text=${search_text}` : ''
      const res = await api.get(`/settings/get-trackeds/?page=${pageIndex}&per_page=${pageSize}${querySearch}`, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro get activities')
      }
      setData(res.data)
      setPageCount(Math.ceil(parseInt(res.data.countRows) / pageSize))
      setPageCountRows(parseInt(res.data.countRows))
    } catch (error) {
      console.error(error)
    } finally {
      setFetching(false)
    }
  }

  useEffect(() => {
    document.title = 'Befective | ' + t('menu.activities');
    activitiesActions.getActivitiesCategories()
    getActivities({ pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20, isLoading: true })
  }, []);


  return (
    <React.Fragment>

      <section className="mainContent">
        <Toolbar />
        <FilterbarUsersSettings
          filterLabel={t("settings.filter_label_activity")}
          getData={getActivities}
          enableImport={false}
        />
        {/* PAGE STARTS */}
        <div className="mainPage profile">
          <div className="nocolumn" style={{ background: 'none' }}>
            <div className={'tab full active'}>
              {
                !fetching
                  ?
                  <>
                    <ActivitiesHeader 
                      selectedRows={selectedRows} 
                      setSelectedRows={setSelectedRows}
                      alertActions={alertActions}
                      getActivitiesData={getActivities}
                      activities={data ? data.trackeds : []}
                      categories={activities?.categories}
                      userDetails={userDetails}
                    />
                    
                    <ActivitiesTable
                      columns={columns}
                      data={data ? data.trackeds : []}
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                      count={pageCount}
                      getActivitiesData={getActivities}
                      pageCountRows={pageCountRows}
                      alertActions={alertActions}
                      categories={activities?.categories}
                      userDetails={userDetails}
                    />
                  </>
                  :
                  <GlobalSkeleton totalRowsStats={0} heightTable={600} />
              }
            </div>
          </div>
        </div>
        {/* PAGE ENDS */}
      </section>

    </React.Fragment>
  )
};

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    activitiesActions: bindActionCreators(activitiesActions, dispatch)
  }
}

function mapStateToProps(state) {

  return {
    filter: state.filter,
    userDetails: state.authentication.userDetails,
    activities: state.activities
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesSettingsPage)
