import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { settingsActions } from '../../../_actions';
import { trim, size } from 'lodash';

import InputMask from "react-input-mask";

import IconCopy from '../../_icons/IconCopy'
import { Switch } from '@mui/material';
import Select from 'react-select'
import chroma from "chroma-js";
import { TagsInput } from "react-tag-input-component";
import { history } from '../../../_helpers';


const colourStyles = {
	control: (styles) => ({
		...styles,
		backgroundColor: 'white',
		zIndex: 999999,
		minHeight: 48,
		borderRadius: 2,
		border: 'solid 1px #D6DEEB',
		display: 'flex',
		color: '#0D1F40',
		cursor: 'pointer',
		webkitAppearance: 'none',
		transition: 'all 0.3s ease',
		':focus': {
			outline: 0,
			border: 'solid 1px #187DFF',
		},
		':active': {
			outline: 0,
			border: 'solid 1px #187DFF',
		}
	}),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		const color = chroma(data.color ?? 'black');
		return {
			...styles,
			backgroundColor: isDisabled
				? undefined
				: isSelected
					? data.color
					: isFocused
						? color.alpha(0.1).css()
						: undefined,
			color: isDisabled
				? '#ccc'
				: isSelected
					? chroma.contrast(color, 'white') > 2
						? 'white'
						: 'black'
					: data.color,
			cursor: isDisabled ? 'not-allowed' : 'default',

			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled
					? isSelected
						? data.color
						: color.alpha(0.3).css()
					: undefined,
			},
		};
	},
	multiValue: (styles, { data }) => {
		return {
			...styles,
			height: 37,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: '#E8F1FE',
		};
	},
	multiValueLabel: (styles, { data }) => ({
		...styles,
		color: data.color,
	}),
	multiValueRemove: (styles, { data }) => ({
		...styles,
		color: data.color,
		':hover': {
			backgroundColor: data.color,
			color: 'red',
		},
	}),
};

function CompanyInformation(props) {
	const { t, i18n } = useTranslation('common');

	const { settingsActions, userDetails, currencies } = props;

	let companyInformation = props.companyInformation || {};

	const [showGoals, setShowGoals] = useState(companyInformation.showGoals)
	const [originTrackedsFiles, setOriginTrackedsFiles] = useState(companyInformation.originTrackedsFiles || [])


	const [form, setForm] = useState({
		name: companyInformation.name,
		website: companyInformation.website,
		phone: companyInformation.phone,
		target: companyInformation.targetPercent,
		purpose: companyInformation.purpose || t('goals.objectives-ruler-title'),
		show_goals: showGoals,
		currency: companyInformation.currencyCode || 'USD',
		originTrackedsFiles: (!companyInformation.originTrackedsFiles || companyInformation.originTrackedsFiles?.length === 0) ? '' : companyInformation.originTrackedsFiles,
		auth_domain: companyInformation?.auth_domain || []
	});


	const originTrackedsFilesOptions = [
		{
			id: 541340,
			label: "Microsoft Excel",
			value: "excel.exe"
		},
		{
			id: 524500,
			label: "Microsoft Word",
			value: "winword.exe"
		},
		{
			id: 87751,
			label: "Microsoft PowerPoint",
			value: "powerpnt.exe"
		}
	]


	const [formError, setFormError] = useState({
		name: '',
		website: '',
		phone: '',
		target: '',
		currency: ''
	});

	const [submitted, setSubmitted] = useState(false);
	const [mask, setMask] = useState('(99) 99999-9999');

	const handleChange = e => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		})
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		setSubmitted(true);

		let formIsValid = true;

		if (!form["name"]) {
			formIsValid = false;
			setFormError({
				...formError,
				name: t('settings.company.error.required'),
			})
		}

		if (!form["target"]) {
			formIsValid = false;
			setFormError({
				...formError,
				target: t('settings.company.error.required'),
			})
		}

		
		if (!form["currency"]) {
			formIsValid = false;
			setFormError({
				...formError,
				currency: t('settings.company.error.required'),
			})
		}

		if (formIsValid) {
			settingsActions.putCompanyInformation({
				...form,
				originTrackedsFiles: originTrackedsFiles
			});
		} else {
			setSubmitted(false);
		}

	}

	function resetForm() {
		let companyWebsite = '';
		let companyPhone = null;

		setSubmitted(false);


		if (companyInformation.website != "null") {
			companyWebsite = companyInformation.website;
		}

		if (companyInformation.phone != "null") {
			companyPhone = companyInformation.phone;
		}

		setForm({
			name: companyInformation.name,
			website: companyWebsite,
			phone: companyPhone,
			target: companyInformation.targetPercent,
			currency: companyInformation.currencyCode || 'USD'
		})

		setFormError({
			name: '',
			website: '',
			phone: '',
			target: '',
			currency: ''
		})

	}

	useEffect(() => {

		let companyWebsite = '';
		let companyPhone = '';

		if (companyInformation.website != "null") {
			companyWebsite = companyInformation.website;
		}

		if (companyInformation.phone != "null") {
			companyPhone = companyInformation.phone;
		}

		console.log('companyInformation', companyInformation)
		setForm({
			name: companyInformation.name,
			website: companyWebsite,
			phone: companyPhone,
			target: companyInformation.targetPercent,
			currency: companyInformation.currencyCode || 'USD',
			originTrackedsFiles: (!companyInformation.originTrackedsFiles || companyInformation.originTrackedsFiles?.length === 0) ? '' : companyInformation.originTrackedsFiles,
			auth_domain: companyInformation.auth_domain || []
		})

	}, [companyInformation.loading])

	useEffect(() => {
		handleChange({ target: { name: 'show_goals', value: showGoals } })
	}, [showGoals])

	useEffect(() => {	
		console.log("form", form)
	}, [form])

	return (
		<React.Fragment>

			<div className="form extra">
				<form onSubmit={handleSubmit}>
					<div className="field">
						<label htmlFor="companyName">{t('settings.company.name')}*</label>
						<input id="companyName" type="text" name="name" value={form.name} onChange={handleChange} />
						{formError.name &&
							<div className="error">{formError.name}</div>
						}
					</div>

					<div className="fieldset">
						<div className="field website">
							<label htmlFor="companyWebsite">{t('settings.company.website')}</label>
							<input id="companyWebsite" type="text" name="website" value={form.website}
								onChange={handleChange} />
						</div>
						<div className="field">
							<label htmlFor="companyTarget">{t('settings.company.target')}</label>
							<input id="companyTarget" type="number" min="50" max="100" name="target" value={form.target}
								onChange={handleChange} />
							{formError.name &&
								<div className="error">{formError.target}</div>
							}
						</div>
					</div>

					<div className="fieldset">
						<div className="field">
							<label htmlFor="companyPhone">{t('settings.company.phone')}</label>

							<InputMask
								id="companyPhone"
								name="phone"
								value={form.phone}
								onChange={handleChange}
								{...props}
								mask={mask}
								alwaysShowMask={false}
								maskPlaceholder={"(00)"}
								onBlur={e => {
									if (size(trim(e.target.value, '_')) === 14) {
										setMask('(99) 9999-9999');
									}
								}}
								onFocus={e => {
									if (size(trim(e.target.value, '_')) === 14) {
										setMask('(99) 99999-9999');
									}
								}}
							>
								{inputProps => (<input {...inputProps} type="tel" />)}
							</InputMask>
						</div>

						<div className="field">
							<label htmlFor="organizationId">{t('settings.company.organizationId')}</label>
							<input id="organizationId" type="text"
								value={companyInformation?.id}
								onChange={handleChange} disabled />
							<button type="button" className="copy" onClick={() => {
								navigator.clipboard.writeText(companyInformation?.id);
							}}>
								<IconCopy></IconCopy>
								<div className="hint">{t('settings.company.copy')}</div>
							</button>
						</div>
					</div>

					<div className="field">
						<label htmlFor="companyToken">{t('settings.company.organization_token')}</label>
						<input id="companyToken" type="text" value={companyInformation.organizationToken}
							onChange={handleChange}
							disabled />
						<button type="button" className="copy" onClick={() => {
							navigator.clipboard.writeText(companyInformation.organizationToken);
						}}>
							<IconCopy></IconCopy>
							<div className="hint">{t('settings.company.copy')}</div>
						</button>
					</div>
					<div className="field" style={{ display: 'none' }}>
						<label htmlFor="companyToken">{t('settings.company.organization_token')}</label>
						<input id="companyToken" type="text" value={companyInformation.organizationToken}
							onChange={handleChange}
							disabled />
						<button type="button" className="copy" onClick={() => {
							navigator.clipboard.writeText(companyInformation.organizationToken);
						}}>
							<IconCopy></IconCopy>
							<div className="hint">{t('settings.company.copy')}</div>
						</button>
					</div>

					<div className="field">
						<label htmlFor="companyObjective">{t('settings.company.purpose')}</label>
						<input
							id="companyObjective"
							type="text"
							name="purpose"
							value={form.purpose}
							onChange={handleChange}
						/>
					</div>

					{(userDetails.profile_key === 'admin' || userDetails?.isMagicAgent === true) && (
						<div
							className="field"
							style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px' }}
						>
							<label
								htmlFor="show_goals"
								style={{ fontSize: '14px' }}
							>
								{t('settings.company.show_goals')}
							</label>

							<div>

								<span style={{ color: '#1E6B7F' }}>{t('settings.company.no')}</span>
								<Switch
									id="show_goals"
									checked={showGoals}
									onChange={() => setShowGoals(!showGoals)}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
								<span style={{ color: '#1E6B7F' }}>{t('settings.company.yes')}</span>
							</div>
						</div>
					)}


					{(userDetails.profile_key !== 'collaborator') && (

						<div className="field" style={{ display: 'flex', flexDirection: 'column' }}>
							<label htmlFor="originTrackedsFiles">{t('settings.company.collect_file_names')}</label>
							<Select
								id='originTrackedsFiles'
								isMulti
								defaultValue={form?.originTrackedsFiles || ''}
								name="originTrackedsFiles"
								options={originTrackedsFilesOptions}
								className="basic-multi-select"
								classNamePrefix="select"
								onChange={setOriginTrackedsFiles}
								styles={colourStyles}
								placeholder={t('filter.select')}
							/>
						</div>
					)}

					<div className="field">
						<label htmlFor="currency">{t("settings.company.field_currency")}*</label>
						<select id="currency" name="currency" value={form.currency || ''} onChange={handleChange} style={{backgroundPositionX:'500px'}}>
							<option value="" disabled hidden>{t("settings.company.select_option")}</option>
							{
								currencies && currencies.map((currency, index) => {
									return <option key={index} value={currency.value}>{currency[`label_${ userDetails.language || i18n.language}`] + ` ( ${currency.symbol} )`}</option>
								})
							}
						</select>
						{formError.currency &&
							<div className="error">{formError.currency}</div>
						}
					</div>

					{(userDetails.profile_key === 'admin' || userDetails?.isMagicAgent === true) && (
						<div className='field_auth_domains'>
							<label  className='label_auth_domains' htmlFor="auth_domains">{t('settings.company.auth_domains')}</label>
							<div className="tagBoxDomains">
                  <TagsInput
                      value={form.auth_domain}
                      name="auth_domains"
                      id="auth_domains"
                      onChange={values => setForm({ ...form, auth_domain: values })}
                      placeHolder={t('settings.company.auth_domains_placeholder')}
                    />
                </div>
						</div>
					)}

					{
						userDetails.allowed_update && (
							<div className="field">
								<button type="button" disabled={submitted ? true : false} onClick={() => history.push('/global-settings')}>
									{t('settings.company.cancel')}
								</button>
								<button type="submit"
									disabled={submitted ? true : false}>{t('settings.company.update')}</button>
							</div>
						)
					}
				</form>
			</div>
		</React.Fragment>

	)
};


function mapStateToProps(state) {

	return {
		token: state.authentication.user.accessToken,
		companyInformation: state.settings.companyInformation,
		userDetails: state.authentication.userDetails
	}
}

function mapDispatchToProps(dispatch) {
	return {
		settingsActions: bindActionCreators(settingsActions, dispatch)
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyInformation));
