import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, useFilters } from "react-table";
import IconInfo from "../_icons/IconInfo";
import IconSoftware from "../_icons/IconSoftware";
import IconSite from "../_icons/IconSite";
import IconOutdoor from "../_icons/IconOutdoor";
import { secondsToHm } from "../../_helpers";
import { Grid, Typography } from "@mui/material";
import { Clock, WarningOctagon } from "phosphor-react";
import style from "./ActivitiesFull.module.scss";
import BefectiveTooltip from "../_shared/BefectiveTooltip";
import InactivityCalendar from "./InactivityCalendar";


function ActivitiesFull({
    activities,
    stats,
    userDetails,
    typeView,
    toggleTypeView
}) {
  const { t } = useTranslation("common");

  const data = useMemo(() => (activities || []));
  const columns = React.useMemo(
    () => [
      {
        id: "exhibitionName",
        Header: t("activities.activity"),
        accessor: (c) => c.exhibitionName.toLowerCase(),
        Cell: (d) => {
          let iconType = "";

          if (d.row.original.trackedType == "Software") {
            iconType = <IconSoftware />;
          }
          if (d.row.original.trackedType == "Site") {
            iconType = <IconSite />;
          }
          if (d.row.original.trackedType == "Outdoor") {
            iconType = <IconOutdoor />;
          }

          let current = d.row.original;

          return (
            <div
              className={`${style.activity} ${style.noLink}`}
              title={d.row.original.exhibitionName}
            >
              <div className={style.icon}>{iconType}</div>

              {d.row.original.exhibitionName.substring(0, 50)}
            </div>
          );
        },
      },
      {
        Header: t("activities.category"),
        accessor: "categoryName",
      },
      {
        Header: t("activities.type"),
        accessor: "trackedType",
      },
      {
        Header: t("activities.total_time"),
        accessor: "duration",
        Cell: (d) => {
          let percentTotalTime = "";
          if (stats.totalWorked > 0) {
            percentTotalTime =
              Math.round((d.value / stats.totalWorked) * 100) + "%";
          }

          return (
            <div className={style.totalTime}>
              <div className={style.time}>{secondsToHm(d.value)}</div>
              <span>{percentTotalTime}</span>
            </div>
          );
        },
      },
      {
        Header: t("activities.work_related"),
        accessor: "productiveDuration",
        Cell: (d) => {
          let percentRelated = "0%";

          if (d.value > 0 && stats.totalWorkedRelated > 0) {
            percentRelated =
              Math.round((d.value / stats.totalWorkedRelated) * 100) + "%";
          }

          return (
            <div className={style.totalWork}>
              <span>{percentRelated}</span>
            </div>
          );
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableMultiSort: true,
        initialState: {
          sortBy: [
            {
              id: "duration",
              desc: true,
            },
          ],
        },
      },
      useFilters,
      useSortBy
    );

  return (
    <div className="box noBg">
      <Grid container spacing={1} padding={"0px"}>
        <Grid item xs={12} sm={3}>
          <div className={style.cardSmall}>
            <div className={style.boxIcon}>
              <Clock size={42} weight="bold" color="#A9C6D0" />
            </div>
            <div className={style.boxInfo}>
              <h6 className={style.workedTime}>
                {secondsToHm(stats.totalWorked || 0)}
              </h6>
              <div className={style.boxTitleWithTooltip}>
                {t("colab_page.activities.work_time")}
                <BefectiveTooltip
                  title={
                    <Typography
                      component={"div"}
                      variant="caption"
                      marginY={"4px"}
                      fontSize={"12px"}
                      lineHeight={"16px"}
                    >
                      {t("activities.tooltip1")}
                    </Typography>
                  }
                  placement="right"
                  arrow
                >
                  <span>
                    <IconInfo />
                  </span>
                </BefectiveTooltip>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className={style.cardSmall}>
            <div className={style.boxIcon}>
              <Clock size={42} weight="bold" color="#A9C6D0" />
            </div>
            <div className={style.boxInfo}>
              <h6 className={style.productiveTime}>
                {secondsToHm(stats.totalWorkedRelated || 0)}
              </h6>
              <div className={style.boxTitleWithTooltip}>
                {t("colab_page.activities.productive_time")}
                <BefectiveTooltip
                  title={
                    <Typography
                      component={"div"}
                      variant="caption"
                      marginY={"4px"}
                      fontSize={"12px"}
                      lineHeight={"16px"}
                    >
                      {t("colab_page.activities.tooltip2")}
                    </Typography>
                  }
                  placement="right"
                  arrow
                >
                  <span>
                    <IconInfo />
                  </span>
                </BefectiveTooltip>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className={style.cardSmall}>
            <div className={style.boxIcon}>
              <Clock size={42} weight="bold" color="#A9C6D0" />
            </div>
            <div className={style.boxInfo}>
              <h6>
                {secondsToHm(stats.sumTotalTimeOutdoors || 0)}
              </h6>
              <div className={style.boxTitleWithTooltip}>
                {t("colab_page.activities.inactivity_time")}
                <BefectiveTooltip
                  title={
                    <Typography
                      component={"div"}
                      variant="caption"
                      marginY={"4px"}
                      fontSize={"12px"}
                      lineHeight={"16px"}
                    >
                      {t("colab_page.activities.tooltip3")}
                    </Typography>
                  }
                  placement="right"
                  arrow
                >
                  <span>
                    <IconInfo />
                  </span>
                </BefectiveTooltip>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className={`${style.cardSmall} ${style.cardHPointer} ${typeView === "inactivity" ? style.active : ""}`} onClick={toggleTypeView} title={typeView === "activities" ? t("colab_page.activities.view_inactivity") : t("colab_page.activities.view_activities")}>
            <div className={style.boxIcon}>
              <WarningOctagon size={42} weight="bold" color="#FF7A90" />
            </div>
            <div className={style.boxInfo}>
              <h6>
                {stats.totalOutdoors || 0}
              </h6>
              <div className={style.boxTitleWithTooltip}>
                {t("colab_page.activities.inactivity")}
                <BefectiveTooltip
                  title={
                    <Typography
                      component={"div"}
                      variant="caption"
                      marginY={"4px"}
                      fontSize={"12px"}
                      lineHeight={"16px"}
                    >
                      {t("colab_page.activities.tooltip4")}
                    </Typography>
                  }
                  placement="right"
                  arrow
                >
                  <span>
                    <IconInfo />
                  </span>
                </BefectiveTooltip>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {
        typeView == "activities" 
        ?
        <div className={style.tableActivities}>
            {data.length > 0 && (
            <table {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                        {column.render("Header")}
                        <span>
                            {column.isSorted ? (
                            column.isSortedDesc ? (
                                <div className={style.arrowDown}></div>
                            ) : (
                                <div className={style.arrowUp}></div>
                            )
                            ) : (
                            ""
                            )}
                        </span>
                        </th>
                    ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                        return (
                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                        );
                        })}
                    </tr>
                    );
                })}
                </tbody>
            </table>
            )}

            {data.length == 0 && <p className={"noData"}>{t("no_data")}</p>}
        </div>
        :
        <InactivityCalendar/>

      }
    </div>
  );
}

export default ActivitiesFull;
