import React from 'react'
import styles from './FragmentsMoodAvg.module.scss'
import moment from 'moment'
import { getMoodById } from '../../../_helpers'


export default function FragmentsMoodAvg({
  series=[],
  handleClick = () => {}
}) {
  return (
    series.length > 0
    &&
    <div 
      className={styles.containerFragments} 
      onClick={(e)=>{
        e.stopPropagation()
        handleClick()
      }}>
      {
        series.length > 7
        ?        
        series.map((item, index) => {
          if([0,6].includes(moment(item.day_ref).weekday())){
            return <div key={`fragment-${index}`} style={{backgroundColor: '#ffffff', width: `3px`, height: '16px', borderRight: '1px solid #ffffff'}}></div>
          }
          return <div key={`fragment-${index}`} style={{backgroundColor: item.color, width: `6px`, height: '16px', borderRight: '1px solid #ffffff'}}></div>
        })
        :
        series.map((item, index) => {
          if(item.avg_mood_day){
            return <img key={`fragment-${index}`} src={getMoodById(item?.avg_mood_day).img} width={'18px'} height={'18px'} style={{marginRight:'2px'}}/>
          }
          return <div key={`fragment-${index}`} style={{backgroundColor: item.color, width: `18px`, height: '18px', borderRadius:'50%', marginRight:'2px'}}></div>
        })
      }
    </div>
  )
}
