import React, { useEffect, useState } from 'react'
import style from './styles.module.scss';
import { authHeader } from '../../../../_helpers';
import IconInfo from '../../../_icons/IconInfo';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import Widget30Skeleton from './Widget30Skeleton';
import { ThumbsUp, ThumbsDown, Gauge } from 'phosphor-react';
import GaugeComponent from "react-gauge-component";
import BefectiveTooltip from '../../../_shared/BefectiveTooltip';
import { api } from '../../../../_helpers/api';
import GradientBar from './GradientBar';


export default function Widget30({
  alertActions,
  currentDate,
  width,
  filters
}) {
  const { t, i18n } = useTranslation('common');
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState(null);
  const colors = ["#1E6B7F", "#FDBE2B", "#86B2B3", "#187DFF", "#22BEAD"];

  const getData = async ({filterTeams, filterTeammates, filterAssessment}) => {
    try {
      setFetching(true)
      
      const response = await api.get(`/performance-assessment/widgets/overall?current_day=${currentDate.format('YYYY-MM-DD')}${filterTeams}${filterTeammates}${filterAssessment}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget30!!!')
      }
      const { data } = response
      setData(data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData({...filters})
  }, [])

  return (
    !fetching
      ?

      <Grid item xs={width}>
        <div className={style.container}>
          <div>
            <div className={style.header}>
              <div className={style.boxTitle}>
                <h6 className={style.title}>{t('widgets.widget30.title')}</h6>
                <BefectiveTooltip title={<Typography component={'div'} variant='caption' lineHeight={'14px'} marginY={'2px'}>{t('widgets.widget30.tooltip')}</Typography>} placement="right-start" arrow interactive>
                  <div><IconInfo/></div>
                </BefectiveTooltip>
              </div>
            </div>
            <Grid container spacing={1}sx={{display:'flex', flexDirection:'column', minHeight:'180px', justifyContent:'space-around' }}>
             
             <Grid item xs={12} className={style.boxLeft}>
                  <div className={style.rowStrongAndWeak}>
                    <div className={`${style.boxIcon} ${style.strongPointBgColor}`}>
                      <Gauge size={18} color='#22BEAD'/>
                    </div>
                    <div className={style.boxInfo}>
                      <h6 className={style.strongPointColor}>SCORE</h6>
                      {
                        data?.general_avg?.avg
                        ?
                        <span>{Number(data?.general_avg?.avg || 0).toFixed(2)}: {data?.general_avg?.classification?.label || '-----'}</span>
                        :
                        <span>-----</span>
                      }
                    </div>
                  </div>
                  <div className={style.rowStrongAndWeak}>
                    <div className={`${style.boxIcon} ${style.strongPointBgColor}`}>
                      <ThumbsUp size={18} color='#22BEAD'/>
                    </div>
                    <div className={style.boxInfo}>
                      <h6 className={style.strongPointColor}>{t('widgets.widget30.strong_point')}</h6>
                      {
                        data?.strong_point
                        ?
                        <span>{data?.strong_point?.name || '-----'}: {Number(data?.strong_point?.avg || 0).toFixed(2)}</span>
                        :
                        <span>-----</span>
                      }
                    </div>
                  </div>
                  <div className={style.rowStrongAndWeak}>
                    <div className={`${style.boxIcon} ${style.weakPointBgColor}`}>
                      <ThumbsDown size={18} color='#FF7A90'/>
                    </div>
                    <div className={style.boxInfo}>
                      <h6 className={style.weakPointColor}>{t('widgets.widget30.weak_point')}</h6>
                      {
                        data?.weak_point
                        ?
                        <span>{data?.weak_point?.name || '-----'}: {Number(data?.weak_point?.avg || 0).toFixed(2)}</span>
                        :
                        <span>-----</span>
                      }
                    </div>
                  </div>
              </Grid>

              <Grid item xs={12} className={style.boxRight}>                
                {/* <div className={style.chartGauge} style={{width:'260px'}}>
                  <GaugeComponent
                    type="semicircle"
                    arc={{
                      width: 0.10,
                      padding: 0.005,
                      cornerRadius: 1,
                      // gradient: true,
                      subArcs: data?.colors?.length > 0
                      ?
                      data?.colors
                      :
                      [
                        {
                          limit: 2,
                          color: '#D2D7E1'
                        }
                      ]
                    }}
                    pointer={{type: "arrow", elastic: true, color:  "#4F7091"}}
                    value={Number(data?.general_avg?.avg || 0).toFixed(2)}
                    labels={{
                      valueLabel: { 
                        style: {
                          fill:  "#4F7091", // Custom color for the main value
                          fontSize: '24px', // You can adjust the font size as needed
                          fontWeight: 'bold', // You can also set the font weight
                          textShadow: 'none'
                        },
                        formatTextValue: value => String(Number(data?.general_avg?.avg || 0).toFixed(2)),
                        hide: true
                      }
                      
                    }}
                    minValue={1}
                    maxValue={6}
                  />
                  <div className={style.labelScoreGeneral}>
                    <h4 style={{color: "#4F7091"}}>{Number(data?.general_avg?.avg || 0).toFixed(2)}</h4>
                    <p style={{color: "#4F7091"}}>{data?.general_avg?.classification?.label || "-----"}</p>
                  </div>
                </div> */}
                <GradientBar 
                  colors={data?.colors?.length > 0 ? data?.colors?.map(color => color.color) : colors} 
                  markerPercentage={((data?.general_avg?.avg || 0) / (data?.colors?.length > 0 ? data?.colors?.length : 1)) * 100}
                  avgScore={Number(data?.general_avg?.avg || 0).toFixed(1)}
                  />
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
      :
      <Grid item xs={width}>
        <Widget30Skeleton />
      </Grid>

  )
}
