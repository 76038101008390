// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".-Rtt7tAECK8-2qhnSLEaSg\\=\\= {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.-Rtt7tAECK8-2qhnSLEaSg\\=\\=._7CAgoRyRO06P8sYAK6m6Hw\\=\\= span {\n  color: #FF7A90;\n  font-size: 14px;\n}\n\n.-Rtt7tAECK8-2qhnSLEaSg\\=\\=._7CAgoRyRO06P8sYAK6m6Hw\\=\\= .TdtOFx0cDWeVpOazeNIECw\\=\\= {\n  font-weight: 500;\n  color: #FF7A90;\n  background-color: #FFECEC;\n  padding: 2px 6px;\n  font-size: 11px;\n  border-radius: 4px;\n}\n\n.-Rtt7tAECK8-2qhnSLEaSg\\=\\=.fTKq--EXOjKjLwarROJ-0Q\\=\\= span {\n  color: #22BEAD;\n  font-size: 14px;\n}\n\n.-Rtt7tAECK8-2qhnSLEaSg\\=\\=.fTKq--EXOjKjLwarROJ-0Q\\=\\= .TdtOFx0cDWeVpOazeNIECw\\=\\= {\n  font-weight: 500;\n  color: #22BEAD;\n  background-color: #ECFDF9;\n  padding: 2px 6px;\n  font-size: 11px;\n  border-radius: 4px;\n}", "",{"version":3,"sources":["webpack://./src/_components/reports/value_hour/AvgValueHour.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACF;;AACA;EACE,cAAA;EACA,eAAA;AAEF;;AACA;EACE,gBAAA;EACA,cAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;AAEF;;AACA;EACE,cAAA;EACA,eAAA;AAEF;;AACA;EACE,gBAAA;EACA,cAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;AAEF","sourcesContent":[".boxAvgValue{\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n.boxAvgValue.danger span{\n  color: #FF7A90;\n  font-size: 14px;\n}\n\n.boxAvgValue.danger .percent{\n  font-weight: 500;\n  color: #FF7A90;\n  background-color: #FFECEC;\n  padding: 2px 6px;\n  font-size: 11px;\n  border-radius: 4px;\n}\n\n.boxAvgValue.success span{\n  color: #22BEAD;\n  font-size: 14px;\n}\n\n.boxAvgValue.success .percent{\n  font-weight: 500;\n  color: #22BEAD;\n  background-color: #ECFDF9;\n  padding: 2px 6px;\n  font-size: 11px;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxAvgValue": "-Rtt7tAECK8-2qhnSLEaSg==",
	"danger": "_7CAgoRyRO06P8sYAK6m6Hw==",
	"percent": "TdtOFx0cDWeVpOazeNIECw==",
	"success": "fTKq--EXOjKjLwarROJ-0Q=="
};
export default ___CSS_LOADER_EXPORT___;
