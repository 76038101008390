import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { Box, CircularProgress, Drawer, IconButton, TextField } from '@material-ui/core';
import { useTranslation, initReactI18next } from "react-i18next";
import { makeStyles } from '@material-ui/styles';

import Filterbar from '../../_components/_shared/Filterbar';
import MenuCallsPage from '../../_components/_shared/MenuCallsPage';
import DrawerComments from '../../_components/calls_page/DrawerComments'
import Toolbar from '../../_components/_shared/Toolbar';
import AlertBox from '../../_components/_shared/AlertBox';
import { alertActions } from '../../_actions';
import { AttachFile, Close } from '@material-ui/icons';
import { TableCallsPage } from '../../_components/calls_page/TableCallsPage';
import { HeaderCallsPage } from '../../_components/calls_page/HeaderCallsPage';
import {api} from '../../_helpers/api'

import './styles.scss';
import { authHeader } from '../../_helpers';

const useStyles = makeStyles((theme) => ({

  root: {
    '& .MuiFormHelperText-contained': {
      marginLeft: '0px',
      marginRight: '0px'
    },
    '& .MuiTextField-root': {
      width: '100%',
      borderColor: '#e3e9f1',
      marginBottom: '24px',
      '& .MuiOutlinedInput-root': {
        borderColor: '#e3e9f1',
        borderRadius: '0px',
        '& input': {
          width: '100%',
        },
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#3f51b5',
        borderWidth: '1px'
      }
    },
  },
})); 



function CallsPage(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('common');
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [toggleDrawerAddCall, setToggleDrawerAddCall] = useState(false);
  const [state_drawer, setStateDrawer] = useState(false);
  const [callsData, setCallsData] = useState({});
  const [callDataDrawer, setCallsDataDrawer] = useState()
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [callsDataFiltered, setCallsDataFiltered] = useState([]); 

  const [form, setForm] = useState({
    title: '',
    description: '',
    urgency: 0,
    files: []
  })

  const [formError, setFormError] = useState({
    title: '',
    description: '',
    urgency: '',
  })

  const { userDetails, alertActions } = props

  function handleFilterCalls(searchContent) {
    if (searchContent === ''){
      setCallsDataFiltered(callsData?.calls)
    }else{
      const callsFilteredForTile = callsData.calls.filter(item => item.title.toLowerCase().includes(searchContent.toLowerCase()));

      const callsFilteredForId = callsData.calls.filter(item => {
        if (
            String(item.id) === searchContent 
            && !item.title.toLowerCase().includes(searchContent.toLowerCase())
            && !item.description.toLowerCase().includes(searchContent.toLowerCase())
        ){
          return item
        }

        return false
      })

      const callsFilteredForDescription = callsData.calls.filter(item => {
        if (
          item.description.toLowerCase().includes(searchContent.toLowerCase())
          && String(item.id) !== searchContent 
          && !item.title.toLowerCase().includes(searchContent.toLowerCase())
        ){
          return item
        }

        return false
      })

      setCallsDataFiltered([...callsFilteredForTile, ...callsFilteredForId, ...callsFilteredForDescription])
    }
  }

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  };

  function toggleFromMenu(value) {

    if (value === 'closed') {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false)
    }
  }

  const toggleDrawer = (open, callData) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setCallsDataDrawer(callData)
    setStateDrawer(open);
  };

  const getCallsData = async() => {
    try {
      const response = await api.get(`/support_calls?organization_id=${userDetails?.organizationId}&owner_id=${userDetails?.id}`,
        {
          headers: authHeader()
        }
      )

      setCallsData(response.data)
      setCallsDataFiltered(response.data.calls)
    } catch (error) {
      console.log(error.response)
      //alertActions.error(t('calls_page.error.get_calls'))
    }
  }

  const handleChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }

  const onChangeInputFile = (e) => {
    const filesInput = e.target.files;
    const filesArr = Array.prototype.slice.call(filesInput);
    setForm({ ...form, files: [...form.files, ...filesArr] });
    setFormError({ ...formError, files: '' })
  }

  const removeFile = (file) => {
    setForm({ ...form, files: form.files.filter(f => f !== file) });
  }

  const validateForm = (form) => {
    const errors = {};

    if (!form.title) {
      errors.title = t('calls_page.error.required_field')
    } else if (form.title.length < 10) {
      errors.title = t('calls_page.error.title')
    }

    if (!form.description) {
      errors.description = t('calls_page.error.required_field')
    } else if (form.description.length < 10) {
      errors.description = t('calls_page.error.description')
    }

    if (!form.urgency) {
      errors.urgency = t('calls_page.error.required_field')
    }

    if (form.files.length > 0 ){
      form.files.forEach(file => {
        const acceptFiles = ['PNG', 'JPG', 'JPEG', 'GIF', 'WEBP']

        if((file.size / 1000000) > 2) {
          errors.files = t('calls_page.error.file')
        }

        const fileNameSplitted = file.name.split('.')
        const fileExtension = fileNameSplitted[fileNameSplitted.length - 1]

        if (!acceptFiles.includes(fileExtension.toUpperCase())) {
          errors.files = t('calls_page.error.file_type')
        }
      })
    }

    return errors
  }

  const handleSubmitSupportCall = (e) => {
    e.preventDefault();
    setFormError(validateForm(form))
    setSubmitted(true)
  }

  const sendCallToApi = async () => {
    try {
      setIsSubmitting(true)
      const formData = new FormData();

      formData.append('open_date', Moment(new Date()).format('YYYY-MM-DD'));
      formData.append('title', form.title);
      formData.append('description', form.description.replaceAll('\n', '<br />'));
      formData.append('urgency_id', form.urgency);
      formData.append('client_email', userDetails?.email);

      for(let item of form.files) {
        formData.append('files', item)
      }

      await api.post(
        `/support_calls?organization_id=${userDetails?.origin_organization}&owner_id=${userDetails?.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      setIsSubmitting(false)
      closeDrawerAddCall()
      alertActions.success(t('calls_page.success.create_call'))
      getCallsData()
      // setTimeout(() => {
      //   window.location.reload(true)
      // }, 1000)
    } catch (error) {
      setIsSubmitting(false)
      closeDrawerAddCall()
  
      const choice = error.response.data.code || "def"
      switch(choice){
        case"BD-00021":
          //alertActions.error(t(error.response.data.message))
          break;
        default:
          //alertActions.error(t('calls_page.error.create_call'))
      }
      
    }

  }

  const toggleUrgency = (id) => {
    if (id !== 0) {
      setForm({ ...form, urgency: id })
      setFormError({ ...formError, urgency: '' })
    }

  }

  const resetForm = () => {
    setForm({
      title: '',
      description: '',
      urgency: 0,
      files: []
    })
  }

  const resetFormError = () => {
    setFormError({
      title: '',
      description: '',
      urgency: '',
    })
  }

  const closeDrawerAddCall = () => {
    setToggleDrawerAddCall(false);
    resetForm();
    resetFormError()
  }

  useEffect(() => {

    if (Object.keys(formError).length === 0 && submitted) {
      sendCallToApi()
    } else {
      setSubmitted(false)
    }

  }, [formError, submitted])
  
  useEffect(() => {
    document.title = 'Befective | ' + t('calls_page.support_calls');

    getCallsData()
  }, []);

  return (
    <>
      <>

        <AlertBox />
        <MenuCallsPage menuStatus={isMenuOpen} toggle={toggleFromMenu} />

        <div className={isMenuOpen ? "menuToggle positionArrowCalls open" : "menuToggle positionArrowCalls"}
          onClick={() => {
            toggleMenu('main')
          }}>
          <i className="arrow"></i>
          <span></span>
          <span></span>
          <span></span>
        </div>

      </>

      <section className="mainContent productivity">

        <Toolbar />

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
          actionAddCalls={true}
          setToggleDrawerAddCall={setToggleDrawerAddCall}

        />

        {/* PAGE STARTS */}
        <div className="container-calls-page">
          <HeaderCallsPage 
            total_open={callsData?.total_open} 
            total_solved={callsData?.total_solved}
            handleFilterCalls={handleFilterCalls}
          />

          <TableCallsPage 
            data={callsDataFiltered} 
            toggleDrawer={toggleDrawer}
          />
        </div>
        {/* PAGE ENDS */}

        <DrawerComments
          callData={callDataDrawer}
          state_drawer={state_drawer}
          toggleDrawer={toggleDrawer}
          callsDataFiltered={callsDataFiltered}
          setCallsDataFiltered={setCallsDataFiltered}
        />
        
        <Drawer anchor='right' open={toggleDrawerAddCall} onClose={closeDrawerAddCall}>

          <div style={{ position: 'absolute', right: 4, top: 4 }}>
            <IconButton aria-label="close" onClick={closeDrawerAddCall}>
              <Close fontSize="medium" />
            </IconButton>
          </div>

          <div className="drawer-container-calls-page">
            <header>
              <h2 style={{ marginBottom: 26 }}>{t('button.open_call')}</h2>
            </header>

            <main className="main-content-drawer">
              <form 
                className={`${classes.root} formAddCall`} 
                noValidate 
                autoComplete="off" 
                onSubmit={handleSubmitSupportCall}
              >
                <TextField
                  id="title"
                  name='title'
                  label={t('calls_page.add_drawer.title')}
                  placeholder={t('calls_page.add_drawer.title_placeholder')}
                  variant="outlined"
                  value={form.title || ''}
                  onChange={handleChange}
                  error={formError.title ? true : false}
                  helperText={formError.title}
                />


                <TextField
                  id="description"
                  name='description'
                  label={t('calls_page.add_drawer.description')}
                  multiline
                  placeholder={t('calls_page.add_drawer.description_placeholder')}
                  minRows={4}
                  variant="outlined"
                  value={form.description || ''}
                  onChange={handleChange}
                  error={formError.description ? true : false}
                  helperText={formError.description}
                />

                <div style={{ width: '100%', marginBottom: 22 }}>

                  <div className="boxCustomLabel">
                    <label className='customLabel' htmlFor="">{t('calls_page.priority.priority_normal')}</label>
                  </div>

                  <div className="row">

                    <div className="col">
                      <div className={form?.urgency === 3 ? `urgencyOption active` : `urgencyOption`}>
                        <button 
                          type='button' 
                          onClick={() => toggleUrgency(3)}>
                            {t('calls_page.priority.low')}
                        </button>
                      </div>
                    </div>

                    <div className="col">
                      <div className={form?.urgency === 2 ? `urgencyOption active` : `urgencyOption`}>
                        <button 
                          type='button' 
                          onClick={() => toggleUrgency(2)}>
                            {t('calls_page.priority.average')}
                        </button>
                      </div>
                    </div>

                    <div className="col">
                      <div className={form?.urgency === 1 ? `urgencyOption active` : `urgencyOption`}>
                        <button 
                          type='button' 
                          onClick={() => toggleUrgency(1)}>
                            {t('calls_page.priority.high')}
                        </button>
                      </div>
                    </div>
                  </div>

                  {formError.urgency &&
                    <div className="error">{formError.urgency}</div>
                  }
                </div>

                <div className='boxInputFile' style={{
                  fontFamily: 'sans-serif',
                  textAlign: 'left',
                  display: 'flex',
                }} >
                  <label className="custom-file-upload">
                    <input 
                      type="file" 
                      multiple 
                      onChange={onChangeInputFile} 
                      accept="image/*"
                    />
                      <AttachFile fontSize='small' />
                    <div>
                      {t('calls_page.add_drawer.attach_file')}
                    </div>
                  </label>

                  <p className='attachment-info'>
                    {t('calls_page.add_drawer.attach_info')}*
                  </p>

                  {formError.files &&
                    <div className="error">{formError.files}</div>
                  }

                  {form.files.length > 0 && form.files.map((file, index) =>(
                    <div key={index} className="file-preview">
                      <div>{file.name}</div>
                      <div>
                        <IconButton 
                          aria-label="delete" 
                          color="primary" 
                          onClick={() => { removeFile(file) }}
                        >
                          <Close fontSize='small' />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>

                <Box>
                  <button
                    className='button-submit-form-calls'
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={20}/>
                    ) : (
                      t('calls_page.add_drawer.create') 
                    )}
                  </button>
                </Box>

              </form>

            </main>
          </div>
        </Drawer>
        
      </section>
    </>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(CallsPage)