import React, { useState, useEffect, useRef } from "react";
import Moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ActivitiesFullSkeleton from "../../_components/productivity/ActivitiesFullSkeleton";
import ActivitiesFull from "../../_components/productivity/ActivitiesFull";
import HeaderProductivityColab from "../../_components/_shared/HeaderProductivityColab";
import { Container, Grid } from "@mui/material";
import { api } from "../../_helpers/api";
import { authHeader } from "../../_helpers";
import AlertBox from "../../_components/_shared/AlertBox";
import HistoricalOutdoorsDrawer from "../../_components/productivity/HistoricalOutdoorsDrawer";

function ProductivityActivitiesPage(props) {
  const { t } = useTranslation("common");

  const isMountedDate = useRef(false);

  const [fetching, setFetching] = useState(true);
  const [dataActivities, setDataActivities] = useState(null);
  const [typeView, setTypeView] = useState("activities");
  const [openDrawerHistory, setOpenDrawerHistory] = useState(false);

  const toggleTypeView = () => {
    setTypeView(typeView == "activities" ? "inactivity" : "activities");
  }

  const getActivities = async (date) => {
    try {
      setFetching(true);

      const response = await api.get(
        `/stats/my-productivity/activities?current_date=${date}`,
        {
          headers: authHeader(),
        }
      );

      if (response.status !== 200) {
        throw new Error("Error to get activities");
      }

      setDataActivities(response.data);
      setFetching(false);
    } catch (error) {
      console.log(error);
      setFetching(false);
    }
  };

  const handleOpenDrawerHistory = () => { 
    setOpenDrawerHistory(true);
  };
  const handleCloseDrawerHistory = () => { 
    setOpenDrawerHistory(false);
  };

  useEffect(() => {
    const date = props.filter.singleDate
      ? props.filter.singleDate
      : Moment(new Date()).format("YYYY-MM-DD");
    getActivities(date);
  }, []);

  useEffect(() => {
    if (isMountedDate.current) {
      if (props.filter.singleDate) {
        getActivities(props.filter.singleDate);
      }
    } else {
      isMountedDate.current = true;
    }
  }, [props.filter.singleDate]);

  useEffect(() => {
    document.title =
      "Befective | " + t("home.my_productivity") + " | " + t("home.activities");
  },[]);

  return (
    <React.Fragment>

      <AlertBox />
      
      <section className="mainContent productivity">
        <HeaderProductivityColab
          smallHeight={true}
          pageRef="activities"
          showDateFilter={true}
          activeMenu="activities"
          typeView={typeView}
          handleOpenDrawerHistory={handleOpenDrawerHistory}
        />

        <Container
          component={"main"}
          maxWidth="xl"
          sx={{ flexGrow: 1, padding: "16px 0px" }}
        >
          <Grid container spacing={0} mb={"16px"} paddingX={"0px"}>
            <Grid item xs={12}>
              {/* PAGE STARTS */}
              <div className="mainPage noPadding">
                <div className="column single">
                  {fetching ? (
                    <ActivitiesFullSkeleton />
                  ) : (
                    <ActivitiesFull
                      activities={dataActivities?.rows}
                      stats={dataActivities?.totalActivies}
                      userDetails={props.userDetails}
                      typeView={typeView}
                      toggleTypeView={toggleTypeView}
                    />
                  )}
                </div>
              </div>
              {/* PAGE ENDS */}
            </Grid>
          </Grid>
        </Container>
      </section>

      <HistoricalOutdoorsDrawer openDrawer={openDrawerHistory} closeDrawer={handleCloseDrawerHistory}/>
    </React.Fragment>
  );
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

function mapStateToProps(state) {
  return {
    filter: state.filter,
    userDetails: state.authentication.userDetails,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductivityActivitiesPage);
