// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a7C0JtTDD0RBPFwz0tU1Yw\\=\\= {\n  position: absolute;\n  font-size: 10px;\n  font-weight: 500;\n  padding: 2px 6px;\n  top: 10px;\n  right: 10px;\n  border-radius: 2px;\n}\n\n.a7C0JtTDD0RBPFwz0tU1Yw\\=\\=.yAX1M04I6WVHvB-\\+fHjUHQ\\=\\= {\n  background-color: #DCECFF;\n  color: #187DFF;\n}\n\n.a7C0JtTDD0RBPFwz0tU1Yw\\=\\=._5Arx2FIUqN\\+IFNLpGHk77A\\=\\= {\n  background-color: #E4F7F5;\n  color: #22BEAD;\n}\n\n.a7C0JtTDD0RBPFwz0tU1Yw\\=\\=.vVtc\\+sCvXFaGC482j1uCSg\\=\\= {\n  background-color: #F5F6F9;\n  color: #4F7091;\n}", "",{"version":3,"sources":["webpack://./src/_components/reports/value_hour/CardsHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,yBAAA;EACA,cAAA;AACF;;AAEA;EACE,yBAAA;EACA,cAAA;AACF;;AAEA;EACE,yBAAA;EACA,cAAA;AACF","sourcesContent":[".badgePercent{\n  position: absolute;\n  font-size: 10px;\n  font-weight: 500;\n  padding: 2px 6px;\n  top: 10px;\n  right: 10px;\n  border-radius: 2px;\n}\n\n.badgePercent.blue{\n  background-color: #DCECFF;\n  color: #187DFF;\n}\n\n.badgePercent.green{\n  background-color: #E4F7F5;\n  color: #22BEAD;\n}\n\n.badgePercent.grey{\n  background-color: #F5F6F9;\n  color: #4F7091;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgePercent": "a7C0JtTDD0RBPFwz0tU1Yw==",
	"blue": "yAX1M04I6WVHvB-+fHjUHQ==",
	"green": "_5Arx2FIUqN+IFNLpGHk77A==",
	"grey": "vVtc+sCvXFaGC482j1uCSg=="
};
export default ___CSS_LOADER_EXPORT___;
