import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import Toolbar from '../../_components/_shared/Toolbar'
import { useTranslation } from "react-i18next";
import { alertActions, usersActions } from '../../_actions';
import { api } from '../../_helpers/api';
import { authHeader, befectiveFormatDate } from '../../_helpers';
import Filterbar from '../../_components/_shared/Filterbar';
import style from './styles.module.scss'
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import TableProfiles from '../../_components/profiles_settings/TableProfiles';
import HeaderProfiles from '../../_components/profiles_settings/HeaderProfiles';
import Header from '../../_components/switch_page/Header';
import { Box } from '@mui/material';

function ProfilesSettingsPage(props) {
  const { t, i18n } = useTranslation('common');
  const {  userDetails, alertActions} = props;
  const [data, setData] = useState([])
  const [fetching, setFetching] = useState(true)

  const columns = useMemo(() => {
    return [
      {
        Header: t('settings.profiles.col_profile'),
        accessor: 'name',
        Cell: (data) => {
          const {name, key, is_default_profile} = data.row.original
          if(is_default_profile === true){
            return t(`settings.teams.profiles.${key}`)
          }
          return name
        }
      },
      {
        Header: t('settings.profiles.col_description'),
        accessor: 'description',
        Cell: (data) => {
          const {description, key, is_default_profile} = data.row.original
          if(is_default_profile === true){
            return <span title={t(`settings.teams.profiles.${key}_description`)}>{t(`settings.teams.profiles.${key}_description`)}</span>
          }
          return description ? <span title={description}>{description}</span> : '-----'
        }
      },
      {
        Header: t('settings.profiles.col_updated_at'),
        accessor: 'updated_at',
        Cell: ({ value }) => {
          return befectiveFormatDate(value, i18n.language, 'T')
        }
      }
    ];
  }, []);


  const getDataProfiles = async (isFetching=true) => {
    try {
      if(isFetching) setFetching(true)

      const res = await api.get(`/settings/access-profile`, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro get profiles')
      }

      setData(res.data)

    } catch (error) {
      console.error(error)
    } finally {
      setFetching(false)
    }
  }

  useEffect(() => {
    getDataProfiles()
  }, [])

  useEffect(() => {
    document.title = 'Befective | ' + t('menu.profiles');
  }, []);


  return (

    <Box bgcolor="#F5F6F9" width="100%" display="flex" flexDirection="column">
      <Header toBackUrl={"/global-settings"} />
      <section className="mainContent">

        {/* <Toolbar /> */}

        <Filterbar
          filterWho={'disabled'}
          filterWhen={'disabled'}
          filterWhat={'disabled'}
          actionCreateProfile={true}
        />

        <div className={style.container}>
          {
            !fetching
              ?
              <>
                <HeaderProfiles />
                <TableProfiles
                  columns={columns}
                  data={data}
                  getData={getDataProfiles}
                  alertActions={alertActions}
                  userDetails={userDetails}
                />
              </>
              :
              <GlobalSkeleton totalRowsStats={0} totalTables={1} />
          }
        </div>
      </section>
    </Box>
  )
};

function mapDispatchToProps(dispatch) {

  return {
    usersActions: bindActionCreators(usersActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch)
  }
}

function mapStateToProps(state) {

  return {
    filter: state.filter,
    permissions: state.authentication.permissions,
    userDetails: state.authentication.userDetails
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilesSettingsPage)
