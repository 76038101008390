import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Grid } from '@mui/material';
import Widget13Skeleton from './Widget13Skeleton';
import { api } from '../../../_helpers/api';
import { authHeader } from '../../../_helpers';
import MoodVeryBad from '../../../../public/images/mood1_very_bad.png'
import MoodBad from '../../../../public/images/mood2_bad.png'
import MoodOk from '../../../../public/images/mood3_ok.png'
import MoodGood from '../../../../public/images/mood4_good.png'
import MoodVeryGood from '../../../../public/images/mood5_very_good.png'
import MoodWidget from './MoodWidget';

export default function Widget13({ width, currentDate, alertActions, filters }) {
  const { t, i18n } = useTranslation('common');

  const [data, setData] = useState({
    stats:{
      total_users_active: 0,
      total_users: 0
    },
    chart: {
      series: [
        {
          name: t('widgets.answers'),
          data: [
            { x: t('widgets.moods.very_bad'), y: 0, fillColor: "#FF7A90" },
            { x: t('widgets.moods.bad'), y: 0, fillColor: "#FF9B1C" },
            { x: t('widgets.moods.ok'), y: 0, fillColor: "#FFC94B" },
            { x: t('widgets.moods.good'), y: 0, fillColor: "#44D163" },
            { x: t('widgets.moods.very_good'), y: 0, fillColor: "#12B48E" },
          ],
        },
      ],
      options: {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
            columnWidth: "50%",
          },
        },
        xaxis: {
          categories: [t('widgets.moods.very_bad'), t('widgets.moods.bad'), t('widgets.moods.ok'), t('widgets.moods.good'), t('widgets.moods.very_good')],
          labels: {
            show: false,
          },
        },
        yaxis: {
          min: 0,
          max: 10,
          forceNiceScale: true,
          labels: {
            formatter: (value) => value,
          },
          title: {
            text: "",
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (value) => `${value} ${String(t('widgets.answers')).toLowerCase()}`,
          },
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const point = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            return `
              <div style="padding: 10px; background: #fff; border: 1px solid #ccc; border-radius: 5px;">
                <strong>${point.x}</strong>: ${point.y} ${String(t('widgets.answers')).toLowerCase()}
              </div>
            `;
          },
        },
        dataLabels: {
          enabled: false,
        },
      },
    },
    moodImages: [
      { src: MoodVeryBad, alt: t('widgets.moods.very_bad'), percent: '0%', color: "#FF7A90" },
      { src: MoodBad, alt: t('widgets.moods.bad'), percent: '0%', color: "#FF9B1C" },
      { src: MoodOk, alt: t('widgets.moods.ok'), percent: '0%', color: "#FFC94B" },
      { src: MoodGood, alt: t('widgets.moods.good'), percent: '0%', color: "#44D163" },
      { src: MoodVeryGood, alt: t('widgets.moods.very_good'), percent: '0%', color: "#12B48E" },
    ]

  });

  const refMoodImagesSrc = {
    very_bad: MoodVeryBad,
    bad: MoodBad,
    ok: MoodOk,
    good: MoodGood,
    very_good: MoodVeryGood
  }

  const [fetching, setFetching] = useState(true);

  const getData = async ({ filterStartDate, filterEndDate, filterTeams, filterTeammates }) => {
    try {
      setFetching(true)
      const response = await api.get(`/stats/executive/moods2?year_month=${currentDate.format('YYYY-MM')}&current_day=${currentDate.format('YYYY-MM-DD')}${filterStartDate}${filterEndDate}${filterTeams}${filterTeammates}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget13!!!')
      }
      const { data } = response

      setData({
        stats: data.stats,
        chart: {
          series: data.series,
          options: {
            chart: {
              type: "bar",
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: false,
                columnWidth: "50%",
              },
            },
            xaxis: {
              categories: data.categories,
              labels: {
                show: false, // Oculta os rótulos originais
              },
            },
            yaxis: {
              min: 0,
              max: data.stats.total_users ? parseInt(data.stats.total_users) : 10,
              forceNiceScale: true,
              labels: {
                formatter: (value) => value,
              },
              title: {
                text: "",
              },
            },
            tooltip: {
              enabled: true,
              y: {
                formatter: (value) => `${value} ${String(t('widgets.answers')).toLowerCase()}`,
              },
            },
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const point = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                return `
                  <div style="padding: 10px; background: #fff; border: 1px solid #ccc; border-radius: 5px;">
                    <strong>${point.x}</strong>: ${point.y} ${String(t('widgets.answers')).toLowerCase()}
                  </div>
                `;
              },
            },
            dataLabels: {
              enabled: false,
            },
          },
        },
        moodImages: data.moodImages.map(moodImage => ({
          ...moodImage,
          src: refMoodImagesSrc[moodImage.key]
        }))
    
      })
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData(filters)
  }, [])

  return (
    !fetching
      ?
      <Grid item xs={width}>
        <MoodWidget data={data}/>
      </Grid>
      :
      <Grid item xs={width}>
        <Widget13Skeleton />
      </Grid>
  )
};
