import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { history } from '../../_helpers';
import moment from 'moment';

const modules = {
	'productive': 'productivity_management',
	'goals': 'goals_management',
	'virtual-point': 'check_in_remote',
	'project_management': 'project_management',
	'spaces': 'rooms_management',
	'checkin-remote': 'check_in_remote_mobile',
	'performance-assessment': 'performance_assessment'
}

const pathsToVerify = [
	'productive',
	'goals',
	'virtual-point',
	'project_management',
	'spaces',
	'checkin-remote',
	'performance-assessment'
]

const PrivateRoute = ({ component: Component, userDetails, ...rest }) => {
	const path = rest.path.split('/')

	const user = localStorage.getItem(process.env.KEY_USER_LOCAL_STORAGE)

	let currentPath = window.location.pathname.split("/") || [];

	useEffect(() => {
		if (userDetails && currentPath[1] && pathsToVerify.includes(currentPath[1])) {

			const modulesUser = userDetails.modules.reduce((acc, module) => {
				return [...acc, module.name]
			}, [])

			if (!modulesUser.includes(modules[currentPath[1]])) {
				history.push("/")
			}
		}
	}, [path])
	if(userDetails?.org_client_type === 'poc' && moment(userDetails?.org_validation_date).isBefore(moment())) {
		history.push("/finalization-poc")
	}

	if (
		userDetails?.roleName === 'collaborator' && 
		!currentPath.includes('productivity') && 
		!currentPath.includes('messages') &&
		!currentPath.includes('settings') && 
		!currentPath.includes('goals') && 
		!currentPath.includes('virtual-point') &&
		!currentPath.includes('spaces') &&
		!currentPath.includes('project_management') &&
		!currentPath.includes('checkin-remote') &&
		!currentPath.includes('performance-assessment') &&
		!currentPath.includes('global-settings')
	) {
		history.push("/")
	}

	if (
		userDetails?.roleName !== 'collaborator' &&
		currentPath.includes('productivity')
	) {
		history.push("/")
	}
	

	return <Route {...rest} render={props => (
		user
			? <Component {...props} />
			: <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
	)} />
}


function mapDispatchToProps(dispatch) {

	return {
	}
}


function mapStateToProps(state) {
	return {
		userDetails: state.authentication.userDetails,
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)