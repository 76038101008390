// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gsEI-5QBzQyt2nxtraAciw\\=\\= {\n  display: flex;\n  justify-content: start;\n}\n\n.KqEMhEgJ2DfAxGMgQZOkIw\\=\\= {\n  display: flex;\n  background-color: #F5F6F9;\n  border-radius: 4px;\n  padding: 4px 6px;\n  border: none;\n  cursor: pointer;\n}\n.KqEMhEgJ2DfAxGMgQZOkIw\\=\\= div {\n  color: #4F7091;\n  font-size: 11px;\n  margin-right: 2px;\n  margin-top: 1px;\n}\n.KqEMhEgJ2DfAxGMgQZOkIw\\=\\= svg path {\n  stroke: #4F7091;\n}\n\n.KqEMhEgJ2DfAxGMgQZOkIw\\=\\=._0AnULffHj05w16ogXsqFGQ\\=\\= {\n  background-color: #E3EFFF;\n}\n.KqEMhEgJ2DfAxGMgQZOkIw\\=\\=._0AnULffHj05w16ogXsqFGQ\\=\\= div {\n  color: #187DFF;\n}\n.KqEMhEgJ2DfAxGMgQZOkIw\\=\\=._0AnULffHj05w16ogXsqFGQ\\=\\= svg path {\n  stroke: #187DFF;\n}", "",{"version":3,"sources":["webpack://./src/_components/reports/mood_new/ButtonAlertInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;AACF;AACE;EACE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;AACJ;AAEE;EACE,eAAA;AAAJ;;AAIA;EACE,yBAAA;AADF;AAGE;EACE,cAAA;AADJ;AAIE;EACE,eAAA;AAFJ","sourcesContent":[".buttonContainer{\n  display: flex;\n  justify-content: start;\n}\n\n.buttonAlertInfo{\n  display: flex;\n  background-color: #F5F6F9;\n  border-radius: 4px;\n  padding: 4px 6px;\n  border: none;\n  cursor: pointer;\n\n  div{\n    color: #4F7091;\n    font-size: 11px;\n    margin-right: 2px;\n    margin-top: 1px;\n  }\n  \n  svg path{\n    stroke: #4F7091;\n  }\n}\n\n.buttonAlertInfo.active{\n  background-color: #E3EFFF;\n\n  div{\n    color: #187DFF;\n  }\n\n  svg path{\n    stroke: #187DFF;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": "gsEI-5QBzQyt2nxtraAciw==",
	"buttonAlertInfo": "KqEMhEgJ2DfAxGMgQZOkIw==",
	"active": "_0AnULffHj05w16ogXsqFGQ=="
};
export default ___CSS_LOADER_EXPORT___;
