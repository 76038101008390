import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import Moment from "moment";
import { statsActions } from "../../../_actions";
import { historicalActions } from "../../../_actions";
import { secondsToHm } from "../../../_helpers";
import IconDate from "../../_icons/IconDate";

function TransactionalChartSummary(props) {

  
  const { t, i18n } = useTranslation("common");
  Moment.locale(t("date.locale"));
  
  const { chartData } = props;

  const [DisplayOutlineHTML, setDisplayOutlineHTML] = useState([]);
  const [DisplayDaysHTML, setDisplayDaysHTML] = useState([]);
  const [DisplayTotalBarsDays, setDisplayTotalBarsDays] = useState(0);

  function toggleShowing(where, day = null) {
    props.openWho({
      now: Moment().unix(),
      day: day,
    });
  }

  const findMaxHour = (data) => {
    return data.reduce((max, entry) => {
      const total = parseInt(entry.total_transactional) + parseInt(entry.total_no_transactional);
      return Math.max(max, total);
    }, 0);
  };

  function renderChart(data) {
    const OutlineHTML = [];
    const DaysHTML = [];

    setDisplayTotalBarsDays(data.length || 0);

    const MaxHours = findMaxHour(data) / 3600;

    let Fraction = Math.round(MaxHours / 9);

    for (let item of data) {
      let totalTransactional = 0;
      let totalNoTransactional = 0;
      let noBorder = "";
      let rows = MaxHours;

      if (MaxHours % 2 !== 0) {
        rows += 1;
      }

      const itemTotalTransactional = parseInt(item.total_transactional);
      const itemTotalNoTransactional = parseInt(item.total_no_transactional);

      if (MaxHours > 0 && itemTotalTransactional > 0) {
        totalTransactional = Math.round(((itemTotalTransactional / 3600) * 100) / rows);
      }
      if (MaxHours > 0 && itemTotalNoTransactional > 0) {
        totalNoTransactional = Math.round(((itemTotalNoTransactional / 3600) * 100) / rows);
      }

      if (totalTransactional + totalNoTransactional < 5) {
        noBorder = "no-border";
      }

      DaysHTML.push(
        <div className="day">
          <div className="labelBox">
            <div className="header">
              <div className="icon">
                <IconDate />
              </div>
              {Moment.utc(item.day).format("dddd")},{" "}
              {Moment.utc(item.day).format(t("date.mm/dd"))}
            </div>
            <div className="related">
              {t("reports_transactional.chart.transactional")}:{" "}
              {secondsToHm(itemTotalTransactional)}
            </div>
            <div className="unrelated">
              {t("reports_transactional.chart.not_transactional")}:{" "}
              {secondsToHm(itemTotalNoTransactional)}
            </div>
          </div>
          <div
            className="bar"
            onClick={() => {
              toggleShowing("day", Moment.utc(item.day).format("YYYY-MM-DD"));
            }}
          >
            <div
              className={"unrelated " + noBorder}
              style={{ height: totalNoTransactional + "%" }}
            ></div>
            <div
              className={"related " + noBorder}
              style={{ height: totalTransactional + "%" }}
            ></div>
          </div>
          <div className="labelDay">
            {Moment.utc(item.day).format(t("date.mm/dd"))}
          </div>
        </div>
      );
    }

    setDisplayDaysHTML(DaysHTML);

    if (Fraction === 0) {
      Fraction = 1;
    }

    let rows = MaxHours / Fraction;

    if (MaxHours % 2 === 0) {
      rows = Math.ceil(MaxHours / Fraction);
    }

    for (let n = 0; n <= rows; n++) {
      let hour = Fraction * n;
      OutlineHTML.push(
        <div className="hour">
          <span>{parseInt(hour)}h</span>
        </div>
      );
    }

    if (MaxHours % 2 !== 0) {
      OutlineHTML.push(
        <div className="hour">
          <span>{parseInt(MaxHours + 1)}h</span>
        </div>
      );
    }
    setDisplayOutlineHTML(OutlineHTML);
  }

  useEffect(() => {
    if (chartData && chartData.length > 0) {
      renderChart(chartData);
    }
  }, [chartData]);

  return (
    <div className="box historical noBg">
      <div className="chartWrapper">
        <div className="internalBreadcrub">
          <div className="selectedDate">
            {t('reports_transactional.chart.title')}
          </div>
        </div>
        <div className={"historicalChart days"}>
          <div className="outline">
            {DisplayOutlineHTML}
            <div className="timeLabel">{t("historical.hour")}</div>
          </div>
          <div className={DisplayTotalBarsDays > 10 ? "days" : "days few"}>
            {DisplayDaysHTML}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    historicalActions: bindActionCreators(historicalActions, dispatch),
    statsActions: bindActionCreators(statsActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    historical: state.historical,
    filter: state.filter,
    userDetails: state.authentication.userDetails,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionalChartSummary);
