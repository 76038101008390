import React, { useEffect, useState } from "react";
import { Backdrop, Modal, Slide, makeStyles } from "@material-ui/core";
import styles from "./MonthDetailsModal.module.scss";
import { useTranslation } from "react-i18next";
import { api } from "../../../_helpers/api";
import { authHeader, befectiveFormatDate, getDayWeek, getMonths, getMoodById } from "../../../_helpers";
import IconClose from "../../_icons/IconClose";
import moment from "moment";
import { CaretLeft, CaretRight } from "phosphor-react";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: "none",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function SendMessageModal({ openModal, closeModal, currentUser, alertActions, updateDataPage = () => null, closeDrawerAlerts = () => null }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation("common");
  const [loading, setLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [form, setForm] = useState({
    message: ""
  })

  const [formErrors, setFormErrors] = useState({
    message: ""
  })

  const getLastSevenMoodUser = async ({ userId }) => {
    try {
      setLoading(true);

      const response = await api.get(
        `/report/mood/last-seven-days/${userId}`,
        {
          headers: authHeader(),
        }
      );

      setModalData(response.data);

      setLoading(false);
    } catch (error) {
      //alertActions.error(error)
      console.log(error);
      setLoading(false);
    }
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (form.message === "") {
      setFormErrors({
        ...formErrors,
        message: t("validation.required"),
      });
      return
    }

    setIsSubmit(true);
    
    api
      .post(`/report/mood/alert-messages`, {...form, receiver_id: currentUser.user_id}, {
        headers: authHeader(),
      })
      .then((res) => {
        closeModal();
        closeDrawerAlerts();
        setForm({ message: ""});
        setFormErrors({ message: "" });
        setIsSubmit(false);
        alertActions.success(t("reports_mood.modal_send_message.success_send_message"));
        updateDataPage();
      })
      .catch((err) => {
        setIsSubmit(false);
        alertActions.error(t("performance_assessment.messages.error_send_message"));
      });
  }

  useEffect(() => {
    if (openModal) {
      getLastSevenMoodUser({userId: currentUser.user_id})
    }
  }, [openModal]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} ${styles.modal}`}
      open={openModal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: "#205B6F", opacity: 0.8 },
      }}
      style={{ outline: 0, border: "none" }}
    >
      <Slide
        direction="down"
        in={openModal}
        style={{ outline: 0 }}
        mountOnEnter
        unmountOnExit
      >
        <div className={styles.containerModal}>

          <button onClick={closeModal} className={styles.close}>
            <IconClose />
          </button>

          <header>
            <h4 className={styles.textLeft}>
              {`${t('reports_mood.modal_send_message.message_to')} ${currentUser?.label}`}
            </h4>
            <p>{`${String(currentUser?.label).split(' ')[0]} ${t('reports_mood.modal_send_message.subtitle_part')}`}</p>
          </header>

          <form
            onSubmit={handleSubmit}
          >
            <div className={styles.fieldBox}>
              <label>{`${t("reports_mood.modal_send_message.field_mood_initial")} ${befectiveFormatDate(moment().clone().subtract(7,"days"), i18n.language)} ${t("reports_mood.modal_send_message.to")} ${befectiveFormatDate(moment().clone(), i18n.language)}`}</label>
              <ul className={styles.listMoods}>
                {
                  loading
                    ? (
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={45}
                      />
                    )
                    : (
                      modalData.map((item, index) => {
                        const  {key, img} = getMoodById(parseInt(item.mood))
                        return <li key={`mood-item-${index}1`} className={styles.itemMoods}>
                                  <img title={t(`widgets.moods.${key}`)} src={img} alt={t(`widgets.moods.${key}`)} width={'16px'} height={'16px'}/>
                                  <span>{item.mood_date ? befectiveFormatDate(moment(item.mood_date), i18n.language) : '--/--/----'}</span>
                                </li>
                      })
                    )
                }
              </ul>
            </div>

            <div className={styles.fieldBox}>
              <label htmlFor="message">{t("reports_mood.modal_send_message.field_message_suport")}*</label>
              <textarea id="message" name="message" onChange={onChangeInput} rows={3} value={form.message} placeholder={t("reports_mood.modal_send_message.placeholder_message_suport")}></textarea>
              {formErrors.message && <span className={styles.error}>{formErrors.message}</span>}
            </div>  

            <footer>            
              <button className={styles.buttonCancel} type="button" onClick={closeModal}>
                {t("reports_mood.modal_send_message.btn_cancel")}
              </button>
              <button className={styles.buttonSave} disabled={isSubmit}>
                {t("reports_mood.modal_send_message.btn_send")}
              </button>
            </footer> 
          </form>
        </div>
      </Slide>
    </Modal>
  );
}
