import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { NavLink, Link } from 'react-router-dom';

import Toolbar from '../_components/_shared/Toolbar'
import CompanyInformation from '../_components/settings/company/CompanyInformation'
import CompanyInformationSkeleton from '../_components/settings/company/CompanyInformationSkeleton'

import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation, initReactI18next } from "react-i18next";

import { alertActions, teamsActions } from '../_actions';
import { settingsActions } from '../_actions';

import { VictoryPie, VictoryTheme } from 'victory';

import IconAvatar from '../_components/_icons/IconAvatar';
import { authHeader, calcLicensesAvailable } from '../_helpers';
import { api } from '../_helpers/api';
import { Box } from '@mui/material';
import Header from '../_components/switch_page/Header';
import CompanyActivitiesOff from '../_components/settings/company/CompanyActivitiesOff';
import GlobalSkeleton from '../_components/_shared/GlobalSkeleton';


function CompanyPageActivitiesOff(props) {
    const { t, i18n } = useTranslation('common');
    const {alertActions} = props
    const [outdoors, setOutdoors] = useState([]);
    const [loading, setLoading] = useState(true);

    const getOutdoors = async (isLoading=true) => {
        try {
            if (isLoading){
                setLoading(true)
            }
            const response = await api.get('/settings/outdoors', { headers: authHeader() });
            setOutdoors(response.data);
            setLoading(false)
        } catch (error) {
            console.log(error)            
            setLoading(false)
        }
    }

    useEffect(() => {
        document.title = 'Befective | ' + t('menu.settings') + ' | ' + t('menu.company') + ' | ' + t('settings.activities_off_computer');
        getOutdoors();
    },[]);


    return (
        <Box bgcolor="#F5F6F9" width="100%" display="flex" flexDirection="column">
            <Header toBackUrl={"/global-settings"} />
            <section className="mainContent">
                {/* <Toolbar /> */}

                <div className="mainFilterbar">
                    <NavLink exact to="/global-settings/company">
                        <button className={'settingsNav'}>{t('settings.information')}</button>
                    </NavLink>
                    <NavLink exact to="/global-settings/company/acivities-off-computer" activeClassName="active">
                        <button className={'settingsNav'} >{t('settings.activities_off_computer')}</button>
                    </NavLink>
                </div>

                {/* PAGE STARTS */}

                <div className="mainPage profile">
                    <div className="nocolumn">
                        {
                            loading
                                ? <GlobalSkeleton  totalRowsStats={0} totalTables={1} widthTable={'800px'} />
                                : <CompanyActivitiesOff outdoors={outdoors} getOutdoors={getOutdoors} alertActions={alertActions}/>
                        }
                    </div>
                </div>
                {/* PAGE ENDS */}
            </section>

        </Box>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        alertActions: bindActionCreators(alertActions, dispatch),
    }
}


function mapStateToProps(state) {

    return {
        userDetails: state.authentication.userDetails
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPageActivitiesOff)
