import React, { useState } from 'react'
import styles from './styles.module.scss'
import Avatar from 'react-avatar'
import { FileArrowUp, PencilSimple } from 'phosphor-react'
import { useTranslation } from 'react-i18next'
import ModalExportUserSettings from './ModalExportUserSettings'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { filterActions } from '../../../_actions'
import { connect } from 'react-redux'


function HeaderForm({
  formData,
  isEditing=false,
  toogleShowForm,
  titleCreate=null,
  isExistUserId=false,
  submitForm=() => {},
  isSubmitting=false,
  userId,
  filter,
  showExportData=true
}) {

  const {t, i18n} = useTranslation('common')
	const [openModalExportUserSettings, setOpenModalExportUserSettings] = useState(false);

	const handleOpenModalExportUserSettings = () => {
		setOpenModalExportUserSettings(true);
	};

	const handleCloseModalExportUserSettings = () => {
		setOpenModalExportUserSettings(false);
	};
  const getNameAvatar = (name) => {
    if(!name) return '--'

    const split_name = name.split(' ')

    if(split_name.length > 1) return split_name[0] +" "+ split_name[split_name.length - 1]

    return name
    
  }

  return (
    <>
      <header className={styles.headerForm}>
        {
          formData?.id
          ?
          <div className={styles.headerFormTitle}>
            <div className={styles.boxAvatar}>
                <Avatar
                    name={getNameAvatar(formData?.full_name)}
                    size={40}
                    round
                    color={'#24677E'}
                    style={{
                        fontSize: '18px'
                    }}
                />
            </div>
            <div className={`${styles.boxInfo} ${isEditing ? styles.edition : ''}`}>
                <h6>{formData?.full_name || "-----"} {isEditing && `(${t('settings.users.actions.edition')})`}</h6>
                {formData?.office && <span>{formData?.office}</span>}
            </div>
          </div>
          :        
          <div className={styles.headerFormTitle}>
            <div className={`${styles.boxInfo}`}>
              <h6>{titleCreate}</h6>
            </div>
          </div>

        }
          {
            isEditing
            ? 
            
            <div className={styles.headerFormActions}>
              {
                showExportData &&
                <button
                  className={styles.buttonIcon}
                  onClick={handleOpenModalExportUserSettings}
                  style={{marginRight:'12px'}}                  
                  // disabled={!isExistUserId}
                >
                  <FileArrowUp size={14} weight="fill" color='#98ACC1' />
                </button>
              }
              <button
                  className={styles.buttonCancel}
                  onClick={toogleShowForm}
                  disabled={isSubmitting}
                >
                  {t('settings.users.actions.cancel')}
              </button>
              <button
                  className={styles.buttonSave}
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  {t('settings.users.actions.save')}
              </button>
            </div>
            : 
            <div className={styles.headerFormActions}>
              <button
                  className={styles.buttonIcon}
                  onClick={toogleShowForm}
                  // disabled={!isExistUserId}
                >
                  <PencilSimple size={14} weight="fill" color='#98ACC1' />
              </button>
              {
                showExportData &&
                <button
                    className={styles.buttonIcon}
                    disabled={!isExistUserId}
                    onClick={handleOpenModalExportUserSettings}
                  >
                    <FileArrowUp size={14} weight="fill" color='#98ACC1' />
                </button>
              }
            </div>
          }
      </header>
      <ModalExportUserSettings
          openModalExport={openModalExportUserSettings}
          handleCloseModalExport={handleCloseModalExportUserSettings}
          viewExport={`configUserFull`}
          filter={{
            ...filter,
            machine_users_id: userId
          }}
        />
    </>
  )
}

function mapDispatchToProps(dispatch) {

	return {
		filterActions: bindActionCreators(filterActions, dispatch)
	}
}


function mapStateToProps(state) {

	return {
		filter: state.filter
	}

}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderForm)
