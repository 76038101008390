// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A49u5Ujd94dKibguJUWdxA\\=\\= {\n  border-radius: 2px;\n  background-color: #ffffff;\n  margin-bottom: 16px;\n}\n\n.eODvoS\\+xLjIQYzPaW8uFXA\\=\\= {\n  padding: 24px 24px 0px;\n}\n.eODvoS\\+xLjIQYzPaW8uFXA\\=\\= h3 {\n  font-size: 18px;\n  font-weight: 500;\n  color: #1E6B7F;\n  margin: 0px;\n}\n\n.qDXAKMKRvpoS5Cu8LxQbUg\\=\\= {\n  overflow-x: auto;\n  overflow-y: hidden;\n}", "",{"version":3,"sources":["webpack://./src/_components/reports/value_hour/ChartBarDay.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,sBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,WAAA;AACJ;;AAEA;EACE,gBAAA;EACA,kBAAA;AACF","sourcesContent":[".cardContainer{\n  border-radius: 2px;\n  background-color: #ffffff;\n  margin-bottom: 16px;\n}\n\n.header{\n  padding: 24px 24px 0px;\n\n  h3{\n    font-size: 18px;\n    font-weight: 500;\n    color: #1E6B7F;\n    margin: 0px;\n  }\n}\n.chart{\n  overflow-x: auto;\n  overflow-y: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": "A49u5Ujd94dKibguJUWdxA==",
	"header": "eODvoS+xLjIQYzPaW8uFXA==",
	"chart": "qDXAKMKRvpoS5Cu8LxQbUg=="
};
export default ___CSS_LOADER_EXPORT___;
