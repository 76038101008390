import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'

import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import Inactivity from '../_components/reports/inactivity/Inactivity'
import InactivitySkeleton from '../_components/reports/inactivity/InactivitySkeleton'
import Moment from 'moment';

import {useTranslation} from "react-i18next";


import {statsActions, shareActions} from '../_actions';
import ShareModal from '../_components/_shared/ShareModal';
import { api } from '../_helpers/api';
import { authHeader } from '../_helpers';


function ReportsInactivityPage(props) {
    const {t, i18n} = useTranslation('common');

    const {statsActions, shareActions, filter} = props;
    const [count, setCount] = useState(0);
    const [pageCountRows, setPageCountRows] = useState(0);
    const [fetching, setFetching] = useState(true);
    const [data, setData] = useState(null);

    function closeShareModal() {
		shareActions.closeModal()
	}

    const getInactivityData = async ({ pageIndex = 0, pageSize = localStorage.getItem('pageSizeStorage') || 20, isLoading = false, teams = null, teammates = null}) => {
        try {
          if (isLoading) {
            setFetching(true)
          }
          const queryTeams = (teams && teams.length > 0) ? `&teams=${teams.map(t => t.id).join(',')}` : ''
          const queryTeammates = (teammates && teammates.length > 0) ? `&users=${teammates.map(t => t.id).join(',')}` : ''
          const res = await api.get(`/stats/inactivity?page=${pageIndex}&per_page=${pageSize}${queryTeams}${queryTeammates}`, { headers: authHeader() })
    
          if (res.status !== 200) {
            throw new Error('Erro get inactivity data')
          }
          setData(res.data)
          setCount(Math.ceil(parseInt(res.data.CountUsersInactive) / pageSize))
          setPageCountRows(parseInt(res.data.CountUsersInactive))
        } catch (error) {
          console.error(error)
        } finally {
          setFetching(false)
        }
    }

    useEffect(() => {
        let teams = null;
        let teammates = null;
    
        if (filter.teams) {
          teams = filter.teams;
        }
        if (filter.teammates) {
          teammates = filter.teammates;
        }
    
        getInactivityData({ pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20, isLoading: false, teams: teams, teammates: teammates})
    
      }, [filter.teams, filter.teammates]);

      
    useEffect(() => {
        document.title = 'Befective | ' + t('menu.reports') + ' | ' + t('menu.inactivity');
    });


    return (
        <React.Fragment>
            <section className="mainContent">
                <Toolbar/>
                <Filterbar 
                    filterWhen='disabled' 
                    filterWho='disabled' />

                {/* PAGE STARTS */}
                <div className="mainPage">
                    <div className="column single">
                        {
                            fetching === true
                                ? <InactivitySkeleton/>
                                : <Inactivity 
                                    data={data} 
                                    statsActions={statsActions}
                                    count={count}
                                    pageCountRows={pageCountRows}
                                    getInactivityData={getInactivityData}
                                    />
                        }
                    </div>


                </div>
                {/* PAGE ENDS */}
            </section>

            <div 
				className={'overlay' + (props.share.modal ? ' open' : '')} 
				onClick={() => {
					closeShareModal()
				}}
			></div>

			<section 
				className={'modal' + (props.share.modal ? ' open' : '')}
			>
				<ShareModal reports='inactivity' filters={filter} />
			</section>
        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        statsActions: bindActionCreators(statsActions, dispatch),
		shareActions: bindActionCreators(shareActions, dispatch)

    }
}


function mapStateToProps(state) {

    return {
        loading: state.stats.loading,
		share: state.share,
		filter: state.filter
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsInactivityPage)
