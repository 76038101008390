import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Typography, Box } from '@material-ui/core';
import { Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

function CustomPaginationMUI({ page, handlePageChange, rowsPerPage, totalRecords, handleRowsPerPageChange }) {
  
  const { t, i18n } = useTranslation('common');

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop={'8px'}>
      <Box display="flex" alignItems="center" marginRight={'6px'}>
        <Typography variant="body2" style={{ marginRight: '8px' }}>{t('pagination.item_per_page')}: </Typography>
        <Select
          value={rowsPerPage}
          onChange={handleRowsPerPageChange}
          variant="outlined"
          margin="dense"
        >
          {[10, 25, 50, 100, 200].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Pagination
        count={Math.ceil(totalRecords / rowsPerPage)} // Total de páginas (ajuste conforme necessário)
        page={page}
        onChange={handlePageChange}
        color="primary"
        shape="rounded"
      />
    </Box>
  );
}

export default CustomPaginationMUI;
