import React, { useState } from 'react';
import './Pagination.scss'

const Pagination = ({
  totalRecords,
  recordsPerPage,
  currentPage,
  onPageChange,
  onRecordsPerPageChange,
}) => {
  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const pageLimit = 5;

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const handleRecordsPerPageChange = (event) => {
    onRecordsPerPageChange(Number(event.target.value));
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, currentPage - Math.floor(pageLimit / 2));
    let endPage = Math.min(totalPages, startPage + pageLimit - 1);

    if (endPage - startPage < pageLimit - 1) {
      startPage = Math.max(1, endPage - pageLimit + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${i === currentPage ? 'active' : ''}`}
          onClick={() => handlePageChange(i)}
        >
          <span className="page-link">{i}</span>
        </li>
      );
    }

    if (startPage > 1) {
      pageNumbers.unshift(
        <li key="ellipsis-start" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
      pageNumbers.unshift(
        <li key={1} className="page-item" onClick={() => handlePageChange(1)}>
          <span className="page-link">1</span>
        </li>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <li key="ellipsis-end" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
      pageNumbers.push(
        <li key={totalPages} className="page-item" onClick={() => handlePageChange(totalPages)}>
          <span className="page-link">{totalPages}</span>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="pagination-container">
      <div className="records-info">
        <span>
          Showing {Math.min((currentPage - 1) * recordsPerPage + 1, totalRecords)}-
          {Math.min(currentPage * recordsPerPage, totalRecords)} of {totalRecords}
        </span>
      </div>
      <nav>
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <span className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
              Previous
            </span>
          </li>
          {renderPageNumbers()}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <span className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
              Next
            </span>
          </li>
        </ul>
      </nav>
      <div className="records-per-page">
        <label htmlFor="recordsPerPage">Records per page:</label>
        <select id="recordsPerPage" value={recordsPerPage} onChange={handleRecordsPerPageChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </select>
      </div>
    </div>
  );
};

export default Pagination;