import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { authHeader, getRangeFilterWhen, secondsToHm, secondsToHours } from '../../../_helpers';
import { api } from '../../../_helpers/api';
import { CalendarCheck, CalendarX, Clock, Sun, Timer } from 'phosphor-react';
import Widget36Skeleton from './Widget36Skeleton';
import style from './styles.module.scss';


export default function Widget36({
  alertActions,
  currentDate,
  width,
  filters,
  userDetails
}) {
  const { t, i18n } = useTranslation('common');
  const [data, setData] = useState({
    avg_working_day: null,
    avg_start_time: null,
    best_day_week: null,
    worst_day_week: null,
    best_productive_period: null,
    worst_productive_period: null
  });
  const [fetching, setFetching] = useState(true);

  const lang = userDetails.language || i18n.language

  const getData = async ({ filterStartDate, filterEndDate, filterTeams, filterTeammates}) => {
    try {
      setFetching(true)
      const response = await api.get(`/stats/executive/five-stats?current_day=${currentDate.format('YYYY-MM-DD')}${filterStartDate}${filterEndDate}${filterTeams}${filterTeammates}`, { headers: authHeader() })
      if (response.status !== 200) {
        throw new Error('Erro get data Widget36!!!')
      }
      const { data } = response;
      setData(data)
      setFetching(false)
    } catch (error) {
      console.log(error)
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  useEffect(() => {
    getData({...filters})
  }, [])

  return (

    <Grid item xs={width} position={'relative'}>
      {
        !fetching
          ?
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Card
                variant="outlined"
                className={style.cardContainer}
              >
                <CardContent className={`${style.cardHeader} ${style.borderLeftGreen}`}>
                  <Timer size={18} color="#22BEAD" weight="bold" />
                  <h6 className={`${style.titleCard} ${style.colorGreen}`}>
                    {secondsToHm(data?.avg_working_day)}
                  </h6>
                  <span className={style.subtitleCard}>
                    {t('widgets.widget36.avg_daily_journey')}
                  </span>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={2}>
              <Card
                variant="outlined"
                className={style.cardContainer}
              >
                <CardContent className={`${style.cardHeader} ${style.borderLeftRed}`}>
                  <Sun size={18} color="#FC6662" weight="bold" />
                  <h6 className={`${style.titleCard} ${style.colorRed}`}>
                    {data?.avg_start_time || '--:--'}
                  </h6>
                  <span className={style.subtitleCard}>
                    {t('widgets.widget36.avg_start_time')}
                  </span>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={2}>
              <Card
                variant="outlined"
                className={style.cardContainer}
              >
                <CardContent className={`${style.cardHeader} ${style.borderLeftGreen}`}>
                  <CalendarCheck size={18} color="#22BEAD" weight="bold" />
                  <h6 className={`${style.titleCard} ${style.colorGreen}`}>
                    {(data.best_day_week && Object.keys(data.best_day_week).length > 0) ? data.best_day_week.translations[lang] : '-----'}
                  </h6>
                  <span className={style.subtitleCard}>
                    {t('widgets.widget36.more_productive_day')}
                  </span>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={2}>
              <Card
                variant="outlined"
                className={style.cardContainer}
              >
                <CardContent className={`${style.cardHeader} ${style.borderLeftRed}`}>
                  <CalendarX size={18} color="#FC6662" weight="bold" />
                  <h6 className={`${style.titleCard} ${style.colorRed}`}>
                    {(data.worst_day_week && Object.keys(data.worst_day_week).length > 0) ? data.worst_day_week.translations[lang] : '-----'}
                  </h6>
                  <span className={style.subtitleCard}>
                    {t('widgets.widget36.less_productive_day')}
                  </span>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={2}>
              <Card
                variant="outlined"
                className={style.cardContainer}
              >
                <CardContent className={`${style.cardHeader} ${style.borderLeftGreen}`}>
                  <Clock size={18} color="#22BEAD" weight="bold" />
                  <h6 className={`${style.titleCard} ${style.colorGreen}`}>
                    {(data.best_productive_period && Object.keys(data.best_productive_period).length > 0 && Object.keys(data.best_productive_period.data).length > 0) ? data.best_productive_period.data.translations[lang] : '-----'}
                  </h6>
                  <span className={style.subtitleCard}>
                    {t('widgets.widget36.more_produtive_period')}
                  </span>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={2}>
              <Card
                variant="outlined"
                className={style.cardContainer}
              >
                <CardContent className={`${style.cardHeader} ${style.borderLeftRed}`}>
                  <Clock size={18} color="#FC6662" weight="bold" />
                  <h6 className={`${style.titleCard} ${style.colorRed}`}>
                    {(data.worst_productive_period && Object.keys(data.worst_productive_period).length > 0 && Object.keys(data.worst_productive_period.data).length > 0) ? data.worst_productive_period.data.translations[lang] : '-----'}
                  </h6>
                  <span className={style.subtitleCard}>
                    {t('widgets.widget36.less_produtive_period')}
                  </span>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          :
          <Widget36Skeleton />
      }
    </Grid>
  )
}
