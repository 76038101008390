// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._77IFzP506fizu9XPT4sfLQ\\=\\= {\n  padding: 10px 22px;\n  max-width: 365px;\n}\n._77IFzP506fizu9XPT4sfLQ\\=\\= h5 {\n  font-size: 16px;\n  color: #187DFF;\n  margin: 26px 0px 8px;\n}\n._77IFzP506fizu9XPT4sfLQ\\=\\= h5:first-child {\n  margin-bottom: 16px;\n}\n._77IFzP506fizu9XPT4sfLQ\\=\\= h6 {\n  font-weight: 500;\n  font-size: 14px;\n  color: #1E6B7F;\n  margin: 20px 0px 0px;\n}\n._77IFzP506fizu9XPT4sfLQ\\=\\= p {\n  font-size: 14px;\n  color: #1E6B7F;\n  margin: 6px 0px 0px;\n  line-height: 20px;\n}\n\n._8BJQH4XJIeB8sDzE9l4M2w\\=\\= {\n  position: absolute;\n  right: 10px;\n  top: 10px;\n}", "",{"version":3,"sources":["webpack://./src/_components/inactivity_settings/InfoHelpDrawer.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;AACF;AACE;EACE,eAAA;EACA,cAAA;EACA,oBAAA;AACJ;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,eAAA;EACA,cAAA;EACA,oBAAA;AADJ;AAIE;EACE,eAAA;EACA,cAAA;EACA,mBAAA;EACA,iBAAA;AAFJ;;AAKA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;AAFF","sourcesContent":[".mainDrawer{\n  padding: 10px 22px;\n  max-width: 365px;\n\n  h5{\n    font-size: 16px;\n    color: #187DFF;\n    margin: 26px 0px 8px;\n  }\n\n  h5:first-child{\n    margin-bottom: 16px;\n  }\n\n  h6{\n    font-weight: 500;\n    font-size: 14px;\n    color: #1E6B7F;\n    margin: 20px 0px 0px;\n  }\n\n  p{\n    font-size: 14px;\n    color: #1E6B7F;\n    margin: 6px 0px 0px;\n    line-height: 20px;\n  }\n}\n.boxIconClose{\n  position: absolute;\n  right: 10px;\n  top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainDrawer": "_77IFzP506fizu9XPT4sfLQ==",
	"boxIconClose": "_8BJQH4XJIeB8sDzE9l4M2w=="
};
export default ___CSS_LOADER_EXPORT___;
