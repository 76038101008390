import React, { useEffect, useState } from 'react'
import style from './styles.module.scss'
import { Autocomplete } from '@material-ui/lab'
import { Chip, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControlLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles'
import { Delete, Visibility } from '@material-ui/icons';
import { api } from '../../_helpers/api';
import { authHeader } from '../../_helpers';
import MoodVeryBad from '../../../public/images/mood1_very_bad.png'
import MoodBad from '../../../public/images/mood2_bad.png'
import MoodOk from '../../../public/images/mood3_ok.png'
import MoodGood from '../../../public/images/mood4_good.png'
import MoodVeryGood from '../../../public/images/mood5_very_good.png'
import { ModalPreviewMoodGUI } from './ModalPreviewMoodGUI';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  option: {
    fontSize: 15,
    '& > img': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#22BEAD' : '#22BEAD',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#22BEAD',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default function MoodsSettings({
  dataMoods,
  getData,
  alertActions
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('common');
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [openModalPreview, setOpenModalPreview] = useState(false)

  const [form, setForm] = useState({
    show_popup_work_day: false,
    question: '',
    description: '',
    standard_answer: false,
    active_alert_case_mood_negative: false,
    alert_mood_response_type: 'very_bad',
    alert_mood_response_amount: 'three_answer_negative_week',
    alert_mood_response_send_report_to: 'only_responsible'
  })

  const [formError, setFormError] = useState({
    show_popup_work_day: '',
    question: '',
    description: '',
    standard_answer: '',
    alert_mood_response_type: '',
    alert_mood_response_amount: '',
    alert_mood_response_send_report_to: ''
  })

  const moodsOptionsStatic = [
    {
      id: 1,
      key: 'very_bad',
      label: t('moods_settings.moods.very_bad'),
      img: <img src={MoodVeryBad}  loading='lazy' alt='Mood Very Bad' />
    },
    {
      id: 2,
      key: 'bad',
      label: t('moods_settings.moods.bad'),
      img: <img src={MoodBad}  loading='lazy' alt='Mood Bad' />
    },
    {
      id: 3,
      key: 'ok',
      label: t('moods_settings.moods.ok'),
      img: <img src={MoodOk}  loading='lazy' alt='Mood Ok' />
    },
    {
      id: 4,
      key: 'good',
      label: t('moods_settings.moods.good'),
      img: <img src={MoodGood}  loading='lazy' alt='Mood Good' />
    },
    {
      id: 5,
      key: 'very_good',
      label: t('moods_settings.moods.very_good'),
      img: <img src={MoodVeryGood}  loading='lazy' alt='Mood Very Good' />
    }

  ]

  const handleChange = (e) => {
    const { value, name, type, checked } = e.target

    if (type === "checkbox") {
      setForm({
        ...form,
        [name]: checked
      })
      setFormError({
        ...formError,
        [name]: ''
      })
      return;
    }

    setForm({
      ...form,
      [name]: value
    })
    setFormError({
      ...formError,
      [name]: ''
    })
  }


  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      if(!form.question){
        setFormError({
          ...formError,
          question: t('validation.required')
        })
        return
      }
      setIsSubmitting(true)
      const res = await api.post('/settings/mood/global/save', form, {headers: authHeader()})
      await getData(false)
      alertActions.success(t("moods_settings.success_save"))
      setIsSubmitting(false)
    } catch (error) {
      setIsSubmitting(false)
      alertActions.error(t("moods_settings.error_save"))
    }
  }

  const handleCloseModalPreview = () => {
    setOpenModalPreview(false)
  }

  const handleOpenModalPreview = () => {
    setOpenModalPreview(true)
  }

  useEffect(() => {
    if(dataMoods !== null){
      setForm({
        show_popup_work_day: dataMoods.show_popup_work_day,
        question: dataMoods.question,
        description: dataMoods.description,
        standard_answer: dataMoods.standard_answer,
        active_alert_case_mood_negative: dataMoods.active_alert_case_mood_negative,
        alert_mood_response_type: dataMoods.alert_mood_response_type,
        alert_mood_response_amount: dataMoods.alert_mood_response_amount,
        alert_mood_response_send_report_to: dataMoods.alert_mood_response_send_report_to
      })
    }
  }, [dataMoods])


  return (
    <>
      <Box display="flex" flexDirection="column" component="form" onSubmit={handleSubmit}>
        <Box display="flex" bgcolor="#fff" flexDirection="column" paddingX="14px" borderRadius="4px" marginBottom="2px">
          <Box display="flex" alignItems="center" justifyContent="space-between" paddingX="12px" paddingY="16px" minHeight="45px" borderBottom="2px solid #F5F6F9">
            <Box display="flex" alignItems="center" gap="8px">
              <h2 className={style.cardTitle}>
                  {t('moods_settings.title')}
              </h2>
            </Box>

            <Box display="flex" alignItems="center" gap="8px">
              <button 
                type='button'
                className={style.buttonPreview}
                onClick={handleOpenModalPreview}
                >              
                <Visibility />
              </button>

              <button
                className={style.buttonAdd}
                type="submit"
                disabled={isSubmitting}
              >
                {
                  !isSubmitting
                  ?
                  t('moods_settings.save')
                  :
                  <CircularProgress size={'21px'} color='inherit'/>
                }
              </button>
            </Box>
          </Box>
          <Grid container className={style.containerField}>
            <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} gap={'2px'}>
              <h4 className={style.fieldTitle}>
                {t('moods_settings.form.show_popup_work_day_title')}
              </h4>
              <p className={style.fieldSubtitle}>
                {t('moods_settings.form.show_popup_work_day_subtitle')}
              </p>
            </Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <FormControlLabel
                sx={{ margin: 0 }}
                control={<IOSSwitch sx={{ m: 0 }} checked={form.show_popup_work_day} onChange={handleChange} name="show_popup_work_day" />}
              />
            </Stack>
          </Grid>

          <Grid container className={style.containerField}>
            <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} gap={'2px'} width={'100%'}> 
              <h4 className={style.fieldTitle}>
                {t('moods_settings.form.question_title')}
              </h4>
              <p className={style.fieldSubtitle}>
                {t('moods_settings.form.question_subtitle')}
              </p>
              <Box className={style.containerFormGroup}>
                <Box className={style.formGroup} width={'35%'}>
                  <span className={style.fieldSubtitle}>
                    {t('moods_settings.form.question')}*
                  </span>
                  <TextField
                    name="question"
                    error={false}
                    helperText={""}
                    sx={{ height: '48px', fieldset: { border: '1px solid #D6DEEB', height: '50px' }, div: { color: '#4F7091', height: '48px' }, label: { top: '-4px' } }}
                    value={form.question}
                    onChange={handleChange}
                    placeholder={t('moods_settings.form.question_placeholder')}
                  >
                  </TextField>
                  {
                    formError.question &&
                    <span className={style.fieldError}>
                      {formError.question}
                    </span>
                  }
                </Box>
                <Box className={style.formGroup} width={'65%'}>
                  <span className={style.fieldSubtitle}>
                    {t('moods_settings.form.description')}
                  </span>
                  <TextField
                    name="description"
                    error={false}
                    helperText={""}
                    sx={{ height: '48px', fieldset: { border: '1px solid #D6DEEB', height: '50px' }, div: { color: '#4F7091', height: '48px' }, label: { top: '-4px' } }}
                    value={form.description}
                    onChange={handleChange}
                    placeholder={t('moods_settings.form.description_placeholder')}
                  >
                  </TextField>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid container className={style.containerField}>
            <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} gap={'2px'} width={'100%'}> 
              <h4 className={style.fieldTitle}>
                {t('moods_settings.form.mood_scale_title')}
              </h4>
              <p className={style.fieldSubtitle}>
                {t('moods_settings.form.mood_scale_subtitle')}
              </p>
              <Box className={`${style.containerFormGroup} ${style.noGap}`}>
                {
                  moodsOptionsStatic.map((item, index) => (
                    <div key={`mood-option-${index}`} className={`${style.moodItem} ${style[item.key]}`}>
                      {item.img}
                      <span>{`${item.id}. ${item.label}`}</span>
                    </div>
                  ))
                }
              </Box>
            </Box>
          </Grid>

          <Grid container className={style.containerField}>
            <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} gap={'2px'}>
              <h4 className={style.fieldTitle}>
                {t('moods_settings.form.standard_answer_title')}
              </h4>
              <p className={style.fieldSubtitle}>
                {t('moods_settings.form.standard_answer_subtitle')}
              </p>
            </Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <FormControlLabel
                sx={{ margin: 0 }}
                control={<IOSSwitch sx={{ m: 0 }} checked={form.standard_answer} onChange={handleChange} name="standard_answer" />}
              />
            </Stack>
          </Grid>

          <Grid container className={`${style.containerField} ${style.borderBottomNone} ${style.paddingBottomNone}`}>
            <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} gap={'2px'}> 
              <h4 className={style.fieldTitle}>
                {t('moods_settings.form.alert_case_mood_negative_title')}
              </h4>
              <p className={style.fieldSubtitle}>
                {t('moods_settings.form.alert_case_mood_negative_subtitle')}
              </p>
            </Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <FormControlLabel
                sx={{ margin: 0 }}
                control={<IOSSwitch sx={{ m: 0 }} checked={form.active_alert_case_mood_negative} onChange={handleChange} name="active_alert_case_mood_negative" />}
              />
            </Stack>
          </Grid>        
          <Grid container className={`${style.containerField} ${style.borderBottomNone} ${style.paddingTopNone}`} >
            <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'} gap={'2px'} width={'100%'}> 
              <Box className={style.containerFormGroup}>
                <Box className={style.formGroup} width={'20%'}>
                  <span className={style.fieldSubtitle}>
                    {t('moods_settings.form.alert_mood_response_type')}
                  </span>
                  <Select
                    className={style.selectForm}
                    id="alert_mood_response_type"
                    name="alert_mood_response_type"
                    fullWidth
                    value={form?.alert_mood_response_type}
                    onChange={handleChange}
                    MenuProps={{
                      classes: {
                        paper: style.selectFormMenu
                      }
                    }}
                    disabled={form.active_alert_case_mood_negative === false}
                  >
                    <MenuItem value="very_bad">{t('moods_settings.moods.very_bad')}</MenuItem>
                    <MenuItem value="bad">{t('moods_settings.moods.bad')}</MenuItem>
                    <MenuItem value="very_bad_and_bad">{t('moods_settings.moods.very_bad_and_bad')}</MenuItem>
                  </Select>
                </Box>
                <Box className={style.formGroup} width={'40%'}>
                  <span className={style.fieldSubtitle}>
                    {t('moods_settings.form.alert_mood_response_amount')}
                  </span>
                  <Select
                    className={style.selectForm}
                    id="alert_mood_response_amount"
                    name="alert_mood_response_amount"
                    fullWidth
                    value={form?.alert_mood_response_amount}
                    onChange={handleChange}
                    MenuProps={{
                      classes: {
                        paper: style.selectFormMenu
                      }
                    }}                    
                    disabled={form.active_alert_case_mood_negative === false}
                  >
                    <MenuItem value="three_answer_negative_week">{t('moods_settings.options.three_answer_negative_week')}</MenuItem>
                    <MenuItem value="five_answer_negative_week">{t('moods_settings.options.five_answer_negative_week')}</MenuItem>
                    <MenuItem value="teen_answer_negative_month">{t('moods_settings.options.teen_answer_negative_month')}</MenuItem>
                    <MenuItem value="fifteen_answer_negative_month">{t('moods_settings.options.fifteen_answer_negative_month')}</MenuItem>
                  </Select>
                </Box>
                <Box className={style.formGroup} width={'40%'}>
                  <span className={style.fieldSubtitle}>
                    {t('moods_settings.form.alert_mood_response_send_report_to')}
                  </span>
                  <Select
                    className={style.selectForm}
                    id="alert_mood_response_send_report_to"
                    name="alert_mood_response_send_report_to"
                    fullWidth
                    value={form?.alert_mood_response_send_report_to}
                    onChange={handleChange}
                    MenuProps={{
                      classes: {
                        paper: style.selectFormMenu
                      }
                    }}
                    disabled={form.active_alert_case_mood_negative === false}
                  >
                    <MenuItem value="only_responsible">{t('moods_settings.options.only_responsible')}</MenuItem>
                    <MenuItem value="only_admin">{t('moods_settings.options.only_admin')}</MenuItem>
                    <MenuItem value="responsible_and_admin">{t('moods_settings.options.responsible_and_admin')}</MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>
      <ModalPreviewMoodGUI
        openModalPreviewMoodGUI={openModalPreview}
        handleCloseModalPreviewMoodGUI={handleCloseModalPreview}
        data={{
          question: form?.question || t('moods_settings.modal_preview.question'),
          description: form?.description  || t('moods_settings.modal_preview.description'),
          moods_options: moodsOptionsStatic
        }}
      />
    </>
  )
}
