import React from "react";
import styles from "./styles.module.scss";
import HeaderForm from "../personal/HeaderForm";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";

export default function FormAnnotationsPreviewPT({
  formData,
  toogleShowForm,
  isEditing,
  isExistUserId,
  userId
}) {
  const { t, i18n } = useTranslation("common");

  return (
    <div className={styles.containerPreview}>
      <div className={styles.card}>
        <HeaderForm
          formData={formData}
          toogleShowForm={toogleShowForm}
          isEditing={isEditing}
          titleCreate={t("settings.users.annotations_form.title_create")}
          isExistUserId={isExistUserId}
          userId={userId}
          showExportData={false}
        />
        <div className={styles.card}>
          <header className={styles.cardHeader}>
            <h6>{t("settings.users.annotations_form.annotations")}</h6>
          </header>
          <div
            className={styles.cardContent}
            style={{
              padding: "0px 20px 20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {formData?.text_body ? (
              <textarea className={styles.textareaPreview} readOnly rows={16} value={formData?.text_body}></textarea>
            ) : (
              <p className={styles.annotations}>
                {t(
                  "settings.users.annotations_form.none_annotations_available"
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
