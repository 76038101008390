// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vCCRfdLCLO6u\\+l8UvR8A8w\\=\\= {\n  padding: 16px;\n}\n\n.be66g67hMyRVgPzV7YcmDw\\=\\= {\n  color: #22BEAD;\n  font-size: 12px;\n}\n\n.gRCA8uMcnSnb2iHRJ1YotA\\=\\= {\n  color: #FDBE2B;\n  font-size: 12px;\n}\n\n._2VxcNTqhIW9xUkPJMI9w\\+A\\=\\= {\n  color: #FC6662;\n  font-size: 12px;\n}", "",{"version":3,"sources":["webpack://./src/pages/ProjectManagementReportsBoardsPage/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;AACF;;AACA;EACE,cAAA;EACA,eAAA;AAEF;;AAAA;EACE,cAAA;EACA,eAAA;AAGF","sourcesContent":[".containerPage {\n  padding: 16px;\n}\n\n.done{\n  color: #22BEAD;\n  font-size: 12px;\n}\n.pending{\n  color: #FDBE2B;\n  font-size: 12px;\n}\n.overdue{\n  color: #FC6662;\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerPage": "vCCRfdLCLO6u+l8UvR8A8w==",
	"done": "be66g67hMyRVgPzV7YcmDw==",
	"pending": "gRCA8uMcnSnb2iHRJ1YotA==",
	"overdue": "_2VxcNTqhIW9xUkPJMI9w+A=="
};
export default ___CSS_LOADER_EXPORT___;
