import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, teammatesActions, usersActions } from '../../_actions';
import Toolbar from '../../_components/_shared/Toolbar';
import style from './styles.module.scss';
import { api } from '../../_helpers/api';
import { authHeader, getGenders } from '../../_helpers';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import UsersTabComponent from '../../_components/users_settings/UsersTabComponent';
import FormPersonalCreatePT from '../../_components/users_settings/personal/FormPersonalCreatePT';
import FormPersonalPreviewPT from '../../_components/users_settings/personal/FormPersonalPreviewPT';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import Header from '../../_components/switch_page/Header';
import { Box } from '@mui/material';


function UsersSettingsPersonalPage(props) {
  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions } = props;
  const [loading, setLoading] = useState(true);
  const genders = getGenders();
  const [formData, setFormData] = useState({
    id: null,
    full_name: "",
    office: "",
    birth_date: "",
    birth_location: "",
    sex: genders.find(item => item.value === "M"),
    marital_status: null,
    document_1: "",
    document_2: "",
    blood_type:null,
    nationality: "",
    cep: "",
    street: "",
    address_number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: null,
    country: null,
    observation: "",
    telephone_number: "",
    phone_number: "",
    email: "",
    emergency_contact_full_name: "",
    emergency_contact_assignment: null,
    emergency_contact_phone_number: "",
    medica_conditions: "",
    allergies: [],
    medicines: []
  });
  const [showForm, setShowForm] = useState(localStorage.getItem('showForm') === 'true' ? true : false);
  const [optionsData, setOptionsData] = useState(null);
  const [fetchingOptionsData, setFetchingOptionsData] = useState(true);  
  const { id } = useParams();

  const lang = userDetails && userDetails.language ? userDetails.language : i18n.language;

  const getUserPersonalInfo = async (withLoading=true) => {
    try {
      if(withLoading){
        setLoading(true)
      }
      const { data } = await api.get(`/users/personal-information?machine_users_id=${id}`, { headers: authHeader() })
      if(data){
        const sex = data.sex ? genders.find(item => item.value === data.sex) : genders.find(item => item.value === "M")
        const objData = {
          ...data,
          sex: sex,
          emergency_contact_assignment: data.contact_assignment,
          emergency_contact_phone_number: data.emergency_phone_number
        }
        setFormData(objData)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const getOptionsData = async () => {
    try {
      setFetchingOptionsData(true)
      const {data} = await api.get(`/users/get-data-personal`, {
        headers: authHeader()
      })
      setOptionsData(data)
      setFetchingOptionsData(false)
    } catch (error) {
      console.log(error)  
      setFetchingOptionsData(false)
    }
  }

  
  const toogleShowForm = () => {
    if (!showForm) {
      localStorage.setItem('showForm', true)
    }else{
      localStorage.removeItem('showForm')
    }
    setShowForm(!showForm)
  }


  useEffect(() => {

    if (id) {
      getUserPersonalInfo()
    } else {
      setLoading(false)
    }

    getOptionsData()

    document.title = `${t('menu.users')} | ${t('settings.users.tabs.personal')}`

  }, [id])

  useEffect(() => {
    console.log("formData -> ", formData)
  }, [formData])

  return (
    <Box bgcolor="#F5F6F9" width="100%" display="flex" flexDirection="column">
      <Header toBackUrl={"/global-settings/users"} />
      <section className="mainContent productivity">

        {/* <Toolbar /> */}

        <UsersTabComponent currentActive="personal" userId={id} userDetails={userDetails}/>

        {/* PAGE STARTS */}
        <div className={style.containerPage}>
          {
            (!loading && !fetchingOptionsData) 
            ? 
              (
                showForm
                ? <FormPersonalCreatePT 
                    formData={formData} 
                    getDataForm={getUserPersonalInfo} 
                    toogleShowForm={toogleShowForm}
                    isEditing={showForm}
                    lang={lang}
                    optionsData={optionsData}
                    alertActions={alertActions}
                    userId={id}
                  />
                : <FormPersonalPreviewPT 
                    formData={formData} 
                    toogleShowForm={toogleShowForm}  
                    isEditing={showForm}
                    isExistUserId={true}
                    userId={id}
                  />
              )
            : 

            <GlobalSkeleton totalTables={1} totalRowsStats={0} padding={"0px"} marginTopTable={"0px"}/>
          }
        </div>
        {/* PAGE ENDS */}

      </section>
    </Box>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(UsersSettingsPersonalPage)