import React, { useEffect, useMemo, useState } from "react";
import Toolbar from "../../_components/_shared/Toolbar";
import Filterbar from "../../_components/_shared/Filterbar";
import { connect } from "react-redux";
import { alertActions } from "../../_actions";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { authHeader, befectiveFormatDate, getAvatarUrl, history } from "../../_helpers";
import GlobalSkeleton from "../../_components/_shared/GlobalSkeleton";
import EnhancedTable from "../../_components/_shared/EnhancedTable";
import moment from "moment";
import IconThreePoints from "../../_components/_icons/IconThreePoints";
import StatusAssessment from "../../_components/performance_assessments/PerformanceAssessmentsCreated/StatusAssessment";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { AvatarGroup } from "@material-ui/lab";
import { Avatar, Box, Popover, Tooltip } from "@material-ui/core";
import PopoverConfirm from "../../_components/teams_settings/PopoverConfirmDelete";
import { api } from "../../_helpers/api";
import CustomAvatarGroup from "../../_components/_shared/CustomAvatarGroup";
import NoData from "./NoData";

function PerformanceAssessmentsCreatedPage({
  alertActions,
  userDetails,
  filter
}) {
  const { t, i18n } = useTranslation("common");

  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);

  const [firstFetch, setFirstFetch] = useState(true);
  const [firstData, setFirstData] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [pageCountRows, setPageCountRows] = useState(0);
  const [dateRef, setDateRef] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [currentRow, setCurrentRow] = useState(null);
  const [disableActions, setDisableActions] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "actions-popover" : undefined;

  const handleClick = (event, row) => {
    setCurrentRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  
  const [anchorElConfirmDelete, setAnchorElConfirmDelete] = useState(null);
  const openConfirmDelete = Boolean(anchorElConfirmDelete);
  const idConfirmDelete = openConfirmDelete ? 'popover-confirm-delete-assessment' : undefined;

  const handleClickConfirmDelete = (event) => {
    setAnchorElConfirmDelete(event.currentTarget);
  };

  const handleCloseConfirmDelete = () => {
    setAnchorElConfirmDelete(null)
  };

  const handleConfirmDeleteAssessment = async () => {
    try {
      if (!currentRow.id) {
        throw new Error('Assessment to delete not found')
      }
      const res = await api.delete(`/performance-assessment/${currentRow.id}`, { headers: authHeader() })
      if (res.status !== 200) {
        throw new Error('Erro to delete assessment')
      }
      getData(false)
      handleCloseConfirmDelete()
      handleClose()
      alertActions.success(t('performance_assessment.messages.success_delete_assessment'))
    } catch (error) {
      alertActions.error(error.response.data)
      console.error(error)
    }
  }

  const [anchorElConfirmClone, setAnchorElConfirmClone] = useState(null);
  const openConfirmClone = Boolean(anchorElConfirmClone);
  const idConfirmClone = openConfirmClone ? 'popover-confirm-clone-assessment' : undefined;

  const handleClickConfirmClone = (event) => {
    setAnchorElConfirmClone(event.currentTarget);
  };

  const handleCloseConfirmClone = () => {
    setAnchorElConfirmClone(null)
  };

  const handleConfirmCloneAssessment = async () => {
    try {
      if (!currentRow.id) {
        throw new Error('Assessment off to clone not found')
      }

      const res = await api.post(`/performance-assessment/clone`, {performance_assessment_id: currentRow.id}, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro to clone assessment')
      }
      getData(false)
      handleCloseConfirmClone()
      handleClose()
      alertActions.success(t('performance_assessment.messages.success_clone_assessment'))
    } catch (error) {      
      alertActions.error(error.response.data)
      console.error(error)
    }
  }


  async function getData(
    date_ref,
    pageIndex,
    pageSize,
    isLoading,
    teams = null,
    teammates = null
  ) {

    try {

      if (isLoading) {
        setFetching(true)
      }

      const queryTeams = teams ? `&teams=${teams.map(t=>t.id).join(',')}` : ''
      const queryTeammates = teammates ? `&users=${teammates.map(t=>t.id).join(',')}` : ''
      const queryFilterYear = date_ref ? `&year=${moment(date_ref).year()}` : ''
      const response = await api.get(`/performance-assessment/?page=${pageIndex}&per_page=${pageSize}${queryFilterYear}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      if(firstFetch){
        setFirstData(response.data)
        setFirstFetch(false)
      }

      setData(response.data.processed)
      setFetching(false);
      
    } catch (error) {
      console.log(error);
      alertActions.error(t('generic_messages.errors.load'))
      setFetching(false);
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t(
          "performance_assessment.page_assessment_created.table.col_assessment"
        ),
        accessor: "title",
      },
      {
        Header: t(
          "performance_assessment.page_assessment_created.table.col_start"
        ),
        accessor: "date_start",
        Cell: ({ value }) => {
          return befectiveFormatDate(value, i18n.language);
        },
      },
      {
        Header: t(
          "performance_assessment.page_assessment_created.table.col_completed_at"
        ),
        accessor: "date_end",
        Cell: ({ value }) => {
          return befectiveFormatDate(value, i18n.language);
        },
      },
      {
        Header: t(
          "performance_assessment.page_assessment_created.table.col_users_evaluated"
        ),
        accessor: "total_evaluated",
      },
      {
        Header: t(
          "performance_assessment.page_assessment_created.table.col_users_evaluator"
        ),
        accessor: "evaluators",
        Cell: ({ value }) => {
          return value && value.length > 0 ? (
            <CustomAvatarGroup avatars={value} max={4}/>
          ) : (
            "----"
          );
        },
      },
      {
        Header: t(
          "performance_assessment.page_assessment_created.table.col_progress"
        ),
        accessor: "progress_percent",
        Cell: ({ value, ...rest }) => {
          const currentRow = rest.row.original;
          return (
            <div className="boxCellCircularProgress">
              <div className={`containerCircularProgress`}>
                <CircularProgressbar
                  value={Number(value)}
                  strokeWidth={50}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: currentRow.status.text_color,
                    trailColor: "#E4EAEF",
                  })}
                />
              </div>
              <div>{currentRow.progress}</div>
            </div>
          );
        },
      },
      {
        Header: t(
          "performance_assessment.page_assessment_created.table.col_status"
        ),
        accessor: "status",
        style: { padding: "0px 12px" },
        Cell: ({ value }) => {
          return (
            <StatusAssessment
              title={value["title_" + i18n.language]}
              textColor={value.text_color}
              bgColor={value.bg_color}
            />
          );
        },
      },
    ];
  }, []);

  const getDataAssessments = ({pageIndex, pageSize, filter}) => {

    let date_ref = dateRef;

    if (filter.singleDate) {
      date_ref = filter.singleDate;
    }

    setDateRef(date_ref);

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getData(date_ref, pageIndex, pageSize, true, teams, teammates);
  };

  const handleRowClick = (row) => {
    return history.push(`/performance-assessment/details/participants/${row.id}`);
  };

  useEffect(() => {
    getData(
      dateRef,
      0,
      localStorage.getItem("pageSizeStorage") || 20,
      false,
      null,
      null
    );
    document.title = `Befective | ${t(
      "menu.performance_assessment.register"
    )} `;
  }, []);

  return (
    <section className="mainContent">
      <Toolbar />

      <Filterbar
        filterYears={"single"}
        filterWho={"multi"}
        filterWhen={"disabled"}
        filterWhat={"disabled"}
        maxDate={new Date()}
        buttonCreate={{
          title: t("button_titles.assessments_create"),
          handleClick: () => history.push("/performance-assessment/new"),
          showButton: data && data.length > 0 ? true : false,
          hasAssessmentFormInStorage: localStorage.getItem("formAssessment") !== null ? true : false
        }}
      />

      <main className="mainContentPage">
        {!fetching ? (
          
          firstData.total > 0 ? (
              <EnhancedTable
              columns={columns}
              data={data}
              pageCountRows={pageCountRows}
              count={pageCount}
              filter={filter}
              fetchData={getDataAssessments}
              actionColumn={(row) => (
                <div>
                  <button
                    className={"buttonThreePoints"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClick(e, row)
                    }}
                  >
                    <IconThreePoints />
                  </button>
                </div>
              )}
              title={t(
                "performance_assessment.page_assessment_created.table.title"
              )}
              onRowClick={handleRowClick}
            />
            )
            :
            <NoData />
        ) : (
          <GlobalSkeleton
            totalRowsStats={0}
            totalTables={1}
            heightTable={600}
          />
        )}

        {/* Start Popover Actions */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              backgroundColor: "#1E6B7F",
              marginTop: "10px",
            },
          }}
        >
          <Box className={"menuOptionsActions"}>
            <button
              className={
                disableActions
                  ? "menuOptionsActionButtonDisabled"
                  : "menuOptionsActionButton"
              }
              disabled={disableActions}
              onClick={() => history.push(`/performance-assessment/edit/${currentRow.id}`)}
            >
              {t("performance_assessment.actions.edit")}
            </button>
            <button
              className={
                disableActions
                  ? "menuOptionsActionButtonDisabled"
                  : "menuOptionsActionButton"
              }
              disabled={disableActions}
              onClick={handleClickConfirmDelete}
            >
              {t("performance_assessment.actions.delete")}
            </button>
            <button
              className={
                disableActions
                  ? "menuOptionsActionButtonDisabled"
                  : "menuOptionsActionButton"
              }
              disabled={disableActions}
              onClick={handleClickConfirmClone}
            >
              {t("performance_assessment.actions.clone_assessment")}
            </button>
            {/* <button
              className={
                disableActions
                  ? "menuOptionsActionButtonDisabled"
                  : "menuOptionsActionButton"
              }
              disabled={disableActions}
              onClick={() => history.push(`/performance-assessment/reports/assessment?assessment=${currentRow.id}`)}
            >
              {t("performance_assessment.actions.report")}
            </button> */}
          </Box>
        </Popover>
        {/* End Popover Actions */}

        {/* Start Confirm Dialog Delete Assessment */}
        <PopoverConfirm
          idConfirm={idConfirmDelete}
          openConfirm={openConfirmDelete}
          anchorEl={anchorElConfirmDelete}
          handleClose={handleCloseConfirmDelete}
          handleConfirm={handleConfirmDeleteAssessment}
          title={t('performance_assessment.messages.delete_assessment')}
        />
        {/* End Confirm Dialog Delete Assessment */}

         {/* Start Confirm Dialog Clone Assessment */}
         <PopoverConfirm
          idConfirm={idConfirmClone}
          openConfirm={openConfirmClone}
          anchorEl={anchorElConfirmClone}
          handleClose={handleCloseConfirmClone}
          handleConfirm={handleConfirmCloneAssessment}
          title={t('performance_assessment.messages.clone_assessment')}
        />
        {/* End Confirm Dialog Clone Assessment */}

      </main>
    </section>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    alertActions: bindActionCreators(alertActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
    filter: state.filter,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerformanceAssessmentsCreatedPage);
