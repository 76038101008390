import React, { useEffect, useState } from 'react'
import styles from './StatsInfoHeader.module.scss'
import { Box, Button, Popover, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { api } from '../../_helpers/api';
import { authHeader } from '../../_helpers';
import { Robot } from 'phosphor-react';

const useStyles = makeStyles((theme) => ({
  button: {
    minHeight: '42px',
    backgroundColor: '#FDBE2B',
    borderRadius: '2px',
    cursor: 'pointer',
    fontWeight: '600',
    border: 'none',
    color: '#106276',
    textTransform: 'none',
    boxShadow: 'none',
    transition: '0.2s',

    '&:hover': {
      backgroundColor: '#FDBE2B',
      boxShadow: '0px 0px 4px 0px #2B2B2B55',
    },
    '& > span': {
      fontSize: '13px',
      display: 'flex',
      flexWrap: 'nowrap'
    }
  },
  customLabel: {
    fontSize: '10px'
  }

}));

export default function StatsInfoHeader({
  statsGlobal,
  statsFiltered,
  selectedRows,
  getTeamsData,
  alertActions,
  activeFilter,
  setActiveFilter,
  setSelectedRows,
  usersWithoutAgentsFilter,
  setUsersWithoutAgentsFilter
}) {
  const { t } = useTranslation('common');

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null);
  const [disableActions, setDisableActions] = useState(false);
  const [openModalMoveTo, setOpenModalMoveTo] = useState(null);
  const [teamsSelected, setTeamsSelected] = useState([]);
  const [anchorElDeactiveTeam, setAnchorElDeactiveTeam] = useState(null);
  const openConfirmDeactiveTeam = Boolean(anchorElDeactiveTeam);
  const idConfirmDeactiveTeam = openConfirmDeactiveTeam ? 'simple-popover-deactive-team' : undefined;

  const handleClickDeactiveTeam = (event) => {
    setAnchorElDeactiveTeam(event.currentTarget);
  };
  const handleCloseDeactiveTeam = () => {
    setAnchorElDeactiveTeam(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElDeactiveTeam(null)
  };

  const open = Boolean(anchorEl);
  const id = open ? 'actions-popover' : undefined;

  const handleDeactivateTeams = async () => {
    try {
      if (selectedRows.length === 0) {
        throw new Error('Sem equipes selecionadas')
      }
      setDisableActions(true)

      const payload = {
        type: 'TEAM',
        action: 'DEACTIVE',
        ids: selectedRows.join(',')
      }
      const res = await api.post(`/settings/`, payload, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro ao desativar as equipes')
      }

      getTeamsData({ pageIndex: 0, pageSize: 20, isLoading: false })
      handleClose()
      alertActions.success(t('settings.teams.success.deactivate_teams'))
    } catch (error) {
      console.error(error)
    } finally {
      setDisableActions(false)
    }
  }

  const removeTeam = (teamId) => {
    setTeamsSelected(teamsSelected.filter((t) => t.id !== teamId))
    setSelectedRows(selectedRows.filter(r => r !== teamId))
  }

  // useEffect(() => {
  //   setTeamsSelected(teams.filter(t => selectedRows.includes(t.id)))
  // }, [selectedRows])


  return (
    <header className={styles.headerCompany}>
      <div className={styles.containerInfoCompany}>
        <div className={styles.boxStatsCompany}>
          <h6 className={styles.titleCompany}>{t('settings.users.title_table')}</h6>
          <div className={styles.boxStatsColabs}>
            <div className={`${styles.stats} ${activeFilter === 0 && styles.activeFilter}`} onClick={() => setActiveFilter(0)}>
              <div className={`${styles.circle} ${styles.circleBlue}`}></div>
              <div className={styles.labelStats}>{`${t('settings.users.users_register')}:`}</div>
              <div className={styles.numberValue}>{`${statsGlobal.total_users || 0}`}</div>
            </div>
            <div className={`${styles.stats} ${activeFilter === 1 && styles.activeFilter}`} onClick={() => setActiveFilter(1)}>
              <div className={`${styles.circle} ${styles.circleGreen}`}></div>
              <div className={styles.labelStats}>{`${t('settings.users.licenses_in_use')}:`}</div>
              <div className={styles.numberValue}>{`${statsGlobal.total_active_users || 0}`}</div>
            </div>
            <div className={`${styles.stats} ${activeFilter === 2 && styles.activeFilter}`} onClick={() => setActiveFilter(2)}>
              <div className={`${styles.circle} ${styles.circleRed}`}></div>
              <div className={styles.labelStats}>{`${t('settings.users.users_inactive')}:`}</div>
              <div className={styles.numberValue}>{`${statsGlobal.total_unactive_users || 0}`}</div>
            </div>
            <div className={`${styles.statsNoHover}`}>
              <div className={`${styles.circle} ${styles.circleYellow}`}></div>
              <div className={styles.labelStats}>{`${t('settings.users.licenses_available')}:`}</div>
              <div className={styles.numberValue}>{`${statsGlobal?.remaining_users || 0} / ${statsGlobal?.allowed_users || 0}`}</div>
            </div>
            <div className={`${styles.stats} ${usersWithoutAgentsFilter === true && styles.activeFilter}`} onClick={() => setUsersWithoutAgentsFilter(curState => !curState)}>
              <div><Robot color='#FC6662' size={16} weight='bold' /></div>
              <div className={styles.labelStats}>{`${t('settings.users.users_without_agent')}:`}</div>
              <div className={styles.numberValue}>{`${statsFiltered?.total_users_without_agent}`}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.boxActions} style={{ display: 'none' }}>
        <Button disableElevation aria-describedby={id} variant="contained" color="primary" onClick={handleClick} endIcon={<ExpandMore />} className={classes.button} disabled={!(selectedRows.length > 0)}>
          {`${t('settings.teams.actions')}`}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              backgroundColor: '#1E6B7F',
              marginTop: '10px'
            }
          }}
        >
          <Box className={styles.menuOptionsActions}>
            <button className={disableActions ? styles.menuOptionsActionButtonDisabled : styles.menuOptionsActionButton} disabled={disableActions} aria-describedby={idConfirmDeactiveTeam} onClick={handleClickDeactiveTeam}>{t('settings.teams.deactivate')}</button>
            <button className={disableActions ? styles.menuOptionsActionButtonDisabled : styles.menuOptionsActionButton} disabled={disableActions} onClick={() => setOpenModalMoveTo(true)}>{t('settings.teams.action_move_to')}</button>
          </Box>
        </Popover>

        <Popover
          id={idConfirmDeactiveTeam}
          open={openConfirmDeactiveTeam}
          anchorEl={anchorElDeactiveTeam}
          onClose={handleCloseDeactiveTeam}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          disableEnforceFocus
          elevation={0}
        >
          <Box
            sx={{
              backgroundColor: '#FFE0DF'
            }}
          >
            <Typography variant="subtitle2" style={{ padding: '12px', color: '#FC6662', fontSize: '12px', fontWeight: '600' }}>{t('settings.teams.inactive_team_message')}</Typography>
            <Box paddingBottom={2} paddingRight={2} display={'flex'} style={{ gap: '6px' }} justifyContent={'flex-end'}>
              <Button
                onClick={handleCloseDeactiveTeam}
                disabled={disableActions}
                style={{
                  width: '42px !important',
                  height: '25px',
                  backgroundColor: '#fff',
                  color: '#FC6662',
                  textTransform: 'capitalize'
                }}
                classes={{ label: classes.customLabel }}
              >
                {t('settings.teams.no')}
              </Button>
              <Button
                onClick={handleDeactivateTeams}
                disabled={disableActions}
                style={{
                  width: '42px !important',
                  height: '25px',
                  backgroundColor: '#FC6662',
                  color: '#fff',
                  textTransform: 'capitalize'
                }}
                classes={{ label: classes.customLabel }}
              >
                {t('settings.teams.yes')}
              </Button>
            </Box>
          </Box>
        </Popover>

      </div>
    </header>
  )
}
