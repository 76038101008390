import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation, } from "react-i18next";
import { CircularProgress, Tooltip, withStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import { getWeekDays } from '../../../_helpers/get-week-calendar';
import './styles.scss'
import { getMonthName } from '../../../_helpers/get-month-calendar';
import { usePagination, useSortBy, useTable } from 'react-table';
import { Pagination } from '@material-ui/lab';
import { TooltipBlue, TooltipGreen } from '../../_shared/Tooltips';


function MoodFull({ moodsData, getMoodData, startDate, endDate, count, pageCountRows }) {
	const { t, i18n } = useTranslation('common');
	const weekDays = getWeekDays(i18n.language)
	console.log(weekDays)
	const monthName = getMonthName(endDate + 'T00:00:00', i18n.language)
	const [firstLoad, setFirstLoad] = useState(true)
	const [loading, setLoading] = useState(false)

	const myPageOptions = [
		{
			value: 20,
			label: '20'
		},
		{
			value: 50,
			label: '50'
		},
		{
			value: 100,
			label: '100'
		},
		{
			value: 200,
			label: '200'
		},
		{
			value: 300,
			label: '300'
		},
		{
			value: 400,
			label: '400'
		},
		{
			value: 500,
			label: '500'
		},
		{
			value: pageCountRows,
			label: 'all'
		}
	]

	const gererateDynamicColumns = () => {
		const columns = []
		
		if(moodsData.length > 0) {
			moodsData[0].calendarMoods.map((itemDay, index) => {
				columns.push({
					Header: weekDays[itemDay.weekDay],
					accessor: `calendarMoods[${index}]`,
					Cell: ({ row, column }) => {
						const moodItem = row.original.calendarMoods[column.id.split('[')[1].split(']')[0]];
						return (
							<div className='mood'>
								{moodItem?.mood > -1 ? (
									<img src={moodItem.image_url} width={18} loading='lazy' alt='Mood' />
								) : (
									'--'
								)}
							</div>
						);
					},
					width: '5%'
				})
			})
			return columns
		}

		weekDays.map((day, index) => {
			columns.push({
				Header: day,
				accessor: `calendarMoods[${index}]`,
				Cell: ({ row, column }) => {
					const moodItem = row.original.calendarMoods[column.id.split('[')[1].split(']')[0]];
					return (
						<div className='mood'>
							{moodItem?.mood > -1 ? (
								<img src={moodItem.image_url} width={18} loading='lazy' alt='Mood' />
							) : (
								'--'
							)}
						</div>
					);
				},
				width: '5%'
			})
		})

		return columns
	}

	const columns = useMemo(
    () => [
      {
        Header: t('mood.teammate').toUpperCase(),
        accessor: 'username',
        Cell: ({ value }) => value,
				width: '20%',
      },
      {
        Header: t('activities.team').toUpperCase(),
        accessor: 'group',
        Cell: ({ row }) => {
          const item = row.original;
          const groupName = item.ancestry.reduce((acc, ances) => acc + ances.group_name + ' / ', '') + item.group_name;
          return (
              <span title={groupName}>{groupName}</span>
          );
        },
      },
			...gererateDynamicColumns(),
      {
        Header: t('productivity.productivity').toUpperCase(),
        accessor: 'productivity',
        Cell: ({ row }) => {
          const item = row.original;
          return (
            <div className='productivity-container'>
							<TooltipBlue
                title={<Box sx={{
                  p: '10px 12px',
                  backgroundColor:"#187DFF",
                  color: '#fff',
                  borderRadius: '4px',
                  fontSize: '12px'
                }}>
                  {t('mood.time_work')}
                </Box>}
                arrow
              >
								<div className='time-work'>
									<div className='circle' style={{ border: '1px solid #187DFF' }}>
										<CircularProgressbar
											value={item.worked_time * 100}
											strokeWidth={50}
											styles={buildStyles({
												strokeLinecap: 'butt',
												trailColor: '#fff',
												pathColor: '#187DFF',
											})}
										/>
									</div>
									<span>{(item.worked_time * 100).toFixed(2)}%</span>
								</div>
							</TooltipBlue>

							<TooltipGreen
                title={<Box sx={{
                  p: '10px 12px',
                  backgroundColor: "#22BEAD",
                  color: '#fff',
                  borderRadius: '4px',
                  fontSize: '12px'
                }}>
                  {t('mood.time_goal')}
                </Box>}
                arrow
              >
								<div className='time-goal'>
									<div className='circle' style={{ border: '1px solid #22BEAD' }}>
										<CircularProgressbar
											value={item.productive_worked_time * 100}
											strokeWidth={50}
											styles={buildStyles({
												strokeLinecap: 'butt',
												trailColor: '#fff',
												pathColor: '#22BEAD',
											})}
										/>
									</div>
									<span>{(item.productive_worked_time * 100).toFixed(2)}%</span>
								</div>
							</TooltipGreen>
            </div>
          );
        },
				width: '15%',
      },
    ],
    []
  );

	const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			rows,
			prepareRow,
			page,
			pageCount,
			gotoPage,
			setPageSize,
			state: { pageIndex, pageSize }
	} = useTable({
			columns,
			data: moodsData || [],
			disableMultiSort: true,
			manualPagination: true,
			pageCount: count,
			autoResetPage: false,
			initialState: { pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20 },
			pageOptions: Array.from({ length: count }, () => 1).map((_, index) => index)

	}, useSortBy, usePagination);

	
	const fetchData = async ({ pageIndex, pageSize}) => {
		try {
				setLoading(true)
				console.log("chamou aqui")
				await getMoodData({isLoading: false, pageIndex: pageIndex, pageSize: pageSize})
				setLoading(false)
		} catch (error) {
				console.error('Error fetching data:', error);
				setLoading(false)
		}
	};

	useEffect(() => {
		if(!firstLoad){
				fetchData({ pageIndex, pageSize });
		}else{
				setFirstLoad(false)
		}
	}, [pageIndex, pageSize]);


	return (
		<>
			<header className='mood-header'>
				{i18n.language === 'pt' && (
					<h2>Dia {startDate} a {endDate.split('-')[2]} de {monthName}</h2>
				)}
				{i18n.language === 'en' && (
					<h2>{monthName} {startDate}th to {endDate.split('-')[2]}th</h2>
				)}
				{i18n.language === 'es' && (
					<h2>{startDate} al {endDate.split('-')[2]} de {monthName}</h2>
				)}
			</header>

			<table {...getTableProps()} className='table-mood'>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
								<th {...column.getHeaderProps()} style={{ width: column.width }}>
									{column.render('Header')}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map(cell => (
									<td {...cell.getCellProps()} className={['group'].includes(cell.column.id) ? 'cellShrinkTable' : ''}>{cell.render('Cell')}</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>

			<div className={"containerPaginationGlobal bgWhite"}>
				{
						loading && <CircularProgress color="primary" size={20} />
				}
				<Pagination
						count={pageCount}
						page={pageIndex + 1}
						onChange={(event, page) => {
								gotoPage(page - 1)
						}}
						variant="outlined"
						shape="rounded"
						siblingCount={1}
						boundaryCount={1}
				/>
				<select
				value={pageSize}
				onChange={(e) => {
						setPageSize(Number(e.target.value));
						localStorage.setItem('pageSizeStorage', Number(e.target.value));
				}}
				className={"selectOptionsPaginationGlobal"}
				>
				{myPageOptions.map((pageSize, index) => (
            <option key={`page-${index}`} value={pageSize.value}>
						{pageSize.label === 'all' ? t('settings.teams.show_all') : `${t('settings.teams.show')} ${pageSize.label}`}
						</option>
				))}
				</select>
			</div>
		</>
	)
};

function mapDispatchToProps(dispatch) {

	return {

	}
}

function mapStateToProps(state) {

	return {

	}

}

export default connect(mapStateToProps, mapDispatchToProps)(MoodFull)
