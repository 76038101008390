// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Y18LafRMcI2l-Qd0vmSgFw\\=\\= {\n  display: flex;\n  flex: 1;\n  min-width: 165px;\n}\n\n.ilwczBVg7cOX-bKhJ\\+OKUQ\\=\\= {\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/_components/reports/agents_installed_teams/CardsHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,OAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AACF","sourcesContent":[".cardContainer {\n  display: flex;\n  flex: 1;\n  min-width: 165px;\n}\n\n.cardHeader {\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": "Y18LafRMcI2l-Qd0vmSgFw==",
	"cardHeader": "ilwczBVg7cOX-bKhJ+OKUQ=="
};
export default ___CSS_LOADER_EXPORT___;
