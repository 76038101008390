import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'


import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import ActivitiesFull from '../_components/activities/ActivitiesFull'
import ActivitiesFullSkeleton from '../_components/activities/ActivitiesFullSkeleton'

import DrawerActivity from '../_components/activities/DrawerActivity'
import DrawerActivitySkeleton from '../_components/activities/DrawerActivitySkeleton'

import { useTranslation, initReactI18next } from "react-i18next";
import Moment from 'moment';

import { activitiesActions } from '../_actions';
import { statsActions } from '../_actions';

import IconClose from '../_components/_icons/IconClose';

function ActivitiesPage(props) {
    const { t, i18n } = useTranslation('common');

    const { activitiesActions } = props;
    const { statsActions, userDetails} = props;

    let loading = props.loading;
    let loadingStats = props.loadingStats;
    let loadingDetailsTeams = props.loadingDetailsTeams;
    let loadingDetailsTeammates = props.loadingDetailsTeammates;
    let filter = props.filter || {};
    let activities = props.activities || [];


    const [minimumTime, setMinimumTime] = useState(500);
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
    const [loadMore, setLoadMore] = useState(false);

    const [drawer, setDrawer] = useState(false);
    const [drawerData, setDrawerData] = useState({});
    const [activeType, setActiveType] = useState({});
    const [drawerCurrentIndex, setDrawerCurrentIndex] = useState();


    const fetchData = () => {
        let startDate = Moment(new Date()).format('YYYY-MM-DD');
        let endDate = Moment(new Date()).format('YYYY-MM-DD');

        let teams = null;
        let teammates = null;

        let software = true;
        let site = true;
        let offline = true;
        let productive = true;
        let neutral = true;
        let unproductive = true;

        if (filter.date) {
            startDate = filter.date.startDate;
            endDate = filter.date.endDate;
        }

        if (filter.teams) {
            teams = filter.teams;
        }
        if (filter.teammates) {
            teammates = filter.teammates;
        }


        if (filter.software != 'undefined' && filter.software == false) {
            software = false
        }
        if (filter.site != 'undefined' && filter.site == false) {
            site = false
        }
        if (filter.offline != 'undefined' && filter.offline == false) {
            offline = false
        }
        if (filter.productive != 'undefined' && filter.productive == false) {
            productive = false
        }
        if (filter.neutral != 'undefined' && filter.neutral == false) {
            neutral = false
        }
        if (filter.unproductive != 'undefined' && filter.unproductive == false) {
            unproductive = false
        }

        let search = filter.search || null;

        statsActions.getStats(
            startDate,
            endDate,
            teams,
            teammates,
            [
                'TotalWorkedTime', 'TotalWorkedTimeSoftware', 'TotalWorkedTimeSite', 'TotalWorkedTimeOutdoor', 'TotalUnproductiveTime', 'TotalProductiveTime', 'TotalNeutralTime'
            ]
        );

        activitiesActions.getActivities(
            startDate,
            endDate,
            teams,
            teammates,
            0,
            software,
            site,
            offline,
            userDetails.allowed_productive.includes('related') ? productive : false,
            userDetails.allowed_productive.includes('neutral') ? neutral : false,
            userDetails.allowed_productive.includes('not_related') ? unproductive : false,
            search
        );
    }

    useEffect(() => {
        fetchData()
    }, [filter.date, filter.neutral, filter.offline, filter.productive, filter.site, filter.software, filter.teammates, filter.teams, filter.unproductive,])


    function openDrawer(data) {
        setDrawer(true);
        setDrawerCurrentIndex(data.index);
        setDrawerData(data.data);
        setActiveType(data.type);

        let date = new Date();
        let startDate = Moment(new Date()).format('YYYY-MM-DD');
        let endDate = Moment(new Date()).format('YYYY-MM-DD');

        let teams = null;
        let teammates = null;
        let software = true;
        let site = true;
        let offline = true;
        let productive = true;
        let neutral = true;
        let unproductive = true;

        if (filter.date) {
            startDate = filter.date.startDate;
            endDate = filter.date.endDate;
        }

        if (filter.teams) {
            teams = filter.teams;
        }
        if (filter.teammates) {
            teammates = filter.teammates;
        }

        if (filter.software != 'undefined' && filter.software == false) {
            software = false
        }
        if (filter.site != 'undefined' && filter.site == false) {
            site = false
        }
        if (filter.offline != 'undefined' && filter.offline == false) {
            offline = false
        }
        if (filter.productive != 'undefined' && filter.productive == false) {
            productive = false
        }
        if (filter.neutral != 'undefined' && filter.neutral == false) {
            neutral = false
        }
        if (filter.unproductive != 'undefined' && filter.unproductive == false) {
            unproductive = false
        }

        activitiesActions.getActivityDetailsTeammates(
                                                        data.id, 
                                                        startDate, 
                                                        endDate, 
                                                        teams, 
                                                        teammates, 
                                                        software, 
                                                        site, 
                                                        offline,                                                     
                                                        userDetails.allowed_productive.includes('related') ? productive : false,
                                                        userDetails.allowed_productive.includes('neutral') ? neutral : false,
                                                        userDetails.allowed_productive.includes('not_related') ? unproductive : false
                                                    );
        activitiesActions.getActivityDetailsTeams(
                                                    data.id, 
                                                    startDate, 
                                                    endDate, 
                                                    teams, 
                                                    teammates, 
                                                    software, 
                                                    site, 
                                                    offline, 
                                                    userDetails.allowed_productive.includes('related') ? productive : false,
                                                    userDetails.allowed_productive.includes('neutral') ? neutral : false,
                                                    userDetails.allowed_productive.includes('not_related') ? unproductive : false
                                                );

    }

    function changeDrawer(data) {
        setDrawerCurrentIndex(data.next);
        setDrawerData(activities[data.next]);

        let date = new Date();
        let startDate = Moment(new Date()).format('YYYY-MM-DD');
        let endDate = Moment(new Date()).format('YYYY-MM-DD');

        let teams = null;
        let teammates = null;

        if (filter.date) {
            startDate = filter.date.startDate;
            endDate = filter.date.endDate;
        }

        if (filter.teams) {
            teams = filter.teams;
        }
        if (filter.teammates) {
            teammates = filter.teammates;
        }

        activitiesActions.getActivityDetailsTeams(activities[data.next].trackedId, startDate, endDate, teams, teammates);
        activitiesActions.getActivityDetailsTeammates(activities[data.next].trackedId, startDate, endDate, teams, teammates);
    }

    useEffect(() => {
        document.title = 'Befective | ' + t('menu.activities');
    });

    return (
        <React.Fragment>

            <section className="mainContent">
                <Toolbar />

                <Filterbar
                    filterWhat={'enabled'}
                    showShareBtn={false}
                    totalDays={365}
                />
                {/* PAGE STARTS */}
                <div className="mainPage">
                    <div className="column single">
                        {
                            !minimumTimeElapsed || loading || loadingStats
                                ? <ActivitiesFullSkeleton />
                                : <ActivitiesFull
                                    openDrawer={(data) => {
                                        openDrawer(data)
                                    }}
                                    fetchData={fetchData} />
                        }
                    </div>

                </div>
                {/* PAGE ENDS */}
            </section>

            <div className={'overlay' + (drawer ? ' open' : '')} onClick={() => {
                setDrawer(false)
            }}></div>
            <section className={'drawer' + (drawer ? ' open' : '')}>
                <button onClick={() => {
                    setDrawer(false)
                }} className="close"><IconClose /></button>
                {
                    !drawer || loadingDetailsTeams || loadingDetailsTeammates
                        ? <DrawerActivitySkeleton />
                        : <DrawerActivity current={drawerData} active={activeType} currentIndex={drawerCurrentIndex}
                            changeDrawer={(data) => {
                                changeDrawer(data)
                            }} />
                }
            </section>

        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        activitiesActions: bindActionCreators(activitiesActions, dispatch),
        statsActions: bindActionCreators(statsActions, dispatch)

    }
}


function mapStateToProps(state) {

    return {
        loading: state.activities.loading,
        loadingStats: state.stats.loading,
        loadingDetailsTeams: state.activities.loadingDetailsTeams,
        loadingDetailsTeammates: state.activities.loadingDetailsTeammates,
        filter: state.filter,
        activities: state.activities.rows,        
        userDetails: state.authentication.userDetails
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesPage)
