import React, { useState } from 'react'
import styles from './FeedbacksTable.module.scss'
import { useTranslation } from 'react-i18next';
import { befectiveFormatDate } from '../../../_helpers';
import BefectiveTooltip from '../../_shared/BefectiveTooltip';
import { Add, Cancel, Edit } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { NewFeedbackModal } from './NewFeedbackModal';


export default function FeedbacksTable({
  title="",
  data=[],
  lang="en",
  isUpdating=false,
  handleChangeDateFeedback=() => {},
  actionColumn=() => {},
  getDataFormat = () => {},
  teammates=[],
  getFeedbackData = () => {},
  alertActions,
  accountsLogged,
  msal
}) {

  const { t, i18n } = useTranslation("common");
  const [openModal, setOpenModal] = useState(false);
  const [currentColab, setCurrentColab] = useState(null);


  const handleOpenModal = (colab) => {
    setCurrentColab(colab);
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentColab(null);
  }

  return (
    <div className={styles.tableContainer}>
      {
        title && (<div className={styles.titleTable}>
          <h3 className={styles.title}>{title}</h3>
        </div>
        )
      }
      <table>
        <thead>
          <tr>
            <th className={styles.headColTable}>{t("performance_assessment.page_feedback.table.col_assessment")}</th>
            <th className={`${styles.headColTable} ${styles.colShrink}`}>{t("performance_assessment.page_feedback.table.col_completed_at")}</th>
            <th className={styles.headColTable}>{t("performance_assessment.page_feedback.table.col_colab")}</th>
            <th className={styles.headColTable}>{t("performance_assessment.page_feedback.table.col_responsible")}</th>
            <th className={styles.headColTable}>{t("performance_assessment.page_feedback.table.col_score")}</th>
            <th className={styles.headColTable}>{t("performance_assessment.page_feedback.table.col_date")}</th>
            <th className={`${styles.headColTable} ${styles.colShrink}`}>{t("performance_assessment.page_feedback.table.col_action_plan")}</th>
            <th className={`${styles.headColTable} ${styles.colShrink}`}/>
          </tr>
        </thead>
        <tbody>
          {
            data && data.length > 0
            ?          
            data.map((item, index) => {

              return item?.evaluated?.map((evaluated, index2) => {
                return (
                  <tr key={`evaluated-${index2}`}>
                    {
                      index2 === 0 && (
                        <>
                          <td className={`${styles.tableColumn} ${styles.tableRowSpan}`} rowSpan={item.evaluated.length}>
                            {item?.title || '-----'}
                          </td>
                          <td className={`${styles.tableColumn} ${styles.tableRowSpan} ${styles.cellShrink}`} rowSpan={item.evaluated.length}>
                            {item?.date_end ? befectiveFormatDate(item?.date_end, lang) : '-----'}
                          </td>
                        </>
                      )
                    }
                    <td className={styles.tableColumn}>{evaluated?.evaluated_name || '-----'}</td>
                    <td className={styles.tableColumn}>{evaluated?.manager?.username || '-----'}</td>
                    <td className={styles.tableColumn}>
                      <BefectiveTooltip
                        title={<span className={styles.tooltipText}>{`${t("performance_assessment.page_feedback.table.col_classification")}: ${evaluated?.classification?.label || "-----"}`}</span>}
                        placement="bottom-center" 
                        arrow 
                        interactive
                      >
                        <span style={{color: evaluated?.classification?.text_color || '#4F7091', fontWeight: 500}}>{evaluated?.general_avg || "-----"}</span> 
                      </BefectiveTooltip>
                    </td>
                    <td className={styles.tableColumn}>
                      {
                        (evaluated?.meet_information && evaluated?.meet_information?.date) 
                        ? 
                        <>
                          {`${befectiveFormatDate(evaluated?.meet_information?.date, lang)}, ${evaluated?.meet_information?.start_period ? evaluated?.meet_information?.start_period.slice(0, 5) : '--:--'} - ${evaluated?.meet_information?.end_period ? evaluated?.meet_information?.end_period.slice(0, 5) : '--:--'}`}
                          <BefectiveTooltip
                            title={<span className={styles.tooltipText}>{t("performance_assessment.page_feedback.modal_new_feedback.title_edit")}</span>}
                            placement="bottom-center" 
                            arrow 
                            interactive
                          >
                            <IconButton
                              className={styles.iconButton}
                              aria-label="add-feedback"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenModal(evaluated)
                              }}
                              style={{marginLeft: '8px'}}
                            >
                              <Edit style={{color: "#4F7091"}} />
                            </IconButton>
                          </BefectiveTooltip>
                        </>
                        : 
                        <BefectiveTooltip
                          title={<span className={styles.tooltipText}>{t("performance_assessment.page_feedback.modal_new_feedback.title_create")}</span>}
                          placement="bottom-center" 
                          arrow 
                          interactive
                        >
                          <IconButton
                            className={styles.iconButton}
                            aria-label="add-feedback"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenModal(evaluated)
                            }}
                          >
                            <Add style={{color: "#4F7091"}} />
                          </IconButton>
                        </BefectiveTooltip>
                      }
                    </td>
                    <td className={`${styles.tableColumn} ${styles.cellShrink}`}>
                      {
                        evaluated?.has_action_plan 
                        ? 
                        <span style={{color: '#22BEAD'}}>{t("performance_assessment.page_feedback.table.has_action_plan")}</span> 
                        : 
                        <span style={{color: '#FC6662'}}>{t("performance_assessment.page_feedback.table.none_action_plan")}</span>
                      }
                    </td>
                    <td className={`${styles.tableColumn} ${styles.cellShrink}`}>
                      {
                        actionColumn(evaluated)
                      }
                    </td>
                  </tr>
                )
              })
            })
            :
            <tr>
              <td colSpan={8} className={styles.noData}>{t('generic_messages.none_data')}</td>
            </tr>
          }
        </tbody>
      </table>


      <NewFeedbackModal
        currentColab={currentColab}
        openNewFeedbackModal={openModal}
        closeNewFeedbackModal={handleCloseModal}
        lang={lang}
        teammates={teammates}
        getFeedbackData={getFeedbackData}
        alertActions={alertActions}
        accountsLogged={accountsLogged}    
        msal={msal}
      />

    </div>
  )
}
