import { Badge,Button, CircularProgress, Dialog, Drawer, IconButton, makeStyles, withStyles } from '@material-ui/core';
import { Box, Container, Grid, Typography } from '@mui/material';
import { ArrowLeft, BellSimple, CaretRight, ClockCounterClockwise, DotsNine, Gear, List } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Moment from 'moment';
import { Close } from '@material-ui/icons';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { MenuMagic } from '../_shared/MenuMagic';
import axios from 'axios';
import { api } from '../../_helpers/api';
import { backfectiveActions } from '../../_actions';
import style from './HeaderProductivityColab.module.scss';
import { MenuSwitch } from '../_shared/MenuSwitch';
import { authHeader, befectiveFormatDate, history } from '../../_helpers';
import IconLogo from '../_icons/IconLogo';
import utf8 from 'utf8';
import WhenMonthFilter from '../productivity/WhenMonthFilter';
import PopoverConfirm from '../teams_settings/PopoverConfirmDelete';
import AssessmentsFilter from './filters/AssessmentsFilter';
import { MenuOptionsExtra } from './MenuOptionsExtra';
import WhenDateFilter from '../productivity/WhenDateFilter';

const useStyles = makeStyles((theme) => ({
  badge: {
    fontSize: '10px',
    minWidth: '15px',
    width: '15px',
    height: '15px'
  },
  root: {
    '& > *': {
      margin: theme.spacing(0),
    },
  },
  button: {
    // minWidth: '130px',
    minHeight: '42px',
    backgroundColor: '#FDBE2B',
    borderRadius: '2px',
    cursor: 'pointer',
    fontWeight: '600',
    border: 'none',
    color: '#106276',
    textTransform: 'none',
    boxShadow: 'none',
    transition: '0.2s',

    '&:hover': {
      backgroundColor: '#FDBE2B',
      boxShadow: '0px 0px 4px 0px #2B2B2B55',
    },
    '& > span': {
      fontSize: '13px',
      display: 'flex',
      flexWrap: 'nowrap'
    }
  }
}));

function HeaderProductivityColab(props) {
  const { t, i18n } = useTranslation('common');
  const classes = useStyles();

  const { userDetails, organizations, backfectiveActions, toBackUrl, activeMenu, tabsAssessments, skills, currentTab, getDataAssessment, assessmentsOptions, smallHeight, pageRef} = props;
  const [totalAssessmentOpen, setTotalAssessmentOpen] = useState(0);
  const [totalAlertsNotRead, setTotalAlertsNotRead] = useState(0);
  const [toggleDrawerAlerts, setToggleDrawerAlerts] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [isLoadingAlerts, setIsLoadingAlerts] = useState(false);
  const [isAllAlertsLoaded, setIsAllAlertsLoaded] = useState(false);
  const [offSet, setOffset] = useState(0);
  const [todayAlerts, setTodayAlerts] = useState([])
  const [yesterdayAlerts, setYesterdayAlerts] = useState([])
  const [oldAlerts, setOldAlerts] = useState([])
  const [dialogAlert, setDialogAlert] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [notifications, setNotifications] = useState([])
  const [totalNotificationsNotRead, setTotalNotificationsNotRead] = useState(0)


  const [anchorElConfirmExitAssessment, setAnchorElConfirmExitAssessment] = useState(null);
  const openConfirmExitAssessment = Boolean(anchorElConfirmExitAssessment);
  const idConfirmExitAssessment = openConfirmExitAssessment ? 'popover-confirm-exit-assessment' : undefined;

  const handleOpenConfirmExitAssessment = (event) => {
    setAnchorElConfirmExitAssessment(event.currentTarget);
  };

  const handleCloseConfirmExitAssessment = () => {
    setAnchorElConfirmExitAssessment(null)
  };

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeDrawerAlerts = async () => {
    setToggleDrawerAlerts(false);
    await getNotificationsNotRead()
  }

  const handleClickOpenDialog = (alert) => {
    setDialogAlert(alert)
    createReadAlert(alert)
    setOpenDialog(true);
  };

  async function getAlerts(newOffset = 0) {
    try {
      setIsLoadingAlerts(true)
      setOffset(offSet + newOffset)

      const response = await api.get(`/alerts?organization_id=${userDetails.organizationId}&user_id=${userDetails.id}&language=${userDetails.language}&offset=${newOffset + offSet}`);

      if (
        response.data.todayAlerts.length > 0 ||
        response.data.yesterdayAlerts.length > 0 ||
        response.data.oldAlerts.length > 0
      ) {
        setTodayAlerts(oldState => [...oldState, ...response.data.todayAlerts])
        setYesterdayAlerts(oldState => [...oldState, ...response.data.yesterdayAlerts])
        setOldAlerts(oldState => [...oldState, ...response.data.oldAlerts])
        setTotalAlertsNotRead(response.data.totalAlertsNotRead)
      } else {
        setIsAllAlertsLoaded(true)
      }

      setIsLoadingAlerts(false)
    } catch (error) {
      console.log(error.response)
      setIsLoadingAlerts(false)
      // //alertActions.error(t('alerts.error.get_alerts'))
    }
  }

  const createReadAlert = async (alert) => {
    if (alert.read) {
      return
    }

    try {
      const myIpResponse = await axios.get('https://api.ipify.org?format=jsonp?callback=?')

      await api.post('/alerts/create_read', {
        organization_id: userDetails.organizationId,
        user_id: userDetails.id,
        alert_id: alert.ID,
        ip: myIpResponse.data
      })

      setTotalAlertsNotRead(totalAlertsNotRead - 1)

      const isTodayAlert = todayAlerts.find(item => item.ID === alert.ID)

      const isYesterdayALert = yesterdayAlerts.find(item => item.ID === alert.ID)

      const isOldAlert = oldAlerts.find(item => item.ID === alert.ID)

      if (isTodayAlert) {
        const alertsFiltered = todayAlerts.filter(item => item.ID !== alert.ID)
        const todayAlertsUpdated = [...alertsFiltered, {
          ...isTodayAlert,
          read: true
        }]

        setTodayAlerts(todayAlertsUpdated)
      }

      if (isYesterdayALert) {
        const alertsFiltered = yesterdayAlerts.filter(item => item.ID !== alert.ID)
        const yesterdayAlertesUpdated = [...alertsFiltered, {
          ...isYesterdayALert,
          read: true
        }]

        setYesterdayAlerts(yesterdayAlertesUpdated)
      }

      if (isOldAlert) {
        const alertsFiltered = oldAlerts.filter(item => item.ID !== alert.ID)
        const oldAlertsUpdated = [...alertsFiltered, {
          ...isOldAlert,
          read: true
        }]

        setOldAlerts(oldAlertsUpdated)
      }

    } catch (error) {
      console.log(error)
      // //alertActions.error(t('alerts.error.set_read'))
    }
  }

  async function getNotifications() {
    try {
      setIsLoadingAlerts(true)

      const response = await api.get(`/news/small-bell`, { headers: authHeader() });

      if (response.status !== 200) {
        throw new Error("Error to get notifications")
      }

      setNotifications(response.data)
      setIsLoadingAlerts(false)
    } catch (error) {
      console.log(error.response)
      setIsLoadingAlerts(false)
    }
  }

  async function getNotificationsNotRead() {
    try {
      const response = await api.get(`/news/small-bell/total`, { headers: authHeader() });

      if (response.status !== 200) {
        throw new Error("Error to get notifications not read")
      }
      setTotalNotificationsNotRead(parseInt(response.data.total || 0))
    } catch (error) {
      console.log(error.response)
    }
  }

  async function getPerformanceAssessmentsPending() {
    try {
      const response = await api.get(`/performance-assessment/user/alert-pending`, { headers: authHeader() });

      if (response.status !== 200) {
        throw new Error("Error to get assessments pending not read")
      }
      setTotalAssessmentOpen(parseInt(response.data.total || 0))
    } catch (error) {
      console.log(error.response)
    }
  }

  const handleOpenDrawerNotification = async () => {
    setToggleDrawerAlerts(true)
    await getNotifications()
  }

  const handleConfirmExitAssessment = () => {
    return history.push(toBackUrl)
  }

  const renderTitleProductivity = (pageRef) => {
    switch (pageRef) {
      case 'details':
        return <h3 className={style.titleMyProductivity}>
                  <span className={style.cursorPointer} onClick={() => history.push(props.urlToBack)}>{t('colab_page.my_productivity')}</span><CaretRight size={18} weight="bold" color='#FDBE2B'/><span>{t('colab_page.title_details')}</span>
                </h3>
      case 'inactivity':
        return <h3 className={style.titleMyProductivity}>{t('colab_page.inactivity')}</h3>
      case 'activities':
        return <h3 className={style.titleMyProductivity}>{t('colab_page.menu.activities')}</h3>
      default:
        return <h3 className={style.titleMyProductivity}>{t('colab_page.my_productivity')}</h3>

    }
  }

  useEffect(() => {
    if (userDetails.isMagicAgent) {
      backfectiveActions.getOrganizations()
    }
    i18n.changeLanguage(userDetails.language)
    getNotificationsNotRead()
    getPerformanceAssessmentsPending()
    getAlerts()
  }, [])

  return (
    <Box>
      <Box
        display="flex"
        bgcolor="#106276"
        paddingX={4}
        paddingY="12px"
      >
        <Container component={'header'} maxWidth="xl" sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} component={'div'} marginTop={'0px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            {
              !toBackUrl
                ?
                  <Typography color="#E4EAEF">
                    {`${t('colab_page.hello')}, ${utf8.decode(userDetails?.name)}`}
                  </Typography>
                :
                (
                  !tabsAssessments
                  ?
                  <Box display={'flex'} alignItems={'center'} gap={'8px'} sx={{ cursor: 'pointer' }} onClick={() => history.push(toBackUrl)}>
                    <ArrowLeft size={16} color="#CEDEE3" weight="bold" />
                    <Typography sx={{ fontSize: '14px', color: '#CEDEE3' }}>{t('back')}</Typography>
                  </Box>
                  :
                  <Box display={'flex'} alignItems={'center'} gap={'16px'}>
                    <button type="button" className={style.buttonBackHeader} onClick={handleOpenConfirmExitAssessment}>
                      <ArrowLeft size={16} color="#CEDEE3" weight="bold"  />
                    </button>
                    <Typography sx={{ fontSize: '14px', color: '#CEDEE3' }}>{props?.info}</Typography>
                  </Box>
                )
            }
            <div className={style.user}>
              <div
                className={style.notifications_icon}
                onClick={handleOpenDrawerNotification}
              >
                <Badge
                  badgeContent={totalAlertsNotRead + totalNotificationsNotRead}
                  color="secondary"
                  overlap="rectangular"
                  classes={{ badge: classes.badge }}
                >
                  <BellSimple size={24} weight="regular" />
                </Badge>
              </div>

              <MenuSwitch userDetails={userDetails} />

              <div
                className={style.notifications_icon}
                onClick={() => {
                  const pathSplit = history.location.pathname.split("/");
                  if(!pathSplit.includes("global-settings")){
                    localStorage.setItem("pathToBackPage", history.location.pathname);
                  }
                  history.push("/global-settings")
                }}
              >
                <Gear size={24} weight="regular" />
              </div>

              <div className={style.avatar} onClick={handleClick}>
                <img
                  src={`https://ui-avatars.com/api/?background=${userDetails.org_client_type === 'poc' ? 'FC6662' : '24677E'}&color=ffffff&name=` + encodeURI(userDetails.name)}
                  alt="user profile"
                />
              </div>
            </div>
          </Grid>
        </Container>
        <MenuMagic
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          userDetails={userDetails}
          handleClick2={handleClick2}
          open2={open2}
          anchorEl2={anchorEl2}
          handleClose2={handleClose2}
          organizations={organizations}
          backfectiveActions={backfectiveActions}
          isMuUser={userDetails?.roleName === 'collaborator' ? true : false}
        />

        <Drawer anchor='right' open={toggleDrawerAlerts} onClose={closeDrawerAlerts}>

          <div style={{ position: 'absolute', right: 4, top: 4 }}>
            <IconButton aria-label="close" onClick={closeDrawerAlerts}>
              <Close fontSize="medium" />
            </IconButton>
          </div>

          <div className="drawer-container-alerts">
            <header>
              <h2 style={{ marginBottom: 26 }}>
                {t('alerts.title')}
              </h2>
            </header>

            <main className="main-content-drawer-alerts">

              {notifications.length > 0 && (
                <div className='alerts-container'>
                  <h3>{t('alerts.notifications')}</h3>
                  {notifications.map(notification => (
                    <div
                      key={`notification-${notification.id}`}
                      className={`${!notification.is_a_new_one ? 'alerts-info disableHover read-alert' : 'alerts-info disableHover'} ${notification?.redirect_url !== null ? 'linkHover' : ''}`}
                      onClick={notification.redirect_url !== null ? () => userDetails?.profile_key !== "collaborator" ? history.push(`${notification.redirect_url?.admin}`) : history.push(`${notification.redirect_url?.colab}`)  : () => {}}
										  style={{ cursor: notification?.redirect_url !== null ? 'pointer' : '' }}
                    >
                      <div className='alerts-title' style={notification.redirect_url !== null ? {cursor:'pointer'}: {}}>
                        {/* {
                          String(notification[`body_${userDetails.language || i18n.language}`]).length > 100
                            ?
                            <Tooltip
                              title={<Typography component={'span'} variant='body2' >{notification[`body_${userDetails.language || i18n.language}`]}</Typography>}
                            >
                              <strong style={{ color: !notification.is_a_new_one ? '#98ACC1' : '#24677E' }}>
                                {String(notification[`body_${userDetails.language || i18n.language}`]).slice(0, 100) + ' [...]'}
                              </strong>
                            </Tooltip>
                            : */}
                        <strong style={{ color: !notification.is_a_new_one ? '#98ACC1' : '#24677E' }}>
                          {String(notification[`body_${userDetails.language || i18n.language}`])}
                        </strong>
                        {/* } */}
                      </div>
                      <div className='alerts-message'>
                        <span style={{ color: !notification.is_a_new_one ? '#98ACC1' : '#24677E' }}>
                          {befectiveFormatDate(notification.created_at,userDetails.language || i18n.language,'c')}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {todayAlerts.length > 0 && (
                <div className='alerts-container'>
                  <h3>{t('alerts.today')}</h3>

                  {todayAlerts.map(item => (
                    <div
                      key={item.ID}
                      className={item?.read ? 'alerts-info read-alert' : 'alerts-info'}
                      onClick={() => handleClickOpenDialog(item)}
                    >
                      <div className='alerts-title'>
                        <Avatar
                          name={item.display_name}
                          size={15}
                          round
                          color={item?.read ? '#98ACC1' : '#24677E'}
                        />
                        <strong style={item?.read && { color: '#98ACC1' }}>
                          {item.post_title}
                        </strong>
                      </div>
                      <div className='alerts-message'>
                        <span style={item?.read && { color: '#98ACC1' }}>
                          {Moment(new Date(item.post_date)).format('DD/MM/yyyy - HH:MM')}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {yesterdayAlerts.length > 0 && (
                <div className='alerts-container'>
                  <h3>{t('alerts.yesterday')}</h3>

                  {yesterdayAlerts.map(item => (
                    <div
                      key={item.ID}
                      className={item?.read ? 'alerts-info read-alert' : 'alerts-info'}
                      onClick={() => handleClickOpenDialog(item)}
                    >
                      <div className='alerts-title'>
                        <Avatar
                          name={item.display_name}
                          size={15}
                          round
                          color={item?.read ? '#98ACC1' : '#24677E'}
                        />
                        <strong style={item?.read && { color: '#98ACC1' }}>
                          {item.post_title}
                        </strong>
                      </div>
                      <div className='alerts-message'>
                        <span style={item?.read && { color: '#98ACC1' }}>
                          {Moment(new Date(item.post_date)).format('DD/MM/yyyy - HH:MM')}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {oldAlerts.length > 0 && (
                <div className='alerts-container'>
                  <h3>{t('alerts.old')}</h3>

                  {oldAlerts.map(item => (
                    <div
                      key={item.ID}
                      className={item?.read ? 'alerts-info read-alert' : 'alerts-info'}
                      onClick={() => handleClickOpenDialog(item)}
                    >
                      <div className='alerts-title'>
                        <Avatar
                          name={item.display_name}
                          size={15}
                          round
                          color={item?.read ? '#98ACC1' : '#24677E'}
                        />
                        <strong style={item?.read && { color: '#98ACC1' }}>
                          {item.post_title}
                        </strong>
                      </div>
                      <div className='alerts-message'>
                        <span style={item?.read && { color: '#98ACC1' }}>
                          {Moment(new Date(item.post_date)).format('DD/MM/yyyy - HH:MM')}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {((todayAlerts.length + yesterdayAlerts.length + oldAlerts.length) % 10 === 0) &&
                (
                  !isAllAlertsLoaded && (
                    <div className='show-more-alert'>
                      {isLoadingAlerts ? (
                        <CircularProgress size={20} />
                      ) : (
                        <button onClick={() => getAlerts(10)}>
                          {t('alerts.show_more')}
                        </button>
                      )}
                    </div>
                  )
                )}

            </main>
          </div>
        </Drawer>

        <Dialog onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={openDialog}>
          <div className='dialog-container-details'>
            <header>
              <div className='title'>
                <strong>{dialogAlert.post_title}</strong>
              </div>
              <div className='user-info'>
                <strong>{dialogAlert.display_name}</strong>
                <span>{Moment(new Date(dialogAlert.post_date)).format('DD/MM/yyyy HH:MM')}</span>
              </div>

              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                style={{ position: 'absolute', top: '-5px', right: '0' }}
              >
                <Close fontSize="medium" />
              </IconButton>
            </header>

            <main className='main-content-details'>
              <p
                className='alert-message'
                dangerouslySetInnerHTML={
                  { __html: dialogAlert.post_content?.replaceAll('\n', '<br />') }
                }>
              </p>
            </main>
          </div>
        </Dialog>
      </Box>

      <Box
        display="flex"
        bgcolor="#1E6B7F"
        paddingX={4}
        paddingY="12px"
        borderBottom={'1px solid #106276'}
      >
        <Container component={'header'} maxWidth="xl" sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} component={'div'} marginTop={'0px'} display={'flex'} alignItems={'center'}>
            <div className={style.boxLogo}>
              <IconLogo />
            </div>
            {
              !tabsAssessments
              ?
              <ul className={style.menu}>
                <li className={style.menuItem}>
                    <a className={activeMenu === 'productivity' ? style.active : ''} onClick={() => history.push('/productive/productivity')}>
                      <div className={style.contentLink}>
                        {t('colab_page.menu.productivity')}<span className={style.line}></span>
                      </div>
                    </a>
                </li>
                <li className={style.menuItem}>
                    <a className={activeMenu === 'activities' ? style.active : ''} onClick={() => history.push('/productive/productivity/activities_colab')}>
                      <div className={style.contentLink}>
                        {t('colab_page.menu.activities')}<span className={style.line}></span>
                      </div>
                    </a>
                </li>
                <li className={style.menuItem}>
                    <a className={activeMenu === 'checklist' ? style.active : ''} onClick={() => history.push('/productive/productivity/checklist')}>
                      <div className={style.contentLink}>
                        {t('colab_page.menu.checklist')}<span className={style.line}></span>
                      </div>
                    </a>
                </li>
                <li className={style.menuItem}>
                    <a className={activeMenu === 'performance-assessment' ? style.active : ''} onClick={() => history.push('/productive/productivity/performance-assessment')}>
                      <div className={style.contentLink}>
                        {t('colab_page.menu.performance_assessment')}<span className={style.line}></span>
                      </div>
                      {totalAssessmentOpen > 0 && <div className={style.contentLinkWithBadge}>{totalAssessmentOpen}</div>}
                    </a>
                </li>
              </ul>
              :
              <ul className={style.tabsAssessments}>
                {
                  skills?.length > 0 && skills.map((skill, index) => (
                    <React.Fragment key={`skill-${index}`}>
                      <li className={`${style.tabAssessmentsItem} ${ index <= currentTab ? style.active : ''}`}>
                        <span>{skill?.name}</span>
                        <div className={style.progressBar}></div>
                      </li>
                      {
                        index < skills.length - 1
                        &&
                        <li className={`${style.tabAssessmentsItem} ${ index+1 <= currentTab ? style.active : ''}`}>
                          <span className={style.invisible}>-</span>
                          <div className={style.progressBar}></div>
                        </li>
                      }
                    </React.Fragment>
                  ))
                }
              </ul>
            }
          </Grid>
        </Container>
      </Box>

      {
        !tabsAssessments &&
        <Box
          display="flex"
          bgcolor="#1E6B7F"
          paddingX={4}
          paddingY="12px"
          height={smallHeight ? "94px" : "150px"}
        >
          <Container component={'header'} maxWidth="xl" sx={{ flexGrow: 1 }}>
            {
              activeMenu === 'productivity' && 
              <Grid container spacing={2} component={'div'} marginTop={'20px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{paddingLeft:'16px', paddingRight:'16px'}}>
                
                {renderTitleProductivity(pageRef)}
                
                <Box display={'flex'} gap={'10px'}>
                  {
                    props.urlToBack &&
                    <button className={style.buttonBack} onClick={() => history.push(props.urlToBack)}>
                      <ArrowLeft size={16} />
                      {t('colab_page.back')}
                    </button>
                  }
                  {/* {props.optionsExtra && <MenuOptionsExtra/>} */}
                  {props.showMonthFilter && <WhenMonthFilter maxDate={props.maxDate} />}
                </Box>
              </Grid>
            }
            
            {
              activeMenu === 'activities' && 
              <Grid container spacing={2} component={'div'} marginTop={'20px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{paddingLeft:'16px', paddingRight:'16px'}} minHeight={ props.typeView === 'inactivity' ? '50px' : 'auto'}>
                
                {renderTitleProductivity(pageRef)}
                
                <Box display={'flex'} gap={'10px'}>
                  {(props.showDateFilter && props.typeView !== 'inactivity') && <WhenDateFilter maxDate={props.maxDate} />}
                  {(props.typeView === 'inactivity') && (
                    <button className={'buttonMenuOptionsExtra'} onClick={props.handleOpenDrawerHistory}>
                      <ClockCounterClockwise size={22} />
                    </button>
                  )}
                </Box>
              </Grid>
            }

            {
              activeMenu === 'checklist' && 
              <Grid container spacing={2} component={'div'} marginTop={'20px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <h3 className={style.titleMyProductivity}>{t('colab_page.checklist.title')}</h3>
                <Button variant="contained" color="secondary" className={classes.button} onClick={() => props.setOpenModalCreateNote(true)}>
                  {`+ ${t('colab_page.checklist.checklist')}`}
                </Button>
              </Grid>
            }

            {
              activeMenu === 'performance-assessment' && 
              <Grid container spacing={2} component={'div'} marginTop={'20px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <h3 className={style.titleMyProductivity}>{t('colab_page.performance_assessment.title')}</h3>
                <AssessmentsFilter
                  assessments={assessmentsOptions}
                  getData={getDataAssessment}
                />
              </Grid>
            }
          </Container>
        </Box>
      }

      {/* Start Confirm Dialog Exit Assessment */}
        <PopoverConfirm
          idConfirm={idConfirmExitAssessment}
          openConfirm={openConfirmExitAssessment}
          anchorEl={anchorElConfirmExitAssessment}
          handleClose={handleCloseConfirmExitAssessment}
          handleConfirm={handleConfirmExitAssessment}
          title={t('performance_assessment.messages.exit_assessment')}
        />
      {/* End Confirm Dialog Exit Assessment */}

    </Box>
  )
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails,
    organizations: state.backfective.organizations,
    filter: state.filter
  }
}

function mapDispatchToProps(dispatch) {
  return {
    backfectiveActions: bindActionCreators(backfectiveActions, dispatch),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderProductivityColab));
