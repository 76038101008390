import React, { useState } from 'react'
import styles from "./CompanyActivitiesOff.module.scss"
import { useTranslation } from 'react-i18next'
import { api } from '../../../_helpers/api'
import { authHeader } from '../../../_helpers'
import IconAdd from '../../_icons/IconAdd'
import { FloppyDisk, PencilSimple, Plus, Trash, X } from 'phosphor-react'
import PopoverConfirm from '../../teams_settings/PopoverConfirmDelete'


export default function CompanyActivitiesOff({
  outdoors=[],
  getOutdoors = async () => {},
  alertActions,
}) {

  const {t, i18n} = useTranslation('common')

  const [form, setForm] = useState({
    id: null,
    label: "",
    reference_value: "neutral"
  })

  const [errors, setErrors] = useState({
    label: ""
  })

  const  [isSubmitting, setIsSubmitting] = useState(false)
  const  [isUpdating, setIsUpdating] = useState(false)

  const [anchorElConfirmDeleteOutdoor, setAnchorElConfirmDeleteOutdoor] = useState(null);
  const openConfirmDeleteOutdoor = Boolean(anchorElConfirmDeleteOutdoor);
  const idConfirmDeleteOutdoor = openConfirmDeleteOutdoor ? 'popover-confirm-delete-outdoor' : undefined;
  const [outdoorToDelete, setOutdoorToDelete] = useState(null);

  const handleChange = (event) => {
    setForm({...form, [event.target.name]: event.target.value})
    setErrors({...errors, [event.target.name]: ""})
  }

  const validateForm = (form) => {
    let errors = {}
    if (!form.label) {
      errors.label = t('settings.company.activities_off_computer.error.required')
    }else if(outdoors.find(outdoor => String(outdoor.label).toLowerCase().trim() === String(form.label).toLowerCase().trim())){
      const outdoorFinder = outdoors.find(outdoor => String(outdoor.label).toLowerCase().trim() === String(form.label).toLowerCase().trim())
      if(outdoorFinder.id !== form.id){
        errors.label = t('settings.company.activities_off_computer.error.name_exist')
      }
    }else if(form.label.length < 3){
      errors.label = t('settings.company.activities_off_computer.error.minimum_length')
    }
    return errors
  }
  const handleAddOutdoor = async () => {
    try {
      
      const errors = validateForm(form)
      if (Object.keys(errors).length > 0) {
        setErrors(errors)
        return        
      }

      setIsSubmitting(true)

      const res = await api.post('/settings/outdoors', form, {headers: authHeader()})

      setErrors({})
      setForm({
        id: null,
        label: "",
        reference_value: "neutral"
      })
      
      await getOutdoors(false)

      setIsSubmitting(false)  
      
      alertActions.success(t('settings.company.activities_off_computer.success.created_activity'))

    } catch (error) {
      console.log(error)
      setIsSubmitting(false) 
      if(error?.response?.data?.message){
        alertActions.error(error.response.data.message)
      }else{
        alertActions.success(t('settings.company.activities_off_computer.error.created_activity'))
      }
    }
  }

  const handleClickConfirmDeleteOutdoor = (event, outdoor) => {
    setOutdoorToDelete(outdoor)
    setAnchorElConfirmDeleteOutdoor(event.currentTarget);
  };

  const handleCloseConfirmDeleteOutdoor = () => {
    setAnchorElConfirmDeleteOutdoor(null)
    setOutdoorToDelete(null)
  };

  const handleClickEditOutdoor = (event, outdoor) => {
    setForm({
      id: outdoor.id,
      label: outdoor.label,
      reference_value: outdoor.reference_value
    })
  };

  const handleCloseEditOutdoor = () => {
    setForm({
      id: null,
      label: "",
      reference_value: "neutral"
    })
  };
  
  const handleConfirmDeleteOutdoor = async () => {
    try {
      if (!outdoorToDelete.id) {
        throw new Error('Outdoor to delete not found')
      }

      const res = await api.delete(`/settings/outdoors/${outdoorToDelete.id}`, { headers: authHeader() })
      handleCloseConfirmDeleteOutdoor()      
      await getOutdoors(false)
      alertActions.success(t('settings.company.activities_off_computer.success.deleted_activity'))
    } catch (error) {
      console.error(error)
      handleCloseConfirmDeleteOutdoor() 
      if(error?.response?.data?.message){
        alertActions.error(error.response.data.message)
      }else{
        alertActions.success(t('settings.company.activities_off_computer.error.deleted_activity'))
      }    
    }
  }


  const handleEditOutdoor = async () => {
    try {
      
      const errors = validateForm(form)
      if (Object.keys(errors).length > 0) {
        setErrors(errors)
        return        
      }

      setIsUpdating(true)

      const res = await api.put(`/settings/outdoors/${form.id}`, form, {headers: authHeader()})

      setErrors({})
      setForm({
        id: null,
        label: "",
        reference_value: "neutral"
      })
      
      await getOutdoors(false)

      setIsUpdating(false)  
      
      alertActions.success(t('settings.company.activities_off_computer.success.updated_activity'))

    } catch (error) {
      console.log(error)
      setIsUpdating(false) 
      if(error?.response?.data?.message){
        alertActions.error(error.response.data.message)
      }else{
        alertActions.success(t('settings.company.activities_off_computer.error.updated_activity'))
      }
    }
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h4>{t('settings.company.activities_off_computer.title')}</h4>
      </header>
      <div className={styles.form}>
        <div className={styles.field}>
          <label>{t('settings.company.activities_off_computer.field_name')}</label>
          <input className={`${styles.customWidthInput}`} type="text" value={form.id === null ? form.label : ""} name="label" onChange={handleChange} placeholder={t('settings.company.activities_off_computer.name')}/>
          {(form.id === null && errors.label) && <span className={styles.error}>{errors.label}</span>}
        </div>
        <div className={styles.field}>
          <label>{t('settings.company.activities_off_computer.field_classification')}</label>
          <select name="reference_value" id="reference_value" value={form.id === null ? form.reference_value : "neutral"} onChange={handleChange} className={`${styles.customWidthSelect} ${styles[form.id === null ? form.reference_value : "neutral"]}`}>
            <option value="productive" className={styles.productive}>{t("settings.teams.related")}</option>
            <option value="neutral" className={styles.neutral}>{t("settings.teams.neutral")}</option>
            <option value="unproductive" className={styles.unproductive}>{t("settings.teams.not_related")}</option>
          </select>
        </div>
        <button 
          type="button" 
          className={styles.add} 
          onClick={handleAddOutdoor}
          disabled={isSubmitting}
          >
            <Plus size={20} color='#187DFF' weight='bold' />
        </button>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>{t('settings.company.activities_off_computer.name')}</th>
            <th>{t('settings.company.activities_off_computer.classification')}</th>
            <th/>
          </tr>
        </thead>
        <tbody>
          {outdoors.map((outdoor) => (
            <tr key={outdoor.id}>
              <td className={styles[outdoor.reference_value]}>
                {
                  outdoor.id === form.id 
                  ?
                  <div className={styles.field}>
                    <input type="text" value={form.label} name="label" onChange={handleChange} placeholder={t('settings.company.activities_off_computer.name')} autoFocus/>
                    {(errors.label) && <span className={styles.error}>{errors.label}</span>}
                  </div>
                  :
                  outdoor.label
                }
              </td>
              <td className={styles[outdoor.reference_value]}>
                {
                  outdoor.id === form.id 
                  ?
                  <div className={styles.field}>
                    <select name="reference_value" id="reference_value" value={form.reference_value} onChange={handleChange} className={styles[form.reference_value]}>
                      <option value="productive" className={styles.productive}>{t("settings.teams.related")}</option>
                      <option value="neutral" className={styles.neutral}>{t("settings.teams.neutral")}</option>
                      <option value="unproductive" className={styles.unproductive}>{t("settings.teams.not_related")}</option>
                    </select>
                  </div>
                  :
                  t(`settings.teams.${outdoor.reference_value}`)
                }
              </td>
              <td>
                {
                  outdoor.id === form.id
                  ?
                  <div className={styles.actions}>
                    <button type="button" className={styles.close} onClick={handleCloseEditOutdoor} disabled={isUpdating}><X size={20} color='#4F7091' /></button>                
                    <button type="button" className={styles.edit} onClick={handleEditOutdoor} disabled={isUpdating}><FloppyDisk size={20} color='#187DFF'/></button>
                  </div>
                  :
                  <div className={styles.actions}>
                    <button type="button" className={styles.delete} onClick={(e) => handleClickConfirmDeleteOutdoor(e, outdoor)}><Trash size={20} color='#FC6662' /></button>                
                    <button type="button" className={styles.edit} onClick={(e) => {handleClickEditOutdoor(e, outdoor)}}><PencilSimple size={20} color='#187DFF'/></button>
                  </div>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Start Confirm Dialog Delete Outdoor */}
      <PopoverConfirm
        idConfirm={idConfirmDeleteOutdoor}
        openConfirm={openConfirmDeleteOutdoor}
        anchorEl={anchorElConfirmDeleteOutdoor}
        handleClose={handleCloseConfirmDeleteOutdoor}
        handleConfirm={handleConfirmDeleteOutdoor}
        title={t('settings.company.activities_off_computer.message_confirm_delete')}
      />
      {/* End Confirm Dialog Delete Outdoor */}
    </div>
  )
}
