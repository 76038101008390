import config from 'config';
import { authHeader } from '../_helpers';

export const usersService = {
    login,
    logout,
    refresh,
    getByToken,
    activate,
    getAll,
    patch,
    updatePassword,
    passwordRecover,
    getByResetToken,
    resetPassword,
    getResponsibles
};

function login(org_id, email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ org_id, email, password })
    };



    return fetch(`${config.apiNodeUrl}/auth/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(process.env.KEY_USER_LOCAL_STORAGE, JSON.stringify(user));

            return user;
        });
}

function passwordRecover(org_id, email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ org_id, email })
    };

    return fetch(`${config.apiNodeUrl}/users/recover`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem(process.env.KEY_USER_LOCAL_STORAGE, JSON.stringify(user));

            return user;
        });
}

function refresh(user){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(true)
    };
    if(user !== undefined && user.roleName === "machine_user"){
        return fetch(`${config.apiNodeUrl}/stats/my-productivity/refresh`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(process.env.KEY_USER_LOCAL_STORAGE, JSON.stringify(res));

            return res;
        });

    }else{

        return fetch(`${config.apiNodeUrl}/auth/refresh`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(process.env.KEY_USER_LOCAL_STORAGE, JSON.stringify(res));

            return res;
        });
    }
    

}

function activate(password, passwordConfirm, user_id, token,org_id) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password, passwordConfirm, user_id, token,org_id })
    };

    return fetch(`${config.apiNodeUrl}/auth/activate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if(user.accessToken){
                localStorage.setItem(process.env.KEY_USER_LOCAL_STORAGE, JSON.stringify(user));
            }
            
            return user;
        });
}

function resetPassword(password, passwordConfirm, user_id, token, org_id) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password, passwordConfirm, user_id, token, org_id })
    };



    return fetch(`${config.apiNodeUrl}/auth/reset_password`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes

        

            if(user.accessToken){
                localStorage.setItem(process.env.KEY_USER_LOCAL_STORAGE, JSON.stringify(user));
            }
        


            return user;
        });
}

function getByToken(token,org_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${config.apiNodeUrl}/auth/activate/${token}/${org_id}`, requestOptions).then(handleResponse)
}

function getByResetToken(token, org_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${config.apiNodeUrl}/users/reset_password/${token}/${org_id}`, requestOptions).then(handleResponse)
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(process.env.KEY_USER_LOCAL_STORAGE);
    
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${config.apiNodeUrl}/users`, requestOptions).then(handleResponse)
}

function patch(form, id) {
    const requestOptions = {
        method: 'PATCH',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify({ 
            Name: form.name, 
            Language: form.language, 
            Timezone: form.timezone, 
            InitialPreview: form.initialPreview, 
            InitialScreen: form.initialScreen, 
            AsideMenu: form.asideMenu,
            active_daily_notification: form.active_daily_notification 
        })
    };

    return fetch(`${config.apiNodeUrl}/users/`+id, requestOptions).then(handleResponse);
}

function updatePassword(form, id) {
    const requestOptions = {
        method: 'PATCH',
        headers: { ...authHeader(), ...{ 'Content-Type': 'application/json' } },
        body: JSON.stringify({ CurrentPassword: form.current, NewPassword: form.new })
    };

    return fetch(`${config.apiNodeUrl}/users/password/`+id, requestOptions).then(handleResponse);
}

function getResponsibles(organizationId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiNodeUrl}/goals/get-responsibles?organization_id=${organizationId}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {                                
     
                logout();
                location.reload(true);
                               
            }

            const error = (data && data.Message) || response.statusText;
            return Promise.reject(error);
        }
        
        return data;
    });
}