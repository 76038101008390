import React from 'react'
import styles from './HistoricalOutdoorsDrawer.module.scss';
import { Skeleton } from '@mui/material';

export default function HistoricalOutdoorsSkeleton() {
  return (
    <div className={styles.historyContainer} >
      <div className={styles.boxMonthYear}>
        <Skeleton variant="rectangular" width={100} height={8} />
      </div>
      {
        [1,2,3].map((reg, index) => (
          <div key={`register-${index}`} className={styles.boxRegister}>
            <span className={styles.momentRegister}>
              <Skeleton variant="rectangular" width={100} height={7} />
            </span>
            <div className={styles.infoRegister}>
              <Skeleton variant="rectangular" width={200} height={16} />
            </div>
          </div>                      
        ))
      }
    </div>
  )
}
