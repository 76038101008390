import { Backdrop, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, Modal, Radio, RadioGroup, Select, Slide, makeStyles } from '@material-ui/core';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType } from '@azure/msal-browser';

import { authHeader, befectiveFormatDate, history } from '../../../_helpers';
import { api } from '../../../_helpers/api';

import style from './NewFeedbackModal.module.scss';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import PopoverConfirm from '../../teams_settings/PopoverConfirmDelete';
import BefectiveCheckbox from '../../_shared/BefectiveCheckbox';
import { PlugsConnected } from 'phosphor-react';
import { createNewEventMicrosoftCalendar, deleteEventMicrosoftCalendar, updateEventMicrosoftCalendar } from '../../../_helpers/graph';
import msalConfig from '../../../msal-config';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  select: {
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1976d2', // Cor da borda ao focar
    },
  }
}));

export function NewFeedbackModal({
  currentColab,
  openNewFeedbackModal,
  closeNewFeedbackModal,
  lang,
  teammates,
  getFeedbackData,
  alertActions,
  accountsLogged,
  msal
}) {
  
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [anchorElConfirmCancelFeedback, setAnchorElConfirmCancelFeedback] = useState(null);
  const openConfirmCancelFeedback = Boolean(anchorElConfirmCancelFeedback);
  const idConfirmCancelFeedback = openConfirmCancelFeedback ? 'popover-confirm-cancel-feedback' : undefined;
  const [form, setForm] = useState({
    id: null,
    date: null,
    start_period: null,
    end_period: null,
    meet_title: null,
    organizer_id: 'none',
    platform: null
  })

  const [formErrors, setFormErrors] = useState({
    date: false,
    start_period: false,
    end_period: false,
    meet_title: false,
    organizer_id: false,
    platform: false
  })

  const [integrations, setIntegrations] = useState({
    google: null,
    microsoft: null
  })

  const handleClickConfirmCancelFeedback = (event) => {
    setAnchorElConfirmCancelFeedback(event.currentTarget);
  };

  const handleCloseConfirmCancelFeedback = () => {
    setAnchorElConfirmCancelFeedback(null)
  };

  const handleChange = (e) => {
    // console.log(e.target.name, e.target.value)
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })

    setFormErrors({
      ...formErrors,
      [e.target.name]: false
    })

  }

  const validateForm = (form) => {
    const errors = {};

    if (!form.date) {
      errors.date = t('validation.required');
    }
    if (!form.start_period || form.start_period > form.end_period) {
      errors.start_period = t('validation.required');
    }
    if (!form.end_period || form.end_period < form.start_period) {
      errors.end_period = t('validation.required');
    }
    if (!form.meet_title) {
      errors.meet_title = t('validation.required');
    }
    if (!form.organizer_id || form.organizer_id === 'none') {
      errors.organizer_id = t('validation.required');
    }

    return errors
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validateForm(form))
    setSubmitted(true)
  }


  async function handleCancelFeedback() {
    try {
      setIsDeleting(true)
      //cria o evento no outlook calendar e seta o event_id no form.      
      
      if(currentColab.meet_information && currentColab.meet_information.event_id && currentColab.meet_information.platform === 'microsoft'  ) {
        
        const resp =  await deleteEventOnOutlookCalendar(currentColab.meet_information.event_id)
        
      }
      const res = await api.delete(`/performance-assessment/feedback/${form.id}`, {headers: authHeader()}) 
      if (res.status !== 200) {
        throw new Error('Erro to cancel feedback')
      }
      getFeedbackData()  
      handleCloseNewFeedbackModal()
      alertActions.success(t('performance_assessment.messages.feedback_canceled_success'))
      setIsDeleting(false)
    } catch (error) {
      console.log(error)
      setIsDeleting(false)
      alertActions.error(t('performance_assessment.messages.feedback_canceled_error')) 
    }
  }

  function resetForm() {
    setForm({
      date: null,
      start_period: null,
      end_period: null,
      meet_title: null,
      organizer_id: null
    })

    setFormErrors({
      date: false,
      start_period: false,
      end_period: false,
      meet_title: false,
      organizer_id: false
    })
  }

  const convertToTime = (valueTime) => {
    const hour = dayjs(valueTime).hour()
    const minute = dayjs(valueTime).minute()
    let hourString = ''
    let minuteString = ''
    if (hour < 10) {
      hourString = '0' + String(hour)
    } else {
      hourString = String(hour)
    }
    if (minute < 10) {
      minuteString = '0' + String(minute)
    } else {
      minuteString = String(minute)
    }
    return hourString + ':' + minuteString
  }

  async function createEventOnOutlookCalendar(title,date,start_period,end_period,emails,language) {
    
    try {
      const allAccounts = msal.instance.getAllAccounts()
      const accountActive = accountsLogged.find(account => account.is_active === true)
      const userProvider = allAccounts.find(account => account.username === accountActive.email)

      const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
        msal.instance,
        {
          account: userProvider,
          scopes: msalConfig.scopes,
          interactionType: InteractionType.Popup
        }
      );

      const response = await createNewEventMicrosoftCalendar(authProvider,title,date,start_period,end_period,emails,language)
      return response
    } catch (e) {
      console.log("Error on `createEventOnOutlookCalendar` : ",e)
    }
    

  }
  async function updateEventOnOutlookCalendar(event_id,title,date,start_period,end_period,emails,language) {
    
    try {
      const allAccounts = msal.instance.getAllAccounts()
      const accountActive = accountsLogged.find(account => account.is_active === true)
      const userProvider = allAccounts.find(account => account.username === accountActive.email)

      const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
        msal.instance,
        {
          account: userProvider,
          scopes: msalConfig.scopes,
          interactionType: InteractionType.Popup
        }
      );

      const response = await updateEventMicrosoftCalendar(authProvider,event_id,title,date,start_period,end_period,emails,language)
      return response
    } catch (e) {
      console.log("Error on `updateEventOnOutlookCalendar` : ",e)
    }
    

  }
  async function deleteEventOnOutlookCalendar(event_id) {
    
    try {
      const allAccounts = msal.instance.getAllAccounts()
      const accountActive = accountsLogged.find(account => account.is_active === true)
      const userProvider = allAccounts.find(account => account.username === accountActive.email)

      const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
        msal.instance,
        {
          account: userProvider,
          scopes: msalConfig.scopes,
          interactionType: InteractionType.Popup
        }
      );
      // authProvider,event_id
      const response = await deleteEventMicrosoftCalendar(authProvider,event_id)
      return response
    } catch (e) {
      console.log("Error on `deleteEventOnOutlookCalendar` : ",e)
    }
    

  }

  async function createNewFeedback() {
    try {
      
      let microsoft_event_id = ''
      if(currentColab.meet_information && currentColab?.meet_information.platform && currentColab?.meet_information.event_id && currentColab?.meet_information.platform !== form.platform && form.platform === 'microsoft') {
        
        const res_emails = await api.get(`/performance-assessment/feedback/get-email_list?users=${currentColab.machine_users_id},${form.organizer_id}`, {headers: authHeader()}) 
        if (res_emails.status !== 200) {
          throw new Error('Erro to create feedback')
        }
        const emails = res_emails.data || []
        // title,date,start_period,end_period,emails,language
        const res = await createEventOnOutlookCalendar(form.meet_title,form.date,form.start_period,form.end_period,emails,lang)
        
        microsoft_event_id = res.id

      }
      
      //delete a agenda criada no outlook calendar e segue o fluxo
      if(currentColab.meet_information && currentColab?.meet_information.platform && currentColab?.meet_information.event_id && currentColab?.meet_information.platform !== form.platform && form.platform === 'google') {
        await deleteEventOnOutlookCalendar(currentColab?.meet_information.event_id)
      }
      //cria o evento no outlook calendar e seta o event_id no form.
      if(!currentColab.meet_information && form.platform === 'microsoft') {
        
        const res_emails = await api.get(`/performance-assessment/feedback/get-email_list?users=${currentColab.machine_users_id},${form.organizer_id}`, {headers: authHeader()}) 
        if (res_emails.status !== 200) {
          throw new Error('Erro to create feedback')
        }
        const emails = res_emails.data || []
        // title,date,start_period,end_period,emails,language
        const res = await createEventOnOutlookCalendar(form.meet_title,form.date,form.start_period,form.end_period,emails,lang)
        
        microsoft_event_id = res.id
      }
      //update o evento no outlook calendar e seta o event_id no form.
      if(currentColab.meet_information && form.platform === 'microsoft' && currentColab?.meet_information.event_id) {
        
        const res_emails = await api.get(`/performance-assessment/feedback/get-email_list?users=${currentColab.machine_users_id},${form.organizer_id}`, {headers: authHeader()}) 
        if (res_emails.status !== 200) {
          throw new Error('Erro to update feedback')
        }
        const emails = res_emails.data || []
        // title,date,start_period,end_period,emails,language
        const res = await updateEventOnOutlookCalendar(currentColab?.meet_information.event_id,form.meet_title,form.date,form.start_period,form.end_period,emails,lang)
        
        microsoft_event_id = res.id
      }
      
      setIsSubmitting(true)
      const res = await api.put(`/performance-assessment/feedback`, {...form, start_period: form.start_period+":00", end_period: form.end_period+":00", evaluated_id: currentColab.evaluated_id,event_id:microsoft_event_id}, {headers: authHeader()}) 
      if (res.status !== 200) {
        throw new Error('Erro to create feedback')
      }
      getFeedbackData()  
      handleCloseNewFeedbackModal()
      alertActions.success(form?.id ? t('performance_assessment.messages.feedback_updated_success') : t('performance_assessment.messages.feedback_scheduled_success'))
      setIsSubmitting(false)
    } catch (error) {
      console.log("Error on function `createNewFeedback` : ",error)
      setIsSubmitting(false)
      alertActions.error(form?.id ? t('performance_assessment.messages.feedback_updated_error') : t('performance_assessment.messages.feedback_scheduled_error')) 
    }
  }

  function handleCloseNewFeedbackModal() {
    closeNewFeedbackModal()
    resetForm()
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && submitted) {
      createNewFeedback()
    } else {
      setSubmitted(false)
    }
  }, [formErrors, submitted])

  useEffect(() => {
    if(currentColab && currentColab.meet_information) {
      const {id, meet_title, date, start_period, end_period, organizer_id,platform,event_id} = currentColab.meet_information
      setForm({
        id: id || null,
        meet_title: meet_title || null,
        date: date ? dayjs(date).format('YYYY-MM-DD') : null,
        start_period: start_period ? String(start_period).slice(0, 5) : null,
        end_period: end_period ? String(end_period).slice(0, 5) : null,
        organizer_id: organizer_id || ((currentColab?.manager && currentColab?.manager?.responsible_id) ? currentColab?.manager.responsible_id : 'none'),
        platform:platform ? platform : null,
        event_id: event_id ? event_id : null
      })
    }
  }, [currentColab])

  const getIntegrations = async () => {
    try {
      const response = await api.get(`integrations/google-calendar/my-integrations`, { headers: authHeader() })
      if(response.status !== 200) {
        throw new Error('Error to load data my integrations')
      }
      const { data } = response
      setIntegrations({
        google: data.find(i => i.platform === 'google'),
        microsoft: data.find(i => i.platform === 'microsoft'),
      })
    }catch (error) {
      console.log(error)
    }
  }
  
  useEffect(() => {
    getIntegrations()
  }, [])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openNewFeedbackModal}
      onClose={() => !isSubmitting && handleCloseNewFeedbackModal()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: '#205B6F', opacity: 0.8 }
      }}
      sx={{ border: 'none' }}
      style={{ outline: 0 }}
    >
      <Slide direction="down" in={openNewFeedbackModal} style={{ outline: 0 }} mountOnEnter unmountOnExit>
        <Box
          display="flex"
          bgcolor="#fff"
          flexDirection="column"
          zIndex={2}
          py="28px"
          px="26px"
          borderRadius="8px"
          gap="26px"
          width={380}
          component="form"
          noValidate
          autoComplete='off'
        >
          <Typography component="h4" fontSize={16} color="#205B6F" fontWeight={500}>
            {form?.id ? t("performance_assessment.page_feedback.modal_new_feedback.title_edit") : t("performance_assessment.page_feedback.modal_new_feedback.title_create")}
          </Typography>

          <Box display="flex" flexDirection="column" gap={'18px'}>

            <TextField
              disabled
              fullWidth
              id="outlined-disabled"
              label={t("performance_assessment.page_feedback.modal_new_feedback.colaborator")}
              defaultValue={currentColab?.evaluated_name}
              sx={{ flex: '1', bgcolor: '#F5F6F9' }}
            />

            <FormControl variant="outlined">
              <InputLabel>{t("performance_assessment.page_feedback.modal_new_feedback.organizer")}</InputLabel>
              <Select
                native
                className={classes.select}
                variant='outlined'
                name="organizer_id"
                label={t("performance_assessment.page_feedback.modal_new_feedback.organizer")}
                error={formErrors.organizer_id}
                // helperText={formErrors.organizer_id}
                value={form.organizer_id}
                onChange={handleChange}
                style={{ fieldset: { border: '1px solid #DEE3E9CC' }, div: { color: '#4F7091' } }}
              >
                <option value="none" disabled>
                  {t("performance_assessment.page_feedback.modal_new_feedback.select")}
                </option>
                {teammates?.length > 0 && teammates?.filter(item => item?.id !== currentColab?.machine_users_id)?.map(teammate => (
                  <option key={teammate.id} value={teammate.id}>
                    {teammate.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            <TextField
              variant='outlined'
              fullWidth
              value={form.meet_title}              
              label={t("performance_assessment.page_feedback.modal_new_feedback.title_field")}
              name='meet_title'
              onChange={handleChange}
              sx={{ fieldset: { border: '1px solid #DEE3E9CC' } }}
              error={formErrors.meet_title}
              // helperText={formErrors.meet_title}
            />

            <Box display="flex">
              <LocalizationProvider dateAdapter={AdapterDayjs} locale={lang}>
                <DatePicker
                  value={form.date}
                  inputFormat={lang === 'en' ? "MM/DD/YYYY" : "DD/MM/YYYY"}
                  onChange={(newValue) => {
                    setForm({
                      ...form,
                      date: moment(newValue.$d).format('YYYY-MM-DD')
                    })
                    setFormErrors({ ...formErrors, date: false })
                  }}
                  minDate={dayjs().format('YYYY-MM-DD')}
                  label={t("performance_assessment.page_feedback.modal_new_feedback.date")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      fullWidth
                      sx={{ fieldset: { border: '1px solid #DEE3E9CC' } }}                      
                      error={formErrors.date}
                      // helperText={formErrors.date}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Box display="flex" gap={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  disabled={!form.date}
                  label={t("performance_assessment.page_feedback.modal_new_feedback.start_time")}
                  minTime={dayjs('2022-01-01 ' + (moment().format('YYYY-MM-DD') === form.date ? moment().format('HH:mm') : '00:00'))}
                  maxTime={form.end_period ? dayjs('2022-01-01 ' + form.end_period).subtract(10, 'minutes') : dayjs('2022-01-01 23:59')}
                  ampm={false}
                  views={['hours', 'minutes']}
                  inputFormat="HH:mm"
                  mask="__:__"
                  value={dayjs('2022-01-01 ' + form.start_period)}
                  onChange={(newValue) => {
                    setForm({
                      ...form,
                      start_period: convertToTime(newValue)
                    })
                    setFormErrors({ ...formErrors, start_period: false })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant='outlined'
                      error={formErrors.start_period}
                      // helperText={formErrors.start_period}
                      sx={{ fieldset: { border: '1px solid #DEE3E9CC' } }}
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  disabled={!form.date}
                  label={t("performance_assessment.page_feedback.modal_new_feedback.end_time")}
                  minTime={dayjs('2022-01-01 ' + form.start_period).add(10, 'minutes')}
                  ampm={false}
                  views={['hours', 'minutes']}
                  inputFormat="HH:mm"
                  mask="__:__"
                  value={dayjs('2022-01-01 ' + form.end_period)}
                  onChange={(newValue) => {
                    setForm({
                      ...form,
                      end_period: convertToTime(newValue)
                    })
                    setFormErrors({ ...formErrors, end_period: false })
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant='outlined'
                      error={formErrors.end_period}
                      // helperText={formErrors.end_period}
                      sx={{ fieldset: { border: '1px solid #DEE3E9CC' } }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Box display="flex">
              {
                integrations.google || integrations.microsoft
                ?
                <FormControl component="fieldset">
                  <FormLabel component="legend">{t('performance_assessment.page_feedback.modal_new_feedback.choose_integration')}</FormLabel>
                  <FormGroup row aria-label="platform">
                    {
                    integrations.google && 
                      <FormControlLabel 
                        color="primary" 
                        control={<BefectiveCheckbox
                          value="google"
                          checked={form.platform === 'google'}
                          onChange={(e) => setForm({ ...form, platform: e.target.checked ? 'google' : null })}
                          color="primary"
                        />} 
                        label="Google Calendar" 
                        style={{ color: form.platform === 'google' ? '#22BEAD': '#4F7091' }}
                      />
                    }
                    {
                    integrations.microsoft && 
                      <FormControlLabel 
                        color="primary" 
                        control={<BefectiveCheckbox
                          value="microsoft"
                          checked={form.platform === 'microsoft'}
                          onChange={(e) => setForm({ ...form, platform: e.target.checked ? 'microsoft' : null })}
                          color="primary"
                        />} 
                        label="Microsoft Calendar" 
                        style={{ color: form.platform === 'microsoft' ? '#22BEAD': '#4F7091' }}
                      />
                    }
                  </FormGroup>
                </FormControl>
                :
                <button className={style.btnConfigIntegrations} type='button' onClick={() => history.push('/global-settings/integrations')}>
                  <PlugsConnected size={18} color='#106276'/> <span>{t('performance_assessment.page_feedback.modal_new_feedback.config_integrations')}</span>
                </button>
              }
            </Box>

          </Box>

          <Box display="flex" alignItems="center" justifyContent="flex-end" gap="4px">
            {
              form?.id && (
                <Button
                  className={style.btnCancelFeedback}
                  onClick={handleClickConfirmCancelFeedback}
                  disabled={isDeleting || isSubmitting}
                >
                  {isDeleting ? (
                    <CircularProgress size={14} />
                  ) : (
                    <Typography component="span" fontSize={12} color='#FC6662' textTransform="initial" fontWeight={500}>{t("performance_assessment.page_feedback.modal_new_feedback.btn_cancel_feedback")}</Typography>
                  )}
                  
                </Button>
              )
            }
            <Button
              type='button'
              className={style.btnClose}
              onClick={handleCloseNewFeedbackModal}
              disabled={isDeleting || isSubmitting}
            >
              <Typography component="span" fontSize={12} color='#187DFF' textTransform="initial" fontWeight={500}>{t("performance_assessment.page_feedback.modal_new_feedback.btn_close")}</Typography>
            </Button>
            <Button
              className={style.save}
              onClick={handleSubmit}
              disabled={isDeleting || isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={14} />
              ) : (
                <Typography
                  component="span"
                  fontSize={12}
                  color='#fff'
                  textTransform="initial"
                  fontWeight={500}
                >
                  {t("performance_assessment.page_feedback.modal_new_feedback.btn_save")}
                </Typography>
              )}
            </Button>
          </Box>
          {/* Start Confirm Dialog Cancel Feedback */}
            <PopoverConfirm
              idConfirm={idConfirmCancelFeedback}
              openConfirm={openConfirmCancelFeedback}
              anchorEl={anchorElConfirmCancelFeedback}
              handleClose={handleCloseConfirmCancelFeedback}
              handleConfirm={handleCancelFeedback}
              title={t('performance_assessment.messages.cancel_feedback')}
            />
          {/* End Confirm Dialog Cancel Feedback */}
        </Box>
      </Slide>
    </Modal>
  )
}
