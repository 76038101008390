import { Badge, CircularProgress, Dialog, Drawer, IconButton, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { Close, Mail, MailOutline, Message, MessageOutlined, QuestionAnswerOutlined } from '@material-ui/icons';
import axios from 'axios';
import Moment from 'moment';
import { BellSimple, EnvelopeSimple, Gear } from 'phosphor-react';
import React, { useEffect, useRef, useState } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { NavLink, useHistory, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import utf8 from 'utf8';

import { alertActions, backfectiveActions } from '../../_actions';
import { api } from '../../_helpers/api';
import IconHelp from '../_icons/IconHelp';

import { authHeader, befectiveFormatDate, getDynamicRedirectUrl } from '../../_helpers';
import { MenuMagic } from './MenuMagic';
import { MenuSwitch } from './MenuSwitch';
import { useMessagesCount } from '../../contexts/MessagesCountContext';
// import './toolbar.scss';

const useStyles = makeStyles((theme) => ({
	badge: {
		fontSize: '10px',
		minWidth: '15px',
		width: '15px',
		height: '15px'
	},
}));

function Toolbar(props) {
	const { t, i18n } = useTranslation('common');
	const classes = useStyles();

	const { companyPurpose, groupName } = props || ""
	const { backfectiveActions, alertActions, setOpenModalHelp, titleAssessment } = props
	const { userDetails, showToolbarOptions  } = props
	const { organizations } = props || []
	const showMenu = userDetails.showMenu
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const [anchorEl2, setAnchorEl2] = useState(null);
	const open2 = Boolean(anchorEl2);

	const [toggleDrawerAlerts, setToggleDrawerAlerts] = useState(false);
	const [magicAgentHTML, setMagicAgentHTML] = useState([])
	const [userMenu, setSate] = useState(false);
	const [magicAgent, setMagicAgent] = useState(false);

	const [todayAlerts, setTodayAlerts] = useState([])
	const [yesterdayAlerts, setYesterdayAlerts] = useState([])
	const [oldAlerts, setOldAlerts] = useState([])
	const [totalAlerts, setTotalAlerts] = useState(0);
	const [totalAlertsNotRead, setTotalAlertsNotRead] = useState(0);
	const [openDialog, setOpenDialog] = useState(false);
	const [dialogAlert, setDialogAlert] = useState({});
	const [offSet, setOffset] = useState(0);
	const [isLoadingAlerts, setIsLoadingAlerts] = useState(false);
	const [isAllAlertsLoaded, setIsAllAlertsLoaded] = useState(false);
	const [notifications, setNotifications] = useState([])
	const [totalNotificationsNotRead, setTotalNotificationsNotRead] = useState(0)

	const { objectiveName } = props || null

	const history = useHistory();

	let changed = props.changed || false

	let breadcrub = null;

	let path = window.location.pathname.split("/");

	const { messagesNotRead, getMessagesUnRead, solicitations, getOpenSolicitations } = useMessagesCount()

	// Pega primeiro nível de breadcrub

	if (path.includes('productivity')) {
		breadcrub = props.breadcrumb
	} else {
		switch (path[1]) {
			case 'rooms':
				switch (path[2]) {
					case 'management':
						breadcrub = t('breadcrub.rooms_management');
						break;
					case 'reports-colabs':
						breadcrub = t('breadcrub.reports');
						break;
					case 'my-reservations':
						breadcrub = t('breadcrub.my_reservations');
						break;
					case 'attributes':
						breadcrub = t('spaces.settings');
						break;
					case 'maps':
						breadcrub = t('breadcrub.maps');
						break;
					default:
						breadcrub = t('spaces.reservation_room')
						break;
				}
				break;
			case 'project_management':
				switch (path[2]) {
					case 'home':
						breadcrub = t('breadcrub.project_management.home');
						break;
					case 'workspaces':
						breadcrub = t('breadcrub.project_management.workspaces');
						break;
					case 'archived-boards':
						breadcrub = t('breadcrub.project_management.archived_boards');
						break;
					case 'reports':
						breadcrub = t('breadcrub.reports');
						switch (path[3]) {
							case 'boards':
								breadcrub += " / "+t('breadcrub.project_management.boards');
								break;
							case 'timeline':
								breadcrub += " / "+t('breadcrub.project_management.timeline');
								break;
							default:
								break
						}
						break;
					default:
						breadcrub = ''
						break;
				}
				break;
			case 'checkin':
				switch (path[2]) {
					case 'control':
						breadcrub = t('breadcrub.control_points');
						break;
					case 'reports':
						breadcrub = t('breadcrub.reports');
						break;
					case 'history':
						breadcrub = t('menu.historical');
						break;
					default:
						breadcrub = t('breadcrub.home') + " " + utf8.decode(userDetails.name);
						break;
				}
				break;
			case 'productive':
				switch (path[2]) {
					case 'reports':
						breadcrub = t('breadcrub.reports');
						break;
					case 'activities':
						breadcrub = t('breadcrub.activities');
						break;
					case 'historical':
						breadcrub = t('breadcrub.historical');
						break;
					default:
						breadcrub = t('breadcrub.home') + " " + utf8.decode(userDetails.name);
						break;
				}
				break;
			case 'settings':
				breadcrub = t('breadcrub.settings');
				break;
			case 'download':
				breadcrub = t('breadcrub.download');
				break;
			case 'calls-page':
				breadcrub = t('breadcrub.calls_page_of')
				break;
			case 'goals':
				breadcrub = t('breadcrub.goals');
				break;
			case 'messages':
				breadcrub = t('breadcrub.messages')
				break;
			case 'checkin':
				breadcrub = t('breadcrub.home') + " " + utf8.decode(userDetails?.name);
				break;
			case 'performance-assessment':
				breadcrub = t('menu.performance_assessment.assessments');
				break;
			default:
				break
		}
	}


	// Pega segundo nível de breadcrub
	switch (path[1]) {
		case 'checkin':
			switch (path[3]) {
				case 'overtime':
					breadcrub += " / " + t('breadcrub.overtime')
					break;
				case 'map':
					breadcrub += " / " + t('breadcrub.map')
					break;
				case 'overview':
					breadcrub += " / " + t('breadcrub.overview')
					break;
				case 'solicitations':
					breadcrub += " / " + t('breadcrub.solicitations')
					break;
				default:
					break;
			}
			break;
		case 'productive':
			switch (path[3]) {
				case 'realtime':
					breadcrub += " / " + t('breadcrub.realtime')
					break;
				case 'highlights':
					breadcrub += " / " + t('breadcrub.highlights')
					break;
				case 'timegoal':
					breadcrub += " / " + t('standards.working_day')
					break;
				case 'workgoal':
					breadcrub += " / " + t('standards.performance')
					break;
				case 'mood':
					breadcrub += ` / ${t('breadcrub.mood')} ${path[4] && path[4] === 'details' ? (groupName !== undefined ? ' / ' + groupName : '') : ''}`
					break;
				case 'value-hour':
					breadcrub += ` / ${t('breadcrub.value_hour')} ${path[4] && path[4] === 'details' ? (groupName !== undefined ? ' / ' + groupName : '') : ''}`
					break;
				case 'teams':
					breadcrub += " / " + t('breadcrub.teams')
					break;
				case 'teammates':
					breadcrub += " / " + t('breadcrub.teammates')
					break;
				case 'inactivity':
					breadcrub += " / " + t('breadcrub.inactivity')
					break;
				case 'advanced':
					breadcrub += " / " + t('breadcrub.advanced')
					break;
				case 'periodic':
					breadcrub += " / " + t('breadcrub.periodic_reports')
					break;
				case 'scheduled-reports':
					breadcrub += " / " + t('breadcrub.periodic_reports')
					break;
				case 'vacation':
					breadcrub += " / " + t('breadcrub.vacation')
					break;
				case 'absenteeism':
					breadcrub += " / " + t('breadcrub.absenteeism')
					break;
				case 'potential-productive':
					breadcrub += " / " + t('breadcrub.potential_productive')
					break;
				case 'fatigue':
					breadcrub += " / " + t('breadcrub.fatigue')
					break;
				case 'checklists':
					breadcrub += " / " + t('breadcrub.checklists')
					break;
				case 'transactional':
					breadcrub += " / " + t('breadcrub.transactional')
					break;
				case 'collection_monitoring':
					breadcrub += " / " + t('breadcrub.collection_monitoring')
					break;
				default:
					break;
			}
			break;
		case 'performance-assessment':
			if(!path[2]){
				breadcrub = t('menu.performance_assessment.dashboard')
				break
			}
			switch (path[2]) {
				case 'created':
					breadcrub +=" / "+ t('menu.performance_assessment.register')
					break;
				case 'assess':
					breadcrub +=" / "+ t('menu.performance_assessment.pending')
					break;
				case "new":
					breadcrub = t('breadcrub.manager_assessments')+" / "+ t('breadcrub.new_assessment')
					break;
				case 'dashboard':
					breadcrub = t('menu.performance_assessment.dashboard')
					break;
				case 'widgets':
					breadcrub = 'Dashboard / Widgets'
					break;
				case 'pre-settings':
					switch (path[3]) {
						case 'skills':
							breadcrub = t('menu.performance_assessment.pre_settings')+" / "+t('menu.performance_assessment.skills')
							break;
						case 'scales':
							breadcrub = t('menu.performance_assessment.pre_settings')+" / "+t('menu.performance_assessment.scales')
							break;
						case 'indicators':
							breadcrub = t('menu.performance_assessment.pre_settings')+" / "+t('menu.performance_assessment.result_indicators')
							break;
						default:
							break;
					}
					break;
				case "details":
					breadcrub += `${titleAssessment ? " / " + titleAssessment : ""}`
					break;
				case 'feedback':
					breadcrub = t('menu.performance_assessment.feedback')
					switch (path[3]) {
						case 'report':
							breadcrub += " / " + t('menu.performance_assessment.report')
							break;
						case 'action-plan':
							breadcrub += " / " + t('menu.performance_assessment.action_plan')
							break;
						default:
							break;
					}				
					break;
				case 'action-plan':
					breadcrub = t('menu.performance_assessment.action_plan')
					switch (path[3]) {
						case 'create':
							breadcrub += " / " + t('menu.performance_assessment.new_action_plan')
							break;
						case 'details':
							breadcrub += " / " + t('menu.performance_assessment.action_plan_details')
							break;
						default:
							break;
					}
					break;
				case 'reports':
					breadcrub = t('menu.performance_assessment.reports')
					switch (path[3]) {
						case 'assessments':
							breadcrub += " / " + t('menu.performance_assessment.assessments')
							break;
						default:
							break;
					}
					break;
				default:
					break;
			}
			break;
		case ('settings'):
			switch (path[2]) {
				case 'user':
					breadcrub += " / " + t('breadcrub.user')
					break;
				case 'company':
					breadcrub += " / " + t('breadcrub.company')
					break;
				case 'teams':
					breadcrub += " / " + t('breadcrub.teams')
					break;
				case 'checkin':
					breadcrub += " / " + t('menu.check_in_remote')
					break;
				case 'users':
					breadcrub += " / " + t('menu.users')
					break;
				case 'extra-collections':
					breadcrub += " / " + t('menu.extra_collections')
					break;
				case 'daysoff':
					breadcrub += " / " + t('menu.daysoff')
					break;
				case 'profiles':
					breadcrub += " / " + t('menu.profiles')
					break;
				case 'moods':
					breadcrub += " / " + t('menu.moods_settings')
					break;
				case 'activities':
					breadcrub += " / " + t('menu.activities')
					break;
				default:
					break;
			}
			break;
		default:
			break
		// case 'averagestart':
		// 	breadcrub += " / " + t('breadcrub.averagestart')
		// 	break;

	}

	const ref = useRef();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClick2 = (event) => {
		setAnchorEl2(event.currentTarget);
	};

	const handleClose2 = () => {
		setAnchorEl2(null);
	};

	const closeDrawerAlerts = async () => {
		setToggleDrawerAlerts(false);
		await getNotificationsNotRead()
	}

	async function getAlerts(newOffset = 0) {
		try {
			setIsLoadingAlerts(true)
			setOffset(offSet + newOffset)

			const response = await api.get(`/alerts?organization_id=${userDetails.organizationId}&user_id=${userDetails.id}&language=${userDetails.language}&offset=${newOffset + offSet}`);

			if (
				response.data.todayAlerts.length > 0 ||
				response.data.yesterdayAlerts.length > 0 ||
				response.data.oldAlerts.length > 0
			) {
				setTodayAlerts(oldState => [...oldState, ...response.data.todayAlerts])
				setYesterdayAlerts(oldState => [...oldState, ...response.data.yesterdayAlerts])
				setOldAlerts(oldState => [...oldState, ...response.data.oldAlerts])
				setTotalAlerts(oldState => oldState + response.data.totalAlerts)
				setTotalAlertsNotRead(response.data.totalAlertsNotRead)
			} else {
				setIsAllAlertsLoaded(true)
			}

			setIsLoadingAlerts(false)
		} catch (error) {
			console.log(error.response)
			setIsLoadingAlerts(false)
			//alertActions.error(t('alerts.error.get_alerts'))
		}
	}

	const createReadAlert = async (alert) => {
		if (alert.read) {
			return
		}

		try {
			const myIpResponse = await axios.get('https://api.ipify.org?format=jsonp?callback=?')

			await api.post('/alerts/create_read', {
				organization_id: userDetails.organizationId,
				user_id: userDetails.id,
				alert_id: alert.ID,
				ip: myIpResponse.data
			})

			setTotalAlertsNotRead(totalAlertsNotRead - 1)

			const isTodayAlert = todayAlerts.find(item => item.ID === alert.ID)

			const isYesterdayALert = yesterdayAlerts.find(item => item.ID === alert.ID)

			const isOldAlert = oldAlerts.find(item => item.ID === alert.ID)

			if (isTodayAlert) {
				const alertsFiltered = todayAlerts.filter(item => item.ID !== alert.ID)
				const todayAlertsUpdated = [...alertsFiltered, {
					...isTodayAlert,
					read: true
				}]

				setTodayAlerts(todayAlertsUpdated)
			}

			if (isYesterdayALert) {
				const alertsFiltered = yesterdayAlerts.filter(item => item.ID !== alert.ID)
				const yesterdayAlertesUpdated = [...alertsFiltered, {
					...isYesterdayALert,
					read: true
				}]

				setYesterdayAlerts(yesterdayAlertesUpdated)
			}

			if (isOldAlert) {
				const alertsFiltered = oldAlerts.filter(item => item.ID !== alert.ID)
				const oldAlertsUpdated = [...alertsFiltered, {
					...isOldAlert,
					read: true
				}]

				setOldAlerts(oldAlertsUpdated)
			}

		} catch (error) {
			console.log(error)
			//alertActions.error(t('alerts.error.set_read'))
		}
	}

	const handleClickOpenDialog = (alert) => {
		setDialogAlert(alert)
		createReadAlert(alert)
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};


	async function getNotifications() {
		try {
			setIsLoadingAlerts(true)

			const response = await api.get(`/news/small-bell`, { headers: authHeader() });

			if (response.status !== 200) {
				throw new Error("Error to get notifications")
			}

			setNotifications(response.data)
			setIsLoadingAlerts(false)
		} catch (error) {
			console.log(error.response)
			setIsLoadingAlerts(false)
		}
	}

	async function getNotificationsNotRead() {
		try {
			const response = await api.get(`/news/small-bell/total`, { headers: authHeader() });

			if (response.status !== 200) {
				throw new Error("Error to get notifications not read")
			}
			setTotalNotificationsNotRead(parseInt(response.data.total || 0))
		} catch (error) {
			console.log(error.response)
		}
	}

	const handleOpenDrawerNotification = async () => {
		setToggleDrawerAlerts(true)
		await getNotifications()
	}



	// useEffect(() => {

	// 	if (organizations) {
	// 		let html = []

	// 		for (let org of organizations) {

	// 			if (Moment(org.validation_date).isAfter(Moment(), "day")) {
	// 				html.push(<li key={"org" + org.id} onClick={() => backfectiveActions.magicAgent(org.id)}>
	// 					<span>{zeroPad(org.id, 4)} </span>{org.name}</li>)
	// 			}


	// 		}

	// 		setMagicAgentHTML(html)

	// 	}


	// }, [organizations])


	useEffect(() => {

		if (changed == 'error') {

		}

		if (changed == 'success') {

			setMagicAgent(false)
			history.push("/productive")
			window.location.reload();
		}

	}, [changed])

	useEffect(() => {

		if (userDetails.isMagicAgent) {
			backfectiveActions.getOrganizations()
		}

		i18n.changeLanguage(userDetails.language)
		getNotificationsNotRead()
		getAlerts()
		getMessagesUnRead(userDetails?.organizationId, userDetails?.mu_id)
	}, [])


	const generateBreadcrubLinks = (breadcrub) => {
		const currentPath = window.location.pathname.split("/")

		const sliceArray = currentPath.slice(2, currentPath.length)

		if (sliceArray.length === 0) return breadcrub

		if (['productive', 'performance-assessment'].includes(currentPath[1]) && currentPath[2] === 'reports') {
			const arrayBreadcrubs = breadcrub.split('/')
			const auxList = arrayBreadcrubs
				.map((item, index) => ({ label: item, path: `/${currentPath[1]}/${sliceArray.slice(0, index + 1).join('/')}` }))

			const arrayFull = []

			auxList.forEach((item, index) => {
				if (index !== arrayBreadcrubs.length - 1) {
					arrayFull.push(
						item,
						{
							label: '/',
							path: null
						}
					)
				} else {
					arrayFull.push(item)
				}
			});
			return <div>{arrayFull.map(item => (item.path !== null ? <span key={item.path} className={!['/productive/reports/mood/details', '/productive/reports/value-hour/details'].includes(item.path) ? 'breadcrumbLink' : ''} onClick={() => !['/productive/reports/mood/details', '/productive/reports/value-hour/details'].includes(item.path) && history.push(item.path)}>{String(item.label).trim()}</span> : <span key={item.label}> {item.label} </span>))}</div>
		} else {
			return breadcrub
		}
	}

	return (
		<div className="mainToolbar">
			{objectiveName ? (
				<ul className="breadCrub" style={{ lineHeight: '18px', alignItems: 'auto' }}>
					<li style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
						{
							objectiveName.length <= 60
								?
								<span>
									<NavLink to={getDynamicRedirectUrl(userDetails, "/goals")} style={{
										textDecoration: 'none',
										color: '#98ACC1'
									}}>
										{breadcrub}
									</NavLink> {" / " + objectiveName}
								</span>
								:
								<span><NavLink to={getDynamicRedirectUrl(userDetails, "/goals")} style={{
									textDecoration: 'none',
									color: '#98ACC1'
								}}>
									{breadcrub}
								</NavLink>{" / " + objectiveName.substring(0, 60) + '...'}</span>
						}

					</li>
				</ul>
			) : (
				<ul className="breadCrub">
					<li>
						{generateBreadcrubLinks(breadcrub)}
					</li>
				</ul>
			)}

			{userDetails?.roleName === 'machine_user' ? (
				path[4] === "goals" && !path[5] && companyPurpose !== undefined
					?
					<div>
						{
							companyPurpose.length > 70 ?
								<Tooltip
									title={companyPurpose}
								>
									<p className='purpose'>{String(companyPurpose).slice(0, 70) + "..."}</p>
								</Tooltip>
								:
								<p className='purpose'>{String(companyPurpose)}</p>

						}
					</div>
					: (<></>)
			) : (
				path[1] === "goals" && !path[2] && companyPurpose !== undefined
					?
					<div>
						{
							String(companyPurpose).length > 70 ?
								<Tooltip
									title={companyPurpose}
								>
									<p className='purpose'>{String(companyPurpose).slice(0, 70) + "..."}</p>
								</Tooltip>
								:
								<p className='purpose'>{String(companyPurpose)}</p>

						}
					</div>
					: (<></>)
			)}


			<div className="user">         

				{
					(showMenu.find(item => item.name === 'messages') && (!showToolbarOptions || showToolbarOptions.messages === true)) &&

					<div
						className='notifications_icon'
						onClick={() => history.push('/messages')}
					>
						<Badge
							badgeContent={messagesNotRead}
							color="secondary"
							overlap="rectangular"
							classes={{ badge: classes.badge }}
						>
							<EnvelopeSimple size={24} weight="regular" />
						</Badge>
					</div>
				}

				{(userDetails?.roleName !== 'machine_user' && (!showToolbarOptions || showToolbarOptions.bell === true)) && (
					<div
						className='notifications_icon'
						onClick={handleOpenDrawerNotification}
					>
						<Badge
							badgeContent={totalAlertsNotRead + totalNotificationsNotRead}
							color="secondary"
							overlap="rectangular"
							classes={{ badge: classes.badge }}
						>
							<BellSimple size={24} weight="regular" />
						</Badge>
					</div>
				)}

				{
					(!showToolbarOptions || showToolbarOptions.switch === true) &&
					<div className='switchBtn'>
						<MenuSwitch userDetails={userDetails} />
					</div>
				}

				{(path[1] === 'checkin' && !path[2]) && (
					<div className="help" onClick={() => setOpenModalHelp(true)}>
						<IconHelp />
					</div>
				)}
				
				<div
					className={'notifications_icon'}
					onClick={() => {
						const pathSplit = history.location.pathname.split("/");
            if(!pathSplit.includes("global-settings")){
              localStorage.setItem("pathToBackPage", history.location.pathname);
            }
						history.push("/global-settings")
					}}
				>
					<Gear size={24} weight="regular" />
				</div>

				<div className={`${(!showToolbarOptions || showToolbarOptions.avatar === true) ? 'avatar' : 'avatar-simple'}`} onClick={(e) => (!showToolbarOptions || showToolbarOptions.avatar === true) && handleClick(e)}>
					<img
						src={`https://ui-avatars.com/api/?background=${userDetails.org_client_type === 'poc' ? 'FC6662' : '24677E'}&color=ffffff&name=` + encodeURI(userDetails.name)} alt="avatar" />
				</div>

			</div>

			<Drawer anchor='right' open={toggleDrawerAlerts} onClose={closeDrawerAlerts}>

				<div style={{ position: 'absolute', right: 4, top: 4 }}>
					<IconButton aria-label="close" onClick={closeDrawerAlerts}>
						<Close fontSize="medium" />
					</IconButton>
				</div>

				<div className="drawer-container-alerts">
					<header>
						<h2 style={{ marginBottom: 26 }}>
							{t('alerts.title')}
						</h2>
					</header>

					<main className="main-content-drawer-alerts">

						{notifications.length > 0 && (
							<div className='alerts-container'>
								<h3>{t('alerts.notifications')}</h3>
								{notifications.map(notification => (
									<div
										key={`notification-${notification.id}`}
										className={`${!notification.is_a_new_one ? 'alerts-info disableHover read-alert' : 'alerts-info disableHover'} ${notification?.redirect_url !== null ? 'linkHover' : ''}`}										
										onClick={notification.redirect_url !== null ? () => userDetails?.profile_key !== "collaborator" ? history.push(`${notification.redirect_url?.admin}`) : history.push(`${notification.redirect_url?.colab}`)  : () => {}}
										style={{ cursor: notification?.redirect_url !== null ? 'pointer' : '' }}
									>
										<div className='alerts-title'>
											{/* {
												String(notification[`body_${userDetails.language || i18n.language}`]).length > 100
													?
													<Tooltip
														title={<Typography component={'span'} variant='body2' >{notification[`body_${userDetails.language || i18n.language}`]}</Typography>}
													>
														<strong style={{ color: !notification.is_a_new_one ? '#98ACC1' : '#24677E' }}>
															{String(notification[`body_${userDetails.language || i18n.language}`]).slice(0, 100) + ' [...]'}
														</strong>
													</Tooltip>
													: */}
											<strong style={{ color: !notification.is_a_new_one ? '#98ACC1' : '#24677E' }}>
												{String(notification[`body_${userDetails.language || i18n.language}`])}
											</strong>
											{/* } */}
										</div>
										<div className='alerts-message'>
											<span style={{ color: !notification.is_a_new_one ? '#98ACC1' : '#24677E' }}>
											{befectiveFormatDate(notification.created_at,userDetails.language || i18n.language,'c')}
											</span>
									  </div>
									</div>
								))}
							</div>
						)}

						{todayAlerts.length > 0 && (
							<div className='alerts-container'>
								<h3>{t('alerts.today')}</h3>

								{todayAlerts.map(item => (
									<div
										key={item.ID}
										className={item?.read ? 'alerts-info read-alert' : 'alerts-info'}
										onClick={() => handleClickOpenDialog(item)}
									>
										<div className='alerts-title'>
											<Avatar
												name={item.display_name}
												size={15}
												round
												color={item?.read ? '#98ACC1' : '#24677E'}
											/>
											<strong style={item?.read && { color: '#98ACC1' }}>
												{item.post_title}
											</strong>
										</div>
										<div className='alerts-message'>
											<span style={item?.read && { color: '#98ACC1' }}>
												{Moment(new Date(item.post_date)).format('DD/MM/yyyy - HH:MM')}
											</span>
										</div>
									</div>
								))}
							</div>
						)}

						{yesterdayAlerts.length > 0 && (
							<div className='alerts-container'>
								<h3>{t('alerts.yesterday')}</h3>

								{yesterdayAlerts.map(item => (
									<div
										key={item.ID}
										className={item?.read ? 'alerts-info read-alert' : 'alerts-info'}
										onClick={() => handleClickOpenDialog(item)}
									>
										<div className='alerts-title'>
											<Avatar
												name={item.display_name}
												size={15}
												round
												color={item?.read ? '#98ACC1' : '#24677E'}
											/>
											<strong style={item?.read && { color: '#98ACC1' }}>
												{item.post_title}
											</strong>
										</div>
										<div className='alerts-message'>
											<span style={item?.read && { color: '#98ACC1' }}>
												{Moment(new Date(item.post_date)).format('DD/MM/yyyy - HH:MM')}
											</span>
										</div>
									</div>
								))}
							</div>
						)}

						{oldAlerts.length > 0 && (
							<div className='alerts-container'>
								<h3>{t('alerts.old')}</h3>

								{oldAlerts.map(item => (
									<div
										key={item.ID}
										className={item?.read ? 'alerts-info read-alert' : 'alerts-info'}
										onClick={() => handleClickOpenDialog(item)}
									>
										<div className='alerts-title'>
											<Avatar
												name={item.display_name}
												size={15}
												round
												color={item?.read ? '#98ACC1' : '#24677E'}
											/>
											<strong style={item?.read && { color: '#98ACC1' }}>
												{item.post_title}
											</strong>
										</div>
										<div className='alerts-message'>
											<span style={item?.read && { color: '#98ACC1' }}>
												{Moment(new Date(item.post_date)).format('DD/MM/yyyy - HH:MM')}
											</span>
										</div>
									</div>
								))}
							</div>
						)}

						{((todayAlerts.length + yesterdayAlerts.length + oldAlerts.length) % 10 === 0) &&
							(
								!isAllAlertsLoaded && (
									<div className='show-more-alert'>
										{isLoadingAlerts ? (
											<CircularProgress size={20} />
										) : (
											<button onClick={() => getAlerts(10)}>
												{t('alerts.show_more')}
											</button>
										)}
									</div>
								)
							)}

					</main>
				</div>
			</Drawer>

			<Dialog onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" open={openDialog}>
				<div className='dialog-container-details'>
					<header>
						<div className='title'>
							<strong>{dialogAlert.post_title}</strong>
						</div>
						<div className='user-info'>
							<strong>{dialogAlert.display_name}</strong>
							<span>{Moment(new Date(dialogAlert.post_date)).format('DD/MM/yyyy HH:MM')}</span>
						</div>

						<IconButton
							aria-label="close"
							onClick={handleCloseDialog}
							style={{ position: 'absolute', top: '-5px', right: '0' }}
						>
							<Close fontSize="medium" />
						</IconButton>
					</header>

					<main className='main-content-details'>
						<p
							className='alert-message'
							dangerouslySetInnerHTML={
								{ __html: dialogAlert.post_content?.replaceAll('\n', '<br />') }
							}>
						</p>
					</main>
				</div>
			</Dialog>

			<MenuMagic
				anchorEl={anchorEl}
				open={open}
				handleClose={handleClose}
				userDetails={userDetails}
				handleClick2={handleClick2}
				open2={open2}
				anchorEl2={anchorEl2}
				handleClose2={handleClose2}
				organizations={organizations}
				backfectiveActions={backfectiveActions}
				isMuUser={userDetails?.roleName === 'collaborator' ? true : false}
				showLogin={false}
			/>
		</div>
	)
};


function mapStateToProps(state) {

	return {
		userDetails: state.authentication.userDetails,
		organizations: state.backfective.organizations,
		changed: state.backfective.changed
	}
}

function mapDispatchToProps(dispatch) {
	return {
		backfectiveActions: bindActionCreators(backfectiveActions, dispatch),
		alertActions: bindActionCreators(alertActions, dispatch)
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Toolbar));
