import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import TeamsSkeleton from '../../_components/settings/teams/TeamsSkeleton'
import DrawerInvite from '../../_components/settings/teams/DrawerInvite'
import DrawerEdit from '../../_components/settings/teams/DrawerEdit'
import { useTranslation } from "react-i18next";
import { alertActions, settingsActions, teamsActions } from '../../_actions';
import { teammatesActions } from '../../_actions';
import IconClose from '../../_components/_icons/IconClose';
import { api } from '../../_helpers/api';
import { authHeader, befectiveFormatDate } from '../../_helpers';
import FilterbarUsersSettings from '../../_components/_shared/FilterbarUsersSettings';
import StatsInfoHeader from '../../_components/users_settings/StatsInfoHeader';
import UsersTable from '../../_components/users_settings/UsersTable';
import { Box } from '@mui/material';
import Header from '../../_components/switch_page/Header';


function UsersSettingsPage(props) {
  const { t, i18n } = useTranslation('common');

  const id = 1

  const { teamsActions, teammatesActions, userDetails, alertActions, settingsActions, loadingTeams, loadingTeammates, filter } = props;

  const [drawer, setDrawer] = useState(false)
  const [isShowingDrawerInvite, setDrawerInvite] = useState(false)
  const [isShowingDrawerEdit, setDrawerEdit] = useState(false)
  const [inviteCurrent, setInviteCurrent] = useState()
  const [editCurrent, setEditCurrent] = useState()
  const [inactiveTeammates, setInactiveTeammates] = useState([])
  const [isTeamsSelected, setIsTeamsSelected] = useState(true)
  const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true)
  const [value, setValue] = useState('1');
  const [data, setData] = useState(null)
  const [selectedRows, setSelectedRows] = useState([]);
  const [fetching, setFetching] = useState(true)
  const [pageCount, setPageCount] = useState(0);
  const [pageCountRows, setPageCountRows] = useState(0);
  const [activeFilter, setActiveFilter] = useState(0);

  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };

  function openDrawer(data) {

    setDrawer(true)

    if (data.type == "invite") {
      setDrawerInvite(true)
      setDrawerEdit(false)
      setInviteCurrent(data)
      setEditCurrent()
    }

    if (data.type == "edit") {
      teammatesActions.getTeammate(data.id)
      setDrawerInvite(false)
      setDrawerEdit(true)
      setInviteCurrent()
      setEditCurrent(data)
      setValue("1")
    }

  }

  function closeDrawer() {
    setDrawer(false)
    setDrawerInvite(false)
    setDrawerEdit(false)
    setInviteCurrent(null)
    setEditCurrent(null)
  }

  async function getInactiveTeammates() {
    try {
      const response = await api.get(
        `/teammates/inactive_teammates?organization_id=${userDetails.organizationId}`,
        {
          headers: authHeader()
        }
      )

      setInactiveTeammates(response.data)
    } catch (error) {
      console.log(error)
      alertActions.error(t('teams.get_inactive_erro'))
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t('settings.teams.colabs'),
        accessor: 'username'
      },
      {
        Header: t('settings.teams.team'),
        accessor: 'group_name'
      },
      {
        Header: 'E-mail',
        accessor: 'email'
      },
      {
        Header: 'Token',
        accessor: 'token',
      },
      {
        Header: 'Status',
        accessor: 'active',
        Cell: ({ value }) => {
          return value == true ? t(`settings.teams.status_active`) : t(`settings.teams.status_inactive`);
        },
      },
      {
        Header: t('settings.teams.profile'),
        accessor: 'access_profile',
        Cell: (data) => {
          const { access_profile } = data.row.original
          if(!access_profile) return '------'
          
          if(access_profile.is_default_profile === true){
            return t(`settings.teams.profiles.${access_profile.key}`)
          }
          return access_profile.label || '-----'
        },
      },
      {
        Header: t('settings.teams.last_access'),
        accessor: 'last_sign_in_at',
        Cell: ({ value }) => {
          return value ? befectiveFormatDate(value, i18n.language, 'T') : '------'
        },
      },
      {
        Header: t('settings.teams.last_collection'),
        accessor: 'last_tracked',
        Cell: ({ value }) => {
          return value ? befectiveFormatDate(value, i18n.language, 'T') : '------'
        },
      }
    ];
  }, []);

  const getUsers = async ({ pageIndex, pageSize, isLoading, teammates = '', search_text = '', activeFilter = '' }) => {
    try {
      if (isLoading) {
        setFetching(true)
      }

      const queryTeammates = (teammates && teammates.length > 0) ? `&users=${teammates.map(t => t.id).join(',')}` : ''
      const querySearch = search_text != '' ? `&search_text=${search_text}` : ''

      let queryActive = '';

      if (activeFilter == 1) {
        queryActive = `&filter_active=${true}`
      }
      if (activeFilter == 2) {
        queryActive = `&filter_active=${false}`
      }

      const res = await api.get(`/users/v2/?page=${pageIndex}&per_page=${pageSize}${queryTeammates}${querySearch}${queryActive}`, { headers: authHeader() })

      if (res.status !== 200) {
        throw new Error('Erro get users')
      }
      setData(res.data)
      setPageCount(Math.ceil(parseInt(res.data.countRows) / pageSize))
      setPageCountRows(parseInt(res.data.countRows))
    } catch (error) {
      console.error(error)
    } finally {
      setFetching(false)
    }
  }

  useEffect(() => {
    getUsers({ pageIndex: 0, pageSize: localStorage.getItem('pageSizeStorage') || 20, isLoading: false, activeFilter: activeFilter })
  }, [activeFilter])

  useEffect(() => {
    teammatesActions.getTeammates(id)
    teamsActions.getTeams()
    settingsActions.getCompanyInformation()
    getInactiveTeammates()
  }, [])

  useEffect(() => {
    document.title = 'Befective | ' + t('menu.users');
  }, []);


  return (
    <Box bgcolor="#F5F6F9" width="100%" display="flex" flexDirection="column">
      <Header toBackUrl={"/global-settings"} />

      <section className="mainContent">
        {/* <Toolbar /> */}
        <FilterbarUsersSettings
          getData={getUsers}
          filterLabel={t("settings.filter_label_user")}
          hasCreateUser={true}
          enableImport={true}
        />
        {/* PAGE STARTS */}
        <div className="mainPage profile">
          <div className="nocolumn" style={{ background: 'none' }}>
            <div className={'tab full active'}>
              {
                fetching
                  ? (
                    <TeamsSkeleton />
                  ) : (
                    <>
                      <StatsInfoHeader
                        stats={data.stats || null}
                        selectedRows={selectedRows}
                        activeFilter={activeFilter}
                        setActiveFilter={setActiveFilter}
                      />
                      <UsersTable
                        columns={columns}
                        data={data.users || []}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        count={pageCount}
                        getUsersData={getUsers}
                        openDrawer={(data) => {
                          openDrawer(data)
                        }}
                        pageCountRows={pageCountRows}
                        alertActions={alertActions}
                        activeFilter={activeFilter}
                        userDetails={userDetails}
                      />
                    </>
                  )
              }
            </div>
          </div>
        </div>
        {/* PAGE ENDS */}
      </section>

      <div
        className={'overlay' + (drawer ? ' open' : '')}
        onClick={() => {
          setDrawer(false)
        }}>
      </div>

      <section className={'drawer drawer600' + (drawer ? ' open' : '')}>
        <button
          onClick={() => {
            setDrawer(false)
            if (!isTeamsSelected)
              setValue("1")
          }}
          className="close">
          <IconClose />
        </button>

        {
          (isShowingDrawerInvite && props.companyInformation) &&
          <DrawerInvite
            current={inviteCurrent}
            closeDrawer={() => {
              closeDrawer()
            }}
            companyInformation={props.companyInformation}
            getCompanyInformation={settingsActions.getCompanyInformation}
          />
        }

        {
          isShowingDrawerEdit &&
          <DrawerEdit
            current={editCurrent}
            setInactiveTeammates={setInactiveTeammates}
            inactiveTeammates={inactiveTeammates}
            isTeamsSelected={isTeamsSelected}
            value={value}
            handleChangeValue={handleChangeValue}
            closeDrawer={() => {
              closeDrawer()
            }}
            getCompanyInformation={settingsActions.getCompanyInformation}
          />
        }

      </section>

    </Box>
  )
};

function mapDispatchToProps(dispatch) {

  return {
    teamsActions: bindActionCreators(teamsActions, dispatch),
    teammatesActions: bindActionCreators(teammatesActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch),
    settingsActions: bindActionCreators(settingsActions, dispatch)
  }
}

function mapStateToProps(state) {

  return {
    token: state.authentication.user.accessToken,
    loadingTeams: state.teams.loading,
    loadingTeammates: state.teammates.loading,
    filter: state.filter,
    permissions: state.authentication.permissions,
    userDetails: state.authentication.userDetails,
    companyInformation: state.settings.companyInformation,

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(UsersSettingsPage)
