import React from 'react';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import style from './Header.module.scss';

function Header(props) {

  const { userDetails } = props

  return (
    <Box
      display="flex"
      bgcolor="#1E6B7F"
      alignItems="center"
      justifyContent="flex-end"
      flexDirection="row"
      paddingX={4}
      paddingY="12px"
    >
      <div className={style.user}>
        <div className={style.avatar}>
          <img
            src={`https://ui-avatars.com/api/?background=${userDetails.org_client_type === 'poc' ? 'FC6662' : '24677E'}&color=ffffff&name=` + encodeURI(userDetails.name)}
            alt="user profile"
          />
        </div>
      </div>
    </Box>
  )
}

function mapStateToProps(state) {
  return {
    userDetails: state.authentication.userDetails
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
