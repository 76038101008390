import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'

import Toolbar from '../_components/_shared/Toolbar'
import Filterbar from '../_components/_shared/Filterbar'
import WorkGoalFull from '../_components/reports/workgoal/WorkGoalFull'
import WorkGoalFullSkeleton from '../_components/reports/workgoal/WorkGoalFullSkeleton'
import Moment from 'moment';
import {useTranslation, initReactI18next} from "react-i18next";


import {goalsActions, shareActions, statsActions} from '../_actions';

import IconClose from '../_components/_icons/IconClose';
import ShareModal from '../_components/_shared/ShareModal';
import { ReportAiModal } from '../_components/_shared/ReportAiModal';
import { secondsToHm } from '../_helpers';

function WorkGoalPage(props) {
    const {t, i18n} = useTranslation('common');

    const {goalsActions} = props;
    const {statsActions} = props;
    const {shareActions} = props;

    let loading = props.loading;
    let filter = props.filter || {};

    const [strBuilderGoals, setStrBuilderGoals] = useState('');

    const [minimumTime, setMinimumTime] = useState(500);
    const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
    const [loadMore, setLoadMore] = useState(false);
	const [openModalInsights, setOpenModalInsights] = useState(false);

    function closeShareModal() {
        shareActions.closeModal()
    }

    useEffect(() => {
        let date = new Date();
        let startDate = Moment(new Date()).format('YYYY-MM-DD');
        let endDate = Moment(new Date()).format('YYYY-MM-DD');

        let teams = null;
        let teammates = null;

        if (filter.date) {
            startDate = filter.date.startDate;
            endDate = filter.date.endDate;
        }

        if (filter.teams) {
            teams = filter.teams;
        }
        if (filter.teammates) {
            teammates = filter.teammates;
        }

        goalsActions.getGoals(startDate, endDate, teams, teammates);
        statsActions.getStats(startDate, endDate, teams, teammates);


    }, [filter]);

    
    function handleToggleModalInsights() { 
        setOpenModalInsights(!openModalInsights) 
    }

    useEffect(() => {
        document.title = 'Befective | ' + t('menu.reports') + ' | ' + t('standards.performance');
    }, []);

    return (
        <React.Fragment>
            <section className="mainContent">
                <Toolbar/>
                <Filterbar 
					enableGenerateInsights={false}
					handleToggleModalInsights={handleToggleModalInsights}/>
                {/* PAGE STARTS */}
                <div className="mainPage">
                    <div className="column single">
                        {
                            !minimumTimeElapsed || loading
                                ? <WorkGoalFullSkeleton/>
                                : <WorkGoalFull setStrBuilderGoals={setStrBuilderGoals}/>
                        }
                    </div>


                </div>
                {/* PAGE ENDS */}
            </section>

            <ReportAiModal
                openModal={openModalInsights}
                toggleModal={handleToggleModalInsights}
                filter={filter}
                userDetails={props.userDetails}
                report='performance'
                data={{
                    stats: `${t('standards.performance')}: ${secondsToHm(props?.stats?.TotalProductiveTime)} / ${secondsToHm(props?.stats?.total_expected_productive_time)}\n${t('work_goal.goal_reached')}: ${props?.stats?.total_teams_reached_target}\n${t('work_goal.goal_not_reached')}: ${props?.stats?.total_teams - props?.stats?.total_teams_reached_target}\n${t('work_goal.completed')}: ${props?.stats?.productive_percent_from_total_productive}%\n${t('work_goal.online_activities')}: ${props?.stats?.percent_online_from_total_productive}%\n${t('work_goal.offline_activities')}: ${props?.stats?.percent_ofline_from_total_productive}%`,
                    goals: strBuilderGoals
                }}
            />
            

            <div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
                closeShareModal()
            }}></div>
            <section className={'modal' + (props.share.modal ? ' open' : '')}>
                <ShareModal reports='activeWorkGoal' filters={filter}/>
            </section>
        </React.Fragment>
    )
};


function mapDispatchToProps(dispatch) {

    return {
        goalsActions: bindActionCreators(goalsActions, dispatch),
        statsActions: bindActionCreators(statsActions, dispatch),
        shareActions: bindActionCreators(shareActions, dispatch)
    }
}


function mapStateToProps(state) {

    return {
        loading: state.goals.loading,
        filter: state.filter,
        share: state.share,
        goals: state.goals,
        stats: state.stats.payload,        
        userDetails: state.authentication.userDetails
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(WorkGoalPage)
