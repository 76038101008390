import React, { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { alertActions, shareActions, usersActions } from '../../_actions';
import Filterbar from '../../_components/_shared/Filterbar';
import Toolbar from '../../_components/_shared/Toolbar';
import { authHeader, getMoodById} from '../../_helpers';
import { api } from '../../_helpers/api';
import style from './styles.module.scss';
import GlobalSkeleton from '../../_components/_shared/GlobalSkeleton';
import moment from 'moment';
import { MoodTeamsCards } from '../../_components/reports/mood_new/MoodTeamsCards';
import MoodTeamsTable from '../../_components/reports/mood_new/MoodTeamsTable';
import ButtonAlertInfo from '../../_components/reports/mood_new/ButtonAlertInfo';
import ProductivityGroupPercent from '../../_components/reports/mood_new/ProductivityGroupPercent';
import FragmentsMoodAvg from '../../_components/reports/mood_new/FragmentsMoodAvg';
import { useReactToPrint } from 'react-to-print';
import ModalExportReports from '../../_components/goals/ModalExportReports';
import MessageAlertDrawer from '../../_components/reports/mood_new/MessageAlertDrawer';
import AlertsNegativesDrawer from '../../_components/reports/mood_new/AlertsNegativesDrawer';
import MonthDetailsModal from '../../_components/reports/mood_new/MonthDetailsModal';
import { Send } from '@material-ui/icons';
import SendMessageModal from '../../_components/reports/mood_new/SendMessageModal';

const useStyles = makeStyles((theme) => ({
  modal: {
    "&:focus": {
      outline: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

function ProductiveReportsMoodTeamsPage(props) {

  const classes = useStyles();

  const { t, i18n } = useTranslation('common');

  const { userDetails, alertActions, teammatesActions, shareActions, teammates, filter } = props

  const [fetching, setFetching] = useState(true);
  const [dataMood, setDataMood] = useState(null);
	const [currentMonth, setCurrentMonth] = useState(moment(new Date()).format('YYYY-MM'));	
	const [openDrawerMessageAlert, setOpenDrawerMessageAlert] = useState(false);
	const [openDrawerAlertsNegatives, setOpenDrawerAlertsNegatives] = useState(false);
	const [currentGroup, setCurrentGroup] = useState(null);
	const [currentUser, setCurrentUser] = useState(null);
	const [openModalExportReports, setOpenModalExportReports] = useState(false);
	const [openModalMonthDetails, setOpenModalMonthDetails] = useState(false);
	const [openModalSendMessage, setOpenModalSendMessage] = useState(false);
  
	const contentDocument = useRef();
	
	const handlePrint = useReactToPrint({
		content: () => contentDocument.current,
	})

	const handleOpenModalExport = () => {
		setOpenModalExportReports(true);
	};

  const handleCloseModalExport = () => {
		setOpenModalExportReports(false);
	};

  const handleOpenDrawerMessageAlert = (group) => { 
		setCurrentGroup(group);
    setOpenDrawerMessageAlert(true);
  };

  const handleCloseDrawerMessageAlert = () => { 
    setOpenDrawerMessageAlert(false);
		setCurrentGroup(null);
  };

	const handleOpenModalMonthDetails = (group) => { 
		setCurrentGroup(group);
    setOpenModalMonthDetails(true);
  };

  const handleCloseModalMonthDetails = () => { 
    setOpenModalMonthDetails(false);
		setCurrentGroup(null);
  };

  const handleOpenModalSendMessage = (user) => { 
		setCurrentUser(user);
    setOpenModalSendMessage(true);
  };

  const handleCloseModalSendMessage = () => { 
    setOpenModalSendMessage(false);
		setCurrentUser(null);
  };

	const handleOpenDrawerAlertsNegatives = () => { 
    setOpenDrawerAlertsNegatives(true);
  };

  const handleCloseDrawerAlertsNegatives = () => { 
    setOpenDrawerAlertsNegatives(false);
  };

  async function getMoodData(is_loading, month_ref = null, teams = null, teammates = null) {
    try {

      if (is_loading) {
        setFetching(true)
      }

      const queryTeams = teams ? `&teams=${teams.map(t=>t.id).join(',')}` : ''
      const queryTeammates = teammates ? `&users=${teammates.map(t=>t.id).join(',')}` : ''

      const response = await api.get(`/report/mood2?month_ref=${month_ref}&current_day=${moment().format('YYYY-MM-DD')}${queryTeams}${queryTeammates}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }

      setDataMood(response.data)
      setFetching(false)

    } catch (error) {
      console.log(error)
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t('reports_mood.table.col_team'),
        accessor: 'name'
      },
      {
        Header: t('reports_mood.table.col_colabs'),
        accessor: 'total_users',
			},
      {
        Header: t('reports_mood.table.col_mood_avg'),
        accessor: 'series',
				Cell: ({value, row}) => {
					const {group_id, name} = row.original
					return <FragmentsMoodAvg series={value} handleClick={() => handleOpenModalMonthDetails({group_id: group_id, label: name})}/>
				}
      },
      {
        Header: t('reports_mood.table.col_answers'),
        accessor: 'total_mood_sent',
				Cell: ({value, row}) => {
					return `${value || 0}/${row.original.total_expected_mood || 0}`
				}
      },
			{
        Header: t('reports_mood.table.col_average'),
        accessor: 'avg_mood_group',
        width: '8%',
				Cell: ({value}) => {
					if(!value) return '---'
					return <img title={t(`widgets.moods.${getMoodById(parseInt(value)).key}`)} src={getMoodById(parseInt(value)).img} alt={t(`widgets.moods.${getMoodById(parseInt(value)).key}`)} width={'18px'} height={'18px'}/>
				}
      },
			{
        Header: t('reports_mood.table.col_alert'),
        accessor: 'total_alert_message',
				Cell: ({value, row}) => {
					const {group_id, name} = row.original
					return <ButtonAlertInfo total_alerts={parseInt(value)} handleClick={() => handleOpenDrawerMessageAlert({group_id: group_id, label: name})} />
				}
      },
			{
        Header: t('reports_mood.table.col_productivity'),
        accessor: 'percent_working_day',
				Cell: ({ value, ...rest }) => {
          const currentRow = rest.row.original;
          return <ProductivityGroupPercent percent_working_day={parseInt(value)} percent_performance={parseInt(currentRow.percent_performance)} />;
        },
      }
    ];
  }, []);


  useEffect(() => {
   	let date = moment(new Date()).format('YYYY-MM');
	 
		if (filter.singleDate) {
			date = moment(filter.singleDate).format('YYYY-MM')
		}

		setCurrentMonth(date)

    let teams = null;
    let teammates = null;

    if (filter.teams) {
      teams = filter.teams;
    }
    if (filter.teammates) {
      teammates = filter.teammates;
    }

    getMoodData(true, date, teams, teammates)

  }, [filter])

  function closeShareModal() {
    shareActions.closeModal()
  }

	useEffect(() => {			
		document.title = 'Befective | ' + t('menu.reports') + ' | ' + t('menu.mood');
	}, [])

  return (
			<>
				<section className="mainContent">

					<Toolbar />

					<Filterbar
						filterWho={'multi'}
						filterWhenMonths={'single'}
						filterWhen={'disabled'}
						filterWhat={'disabled'}
						showShareBtn={false}
					/>

					<div className={style.containerPage} ref={contentDocument}>
						{
							!fetching
								?
								<>
									<MoodTeamsCards alertActions={alertActions} userDetails={userDetails} stats={dataMood?.stats} handleOpenDrawerAlertsNegatives={handleOpenDrawerAlertsNegatives}/>
									<MoodTeamsTable columns={columns} data={dataMood?.data} handlePrint={handlePrint} handleOpenModalExport={handleOpenModalExport}/>
								</>
								:
								<GlobalSkeleton totalRowsStats={1} totalTables={1} heightTable={600} />
						}

					</div>

				</section>

				<ModalExportReports
					openModalExport={openModalExportReports} 
					handleCloseModalExport={handleCloseModalExport} 
					viewExport='mood2' 
					filter={{ ...filter, month_ref: currentMonth, current_day: moment().format('YYYY-MM-DD')}} 
					disabledExport={{
						excel: true,
						pdf: false
					}}
				/>

				<MonthDetailsModal
					currentMonthYear={currentMonth}
					currentData={currentGroup}
					openModal={openModalMonthDetails}
					closeModal={handleCloseModalMonthDetails}
				/>

        <SendMessageModal
          openModal={openModalSendMessage}
          closeModal={handleCloseModalSendMessage}
          closeDrawerAlerts={handleCloseDrawerAlertsNegatives}
          currentUser={currentUser}
          alertActions={alertActions}
          updateDataPage={() => getMoodData(true, currentMonth, filter.teams, filter.teammates)}
        />
				
				<MessageAlertDrawer 
					currentMonthYear={currentMonth}
					currentData={currentGroup}
					openDrawer={openDrawerMessageAlert} 
					closeDrawer={handleCloseDrawerMessageAlert}
				/>

				<AlertsNegativesDrawer
					openDrawer={openDrawerAlertsNegatives}
					closeDrawer={handleCloseDrawerAlertsNegatives}
          handleOpenModalSendMessage={handleOpenModalSendMessage}
          handleOpenModalMonthDetails={handleOpenModalMonthDetails}
          handleOpenDrawerMessageAlert={handleOpenDrawerMessageAlert}
				/>

			</>
  )
};


function mapDispatchToProps(dispatch) {

  return {
    alertActions: bindActionCreators(alertActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    shareActions: bindActionCreators(shareActions, dispatch)
  }
}


function mapStateToProps(state) {

  return {
    userDetails: state.authentication.userDetails,
    users: state.users.items,
    teammates: state.teammates,
    filter: state.filter,
    share: state.share
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ProductiveReportsMoodTeamsPage)