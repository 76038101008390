import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


export function numberToPercentString(number) {
	return String(Number(number).toFixed(2)).replace('.', ',') + '%'
}

export function formatDate(date) {
	if (!date) return date
	return new Date(date).toLocaleDateString()
}

export function TruncateNumberWithoutRounding(number) {
	return number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
}

export function formatDatetime(datetime) {
	if (!datetime) return datetime
	const dateTimeSplit = String(datetime).split('T')
	const date = new Date(dateTimeSplit[0] + 'T00:00:00.000').toLocaleDateString()
	const time = dateTimeSplit[1].slice(0, 5)
	return date + ' ' + time
}
export function getLabelByStatus(status) {
	switch (status) {
		case 'completed':
			return 'Feito'
		case 'progress':
			return 'Em andamento'
		case 'planned':
			return 'Planejamento'
		case 'late':
			return 'Atraso'
		default:
			break;
	}
}

export function getLabelByStatusId(status_id) {
	switch (status_id) {
		case 3:
			return 'tasks.completed'
		case 2:
			return 'tasks.progress'
		case 1:
			return 'tasks.planned'
		case 4:
			return 'tasks.late'
		default:
			break;
	}
}

export function getAvatarUrl(teammate, bg = "24677E") {
	const { name, username, urlImg } = teammate

	if (urlImg) return urlImg

	if (username) return `https://ui-avatars.com/api/?background=${bg}&color=ffffff&name=` + encodeURI(username)

	return `https://ui-avatars.com/api/?background=${bg}&color=ffffff&name=` + encodeURI(name)


}

export function getStatusClass(status_id) {

	if (status_id === 4) {
		return 'late';
	} else if (status_id === 1) {
		return 'planned';
	} else if (status_id === 3) {
		return 'completed'
	} else {
		return 'progress'
	}
}

export function getStatusClassColor(status_id) {

	if (status_id === 4) {
		return '#FFEAED';
	} else if (status_id === 1) {
		return '#E4EAEF';
	} else if (status_id === 3) {
		return '#E4F7F5'
	} else {
		return '#FEF3E4'
	}
}

export function getColorStatusKr(status_id) {
	switch (Number(status_id)) {
		case 4:
			return '#FF5151';
		case 1:
			return '#98ACC1';
		case 2:
			return '#FDA02B';
		case 3:
			return '#22BEAD';
		default:
			return '#F3F3F3'
	}
}


export function getColorByTypeGoals(type) {
	switch (type) {
		case "late":
			return '#FF5151';
		case "planned":
			return '#98ACC1';
		case "progress":
			return '#FDA02B';
		case "completed":
			return '#22BEAD';
		default:
			return '#F3F3F3'
	}
}
export function getPeriod(date_start, date_end, language, to='-') {

	let dateStart = befectiveFormatDate(date_start, language);
	let dateEnd = befectiveFormatDate(date_end, language);

	return `${dateStart} ${to} ${dateEnd}`

}

export function getPriority(priorityId) {
	if (priorityId) {
		if (priorityId === 1) {
			return 'low';
		} else if (priorityId === 2) {
			return 'medium';
		} else if (priorityId === 3) {
			return 'high';
		}
	} else {
		return 'low'
	}
}

export function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}



export function limitedText(text, limit = 20) {
	const textStr = String(text)
	if (textStr.length <= limit)
		return textStr
	return textStr.substring(0, limit) + '...'
}

export function enumerateYearsBetweenDates(startDate, endDate) {
	const dates = [];
	dates.push(moment(startDate).format('yyyy'));
	const currDate = moment(startDate).startOf('year');
	const lastDate = moment(endDate).startOf('year');

	while (currDate.add(1, 'year').diff(lastDate) < 0) {
		dates.push(currDate.clone().year());
	}

	return dates;
};

export function befectiveFormatDate(datetime, language, format = "S") {

	if(!datetime) return "-----";
	
	switch (language) {
		case "pt":
			if (format === "S") {
				return moment.utc(datetime).format("DD/MM/YYYY")
			}
			return moment(datetime).format("DD/MM/YYYY HH:mm")
		case "es":
			if (format === "S") {
				return moment.utc(datetime).format("DD/MM/YYYY")
			}
			return moment(datetime).format("DD/MM/YYYY HH:mm")
		case "en":
			if (format === "S") {
				return moment.utc(datetime).format("MM/DD/YYYY")
			}
			return moment(datetime).format("MM/DD/YYYY HH:mm")
	}
}

export function befetiveFormatCurrency(value, language, withSymbol=true) {
	// Arredonda para 2 casas decimais
	value = parseFloat(Number(value).toFixed(2));

	// Configura o language da moeda
	let options = {};
	let format_language = "en-US"
	if (language === 'pt') {
			options = { style: 'currency', currency: 'BRL' };
			format_language = 'pt-BR'
	} else if (language === 'es') {
			options = { style: 'currency', currency: 'MXN' };
			format_language = 'es-MX'
	}else{
		options = { style: 'currency', currency: 'USD' };
	}

	// Adiciona separador de milhar e formata como moeda
	const resp = value.toLocaleString(format_language, options);
	
	return withSymbol ? resp : resp.replace(/[^0-9,.]/g, '');
}



export function befectiveMaskFormatDate(language) {
	switch (language) {
		case "pt":
			return "dd/MM/yyyy"
		case "es":
			return "dd/MM/yyyy"
		case "en":
			return "MM/dd/yyyy"
	}
}


export function befectiveMaskFormatDateMUI(language, withTime = false) {
	if (withTime) {
		switch (language) {
			case "pt":
				return "DD/MM/YYYY HH:mm"
			case "es":
				return "DD/MM/YYYY HH:mm"
			case "en":
				return "MM/DD/YYYY HH:mm"
		}
	} else {
		switch (language) {
			case "pt":
				return "DD/MM/YYYY"
			case "es":
				return "DD/MM/YYYY"
			case "en":
				return "MM/DD/YYYY"
		}
	}
}


export function getFirstAndLastMonth(current_month, type) {
	const cur_month = Number(current_month)
	switch (type) {
		case "monthly":
			if (cur_month === 1) {
				return {
					first_month: '01',
					last_month: '02'
				}
			}
			break;
		case "bimonthly":
			if (cur_month >= 1 && cur_month <= 2) {
				return {
					first_month: '01',
					last_month: '02'
				}
			}
			if (cur_month >= 3 && cur_month <= 4) {
				return {
					first_month: '03',
					last_month: '04'
				}
			}
			if (cur_month >= 5 && cur_month <= 6) {
				return {
					first_month: '05',
					last_month: '06'
				}
			}
			if (cur_month >= 7 && cur_month <= 8) {
				return {
					first_month: '07',
					last_month: '08'
				}
			}
			if (cur_month >= 9 && cur_month <= 10) {
				return {
					first_month: '09',
					last_month: '10'
				}
			}
			if (cur_month >= 11 && cur_month <= 12) {
				return {
					first_month: '11',
					last_month: '12'
				}
			}

			break;
		case "quarterly":
			if (cur_month >= 1 && cur_month <= 3) {
				return {
					first_month: '01',
					last_month: '03'
				}
			}
			if (cur_month >= 4 && cur_month <= 6) {
				return {
					first_month: '04',
					last_month: '06'
				}
			}
			if (cur_month >= 7 && cur_month <= 9) {
				return {
					first_month: '07',
					last_month: '09'
				}
			}
			if (cur_month >= 10 && cur_month <= 12) {
				return {
					first_month: '10',
					last_month: '12'
				}
			}

			break;
		case "semester":
			if (cur_month >= 1 && cur_month <= 6) {
				return {
					first_month: '01',
					last_month: '06'
				}
			}
			if (cur_month >= 7 && cur_month <= 12) {
				return {
					first_month: '07',
					last_month: '12'
				}
			}

			break;
		default:
			return;
	}
}

export function getValuePeriod(form, period, valuePeriod) {

	if (period === 'yearly') {
		return {
			period: period,
			value_period: valuePeriod,
			date_start: moment.utc(valuePeriod + '-01-01').format('YYYY-MM-DD'),
			date_end: moment.utc(String(parseInt(valuePeriod) + 1) + '-01-01').subtract(1, 'days').format('YYYY-MM-DD')
		}
	}

	let months

	switch (valuePeriod) {
		case 'jan':
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-01-01').startOf('month').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-01-01').endOf('month').format('YYYY-MM-DD')
			}
		case 'feb':
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-02-01').startOf('month').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-02-01').endOf('month').format('YYYY-MM-DD')
			}
		case 'mar':
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-03-01').startOf('month').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-03-01').endOf('month').format('YYYY-MM-DD')
			}
		case 'apr':
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-04-01').startOf('month').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-04-01').endOf('month').format('YYYY-MM-DD')
			}
		case 'may':
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-05-01').startOf('month').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-05-01').endOf('month').format('YYYY-MM-DD')
			}

		case 'jun':
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-06-01').startOf('month').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-06-01').endOf('month').format('YYYY-MM-DD')
			}

		case 'jul':
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-07-01').startOf('month').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-07-01').endOf('month').format('YYYY-MM-DD')
			}
		case 'aug':
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-08-01').startOf('month').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-08-01').endOf('month').format('YYYY-MM-DD')
			}
		case 'sep':
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-09-01').startOf('month').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-09-01').endOf('month').format('YYYY-MM-DD')
			}
		case 'oct':
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-10-01').startOf('month').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-10-01').endOf('month').format('YYYY-MM-DD')
			}
		case 'nov':
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-11-01').startOf('month').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-11-01').endOf('month').format('YYYY-MM-DD')
			}
		case 'dec':
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-12-01').startOf('month').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-12-01').endOf('month').format('YYYY-MM-DD')
			}
		case 'B1':
			months = getFirstAndLastMonth(1, period)
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-' + months.first_month + '-01').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-' + months.last_month + '-01').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
			}
		case 'B2':
			months = getFirstAndLastMonth(3, period)
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-' + months.first_month + '-01').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-' + months.last_month + '-01').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
			}
		case 'B3':
			months = getFirstAndLastMonth(5, period)
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-' + months.first_month + '-01').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-' + months.last_month + '-01').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
			}
		case 'B4':
			months = getFirstAndLastMonth(7, period)
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-' + months.first_month + '-01').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-' + months.last_month + '-01').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
			}
		case 'B5':
			months = getFirstAndLastMonth(9, period)
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-' + months.first_month + '-01').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-' + months.last_month + '-01').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
			}
		case 'B6':
			months = getFirstAndLastMonth(11, period)
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-' + months.first_month + '-01').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-' + months.last_month + '-01').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
			}
		case 'T1':
			months = getFirstAndLastMonth(1, period)
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-' + months.first_month + '-01').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-' + months.last_month + '-01').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
			}
		case 'T2':
			months = getFirstAndLastMonth(4, period)
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-' + months.first_month + '-01').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-' + months.last_month + '-01').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
			}
		case 'T3':
			months = getFirstAndLastMonth(7, period)
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-' + months.first_month + '-01').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-' + months.last_month + '-01').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
			}
		case 'T4':
			months = getFirstAndLastMonth(10, period)
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-' + months.first_month + '-01').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-' + months.last_month + '-01').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
			}
		case 'S1':
			months = getFirstAndLastMonth(1, period)
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-' + months.first_month + '-01').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-' + months.last_month + '-01').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
			}
		case 'S2':
			months = getFirstAndLastMonth(7, period)
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-' + months.first_month + '-01').format('YYYY-MM-DD'),
				date_end: moment.utc(form?.year_period + '-' + months.last_month + '-01').add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
			}
		case 'Y':
			return {
				period: period,
				value_period: valuePeriod,
				date_start: moment.utc(form?.year_period + '-01-01').format('YYYY-MM-DD'),
				date_end: moment.utc(String(parseInt(form?.year_period) + 1) + '-01-01').subtract(1, 'days').format('YYYY-MM-DD')
			}
		default:
			return
	}
}

export function getOptionsPeriods() {
	const yearsRange = enumerateYearsBetweenDates(moment.utc(), moment.utc().add(10, 'y'))?.map((year, index) => {
		return {
			type: 'yearly',
			id: year,
			value: year
		}
	})

	return [
		{
			type: 'monthly',
			id: 'jan',
			value: 'jan'
		},
		{
			type: 'monthly',
			id: 'feb',
			value: 'feb'
		},
		{
			type: 'monthly',
			id: 'mar',
			value: 'mar'
		},
		{
			type: 'monthly',
			id: 'apr',
			value: 'apr'
		},
		{
			type: 'monthly',
			id: 'may',
			value: 'may'
		},
		{
			type: 'monthly',
			id: 'jun',
			value: 'jun'
		},
		{
			type: 'monthly',
			id: 'jul',
			value: 'jul'
		},
		{
			type: 'monthly',
			id: 'aug',
			value: 'aug'
		},
		{
			type: 'monthly',
			id: 'sep',
			value: 'sep'
		},
		{
			type: 'monthly',
			id: 'oct',
			value: 'oct'
		},
		{
			type: 'monthly',
			id: 'nov',
			value: 'nov'
		},
		{
			type: 'monthly',
			id: 'dec',
			value: 'dec'
		},

		{
			type: "bimonthly",
			id: 'B1',
			value: 'B1'
		},
		{
			type: "bimonthly",
			id: 'B2',
			value: 'B2'
		},
		{
			type: "bimonthly",
			id: 'B3',
			value: 'B3'
		},
		{
			type: "bimonthly",
			id: 'B4',
			value: 'B4'
		},
		{
			type: "bimonthly",
			id: 'B5',
			value: 'B5'
		},
		{
			type: "bimonthly",
			id: 'B6',
			value: 'B6'
		},
		{
			type: "quarterly",
			id: 'T1',
			value: 'T1'
		},
		{
			type: "quarterly",
			id: 'T2',
			value: 'T2'
		},
		{
			type: "quarterly",
			id: 'T3',
			value: 'T3'
		},
		{
			type: "quarterly",
			id: 'T4',
			value: 'T4'
		},
		{
			type: "semester",
			id: 'S1',
			value: 'S1'
		},
		{
			type: "semester",
			id: 'S2',
			value: 'S2'
		},
		...yearsRange
	]
}


export function getDynamicRedirectUrl(userDetails, uri_redirect = '') {

	if (!userDetails || uri_redirect === '') {
		return '/'
	}

	let uri_redirect_prepared = uri_redirect;

	if (!uri_redirect.startsWith('/')) {
		uri_redirect_prepared = "/" + uri_redirect
	}

	if (userDetails?.roleName === 'machine_user' && userDetails?.mu_token !== 'not_defined') {
		return `/productivity/${userDetails?.organizationId}/${userDetails?.mu_token}${uri_redirect_prepared}`
	} else {
		return uri_redirect_prepared
	}

}

export const calcLicensesAvailable = (companyInfo, gap = 0) => companyInfo ? (parseInt(companyInfo?.totalLicenses) - parseInt(companyInfo?.usedLicenses) - gap) : 0

export const delay = ms => new Promise(
	resolve => setTimeout(resolve, ms)
);

export function hoursToMinutes(time) {
	if (!time || Number(time) === 0) return 0
	let is_negative = false
	if (String(time).includes("-")) {
		is_negative = true
	}
	const hours = Math.abs(Number(time.split(':')[0]))
	const minutes = Math.abs(Number(time.split(':')[1]))

	return is_negative ? -1 * ((hours * 60) + minutes) : ((hours * 60) + minutes);
}

export function genPeriodSimpleArray(num) {
	if (num === 0) return []
	return [{ day: '0000-00-00', points_by_periods: [] }, ...genPeriodSimpleArray(num - 1)]
}

export function checkIfIsAfterTime(period, checkStart = true) {
	const now = moment();

	if (checkStart) {
		const start = moment(period.start_time, 'HH:mm:ss');

		if (now.isAfter(start)) {
			return true
		} else {
			return false
		}
	} else {
		const end = moment(period.end_time, 'HH:mm:ss');

		if (now.isAfter(end)) {
			return true
		} else {
			return false
		}
	}
}

export const formatPeriods = (periods) => {

	const { t, i18n } = useTranslation('common');

	if (!periods) {
		return ' ' + t('periods.none')
	}

	let strBuildPeriods = '';
	for (const period of periods) {
		strBuildPeriods += ` ${String(period.start_time).slice(0, 5)} - ${String(period.end_time).slice(0, 5)} /`
	}
	return strBuildPeriods.slice(0, -1)
}

export const getMonths = (month, t) => {

	switch (month) {
		case 0:
			return t('months.jan')
		case 1:
			return t('months.feb')
		case 2:
			return t('months.mar')
		case 3:
			return t('months.apr')
		case 4:
			return t('months.may')
		case 5:
			return t('months.jun')
		case 6:
			return t('months.jul')
		case 7:
			return t('months.aug')
		case 8:
			return t('months.sep')
		case 9:
			return t('months.oct')
		case 10:
			return t('months.nov')
		case 11:
			return t('months.dec')
		default:
			return 'Not month'
	}
}

export const getMonthsAbbreviated = (month, t) => {

	switch (month) {
		case 0:
			return t('months_small.jan')
		case 1:
			return t('months_small.feb')
		case 2:
			return t('months_small.mar')
		case 3:
			return t('months_small.apr')
		case 4:
			return t('months_small.may')
		case 5:
			return t('months_small.jun')
		case 6:
			return t('months_small.jul')
		case 7:
			return t('months_small.aug')
		case 8:
			return t('months_small.sep')
		case 9:
			return t('months_small.oct')
		case 10:
			return t('months_small.nov')
		case 11:
			return t('months_small.dec')
		default:
			return 'Not month'
	}
}

export const getDayWeek = (day, abbreviated = false) => {
	const { t, i18n } = useTranslation('common');

	switch (parseInt(day)) {
		case 0:
			return abbreviated ? t('settings.teams.sun') : t('days_week.sun')
		case 1:
			return abbreviated ? t('settings.teams.mon') : t('days_week.mon')
		case 2:
			return abbreviated ? t('settings.teams.tue') : t('days_week.tue')
		case 3:
			return abbreviated ? t('settings.teams.wed') : t('days_week.wed')
		case 4:
			return abbreviated ? t('settings.teams.thu') : t('days_week.thu')
		case 5:
			return abbreviated ? t('settings.teams.fri') : t('days_week.fri')
		case 6:
			return abbreviated ? t('settings.teams.sat') : t('days_week.sat')
		default:
			return '----'
	}
}
export const getDayWeekV2 = (day, abbreviated = false, t) => {

	switch (parseInt(day)) {
		case 0:
			return abbreviated ? t('settings.teams.sun') : t('days_week.sun')
		case 1:
			return abbreviated ? t('settings.teams.mon') : t('days_week.mon')
		case 2:
			return abbreviated ? t('settings.teams.tue') : t('days_week.tue')
		case 3:
			return abbreviated ? t('settings.teams.wed') : t('days_week.wed')
		case 4:
			return abbreviated ? t('settings.teams.thu') : t('days_week.thu')
		case 5:
			return abbreviated ? t('settings.teams.fri') : t('days_week.fri')
		case 6:
			return abbreviated ? t('settings.teams.sat') : t('days_week.sat')
		default:
			return '----'
	}
}

export const getLabelReasonAdjust = (solicitation) => {
	const { t, i18n } = useTranslation('common');
	switch (i18n.language) {
		case 'pt':
			return solicitation?.label_pt || solicitation?.label_custom
		case 'es':
			return solicitation?.label_es || solicitation?.label_custom
		case 'en':
			return solicitation?.label_en || solicitation?.label_custom
		default:
			return solicitation?.label_en || solicitation?.label_custom
	}
}

export const getRemainingPoints = ({ periods, points }) => {

	if (points.length === 0 || periods.length === 0) return []

	const totalPeriods = periods.length * 2
	const totalPoints = points.length

	if (totalPoints === totalPeriods || totalPoints > totalPeriods && totalPoints % 2 === 0) return []

	if (totalPoints < totalPeriods) {
		const arrayReturn = points[totalPoints - 1].type === 'IN' ?
			Array.from(Array(totalPeriods - totalPoints))
				.map((_, index) => ({ type: index % 2 == 0 ? 'OUT' : 'IN' }))
			:
			Array.from(Array(totalPeriods - totalPoints))
				.map((_, index) => ({ type: index % 2 == 0 ? 'IN' : 'OUT' }))
		return arrayReturn
	}

	if (totalPoints > totalPeriods && totalPoints % 2 !== 0) {
		return Array.from(Array(1)).map((_, index) => ({ type: 'OUT' }))
	}
}

export function getCorrectPeriod(point, periods, tolerated_time = 45) {

	if (point === null) {
		return periods.reduce((acc, curr) => {
			return acc + curr.start + " - " + curr.end + " | "
		}, "").slice(0, -2)
	}
	for (const p of periods) {
		const p1 = moment(`2000-01-01 ${p.start}`).subtract(tolerated_time, 'minutes')
		const p2 = moment(`2000-01-01 ${p.end}`).add(tolerated_time, 'minutes')
		const pComp = moment(`2000-01-01 ${point.time}`)

		if (pComp >= p1 && pComp <= p2) {
			return `${p.start} - ${p.end}`
		}

	}
	return "-- - --"
}

export function calcDurationBTWArrPeriodsInMinutes(periods) {
	if (periods.length === 0) {
		return 0
	}
	let total_duration = 0
	for (const p of periods) {
		const startHour = moment(p.start, "HH:mm:ss");
		const endHour = moment(p.end, "HH:mm:ss");
		const diffInMiliSeconds = endHour.diff(startHour);
		const durationInMinutes = moment.duration(diffInMiliSeconds).asMinutes();
		total_duration += durationInMinutes;
	}
	return total_duration

}

export function initTasks() {
	const currentDate = new Date();
	const data = [
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
			name: "Some Project",
			id: "ProjectSample",
			progress: 25,
			type: "project",
			hideChildren: true,
			displayOrder: 1,
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
			end: new Date(
				currentDate.getFullYear(),
				currentDate.getMonth(),
				2,
				12,
				28
			),
			name: "Idea",
			id: "Task 0",
			progress: 45,
			type: "task",
			project: "ProjectSample",
			displayOrder: 2,
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
			name: "Research",
			id: "Task 1",
			progress: 25,
			type: "task",
			project: "ProjectSample",
			displayOrder: 3,
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
			name: "Discussion with team",
			id: "Task 2",
			progress: 10,
			type: "task",
			project: "ProjectSample",
			displayOrder: 4,
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
			name: "Developing",
			id: "Task 3",
			progress: 2,
			type: "task",
			project: "ProjectSample",
			displayOrder: 5,
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
			name: "Review",
			id: "Task 4",
			type: "task",
			progress: 70,
			project: "ProjectSample",
			displayOrder: 6,
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
			name: "Release",
			id: "Task 6",
			progress: currentDate.getMonth(),
			type: "milestone",
			project: "ProjectSample",
			displayOrder: 7,
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
			name: "Party Time",
			id: "Task 9",
			progress: 0,
			isDisabled: true,
			type: "task",
		},
		{
			start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
			end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
			name: "Other project",
			id: "ProjectSample2",
			progress: 59,
			type: "project",
			hideChildren: true,
			displayOrder: 2,
		}
	];
	return data;
}

export function getStartEndDateForProject(tasks, projectId) {
	const projectTasks = tasks.filter(t => t.project === projectId);
	let start = projectTasks[0].start;
	let end = projectTasks[0].end;

	for (let i = 0; i < projectTasks.length; i++) {
		const task = projectTasks[i];
		if (start.getTime() > task.start.getTime()) {
			start = task.start;
		}
		if (end.getTime() < task.end.getTime()) {
			end = task.end;
		}
	}
	return [start, end];
}

export function secondsToFormatHours(totalSeconds, noneSecond = false) {

	if (noneSecond === true) {

		let secondsPositive = totalSeconds

		if (totalSeconds < 0) {
			secondsPositive = totalSeconds * -1
		}

		const hours = Math.floor(secondsPositive / 3600);
		const minutes = Math.floor((secondsPositive % 3600) / 60);

		const formattedTime = moment()
			.startOf('day')
			.hours(hours)
			.minutes(minutes)
			.format('HH:mm');

		if (totalSeconds < 0) {
			return '-' + formattedTime;
		}

		return formattedTime;
	}

	if (!totalSeconds || totalSeconds < 0)
		return '00:00:00'


	const hours = Math.floor(totalSeconds / 3600);
	const minutes = Math.floor((totalSeconds % 3600) / 60);


	const seconds = totalSeconds % 60;

	const formatHours = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

	return formatHours;
}

export const replaceTemplate = (template_str, data, delimiter = ['{{', '}}']) => {

	Object.keys(data).forEach((key, i) => {
		if (data[key] !== null) {
			const regexp = new RegExp(delimiter[0] + key + delimiter[1], 'g')
			const value = typeof data[key] === 'function' ? data[key](key, i, data, template_str) : data[key]

			template_str = template_str.replace(regexp, value)
		}
	})

	return template_str

}

export function secondsToHours(seconds) {
	
	const secondsNumber = Number(seconds);

	if (secondsNumber > 0) {
		const h = Math.floor(secondsNumber / 3600);

		const hDisplay = h > 0 ? h.toFixed() + (h == 1 ? "h " : "h ") : "";

		return hDisplay

	}

	return '0h'
}

export async function doCountAccessModule (key,api,authHeader) {
	try {
		await api.post(`/settings/count-module`, {
			module_name: key
		}, {
			headers: authHeader()
		})
	} catch (error) {
		console.log(error)
	}
}


export function getRangeFilterWhen (preview){
	switch (preview) {
			case 'yesterday':
					return {
							startDate: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
							endDate: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD')
					}
			case 'current_week':
					return {
							startDate: moment(new Date()).clone().startOf('isoWeek').format('YYYY-MM-DD'),
							endDate: moment(new Date()).format('YYYY-MM-DD')
					}
			case 'last_week':
					return {
							startDate: moment(new Date()).add(-1, 'w').weekday(1).format('YYYY-MM-DD'),
							endDate: moment(new Date()).add(-1, 'w').weekday(7).format('YYYY-MM-DD')
					}
			case 'this_month':
					return {
							startDate: moment(new Date()).startOf('month').format('YYYY-MM-DD'),
							endDate: moment(new Date()).endOf('month').format('YYYY-MM-DD')
					}
			case 'last_month':
					return {
							startDate: moment(new Date()).add(-1, 'M').startOf('month').format('YYYY-MM-DD'),
							endDate: moment(new Date()).add(-1, 'M').endOf('month').format('YYYY-MM-DD')
					}
			default:
					return {
							startDate: moment(new Date()).format('YYYY-MM-DD'),
							endDate: moment(new Date()).format('YYYY-MM-DD')
					}

	}
}

export function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

export function formatNumber(number, lang) {
  const numberFormatted = new Intl.NumberFormat(lang, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
  }).format(number);

  return numberFormatted;
}

export function calcWidthWidget(percent){
	if (!percent || Number(percent) <= 0 || Number(percent) > 100) return 12

	return Number((Number(percent) * 12 / 100)).toFixed()

}

export function createHierarchy(data, lang) {
	const hierarchy = {};
	const itemsById = {};

	// Organize items by id
	data.forEach(item => {
		itemsById[item.id] = item;
	});

	// Create hierarchy
	data.forEach(item => {
		if (item.is_parent) {
			hierarchy[item.id] = { ...item, children: [] };
		} else {
			const parentId = item.parent_id;
			if (hierarchy[parentId]) {
				hierarchy[parentId].children.push(item);
			} else {
				const parentItem = itemsById[parentId];
				if (parentItem) {
					if (!parentItem.children) {
						parentItem.children = [];
					}
					parentItem.children.push(item);
				}
			}
		}
	});

	// Convert hierarchy object to array
	const result = Object.values(hierarchy).concat(Object.values(itemsById).filter(item => !item.is_parent && !item.parent_id));
	const arrayReorganized = []

	result.forEach(parent => {
		arrayReorganized.push({
			value: parent.id,
			label: parent[`name_${lang}`],
			is_children: false
		})
		if (parent.children && parent.children.length > 0) {
			parent.children.forEach(children => {
				arrayReorganized.push({
					value: children.id,
					label: children[`name_${lang}`],
					is_children: true
				})
			})
		}
	})
	return arrayReorganized

}

export function  filteredDataEfficiencies(data, userDetails){

	if(!data || data.length === 0 || !userDetails) return []

	const newData = data.reduce((acc, obj) => {
			
			const newObj = { ...obj };

			if(obj.efficiencyName==='productive'){
					newObj.productive = 'related'
			}else if(obj.efficiencyName === 'unproductive'){
					newObj.productive = 'not_related'
			}else{
					newObj.productive = 'neutral'
			}

			acc.push(newObj);
			
			return acc;
	}, []);
		
	return newData.filter(item => userDetails.allowed_productive.includes(item.productive));
}


export function parseValue(value){
	if(value === null || value === undefined) return "0"
	return value
}

export function useQuery(){
	return new URLSearchParams(useLocation().search);
}

export function updateQueryParams(param, value){
	const newParams = new URLSearchParams();
	newParams.set(param, value);
	return newParams;
};

export const getStructureOrganizationalLabel = (is_manager=false, is_self_assessment=false) => {
	const { t, i18n } = useTranslation('common');

	if(is_self_assessment){
		return t('performance_assessment.labels.self_assessment')
	}

	if(is_manager){
		return t('performance_assessment.labels.manager')
	}

	return t('performance_assessment.labels.colab')
}

export const truncateNumber = (number) => {
	if(!number) return '----'
	return number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
}

export const calculateScoreRatings = (ratings) => {
    if(ratings.length === 0){
      return []
    }
    const total_ratings = ratings.length
    let total = 100
    let aux = Math.round(100/total_ratings)
    let max_value = total_ratings
    const processed_data = ratings.map((item) => {
      const p1 = total
      const p2 = (total - aux) !== 1 ? (total - aux)+1 : (total - aux)
      const score = `${p2 > 0 ? p2 : 1}/${p1}`
      const data = {
        ...item,
        ratings_score: score
      }
      total = total - aux
      max_value -= 1
      return data
    })
    
    return processed_data

}


export const prepareOptionsData = (data, lang='en') => {
	if (!data || !data.length) return [];

	if (!data[0].label_pt)
		return data.map((item) => ({ value: item.id, label: item.name }));

	return data.map((item) => ({
		value: item.id,
		label: item[`label_${lang}`],
	}));
};

export const getGenders = () => {
	const { t, i18n } = useTranslation('common');
	return [
		{ value: 'M', label: t('settings.users.personal_form.male') },
		{ value: 'F', label: t('settings.users.personal_form.female') }
	];
}

export const getModuleByPath = (path) => {
	switch (path) {
		case 'performance-assessment':
			return 'performance_assessment'
		default:
			return 'productivity_management'
	}
}

export const getIntroExample = (lang) => {
    switch (lang) {
      case "pt":
        return  `<p class="ql-align-center"><strong>Esta é uma oportunidade para refletir sobre as áreas de desenvolvimento e para fornecer um feedback construtivo.</strong></p><p class="ql-align-center"><br></p><p><strong>Instruções </strong></p><ul><li>Preencha o questionário com atenção.</li><li>Seja honesto e responda às perguntas com sinceridade.</li><li>Reserve um tempo para refletir sobre suas respostas.</li><li>Envie a avaliação quando terminar. </li><li>Se tiver alguma dúvida, entre em contato com seu gestor. </li></ul><p><br></p><p><strong>Sua avaliação de desempenho é confidencial </strong></p><p>Somente o seu gestor e o departamento de Recursos Humanos terão acesso às suas respostas. Você pode ter certeza de que suas respostas serão tratadas com confidencialidade e respeito. </p><p><br></p><p>Obrigado pela sua participação!</p><p><br></p><p>Atenciosamente, </p><p><br></p><p><strong>Departamento de Recursos Humanos</strong></p>`;
      case "en":
        return  `<p class="ql-align-center"><strong>This is an opportunity to reflect on areas for development and to provide constructive feedback.</strong></p><p class="ql-align-center"><br></p><p><strong>Instructions</strong></p><ul><li>Fill out the questionnaire carefully.</li><li>Be honest and answer the questions sincerely.</li><li>Take some time to reflect on your answers.</li><li>Submit the evaluation when you are finished.</li><li>If you have any questions, contact your manager.</li></ul><p><br></p><p><strong>Your performance evaluation is confidential</strong></p><p>Only your manager and the Human Resources department will have access to your responses. You can be assured that your answers will be treated with confidentiality and respect.</p><p><br></p><p>Thank you for your participation!</p><p><br></p><p>Sincerely,</p><p><br></p><p><strong>Human Resources Department</strong></p>`;
      case "es":
        return `<p class="ql-align-center"><strong>Esta es una oportunidad para reflexionar sobre las áreas de desarrollo y para proporcionar retroalimentación constructiva.</strong></p><p class="ql-align-center"><br></p><p><strong>Instrucciones</strong></p><ul><li>Complete el cuestionario con atención.</li><li>Sea honesto y responda las preguntas con sinceridad.</li><li>Tómese un tiempo para reflexionar sobre sus respuestas.</li><li>Envíe la evaluación cuando termine.</li><li>Si tiene alguna pregunta, póngase en contacto con su gerente.</li></ul><p><br></p><p><strong>Su evaluación de desempeño es confidencial</strong></p><p>Solo su gerente y el departamento de Recursos Humanos tendrán acceso a sus respuestas. Puede estar seguro de que sus respuestas serán tratadas con confidencialidad y respeto.</p><p><br></p><p>¡Gracias por su participación!</p><p><br></p><p>Atentamente,</p><p><br></p><p><strong>Departamento de Recursos Humanos</strong></p>`;    
      default:
        return ""
    }
  }

import bedtime from '../../public/images/icons_inactivity/bedtime.png';
import check_circle_outline from '../../public/images/icons_inactivity/check_circle_outline.png';
import edit from '../../public/images/icons_inactivity/edit.png';
import emoji_food_beverage from '../../public/images/icons_inactivity/emoji_food_beverage.png';
import event_available from '../../public/images/icons_inactivity/event_available.png';
import fastfood from '../../public/images/icons_inactivity/fastfood.png';
import highlight_off from '../../public/images/icons_inactivity/highlight_off.png';
import meeting_room from '../../public/images/icons_inactivity/meeting_room.png';
import offline_bolt from '../../public/images/icons_inactivity/offline_bolt.png';
import person from '../../public/images/icons_inactivity/person.png';
import phone_in_talk from '../../public/images/icons_inactivity/phone_in_talk.png';
import phone_iphone from '../../public/images/icons_inactivity/phone_iphone.png';
import place from '../../public/images/icons_inactivity/place.png';
import smoking_rooms from '../../public/images/icons_inactivity/smoking_rooms.png';
import support_agent from '../../public/images/icons_inactivity/support_agent.png';
import voice_chat from '../../public/images/icons_inactivity/voice_chat.png';
import wifi_off from '../../public/images/icons_inactivity/wifi_off.png';



export const getIconInactivityByName = (name) => {
	const iconsObj = {
		bedtime:bedtime,
		check_circle_outline:check_circle_outline,
		edit:edit,
		emoji_food_beverage:emoji_food_beverage,
		event_available:event_available,
		fastfood:fastfood,
		highlight_off:highlight_off,
		meeting_room:meeting_room,
		offline_bolt:offline_bolt,
		person:person,
		phone_in_talk:phone_in_talk,
		phone_iphone:phone_iphone,
		place:place,
		smoking_rooms:smoking_rooms,
		support_agent:support_agent,
		voice_chat:voice_chat,
		wifi_off:wifi_off,
	}

	return iconsObj[name]
}