import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Moment from 'moment';
import Filterbar from '../_components/_shared/Filterbar';
import Toolbar from '../_components/_shared/Toolbar';
import RealTimeFull from '../_components/reports/realtime/RealTimeFull';
import RealTimeFullSkeleton from '../_components/reports/realtime/RealTimeFullSkeleton';

import { useTranslation } from "react-i18next";


import { shareActions, statsActions } from '../_actions';
import ShareModal from '../_components/_shared/ShareModal';
import { authHeader } from '../_helpers';
import { api } from '../_helpers/api';


function ReportsRealTimePage(props) {
	const { t, i18n } = useTranslation('common');

	const { statsActions, shareActions } = props;

	let loadingUsers = props.loadingUsers;
	let loading = props.loading;

	const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
	const [data, setData] = useState([]);
	const [pageCount, setPageCount] = useState(0);
  const [pageCountRows, setPageCountRows] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
	const [pageLoading, setPageLoading] = useState(true);
	const [fetching, setFetching] = useState(false);
	// console.log('/data',data)

	async function getRealtimeData(isLoading, pageIndex, pageSize, startDate, teams = null, dont_show_arr=null) {
    try {
      if (isLoading) {
        setFetching(true)
      }
			let dont_show_arr_value = ''
			if(dont_show_arr){
				dont_show_arr_value = dont_show_arr
			}
      const queryTeams = teams ? `&teams=${teams.map(t=>t.id).join(',')}` : ''
      // const queryTeammates = teammates ? `&users=${teammates.map(t=>t.id).join(',')}` : ''
      const response = await api.get(`/stats/realtime/users/?origin=repoRealTimePage&startDate=${startDate}&page=${pageIndex}&per_page=${pageSize}${queryTeams}&dont_show=${dont_show_arr_value}`, {
        headers: authHeader()
      })

      if (response.status !== 200) {
        throw new Error(t('generic_messages.errors.load'))
      }
			// console.log(response.data)
      setData(response.data.data)
      setPageCount(Math.ceil(parseInt(response.data.countRows) / pageSize))
      setPageCountRows(parseInt(response.data.countRows))
      setPageSize(pageSize)
      setPageIndex(pageIndex)
      setFetching(false)

    } catch (error) {
      console.log(error)
      //alertActions.error(t('generic_messages.errors.load'))
      setFetching(false)
    }
  }

	useEffect(() => {
		// console.log("chamou")
		let startDate = Moment(new Date()).format('YYYY-MM-DD');
		let endDate = Moment(new Date()).format('YYYY-MM-DD');

		if (props.filter.singleDate) {
			startDate = props.filter.singleDate;
			endDate = props.filter.singleDate;
		}

		let teams = null;
		let teammates = null;

		if (props.filter.teams && props.filter.teams.length > 0) {
			teams = props.filter.teams;
		}

		statsActions.getStats(startDate, endDate, teams, teammates);
		// statsActions.getRealtimeMachineUsers(startDate, endDate, teams);
		getRealtimeData(true, 0,localStorage.getItem('pageSizeStorage') || 20,startDate, teams)
		// console.log("aqui!!",teams,teammates,"data:",startDate)

	}, [props.filter.singleDate, props.filter.teams])


	function closeShareModal() {
		shareActions.closeModal()
	}

	useEffect(() => {
		document.title = 'Befective | ' + t('menu.reports') + ' | ' + t('menu.realtime');
	});

	return (
		<React.Fragment>
			<section className="mainContent">
				<Toolbar />

				<Filterbar
					filterWho="singleTeamsTESTE"
					filterWhen={'single'}
					filterWhat={'disabled'}
				/>

				{/* PAGE STARTS */}
				<div className="mainPage">
					<div className="column single">
						{
							fetching
								? (
									<RealTimeFullSkeleton />
								) : (
									<RealTimeFull
									data={data} 
									count={pageCount}
                  pageCountRows={pageCountRows}
									getData={getRealtimeData}
									setData={setData}
									pageLoading={setPageLoading}
									/>
								)
						}
					</div>

				</div>
				{/* PAGE ENDS */}
			</section>

			<div className={'overlay' + (props.share.modal ? ' open' : '')} onClick={() => {
				closeShareModal()
			}}></div>
			<section className={'modal' + (props.share.modal ? ' open' : '')}>
				<ShareModal reports='realTime' filters={props.filter.singleDate} />
			</section>


		</React.Fragment>
	)
};


function mapDispatchToProps(dispatch) {

	return {
		statsActions: bindActionCreators(statsActions, dispatch),
		shareActions: bindActionCreators(shareActions, dispatch),


	}
}


function mapStateToProps(state) {

	return {
		loading: state.stats.loading,
		loadingUsers: state.stats.loadingUsers,
		filter: state.filter,
		share: state.share

	}

}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsRealTimePage)
