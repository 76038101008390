import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { alertActions, teamsActions } from "../../_actions";
import { settingsActions } from "../../_actions";
import { authHeader } from "../../_helpers";
import { api } from "../../_helpers/api";
import { Box } from "@mui/material";
import Header from "../../_components/switch_page/Header";
import style from "./styles.module.scss";
import GlobalSkeleton from "../../_components/_shared/GlobalSkeleton";
import { InactivityForm } from "../../_components/inactivity_settings/InactivityForm";
import { Question } from "phosphor-react";
import InfoHelpDrawer from "../../_components/inactivity_settings/InfoHelpDrawer";


function InactivitySettingsFormPage(props) {
  const { t, i18n } = useTranslation("common");
  const {alertActions, userDetails} = props;
  const [loading, setLoading] = useState(true);
  const [dataInactivity, setDataInactivity] = useState(null);
  const [inactivityIcons, setInactivityIcons] = useState([]);
  const[ globalOutdoors, setGlobalOutdoors] = useState([])
  const [openDrawerInfoHelp, setOpenDrawerInfoHelp] = useState(false);

  const handleOpenDrawerInfoHelp = () => {
    setOpenDrawerInfoHelp(true);
  };

  const handleCloseDrawerInfoHelp = () => {
    setOpenDrawerInfoHelp(false);
  };

  const getGlobalSettingsInactivityData = async (isLoading=true) => {
    try {
      if(isLoading){
        setLoading(true)
      }
      const responseInactivity = await api.get(`/settings/global/inactivity`, { headers: authHeader() })
      setDataInactivity(responseInactivity.data);
      const responseInactivityIcons = await api.get(`/settings/global/inactivity-icons`, { headers: authHeader() })
      setInactivityIcons(responseInactivityIcons.data);
      const responseGlobalOutdoors = await api.get('/settings/outdoors', { headers: authHeader() });
      setGlobalOutdoors(responseGlobalOutdoors.data);
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
    }

  }

  useEffect(() => {
    getGlobalSettingsInactivityData();
    document.title = "Befective | " + t("menu.settings") + " | "+ t("global_settings.inactivities.title");
  }, []);

  return (
    <Box bgcolor="#F5F6F9" width="100%" display="flex" flexDirection="column">
      
      <Header toBackUrl={"/global-settings"} />

      <section className="mainContent">
        <div className="mainFilterbar">
          <div className="filters">
            {
              ['admin', 'magic'].includes(userDetails.profile_key) &&
                <NavLink
                  exact
                  to="/global-settings/inactivities"
                  activeClassName="active"
                >
                  <button className={"settingsNav"}>
                    {t("global_settings.inactivities.tab_config_global")}
                  </button>
                </NavLink>
            }
            <NavLink exact to="/global-settings/inactivities/teams">
              <button className={"settingsNav"}>
                {t("global_settings.inactivities.tab_config_by_team")}
              </button>
            </NavLink>
          </div>
          <button className="buttonInfoInactivity" type="button" onClick={handleOpenDrawerInfoHelp}>
            <Question size={24} />
          </button>
        </div>

        {/* PAGE STARTS */}
        <div className={style.containerPage}>
          {
            !loading
              ?
              <InactivityForm teamId={null} alertActions={alertActions} userDetails={userDetails} dataInactivity={dataInactivity} getInactivityData={getGlobalSettingsInactivityData} inactivityIcons={inactivityIcons} globalOutdoors={globalOutdoors}/>
              :
              <GlobalSkeleton totalRowsStats={0} totalTables={1} />
          }
        </div>
        {/* PAGE ENDS */}
      </section>

      <InfoHelpDrawer
        openDrawer={openDrawerInfoHelp}
        closeDrawer={handleCloseDrawerInfoHelp}
      />
    </Box>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    settingsActions: bindActionCreators(settingsActions, dispatch),
    teamsActions: bindActionCreators(teamsActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    token: state.authentication.user.accessToken,
    companyInformation: state.settings.companyInformation,
    loading: state.settings.loading,
    filter: state.filter,
    permissions: state.authentication.permissions,
    userDetails: state.authentication.userDetails,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InactivitySettingsFormPage);
