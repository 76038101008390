// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IZ3S4Nq3jwKkz2dYQ8DgEg\\=\\=:hover {\n  background-color: #d5e4f4 !important;\n}\n\n.L4PpNUNXZ7cIfZj-mBoaoA\\=\\=:hover {\n  background-color: #5ea0f7 !important;\n}\n\n.x997vtEEr7BZFEQkoijRHg\\=\\= {\n  position: absolute;\n  top: 12px;\n  right: 12px;\n  z-index: 5;\n}\n\n.WPlQ7RzB\\+17aqU8-xCf3mg\\=\\= {\n  color: #205B6F;\n}", "",{"version":3,"sources":["webpack://./src/_components/_shared/ReportAiModal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,UAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".cancelBtn:hover {\n  background-color: #d5e4f4 !important;\n}\n\n.save:hover {\n  background-color: #5ea0f7 !important;\n}\n\n.closeBtn{\n  position: absolute;\n  top: 12px;\n  right: 12px;\n  z-index: 5;\n}\n\n.containerMarkdown{\n  color: #205B6F;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancelBtn": "IZ3S4Nq3jwKkz2dYQ8DgEg==",
	"save": "L4PpNUNXZ7cIfZj-mBoaoA==",
	"closeBtn": "x997vtEEr7BZFEQkoijRHg==",
	"containerMarkdown": "WPlQ7RzB+17aqU8-xCf3mg=="
};
export default ___CSS_LOADER_EXPORT___;
