import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { alertActions, teamsActions } from "../../_actions";
import { settingsActions } from "../../_actions";
import { authHeader, history } from "../../_helpers";
import { api } from "../../_helpers/api";
import { Box } from "@mui/material";
import Header from "../../_components/switch_page/Header";
import style from "./styles.module.scss";
import GlobalSkeleton from "../../_components/_shared/GlobalSkeleton";
import EnhancedTable from "../../_components/_shared/EnhancedTable";

function InactivitySettingsTeamsPage(props) {
  const { t, i18n } = useTranslation("common");
  const {alertActions, userDetails} = props;
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([]);

  const columns = useMemo(() => {
    return [
      {
        Header: t("global_settings.inactivities.teams_table.col_team"),
        accessor: "name",        
        style: { paddingLeft: "20px" }
      },
      {
        Header: t("global_settings.inactivities.teams_table.col_colabs"),
        accessor: "total_colabs",
        style: { width: '20%', textAlign: 'center' }
      },
      {
        Header:t("global_settings.inactivities.teams_table.col_active_at"),
        accessor: "inactivity_time",
        style: { width: '15%' },
        Cell: ({ value }) => {
          return value ? `${value} ${t("global_settings.inactivities.teams_table.minutes")}` : '----'
        },
      },
      {
        Header:t("global_settings.inactivities.teams_table.col_popup"),
        accessor: "case",
        style: { width: '15%' },
        Cell: ({ value }) => {
          return value === 'by_global_settings' ? t("global_settings.inactivities.teams_table.popup_global") : t("global_settings.inactivities.teams_table.popup_custom")
        },
      },
    ];
  }, []);

  const getSettingsInactivityTeams = async (isLoading=true) => {
    try {
      if(isLoading){
        setLoading(true)
      }
      const {data} = await api.get(`/settings/groups/inactivity`, { headers: authHeader() })
      setTeams(data);
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
    }

  }

  useEffect(() => {
    getSettingsInactivityTeams();
    document.title = "Befective | " + t("menu.settings") + " | "+ t("global_settings.inactivities.title");
  }, []);

  return (
    <Box bgcolor="#F5F6F9" width="100%" display="flex" flexDirection="column">
      
      <Header toBackUrl={"/global-settings"} />

      <section className="mainContent">
        <div className="mainFilterbar">
          <div className="filters">
            {
              ['admin', 'magic'].includes(userDetails.profile_key) &&
              <NavLink
                exact
                to="/global-settings/inactivities"
              >
                <button className={"settingsNav"}>
                  {t("global_settings.inactivities.tab_config_global")}
                </button>
              </NavLink>
            }
            <NavLink exact to="/global-settings/inactivities/teams" activeClassName="active">
              <button className={"settingsNav"}>
                {t("global_settings.inactivities.tab_config_by_team")}
              </button>
            </NavLink>
          </div>
        </div>

        {/* PAGE STARTS */}
        <div className={style.containerPage}>
          {
            !loading
              ?
              <div className={style.mainCard}>
                <header className={style.mainCardHeader}>
                  <h4>{t("global_settings.inactivities.teams_table.title")}</h4>
                  <p>{t("global_settings.inactivities.teams_table.subtitle")}</p>
                </header>
                <EnhancedTable
                  columns={columns}
                  data={teams}
                  onRowClick={(row) => history.push(`/global-settings/inactivities/teams/${row.id}`)}
                />
              </div>
              :
              <GlobalSkeleton totalRowsStats={0} totalTables={1} />
          }
        </div>
        {/* PAGE ENDS */}
      </section>
    </Box>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    settingsActions: bindActionCreators(settingsActions, dispatch),
    teamsActions: bindActionCreators(teamsActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    token: state.authentication.user.accessToken,
    companyInformation: state.settings.companyInformation,
    loading: state.settings.loading,
    filter: state.filter,
    permissions: state.authentication.permissions,
    userDetails: state.authentication.userDetails,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InactivitySettingsTeamsPage);
